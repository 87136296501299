import axios from "../../plugins/axios";

const state = {
    editapidata: null,
    editapi: null,
};

const getters = {
    editapidata: (state) => state.editapidata,
    editapi: (state) => state.editapi,
};

const actions = {
    async fetchApiData({commit}, data) {
        commit("seteditapi", true)
        const response = await axios.get("/api/v1/navplus/user/apiAccess/" + data, {
            headers: {
                Authorization: "Bearer" + localStorage.getItem("token"),
            },
        });
        //console.log('EDIT API DI FETCH')
        //console.log(response)
        commit("seteditapidata", response.data.data)
        //console.log(commit)
    },


    removeEditApi({commit}) {
        commit("seteditapi", null),
            commit("seteditapidata", null)
    },

    async postApiData({commit}, data) {
        const dataapi = {
            id: data.id,
            ip_whitelist: data.ip_whitelist,
            isEnabled: data.isEnabled
        };
        const response = await axios.post(
            "/api/v1/navplus/user/apiAccess",
            dataapi,
            {
                headers: {
                    Authorization: "Bearer" + data.token,
                },
            }
        );
        //console.log(response);
        //console.log(commit)
    }
};

const mutations = {
    seteditapidata: (state, editapidata) => (state.editapidata = editapidata),
    seteditapi: (state, editapi) => (state.editapi = editapi)

};

export default {
    state,
    getters,
    actions,
    mutations,
};
