// import 'core-js/stable'
// import 'regenerator-runtime/runtime'
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import Vuesax from 'vuesax'
import 'vuesax/dist/vuesax.css'
import vuetify from './plugins/vuetify'
import './assets/maps/leaflet-1.8/leaflet.css';
import '@/plugins/ApexCharts'
import './registerServiceWorker'

// Suppress all Vue logs and warnings

Vue.config.silent = true
Vue.config.productionTip = false

var vm = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')

window.vm = vm;