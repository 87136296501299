<template>
  <v-app class="container_tabledata" color="#fff">
    <v-card elevation="0">
      <v-alert v-if="OffManifest" class="elevation-0 rounded-0" prominent type="warning" icon="mdi-web">
        <v-row align="center">
          <v-col class="grow">
            Connection timeout , please check your internet connection
          </v-col>
          <v-col class="shrink">
            <v-btn small @click="reload_page()" class="elevation-0 rounded-0">Reload</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <!-- {{ user_allowed }} -->
      <v-layout v-resize="onResize" column style="">
        <loadingTable v-if="!manifestData" />
        <v-card v-if="manifestData" class="ma-3 elevation-0 rounded-0" style="border: solid 1px #E0E0E0;">

          <v-toolbar elevation="0" class="pa-0" color="#F7F7F7" fixed dense>
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0" color="#ffff"
                  v-bind="attrs" v-on="on">
                  {{ kFormatter(itemsPerPage)
                  }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item @click="itemsPerPage = 10"><v-list-item-title>10</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 25"><v-list-item-title>25</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 50"><v-list-item-title>50</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 100"><v-list-item-title>100</v-list-item-title></v-list-item>
              </v-list>
            </v-menu>
            <v-spacer style="width: 70%"></v-spacer>
            <v-text-field style="border: solid 1px #e0e0e0;" width="40" v-model="search" hide-details
              prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
              class="elevation-0 rounded-0"></v-text-field>

          </v-toolbar>
          <v-divider></v-divider>
          <v-layout v-resize="onResize" column style="">
            <v-data-table style="margin-top: 0px" v-if="manifestData" :search="search" :headers="headers"
              :items="manifestData" hide-default-footer hide-default-header :page.sync="page"
              :items-per-page="itemsPerPage" class="elevation-0 rounded-0 dataTable desktopTable_manifest"
              @page-count="pageCount = $event" @pagination="yourMethod">
              <template v-slot:header>
                <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">No</th>
                <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Thuraya Number
                </th>
                <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Vessel</th>
                <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Company</th>
                <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Manifest</th>
              </template>
              <template v-slot:item="row">
                <tr>
                  <td width="9%" class="pa-1" style="white-space: nowrap;min-width:2.5em;text-align:left">{{
                    row.item.index + 1 }}</td>
                  <td width="20%" class="pa-1" style="white-space: nowrap;text-align:left">+{{ row.item.thuraya_number }}
                  </td>
                  <td width="27%" class="pa-1" style="white-space: nowrap;text-align:left">{{ row.item.name }}</td>
                  <td width="29%" class="pa-1" style="white-space: nowrap;text-align:left"> {{ row.item.pt_name }}</td>
                  <td width="15%" class="pa-1" style="white-space: nowrap;text-align:left">
                    <v-btn width="98%" color="#38C172" dark role="button" class="elevation-0 rounded-0 pa-1"
                      @click="addmanifest_executed(false), showManifest(row.item.thuraya_number, '24')" small><v-icon
                        small>mdi-trending-up</v-icon></v-btn>
                  </td>
                </tr>
              </template>
            </v-data-table>
            <v-data-table style="margin-top: 0px" v-if="manifestData" :search="search" :headers="headers"
              :items="manifestData" hide-default-footer hide-default-header :page.sync="page"
              :items-per-page="itemsPerPage" class="elevation-0 rounded-0 mobileTable_manifest"
              @page-count="pageCount = $event" @pagination="yourMethod"><template v-slot:item="row">

                <tr style="margin-top:0.4em;margin-bottom:0.4em">
                  <td style="border-bottom:none;text-align:left">
                    <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" width="87vw">
                      <v-toolbar dense class="elevation-0 rounded-0">
                        <v-toolbar-subtitle>{{ row.item.index + 1 }}</v-toolbar-subtitle>
                        <v-spacer></v-spacer>



                      </v-toolbar>
                      <v-divider></v-divider>
                      <v-card-text>
                        <table>

                          <tr>
                            <td>Thuraya Number</td>
                            <td>:</td>
                            <td>{{ row.item.thuraya_number }}</td>
                          </tr>
                          <tr>
                            <td>Vessel</td>
                            <td>:</td>
                            <td>{{ row.item.name }}</td>
                          </tr>
                          <tr>
                            <td>Company</td>
                            <td>:</td>
                            <td>{{ row.item.pt_name }}</td>
                          </tr>
                          <tr>
                            <td colspan="3"><v-divider></v-divider></td>
                          </tr>
                          <tr>
                            <td colspan="3" class="pa-1">

                              <v-btn block color="#38C172" dark role="button" class="elevation-0 rounded-0 pa-1"
                                @click="addmanifest_executed(false), showManifest(row.item.thuraya_number, 'today')"
                                small><v-icon small>mdi-trending-up</v-icon></v-btn>
                            </td>
                          </tr>



                        </table>
                      </v-card-text>
                    </v-card>



                  </td>
                </tr>

              </template></v-data-table>
            <v-divider></v-divider>
            <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">

              <p class="mt-3" style="font-size: 0.8em">
                Showing {{ showingDatafrom }} to {{ showingDatato }} of
                {{ totalFilter }} entries
              </p>
              <v-spacer></v-spacer>
              <v-pagination class="pt-2 pb-2" v-model="page" color="primary" :length="pageCount"
                :total-visible="7"></v-pagination>
            </v-toolbar>

          </v-layout>



        </v-card>
      </v-layout>
      <div id="bottom_table_manifest"></div>
    </v-card>
    <ManifestShowSkeleton v-if="!showmanifest && showmanifestclick" />
    <ShowManifest v-if="showmanifest" />
  </v-app>
</template>

<script>
// import axios from "axios";
import ShowManifest from "@/components/showpage/ShowManifest.vue";
import { mapGetters, mapActions } from "vuex";
import loadingTable from "@/components/progress/loadingTable.vue";
import ManifestShowSkeleton from "@/components/skeleton/ManifestShowSkeleton.vue";
import $ from "jquery";

export default {
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  components: {
    ShowManifest,
    loadingTable,
    ManifestShowSkeleton
  },
  data() {
    return {
      totalFilter: null,
      loadernavplus: require("@/assets/img/load100new.gif"),
      actif: "none",
      items: [5, 10, 15, "All"],
      itemsPerPage: 10,
      page: 1,
      pageCount: 0,
      show: false,
      mrtgopen: false,
      notifopen: false,
      manifest: null,
      mrtgData: null,
      notifData: null,
      selectedNotif: "",
      search: "",
      search_name: localStorage.getItem("search_name"),
      search_nav: localStorage.getItem("search_nav"),
      showmaptable: "",
      showmaptracking: null,
      showmapclick: null,
      index: "",
      switch1: true,
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Thuraya Number", value: "name", width: "20%" },
        { text: "Vessel", value: "Quota", width: "50%" },
        { text: "Company", value: "pt_name", width: "50%" },
        { text: "Manifest", value: "pt_group", width: "50%" },
      ],
    };
  },

  mounted() {
    this.fetchUSERGET(localStorage.getItem("token"))
  },

  async created() {
    this.fetchManifest(localStorage.getItem("token"));
    if (this.search_nav) {
      this.showOnMap("frommap", this.search_nav);
    }
  },

  methods: {
    ...mapActions(["fetchUSERGET", "fetchManifest", "fetchShowManifest", "addmanifest_executed"]),
    showManifest(thuraya_number, range) {
      console.log(this.manifest_executed)
      if (!this.manifest_executed) {

        $("html, body").animate(
          {
            scrollTop: $("#bottom_table_manifest").offset().top,
          },
          1000
        );
      }
      const data = {
        range: range,
        thuraya_number: thuraya_number,
        token: localStorage.getItem("token")
      };
      this.fetchShowManifest(data)
    },

    reload_page() {
      window.location.reload()
    },

    yourMethod(pagination) {
      this.totalFilter = pagination.itemsLength

    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },


  },
  computed: {
    ...mapGetters(["OffManifest", "user_allowed", "manifestData", "showmanifest", "showmanifestclick", "clickmanifest", "manifest_executed"]),
    showingDatafrom() {
      if (this.totalFilter < 1) {
        return 0
      } else {
        return 1
      }

    },

    showingDatato() {
      if (this.totalFilter < this.itemsPerPage) {
        return this.totalFilter
      } else {
        return this.itemsPerPage
      }

    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/data.scss";
@import "@/assets/css/manifestView.css";
</style>
