<template>
  <v-app class="container_tabledata" color="#fff">
    <v-card id="top_crewwatch" elevation="0">
      <v-alert
      v-if="OffCrew"
      class="elevation-0 rounded-0"
      prominent
      type="warning"
      icon="mdi-web"
    >
      <v-row align="center">
        <v-col class="grow">
          Connection timeout , please check your internet connection
        </v-col>
        <v-col class="shrink">
          <v-btn small @click="reload_page()" class="elevation-0 rounded-0">Reload</v-btn>
        </v-col>
      </v-row>
    </v-alert>
      <v-layout v-resize="onResize" column style="">
        <loadingTable v-if="!crewwatchData" />
        <v-card
          v-if="crewwatchData"
          class="ma-3 elevation-0 rounded-0"
          style="border: solid 1px #e0e0e0"
        >
          <v-toolbar elevation="0" class="pa-0" color="#F7F7F7" fixed dense>
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="border: solid 1px #e0e0e0"
                  small
                  class="rounded-0 pa-1 elevation-0 ma-0"
                  color="#ffff"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ kFormatter(itemsPerPage)
                  }}<v-icon small style="margin-left: 0.2em"
                    >mdi-unfold-more-horizontal</v-icon
                  >
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item @click="itemsPerPage = 10"
                  ><v-list-item-title>10</v-list-item-title></v-list-item
                >
                <v-list-item @click="itemsPerPage = 25"
                  ><v-list-item-title>25</v-list-item-title></v-list-item
                >
                <v-list-item @click="itemsPerPage = 50"
                  ><v-list-item-title>50</v-list-item-title></v-list-item
                >
                <v-list-item @click="itemsPerPage = 100"
                  ><v-list-item-title>100</v-list-item-title></v-list-item
                >
              </v-list>
            </v-menu>
            <v-spacer style="width: 70%"></v-spacer>

            <v-text-field
              style="border: solid 1px #e0e0e0"
              width="40"
              v-model="search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              solo
              clearable
              class="elevation-0 rounded-0"
          
            ></v-text-field>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table
            style="margin-top: 0px"
            :search="search"
            :headers="headers"
            :items="crewwatchData"
            hide-default-footer
            :page.sync="page"
            :items-per-page="itemsPerPage"
            class="elevation-0 rounded-0 dataTable desktopTable_crewwatch"
            @page-count="pageCount = $event"
            @pagination="yourMethod"
            hide-default-header
          >
            <template v-slot:header>
              <tr>
                <th
                  class="pa-0 pt-2 pl-1"
                  style="
                    text-align: left;
                    color: #626364;
                    font-size: 0.9em;
                    height: 3em;
                  "
                >
                  No
                </th>
                <th
                  class="pa-0 pt-2 pl-1"
                  style="
                    text-align: left;
                    color: #626364;
                    font-size: 0.9em;
                    height: 3em;
                  "
                >
                  Vessel Name
                </th>
                <th
                  class="pa-0 pt-2 pl-1"
                  style="
                    text-align: left;
                    color: #626364;
                    font-size: 0.9em;
                    height: 3em;
                  "
                >
                  Company
                </th>
                <th
                  class="pa-0 pt-2 pl-1"
                  style="
                    text-align: left;
                    color: #626364;
                    font-size: 0.9em;
                    height: 3em;
                  "
                >
                  Crew
                </th>
              </tr>
            </template>

            <template v-slot:item="row">
              <tr>
               <td  width="4%" style="text-align:left">{{ row.item.index+1 }}</td>
               <td  width="30%" style="text-align:left">{{ row.item.name }}</td>
               <td  width="32%" style="text-align:left">{{row.item.pt_name}}</td>
               <td  width="32%" style="text-align:left">  <v-btn
                    @click="showCrews(row.item),removeShowCrew()"
                    color="primary"
                    dark
                    role="button"
                    class="pingbtn elevation-0 rounded-0"
                    style="min-width: 23em"
                    small
                    ><div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-account-multiple</v-icon>Show Crew</div></v-btn
                  ></td>
              </tr>
            </template>
          </v-data-table>


          <v-data-table
          
            v-if="crewwatchData"
            style="margin-top: 0px"
            :search="search"
            :headers="headers"
            :items="crewwatchData"
            hide-default-footer
            :page.sync="page"
            :items-per-page="itemsPerPage"
            class="elevation-0 rounded-0 mobileTable_crewwatch"
            @page-count="pageCount = $event"
            @pagination="yourMethod"
            hide-default-header
          ><template v-slot:item="row">
    
      <tr style="margin-top:0.4em;margin-bottom:0.4em" >
        <td style="border-bottom:none;text-align:left" >
        <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" width="87vw">
          <v-toolbar
           dense
           class="elevation-0 rounded-0"
          >
            <v-toolbar-subtitle>{{row.item.index+1}}</v-toolbar-subtitle>
            <v-spacer></v-spacer>
           
                

          </v-toolbar>
          <v-divider></v-divider>
      <v-card-text>
              <table>
           
            <tr><td>Vessel Name</td><td>:</td><td>{{row.item.name}}</td></tr>
            <tr><td>Company</td><td>:</td><td>{{row.item.pt_name}}</td></tr>
            <tr><td colspan="3"><v-divider></v-divider></td></tr>
            <tr><td  colspan="3" class="pa-1"> 

              <v-btn
                    @click="showCrews(row.item),removeShowCrew()"
                    color="primary"
                    dark
                    role="button"
                    class="pingbtn elevation-0 rounded-0"
                    style="min-width: 23em"
                    small
                    ><i class="bx bxs-user"></i>&nbsp;&nbsp;Show Crew</v-btn
                  ></td></tr>
       
        
            
          </table>
            </v-card-text>
          </v-card>
          
          
          
        </td>
      </tr>
   
  </template></v-data-table>

          <v-divider></v-divider>
          <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
            <p class="mt-3" style="font-size: 0.8em">
              Showing {{ showingDatafrom }} to {{ showingDatato }} of
              {{ totalFilter }} entries
            </p>
            <v-spacer></v-spacer>
            <v-pagination
              class="pt-2 pb-2"
              color="primary"
              v-if="crewwatchData"
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-toolbar>
        </v-card>
      </v-layout>
      <div id="bottom_table"></div>
    </v-card>
    <v-card class="elevation-0 rounded-0" v-if="!listcrew && listcrewclick ">
      <v-card style="border: solid 1px #e0e0e0" color="#ffff" class="ma-3 elevation-0 rounded-0 mt-0 ">
        <v-toolbar id="title_showcrew" color="#F7F7F7" class="elevation-0 rounded-0" dense>
          
        </v-toolbar>
        <v-divider></v-divider>
        <v-card class="elevation-0 rounded-0 ma-3"  style="text-align:left;border: solid 1px #e0e0e0" >
          <v-row no-gutters style="flex-wrap: nowrap">
            <v-col
              cols="1"
              style="min-width: 100px; max-width: 100%; margin: 5px"
              class="flex-grow-1 flex-shrink-0 "
            >
              <v-card  class="pa-2" outlined tile>
                <v-container style="padding-left:16em"  fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>
              </v-card>
            </v-col>
            <v-col
              cols="1"
              style="min-width: 100px; max-width: 100%; margin: 5px"
              class="flex-grow-1 flex-shrink-0"
            >
              <v-card class="pa-2" outlined tile>
                <v-container style="padding-left:16em"  fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
     
      </v-card>
    </v-card>
    <v-card class="elevation-0 rounded-0" v-if="listcrew">
      <v-card style="border: solid 1px #e0e0e0" color="#ffff" class="ma-3 elevation-0 rounded-0 mt-0 pb-3">
        <v-toolbar id="title_showcrew" color="#F7F7F7" class="elevation-0 rounded-0" dense>
          <v-toolbar-title>{{ listcrew.vessel_pt }} </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
           
            small class="elevation-0 rounded-0" icon
            @click="removeShowCrew(), (showcrewclick = null), listcrewclick=null,detailcrewclick=null"
          >
            <v-icon > mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card class="elevation-0 rounded-0 ma-3"  style="text-align:left;border: solid 1px #e0e0e0" >
          <v-row v-if="listcrew" no-gutters style="flex-wrap: nowrap">
            <v-col
              cols="1"
              style="min-width: 100px; max-width: 100%; margin: 5px"
              class="flex-grow-1 flex-shrink-0"
            >
              <v-card class="pa-2" outlined tile>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th :colspan="2" class="text-left">
                            Seafarer (Officer)
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          @click="
                            showDetailCrews(item.id), (showcrewclick = true)
                          "
                          v-for="item in listcrew.list_officer"
                          :key="item.name"
                          style="cursor: pointer"
                        >
                          <td style="padding: 10px">
                            <p
                              class="font-weight-bold caption"
                              style="margin-bottom: 0"
                            >
                              {{ item.rank }}
                            </p>
                            {{ item.name }}
                          </td>
                          <td style="padding: 10px">
                            <v-badge :color="item.status" dot inline></v-badge>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col
              cols="1"
              style="min-width: 100px; max-width: 100%; margin: 5px"
              class="flex-grow-1 flex-shrink-0"
            >
              <v-card class="pa-2" outlined tile>
                <v-card-text>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th :colspan="2" class="text-left">Crew</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          @click="
                            showDetailCrews(item.id), (showcrewclick = true)
                          "
                          v-for="item in listcrew.list_crew"
                          :key="item.name"
                          style="cursor: pointer"
                        >
                          <td>{{ item.name }}</td>
                          <td>
                            <v-badge :color="item.status" dot inline></v-badge>
                          </td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card>
        <v-card v-if="!detailcrew && detailcrewclick " style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-3" >
        <v-container  fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>
      </v-card>

        <v-card id="title_tablecrew" style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-3" v-if="detailcrew">
          <v-toolbar class="elevation-0 rounded-0" color="#F7F7F7" dense>
            {{ detailcrew.name_crew }}
          </v-toolbar>
          <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense>
            {{ detailcrew.range.range_actual }}
            <v-spacer style="width:30%"> </v-spacer>

          

            <v-text-field
            style="border: solid 1px #e0e0e0"
            width="40"
            v-model="searchCREW"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            solo
            clearable
            class="elevation-0 rounded-0"
          ></v-text-field>
            <v-menu content-class="elevation-0 rounded-0 " offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                style="border: solid 1px #e0e0e0"
                  color="#fff"
                  class="elevation-0 rounded-0 mr-1 ml-1"
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <i class="bx bx-time"></i>
                  &nbsp; &nbsp;
                  <v-text v-if="detailcrew.range.range">{{
                    detailcrew.range.range
                  }}</v-text>
                  <v-card-text v-if="!detailcrew.range.range">
                    <div class="snippet" data-title=".dot-pulse">
                      <div class="stage">
                        <div class="dot-pulse"></div>
                      </div>
                    </div>
                  </v-card-text>
                  &nbsp; &nbsp;
                  <i class="bx bxs-chevron-down"></i>
                </v-btn>
              </template>
              <v-list>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, 'hourly', 'LAST HOUR', inout),
                        delmodalConv()
                    "
                    >Last Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, '6hour', 'LAST 6 HOURS', inout),
                        delmodalConv()
                    "
                    >Last 6 Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, 'today', 'LAST 24 HOURS', inout),
                        delmodalConv()
                    "
                    >Last 24 Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, 'Daily', 'TODAY', inout),
                        delmodalConv()
                    "
                    >Today</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, 'yesterday', 'YESTERDAY', inout),
                        delmodalConv()
                    "
                    >Yesterday</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, 'Weekly', 'LAST 7 DAYS', inout),
                        delmodalConv()
                    "
                    >Last 7 Days</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, 'Monthly', 'LAST 30 DAYS', inout),
                        delmodalConv()
                    "
                    >Last 30 Days</v-btn
                  >
                </v-list-item>
              </v-list>
            </v-menu>
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                style="border: solid 1px #e0e0e0"
                  small
                  class="rounded-0 pa-1 elevation-0 ma-0"
                  color="#ffff"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ kFormatter(itemsPerPageCREW)
                  }}<v-icon small style="margin-left: 0.2em"
                    >mdi-unfold-more-horizontal</v-icon
                  >
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="itemsPerPageCREW = 5"
                  ><v-list-item-title>5</v-list-item-title></v-list-item
                >
                <v-list-item @click="itemsPerPageCREW = 10"
                  ><v-list-item-title>10</v-list-item-title></v-list-item
                >
                <v-list-item @click="itemsPerPageCREW = 15"
                  ><v-list-item-title>15</v-list-item-title></v-list-item
                >
                <v-list-item
                  @click="itemsPerPageCREW = detailcrew.message.length"
                  ><v-list-item-title>All</v-list-item-title></v-list-item
                >
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-data-table
            style="margin-top: 0px"
            :headers="headersDetail"
            :items="detailcrew.message"
            :items-per-page="5"
            class="elevation-0 dataTable"
            :search="searchCREW"
          >
            <template v-slot:item="row">
              <tr>
                <!-- <td>{{row.index}}</td> -->
                <td>{{ row.item.Name }}</td>
                <td>{{ row.item.ip }}</td>
                <td>{{ row.item.volume }}</td>
                <td>{{ row.item.traffic }}</td>
              </tr>
            </template>
          </v-data-table>
        </v-card>

        <div id="bottom_tablecrew"></div>
      </v-card>
    </v-card>
    <div id="bottom_crewwatch"></div>
  </v-app>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import ShowonMap from "@/components/showonmap/ShowonMap.vue";
import { mapGetters, mapActions } from "vuex";
import * as easings from "vuetify/lib/services/goto/easing-patterns";
import MrtgModal from "@/components/modals/mrtg_modal.vue";
import NameModal from "@/components/modals/name_modal.vue";
import NotifModal from "@/components/modals/notif_modal.vue";
import loadingTable from "@/components/progress/loadingTable.vue";
import ShowonMapLoad from "@/components/skeleton/ShowonMapLoad.vue";

export default {
  components: {
    ShowonMap,
    MrtgModal,
    NameModal,
    NotifModal,
    loadingTable,
    ShowonMapLoad,
  },
  data() {
    return {
      detailcrewclick:null,
      listcrewclick:null,
      nameModal: null,
      notif_id: null,
      type: "number",
      mrtgclicked: null,
      number: 9999,
      selector: "#first",
      selections: ["#first", "#second", "#third"],
      selected: "Button",
      elements: ["Button", "Radio group"],
      duration: 300,
      offset: 0,
      easing: "easeInOutCubic",
      easings: Object.keys(easings),
      loading: null,
      alert_: null,
      items: [5, 10, 15, "All"],
      pingData: [],
      itemsPerPage: 10,
      pingClicked: null,
      page: 1,
      pageCount: 0,
      ip_adr: null,
      ln_status: null,
      show: false,
      selectedNotif: "",
      search: "",
      search_name: localStorage.getItem("search_name"),
      search_nav: localStorage.getItem("search_nav"),
      index: "",
      loadernavplus: require("@/assets/img/load100new.gif"),
      totalFilter: null,

      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "index",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "Edit Vessel Name",
        },
        { text: "Vessel Name", value: "name", sortable: false },
        { text: "Quota", value: "Quota", sortable: false },
        { text: "Company", value: "pt_name", sortable: false },
        { text: "Group", value: "pt_group", sortable: false },
        { text: "S/N", value: "serial_number", sortable: false },
        { text: "", value: "good", sortable: false },
      ],

      switch1: true,
      itemsPerPageCREW: 5,
      pageCREW: 1,
      pageCountCREW: 0,
      loadernavplus: require("@/assets/img/load100new.gif"),
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Vessel Name", value: "name", width: "20%" },
        { text: "Company", value: "Quota", width: "50%" },
        { text: "Crew", value: "pt_name", width: "50%" },
      ],
      headersDetail: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "",
        },
        { text: "(Latitude, Longitude)", value: "name", width: "20%" },
        { text: "Skin Temperature (°C)", value: "Quota", width: "50%" },
        {
          text: "Heart Rate (Beat per Minutes)",
          value: "pt_name",
          width: "50%",
        },
        { text: "Calorie (kcal)", value: "pt_name", width: "50%" },
        { text: "Walk (Steps)", value: "pt_name", width: "50%" },
        { text: "Distance (Meter)", value: "pt_name", width: "50%" },
        { text: "Date/Time (GMT+7)", value: "pt_name", width: "50%" },
        { text: "Status", value: "pt_name", width: "50%" },
      ],
    };
  },

  mounted() {
    this.fetchCrewwatch(localStorage.getItem("token"));
    this.fetchUSERGET(localStorage.getItem("token"))
  },

  methods: {
    ...mapActions([
      "fetchCrewwatch",
      "fetchUSERGET",
      "showCrewData",
      "showCrewDetail",
      "removeShowCrew",
    ]),

    reload_page(){
    window.location.reload()
  },

    yourMethod(pagination) {
      this.totalFilter = pagination.itemsLength;
    },

    async showCrews(item) {
    this.detailcrewclick=null
    this.listcrewclick=null
     try{
      console.log(item);
      this.listcrewclick=true
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.showCrewData(data);
      $("html, body").animate(
        {
          scrollTop: $("#bottom_crewwatch").offset().top - 109,
        },
        800
      );
     }catch(e){
      
     }

      
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    async showDetailCrews(item) {
    this.detailcrewclick=null
     try{
      this.detailcrewclick=true
      const data = {
        id: item,
        token: localStorage.getItem("token"),
      };

      this.showCrewDetail(data);
      $("html, body").animate(
        {
          scrollTop: $("#bottom_tablecrew").offset().top - 109,
        },
        800
      );
     }catch(e){
      
     }
      
    },

    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.desserts.slice();
    },
    changeSort(column) {
      console.log(column);
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
   

    sailnameFetch(item) {
      this.nameModal = item;
      const dataname = {
        id: item.id,
        token: localStorage.getItem("token"),
      };

      this.vnameFetchSailink(dataname);
    },
  },

  computed: {
    ...mapGetters(["crewwatchData", "listcrew", "detailcrew","OffCrew"]),
    target() {
      return 62000000;
    },

    showingDatafrom() {
      if (this.totalFilter < 1) {
        return 0;
      } else {
        return 1;
      }
    },

    showingDatato() {
      if (this.totalFilter < this.itemsPerPage) {
        return this.totalFilter;
      } else {
        return this.itemsPerPage;
      }
    },
    ListOficer: function () {
      return this.listcrew.crews.filter(function (lc) {
        return lc.rank;
      });
    },
    ListCrews: function () {
      return this.listcrew.crews.filter(function (lc) {
        return !lc.rank;
      });
    },
    target() {
      return 62000000;
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/data.scss";
.vue2leaflet-map {
  z-index: 1;
}

.v-pagination__item {
  background: transparent;
  border-radius: 0px;
  font-size: 0.8rem;
  height: 1.6em;
  margin: 0px;
  min-width: 1.6em;
  padding: 0 5px;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  width: auto;
  box-shadow: none;
}

.v-pagination__navigation {
  box-shadow: none;
  border-radius: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 1.2em;
  width: 1.2em;
  margin: 0.2em 0.4em;
}

.v-pagination__item--active {
  box-shadow: none;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding-left: 0.4em;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 0;
  box-shadow: none;
}

.v-dialog {
  box-shadow: none;
  border-radius: 0;
}

.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 2em;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
}

$base-color: #edecff;
$shine-color: #ccdbe3;
$animation-duration: 2s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(
    90deg,
    $base-color 0px,
    $shine-color 40px,
    $base-color 80px
  );
  background-size: 600px;
}

.line {
  float: left;
  width: 90%;
  height: 16px;
  border-radius: 0;
  margin-top: 0.7em;
  @include background-gradient;
  animation: shine-lines $animation-duration infinite ease-out;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }
  40%,
  100% {
    background-position: 140px;
  }
}

.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
  min-height: 8px;
}

@media (min-width: 280px) and (max-width: 319px) {
  .mobileTable_crewwatch {
    display: block;
  }
  .desktopTable_crewwatch {
    display: none;
  }
}

/* Mobile Devices */
@media (min-width: 320px) and (max-width: 480px) {
  .mobileTable_crewwatch {
    display: block;
  }
  .desktopTable_crewwatch {
    display: none;
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .mobileTable_crewwatch {
    display: none;
  }
  .desktopTable_crewwatch {
    display: block;
  }
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .mobileTable_crewwatch {
    display: none;
  }
  .desktopTable_crewwatch {
    display: block;
  }
}

/* Desktops, large screens */
@media (min-width: 1025px) and (max-width: 1200px) {
  .mobileTable_crewwatch {
    display: none;
  }
  .desktopTable_crewwatch {
    display: block;
  }
}

/* Extra large screens / TV */
@media screen and (min-width: 1201px) {
  .mobileTable_crewwatch {
    display: none;
  }
  .desktopTable_crewwatch {
    display: block;
  }
}

.dataTable_Sailink tbody {
  flex-direction: column;
}
</style>
