<template>
  <v-dialog v-model="CallCreated_" h width="700" persistent>
    <v-card class="rounded-0 elevation-0 pb-5" >
      <v-toolbar class="elevation-0 rounded-0" color="#ffff" dense>
        <v-spacer></v-spacer>
        <v-btn
          small
          class="elevation-0 rounded-0"
          icon
          @click="closeCall_Modal()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
     <v-card height="200" class="elevation-0 rounded-0 ma-2">
      <v-avatar color="#CCDBE3">
        <span class="white--text text-h5">{{ initialVessel_ }}</span>
      </v-avatar>
      <v-card-title style="display: block" class="text-h5 text-center">
        <span id="thurayaCallStatus"></span>
      </v-card-title>
    
     Thuraya | {{ CallVessel_ }} | +{{ CallCreated_ }}
      <v-card-text
        id="progress-tatus"
        style="display: flex; padding-left: 5em; padding-right: 5em"
      >
        <v-icon>mdi-phone-forward</v-icon>
        <v-progress-linear
          color="black"
          buffer-value="0"
          class="ma-3"
          reverse
          stream
        ></v-progress-linear
      ></v-card-text>
      <v-card-text id="time-status">
        <span>Remaining Call Time Is </span><span id="callQuota">24</span
        ><span> Minutes</span>
      </v-card-text>
      <v-card-text id="totaltime-status">
        <span>Total Call Time </span><span id="callQuota">{{totalRoundedUP}}</span
        ><span> Minutes</span>
      </v-card-text>
     </v-card>
      <v-divider></v-divider>
      <v-card-actions class="mt-5">
        <v-menu  content-class="elevation-0 rounded-0" top :offset-y="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#f7f7f7"
              v-bind="attrs"
              v-on="on"
              class="elevation-0 rounded-0"
            >
              <v-icon>{{valueVolume==0 ? 'mdi-volume-off': 'mdi-volume-high'}}</v-icon>
            </v-btn>
          </template>

          <v-list color="#fff" style="border: solid 1px #e0e0e0">
            <v-list-item class="pt-3">{{ valueVolume }}</v-list-item>
            <v-list-item>
              <v-slider
                v-model="valueVolume"
                @change="changeVolume()"
                vertical
               
              ></v-slider>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-spacer></v-spacer>
        <v-btn
          dark
          class="elevation-0 rounded-0"
          id="hangup-btn"
          :color="conectedCall ? 'red' : '#EDC1C1'"
          @click="dialog = false"
        >
          <v-icon class="mr-2">mdi-phone-off</v-icon>
          Hangup
        </v-btn>
        <v-btn
          id="call-btn"
          dark
          class="elevation-0 rounded-0 ml-2"
          :color="!conectedCall ? '#38C172' : '#C7E1D2'"
          @click="!conectedCall ? mulaiCall() : console.log('cant call twice')"
        >
          <v-icon class="mr-2">mdi-phone</v-icon>
          Call
        </v-btn>
      </v-card-actions>
    </v-card>

    <audio id="remoteAudio" autoplay control style="display: none">
      <p>Your browser doesn't support HTML5 audio.</p>
    </audio>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";

export default {
  data() {
    return {
      conectedCall: true,
      initialVessel_: "",
      valueVolume: 100,
      totalRoundedUP:0,
    };
  },
  methods: {
    ...mapActions([
      "removeCallData",
      "storeCallData",
      "removeSearch",
      "fetchMySailink",
      "updateVesselName",
      "fetchMRTG",
      "removeMRTG",
      "showonMap",
      "ipAdr",
      "lnStatus",
      "showondate",
      "addShowClick",
      "setDataName",
      "removeName",
      "setCallCreated_Data",
      "unsetCallCreated_Data",
    ]),

    closeCall_Modal(){
      this.unsetCallCreated_Data()
      this.removeCallData()
    },

    async changeVesselName(id) {
      const data = {
        id: id,
        name: this.nameData.name,
        token: localStorage.getItem("token"),
      };
      this.updateVesselName(data);
      this.fetchMySailink(data.token);
      console.log(this.color_name);
      console.log(this.alertColor);
    },

    changeVolume() {
      var vid = document.getElementById("remoteAudio");
      var volumeNilai = this.valueVolume / 100;
      console.log("VOLUMENYA BERUBAH");
      console.log(volumeNilai);
      vid.volume = volumeNilai;
    },

    mulaiCall() {
      var supreme = this
      let name = this.CallVessel_;
      let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

      let initials = [...name.matchAll(rgx)] || [];

      initials = (
        (initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")
      ).toUpperCase();
      console.log("INITIAL");
      console.log(initials);
      this.initialVessel_ = initials;
      this.conectedCall = true;
      document.getElementById("time-status").classList.add("d-none");
      document.getElementById("totaltime-status").classList.add("d-none");
      document.getElementById("progress-tatus").classList.remove("d-none");
      document.getElementById("hangup-btn").style.color = "#fff";
      document.getElementById("hangup-btn").style.cursor = "pointer";
      document.getElementById("call-btn").style.color = "#A1B8AB";
      document.getElementById("call-btn").style.cursor = "not-allowed";
      var callInterval;
      var thuraya_number = "";
      
      //from API
      var quotaCall = this.callData.quota;
      var limited = !this.callData.nolimit;

      // if (this.callData.nolimit == false) {limited = true } else {limited = false}

      var durationCall = 0;
      var cd = 30;
      

      const webSocketServer = "wss://pbx.sailink.id:8099/ws";
      const domain = "pbx.sailink.id";
      const aor = "sip:99305@" + domain;
      const displayName = "99305";
      const authorizationUsername = "99305";
      const authorizationPassword = "cb7ac990829f2f40f14b010271c5494c";
      // const target = "sip:" + thurayaNumber + "@" + domain;
      function pad(num, size) {
    var s = "000" + num;
    return s.substring(s.length-size);
}

function getFormat(second) {
    var m = Math.floor(second / 60);
    var s = second - m * 60;
    var formatted = pad(m, 2)+':'+pad(s, 2);
    return formatted;
}
function getFormatRoundUp(second) {
  var m = Math.floor(second / 60);
  var formatted = pad(m, 2);
  return formatted;
}

      function getAudio(id) {
        const el = document.getElementById(id);
        if (!(el instanceof HTMLAudioElement)) {
          throw new Error(`Element "${id}" not found or not an audio element.`);
        }
        console.log("audio element is valid");
        return el;
      }
      function getButton(id) {
        const el = document.getElementById(id);
        if (!(el instanceof HTMLButtonElement)) {
          throw new Error(`Element "${id}" not found or not a button element.`);
        }
        return el;
      }
      function getDiv(id) {
        const el = document.getElementById(id);
        // if (!(el instanceof HTMLDivElement)) {
        if (!(el instanceof HTMLSpanElement)) {
          throw new Error(`Element "${id}" not found or not a div element.`);
        }
        return el;
      }

      const audioElement = getAudio("remoteAudio");
      const hangupButton = getButton("hangup-btn");
      const statusDiv = getDiv("thurayaCallStatus");

      const simpleUserDelegate = {
        onCallCreated: () => {
          // console.log(`[${displayName}] Call created`);
          // statusDiv.innerHTML = "Call created";

          console.log(`[${displayName}] Call Requested`);
          statusDiv.innerHTML = "Call Requested";
        },
        onCallAnswered: () => {
          // console.log(`[${displayName}] Call answered`);
          // statusDiv.innerHTML = "Call answered";
          document.getElementById("progress-tatus").classList.add("d-none");
          document.getElementById("totaltime-status").classList.add("d-none");
          document.getElementById("time-status").classList.remove("d-none");
          console.log(`[${displayName}] On Progress Call`);
          statusDiv.innerHTML = "On Progress Call";

          // start counting
          console.log("started counting");

          durationCall = 0;

          callInterval = setInterval(() => {
            console.log("==============================");
            durationCall++;
            if (durationCall > cd) {
              quotaCall--;
            }
            console.log("duration   = " + durationCall);
            console.log("quota      = " + quotaCall);
            console.log("call duration  == " + getFormat(durationCall));
            console.log("call quota     == " + getFormat(quotaCall));
            console.log("\n");

            // $('#callDuration').html(getFormat(durationCall));
            $("#callQuota").html(getFormatRoundUp(quotaCall));

            if (limited) {
              console.log("masuk limited");
              if (quotaCall < 1 || durationCall >= 180) {
                doHangup();
                alert(
                  "Quota Call HABIS / Durasi Sesi melebihi batas (3 Menit)"
                );
              }
            }
          }, 1000);
        },

        onCallHangup: () => {


          console.log('INI ADALAH SISA DURATION CALL : ')
          console.log(durationCall)
          console.log('INI ADALAH SISA QUOTA CALL : ')
          console.log(quotaCall)
          var sisaqt = getFormatRoundUp(quotaCall)

          var dataCLsend = {
            token: localStorage.getItem("token"),
            thuraya_number : supreme.CallCreated_,
            duration:durationCall,
            quota:sisaqt,
          }

          supreme.storeCallData(dataCLsend)


          console.log(`[${displayName}] Call hangup`);
          statusDiv.innerHTML = "Call disconnected";
          document.getElementById("time-status").classList.add("d-none");
          document.getElementById("progress-tatus").classList.add("d-none");
          document
            .getElementById("totaltime-status")
            .classList.remove("d-none");
          document.getElementById("call-btn").style.color = "#ffff";
          document.getElementById("call-btn").style.cursor = "pointer";
          document.getElementById("hangup-btn").style.color = "#CB7C7C";
          document.getElementById("hangup-btn").style.cursor = "not-allowed";
          this.conectedCall = false;

          // stop counting
          console.log("stop counting");
          clearInterval(callInterval);
          console.log("TOTAL CALL TIME == " + durationCall + " Second");
          let sisa = getFormatRoundUp(quotaCall);
          this.totalRoundedUP = getFormatRoundUp(durationCall);
          console.log("TOTAL ROUNDED UP == " + sisa + " Minute");
          save_call_log(durationCall, sisa);
        },
        onCallHold: (held) => {
          console.log(`[${displayName}] Call hold ${held}`);
        },
      };
      const simpleUserOptions = {
        aor,
        delegate: simpleUserDelegate,
        media: {
          remote: {
            audio: audioElement,
          },
        },
        userAgentOptions: {
          logLevel: "debug",
          displayName,
          authorizationUsername,
          authorizationPassword,
          logConfiguration: true,
        },
      };

      const simpleUser = new SIP.Web.SimpleUser(
        webSocketServer,
        simpleUserOptions
      );

      async function doCall(thurayaNumber, noLimit) {
        let target = "sip:" + thurayaNumber + "@" + domain;
        thuraya_number = thurayaNumber;
        limited = !noLimit;

        statusDiv.innerHTML = "Connecting...";
        await simpleUser.connect();
        statusDiv.innerHTML = "Registering...";
        await simpleUser.register();
        statusDiv.innerHTML = "Calling... " + thurayaNumber;
        await simpleUser.call(target, { inviteWithoutSdp: false });
      }

      console.log("THURAYA NUMBERNYA");
      console.log(this.CallCreated_);
      doCall(this.CallCreated_);

      async function doHangup() {
        try {
          await simpleUser.disconnect();
          await simpleUser.hangup();
        } catch (error) {
          console.log(`[${displayName}] Call hangup`);
          statusDiv.innerHTML = "Call disconnected";

          // stop counting
          console.log("stop counting");
          clearInterval(callInterval);
          console.log("TOTAL CALL TIME == " + durationCall + " Second");
          console.log(getFormatRoundUp(quotaCall))          
          //save_call_log(durationCall, getFormatRoundUp(quotaCall));
        }
      }

      // callButton.addEventListener("click", () => {
      //     doCall();
      // });

      hangupButton.addEventListener("click", () => {
        doHangup();
        // clearInterval(callCounter);
      });

      function save_call_log(duration, sisa) {
        // call api store call
        var _token = $('input[name="_token"]').val();

        $.ajax({
          url: "ajax/storeCallLog",
          method: "POST",
          data: {
            thuraya_number: thuraya_number,
            duration: duration,
            sisa: sisa,
            _token: _token,
          },
          dataType: "json",
        }).done(function (con) {
          console.log("log saved");
        });
      }
    },
  },

  mounted() {
    // this.$nextTick(() => {
    //   this.mulaiCall();
    // });
  },

  watch: {
    // whenever question changes, this function will run
    callData(newQuestion, oldQuestion) {
      this.mulaiCall();
    }
  },
  computed: {
    ...mapGetters([
      "callData",
      "sailinkData",
      "mrtgSailink",
      "showmaptable",
      "searchVessel",
      "searchVesselID",
      "showmapclick",
      "status_mrtg",
      "mrtgopen",
      "alertSailink",
      "nameopen ",
      "message_name",
      "color_name ",
      "nameData",
      "nameChangeData",
      "icon_name",
      "msg_name",
      "nmopen",
      "alertColor",
      "StaticName",
      "CallCreated_",
      "CallVessel_",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  box-shadow: none;
  border-radius: 0;
}

.d-none {
  display: none;
}
</style>
