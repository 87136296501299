<template>
  <v-card class="elevation-0 mt-0 rounded-0">
    <v-card
      id="title_map_snapshot"
      class="container_showonmap ma-3 elevation-0 rounded-0 mt-0 pb-3 mt-0"
      style="border: solid 1px #e0e0e0"
    >
      <v-toolbar color="#F7F7F7" dense class="elevation-0 rounded-0">
      </v-toolbar>
      <v-divider></v-divider>

      <v-card
        class="elevation-0 rounded-0 ma-3"
        color="#FFF"
        style="border: solid 1px #e0e0e0"
      >
        <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0" dense>
          <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                disabled
                style="border: solid 1px #e0e0e0"
                small
                class="rounded-0 pa-1 elevation-0 ma-0"
                color="#ffff"
                v-bind="attrs"
                v-on="on"
              >
                {{ kFormatter(itemsPerPageImage)
                }}<v-icon small style="margin-left: 0.2em"
                  >mdi-unfold-more-horizontal</v-icon
                >
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="(itemsPerPageImage = 10), getDataFromApi()"
                ><v-list-item-title>10</v-list-item-title></v-list-item
              >
              <v-list-item @click="(itemsPerPageImage = 25), getDataFromApi()"
                ><v-list-item-title>25</v-list-item-title></v-list-item
              >
              <v-list-item @click="(itemsPerPageImage = 50), getDataFromApi()"
                ><v-list-item-title>50</v-list-item-title></v-list-item
              >
              <v-list-item @click="(itemsPerPageImage = 100), getDataFromApi()"
                ><v-list-item-title>100</v-list-item-title></v-list-item
              >
            </v-list>
          </v-menu>
          <v-spacer style="width: 67%"></v-spacer>
          <v-icon class="mr-3">mdi-folder-outline</v-icon>00-00-00
        </v-toolbar>
        <v-divider></v-divider>
        <v-progress-linear indeterminate color="primary"></v-progress-linear>

        <v-divider></v-divider>
        <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">
        </v-toolbar>
      </v-card>
      <v-card
        class="elevation-0 rounded-0 ma-3 mt-0 mb-0"
        style="border: solid 1px #e0e0e0"
      >
        <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0" dense>
          <v-toolbar-title>
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  disabled
                  style="border: solid 1px #e0e0e0"
                  small
                  class="rounded-0 pa-1 elevation-0 ma-0"
                  color="#ffff"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ kFormatter(itemsPerPageFolders)
                  }}<v-icon small style="margin-left: 0.2em"
                    >mdi-unfold-more-horizontal</v-icon
                  >
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="itemsPerPageFolders = 10"
                  ><v-list-item-title>10</v-list-item-title></v-list-item
                >
                <v-list-item @click="itemsPerPageFolders = 25"
                  ><v-list-item-title>25</v-list-item-title></v-list-item
                >
                <v-list-item @click="itemsPerPageFolders = 50"
                  ><v-list-item-title>50</v-list-item-title></v-list-item
                >
                <v-list-item @click="itemsPerPageFolders = 100"
                  ><v-list-item-title>100</v-list-item-title></v-list-item
                >
              </v-list>
            </v-menu></v-toolbar-title
          >
          <v-spacer style="width: 67%"></v-spacer>
          <v-text-field
            disabled
            style="border: solid 1px #e0e0e0"
            width="40"
            v-model="searchFolder"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            solo
            clearable
            class="elevation-0 rounded-0"
          ></v-text-field>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card class="elevation-0 rounded-0">
          <v-data-table
            :items="loadinglabel"
            class="elevation-0 imagesnp"
            :items-per-page="itemsPerPageFolders"
            :headers="headersFolder"
            hide-default-footer
            hide-default-header
            :page.sync="pageFolders"
            @page-count="pageCountFolders = $event"
          >
            <template v-slot:item="row">
              <tr style="border-bottom: none">
                <td
                  style="
                    padding: 0em;
                    background-color: #ffff;
                    border-bottom: none;
                  "
                >
                  <v-card
                    :color="'#fff'"
                    class="elevation-0 rounded-0 pa-2"
                    :style="loading ? 'cursor: context-menu;' : ''"
                  >
                    <v-icon :color="'#D8D8D8'" x-large>{{
                      folderSelect == row.item.label_search
                        ? "mdi-folder-outline"
                        : "mdi-folder"
                    }}</v-icon>
                  </v-card>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">
          </v-toolbar>
        </v-card>
      </v-card>
    </v-card>
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";
export default {
  data() {
    return {
      loadinglabel: [
        {
          0: "2",
          1: "0",
          2: "2",
          3: "3",
          4: "-",
          5: "0",
          6: "4",
          7: "-",
          8: "2",
          9: "3",
          label_search: "00-00-00",
        },
        {
          0: "2",
          1: "0",
          2: "2",
          3: "3",
          4: "-",
          5: "0",
          6: "4",
          7: "-",
          8: "2",
          9: "2",
          label_search: "00-00-00",
        },
        {
          0: "2",
          1: "0",
          2: "2",
          3: "3",
          4: "-",
          5: "0",
          6: "4",
          7: "-",
          8: "2",
          9: "1",
          label_search: "00-00-00",
        },
        {
          0: "2",
          1: "0",
          2: "2",
          3: "3",
          4: "-",
          5: "0",
          6: "4",
          7: "-",
          8: "2",
          9: "0",
          label_search: "00-00-00",
        },
        {
          0: "2",
          1: "0",
          2: "2",
          3: "3",
          4: "-",
          5: "0",
          6: "4",
          7: "-",
          8: "1",
          9: "9",
          label_search: "00-00-00",
        },
        {
          0: "2",
          1: "0",
          2: "2",
          3: "3",
          4: "-",
          5: "0",
          6: "4",
          7: "-",
          8: "1",
          9: "8",
          label_search: "00-00-00",
        },
        {
          0: "2",
          1: "0",
          2: "2",
          3: "3",
          4: "-",
          5: "0",
          6: "4",
          7: "-",
          8: "1",
          9: "7",
          label_search: "00-00-00",
        },
        {
          0: "2",
          1: "0",
          2: "2",
          3: "3",
          4: "-",
          5: "0",
          6: "4",
          7: "-",
          8: "1",
          9: "4",
          label_search: "00-00-00",
        },
        {
          0: "2",
          1: "0",
          2: "2",
          3: "3",
          4: "-",
          5: "0",
          6: "4",
          7: "-",
          8: "1",
          9: "3",
          label_search: "00-00-00",
        },
        {
          0: "2",
          1: "0",
          2: "2",
          3: "3",
          4: "-",
          5: "0",
          6: "4",
          7: "-",
          8: "1",
          9: "2",
          label_search: "00-00-00",
        },
        {
          0: "2",
          1: "0",
          2: "2",
          3: "3",
          4: "-",
          5: "0",
          6: "4",
          7: "-",
          8: "1",
          9: "1",
          label_search: "00-00-00",
        },
        {
          0: "2",
          1: "0",
          2: "2",
          3: "3",
          4: "-",
          5: "0",
          6: "4",
          7: "-",
          8: "1",
          9: "0",
          label_search: "00-00-00",
        },
        {
          0: "2",
          1: "0",
          2: "2",
          3: "3",
          4: "-",
          5: "0",
          6: "4",
          7: "-",
          8: "0",
          9: "9",
          label_search: "00-00-00",
        },
      ],
      searchFolder: "",
      itemsPerPage: 10,
      showpictonclick: null,
      page: 1,
      pageCount: 0,
      itemsPerPageImage: 25,
      pageImage: 1,
      pageCountImage: 0,
      itemsPerPageFolders: 25,
      pageFolders: 1,
      pageCountFolders: 0,
      pageSize: 1,
      loadernavplus: require("@/assets/img/load100new.gif"),
      folderclose: require("@/assets/img/close-folder.png"),
      folderloading: require("@/assets/img/loading-folder.png"),
      foldercloseagain: require("@/assets/img/close-folder.png"),
      folderopen: require("@/assets/img/open-folder.png"),
      headersFolder: [{ text: "Company", value: "", sortable: false }],
    };
  },
  methods: {
    ...mapActions([
      "closeShow",
      "fetchSnapshot",
      "snapshotFolder",
      "FolderClick",
      "vesselDataSet",
      "FilterClick",
      "addsnapshot_executed",
    ]),

    async onFolderClick(id, label) {
      $("html, body").animate(
        {
          scrollTop: $("#title_map_snapshot").offset().top - 108,
        },
        800
      );
      this.folderOnSelect = null;
      const dataimg = {
        id: id,
        label: label,
        token: localStorage.getItem("token"),
      };
      console.log(label);
      console.log(this.vesselData.id);
      this.FolderClick(dataimg);
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
  },

  mounted() {
    var mama = this;
    if (!mama.snapshot_executed) {
      //console.log("jalan title map");
      mama.addsnapshot_executed(true);
      $("html, body").animate(
        {
          scrollTop: $("#title_map_snapshot").offset().top - 108,
        },
        800
      );
    } else {
      ////console.log("Muncul"), //console.log(mama.sailink_executed);
    }
  },

  computed: {
    ...mapGetters([
      "folderSelect",
      "filterList",
      "snapshotData",
      "snapshotpict",
      "snapshotfolder",
      "loadingimage",
      "loadingfolder",
      "vesselData",
      "snapshot_executed",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.margintab {
  margin-top: -25px;
}

.bxfile {
  font-size: 40px;
}

.imgsnapshot {
  width: 150px;
  margin: 10px;
}

.datasnapshot {
  top: 70px;
}

.image th {
  background-color: #0479f5;
  color: #fff;
}

.image {
  background-color: #ffff;
}

.image tbody {
  display: flex;
  white-space: initial;
  flex-wrap: wrap;
  text-align: center;
  width: 104vw;
  padding: 1em 1em 0.5em 0.5em;
}
</style>
