import axios from "../../plugins/axios";

const state = {
    user_pass: null,
    outsidelogin_:null,
};

const getters = {
    userPass: (state) => state.user_pass,
    outsidelogin_:(state) => state.outsidelogin_

};

const actions = {
    async fetchUserPass({commit}) {
        const response = await axios.get("/api/v1/navplus/user/edit/pass/" + localStorage.getItem("id"), {
            headers: {
                Authorization: "Bearer" + localStorage.getItem("token"),
            },
        });
        //console.log(response.data.data)
        commit("setUserPass", response.data.data);
    },

    async fetchOutside_({commit},data){
        console.log('MASUK OUTSIDE_')
        const response = await axios.get("/api/login_by_id/", {
            headers: {
              // Authorization: "Bearer" + localStorage.getItem("token"),
              Authorization: "Bearer" + data,
            },
          });
            console.log(response)
            console.log(commit)
    },

    addoutsidelogin_({commit},data){
        commit("setoutsidelogin_",data)
    }
};

const mutations = {
    setUserPass: (state, userpass) => (state.user_pass = userpass),
    setoutsidelogin_: (state, outsidelogin_) => (state.outsidelogin_ = outsidelogin_)
};

export default {
    state,
    getters,
    actions,
    mutations,
};
