<template>
  <div style="background-color: #f7f7f7;">
    <v-container style="margin-top:1em;margin-bottom: 1em;">
      <v-row style="text-align: left">
        <strong>Privacy Policy</strong>
        <br />
        PT Skyreach built the SAILINK Navigator+ or Mobile Navplus app as a
        Commercial app. This SERVICE is provided by PT Skyreach and is intended
        for use as is.
        <br />
        <br />
        This page is used to inform visitors regarding our policies with the
        collection, use, and disclosure of Personal Information if anyone
        decided to use our Service.
        <br />
        <br />
        If you choose to use our Service, then you agree to the collection and
        use of information in relation to this policy. The Personal Information
        that we collect is used for providing and improving the Service. We will
        not use or share your information with anyone except as described in
        this Privacy Policy.
        <br />
        <br />
        The terms used in this Privacy Policy have the same meanings as in our
        Terms and Conditions, which are accessible at SAILINK Navigator+ or
        Mobile Navplus unless otherwise defined in this Privacy Policy.
      </v-row>
      <v-row style="text-align: left; margin-top: 3em">
        <strong>Information Collection and Use</strong>
        <br />

        For a better experience, while using our Service, we may require you to
        provide us with certain personally identifiable information. The
        information that we request will be retained by us and used as described
        in this privacy policy.
      </v-row>
      <v-row style="text-align: left; margin-top: 3em">
        <strong>Log Data</strong>
        <br />

        We want to inform you that whenever you use our Service, in a case of an error in the app we collect data and information (through third-party products) on your phone called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app when utilizing our Service, the time and date of your use of the Service, and other statistics.
      </v-row>
      <v-row style="text-align: left; margin-top: 3em">
        <strong>Cookies</strong>
        <br />

        Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers. These are sent to your browser from the websites that you visit and are stored on your device's internal memory.
        <br />
        <br />
This Service does not use these “cookies” explicitly. However, the app may use third-party code and libraries that use “cookies” to collect information and improve their services. You have the option to either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose to refuse our cookies, you may not be able to use some portions of this Service.
      </v-row>
      <v-row style="text-align: left; margin-top: 3em;display:block">
        <strong>Service Providers</strong>
        <br />
        <br/>
       

We may employ third-party companies and individuals due to the following reasons:
<br/>
<br/>

<ul>
      <li>To facilitate our Service;</li>
      <li>To provide the Service on our behalf;</li>
      <li>To perform Service-related services; or</li>
      <li>To assist us in analyzing how our Service is used.</li>

    </ul>
<br/>




We want to inform users of this Service that these third parties have access to their Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped></style>
