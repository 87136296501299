<template>
  <v-card class="ma-3 elevation-0 rounded-0" style="border: solid 1px #E0E0E0;">
   
    <v-toolbar
      elevation="0"
      style="max-height: 70px"
      class="pa-0"
      color="#F7F7F7"
      fixed
      dense
    >
     
      
    </v-toolbar>
    <v-data-table
      style="margin-top: 0px"
      :items="EmptyData"
      hide-default-footer
      :page.sync="page"
      :items-per-page="itemsPerPage"
      class="elevation-0 rounded-0 "
      @page-count="pageCount = $event"
    >
      <template v-slot:item="row">
        <tr class="pa-0">
          <v-container
            color="#ffff"
            fill-height
            fluid
            style="height: 9em; margin-top: 0"
          >
            <v-row align="center" justify="center">
              <v-col
                ><img
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
              /></v-col>
            </v-row>
          </v-container>
          {{
            row.item.info
          }}
        </tr>
      </template>
    </v-data-table>
    <v-progress-linear
  
    indeterminate
    :color="'primary'"
  ></v-progress-linear>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      EmptyData: [{ index: 1, info: "" }],
      itemsPerPage: 10,
      pingClicked: null,
      page: 1,
      pageCount: 0,
      loadernavplus: require("@/assets/img/load100new.gif"),
    };
  },
};
</script>

<style lang="scss" scoped></style>
