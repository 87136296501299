<template>
  <div>
    <v-dialog
      class="elevation-0 rounded-0"
      width="500"
      v-model="gpsOpen"
      persistent
    >
      <v-card class="elevation-0 rounded-0">
        <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense>
         GPS Email
                Report Config

          <v-spacer></v-spacer>

          <v-btn small class="elevation-0 rounded-0" icon @click="gpsRemove()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-toolbar style="text-align:left" v-if="gpsData" class="elevation-0 rounded-0" dense>
         {{ gpsData.vessel_number }}

          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider v-if="gpsData"></v-divider>
        <v-container style="margin-top: 0" v-if="!gpsmodal" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>
        <v-card-text v-if="gpsmodal">
          <v-container style="margin-top: 0" v-if="!gpsData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>

        <v-alert
 v-if="gpssend"
  dense
  outlined
  text
  type="success"
  class="elevation-0 rounded-0 mt-4"
>Berhasil Update Konfigurasi GPS Email Report</v-alert>
          <table v-if="gpsData" style=" margin-left:-1em;margin-bottom: 2em;margin-top:-2em">
            <tr>
              <td style="padding: 1em">
                <b>GPS Report</b>
              </td>
              <td style="white-space: nowrap; padding: 0em">
               
                <v-radio-group
                  @change="checkdata('gpsEmailEnabled')"
                  v-model="gpsData.gpsEmailEnabled"
               
                >
                  <v-radio label="Yes" :value="1"></v-radio>
                  <v-radio label="No" :value="0"></v-radio>
                </v-radio-group>
              </td>
            </tr>
          
            <tr>
              <td style=" padding: 1em">
                <b>E-mail Addresses</b>
              </td>
              <td style="white-space: nowrap; padding: 0em; width: 20em">
                
                <v-textarea
                  @input="checkdata('trackingContact')"
                  :disabled="
                    user_allowed.allowed['edit tracking contact'] ? false : true
                  "
                  name="input-7-1"
                  v-model="gpsData.trackingContact"
                  class="elevation-0 rounded-0"
                  outlined
                  auto-grow
                  width="100"
                 
                ></v-textarea>
              </td>
            </tr>
            <tr>
              <td></td>
              <td style="text-align:left">
                **dipisahkan menggunakan spasi E.g. catchreporting@sailink.co.id
                skyreach@sailink.co.id
              </td>
            </tr>
          </table>
          <v-btn
            :disabled="changes_ ? false : true"
            :style="
              user_allowed.allowed['edit tracking contact']
                ? 'cursor:pointer'
                : 'cursor:not-allowed'
            "
            @click="user_allowed.allowed['edit tracking contact'] ? gpsDataPost() : console.log('cant')"
            small
            class="rounded-0 elevation-0"
            :color="
              user_allowed.allowed['edit tracking contact']
                ? 'primary'
                : '#7694B6'
            "
            block
          >
            Update GPS Reports
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "../../plugins/axios";
import $ from "jquery";
export default {
  data: () => ({
    gpsmodal: null,
    change: null,
    GpsReport: null,

    loadernavplus: require("@/assets/img/load100new.gif"),
  }),
  mounted() {
    this.gpsFetchStatic();
  },
  methods: {
    ...mapActions(["gpsRemove","gpsPost"]),
    checkdata(statis) {
      if (this.gpsmodal[statis] == this.gpsData[statis]) {
        this.change = false;
      } else {
        this.change = true;
      }
    },

    async gpsDataPost() {
      this.change = false;
      const data = {
        id:this.gpsID,
        token:localStorage.getItem("token"),
        trackingContact : this.gpsData.trackingContact,
        gpsEmailEnabled : this.gpsData.gpsEmailEnabled
      };
      this.gpsPost(data);
      var mother=this;
      setTimeout(function () {
      
        mother.gpsFetchStatic();
      }, 1000);
     
    },

    async gpsFetchStatic() {
      try {
        const response = await axios.get(
          "/api/v1/navplus/thuraya/" + this.gpsID + "/email",
          {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
            },
          }
        );
        this.gpsmodal = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
  },
  computed: {
    ...mapGetters(["gpsOpen", "gpsData", "gpsID", "user_allowed","gpssend"]),
    changes_() {
      return this.change;
    },
  },
};
</script>

<style lang="scss" scoped></style>
