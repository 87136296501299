<template>
  <v-app>
    <v-dialog
      class="elevation-0 rounded-0"
      v-model="crownerOpen"
      width="70vh"
      persistent
    >
      <v-card class="elevation-0 rounded-0">
        <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense >
          <v-col class="header_modal">
            <v-row class="notifquota"
              ><v-toolbar-title>Create Owner</v-toolbar-title></v-row
            >
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            small
            class="elevation-0 rounded-0"
            icon
            
            @click="close_createowner()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card-text style="margin-top: 30px">
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-text-field
              @change="at_change()"
              :rules="nameRules"
              label="Name"
              v-model="ownerData.name"
              outlined
              dense
              class="rounded-0 pa-1"
            ></v-text-field>
            <v-text-field
              @change="at_change()"
              :rules="emailRules"
              label="Email Address"
              v-model="ownerData.mail"
              outlined
              dense
              class="rounded-0 pa-1"
            ></v-text-field>

            <v-card
              style="border: 1px solid #9e9e9e"
              height="fit-content"
              class="elevation-0 rounded-0 pa-0 pb-0 mb-5"
            >
              <v-toolbar
                class="elevation-0 rounded-0"
                height="30"
                dense
                color="#F7F7F7"
               
              >
                <v-toolbar-subtitle>Sailink Company</v-toolbar-subtitle>
              </v-toolbar>
              <v-divider></v-divider>
              <v-select
                @change="at_change()"
                class="elevation-0 rounded-0 pa-0 ma-2 mb-0"
                v-if="!crownerData"
                item-text="name"
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                label="Loading company..."
                multiple
                persistent-hint
                style="justify-content: left; text-align: left"
              ></v-select>
              <v-select
                @change="at_change()"
                class="elevation-0 rounded-0 pa-0 ma-2 mb-0"
                v-if="crownerData"
                v-model="ownerData.pts"
                :items="crownerData.pts"
                :value="items"
                item-text="name"
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                label="Select one or more company"
                multiple
                persistent-hint
                style="justify-content: left; text-align: left"
              ></v-select>
            </v-card>
            <v-text-field
              @change="at_change()"
              :append-icon="confirshow ? 'mdi-eye' : 'mdi-eye-off'"
              :type="confirshow ? 'text' : 'password'"
              @click:append="confirshow = !confirshow"
              label="Password"
              v-model="ownerData.pass"
              :rules="passwordRules"
              class="elevation-0 rounded-0"
              outlined
              dense
            ></v-text-field>
            <v-text-field
              @change="at_change()"
              :append-icon="confirshow_cr ? 'mdi-eye' : 'mdi-eye-off'"
              :type="confirshow_cr ? 'text' : 'password'"
              @click:append="confirshow_cr = !confirshow_cr"
              label="Confirm Password"
              v-model="ownerData.confirmpass"
              :rules="confirmPasswordRules"
              outlined
              dense
              class="elevation-0 rounded-0"
            ></v-text-field>
          </v-form>
        </v-card-text>

        <v-card-actions style="margin-top: -10px">
          <v-btn
            v-if="!change_"
            style="cursor: not-allowed"
            small
            class="elevation-0 rounded-0"
            dark
            color="#9CBDDE"
            block
          >
            Create
          </v-btn>
          <v-btn
            v-if="change_"
            @click="!valid ? console.log('invalid') : (crowner_confirm = true)"
            :style="!valid ? 'cursor:not-allowed' : 'cursor:pointer'"
            small
            class="elevation-0 rounded-0"
            dark
            :color="!valid ? '#9CBDDE' : 'primary'"
            block
          >
            Create
          </v-btn>
        </v-card-actions>

        <v-card-actions class="justify-end"> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      class="rounded-0 elevation-0"
      v-model="crowner_confirm"
      persistent
      max-width="290"
    >
      <v-card class="rounded-0 elevation-0">
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text style="text-align: left"
          >New owner will created after you click yes</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="crowner_confirm = false"> No </v-btn>
          <v-btn
            color="#174A8A"
            text
            @click="createOwnerPost(), close_createowner()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      crowner_confirm: false,
      confirshow: false,
      confirshow_cr: false,
      change_: null,
      valid: null,
      ownermail: "",
      labelSelectOwner: "Ship Owner",
      nameRules: [
        (v) => !!v || "Name is required",
        (v) => (v && v.length > 1) || "Name must be more than 1 characters",
        (v) => (v && v.length <= 30) || "Name must be less than 40 characters",
      ],
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      passwordRules: [
        (v) => !!v || "Please type password.",
        (v) => (v && v.length >= 8) || "minimum 8 characters",
      ],
      confirmPasswordRules: [
        (v) => !!v || "type confirm password",
        (v) =>
          v === this.ownerData.pass ||
          "The password confirmation does not match.",
      ],
    };
  },

  methods: {
    ...mapActions([
      "postCreateOwner",
      "set_sailink_Vessel_",
      "removecruserOpen",
      "fetchAdmin",
      "removecrownerOpen",
    ]),

    reset() {
      this.$refs.form.reset();
    },

    at_change() {
      if (
        this.ownerData.name &&
        this.ownerData.mail &&
        this.ownerData.pass &&
        this.ownerData.confirmpass &&
        this.ownerData.pts
      ) {
        this.change_ = true;
      } else {
        this.change_ = null;
      }
    },

    close_createowner() {
      this.removecrownerOpen();
      this.reset();
      this.change_ = null;
      this.crowner_confirm = false;
    },

    async createOwnerPost() {
      const data = {
        name: this.ownerData.name,
        email: this.ownerData.mail,
        password: this.ownerData.pass,
        role: "3",
        pts: this.ownerData.pts,
        password_confirmation: this.ownerData.confirmpass,
        token: localStorage.getItem("token"),
      };
      console.log(data);
      this.postCreateOwner(data);
      var mother = this;
      setTimeout(function () {
        mother.fetchAdmin(localStorage.getItem("token"));
      }, 5000);
    },

    sendOwnerData_(email, name, vessel) {
      this.ownermail = email;
      this.labelSelectOwner = name;
      this.set_sailink_Vessel_(vessel);
    },
  },
  computed: {
    ...mapGetters([
      "ownerData",
      "crownerData",
      "crownerOpen",
      "sailinkvessel_",
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
