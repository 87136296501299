<template>
  <v-card id="card_map_sailink" class="elevation-0 mt-0 rounded-0">
    <v-card id="title_map_sailink" class="container_showonmap ma-3 elevation-0 rounded-0 mt-0 pb-3"
      style="border: solid 1px #e0e0e0" v-if="showmaptable">
      <v-toolbar color="#F7F7F7" elevation="0" dense>
        {{ showmaptable.vessel_pt }}
        <v-spacer></v-spacer>

        <v-btn small class="elevation-0 rounded-0" icon @click="closeShowMap()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>

      <v-card class="flexposisi pa-3 rounded-0" height="fit-content" elevation="0" style="margin-top: 0">
        <v-card class="datacard elevation-0 rounded-0" color="#F7F7F7" elevation="0"
          style="border: solid 1px #e0e0e0;text-align:left">
          <v-simple-table class="elevation-0 rounded-0" dense>
            <template v-slot:default>
              <tbody>
                
                <tr>
                  <td class="pl-3">Type Terminal</td>
                  <td>{{ showmaptable.device.terminal }}</td>
                </tr>
                <tr>
                  <td class="pl-3">IP Address</td>
                  <td v-if="!ip_adr">Loading...</td>
                  <td v-if="ip_adr">
                    {{ ip_adr.ip_addr }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-3">Link Status</td>
                  <td v-if="!ln_status">
                    <v-chip style="cursor: pointer" small color="#1976D2" text-color="white">Updating..</v-chip>
                  </td>
                  <td v-if="ln_status">
                    <v-chip v-if="ln_status.status" @click="lnStatus(showmaptable.id)" style="cursor: pointer" small
                      :color="ln_status.status == 'UP' ? '#38C072' : 'red'" text-color="white"><v-icon
                        x-small>mdi-refresh</v-icon>&nbsp;{{
                          ln_status.status
                        }}</v-chip>
                  </td>
                </tr>
                <tr v-if="!ip_adr">
                  <td class="pl-3">SatNet</td>
                  <td>{{showmaptable.message.satnet}}</td>
                </tr>
                <tr v-if="ip_adr">
                  <td class="pl-3">SatNet</td>
                  <td  >{{!ip_adr.satnetAllowed ? showmaptable.message.satnet : '' }}<v-row  v-if="ip_adr.satnetAllowed" style="height:4em" no-gutters><v-col cols="8" class="pt-4 pl-1" >{{ showmaptable.message.satnet }}</v-col><v-col cols="4" class="pt-2 pr-2"><v-text-field
                    :append-icon="'mdi-sync-circle'"
            @click:append="switch_satnet_modal=true"
           v-model="satnet_id"
     dense
     class="elevation-0 rounded-0"
            outlined
          ></v-text-field></v-col></v-row></td>
                </tr>
                <tr>
                  <td class="pl-3">RX SNR</td>
                  <td>{{ showmaptable.message.rx_snr }}</td>
                </tr>
                <tr>
                  <td class="pl-3">TX Power</td>
                  <td>{{ showmaptable.message.tx_power }}</td>
                </tr>
                <tr>
                  <td class="pl-3">MODCOD</td>
                  <td>{{ showmaptable.message.modcod }}</td>
                </tr>
                <tr v-if="showmaptable.ip_phones.length != 0">
                  <td class="pl-3">PSTN/MobilePhone</td>
                  <td>
                    <ul style="display: block; margin-left: -1.8em">
                      <li v-for="phones in showmaptable.ip_phones" :key="phones">
                        +{{ phones.number }} ({{ phones.provider.name }})
                      </li>
                    </ul>
                  </td>
                </tr>

                <tr>
                  <td class="pl-3">Latitude, Longtitude</td>
                  <td>{{ showmaptable.message.latlong }}</td>
                </tr>
                <tr>
                  <td class="pl-3">DMS</td>
                  <td>{{ showmaptable.message.dms }}</td>
                </tr>
                <tr>
                  <td class="pl-3">Last Updated</td>
                  <td>{{ showmaptable.message.last_up }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-simple-table class="elevation-0 rounded-0" dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td colspan="2" class="pa-3 pl-3">
                    <v-chip v-if="showmaptable.band.service_name" class="rounded-0" color="#1976D2" small outlined
                      label>{{ showmaptable.band.service_name }}</v-chip>
                  </td>
                </tr>
                <tr v-if="showmaptable.band.service_name &&
                  showmaptable.band.service_name != 'Services : UNMETERED'
                  ">
                  <td class="pl-3">
                    <v-chip class="rounded-0 mr-2" @click="fetchQdialy('metered')" small label style="cursor: pointer"
                      text-color="white" color="primary"><v-icon small>mdi-chart-bar</v-icon></v-chip><b>Metered</b>
                  </td>
                  <td></td>
                </tr>
                <tr v-if="showmaptable.band.service_name &&
                  showmaptable.band.service_name != 'Services : UNMETERED'
                  ">
                  <td class="pl-3">Quota</td>
                  <td>{{ showmaptable.band.quota }}</td>
                </tr>
                <tr v-if="showmaptable.band.service_name &&
                  showmaptable.band.service_name != 'Services : UNMETERED'
                  ">
                  <td class="pl-3">Quota Used</td>
                  <td style="padding-right: 7em">
                    <row>
                      <p style="font-size: 10px; margin-bottom: 0px">
                        {{ showmaptable.band.used }} ({{
                          showmaptable.band.percentage
                        }}
                        %)
                      </p>
                      <v-progress-linear height="10" :value="showmaptable.band.percentage"
                        color="primary"></v-progress-linear>
                    </row>
                  </td>
                </tr>
                <tr v-if="showmaptable.band.service_name &&
                  showmaptable.band.service_name != 'Services : UNMETERED'
                  ">
                  <td class="pl-3">Upload</td>
                  <td>{{ showmaptable.band.m_upload }}</td>
                </tr>
                <tr v-if="showmaptable.band.service_name &&
                  showmaptable.band.service_name != 'Services : UNMETERED'
                  ">
                  <td class="pl-3">Download</td>
                  <td>{{ showmaptable.band.m_download }}</td>
                </tr>
                <tr v-if="showmaptable.band.service_name &&
                  showmaptable.band.service_name != 'Services : METERED'
                  ">
                  <td class="pl-3">
                    <v-chip @click="fetchQdialy('unmetered')" class="rounded-0 mr-2" small label style="cursor: pointer"
                      text-color="white" color="primary"><v-icon small>mdi-chart-bar</v-icon></v-chip><b>Unmetered</b>
                  </td>
                  <td></td>
                </tr>
                <tr v-if="showmaptable.band.service_name &&
                  showmaptable.band.service_name != 'Services : METERED'
                  ">
                  <td class="pl-3">Upload</td>
                  <td>{{ showmaptable.band.um_upload }}</td>
                </tr>
                <tr v-if="showmaptable.band.service_name &&
                  showmaptable.band.service_name != 'Services : METERED'
                  ">
                  <td class="pl-3">Download</td>
                  <td>{{ showmaptable.band.um_download }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider></v-divider>
          <v-card color="#f7f7f7" class="elevation-0 rounded-0 pa-3">
            <v-card flat width="100%" class=" elevation-0 rounded-0  overflow-y-auto  " color="#f7f7f7" height="200">

              <div class="pswp-gallery pswp-gallery--single-column" id="sailink-gallery-container">
                <a v-for="(gambar, index) in showmaptable.images" :key="index" :href="gambar[0]"
                  :data-pswp-width="gambar[1]" :data-pswp-height="gambar[2]" :data-cropped="true" target="_blank"><img
                    :src="gambar[0]" class="vsl_img" alt="Vessel Image" /></a>
              </div>

            </v-card>
          </v-card>





        </v-card>

        <v-card color="#F7F7F7" id="mapcard" class="mapcard rounded-0" elevation="0" style="border: solid 1px #e0e0e0">
          <v-toolbar color="#F7F7F7" class="rounded-0 toolbar_sailinkdesktop" dense elevation="0">
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0" color="#fff" v-bind="attrs"
                  v-on="on" small elevation="0">
                  <v-icon small>mdi-update</v-icon>
                  &nbsp;
                  <div v-if="!showmaptracking">Loading...</div>
                  <div v-if="showmaptracking">
                    {{ showmaptracking.range.label }}
                  </div>
                  &nbsp;
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, 'today')">Today</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, '24')">Last 24 Hour</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, '7')">Last 7 Days</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, '30')">Last 30 Days</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, 'week')">This Week</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, 'month')">This Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, 'lmonth')">Last Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="(dateopen = true), (datepickerID = showmaptable.id)">Custom
                    Range</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>

            <v-chip v-if="!showmaptracking" style="border: solid 1px #e0e0e0" color="#ffff" class="rounded-0"
              label><v-icon small class="mr-1">mdi-calendar</v-icon>- - -</v-chip>
            <v-chip style="border: solid 1px #e0e0e0" color="#ffff" class="rounded-0" v-if="showmaptracking" label><v-icon
                small class="mr-1">mdi-calendar</v-icon>{{ showmaptracking.range.actual }}</v-chip>
          </v-toolbar>
          <v-card color="#F7F7F7" class="elevation-0 rounded-0 toolbar_sailinkmobile" elevation="0">
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn block style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0" color="#fff" v-bind="attrs"
                  v-on="on" small elevation="0">
                  <v-icon small>mdi-update</v-icon>
                  &nbsp;
                  <div v-if="!showmaptracking">Loading...</div>
                  <div v-if="showmaptracking">
                    {{ showmaptracking.range.label }}
                  </div>
                  &nbsp;
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, 'today')">Today</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, '24')">Last 24 Hour</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, '7')">Last 7 Day</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, '30')">Last 30 Day</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, 'week')">This Week</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, 'month')">This Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaptable.id, 'lmonth')">Last Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="(dateopen = true), (datepickerID = showmaptable.id)">Custom
                    Range</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>


          </v-card>

          <v-divider></v-divider>
          <v-container v-if="!showmaptracking" id="map_sailink" fill-height fluid
            style="margin-top: 0; height: 35em; background-color: #aad3df">
            <v-row align="center" justify="center">
              <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
            </v-row>
          </v-container>

          <l-map :use-global-leaflet="true" id="map_sailink_" v-if="showmaptracking" @fullscreenchange="triggerFullscreen"
            @polylinemeasure:move="onCount_move" @polylinemeasure:add="onCount" @polylinemeasure:remove="onCount"
            @polylinemeasure:toggle="handleToggle" @polylinemeasure:finish="handleFinish" style="height: 40em" zoom="7"
            ref="map_sailink" :center="[
              parseFloat(showmaptracking.maptop.lat),
              parseFloat(showmaptracking.maptop.lon),
            ]" :options="{
  minZoom: 3,
  zoomControl: false,
  doubleClickZoom: true,
  scrollWheelZoom: false,
}">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-control-zoom position="bottomright"></l-control-zoom>
            <l-control-fullscreen position="bottomright" :options="{ title: { false: 'Go big!', true: 'Be regular' } }" />
            <l-control-polyline-measure v-if="etaControl" :ref="'polylineMeasure'" :options="{
              showUnitControl: false,
              showClearControl: false,
              showBearings: true,
              unit: unit,
              clearMeasurementsOnStop: false,
              measureControlClasses: ['hideControl'], // Classes to apply to the Measure Control
            }" v-model="measurepoly" position="bottomright" />

            <l-moving-rotated-marker v-if="drivePath" ref="driveMarker" :lat-lng="driveLatLng"
              :rotationAngle="driveRotationAngle" :duration="3000" :icon="icon" />

            <v-rotated-marker :lat-lng="[
              parseFloat(showmaptracking.maptop.lat),
              parseFloat(showmaptracking.maptop.lon),
            ]" :rotationAngle="parseInt(showmaptracking.maptop.heading)">
              <l-icon :icon-size="[40 * 0.30, 100 * 0.30]" :icon-anchor="[40 * 0.30 / 2, 100 * 0.30 / 2]"
                class-name="someExtraClass">
                <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 100">
                  <path :fill="showmaptracking.maptop.fill" stroke="#FFF" stroke-width="10"
                    d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" />
                </svg>
              </l-icon>
              <l-popup @ready="draw_Circle()">
                <table>
                  <tr>
                    <td><b>Latitude</b></td>
                    <td>:</td>
                    <td>
                      {{ showmaptracking.maptop.lat }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Longitude</b></td>
                    <td>:</td>
                    <td>{{ showmaptracking.maptop.lon }}</td>
                  </tr>
                  <tr>
                    <td><b>DMS</b></td>
                    <td>:</td>
                    <td>{{ showmaptracking.maptop.dms }}</td>
                  </tr>
                  <tr>
                    <td><b>Speed</b></td>
                    <td>:</td>
                    <td>
                      {{ showmaptracking.maptop.speed + "&nbsp;knots" }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Heading</b></td>
                    <td>:</td>
                    <td>{{ showmaptracking.maptop.heading + "&deg;" }}</td>
                  </tr>
                  <tr>
                    <td><b>Last Update</b></td>
                    <td>:</td>
                    <td>{{ showmaptracking.maptop.lastUp }}</td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <td><b>SatNet</b></td>
                    <td><b>:</b></td>
                    <td>
                      <b>{{ showmaptracking.maptop.satnet }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>EIRP</td>
                    <td>:</td>
                    <td>{{ showmaptracking.maptop.satnet }}</td>
                  </tr>
                  <tr>
                    <td>G/T+</td>
                    <td>:</td>
                    <td>{{ showmaptracking.maptop.satnet }}</td>
                  </tr>
                </table>
              </l-popup>
            </v-rotated-marker>



            <!-- <l-control position="topleft" style="margin-left: -0.2em">
   
    </l-control> -->

            <!--EIRP-->
            <div v-if="SKYR1 && EIRP">
              <l-polygon :key="sky1" v-for="(m, sky1) in paths_cns11" :lat-lngs="m" :color="polygon.skyr1" :weight="0.8"
                :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.skyr1">
                <l-popup @change="console.log('OKAY')">
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="
                          height: 25px;
                          margin-top: 20px;
                          margin-right: 10px;
                        " />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Skyreach1</strong><br />
                        <strong>Level: {{ gain_cns11[sky1] }}</strong><br />
                        <strong>[{{ sky1 }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>
            </div>

            <div v-if="SKYR2 && EIRP">
              <l-polygon :key="sky2" v-for="(m, sky2) in paths_apstar5" :lat-lngs="m" :color="polygon.skyr2" :weight="0.8"
                :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.skyr2">
                <l-popup @change="console.log('OKAY')">
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="
                          height: 25px;
                          margin-top: 20px;
                          margin-right: 10px;
                        " />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Skyreach2</strong><br />
                        <strong>Level: {{ gain_apstar5[sky2] }}</strong><br />
                        <strong>[{{ sky2 }}]</strong>
                      </div>
                    </div>
                    <!-- <div style="text-align:left;margin-top:20px;background-color:#EAE9FC;padding:4px;padding-bottom:0px;border-radius:3px">
    
    <p>Lat,lon 
    </p>
    </div> -->
                  </div>
                </l-popup>
              </l-polygon>
            </div>
            <div v-if="APST9 && EIRP">
              <l-polygon :key="apst9" v-for="(m, apst9) in paths_apstar9" :lat-lngs="m" :color="polygon.apst9"
                :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.apst9">
                <l-popup @change="console.log('OKAY')">
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="
                          height: 25px;
                          margin-top: 20px;
                          margin-right: 10px;
                        " />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Apstar 9</strong><br />
                        <strong>Level: {{ gain_apstar9[apst9] }}</strong><br />
                        <strong>[{{ apst9 }}]</strong>
                      </div>
                    </div>
                    <!-- <div style="text-align:left;margin-top:20px;background-color:#EAE9FC;padding:4px;padding-bottom:0px;border-radius:3px">
    
    <p>Lat,lon 
    </p>
    </div> -->
                  </div>
                </l-popup>
              </l-polygon>
            </div>
            <div v-if="ABS2A && EIRP">
              <l-polygon :key="abs" v-for="(m, abs) in paths_abs" :lat-lngs="m" :color="polygon.abs2a" :weight="0.8"
                :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.abs2a">
                <l-popup>
                  <div id="content1">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="
                          height: 25px;
                          margin-top: 20px;
                          margin-right: 10px;
                        " />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Abs 2A</strong><br />
                        <strong>Level: {{ gain_abs[abs] }}</strong><br />
                        <strong>[{{ abs }}]</strong>
                      </div>
                    </div>
                    <!-- <div style="text-align:left;margin-top:20px;background-color:#EAE9FC;padding:4px;padding-bottom:0px;border-radius:3px">
    
    <p>Lat,lon 
    </p>
    </div> -->
                  </div>
                </l-popup>
              </l-polygon>
            </div>

            <div v-if="SR1MR && EIRP">
              <l-polygon :key="ocn" v-for="(m, ocn) in paths_ocn" :lat-lngs="m" :color="polygon.sr1mr" :weight="0.8"
                :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.sr1mr">
                <l-popup @change="console.log('OKAY')">
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="
                          height: 25px;
                          margin-top: 20px;
                          margin-right: 10px;
                        " />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : SR1 Maritime</strong><br />
                        <strong>Level: {{ gain_ocn[ocn] }}</strong><br />
                        <strong>[{{ ocn }}]</strong>
                      </div>
                    </div>
                    <!-- <div style="text-align:left;margin-top:20px;background-color:#EAE9FC;padding:4px;padding-bottom:0px;border-radius:3px">
    
    <p>Lat,lon 
    </p>
    </div> -->
                  </div>
                </l-popup>
              </l-polygon>
            </div>

            <div v-if="SR2MR && EIRP">

              <l-polygon :key="hts" v-for="(m, hts) in paths_hts" :lat-lngs="m" :color="polygon.sr1mr" :weight="0.8"
                :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.sr1mr">
                <l-popup>
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Skyreach_2_HTS_BEAM4</strong><br />
                        <strong>Level: {{ gain_hts[hts] }}</strong><br />
                        <strong>[{{ hts }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>
            </div>

            <div v-if="BEAM3 && EIRP">

              <l-polygon :key="beam3" v-for="(m, beam3) in paths_beam3" :lat-lngs="m" :color="polygon.beam3" :weight="0.8"
                :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.beam3">
                <l-popup>
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Skyreach_2_HTS_BEAM3</strong><br />
                        <strong>Level: {{ gain_beam3[beam3] }}</strong><br />
                        <strong>[{{ beam3 }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>

            </div>

            <div v-if="HRZ && EIRP">

              <l-polygon :key="hrz" v-for="(m, hrz) in paths_hrz" :lat-lngs="m" :color="polygon.hrz" :weight="0.8"
                :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.hrz">
                <l-popup>
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Skyreach_Intelsat_HTS</strong><br />
                        <strong>Level: {{ gain_hrz[hrz] }}</strong><br />
                        <strong>[{{ hrz }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>

            </div>

            <div v-if="UTL70B && EIRP">

              <l-polygon :key="utl" v-for="(m, utl) in paths_utl" :lat-lngs="m" :color="polygon.utl" :weight="0.8"
                :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.utl">
                <l-popup>
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Skyreach_Eutelsat</strong><br />
                        <strong>Level: {{ gain_utl[utl] }}</strong><br />
                        <strong>[{{ utl }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>

            </div>

            <!--GNT-->
            <div v-if="SKYR1 && GNT">
              <l-polygon :key="sky1gnt" v-for="(m, sky1gnt) in paths_cns11gnt" :lat-lngs="m" :color="polygon.skyr1"
                :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.skyr1">
                <l-popup @change="console.log('OKAY')">
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="
                          height: 25px;
                          margin-top: 20px;
                          margin-right: 10px;
                        " />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Skyreach1</strong><br />
                        <strong>Level: {{ gain_cns11gnt[sky1gnt] }}</strong><br />
                        <strong>[{{ sky1gnt }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>
            </div>

            <div v-if="SKYR2 && GNT">
              <l-polygon :key="sky2gnt" v-for="(m, sky2gnt) in paths_apstar5gnt" :lat-lngs="m" :color="polygon.skyr2"
                :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.skyr2">
                <l-popup @change="console.log('OKAY')">
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="
                          height: 25px;
                          margin-top: 20px;
                          margin-right: 10px;
                        " />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Skyreach2</strong><br />
                        <strong>Level: {{ gain_apstar5gnt[sky2gnt] }}</strong><br />
                        <strong>[{{ sky2gnt }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>
            </div>

            <div v-if="APST9 && GNT">
              <l-polygon :key="apst9gnt" v-for="(m, apst9gnt) in paths_apstar9gnt" :lat-lngs="m" :color="polygon.apst9"
                :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.apst9">
                <l-popup @change="console.log('OKAY')">
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="
                          height: 25px;
                          margin-top: 20px;
                          margin-right: 10px;
                        " />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Apstar 9</strong><br />
                        <strong>Level: {{ gain_apstar9gnt[apst9gnt] }}</strong><br />
                        <strong>[{{ apst9gnt }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>
            </div>

            <div v-if="ABS2A && GNT">
              <l-polygon :key="absgnt" v-for="(m, absgnt) in paths_absgnt" :lat-lngs="m" :color="polygon.abs2a"
                :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.abs2a">
                <l-popup @change="console.log('OKAY')">
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="
                          height: 25px;
                          margin-top: 20px;
                          margin-right: 10px;
                        " />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : ABS 2A</strong><br />
                        <strong>Level: {{ gain_absgnt[absgnt] }}</strong><br />
                        <strong>[{{ absgnt }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>
            </div>

            <div v-if="SR1MR && GNT">
              <l-polygon :key="ocngnt" v-for="(m, ocngnt) in paths_ocngnt" :lat-lngs="m" :color="polygon.sr1mr"
                :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.sr1mr">
                <l-popup @change="console.log('OKAY')">
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="
                          height: 25px;
                          margin-top: 20px;
                          margin-right: 10px;
                        " />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : SR1 Maritime</strong><br />
                        <strong>Level: {{ gain_ocngnt[ocngnt] }}</strong><br />
                        <strong>[{{ ocngnt }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>
            </div>

            <div v-if="SR2MR && GNT">
              <l-polygon :key="htsgnt" v-for="(m, htsgnt) in paths_htsgnt" :lat-lngs="m" :color="polygon.sr1mr"
                :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.sr1mr">
                <l-popup>
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Skyreach_2_HTS_BEAM4</strong><br />
                        <strong>Level: {{ gain_htsgnt[htsgnt] }}</strong><br />
                        <strong>[{{ htsgnt }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>
            </div>
            <div v-if="BEAM3 && GNT">
              <l-polygon :key="beam3gnt" v-for="(m, beam3gnt) in paths_beam3gnt" :lat-lngs="m" :color="polygon.beam3"
                :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.beam3">
                <l-popup>
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Skyreach_2_HTS_BEAM3</strong><br />
                        <strong>Level: {{ gain_beam3gnt[beam3gnt] }}</strong><br />
                        <strong>[{{ beam3gnt }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>
            </div>
            <div v-if="HRZ && GNT">
              <l-polygon :key="hrzgnt" v-for="(m, hrzgnt) in paths_hrzgnt" :lat-lngs="m" :color="polygon.hrz"
                :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.hrz">
                <l-popup>
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Skyreach_Intelsat_HTS</strong><br />
                        <strong>Level: {{ gain_hrzgnt[hrzgnt] }}</strong><br />
                        <strong>[{{ hrzgnt }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>
            </div>
            <div v-if="UTL70B && GNT">
              <l-polygon :key="utlgnt" v-for="(m, utlgnt) in paths_utlgnt" :lat-lngs="m" :color="polygon.utl"
                :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.utl">
                <l-popup>
                  <div id="content">
                    <div class="popup_polygon">
                      <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                      <div style="text-align: left; margin-top: 20px">
                        <strong>SatNet : Skyreach_Eutelsat</strong><br />
                        <strong>Level: {{ gain_utlgnt[utlgnt] }}</strong><br />
                        <strong>[{{ utlgnt }}]</strong>
                      </div>
                    </div>
                  </div>
                </l-popup>
              </l-polygon>
            </div>

            <l-control class="lmapbtn_sailinkmobile" position="topright"
              style="display: flex; justify-content: space-between">
              <div class="lmapbtn_sailinkmobile">
                <v-expansion-panels class="elevation-0 rounded-0" style="width: 35vw">
                  <v-expansion-panel v-if="user_allowed.role_id == 2 || user_allowed.role_id == 5
                    " class="elevation-0 rounded-0 pa-0">
                    <v-expansion-panel-header class="elevation-0 rounded-0 pa-1 ma-1"
                      style="height: 2em">SatNet</v-expansion-panel-header>
                    <v-expansion-panel-content class="elevation-0 rounded-0 pa-0" width="20" style="text-align: left">
                      <div class="uper_li" @click="EIRP = !EIRP">
                        <input type="checkbox" v-model="EIRP" value="EIRP" />&nbsp;EIRP
                      </div>
                      <div class="uper_li" @click="GNT = !GNT">
                        <input type="checkbox" v-model="GNT" value="GNT" />&nbsp;G/T+
                      </div>
                      <div class="divider"></div>
                      <div class="bot_li" @click="
                        !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                        (SKYR1 = !SKYR1)
                        ">
                        <input type="checkbox" v-model="SKYR1" value="SKYR1" @change="
                          !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')
                          " />&nbsp;Skyreach 1
                      </div>
                      <div class="bot_li" @click="
                        !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                        (SKYR2 = !SKYR2)
                        ">
                        <input type="checkbox" v-model="SKYR2" value="SKYR2" @change="
                          !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')
                          " />&nbsp;Skyreach 2
                      </div>
                      <div class="bot_li" @click="
                        !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                        (APST9 = !APST9)
                        ">
                        <input type="checkbox" v-model="APST9" value="APST9" @change="
                          !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')
                          " />&nbsp;Apstar 9
                      </div>
                      <div class="bot_li" @click="
                        !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                        (ABS2A = !ABS2A)
                        ">
                        <input type="checkbox" v-model="ABS2A" value="ABS2A" @change="
                          !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')
                          " />&nbsp;ABS 2A
                      </div>
                      <div class="bot_li" @click="
                        !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                        (SR1MR = !SR1MR)
                        ">
                        <input type="checkbox" v-model="SR1MR" value="SR1MR" @change="
                          !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')
                          " />&nbsp;SR1 Maritime
                      </div>
                      <div class="bot_li" @click="
                        !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                        (SR2MR = !SR2MR)
                        ">
                        <input type="checkbox" v-model="SR2MR" value="SR2MR" @change="
                          !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')
                          " />&nbsp;SR2 HTS BEAM#4
                      </div>
                      <div class="bot_li" @click="
                        !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                        (BEAM3 = !BEAM3)
                        ">
                        <input type="checkbox" v-model="BEAM3" value="BEAM3" @change="
                          !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')
                          " />&nbsp;SR2 HTS BEAM#3
                      </div>
                      <div class="bot_li" @click="
                        !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                        (HRZ = !HRZ)
                        ">
                        <input type="checkbox" v-model="HRZ" value="HRZ" @change="
                          !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')
                          " />&nbsp;HORIZON 3E
                      </div>
                      <div class="bot_li" @click="
                        !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                        (UTL70B = !UTL70B)
                        ">
                        <input type="checkbox" v-model="UTL70B" value="UTL70B" @change="
                          !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')
                          " />&nbsp;Utelsat 70B
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
            </l-control>
            <l-control position="topleft" style="display: flex; justify-content: space-between">
              <div class="lmapbtn_sailinkmobile">
                <v-expansion-panels class="elevation-0 rounded-0" style="width: 35vw">
                  <v-expansion-panel v-if="!etaControl">
                    <v-btn style="text-align: left" small color="primary" @click="
                      etaControl ? console.log('Already Open') : openETA()
                      " block class="elevation-0 rounded-0">ETA</v-btn>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <div class="btneta_sailinkdesktop">
                <v-btn v-if="!etaControl" elevation="0" class="rounded-0 pa-1" height="31" width="50" dark
                  :color="etaControl ? '#95BADF' : 'primary'"
                  @click="etaControl ? console.log('Already Open') : openETA()">
                  <v-icon small>mdi-map-marker-distance</v-icon>
                  &nbsp; ETA
                </v-btn>
              </div>
              <div class="satnetbtn_sailinkdesktop" v-if="user_allowed.role_id == 2 || user_allowed.role_id == 5">
                <label class="dropdown">
                  <div class="dd-button">
                    <v-icon>mdi-satellite-variant</v-icon>
                    <v-text style="margin-top: 0.3em">SatNet</v-text>
                    <v-icon>mdi-chevron-down</v-icon>
                  </div>
                  <input type="checkbox" class="dd-input" />

                  <ul class="dd-menu">
                    <li class="uper_li" @click="EIRP = !EIRP">
                      <input type="checkbox" v-model="EIRP" value="EIRP" />&nbsp;EIRP
                    </li>
                    <li class="uper_li" @click="GNT = !GNT">
                      <input type="checkbox" v-model="GNT" value="GNT" />&nbsp;G/T+
                    </li>
                    <li class="divider"></li>
                    <li class="bot_li" @click="
                      !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                      (SKYR1 = !SKYR1)
                      ">
                      <input type="checkbox" v-model="SKYR1" value="SKYR1"
                        @change="!EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')" />&nbsp;Skyreach 1
                    </li>
                    <li class="bot_li" @click="
                      !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                      (SKYR2 = !SKYR2)
                      ">
                      <input type="checkbox" v-model="SKYR2" value="SKYR2"
                        @change="!EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')" />&nbsp;Skyreach 2
                    </li>
                    <li class="bot_li" @click="
                      !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                      (APST9 = !APST9)
                      ">
                      <input type="checkbox" v-model="APST9" value="APST9"
                        @change="!EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')" />&nbsp;Apstar 9
                    </li>
                    <li class="bot_li" @click="
                      !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                      (ABS2A = !ABS2A)
                      ">
                      <input type="checkbox" v-model="ABS2A" value="ABS2A"
                        @change="!EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')" />&nbsp;ABS 2A
                    </li>
                    <li class="bot_li" @click="
                      !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                      (SR1MR = !SR1MR)
                      ">
                      <input type="checkbox" v-model="SR1MR" value="SR1MR"
                        @change="!EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')" />&nbsp;SR1 Maritime
                    </li>
                    <li class="bot_li" @click="
                      !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                      (SR2MR = !SR2MR)
                      ">
                      <input type="checkbox" v-model="SR2MR" value="SR2MR"
                        @change="!EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')" />&nbsp;SR2 HTS BEAM#4
                    </li>
                    <li class="bot_li" @click="
                      !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                      (BEAM3 = !BEAM3)
                      ">
                      <input type="checkbox" v-model="BEAM3" value="BEAM3"
                        @change="!EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')" />&nbsp;SR2 HTS BEAM#3
                    </li>
                    <li class="bot_li" @click="
                      !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                      (HRZ = !HRZ)
                      ">
                      <input type="checkbox" v-model="HRZ" value="HRZ"
                        @change="!EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')" />&nbsp;HORIZON 3E
                    </li>
                    <li class="bot_li" @click="
                      !EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP'),
                      (UTL70B = !UTL70B)
                      ">
                      <input type="checkbox" v-model="UTL70B" value="UTL70B"
                        @change="!EIRP && GNT ? (EIRP = null) : (EIRP = 'EIRP')" />&nbsp;Utelsat 70B
                    </li>
                  </ul>
                </label>
              </div>
            </l-control>
            <l-control position="topright">
              <v-card width="300" class="elevation-0 rounded-0 coordinate_sailinkdesktop" max-height="800"
                style="overflow-y: auto; margin-left: 1em" v-show="showRoute">
                <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7"
                  dense><v-spacer><v-toolbar-title>Coordinates</v-toolbar-title></v-spacer>
                </v-toolbar>

                <v-data-table v-if="!showRouteData" :headers="headersRoute" :items="showRouteEmpty"
                  :items-per-page="itemsPerPageCoordinate" :page.sync="pageCoordinate" hide-default-footer
                  class="elevation-0 routeTable" @page-count="pageCountCoordinate = $event"><template v-slot:item="row">
                    <tr>
                      <td class="pa-0" style="white-space: nowrap; text-align: center">
                        {{ row.item.index }}
                      </td>
                      <td class="pa-0" style="white-space: nowrap; text-align: center">
                        {{ row.item.lat }}
                      </td>
                      <td class="pa-0" style="white-space: nowrap; text-align: center">
                        {{ row.item.br }}
                      </td>
                    </tr>
                  </template></v-data-table>
                <v-data-table v-if="showRouteData" :headers="headersRoute" :items="showRouteData" hide-default-footer
                  :page.sync="pageCoordinate" :items-per-page="itemsPerPageCoordinate" class="elevation-0 routeTable"
                  @page-count="pageCountCoordinate = $event"><template v-slot:item="row">
                    <tr>
                      <td class="pa-0" style="white-space: nowrap; text-align: center">
                        {{ row.item.index + 1 }}
                      </td>
                      <td class="pa-0" style="text-align: center">
                        {{ convertDMS(row.item.lat, row.item.lng).split("-")[0]
                        }}<br />
                        {{
                          convertDMS(row.item.lat, row.item.lng).split("-")[1]
                        }}
                      </td>
                      <td class="pa-0" style="text-align: center" v-if="brRouteData">
                        {{
                          brRouteData[row.item.index]._icon.childNodes[4]
                            .innerText
                        }}
                        &nbsp;/&nbsp;
                        {{
                          brRouteData[
                            row.item.index
                          ]._icon.childNodes[0].data.split(" ")[1]
                        }}
                      </td>
                      <td class="pa-0" v-else></td>
                    </tr>
                  </template></v-data-table>
                <v-toolbar v-if="showRouteData" dense class="elevation-0 rounded-0" color="#F7F7F7">
                  <p class="mt-3" style="font-size: 0.8em">
                    Showing 1 to {{ itemsPerPageCoordinate }} of
                    {{ showRouteData.length }} entries
                  </p>
                  <v-spacer></v-spacer>
                  <v-pagination dense color="primary" v-model="pageCoordinate" :length="pageCountCoordinate"
                    :total-visible="7"></v-pagination>
                </v-toolbar>
              </v-card>
              <v-card width="180" class="elevation-0 rounded-0 coordinate_sailinkmobile" max-height="800"
                style="overflow-y: auto; margin-left: 1em" v-show="showRoute">
                <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense><v-spacer>Coordinates</v-spacer>
                </v-toolbar>

                <v-data-table v-if="!showRouteData" :headers="headersRoute" :items="showRouteEmpty" :items-per-page="3"
                  :page.sync="pageCoordinate" hide-default-footer hide-default-header style="text-align: left"
                  class="elevation-0 routeTable" @page-count="pageCountCoordinate = $event"><template v-slot:item="row">
                    <tr>
                      <td>
                        <v-card class="elevation-0 rounded-0">
                          <table>
                            <tr>
                              <td>#</td>
                              <td>:</td>
                              <td class="pa-0" style="white-space: nowrap; text-align: left">
                                {{ row.item.index }}
                              </td>
                            </tr>
                            <tr>
                              <td>Lat/Lng</td>
                              <td>:</td>
                              <td class="pa-0" style="white-space: nowrap; text-align: left">
                                {{ row.item.lat }}
                              </td>
                            </tr>
                            <tr>
                              <td>Dist/Brng</td>
                              <td>:</td>
                              <td class="pa-0" style="white-space: nowrap; text-align: left">
                                {{ row.item.br }}
                              </td>
                            </tr>


                          </table>
                        </v-card>
                      </td>
                    </tr>
                  </template></v-data-table>
                <v-data-table v-if="showRouteData" :headers="headersRoute" :items="showRouteData" hide-default-footer
                  hide-default-header :page.sync="pageCoordinate" :items-per-page="2" class="elevation-0 routeTable"
                  style="text-align: left" @page-count="pageCountCoordinate = $event"><template v-slot:item="row">
                    <tr>
                      <td>
                        <v-card class="elevation-0 rounded-0">
                          <table>
                            <tr>
                              <td>#</td>
                              <td>:</td>
                              <td class="pa-0" style="white-space: nowrap; text-align: left">
                                {{ row.item.index + 1 }}
                              </td>
                            </tr>
                            <tr>
                              <td>Lat/Lng</td>
                              <td>:</td>
                              <td class="pa-0" style="text-align: left">
                                {{ convertDMS(row.item.lat, row.item.lng).split("-")[0]
                                }}<br />
                                {{
                                  convertDMS(row.item.lat, row.item.lng).split("-")[1]
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Dist/Brng</td>
                              <td>:</td>
                              <td class="pa-0" style="text-align: left" v-if="brRouteData">
                                {{
                                  brRouteData[row.item.index]._icon.childNodes[4]
                                    .innerText
                                }}
                                &nbsp;/&nbsp;
                                {{
                                  brRouteData[
                                    row.item.index
                                  ]._icon.childNodes[0].data.split(" ")[1]
                                }}
                              </td>
                              <td class="pa-0" v-else></td>
                            </tr>


                          </table>
                        </v-card>
                      </td>
                    </tr>




                  </template></v-data-table>
                <v-toolbar v-if="showRouteData" dense class="elevation-0 rounded-0" color="#F7F7F7">
                  <v-spacer></v-spacer>
                  <v-pagination dense color="primary" v-model="pageCoordinate" :length="pageCountCoordinate"
                    :total-visible="7"></v-pagination>
                </v-toolbar>
              </v-card>
            </l-control>
            <l-control position="bottomleft" style="display: flex">
              <v-card class="elevation-0 rounded-0 ETAcard_sailinkdesktop" v-show="etaControl" height="fit-content"
                width="400">
                <v-toolbar class="elevation-0 rounded-0" dense color="#F7F7F7">
                  <v-btn id="strDraw_btn" class="elevation-0 rounded-0" :disabled="onDraw ? true : false" color="primary"
                    small @click="!onDraw ? startDraw() : console.log('test')">START DRAW</v-btn>
                  <v-btn class="elevation-0 rounded-0 ml-1" :disabled="!showRouteData?true:false" color="primary" small 
                    @click="clearMeasure()">CLEAR</v-btn>
                  <v-btn  class="elevation-0 rounded-0 ml-1" :disabled="!showRouteData?true:false" color="primary" small
                    @click="onCountTotal()">COUNT</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0 rounded-0" fab x-small color="#f7f7f7" @click="closeETA()">
                    <v-icon dark> mdi-close </v-icon>
                  </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <v-toolbar class="elevation-0 pa-0" dense color="#ffff" style="display: flex">
                  <div style="margin-left: -1.5em; display: flex">
                    <v-checkbox v-model="showonMarker" label="" class="mx-0" color="primary" hide-details></v-checkbox>
                    <div style="
                        font-size: 1.2em;
                        margin-left: -1em;
                        margin-top: 1.3em;
                      ">
                      Start From Marker
                    </div>
                    <v-spacer style="width: 1em"></v-spacer>
                    <v-checkbox v-model="showRoute" label="" color="primary" value="red darken-3"
                      hide-details></v-checkbox>
                    <div style="
                        font-size: 1.2em;
                        margin-left: -1em;
                        margin-top: 1.3em;
                      ">
                      Show Route Detail
                    </div>
                  </div>
                </v-toolbar>
                <v-divider></v-divider>
                <div style="padding: 0.5em">
                  <v-list style="margin: auto">
                    <v-list-item>
                      <v-list-item-content style="text-align: left">
                        <table>
                          <tr>
                            <td>
                              <h3 style="font-size: 1.2em">Total Distance</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">:</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">
                                {{ etaMiles }}&nbsp;Nmi
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h3 style="font-size: 1.2em">Duration</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">:</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">
                                {{ etaDuration }}&nbsp;Hours
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h3 style="font-size: 1.2em">Speed</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">:</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">
                                <input style="
                                    width: 11em;
                                    background-color: #d5e6f7;
                                    padding: 0.2em;
                                    margin-right: 0.1em;
                                    cursor: pointer;
                                  " v-model="etaSpeed" type="number" v-on:input="countETA()" />&nbsp;Knots
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h3 style="font-size: 1.2em">Depature</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">:</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">
                                <input style="
                                    width: 11em;
                                    background-color: #d5e6f7;
                                    padding: 0.2em;
                                    margin-right: 0.1em;
                                    cursor: pointer;
                                  " type="datetime-local" id="departure-sailink" name="departure-form"
                                  v-on:input="countETA()" />
                                <span id="depart-container"></span>&nbsp;WIB
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h3 style="font-size: 1.2em">Arrival</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">:</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">
                                <input style="
                                    width: 11em;
                                    background-color: #f7f7f7;
                                    padding: 0.2em;
                                    margin-right: 0.1em;
                                    cursor: auto;
                                  " type="text" readonly :value="etaArrival" />&nbsp;WIB
                              </h3>
                            </td>
                          </tr>
                        </table>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>

                <span class="d-none" id="depart-input-container"></span>
              </v-card>
              <v-card style="margin-bottom:1.4em" class="elevation-0 rounded-0 ETAcard_sailinkmobile" v-show="etaControl"
                height="fit-content" width="300">
                <v-toolbar class="elevation-0 rounded-0" dense color="#F7F7F7">
                  <v-btn id="strDraw_btn" class="elevation-0 rounded-0" x-small :disabled="onDraw ? true : false"
                    color="primary" @click="startDraw()">START DRAW</v-btn>
                  <v-btn :disabled="!showRouteData?true:false" class="elevation-0 rounded-0 ml-1" color="primary" x-small 
                    @click="clearMeasure()">CLEAR</v-btn>
                  <v-btn  :disabled="!showRouteData?true:false" class="elevation-0 rounded-0 ml-1" color="primary" x-small
                    @click="onCountTotal()">COUNT</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0 rounded-0" fab x-small color="#f7f7f7" @click="closeETA()">
                    <v-icon dark> mdi-close </v-icon>
                  </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <v-toolbar class="elevation-0 pa-0" dense color="#ffff" style="display: flex">
                  <div style="margin-left: -1.5em; display: flex">
                    <v-checkbox v-model="showonMarker" label="" class="mx-0" color="primary" hide-details></v-checkbox>
                    <div style="
                        font-size: 1em;
                        margin-left: -2em;
                        margin-top: 1.3em;
                      ">
                      Start From Marker
                    </div>
                    <v-spacer style="width: 1em"></v-spacer>
                    <v-checkbox v-model="showRoute" label="" color="primary" value="red darken-3"
                      hide-details></v-checkbox>
                    <div style="
                        font-size: 1em;
                        margin-left: -2em;
                        margin-top: 1.3em;
                      ">
                      Show Route Detail
                    </div>
                  </div>
                </v-toolbar>
                <v-divider></v-divider>
                <div style="padding: 0em">
                  <v-list style="margin: auto">
                    <v-list-item>
                      <v-list-item-content style="text-align: left">
                        <table>
                          <tr>
                            <td>
                              Total Distance
                            </td>
                            <td>:</td>
                            <td>

                              {{ etaMiles }}&nbsp;Nmi

                            </td>
                          </tr>
                          <tr>
                            <td>
                              Duration
                            </td>
                            <td>:</td>
                            <td>

                              {{ etaDuration }}&nbsp;Hours

                            </td>
                          </tr>
                          <tr>
                            <td>Speed</td>
                            <td>:</td>
                            <td>

                              <input style="
                                    width: 7em;
                                    background-color: #d5e6f7;
                                    padding: 0.2em;
                                    margin-right: 0.1em;
                                    cursor: pointer;
                                  " v-model="etaSpeed" type="number" v-on:input="countETA()" />&nbsp;Knots

                            </td>
                          </tr>
                          <tr>
                            <td>
                              Depature
                            </td>
                            <td>:</td>
                            <td>

                              <input style="
                                    width: 7em;
                                    background-color: #d5e6f7;
                                    padding: 0.2em;
                                    margin-right: 0.1em;
                                    cursor: pointer;
                                  " type="datetime-local" id="departure-sailink" name="departure-form"
                                v-on:input="countETA()" />
                              <span id="depart-container"></span>&nbsp;WIB

                            </td>
                          </tr>
                          <tr>
                            <td>
                              Arrival
                            </td>
                            <td>:</td>
                            <td>

                              <input style="
                                    width: 7em;
                                    background-color: #f7f7f7;
                                    padding: 0.2em;
                                    margin-right: 0.1em;
                                    cursor: auto;
                                  " type="text" readonly :value="etaArrival" />&nbsp;WIB

                            </td>
                          </tr>
                        </table>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>

                <span class="d-none" id="depart-input-container"></span>
              </v-card>
            </l-control>

          </l-map>
          <v-divider v-if= "user_allowed.role_id <= 3"></v-divider>
        
          <v-toolbar v-if= "user_allowed.role_id <= 3"  dense color="#F7F7F7" elevation="0">
            User Who Can See This Vessel
            <v-spacer style="width: 20%"></v-spacer>
            <v-text-field style="border: solid 1px #e0e0e0" width="40" v-model="searchUserWho" hide-details
              prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
              class="elevation-0 rounded-0"></v-text-field>
          </v-toolbar>
          <v-divider v-if= "user_allowed.role_id <= 3"></v-divider>
          <v-data-table v-if= "user_allowed.role_id <= 3" :headers="headersUser" :items="vesselUsers" :items-per-page="itemsPerPageUser"
            class="elevation-0 rounded-0 dataTable userTable_desktop " hide-default-footer hide-default-header
            :search="searchUserWho" :page.sync="pageUser" @page-count="pageCountUser = $event"
            @pagination="intoFilterUser" dense>
            <template v-slot:header>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">#</th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">Name</th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">Email</th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">Role</th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">Last Login</th>
            </template>
            <template v-slot:item="row">
              <tr>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.index + 1 }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.name }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.email }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.role_name }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.lastLogin }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table v-if= "user_allowed.role_id <= 3" :headers="headersUser" :items="vesselUsers" :items-per-page="3"
            class="elevation-0 rounded-0 userTable_mobile dataTable_Sailink" hide-default-footer hide-default-header
            :search="searchUserWho" :page.sync="pageUser" @page-count="pageCountUser = $event"
            @pagination="intoFilterUser" dense><template v-slot:item="row">
              <tr>
                <td style="border-bottom: none; text-align: left">
                  <v-card style="border-bottom: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-0" width="80vw">
                    <table>
                      <tr>
                        <td>{{ row.item.index + 1 }}</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>:</td>
                        <td>{{ row.item.name }}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>:</td>
                        <td>{{ row.item.email }}</td>
                      </tr>
                      <tr>
                        <td>Role</td>
                        <td>:</td>
                        <td>{{ row.item.role_name }}</td>
                      </tr>
                      <tr>
                        <td>Last Login</td>
                        <td>:</td>
                        <td>{{ row.item.lastLogin }}</td>
                      </tr>
                    </table>
                  </v-card>
                </td>
              </tr>
            </template></v-data-table>
          <v-divider v-if= "user_allowed.role_id <= 3"></v-divider>
          <v-toolbar v-if= "user_allowed.role_id <= 3" dense class="elevation-0 rounded-0" color="#F7F7F7">
            <p class="mt-3" style="font-size: 0.8em;text-align: left;">
              Showing {{ showingDatafromUser }} to {{ showingDatatoUser }} of
              {{ totalFilterUser }} entries
            </p>
            <v-spacer></v-spacer>
            <v-pagination color="primary" v-if="showmaptable" v-model="pageUser" :length="pageCountUser"
              :total-visible="7"></v-pagination>
          </v-toolbar>
        </v-card>
      </v-card>

      <v-card style="border: solid 1px #e0e0e0" class="ma-3 datatabledetail rounded-0 mt-0 mb-0" elevation="0">
        <v-toolbar v-if="showmaptable" elevation="0" style="max-height: 70px" color="#F7F7F7" fixed dense>
          <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0 mr-2" color="#ffff"
                v-bind="attrs" v-on="on">
                {{ kFormatter(itemsPerPage)
                }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list style="border: solid 1px #e0e0e0">
              <v-list-item @click="itemsPerPage = 10"><v-list-item-title>10</v-list-item-title></v-list-item>
              <v-list-item @click="itemsPerPage = 25"><v-list-item-title>25</v-list-item-title></v-list-item>
              <v-list-item @click="itemsPerPage = 50"><v-list-item-title>50</v-list-item-title></v-list-item>
              <v-list-item @click="itemsPerPage = 100"><v-list-item-title>100</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
          <v-btn color="primary" class="elevation-0 rounded-0 csvsailink_desktop" @click="createCSV()" small><v-icon
              x-small>mdi-file-move</v-icon> &nbsp;Export CSV</v-btn>
          <v-btn :href="'https://sailink.id/cetak/pdf/sailink/' + showmaptable.id + '/' + exp_range" color="primary" dark
            small style="color:#fff" class="elevation-0 rounded-0 pt-1 ml-2 pdfsailink_desktop" target="_blank">
            <v-icon x-small>mdi-file-move</v-icon> Export PDF
          </v-btn>
          <v-btn :href="'https://sailink.id/cetak/kml/sailink/' + showmaptable.id + '/' + exp_range" color="primary" dark
            small style="color:#fff" class="elevation-0 rounded-0 pt-1 ml-2 pdfsailink_desktop" target="_blank">
            <v-icon x-small>mdi-file-move</v-icon> Export KML
          </v-btn>


          <v-btn color="primary" class="elevation-0 rounded-0 csvsailink_mobile" @click="createCSV()" x-small>CSV</v-btn>
          <v-btn color="primary" class="elevation-0 rounded-0 pdfsailink_mobile  ml-1" style="padding-top: 0.24em;"
            :href="'https://sailink.id/cetak/pdf/sailink/' + showmaptable.id + '/' + exp_range" target="_blank"
            x-small>PDF</v-btn>
          <v-btn color="primary" class="elevation-0 rounded-0 pdfsailink_mobile ml-1" style="padding-top: 0.24em;"
            :href="'https://sailink.id/cetak/kml/sailink/' + showmaptable.id + '/' + exp_range" target="_blank"
            x-small>KML</v-btn>


          <v-spacer style="width: 34%"></v-spacer>
          <v-text-field style="border: solid 1px #e0e0e0" width="40" v-model="search" hide-details
            prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
            class="elevation-0 rounded-0"></v-text-field>
        </v-toolbar>
        <v-divider></v-divider>
        <v-layout v-resize="onResize" column style="">
          <v-container id="map_sailink" v-if="!showmaptracking" fill-height fluid style="height: 250px"
            class="elevation-0">
            <v-row align="center" justify="center">
              <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
            </v-row>
          </v-container>
          <v-data-table :search="search" style="margin-top: 0px" v-if="showmaptracking && showmaptable.weather == null"
            :headers="headersData" :items="tableTracking" hide-default-footer hide-default-header :page.sync="page"
            :items-per-page="itemsPerPage" class="elevation-0 dataTable trackingTable_sailinkdesktop"
            @page-count="pageCount = $event" @pagination="intoFilter">
            <template v-slot:header>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">No</th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">
                Latitude, Longitude<br />(DMS)
              </th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">
                Heading<br />(Degree)
              </th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">
                Speed<br />(Knots)
              </th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">
                Distance<br />(Nmi)
              </th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">RX SNR<br />(db)</th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">RX<br />MODCOD</th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">
                TX Power<br />(db)
              </th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">SatNet</th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">
                Date/Time<br />(GMT+7)
              </th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">Link Status</th>
            </template>
            <template v-slot:item="row">
              <tr>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.index + 1 }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  <a @mouseover="latdms = row.item.index" @mouseleave="latdms = null" class="link_latlon"
                    :style="latdms == row.item.index ? 'color:blue' : 'color:black'" @click="triggerPopup(row.item)">{{
                      row.item.lat }} , {{ row.item.lng }}<br />({{
    row.item.dms
  }})</a>
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.heading }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.speed }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.distance }}
                </td>
                <td style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.rx_snr }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.modcod }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.tx }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.satnet }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.dateTime }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  <v-chip style="width: 60px" v-if="row.item.isActive" class="elevation-0" color="#38C072"
                    text-color="white" small>
                    &nbsp;&nbsp;&nbsp;UP
                  </v-chip>

                  <v-chip style="width: 60px" v-if="!row.item.isActive" class="elevation-0" color="red" text-color="white"
                    small>
                    DOWN
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table :search="search" style="margin-top: 0px" v-if="showmaptracking && showmaptable.weather != null"
            :headers="headers" :items="tableTracking" multi-sort hide-default-footer hide-default-header :page.sync="page"
            :items-per-page="itemsPerPage" class="elevation-0 dataTable trackingTable_sailinkdesktop"
            @page-count="pageCount = $event" item-key="msg_id" :expanded.sync="expanded" @pagination="intoFilter"
            :single-expand="singleExpand">
            <template v-slot:header>

              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">No</th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">
                Latitude, Longitude<br />(DMS)
              </th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">Weather Status</th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">
                Heading<br />(Degree)
              </th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">
                Speed<br />(Knots)
              </th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">
                Distance<br />(Nmi)
              </th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">RX SNR<br />(db)</th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">RX<br />MODCOD</th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">
                TX Power<br />(db)
              </th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">SatNet</th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">
                Date/Time<br />(GMT+7)
              </th>
              <th class="tableHeader_ pa-0 pt-2 pl-1 pb-2">Link Status</th>
            </template>
            <template v-slot:item="{ item, expand, isExpanded }">
              <tr>

                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.index + 1 }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  <a @mouseover="latdms = item.index" @mouseleave="latdms = null" class="link_latlon"
                    :style="latdms == item.index ? 'color:blue' : 'color:black'" small text @click="triggerPopup(item)">{{
                      item.lat }} , {{ item.lng }}<br />({{ item.dms }})</a>
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">

                  {{ item.weather }}
                  <v-btn icon x-small class="mx-2 rounded-0 elevation-0" fab dark color="primary" @click="
                    !isExpanded
                      ? (weatherClick(
                        showmaptable.id,
                        item.msg_id,
                        'sailink',
                        item.dateTime
                      ),
                        expand(!isExpanded))
                      : (removeWeather(item.dateTime), expand(!isExpanded))
                    " :color="!isExpanded ? 'green' : 'red'">
                    <v-icon dark>
                      {{ !isExpanded ? "mdi-plus-box" : "mdi-minus-box" }}
                    </v-icon>
                  </v-btn>
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.heading }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.speed }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.distance }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.rx_snr }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.modcod }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.tx }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.satnet }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.dateTime }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  <v-chip style="width: 60px" v-if="item.isActive" class="elevation-0" color="#38C072" text-color="white"
                    small>
                    &nbsp;&nbsp;&nbsp;UP
                  </v-chip>

                  <v-chip style="width: 60px" v-if="!item.isActive" class="elevation-0" color="red" text-color="white"
                    small>
                    DOWN
                  </v-chip>
                </td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style="background-color: #c1c1c1">
                <v-card class="elevation-0 rounded-0 pa-1" width="100%">
                  <v-card class="elevation-0 rounded-0 pa-2" style="border: solid 1px #e0e0e0">
                    <v-card class="elevation-0 rounded-0 pa-2" style="border: solid 1px #e0e0e0">
                      <v-toolbar class="elevation-0 text-center" dense color="#F7F7F7" elevation="2">
                        <v-spacer> Weather & Wave </v-spacer></v-toolbar>
                      <v-progress-linear v-if="!weatherData[item.dateTime]" indeterminate color="primary" height="7"
                        striped></v-progress-linear>
                      <v-row v-if="weatherData[item.dateTime]" no-gutters style="flex-wrap: nowrap">
                        <v-col cols="1" style="min-width: 100px; max-width: 100%; margin: 5px"
                          class="flex-grow-1 flex-shrink-0">
                          <v-card class="pa-2" outlined tile>
                            <v-card-text>
                              <v-simple-table>
                                <template v-slot:default>
                                  <tr>
                                    <td style="padding: 6px">Weather</td>
                                    <td style="padding: 6px">
                                      {{ weatherData[item.dateTime].weather }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 6px">Wind Direction</td>
                                    <td style="padding: 6px">
                                      {{ weatherData[item.dateTime].windDirection }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 6px">Wind Speed</td>
                                    <td style="padding: 6px">
                                      {{ weatherData[item.dateTime].windSpeed }}
                                    </td>
                                  </tr>
                                </template>
                              </v-simple-table>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="1" style="min-width: 100px; max-width: 100%; margin: 5px"
                          class="flex-grow-1 flex-shrink-0">
                          <v-card class="pa-2" outlined tile>
                            <v-card-text>
                              <v-simple-table>
                                <template v-slot:default>
                                  <tr>
                                    <td style="padding: 6px">Temperature</td>
                                    <td style="padding: 6px">
                                      {{ weatherData[item.dateTime].temperature }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 6px">Humidity</td>
                                    <td style="padding: 6px">
                                      {{ weatherData[item.dateTime].humidity }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 6px">Dew Point</td>
                                    <td style="padding: 6px">
                                      {{ weatherData[item.dateTime].dewPoint }}
                                    </td>
                                  </tr>
                                </template>
                              </v-simple-table>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="1" style="min-width: 100px; max-width: 100%; margin: 5px"
                          class="flex-grow-1 flex-shrink-0">
                          <v-card class="pa-2" outlined tile>
                            <v-card-text>
                              <v-simple-table>
                                <template v-slot:default>
                                  <tr>
                                    <td style="padding: 6px">Wave Height</td>
                                    <td style="padding: 6px">
                                      {{
                                        weatherData[item.dateTime]
                                          .waveSignificantHeight
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 6px">Wave From Direction</td>
                                    <td style="padding: 6px">
                                      {{
                                        weatherData[item.dateTime].waveFromDirection
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 6px">
                                      <p style="height: 2px"></p>
                                    </td>
                                    <td style="padding: 6px">
                                      <p style="height: 2px"></p>
                                    </td>
                                  </tr>
                                </template>
                              </v-simple-table>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </td>
            </template>
          </v-data-table>
          <v-data-table :search="search" style="margin-top: 0px" v-if="showmaptracking && showmaptable.weather != null"
            :headers="headers" :items="tableTracking" multi-sort hide-default-footer hide-default-header :page.sync="page"
            :items-per-page="itemsPerPage" class="elevation-0 dataTable_Sailink trackingTable_sailinkmobile"
            @page-count="pageCount = $event" item-key="msg_id" :expanded.sync="expanded" @pagination="intoFilter"
            :single-expand="singleExpand"><template v-slot:item="row">
              <tr>
                <td style="border-bottom: none; text-align: left">
                  <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" width="80vw">
                    <v-toolbar style="border-bottom: solid 1px #e0e0e0" class="elevation-0 rounded-0" dense>{{
                      row.item.index + 1 }}<v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                      <table>
                        <tr>
                          <td>Latitude, Longitude (DMS)</td>
                          <td>:</td>
                          <td>
                            <a @mouseover="latdms = row.item.index" @mouseleave="latdms = null" class="link_latlon"
                              :style="latdms == row.item.index ? 'color:blue' : 'color:black'" small text
                              @click="triggerPopup(row.item)">{{ row.item.lat }} , {{ row.item.lng }}<br />({{
                                row.item.dms }})</a>
                          </td>
                        </tr>
                        <tr>
                          <td>Weather Status</td>
                          <td>:</td>
                          <td>
                            {{ row.item.weather }}
                            <v-btn @click="!weatherData[row.item.dateTime] ? weatherClick(
                              showmaptable.id,
                              row.item.msg_id,
                              'sailink',
                              row.item.dateTime
                            ) : removeWeather(row.item.dateTime)" icon x-small class="mx-2 rounded-0 elevation-0" fab
                              dark :color="!weatherData[row.item.dateTime] ? 'green' : 'red'">
                              <v-icon dark>
                                {{ !weatherData[row.item.dateTime] ? "mdi-plus-box" : "mdi-minus-box" }}
                              </v-icon>
                            </v-btn>
                          </td>
                        </tr>
                        <tr>
                          <td>Heading (Degree)</td>
                          <td>:</td>
                          <td>{{ row.item.heading }}</td>
                        </tr>
                        <tr>
                          <td>Speed (Knots)</td>
                          <td>:</td>
                          <td>{{ row.item.speed }}</td>
                        </tr>
                        <tr>
                          <td>Distance (Nmi)</td>
                          <td>:</td>
                          <td>{{ row.item.distance }}</td>
                        </tr>
                        <tr>
                          <td>RX SNR (db)</td>
                          <td>:</td>
                          <td>{{ row.item.rx_snr }}</td>
                        </tr>
                        <tr>
                          <td>RX MODCOD</td>
                          <td>:</td>
                          <td>{{ row.item.modcod }}</td>
                        </tr>
                        <tr>
                          <td>TX Power (db)</td>
                          <td>:</td>
                          <td>{{ row.item.tx }}</td>
                        </tr>
                        <tr>
                          <td>SatNet</td>
                          <td>:</td>
                          <td>{{ row.item.satnet }}</td>
                        </tr>
                        <tr>
                          <td>Date/Time (GMT+7)</td>
                          <td>:</td>
                          <td>{{ row.item.dateTime }}</td>
                        </tr>
                        <tr>
                          <td>Link Status</td>
                          <td>:</td>
                          <td>
                            <v-chip style="width: 60px" v-if="row.item.isActive" class="elevation-0" color="#38C072"
                              text-color="white" small>
                              &nbsp;&nbsp;&nbsp;UP
                            </v-chip>

                            <v-chip style="width: 60px" v-if="!row.item.isActive" class="elevation-0" color="red"
                              text-color="white" small>
                              DOWN
                            </v-chip>
                          </td>
                        </tr>
                      </table>
                    </v-card-text>
                  </v-card>
                </td>



                <v-progress-linear
                  v-if="showclick_dateTime[row.item.dateTime] == row.item.dateTime && !weatherData[row.item.dateTime]"
                  indeterminate color="primary" height="7" striped></v-progress-linear>

              </tr>
              <tr style="" v-if="weatherData[row.item.dateTime]">
                <td style="border-bottom: none; text-align: center">
                  <v-card class="elevation-0 rounded-0 pa-1" width="83vw">
                    <v-card class="elevation-0 rounded-0 pa-1" style="border: solid 1px #e0e0e0">

                      <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0" width="100%">
                        <v-toolbar style="border-bottom: solid 1px #e0e0e0" class="elevation-0 rounded-0" color="#F7F7F7"
                          dense><v-spacer>Weather &
                            Wave</v-spacer>
                        </v-toolbar>
                        <v-card-text style="text-align:left">
                          <table>
                            <tr>
                              <td>Weather</td>
                              <td>:</td>
                              <td>
                                {{ weatherData[row.item.dateTime].weather }}
                              </td>
                            </tr>
                            <tr>
                              <td>Wind Direction</td>
                              <td>:</td>
                              <td> {{ weatherData[row.item.dateTime].windDirection }}</td>
                            </tr>
                            <tr>
                              <td>Wind Speed</td>
                              <td>:</td>
                              <td> {{ weatherData[row.item.dateTime].weather }}</td>
                            </tr>
                            <tr>
                              <td>Temperature</td>
                              <td>:</td>
                              <td>{{ weatherData[row.item.dateTime].temperature }}</td>
                            </tr>
                            <tr>
                              <td>Humidity</td>
                              <td>:</td>
                              <td>{{ weatherData[row.item.dateTime].humidity }}</td>
                            </tr>
                            <tr>
                              <td>Humidity</td>
                              <td>:</td>
                              <td> {{ weatherData[row.item.dateTime].dewPoint }}</td>
                            </tr>

                            <tr>
                              <td>Wave Height</td>
                              <td>:</td>
                              <td>{{ weatherData[row.item.dateTime]
                                .waveSignificantHeight }}</td>
                            </tr>
                            <tr>
                              <td>Wave From Direction</td>
                              <td>:</td>
                              <td> {{
                                weatherData[row.item.dateTime].waveFromDirection
                              }}</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>

                    </v-card>
                  </v-card>
                </td>
              </tr>
            </template></v-data-table>
          <v-data-table :search="search" style="margin-top: 0px" v-if="showmaptracking && showmaptable.weather == null"
            :headers="headers" :items="tableTracking" multi-sort hide-default-footer hide-default-header :page.sync="page"
            :items-per-page="itemsPerPage" class="elevation-0 dataTable_Sailink trackingTable_sailinkmobile"
            @page-count="pageCount = $event" item-key="msg_id" @pagination="intoFilter"><template v-slot:item="row">
              <tr>
                <td style="border-bottom: none; text-align: left">
                  <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" width="80vw">
                    <v-toolbar style="border-bottom: solid 1px #e0e0e0" class="elevation-0 rounded-0" dense>{{
                      row.item.index + 1 }}<v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                      <table>
                        <tr>
                          <td>Latitude, Longitude (DMS)</td>
                          <td>:</td>
                          <td>


                            <a @mouseover="latdms = row.item.index" @mouseleave="latdms = null" class="link_latlon"
                              :style="latdms == row.item.index ? 'color:blue' : 'color:black'" small text
                              @click="triggerPopup(row.item)">{{ row.item.lat }} , {{ row.item.lng }}<br />({{
                                row.item.dms }})</a>
                          </td>
                        </tr>
                        <tr>
                          <td>Heading (Degree)</td>
                          <td>:</td>
                          <td>{{ row.item.heading }}</td>
                        </tr>
                        <tr>
                          <td>Speed (Knots)</td>
                          <td>:</td>
                          <td>{{ row.item.speed }}</td>
                        </tr>
                        <tr>
                          <td>Distance (Nmi)</td>
                          <td>:</td>
                          <td>{{ row.item.distance }}</td>
                        </tr>
                        <tr>
                          <td>RX SNR (db)</td>
                          <td>:</td>
                          <td>{{ row.item.rx_snr }}</td>
                        </tr>
                        <tr>
                          <td>RX MODCOD</td>
                          <td>:</td>
                          <td>{{ row.item.modcod }}</td>
                        </tr>
                        <tr>
                          <td>TX Power (db)</td>
                          <td>:</td>
                          <td>{{ row.item.tx }}</td>
                        </tr>
                        <tr>
                          <td>SatNet</td>
                          <td>:</td>
                          <td>{{ row.item.satnet }}</td>
                        </tr>
                        <tr>
                          <td>Date/Time (GMT+7)</td>
                          <td>:</td>
                          <td>{{ row.item.dateTime }}</td>
                        </tr>
                        <tr>
                          <td>Link Status</td>
                          <td>:</td>
                          <td>
                            <v-chip style="width: 60px" v-if="row.item.isActive" class="elevation-0" color="#38C072"
                              text-color="white" small>
                              &nbsp;&nbsp;&nbsp;UP
                            </v-chip>

                            <v-chip style="width: 60px" v-if="!row.item.isActive" class="elevation-0" color="red"
                              text-color="white" small>
                              DOWN
                            </v-chip>
                          </td>
                        </tr>
                      </table>
                    </v-card-text>
                  </v-card>
                </td>
              </tr>
            </template></v-data-table>
          <v-divider></v-divider>
          <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
            <p class="mt-3" style="font-size: 0.8em;text-align: left;">
              Showing {{ showingDatafrom }} to {{ showingDatato }} of
              {{ totalFilter }} entries
            </p>
            <v-spacer></v-spacer>
            <v-pagination color="primary" v-if="showmaptable" v-model="page" :length="pageCount"
              :total-visible="7"></v-pagination>
          </v-toolbar>
        </v-layout>

        <div id="app">
          <v-app id="inspire"> </v-app>
        </div>



      </v-card>

      <!--Modal Image-->
      <v-dialog v-model="imageOpen">
        <v-carousel hide-controls hide-delimiters>
          <v-carousel-item @click="imageOpen = false" v-for="(item, i) in showmaptable.images" :key="i">
            <img style="height: 100%" :src="item[0]" />
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
      <!--Modal Image End-->

    </v-card>

    <!--Modal App-->
    <v-dialog v-model="qdaily" width="85vw" persistent>
      <v-card color="#fff" class="elevation-0 rounded-0">
        <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7">
          <v-icon class="mr-2" small>mdi-chart-bar</v-icon> Quota daily usage {{ serviceQuota }}
          <v-spacer></v-spacer>
          <v-btn class="elevation-0 rounded-0" small icon @click="(qdaily = false), removeQuota()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>
        <v-toolbar v-if="showmaptable" dense class="elevation-0 rounded-0">

          <v-toolbar-subtitle style="font-size: 0.8em;text-align: left;">{{ showmaptable.vessel_pt }}</v-toolbar-subtitle>


          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider v-if="showmaptable"></v-divider>
        <v-container v-if="!quotaData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
          </v-row>
        </v-container>

        <v-card-text v-if="quotaData" style="padding: 2em">
          <QuotaChart v-if="serviceQuota == 'metered'" v-bind:dataChart="quotaData.chart" />

          <QuotaChartLine v-else v-bind:dataChart="quotaData.chart" />
        </v-card-text>

        <v-card-actions class="justify-end"> </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Modal App End-->

    <!--Modal Date Picker-->
    <v-dialog ref="dialog" v-model="dateopen" :return-value.sync="dates" class="elevation-0 rounded-0" persistent
      width="750">
      <v-card class="elevation-0 rounded-0 pb-1">
        <v-toolbar dense color="#f7f7f7" class="elevation-0 rounded-0">
          Choose Custom Date Range for Tracking
          <v-spacer></v-spacer>
          <v-btn small class="elevation-0 rounded-0" icon @click="closeCostumRange()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card class="elevation-0 rounded-0">
          <v-alert v-if="cant_future" class="elevation-0 rounded-0 ma-2 mt-3" text dense color="red"
            icon="mdi-clock-alert-outline" style="font-size: 0.86em;text-align: left;">
            Cannot predict future | Enter the correct date and time range
          </v-alert>
          <v-alert v-if="cant_backwards" type="warning" class="elevation-0 rounded-0 ma-2 mt-3" text dense
            icon="mdi-history" style="font-size: 0.86em;text-align: left;">
            Backwards date and time range | Enter the correct date and time range
          </v-alert>
        </v-card>
        <v-row width="100%" no-gutters>
          <v-col cols="12" sm="6">
            <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 pa-2 ma-2">
              <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 pa-2 ma-1">
                <v-date-picker @click:date="logtest" :disabled="(testdisabled || dates.length > 1) ? true : false"
                  color="primary" class="elevation-0 rounded-0" v-model="dates" range scrollable>
                </v-date-picker>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 pa-2 ma-2">
              <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0  ma-2">
                <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">From</v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="7">

                      <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="dates[0]" label="Date"
                        prepend-inner-icon="mdi-calendar"></v-text-field>

                    </v-col>
                    <v-col cols="12" sm="5">

                      <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="times[0]" label="Time"
                        prepend-inner-icon="mdi-clock-outline"></v-text-field>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0  ma-2">
                <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">To</v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="7">

                      <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="dates[1]" label="Date"
                        prepend-inner-icon="mdi-calendar"></v-text-field>

                    </v-col>
                    <v-col cols="12" sm="5">

                      <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="times[1]" label="Time"
                        prepend-inner-icon="mdi-clock-outline"></v-text-field>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>


              <v-toolbar class="elevation-0 rounded-0" dense color="#f7f7f7">
                <v-spacer></v-spacer>
                <v-btn small :disabled="dates.length > 1 ? false : true" class="elevation-0 rounded-0 mr-1"
                  @click="resetTimesDates()" color="primary"> Reset </v-btn>
                <v-btn small :disabled="dates.length > 1 ? false : true" class="elevation-0 rounded-0" color="primary"
                  @click="customRange()"> OK </v-btn>
              </v-toolbar>
            </v-card>
          </v-col>
        </v-row>

      </v-card>

    </v-dialog>

    <!--Modal Time Picker-->
    <v-dialog ref="dialog" v-model="timeopen" :return-value.sync="dates" class="elevation-0 rounded-0" persistent
      width="fit-content">
      <v-card class="elevation-0 rounded-0 pa-2">
        <v-card style="border: solid 1px #e0e0e0;display: block;" class="elevation-0 rounded-0 ma-2">
          <v-card style="border: solid 1px #e0e0e0;display: block;" class="elevation-0 rounded-0 ma-2">
            <v-time-picker v-model="timein" ampm-in-title format="24hr">
            </v-time-picker>
          </v-card>

          <v-card style="border: solid 1px #e0e0e0;display: block;" class="elevation-0 rounded-0 ma-2">
            <v-toolbar class="elevation-0 rounded-0" dense color="f7f7f7">
              <v-spacer></v-spacer>
              <v-btn :disabled="!timein ? true : false" class="elevation-0 rounded-0" small color="primary"
                @click="saveTimes()"> Choose Time </v-btn>
            </v-toolbar>
          </v-card>

        </v-card>
      </v-card>
    </v-dialog>


    <v-dialog
      class="rounded-0 elevation-0"
      v-model="switch_satnet_modal"
      persistent
      max-width="590"
    >
      <v-card class="rounded-0 elevation-0">
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text v-if="!switch_true" style="text-align: left"
          >Satnet will be switch</v-card-text
        >
        <v-card-text v-if="satnet_output">
          {{satnet_output}}
        </v-card-text>
        <v-card-text v-if="switch_true">
          <v-progress-linear
      indeterminate
      color="primary"
    ></v-progress-linear>
    
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="satnet_output==null"
            :disabled="switch_true?true:false"
            dark
            small
            class="rounded-0 elevation-0"
            color="red"
            @click="switch_satnet_modal = false"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="switch_true==false && satnet_output!=null"
            dark
            small
            class="rounded-0 elevation-0"
            color="red"
            @click="switch_satnet_modal = false,removeSatnetOutput()"
          >
            Close
          </v-btn>
          <v-btn
            :disabled="!satnet_id ? true : false"
            small
            class="rounded-0 elevation-0"
            color="primary"
            @click="post_switch_satnet_()"
          >
            Switch Satnet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-card>
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import axios from "axios";
// import VuePictureSwipe from 'vue-picture-swipe';
import { mapSettingsShow } from "@/constants/mapSettings";
import mapstyle from "@/assets/json/mapstyle.json";
import { mapActions, mapGetters } from "vuex";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import QuotaChart from "@/components/charts/QuotaChart.vue";
import QuotaChartLine from "@/components/charts/QuotaChartLine.vue";
import L from "leaflet";
import {
  LPopup,
  LMap,
  LTileLayer,
  LIcon,
  LControl,
  LPolygon,
  LControlZoom,
} from "vue2-leaflet";

import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import LControlPolylineMeasure from "vue2-leaflet-polyline-measure";
import LMovingRotatedMarker from 'vue2-leaflet-rotatedmarker'
import {
  cns11,
  cns11gain,
  cns11_gnt,
  cns11_gntgain,
} from "@/assets/js/cns11.js";
import {
  apstar5,
  apstar5gain,
  apstar5_gnt,
  apstar5_gntgain,
} from "@/assets/js/apstar5.js";
import {
  apstar92,
  apstar92gain,
  apstar92_gnt,
  apstar92_gntgain,
} from "@/assets/js/apstar9.js";
import $ from "jquery";
import { abs, absgain, abs_gnt, abs_gntgain } from "@/assets/js/abs.js";
import { ocn, ocngain, ocn_gnt, ocn_gntgain } from "@/assets/js/oceanbeam.js";
import {
  hts,
  htsgain,
  hts_gnt,
  hts_gntgain,
} from "@/assets/js/hts.js";

import { beam3, beam3gain, beam3_gnt, beam3_gntgain } from "@/assets/js/beam3.js";
import { horizon, horizongain, horizon_gnt, horizon_gntgain } from "@/assets/js/horizon.js";
import { utelsat, utelsatgain, utelsat_gnt, utelsat_gntgain } from "@/assets/js/utelsat.js";
import { ReInviteUserAgentServer } from 'sip.js/lib/core';



const iconUrl = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDIuODggMy40NiI+PGRlZnM+PHN0eWxlPi5ke2ZpbGw6IzAwMDBGRjtzdHJva2U6I2ZmZjtzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6LjJweDt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9ImEiLz48ZyBpZD0iYiI+PGcgaWQ9ImMiPjxwYXRoIGNsYXNzPSJkIiBkPSJNMi42OSwzLjI2bC0uOTYtLjQ1Yy0uMTktLjA5LS40LS4wOS0uNTksMGwtLjk2LC40NUwxLjQ0LC4yNmwxLjI1LDNaIi8+PC9nPjwvZz48L3N2Zz4='


export default {
  components: {
    LPopup,
    LIcon,
    LMap,
    LTileLayer,
    LControl,
    LPolygon,
    QuotaChart,
    QuotaChartLine,
    LControlZoom,
    LControlPolylineMeasure,
    LControlFullscreen,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
    LMovingRotatedMarker
  },







  data() {
    return {
      satnet_id:null,
      switch_satnet_modal:false,
      onFinish: false,
      currentLine: null,
      coords: null,
      toolTipsArr: null,
      cant_future: false,
      cant_backwards: false,
      testdisabled: false,
      exp_range: 'today',
      IntervalMoving: null,
      fetchInterval: null,
      latdms: null,
      itemsGambar: [
      ],
      icon: L.icon({
        iconUrl: iconUrl,
        iconSize: [17, 22],
        iconAnchor: [8.5, 11.5],
        popupAnchor: [0, -25]
      }),
      drivePath: null,
      driveLatLng: null,
      driveRotationAngle: null,
      driveMarker: null,
      showclick_dateTime: [],
      map_sailink_fs: false,
      totalFilter: null,
      totalFilterUser: null,
      itemsPerPageUser: 5,
      showonMarker: false,
      searchUserWho: "",
      qdaily: null,
      weatherData: [],
      imageOpenData: null,
      showonMarker: false,
      showRoute: false,
      showRouteData: null,
      showRouteEmpty: [{ index: 0, lat: "-", lng: "-", br: "-" }],
      brRouteData: null,
      headersRoute: [
        {
          text: "#",
          value: "index",
        },
        { text: "Latitude/Longitude", value: "Latitude" },
        { text: "Distance/Bearing", value: "Distance/Bearing" },
      ],
      onDraw: null,
      etaControl: false,
      url: "https://maps.skyreach.id/sailink/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      datepickerID: null,
      dates: [],
      times: [],
      timein: null,
      menu: false,
      a: 0,
      measurepoly: 0,
      mobileBreakpoint: 600,
      singleExpand: false,
      expanded: [],
      dateopen: false,
      timeopen: false,
      itemsPerPage: 10,
      itemsPerPageCoordinate: 10,
      page: 1,
      pageCount: 0,
      pageUser: 1,
      pageCountUser: 0,
      pageImage: 1,
      pageCountImage: 0,
      pageCoordinate: 1,
      pageCountCoordinate: 0,
      search: "",
      countLength: 0,
      markers: [
        {
          id: "a",
          position: { lat: 3, lng: 101 },
        },
        {
          id: "b",
          position: { lat: 5, lng: 99 },
        },
        {
          id: "c",
          position: { lat: 6, lng: 97 },
        },
      ],
      lines: [
        {
          id: "1",
          path: [
            { lat: 3, lng: 101 },
            { lat: 5, lng: 99 },
          ],
        },
        {
          id: "2",
          path: [
            { lat: 5, lng: 99 },
            { lat: 6, lng: 97 },
          ],
        },
      ],

      path: [
        { lat: -1.67292, lng: 100.63035 },
        { lat: -4.01517, lng: 102.28569 },
      ],

      loadernavplus: require("@/assets/img/load100new.gif"),
      headersData: [
        {
          text: "No",
          align: "start",
          sortable: false,
        },
        { text: "Latitude, Longtitude (DMS)", value: "lat", sortable: false },
        { text: "Heading (Degree)", value: "heading", sortable: false },
        { text: "Speed (Knots)", value: "speed", sortable: false },
        { text: "Distance(Nmi)", value: "distance", sortable: false },
        { text: "RX SNR (db)", value: "rx_snr", sortable: false },
        { text: "RX MODCOD", value: "modcod", sortable: false },
        { text: "TX Power(db)", value: "tx", sortable: false },
        { text: "SatNet", value: "satnet", sortable: false },
        { text: "Date/Time (GMT+7)", value: "dateTime", sortable: false },
        { text: "Link Status", value: "good", sortable: false },
      ],
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
        },
        { text: "Latitude, Longtitude (DMS)", value: "lat", sortable: false },
        {
          text: "",
          align: "start",
          sortable: false,
        },
        { text: "Heading (Degree)", value: "heading", sortable: false },
        { text: "Speed (Knots)", value: "speed", sortable: false },
        { text: "Distance(Nmi)", value: "distance", sortable: false },
        { text: "RX SNR (db)", value: "rx_snr", sortable: false },
        { text: "RX MODCOD", value: "modcod", sortable: false },
        { text: "TX Power(db)", value: "tx", sortable: false },
        { text: "SatNet", value: "satnet", sortable: false },
        { text: "Date/Time (GMT+7)", value: "dateTime", sortable: false },
        { text: "Link Status", value: "good", sortable: false },
      ],

      headersUser: [
        {
          text: "#",
          align: "start",
          sortable: false,
        },
        { text: "Name", value: "name", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Role", value: "role_name", sortable: false },
        { text: "Last Login", value: "lastLogin", sortable: false },
      ],

      options: {
        disableDefaultUI: true,
        fullscreenControl: true,
        zoomControl: true,
        styles: mapstyle,
      },
      model: 0,
      etaArrival: "",
      etaDuration: 0,
      etaSpeed: 1,
      etaMiles: 0,
      unit: "nauticalmiles",
      satellite: require("@/assets/img/satellite.png"),
      imageOpen: null,
      totalDistance: null,
      imageData: null,
      msg_id_after: null,
      paths_cns11: cns11,
      gain_cns11: cns11gain,
      paths_cns11gnt: cns11_gnt,
      gain_cns11gnt: cns11_gntgain,
      paths_apstar5: apstar5,
      gain_apstar5: apstar5gain,
      paths_apstar5gnt: apstar5_gnt,
      gain_apstar5gnt: apstar5_gntgain,
      paths_apstar9: apstar92,
      gain_apstar9: apstar92gain,
      paths_apstar9gnt: apstar92_gnt,
      gain_apstar9gnt: apstar92_gntgain,
      paths_abs: abs,
      gain_abs: absgain,
      paths_absgnt: abs_gnt,
      gain_absgnt: abs_gntgain,
      paths_ocn: ocn,
      gain_ocn: ocngain,
      paths_ocngnt: ocn_gnt,
      gain_ocngnt: ocn_gntgain,
      paths_hts: hts,
      gain_hts: htsgain,
      paths_htsgnt: hts_gnt,
      gain_htsgnt: hts_gntgain,
      paths_beam3: beam3,
      gain_beam3: beam3gain,
      paths_beam3gnt: beam3_gnt,
      gain_beam3gnt: beam3_gntgain,
      paths_hrz: horizon,
      gain_hrz: horizongain,
      paths_hrzgnt: horizon_gnt,
      gain_hrzgnt: horizon_gntgain,
      paths_utl: utelsat,
      gain_utl: utelsatgain,
      paths_utlgnt: utelsat_gnt,
      gain_utlgnt: utelsat_gntgain,
      EIRP: null,
      GNT: null,
      SKYR1: null,
      SKYR2: null,
      APST9: null,
      ABS2A: null,
      SR1MR: null,
      SR2MR: null,
      BEAM3: null,
      UTL70B: null,
      HRZ: null,
      polygon: {
        skyr1: "green",
        skyr2: "blue",
        apst9: "purple",
        abs2a: "orange",
        sr1mr: "#F7038B",
        beam3: "#DB6B0F",
        hrz: "#DBC70f",
        utl: "#0CAD93"
      },
    };
  },


  mounted() {
    var mama = this;
    if (!mama.sailink_executed) {
      //console.log("jalan title map");
      mama.addsailink_executed(true);
      $("html, body").animate(
        {
          scrollTop: $("#title_map_sailink").offset().top - 109,
        },
        800
      );
    }
  },

  methods: {
    ...mapActions([
      "removeSatnetOutput",
      "removeQuota",
      "showondate",
      "lnStatus",
      "showweather",
      "removeShowMap",
      "fetchQuota",
      "addsailink_executed",
      "post_switch_satnet",
    ]),

    //moving marker
    initialdata_moving() {
      var mother = this
      mother.drivePath = mother.showmaptracking.tracking.slice()
      mother.driveLatLng = L.latLng([mother.drivePath[0].lat, mother.drivePath[0].lng])
      mother.driveRotationAngle = mother.drivePath[0].heading
    },

    post_switch_satnet_(){
      // console.log(this.ip_adr.ip_addr)
      const data = {
        ip_addr : this.ip_adr.ip_addr_val,
        iq_series :this.ip_adr.iq_series,
        satnet_id : this.satnet_id,
        token:localStorage.getItem("token"),
      };
      // console.log(data);
      this.post_switch_satnet(data);
      setTimeout(function () {
        this.satnet_id = null;
      }, 4000);
    },

    logtest(date) {
      console.log('DI CLICK')
      console.log(date)
      this.testdisabled = true;
      this.timeopen = true;
    },

    saveTimes() {
      this.times.push(this.timein);
      this.timein = null;
      this.timeopen = false;
      this.testdisabled = false;
    },

    //moving marker
    newAnimation() {
      this.drivePath = null;
      clearInterval(this.IntervalMoving);
      this.initialdata_moving()
    },



    //moving marker
    movingStart() {
      var mother = this
      this.IntervalMoving = setInterval(function () {
        mother.simulate()
      }, 250);

    },


    //moving marker
    simulate() {
      this.countLength = this.countLength + 1
      // console.log(this.countLength)
      // console.log(this.drivePath)


      if (!this.drivePath.length) {
        this.$refs.driveMarker.mapObject.setLatLng(L.latLng([this.drivePath[0].lat, this.drivePath[0].lng]))
        this.drivePath = this.drivePath.slice()
        return
      }
      let point = this.drivePath.shift()
      this.driveLatLng = L.latLng([point.lat, point.lng])
      this.driveRotationAngle = point.heading
      // console.log(this.driveRotationAngle)


      if (this.drivePath.length == 0 || !this.drivePath) {
        clearInterval(this.IntervalMoving);
        this.drivePath = null;
      }

    },

    triggerFullscreen() {
      if (this.$refs["map_sailink"].mapObject._isFullscreen != true) {
        this.$refs.map_sailink.mapObject.scrollWheelZoom.disable();
      } else {
        this.$refs.map_sailink.mapObject.scrollWheelZoom.enable();
      }
    },

    intoFilter(pagination) {
      this.totalFilter = pagination.itemsLength;
    },

    intoFilterUser(pagination) {
      this.totalFilterUser = pagination.itemsLength;
    },

    closeShowMap() {
      clearInterval(this.IntervalMoving);
      this.removeShowMap();
      $("html, body").animate(
        {
          scrollTop: $("#top_mysailink").offset().top - 140,
        },
        800
      );
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    closeETA() {
      this.etaControl = false;
      this.clearMeasure();
      this.totalDistance = 0;
      this.showonMarker = false;
      this.showRoute = false;
      if (this.$refs["map_sailink"].mapObject._isFullscreen != true) {
      } else {
        this.$refs["map_sailink"].mapObject.toggleFullscreen();
      }
    },

    draw_Circle() {
      var myMap = this.$refs.map_sailink.mapObject;
      var latLong = this.showmaptracking.tracking;
      var pointList = this.showmaptracking.tracking;

      const lightbox = new PhotoSwipeLightbox({
        gallery: '#sailink-gallery-container',
        children: 'a',
        pswpModule: () => import('photoswipe')
      });
      lightbox.init();





      var mama = this;
      var firstpolyline = new L.Polyline(pointList, {
        color: "#FEE800",
        weight: 3,
        opacity: 1,
        smoothFactor: 1,
      });
      firstpolyline.addTo(myMap);
      // Here's where you iterate over the array of coordinate objects.
      latLong.forEach(function (coord) {
        if (
          coord.lat != mama.showmaptracking.maptop.lat &&
          coord.lng != mama.showmaptracking.maptop.lon
        ) {
          var circle = L.circle(coord, {
            color: "#1DC01D",
            fillColor: "#1DC01D",
            fillOpacity: 0.5,
            radius: 1100,
          })
            .addTo(myMap)
            .bindPopup(
              "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
              coord.lat +
              "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
              coord.lng +
              "</td></tr><tr>" +
              "<td><b>DMS</b></td><td>:</td>" +
              "<td>" +
              coord.dms +
              "</td>" +
              "</tr>" +
              "<tr>" +
              "<td><b>Speed</b></td>" +
              "<td>:</td>" +
              " <td>" +
              coord.speed +
              "&nbsp;knots </td>" +
              "</tr>" +
              "<tr>" +
              " <td><b>Heading</b></td>" +
              "<td>:</td>" +
              "<td>" +
              coord.heading +
              "</td>" +
              " </tr>" +
              "<tr>" +
              "<td><b>Last Update</b></td>" +
              "<td>:</td>" +
              "<td>" +
              coord.updated +
              "</td>" +
              "</tr>" +
              "</table>"
            );
        }
      });
      var mom = this
      this.newAnimation()
      setTimeout(
        function () {
          mom.movingStart()
        }, 1000);



    },

    triggerPopup_mobile(coord) {
      //console.log(coord);
      $("html, body").animate(
        {
          scrollTop: $("#map_sailink_").offset().top - 109,
        },
        800
      );
      var myMap = this.$refs.map_sailink.mapObject;
      var popLocation = new L.LatLng(coord.lat, coord.lng);
      myMap.setView(popLocation, 6);
      var popup = L.popup()
        .setLatLng(popLocation)
        .setContent(
          "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
          coord.lat +
          "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
          coord.lng +
          "</td></tr><tr>" +
          "<td><b>DMS</b></td><td>:</td>" +
          "<td>" +
          coord.dms +
          "</td>" +
          "</tr>" +
          "<tr>" +
          "<td><b>Speed</b></td>" +
          "<td>:</td>" +
          " <td>" +
          coord.speed +
          "&nbsp;knots </td>" +
          "</tr>" +
          "<tr>" +
          " <td><b>Heading</b></td>" +
          "<td>:</td>" +
          "<td>" +
          coord.heading +
          "</td>" +
          " </tr>" +
          "<tr>" +
          "<td><b>Last Update</b></td>" +
          "<td>:</td>" +
          "<td>" +
          coord.dateTime +
          "</td>" +
          "</tr>" +
          "</table>"
        )
        .openOn(myMap);
    },

    triggerPopup(coord) {
      //console.log(coord);
      $("html, body").animate(
        {
          scrollTop: $("#title_map_sailink").offset().top - 109,
        },
        800
      );
      var myMap = this.$refs.map_sailink.mapObject;
      var popLocation = new L.LatLng(coord.lat, coord.lng);
      myMap.setView(popLocation, 6);
      var popup = L.popup()
        .setLatLng(popLocation)
        .setContent(
          "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
          coord.lat +
          "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
          coord.lng +
          "</td></tr><tr>" +
          "<td><b>DMS</b></td><td>:</td>" +
          "<td>" +
          coord.dms +
          "</td>" +
          "</tr>" +
          "<tr>" +
          "<td><b>Speed</b></td>" +
          "<td>:</td>" +
          " <td>" +
          coord.speed +
          "&nbsp;knots </td>" +
          "</tr>" +
          "<tr>" +
          " <td><b>Heading</b></td>" +
          "<td>:</td>" +
          "<td>" +
          coord.heading +
          "</td>" +
          " </tr>" +
          "<tr>" +
          "<td><b>Last Update</b></td>" +
          "<td>:</td>" +
          "<td>" +
          coord.dateTime +
          "</td>" +
          "</tr>" +
          "</table>"
        )
        .openOn(myMap);
    },

    toDegreesMinutesAndSeconds(coordinate, pad) {
      var absolute = Math.abs(coordinate);
      var degrees = Math.floor(absolute);
      var minutesNotTruncated = (absolute - degrees) * 60;
      var minutes = Math.floor(minutesNotTruncated);
      var seconds = Math.floor((minutesNotTruncated - minutes) * 60);
      //eslint-disable-next-line
      return (
        String(degrees).padStart(pad, "0") +
        "°" +
        String(minutes).padStart(2, "0") +
        " ' " +
        String(seconds).padStart(2, "0") +
        '"'
      );
    },

    convertDMS(lat, lng) {
      var latitude = this.toDegreesMinutesAndSeconds(lat, 2);
      var latitudeCardinal = lat >= 0 ? "N" : "S";

      var longitude = this.toDegreesMinutesAndSeconds(lng, 3);
      var longitudeCardinal = lng >= 0 ? "E" : "W";

      return (
        latitude +
        " " +
        latitudeCardinal +
        "-" +
        longitude +
        " " +
        longitudeCardinal
      );
    },

    fetchQdialy(service) {
      this.qdaily = true;
      var data = {
        vessel_id: this.showmaptable.id,
        service: service,
      };
      this.fetchQuota(data);
    },

    fullscreen() {
      $(".leaflet-control-fullscreen-button").trigger("click");
    },

    clearMeasure() {
      console.log('SELESAI');
      this.$refs["polylineMeasure"].mapObject._clearAllMeasurements();
      this.$refs["polylineMeasure"].mapObject._toggleMeasure();
      this.etaMiles = 0;
      this.etaDuration = 0;
      this.etaArrival = "";
      this.showRouteData = null;
    },

    handleToggle(toogle) {
      console.log("ke tugel");
      this.onDraw = toogle.sttus
      console.log(toogle.sttus)

    },

    handleFinish() {
      console.log("ITS FINISH");
      this.onCount();
      this.onFinish = true;
    },

    //Start Draw [ETA]
    startDraw() {

      if (this.onDraw == true && this.showRouteData != null) {
        this.clearMeasure();
      }
      this.$refs["polylineMeasure"].mapObject._toggleMeasure();
      if (this.showonMarker) {
        var latLng = L.latLng([
          parseFloat(this.showmaptracking.maptop.lat),
          parseFloat(this.showmaptracking.maptop.lon)
        ]);

        this.$refs["polylineMeasure"].mapObject._startLine(latLng);
        this.$refs["polylineMeasure"].mapObject._mouseMove({ latLng });
        this.$refs["polylineMeasure"].mapObject._currentLine.addPoint(latLng);
      }
    },

    onCount_move() {
      console.log('HITUNG ROUTE');
      console.log(this.currentLine)
      console.log(this.$refs["polylineMeasure"].mapObject._arrPolylines[0].circleCoords)
      this.currentLine = this.$refs["polylineMeasure"].mapObject._arrPolylines;
      this.coords = this.$refs["polylineMeasure"].mapObject._arrPolylines[0].circleCoords
      this.toolTipsArr = this.$refs["polylineMeasure"].mapObject._arrPolylines[0].tooltips;
      this.showRouteData = this.coords.map((item, index) =>
        Object.assign(item, { index })
      );
      this.brRouteData = this.toolTipsArr;
      var miles = 0;
      this.coords.forEach((coor, i) => {
        this.totalDistance = this.toolTipsArr[i]._icon.childNodes[4].innerText;
        miles = parseFloat(this.toolTipsArr[i]._icon.childNodes[4].innerText);
      });

      this.etaMiles = miles;
      var duration_raw = miles / this.etaSpeed;
      var duration = duration_raw.toFixed(2);
      var departDateInput = $("#departure-sailink").val();
      var today = new Date(departDateInput);
      var seconds = Math.round(duration * 60 * 60);
      today.setSeconds(today.getSeconds() + seconds);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      var h = today.getHours();
      var i = today.getMinutes();

      if (h < 10) {
        h = "0" + h;
      }

      if (i < 10) {
        i = "0" + i;
      }

      today = dd + "/" + mm + "/" + yyyy + " " + h + ":" + i;
      this.etaDuration = duration;
      this.etaArrival = today;
    },

    onCount() {
      console.log('HITUNG ROUTE');
      console.log(this.currentLine);
      this.currentLine = this.$refs["polylineMeasure"].mapObject._currentLine;
      this.coords = this.$refs["polylineMeasure"].mapObject._currentLine.circleCoords;
      this.toolTipsArr = this.$refs["polylineMeasure"].mapObject._currentLine.tooltips;
      this.showRouteData = this.coords.map((item, index) =>
        Object.assign(item, { index })
      );
      this.brRouteData = this.toolTipsArr;
      var miles = 0;
      this.coords.forEach((coor, i) => {
        this.totalDistance = this.toolTipsArr[i]._icon.childNodes[4].innerText;
        miles = parseFloat(this.toolTipsArr[i]._icon.childNodes[4].innerText);
      });

      this.etaMiles = miles;
      var duration_raw = miles / this.etaSpeed;
      var duration = duration_raw.toFixed(2);

      var departDateInput = $("#departure-sailink").val();
      var today = new Date(departDateInput);
      var seconds = Math.round(duration * 60 * 60);
      today.setSeconds(today.getSeconds() + seconds);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      var h = today.getHours();
      var i = today.getMinutes();

      if (h < 10) {
        h = "0" + h;
      }

      if (i < 10) {
        i = "0" + i;
      }

      today = dd + "/" + mm + "/" + yyyy + " " + h + ":" + i;
      this.etaDuration = duration;
      this.etaArrival = today;
    },

    onCountTotal() {
      console.log('HITUNG ROUTE');
      console.log(this.currentLine);
      this.showRouteData = this.coords.map((item, index) =>
        Object.assign(item, { index })
      );
      this.brRouteData = this.toolTipsArr;
      var miles = 0;
      this.coords.forEach((coor, i) => {
        this.totalDistance = this.toolTipsArr[i]._icon.childNodes[4].innerText;
        miles = parseFloat(this.toolTipsArr[i]._icon.childNodes[4].innerText);
      });

      this.etaMiles = miles;
      var duration_raw = miles / this.etaSpeed;
      var duration = duration_raw.toFixed(2);
      var departDateInput = $("#departure-sailink").val();
      var today = new Date(departDateInput);
      var seconds = Math.round(duration * 60 * 60);
      today.setSeconds(today.getSeconds() + seconds);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      var h = today.getHours();
      var i = today.getMinutes();

      if (h < 10) {
        h = "0" + h;
      }

      if (i < 10) {
        i = "0" + i;
      }

      today = dd + "/" + mm + "/" + yyyy + " " + h + ":" + i;
      this.etaDuration = duration;
      this.etaArrival = today;
    },

    countETA() {
      var duration_raw = this.etaMiles / this.etaSpeed;
      var duration = duration_raw.toFixed(2);
      var departDateInput = $("#departure-sailink").val();
      var today = new Date(departDateInput);
      var seconds = Math.round(duration * 60 * 60);
      today.setSeconds(today.getSeconds() + seconds);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      var h = today.getHours();
      var i = today.getMinutes();

      if (h < 10) {
        h = "0" + h;
      }

      if (i < 10) {
        i = "0" + i;
      }

      today = dd + "/" + mm + "/" + yyyy + " " + h + ":" + i;
      //console.log("arrival date " + today);
      this.etaDuration = duration;
      this.etaArrival = today;
    },

    customRange() {

      var tglcurrent = new Date();
      var tgldate1 = new Date(this.dates[0] + ' ' + this.times[0] + ':00');
      var tgldate2 = new Date(this.dates[1] + ' ' + this.times[1] + ':00');



      if (tgldate1.getTime() <= tglcurrent.getTime() && tgldate2.getTime() <= tglcurrent.getTime()) {
        if (tgldate1 > tgldate2) {
          this.cant_backwards = true
          this.resetTimesDates();
          setTimeout(() => {
            this.cant_backwards = false
          }, 5000)
        } else {
          this.dateopen = false;
          this.cant_future = false
          // var range_date = this.dates.join(" ");
          var range1 = this.dates[0].split('-');
          var range1_ = range1.join('_');

          var range2 = this.dates[1].split('-');
          var range2_ = range2.join('_');

          var timerange1 = this.times[0].split(':')
          var timerange1_ = timerange1.join('m')

          var timerange2 = this.times[1].split(':')
          var timerange2_ = timerange2.join('m')

          var range_date = range1_ + ' ' + timerange1_ + ' - ' + range2_ + ' ' + timerange2_;
          console.log(range_date);
          this.resetTimesDates();
          this.showdate(this.datepickerID, range_date);
        }

      } else {
        this.cant_future = true
        this.resetTimesDates();
        setTimeout(() => {
          this.cant_future = false
        }, 5000)
      }

    },

    closeCostumRange() {
      this.dateopen = false
      this.resetTimesDates();
    },

    resetTimesDates() {
      console.log('di reset')
      this.dates = []
      this.times = []
    },

    createCSV() {

      var parts = this.expsailink_title.split(' ').join('_');
      var rangedate = this.showmaptracking.range.actual.split(' ').join('_')
      let pdfName = parts + "_Tracking_Reports_" + rangedate;

      var ln_status;
      var tableTracking = this.tableTracking.map((item, index) =>
        Object.assign(item, { index })
      );
      let data = [];
      let header = [
        ["#"],
        ["latitude"],
        ["longitude"],
        ["DMS"],
        ["Heading (Degree)"],
        ["Speed (Knots)"],
        ["Distance (Nmi)"],
        ["RX SNR (db)"],
        ["RX MODCOD"],
        ["TX Power (db)"],
        ["SatNet"],
        ["Date/Time (GMT+7)"],
        ["Link Status"],
      ];

      tableTracking.forEach((element) => {
        if (element.isActive == 1) {
          ln_status = "UP";
        } else {
          ln_status = "DOWN";
        }
        data.push([
          [element.index + 1],
          [element.lat, element.lng, `(` + element.dms + `)`],
          [element.heading],
          [element.speed],
          [element.distance],
          [element.rx_snr],
          [element.modcod],
          [element.tx],
          [element.satnet],
          [element.dateTime],
          [ln_status],
        ]);
      });

      let csvContent = data.map((e) => e.join(",")).join("\n");

      // var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent));
      link.setAttribute("download", pdfName + ".csv");
      document.body.appendChild(link); // Required for FF
      link.click();
    },

    showdate(item, range) {
      clearInterval(this.IntervalMoving);
      this.exp_range = range;
      this.countLength = 0;
      const data = {
        id: item,
        range: range,
        token: localStorage.getItem("token"),
      };

      this.showondate(data);
    },

    linkStatus(id) {
      const dataLn = {
        id: id,
        token: localStorage.getItem("token"),
      };

      this.lnStatus(dataLn);
    },

    async showwth(vid, msgid, type) {
      this.msg_id_after = msgid;
      const data = {
        id: vid,
        msg_id: msgid,
        type: type,
      };
      this.showweather(data);
    },

    // eta button clicked
    openETA() {
      this.etaControl = !this.etaControl;
      let now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      $("#departure-sailink").val(now.toISOString().slice(0, 16));
      if (this.$refs["map_sailink"].mapObject._isFullscreen != true) {
        this.$refs["map_sailink"].mapObject.toggleFullscreen();
      } else {
        //console.log("ETA ALREADY OPEN");
      }
    },

    removeWeather(dateTime) {

      this.$delete(this.showclick_dateTime, dateTime);
      this.$delete(this.weatherData, dateTime);
    },

    async weatherClick(id, msgid, type, dateTime) {

      this.$set(this.showclick_dateTime, dateTime, dateTime);
      const datawth = {
        id: id,
        msg_id: msgid,
        type: type,
      };
      const response = await axios.post(
        "https://navigatorplus.sailink.id/api/v1/navplus/weather/fetchRow",
        datawth,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      console.log('FETCH WEATHER')
      console.log(response);
      this.$set(this.weatherData, dateTime, response.data.data);
    },
  },

  computed: {
    ...mapGetters([
      "switch_true",
      "satnet_output",
      "expsailink_title",
      "user_allowed",
      "sailink_executed",
      "searchVesselID",
      "serviceQuota",
      "quotaData",
      "showmaptable",
      "tableTracking",
      "vesselUsers",
      "ip_adr",
      "showmaptracking",
      "ln_status",
      "weather_data",
      "showmapclick",
      "startFrom",
    ]),

    showingDatafrom() {
      if (this.totalFilter < 1) {
        return 0;
      } else {
        return 1;
      }
    },

    isFullscreen_() {
      return this.map_sailink_fs;
    },

    showingDatato() {
      if (this.totalFilter < this.itemsPerPage) {
        return this.totalFilter;
      } else {
        return this.itemsPerPage;
      }
    },

    showingDatafromUser() {
      if (this.totalFilterUser < 1) {
        return 0;
      } else {
        return 1;
      }
    },

    showingDatatoUser() {
      if (this.totalFilterUser < this.itemsPerPageUser) {
        return this.totalFilterUser;
      } else {
        return this.itemsPerPageUser;
      }
    },

    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    mapConfig() {
      return {
        ...mapSettingsShow,
        center: this.mapCenter,
      };
    },

    mapCenter() {
      return {
        lat: parseFloat(this.showmaptable.maptop.lat),
        lng: parseFloat(this.showmaptable.maptop.lon),
      };
    },
  },
};
</script>

<style lang="scss" scopped>
@import "@/assets/css/showmapsailink.css";
@import "@/assets/css/navigator.css";
@import "@/assets/css/showmapSailink_.scss";
</style>