<template>
    <app>
  <v-dialog class="elevation-0 rounded-0" v-model="cruserOpen" width="70vh" persistent>
    <v-card class="elevation-0 rounded-0">
      <v-toolbar class="rounded-0 elevation-0" color="#f7f7f7" dense >
        <v-col class="header_modal">
          <v-row class="notifquota"
            ><v-toolbar-title>Create User</v-toolbar-title></v-row
          >
        </v-col>

        <v-spacer></v-spacer>

        <v-btn
          small
          class="elevation-0 rounded-0"
          icon
      
          @click="close_createuser()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text style="margin-top: 30px">
      
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-text-field
            @change="at_change()"
            label="Name"
            v-model="cuserData.name"
            class="elevation-0 rounded-0 pa-1"
            :rules="nameRules"
            required
            outlined
            dense
          ></v-text-field>
          <v-text-field
          @change="at_change()"
            label="Email Address"
            v-model="cuserData.mail"
            class="elevation-0 rounded-0 pa-1"
            :rules="emailRules"
            required
            outlined
            dense
          ></v-text-field>
       
          <v-select
            v-if="!ownerVessel"
            label="Loading Ship Owner.."
            class="elevation-0 rounded-0 pa-1"
            outlined
            item-text="name"
            dense
            required
          ></v-select>
          <v-select
          @change="at_change()"
            v-if="ownerVessel"
            :items="ownerVessel"
            v-model="ownerVessel"
            :label="labelSelectOwner"
            class="elevation-0 rounded-0 pa-1"
            outlined
            item-value="vessels"
            item-text="name"
            dense
            required
            :menu-props="{
              closeOnClick: true,
              closeOnContentClick: true,
            }"
          >
            <template v-slot:item="{ item, props }">
              <v-list-item
                @click="sendOwnerData_(item.email, item.name, item.vessels)"
                v-bind="props"
              >
                {{ item.name }}
              </v-list-item></template
            >
          </v-select>

          <v-card
            style="border: 1px solid #9e9e9e"
            height="fit-content"
            class="elevation-0 rounded-0 pa-0 pb-0"
          >
            <v-toolbar
              class="elevation-0 rounded-0"
              height="30"
              dense
              color="#f7f7f7"
              
            >
              <v-toolbar-subtitle>Sailink Vessel</v-toolbar-subtitle>
            </v-toolbar>
            <v-select
            @change="at_change()"
              class="elevation-0 rounded-0 pa-0 ma-2 mb-0"
              v-if="sailinkvessel_"
              v-model="cuserData.vessels"
              :items="sailinkvessel_"
              :value="items"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              label="Select one or more vessel"
              multiple
              persistent-hint
              style="justify-content: left; text-align: left"
              required
            ></v-select>
            <v-select
              class="elevation-0 rounded-0 pa-0 ma-2 mb-0"
              v-if="!sailinkvessel_"
              :value="items"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              label="Please select ship owner first"
              multiple
              persistent-hint
              style="justify-content: left; text-align: left"
              required
            ></v-select>
          </v-card>

          <v-text-field
          @change="at_change()"
          :append-icon="confirshow ? 'mdi-eye' : 'mdi-eye-off'"
            :type="confirshow ? 'text' : 'password'"
            @click:append="
              (confirshow = !confirshow)
            "
            style="margin-top: 20px"
            label="Password"
            v-model="cuserData.pass"
            class="elevation-0 rounded-0 pa-1"
           :rules="passwordRules"
            outlined
            dense
          ></v-text-field>
          <v-text-field
          @change="at_change()"
          :append-icon="confirshow_cr ? 'mdi-eye' : 'mdi-eye-off'"
            :type="confirshow_cr ? 'text' : 'password'"
            @click:append="
              (confirshow_cr = !confirshow_cr)
            "
            label="Confirm Password"
            v-model="cuserData.confirmpass"
            class="elevation-0 rounded-0 pa-1"
            :rules="confirmPasswordRules"
            outlined
            dense
          ></v-text-field>
        </v-form>
      </v-card-text>

      <v-card-actions
        >
        
        <v-btn
          v-if="!change_"
          style="cursor:not-allowed"
          small
          class="elevation-0 rounded-0"
          dark
          color="#9CBDDE"
          block
        >
          Create
        </v-btn>
        <v-btn
          v-if="change_"
          @click=" !valid ? console.log('invalid') : cruser_confirm=true"
          :style="!valid ? 'cursor:not-allowed' : 'cursor:pointer'"
          small
          class="elevation-0 rounded-0"
          dark
          :color="!valid ? '#9CBDDE' : 'primary'"
          block
        >
          Create
        </v-btn></v-card-actions
      >

      <v-card-actions class="justify-end"> </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog class="rounded-0 elevation-0" v-model="cruser_confirm" persistent max-width="290">
      <v-card class="rounded-0 elevation-0">
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text style="text-align: left"
          >New user will created after you click yes</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="cruser_confirm = false">
            No
          </v-btn>
          <v-btn
            color="green"
            text
            @click="
              createUserPost(), close_createuser()
            "
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
</app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
    
        cruser_confirm:false,
        confirshow: false,
        confirshow_cr: false,
      change_:null,
      valid: null,
      ownermail: "",
      labelSelectOwner: "-- Ship Owner --",
      nameRules: [
        v => !!v || 'Name is required',
        v => (v && v.length > 1) || 'Name must be more than 1 characters',
        v => (v && v.length <= 30) || 'Name must be less than 40 characters',
      ],
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      passwordRules: [
        v => !!v || 'Please type password.',
        v => (v && v.length >= 8) || 'minimum 8 characters',
      ],
      confirmPasswordRules: [
        v => !!v || 'type confirm password',
        v => v === this.cuserData.pass || 'The password confirmation does not match.',
      ],
    };
  },

  // mounted(){
  //     this.$nextTick(() => {
  //       console.log(this.ownerVessel)
  //    })
   
    
  // },
  
  methods: {
    ...mapActions([
      "postCreateUser",
      "set_sailink_Vessel_",
      "removecruserOpen",
      "fetchAdmin",
    ]),

    reset () {
        this.$refs.form.reset()
    },

    at_change (){
        if(this.cuserData.name && this.cuserData.mail && this.cuserData.pass && this.cuserData.confirmpass && this.cuserData.vessels && this.ownermail){
            this.change_=true
        }else{
            this.change_=null
        }
       
    },

    close_createuser(){
        this.removecruserOpen()
        this.reset () 
        this.change_= null
        this.cruser_confirm = false
    },

    createUserPost() {
      console.log("CREATE USER POST");
      var Email_owner ;
      if(this.user_allowed.role_id != 3){
        Email_owner = this.ownermail
      }else{
        Email_owner = this.user_allowed.email
      }
      const data = {
        name: this.cuserData.name,
        email: this.cuserData.mail,
        password: this.cuserData.pass,
        role: "4",
        vessels: this.cuserData.vessels,
        owner: Email_owner,
        password_confirmation: this.cuserData.confirmpass,
        token: localStorage.getItem("token"),
      };
      console.log(data);
      this.postCreateUser(data);
      var mother = this;
      setTimeout(function () {
        mother.fetchAdmin(localStorage.getItem("token"));
      }, 5000);
    },

    sendOwnerData_(email, name, vessel) {
      this.ownermail = email;
      this.labelSelectOwner = name;
      this.set_sailink_Vessel_(vessel);
    },
  },
  computed: {
    ...mapGetters(["cuserData", "ownerVessel", "cruserOpen", "sailinkvessel_","user_allowed"]),
  },
};
</script>

<style lang="scss" scoped></style>
