<template>
    <v-dialog v-model="admOpen" class="elevation-0 rounded-0" width="550" persistent>
    <v-card class="elevation-0 rounded-0">
      <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0" dense >
        Thuraya Administrator Password

        <v-spacer></v-spacer>

        <v-btn class="rounded-0 elevation-0" small icon  @click="closeAdm()">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar v-if="admData" class="elevation-0 rounded-0" >
      
        <v-toolbar-subtitle style="text-align: left"
          >{{ admData.vessel_number }}
          <br />
          <div v-if="admData" style="text-align: left">
            <v-chip
              v-if="admData.latest_status == 'FAILED'"
              class="ma-0 elevation-0 rounded-0"
              color="red"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-close-thick </v-icon>
              Failed to change Settings at {{ admData.last_updated }}, please
              resubmit
            </v-chip>
            <v-chip
              v-if="admData.latest_status == 'ON AIR'"
              class="ma-0 elevation-0 rounded-0"
              color="yellow"
              x-small
              label
              text-color="black"
            >
              <v-icon x-small left> mdi-clock </v-icon>
              Waiting for Confirmation, sent at {{ admData.last_updated }}
            </v-chip>
            <v-chip
              v-if="admData.latest_status == 'CONFIRMED'"
              class="ma-0 elevation-0 rounded-0"
              color="green"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-check-bold </v-icon>
              Updated at {{ admData.last_updated }}
            </v-chip>
          </div>
        </v-toolbar-subtitle>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider v-if="admData"></v-divider>

      <v-container style="margin-top: 2em" v-if="!admmodal" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>
      <v-card-text v-if="admmodal" style="margin-top:0em">
        <v-card-text>
          <v-radio-group  v-model="admData.deffPass">
            <v-radio @click="checkdata(true)" :disabled="admData.latest_status == 'ON AIR'?true : false" label="Default Password" :value="true"></v-radio>
            <v-radio @click="checkdata(false)" :disabled="admData.latest_status == 'ON AIR'?true : false" label="Random Password" :value="false"></v-radio>
          </v-radio-group>
        </v-card-text>
        
        <v-btn v-if="admData.latest_status != 'ON AIR'" small :style="admData.latest_status == 'ON AIR'?'cursor:not-allowed':'cursor:pointer'" :disabled="changes_? false : true" class="elevation-0 rounded-0" @click="admDataPost()" color="primary" block>
          Update Admin Password
        </v-btn>
        <v-btn v-if="admData.latest_status == 'ON AIR'" small :style="admData.latest_status == 'ON AIR'?'cursor:not-allowed':'cursor:pointer'" :disabled="true" class="elevation-0 rounded-0" color="primary" block>
          Update Admin Password
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
 </template>
 
 <script>
 import { mapGetters, mapActions } from "vuex";
 import axios from "../../plugins/axios";
 export default {
   data() {
     return {
       admmodal:null,
       change: null,
     
       loadernavplus: require("@/assets/img/load100new.gif"),
       fetchInterval: null,
     };
   },
   mounted() {
     this.admFetchStatic()

   },
   methods: {
     ...mapActions(["admFetch", "admPost", "admRemove"]),
     checkdata(value) {
      console.log(value)
      console.log(this.admmodal);
      console.log(this.admData.deffPass);
      if (this.admmodal.deffPass == value) {
        this.change = false;
      } else {
        this.change = true;
      }
    },
     async admDataPost() {
      this.change = false;
        if (this.admData.deffPass == false) {
        const data = {
          thuraya_number: this.admData.number,
          passRadio: 1,
          token:localStorage.getItem("token"),
        };
        this.admPost(data)     
      }

      if (this.admData.deffPass == true) {
        const data = {
          thuraya_number: this.admData.number,
          passRadio: 0,
          token:localStorage.getItem("token"),
        };       
        this.admPost(data)    
      }
       var mother = this;
       var intervalSeconds = 0.5;
       this.fetchInterval = setInterval(function () {
         mother.admDataFetch();
       }, intervalSeconds * 1000);
     },
 
     stopFetch() {
       console.log("STOP FETCH STATUS ADMIN PASSWORD");
       clearInterval(this.fetchInterval);
       this.on = false;
     },

     closeAdm(){
      this.admRemove()
       this.stopFetch()
     },
 
     async admDataFetch() {
       console.log("FETCH TERUS STATUS ADMIN PASSWORD");
       console.log("STATUS :");
       console.log(this.admData.latest_status);
       // this.wlModal(true);
       const data = {
         id: this.admID,
         token: localStorage.getItem("token"),
       };
       this.admFetch(data);
       var mother=this
       setTimeout(function () {
      

       if (mother.admData) {
          if (mother.admData.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.admFetchStatic();
          }
        } else {
          if (mother.admmodal.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.admFetchStatic();
          }
        }
     }, 5000);
      
     },

     async admFetchStatic() {
      try {
        const response = await axios.get(
            "https://navigatorplus.sailink.id/api/v1/navplus/thuraya/" +
            this.admID +
              "/pass",
            {
              headers: {
                Authorization: "Bearer" + localStorage.getItem("token"),
              },
            }
          );
        console.log(response)
        this.admmodal = response.data.data;
        var mother = this;
        var intervalSeconds = 0.5;
        if (response.data.data.latest_status == "ON AIR") {
          this.fetchInterval = setInterval(function () {
            mother.admDataFetch();
          }, intervalSeconds * 1000);
        }
      } catch (e) {
        console.log(e);
      }
    },
   },
 
   computed: {
     ...mapGetters(["admData", "admID", "admOpen","user_allowed"]),
     changes_() {
      return this.change;
    },
   },
 };
 </script>
 
 <style lang="scss" scoped></style>
 


