<template>
  <v-dialog v-model="notifsnapopen" width="600" persistent>
    <v-card class="elevation-0 rounded-0">
      <v-toolbar class="elevation-0 rounded-0" style="text-align: left;" color="#f7f7f7">
        <v-icon class="mr-2" small>mdi-cog</v-icon> Snapshot Notification Configuration
        <v-spacer></v-spacer>
        <v-btn small class="elevation-0 rounded-0" icon @click="removeSnapNotif()">
          <v-icon>mdi-close</v-icon>
        </v-btn>

      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar v-if="notifsnapData" class="elevation-0 rounded-0" dense>
        <v-toolbar-subtitle style="font-size: 0.8em;text-align: left;">{{ notifsnapData.vessel_pt }}</v-toolbar-subtitle>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider v-if="notifsnapData"></v-divider>

      <v-container style="margin-top: 0" v-if="!notifsnapmodal" fill-height fluid>
        <v-row align="center" justify="center">
          <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
        </v-row>
      </v-container>
      <v-card-text v-if="notifsnapmodal" class="mt-3">
        <v-container style="margin-top: 0" v-if="!notifsnapData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
          </v-row>
        </v-container>

        <div v-if="notifsnapData">
          <v-alert v-if="notifsn_success" class="elevation-0 rounded-0" text dense color="teal" icon="mdi-check-bold"
            style="font-size: 0.86em;text-align: left;">
            Successfully Updated Snapshot Notification Configuration
          </v-alert>

          <v-row no-gutters>
            <v-col cols="12" sm="3">
              <v-subheader class="pl-0" style="text-align: left;">Enables?</v-subheader>
            </v-col>
            <v-col cols="12" sm="9">
              <v-row no-gutters>
                <v-col cols="12" sm="12" class="pl-0" style="margin-left: -1em;">
                  <v-switch class="ml-0" style="margin-top: -0.2em;" @change="checkdata('email_enabled')" inset
                    v-model="notifsnapData.email_enabled" :label="`Enabled`"></v-switch>
                </v-col>
              </v-row>
            </v-col>

          </v-row>






          <v-row no-gutters>
            <v-col cols="12" sm="3">
              <v-subheader class="pl-0" style="text-align: left;">Email Notification</v-subheader>
            </v-col>
            <v-col cols="12" sm="9">
              <div>
                <v-textarea @input="checkdata('emails')" class="elevation-0 rounded-0" name="input-7-1"
                  v-model="notifsnapData.emails" outlined auto-grow></v-textarea>
                <div class="hint_">
                  *dipisahkan menggunakan spasi<br />E.g. noc@sailink.co.id skyreach@sailink.co.id
                </div>
              </div>
            </v-col>
          </v-row>
          <v-btn @click="notifsnapDataPost()" :disabled="changes_ ? false : true" color="primary"
            class="elevation-0 rounded-0" small block>
            Update Snapshot Notification
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "../../plugins/axios";
import $ from "jquery";
export default {
  data() {
    return {
      notifsnapmodal: null,
      change: false,
      loadernavplus: require("@/assets/img/load100new.gif"),
    };
  },
  methods: {
    ...mapActions(["fetchNotifSnap", "removeSnapNotif", , "notifSnapPost"]),
    checkdata(statis) {
      console.log(this.notifsnapmodal[statis]);
      if (this.notifsnapmodal[statis] == this.notifsnapData[statis]) {
        this.change = false;
      } else {
        this.change = true;
      }
    },
    async notifsnapDataPost() {
      this.change = false;
      const data = {
        id: this.notifsnapID,
        email_enabled: this.notifsnapData.email_enabled,
        emails: this.notifsnapData.emails,
        token: localStorage.getItem("token"),
      };
      this.notifSnapPost(data);
    },

    async notifSnapFetchStatic() {

      try {
        const response = await axios.get(
          "/api/v1/navplus/snapshotContact/" + this.notifsnapID,
          {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
            },
          }
        );

        console.log(response);
        this.notifsnapmodal = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
  },

  mounted() {
    this.notifSnapFetchStatic();
  },

  computed: {
    ...mapGetters(["notifsnapopen", "notifsnapData", "notifsnapID", "notifsn_success"]),
    changes_() {
      return this.change;
    },
  },
};
</script>
  
<style lang="scss" scoped>
.hint_ {
  font-size: 0.8em;
  text-align: left;
  margin-top: -3em;
  padding: 1em;
  padding-left: 0em;
  padding-bottom: 2em;
}
</style>
  