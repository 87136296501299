<template>
  <v-card class="elevation-0 mt-0 rounded-0">
    <v-card id="title_map_snapshot" v-if="loadingfolder"
      class="container_showonmap ma-3 elevation-0 rounded-0 mt-0 pb-3 mt-0" style="border: solid 1px #e0e0e0">
      <v-toolbar v-if="loadingfolder" color="#F7F7F7" dense class="elevation-0 rounded-0">
        <div style="text-align: left">
          {{ vesselData.name }} | {{ vesselData.pt_name }}
        </div>
        <v-spacer></v-spacer>

        <v-btn small class="elevation-0 rounded-0" icon @click="
          closeShowSnapshot(), (showpict = null), (showpictonclick = null)
          ">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>
      <v-card class="elevation-0 rounded-0 ma-3" color="#FFF" style="border: solid 1px #e0e0e0">
        <v-toolbar v-if="loadingfolder" color="#f7f7f7" class="elevation-0 rounded-0" dense>
          <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn :disabled="loading ? true : false" style="border: solid 1px #e0e0e0" small
                class="rounded-0 pa-1 elevation-0 ma-0" color="#ffff" v-bind="attrs" v-on="on">
                {{ kFormatter(itemsPerPageImage)
                }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                @click="(itemsPerPageImage = 25), getDataFromApi()"><v-list-item-title>25</v-list-item-title></v-list-item>
              <v-list-item
                @click="(itemsPerPageImage = 50), getDataFromApi()"><v-list-item-title>50</v-list-item-title></v-list-item>
              <v-list-item
                @click="(itemsPerPageImage = 75), getDataFromApi()"><v-list-item-title>75</v-list-item-title></v-list-item>
              <v-list-item
                @click="(itemsPerPageImage = 100), getDataFromApi()"><v-list-item-title>100</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
          <v-spacer style="width: 67%"></v-spacer>
          <v-icon class="mr-3">mdi-folder-outline</v-icon>{{ folderSelect }}
        </v-toolbar>

        <v-divider></v-divider>
        <v-toolbar v-if="loadingfolder && filterList != null && filterList.length > 0" height="fit-content"
          class="elevation-0 rounded-0">
          <div style="max-width: 90em">
            <v-btn class="elevation-0 rounded-0" @click="
              (showpictonclick = true),
              onFilterClick(filter.channel),
              (filter_btn_clicked = filter.id)
              " :disabled="loading ? true : false" style="margin-right: 0.9em; border: solid 1px #e0e0e0"
              :color="filter_btn_clicked == filter.id ? 'primary' : '#F7F7F7'" :key="filter.id"
              v-for="filter in filterList" small>
              {{ filter.label }}
            </v-btn>
          </div>
        </v-toolbar>
        <v-divider v-if="loadingfolder && filterList != null && filterList.length > 0"></v-divider>
        <v-data-table :headers="headersside" :items="desserts" :options.sync="options"
          :server-items-length="totalDesserts" :items-per-page="itemsPerPageImage" :loading="loading"
          :page.sync="pageImage" @page-count="pageCountImage = $event" hide-default-header hide-default-footer
          class="elevation-1 elevation-0 rounded-0 imagesnp">
          <template v-slot:item="{ item, index }">
            <tr style="border-bottom: none; margin-left: 0em">
              <td style="
                  padding-top: 0.2em;
                  padding-bottom: 0.2em;
                  background-color: #ffff;
                  border-bottom: none;
                ">
                <v-card style="border: solid 1px #e0e0e0; padding-bottom: -2em" class="elevation-0 rounded-0"
                  @click="onImageClick_(item, index)">

                  <img class="imgsnapshot" v-bind:src="item.src" style="cursor: pointer" /><br />
                  <div class="ma-1">
                    <div class="ma-0 ml-0" v-if="filterList.length == 0" style="text-align: left; font-size: 0.8em">
                      {{ item.label }}
                    </div>

                    <div class="ma-0 ml-0" v-if="filterList.length != 0" style="
                        text-align: center;
                        display: block;
                        text-align: left;
                        max-width: 10em;
                        font-size: 0.8em;
                      ">
                      <p>{{ item.label }}</p>
                      <p style="margin-top: -1em" class="wrap-it">
                        {{ item.chLabel }}
                      </p>
                    </div>
                  </div>
                </v-card>
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">
          <p class="mt-3" style="font-size: 0.8em">
            {{ infoImage }}
          </p>
          <v-spacer></v-spacer>
          <v-pagination :disabled="loading ? true : false" v-if="desserts" color="primary" v-model="pageImage"
            :length="pageCountImage" :total-visible="7"></v-pagination>
        </v-toolbar>
      </v-card>

      <v-card class="elevation-0 rounded-0 ma-3 mt-0 mb-0" style="border: solid 1px #e0e0e0">
        <v-toolbar v-if="loadingfolder" color="#f7f7f7" class="elevation-0 rounded-0" dense>
          <v-toolbar-title>
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn :disabled="loading ? true : false" style="border: solid 1px #e0e0e0" small
                  class="rounded-0 pa-1 elevation-0 ma-0" color="#ffff" v-bind="attrs" v-on="on">
                  {{ kFormatter(itemsPerPageFolders)
                  }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="itemsPerPageFolders = 10"><v-list-item-title>25</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPageFolders = 25"><v-list-item-title>50</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPageFolders = 50"><v-list-item-title>75</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPageFolders = 100"><v-list-item-title>100</v-list-item-title></v-list-item>
              </v-list>
            </v-menu></v-toolbar-title>
          <v-spacer style="width: 67%"></v-spacer>
          <v-text-field style="border: solid 1px #e0e0e0" width="40" v-model="searchFolder" hide-details
            prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
            class="elevation-0 rounded-0"></v-text-field>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card v-if="loadingfolder" class="elevation-0 rounded-0">
          <v-data-table v-if="snapshotfolder" :search="searchFolder" :items="dirLabel_" class="elevation-0 imagesnp"
            :items-per-page="itemsPerPageFolders" :headers="headersFolder" hide-default-footer hide-default-header
            :page.sync="pageFolders" @page-count="pageCountFolders = $event" @pagination="yourMethod">
            <template v-slot:item="row">
              <tr style="border-bottom: none">
                <td style="
                    padding: 0em;
                    background-color: #ffff;
                    border-bottom: none;
                  ">
                  <v-card :color="'#fff'" class="elevation-0 rounded-0 pa-2"
                    :style="loading ? 'cursor: context-menu;' : ''" @click="
                      !loading
                        ? onFolderClick(row.item.label_search)
                        : console.log('blocked')
                      ">
                    <v-icon :color="!loading ? 'primary' : '#D8D8D8'" x-large>{{
                      folderSelect == row.item.label_search
                      ? "mdi-folder-outline"
                      : "mdi-folder"
                    }}</v-icon>
                    <br />
                    <div class="ma-0 ml-2" style="text-align: left; font-size: 0.8em">
                      {{ row.item.label_search }}
                    </div>
                  </v-card>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-toolbar v-if="snapshotfolder" dense class="elevation-0 rounded-0" color="#f7f7f7">
            <p class="mt-3" style="font-size: 0.8em">
              Showing {{ pageStartFolder }} to {{ pageStopFolder }} of
              {{ totalFilterFolder }} entries
            </p>
            <v-spacer></v-spacer>
            <v-pagination color="primary" v-model="pageFolders" :length="pageCountFolders"
              :total-visible="7"></v-pagination>
          </v-toolbar>
        </v-card>
      </v-card>
    </v-card>

    <!--Modal Image-->
    <v-dialog class="rounded-0 elevation-0" v-model="imageOpen" width="800">
      <v-card class="rounded-0 elevation-0" v-if="imageData">
        <v-toolbar dense color="#ffff" class="elevation-0 rounded-0">
          {{ imageData.label }} | {{ vesselData.name }} |
          {{ vesselData.pt_name }}
          <v-spacer></v-spacer>
          <v-btn class="rounded-0 elevation-0" fab small color="#ffff" @click="imageOpen = null">
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-carousel hide-delimiters v-model="activeIndex">
          <v-carousel-item v-for="(item, i) in desserts" :key="i" :src="item.src"></v-carousel-item>
        </v-carousel>
        <!-- <v-img
          @click="zoomgambar(imageData.src)"
          width="100%"
          style="cursor: zoom-in"
          :src="imageData.src"
          class="white--text align-end"
          gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
        /> -->
        <div class="pa-2">{{ imageData.filename }}</div>



      </v-card>
    </v-dialog>
    <!--Modal Image End-->
  </v-card>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "../../plugins/axios";
import $ from "jquery";
export default {
  data() {
    return {
      activeIndex: 0,
      infoImage: "Showing 0 to 0 of 0 entries",
      totalFilter: null,
      totalFilterFolder: 0,
      pageStartFolder: 0,
      pageStopFolder: 0,
      filter_btn_clicked: null,
      chsnapshot: "kosong",
      totalSnapshots: 0,
      totalDesserts: 0,
      desserts: [],
      loading: true,
      options: {},
      headersside: [
        { text: "", value: "label", sortable: false },
        { text: "", value: "chLabel", sortable: false },
      ],
      snapshotnot: [{}, {}, {}, {}, {}, {}, {}, {}, {}],
      searchImage: "",
      imageOpen: null,
      imageData: null,
      searchFolder: "",
      itemsPerPage: 10,
      showpictonclick: null,
      page: 1,
      pageCount: 0,
      itemsPerPageImage: 25,
      pageImage: 1,
      pageCountImage: 0,
      itemsPerPageFolders: 25,
      pageFolders: 1,
      pageCountFolders: 0,
      pageSize: 1,
      loadernavplus: require("@/assets/img/load100new.gif"),
      folderclose: require("@/assets/img/close-folder.png"),
      folderloading: require("@/assets/img/loading-folder.png"),
      foldercloseagain: require("@/assets/img/close-folder.png"),
      folderopen: require("@/assets/img/open-folder.png"),
      headersFolder: [{ text: "", value: "label_search", sortable: false }],
      headersImage: [
        { text: "", value: "label", sortable: false },
        { text: "", value: "chLabel", sortable: false },
      ],
    };
  },
  watch: {
    desserts(newQuestion, oldQuestion) {
      $("html, body").animate(
        {
          scrollTop: $("#title_map_snapshot").offset().top - 109,
        },
        800
      );
    },
    options: {
      handler() {
        this.getDataFromApi();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions([
      "setFolderSelect",
      "closeShow",
      "fetchSnapshot",
      "snapshotFolder",
      "FolderClick",
      "vesselDataSet",
      "FilterClick",
      "addsnapshot_executed",
      "removeErrorsnap",
    ]),

    onImageClick_(item, index) {
      this.imageOpen = true
      this.imageData = item
      this.activeIndex = index
    },

    pageFolder_(pagination) {
      console.log(pagination)
      this.totalFilterFolder = pagination.itemsLength;
      this.pageStartFolder = pagination.pageStart + 1
      this.pageStopFolder = pagination.pageStop
    },

    zoomgambar(src) {
      var w = window.open("about:blank");

      // FireFox seems to require a setTimeout for this to work.
      setTimeout(() => {
        w.document.body.appendChild(w.document.createElement("iframe")).src =
          src;
        w.document.body.style.margin = 0;
        w.document.body.style.paddingLeft = "15%";
        w.document.body.style.paddingRight = "10%";
        w.document.body.style.paddingTop = "2%";
        w.document.body.style.backgroundColor = "black";

        w.document.getElementsByTagName("iframe")[0].style.width = "100%";
        w.document.getElementsByTagName("iframe")[0].style.height = "100%";
        w.document.getElementsByTagName("iframe")[0].style.border = 0;
      }, 0);
    },

    downloadGambar(image, filename) {
      console.log(image);
      var a = document.createElement("a"); //Create <a>
      a.href = image; //Image Base64 Goes here
      a.download = filename; //File name Here
      a.click();
    },

    yourMethod(pagination) {
      console.log(pagination)
      this.totalFilterFolder = pagination.itemsLength;
      this.pageStartFolder = pagination.pageStart + 1
      this.pageStopFolder = pagination.pageStop
    },

    closeShowSnapshot() {
      $("html, body").animate(
        {
          scrollTop: $("#top_snapshot").offset().top - 140,
        },
        800
      );
      this.removeErrorsnap();
      this.closeShow();
    },

    async onFolderClick(label) {
      this.pageImage = 1;
      this.showpictonclick = true;
      this.desserts = [];
      this.chsnapshot = "kosong";
      (this.filter_btn_clicked = null), this.setFolderSelect(label);
      setTimeout(() => {
        this.getDataFromApi();
      }, 1000);
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    async onFilterClick(ch) {
      // const dataimg = {
      //   id: this.vesselData.id,
      //   label: this.folderSelect,
      //   ch: ch,
      //   token: localStorage.getItem("token"),
      // };

      // console.log(dataimg);
      // this.FilterClick(dataimg);
      this.pageImage = 1;
      console.log(ch);
      this.chsnapshot = ch;
      this.desserts = [];
      setTimeout(() => {
        this.getDataFromApi();
      }, 1000);
    },

    //test serverside
    getDataFromApi() {
      $("html, body").animate(
        {
          scrollTop: $("#title_map_snapshot").offset().top - 109,
        },
        800
      );
      this.loading = true;
      this.fakeApiCall().then((data) => {
        this.desserts = data.items;
        this.totalDesserts = data.total;
        this.loading = false;
      });
    },
    /**
     * In a real application this would be a call to fetch() or axios.get()
     */
    async fakeApiCall() {
      return new Promise(async (resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options;
        console.log("sortBy == " + sortBy);
        console.log("sortDesc == " + sortDesc);
        console.log("page == " + page);
        console.log("itemsPerPage == " + itemsPerPage);
        // "/api/v1/navplus/snapshot/" + data.id + "/" + data.label + "/" + data.ch,

        //     const dataimg = {
        //   id: this.vesselData.id,
        //   label: this.folderSelect,
        //   ch: ch,
        //   token: localStorage.getItem("token"),
        // };

        var data = {
          id: this.vesselData.id,
          label: this.folderSelect,
          token: localStorage.getItem("token"),
        };

        console.log(data);

        const response = await axios.get(
          "/api/v1/navplus/snapshot/" +
          data.id +
          "/" +
          data.label +
          "/" +
          this.chsnapshot +
          "/" +
          page +
          "/" +
          this.itemsPerPageImage,
          {
            headers: {
              Authorization: "Bearer" + data.token,
            },
          }
        );

        console.log();

        console.log(response);

        // let items = this.getDesserts()
        let items = response.data.data;
        const total = response.data.recordsFiltered;
        this.totalSnapshots = total;
        this.infoImage = response.data.info

        // if (sortBy.length === 1 && sortDesc.length === 1) {
        //   items = items.sort((a, b) => {
        //     const sortA = a[sortBy[0]]
        //     const sortB = b[sortBy[0]]

        //     if (sortDesc[0]) {
        //       if (sortA < sortB) return 1
        //       if (sortA > sortB) return -1
        //       return 0
        //     } else {
        //       if (sortA < sortB) return -1
        //       if (sortA > sortB) return 1
        //       return 0
        //     }
        //   })
        // }

        // if (itemsPerPage > 0) {
        //   items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
        // }

        setTimeout(() => {
          resolve({
            items,
            total,
          });
        }, 1000);
      });
    },
  },

  mounted() {
    var mama = this;
    if (!mama.snapshot_executed) {
      //console.log("jalan title map");
      mama.addsnapshot_executed(true);
      $("html, body").animate(
        {
          scrollTop: $("#title_map_snapshot").offset().top - 109,
        },
        800
      );
    } else {
      ////console.log("Muncul"), //console.log(mama.sailink_executed);
    }
  },

  computed: {
    ...mapGetters([
      "errorsnap",
      "folderSelect",
      "filterList",
      "snapshotData",
      "snapshotfolder",
      "loadingimage",
      "loadingfolder",
      "vesselData",
      "snapshot_executed",
      "dirLabel_",
    ]),
    showingDatafrom() {
      if (this.totalFilter < 1) {
        return 0;
      } else {
        return 1;
      }
    },

    showingDatato() {
      if (this.totalFilter < this.itemsPerPageImage) {
        return this.totalFilter;
      } else {
        return this.itemsPerPageImage;
      }
    },
  },
};
</script>

<style scoped>
.wrap-it {
  word-wrap: break-word;
}

.margintab {
  margin-top: -25px;
}

.bxfile {
  font-size: 40px;
}

.imgsnapshot {
  width: 9.5em;
  margin: 0px;
  height: 5em;
  object-fit: cover;
}

.datasnapshot {
  top: 70px;
}

.imagesnp {
  background-color: #ffff;
}

.imagesnp tbody {
  display: flex !important;
  white-space: initial !important;
  flex-wrap: wrap !important;
  text-align: center !important;
  width: 104vw !important;
  padding: 0 !important;
}

@media (min-width: 280px) and (max-width: 319px) {
  .imgsnapshot {
    width: 20em;
    margin: 0px;
    height: 5em;
    object-fit: cover;
  }
}

/* Mobile Devices */
@media (min-width: 320px) and (max-width: 480px) {
  .imgsnapshot {
    width: 20em;
    margin: 0px;
    height: 5em;
    object-fit: cover;
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .imgsnapshot {
    width: 9em;
    margin: 0px;
    height: 5em;
    object-fit: cover;
  }
}

/* Desktops, large screens */
@media (min-width: 1025px) and (max-width: 1200px) {
  .imgsnapshot {
    width: 9.5em;
    margin: 0px;
    height: 5em;
    object-fit: cover;
  }
}

/* Extra large screens / TV */
@media screen and (min-width: 1201px) {
  .imgsnapshot {
    width: 9.5em;
    margin: 0px;
    height: 5em;
    object-fit: cover;
  }
}
</style>
