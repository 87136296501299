<template>
  <v-dialog v-model="notifopen" width="600" persistent>
    <v-card class="elevation-0 rounded-0">
      <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7">
        <v-icon class="mr-2" small>mdi-cog</v-icon> Quota Notification Configuration
        <v-spacer></v-spacer>
        <v-btn small class="elevation-0 rounded-0" icon @click="removeNotif()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar v-if="notifData" class="elevation-0 rounded-0" dense>
        <v-toolbar-subtitle style="font-size: 0.8em;text-align: left;">{{ notifData.vessel_pt }}</v-toolbar-subtitle>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider v-if="notifData"></v-divider>
      <v-container style="margin-top: 0" v-if="!notifmodal" fill-height fluid>
        <v-row align="center" justify="center">
          <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
        </v-row>
      </v-container>
      <v-card-text v-if="notifmodal" class="mt-4">
        <v-container style="margin-top: 0" v-if="!notifData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
          </v-row>
        </v-container>

        <div v-if="notifData">
          <v-alert v-if="notifqt_success" class="elevation-0 rounded-0" text dense color="teal" icon="mdi-check-bold"
            style="font-size: 0.86em;text-align: left;">
            Successfully Updated Quota Notification Configuration
          </v-alert>
          <v-card class="elevation-0 rounded-0 pa-1 mt-0">

            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <v-subheader class="pl-0" style="text-align: left;">Limit Threshold</v-subheader>
              </v-col>
              <v-col cols="12" sm="9">
                <v-row no-gutters>
                  <v-col cols="12" sm="8">
                    <div>
                      <v-text-field placeholder="Eg. 50 70 100" class="elevation-0 rounded-0" v-model="notifData.batas"
                        @input="checkdata('batas')" outlined></v-text-field>
                      <div class="hint_">
                        *dipisahkan menggunakan spasi<br />E.g. 45 70 80 100
                      </div>
                    </div>


                  </v-col>


                  <v-col cols="12" sm="4">
                    <v-switch style="margin-top: -0.2em;" @change="checkdata('enabled')" inset v-model="notifData.enabled"
                      :label="`Enabled`"></v-switch>
                  </v-col>
                </v-row>
              </v-col>

            </v-row>
            <v-row no-gutters>
              <v-col cols="12" sm="3">
                <v-subheader class="pl-0" style="text-align: left;">Email Notification</v-subheader>
              </v-col>
              <v-col cols="12" sm="9">
                <v-row no-gutters>
                  <v-col cols="12">
                    <div>
                      <v-textarea @input="checkdata('emails')" class="elevation-0 rounded-0 " name="input-7-1"
                        v-model="notifData.emails" outlined auto-grow></v-textarea>
                      <div class="hint_">
                        *dipisahkan menggunakan spasi<br />E.g. noc@sailink.co.id skyreach@sailink.co.id
                      </div>
                    </div>

                  </v-col>
                </v-row>


              </v-col>
            </v-row>
          </v-card>
          <v-btn @click="notifDataPost()" :disabled="changes_ ? false : true" color="primary"
            class="elevation-0 rounded-0" small block>
            Update Quota Notification
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "../../plugins/axios";
import $ from "jquery";
export default {
  props: ['notif_id'],
  data() {
    return {
      notifmodal: null,
      change: false,
      loadernavplus: require("@/assets/img/load100new.gif"),
    };
  },
  methods: {
    ...mapActions(["fetchNotif", "removeNotif", "fetchNotif", "notifPost"]),
    checkdata(statis) {

      if (this.notifmodal[statis] == this.notifData[statis]) {
        this.change = false;
      } else {
        this.change = true;
      }
    },
    async notifDataPost() {
      this.change = false;
      const data = {
        id: this.notifID,
        enabled: this.notifData.enabled,
        batas: this.notifData.batas,
        emails: this.notifData.emails,
        token: localStorage.getItem("token"),
      };
      this.notifPost(data);
    },

    async notifFetchStatic() {
      console.log(this.notif_id);
      try {
        const response = await axios.get(
          "/api/v1/navplus/email/limit/" + this.notif_id,
          {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
            },
          }
        );

        console.log(response);
        this.notifmodal = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
  },

  mounted() {
    console.log(this.notif_id)
    this.notifFetchStatic();
  },

  computed: {
    ...mapGetters(["notifopen", "notifData", "notifID", "notifqt_success"]),
    changes_() {
      return this.change;
    },
  },
};
</script>

<style lang="css" scoped>
.hint_ {
  font-size: 0.8em;
  text-align: left;
  margin-top: -3em;
  padding: 1em;
  padding-left: 0em;
  padding-bottom: 2em;
}
</style>
