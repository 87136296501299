import { render, staticRenderFns } from "./ManifestChart.vue?vue&type=template&id=2a68e1aa&"
import script from "./ManifestChart.vue?vue&type=script&lang=js&"
export * from "./ManifestChart.vue?vue&type=script&lang=js&"
import style0 from "./ManifestChart.vue?vue&type=style&index=0&id=2a68e1aa&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports