<template>
  <v-app>
    <v-dialog v-model="NameThurOpen_" width="800px" persistent>
      <v-card class="rounded-0 elevation-0">
        <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0" dense>
 Change Vessel Name
          <v-spacer></v-spacer>

          <v-btn
            small
            class="elevation-0 rounded-0"
            icon
            @click="closeNamethur()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-toolbar
          v-if="namemodal"
          class="elevation-0 rounded-0"
          dense
          color="#fff"
        >
          <v-toolbar-subtitle>{{ namemodal.vessel_pt }} </v-toolbar-subtitle>

          <v-spacer></v-spacer>
        </v-toolbar>

        <v-divider v-if="nameData_Thur"></v-divider>
        <v-container
          style="margin-top: 0em"
          v-if="!nameData_Thur"
          fill-height
          fluid
        >
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>
        <v-card-text v-if="nameData_Thur">
          <v-alert
            v-if="alert_"
            dense
            outlined
            :icon="alert_icon"
            text
            :type="alert_type"
            class="elevation-0 rounded-0 mt-5"
            >{{ alert_message }}</v-alert
          >
          <!-- <v-alert
              class="elevation-0 rounded-0"
              style="font-size: 14px;"
              v-if="alert_"
              :icon="alert_icon"
              prominent
              text
              :type="alert_type"
              dense
              >{{ alert_message }}</v-alert
            > -->

          <v-form class="mt-8" ref="form" v-model="valid" lazy-validation>
            <v-text-field
              @input="checkdata()"
              class="rounded-0"
              :value="nameData_Thur.name"
              v-model="nameData_Thur.name"
              label="Vessel Name"
              :rules="nameRules"
              required
              outlined
              block
              :disabled="postData_ == true"
            >
              <template v-slot:append>
                <v-fade-transition leave-absolute>
                  <v-progress-circular
                    v-if="postData_"
                    size="24"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </v-fade-transition>
              </template>
            </v-text-field>
          </v-form>

          <v-btn
            :disabled="changes_ && valid ? false : true"
            class="elevation-0 rounded-0"
            @click="
              changes_ && valid
                ? (namethur_confirm = true)
                : console.log('disable')
            "
            color="primary"
            block
          >
            Update vessel name
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      class="rounded-0 elevation-0"
      v-model="namethur_confirm"
      persistent
      max-width="290"
    >
      <v-card class="rounded-0 elevation-0">
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text style="text-align: left"
          >Vessel name will be change</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-0"
            color="red"
            text
            @click="namethur_confirm = false"
          >
            No
          </v-btn>
          <v-btn
            class="rounded-0"
            color="green"
            text
            @click="
              (namethur_confirm = false), ChangeVesselName(nameData_Thur.id)
            "
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "../../plugins/axios";
export default {
  props: ["nameid"],
  data() {
    return {
      loadernavplus: require("@/assets/img/load100new.gif"),
      change: false,
      namemodal: null,
      nameRules: [
        (v) => !!v || "Vessel name cannot empty",
        (v) =>
          (v && v.length > 1) || "Vessel name must be more than 1 characters",
      ],
      valid: null,
      isChange_: null,
      namethur_confirm: false,
      alert_: false,
      alert_type: "",
      alert_message: "",
      alert_icon: "",
      postData_: false,
    };
  },
  mounted() {
    this.vnameFetchStatic();
  },
  methods: {
    ...mapActions(["addNameThurOpen_", "addNameData", "fetchThuraya","unsetMyThuraya"]),

    checkdata() {
      if (this.namemodal.name == this.nameData_Thur.name) {
        this.change = false;
      } else {
        this.change = true;
      }
    },

    addChange() {
      this.isChange_ = true;
    },
    closeNamethur() {
      this.postData_ = false;
      this.alert_ = false;
      this.addNameThurOpen_(null);
      this.addNameData(null);
    },

    async ChangeVesselName(id) {
      var mother = this;
      this.postData_ = true;
      this.alert_ = false;
      this.change = false;
      const data = {
        id: id,
        name: this.nameData_Thur.name,
      };
      try {
        const response = await axios.post("/api/v1/navplus/vessel/name", data, {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        });
        console.log(response);
        if ((response.message = "Retrieved successfully")) {
          (this.alert_ = true),
            (this.alert_type = "success"),
            (this.alert_message = "Vessel name has been changed"),
            (this.alert_icon = "mdi-check-circle"),
            (this.postData_ = false);
          this.vnameFetchStatic();
          this.unsetMyThuraya();
          this.fetchThuraya(localStorage.getItem("token"));
          setTimeout(function () {
            mother.alert_ = false;
          }, 4000);
        } else {
          (this.alert_ = true),
            (this.alert_type = "error"),
            (this.alert_message = "Something went wrong"),
            (this.alert_icon = "mdi-alert-circle"),
            (this.postData_ = false);
          this.fetchThuraya(localStorage.getItem("token"));
          setTimeout(function () {
            mother.alert_ = false;
          }, 4000);
        }
      } catch (error) {
        (this.alert_ = true),
          (this.alert_type = "error"),
          (this.alert_message = "Check your connection"),
          (this.alert_icon = "mdi-alert-circle"),
          (this.postData_ = false);
      }
    },

    async vnameFetchStatic() {
      console.log(this.nameid.id);
      try {
        const response = await axios.get(
          "/api/v1/navplus/vessel/name/" + this.nameid.id,
          {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
            },
          }
        );

        this.namemodal = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
  },

  computed: {
    ...mapGetters(["NameThurOpen_", "nameData_Thur"]),
    changes_() {
      return this.change;
    },
  },
};
</script>
