import api from "../../plugins/axios";

const state = {
    token: null,
    user: null,
    auth: false,
    userData: null,
};

const getters = {
    userToken: (state) => state.token,
    userData: (state) => state.user,
    isAuth: (state) => state.auth,
    Datauser: (state) => state.userData
};

const actions = {
    async fetchUser({commit}, data) {
        try {
            const response = await api.post(
                "/api/login",
                {
                    email: data.email,
                    password: data.password,
                }
            );
            //console.log(response.data.user)
            commit("setToken", response.data.token);
            commit("setUser", response.data.user);
            commit("setAuth", true)

            localStorage.setItem("token", response.data.token);
            localStorage.setItem("id", response.data.user.id);

            api.defaults.headers.common.Authorization = `Bearer ${response.data.token}`;
        } catch (e) {
            console.log("error");
            // this.error = "Invalid Email/Password";
        }
    },

    setUserData({commit}, data) {

        commit("setUserDataData", data)
    }
};

const mutations = {
    setUserDataData: (state, userData) => (state.userData = userData),
    setToken: (state, token) => (state.token = token),
    setUser: (state, user) => (state.user = user),
    setAuth: (state, auth) => (state.auth = auth),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
