<template>
    <v-dialog v-model="speedaltopen" width="650" persistent>
        <v-card class="elevation-0 rounded-0">
            <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7">
                <v-icon class="mr-2" small>mdi-cog</v-icon> Speed Notification Configuration
                <v-spacer></v-spacer>
                <v-btn small class="elevation-0 rounded-0" icon @click="removeSpeedalt()">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
            <v-toolbar v-if="speedaltData" class="elevation-0 rounded-0" dense>
                <v-toolbar-subtitle style="font-size: 0.8em;text-align: left;">{{ speedaltData.vessel_pt
                }}</v-toolbar-subtitle>

                <v-spacer></v-spacer>
            </v-toolbar>
            <v-divider v-if="speedaltData"></v-divider>
            <v-container style="margin-top: 0" v-if="!speedmodal" fill-height fluid>
                <v-row align="center" justify="center">
                    <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
                </v-row>
            </v-container>
            <v-card-text v-if="speedmodal" class="mt-4">
                <v-container style="margin-top: 0" v-if="!speedaltData" fill-height fluid>
                    <v-row align="center" justify="center">
                        <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
                    </v-row>
                </v-container>

                <div v-if="speedaltData">
                    <v-alert v-if="speedalt_success" class="elevation-0 rounded-0" text dense color="teal"
                        icon="mdi-check-bold" style="font-size: 0.86em;text-align: left;">
                        Successfully Updated Speed Notification Configuration
                    </v-alert>
                    <v-card class="elevation-0 rounded-0 pa-1 mt-0">

                        <v-row no-gutters>
                            <v-col cols="12" sm="3">
                                <v-subheader class="pl-0" style="text-align: left;">Enabled</v-subheader>
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-row no-gutters>
                                    <v-col cols="12" sm="6">

                                        <v-radio-group @change="checkdata_isEnabled" style="margin-top: -0.2em;"
                                            v-model="isEnabled" row>
                                            <v-radio label="Yes" :value="1"></v-radio>
                                            <v-radio label="No" :value="0"></v-radio>
                                        </v-radio-group>
                                    </v-col>


                                    <v-col cols="12" sm="6">
                                        <v-subheader class="pl-0" style="text-align: left;">Speed Source:<b>{{
                                            speedaltData.source }}</b></v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" sm="3">
                                <v-subheader class="pl-0" style="text-align: left;">Speed Maximum</v-subheader>
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-row no-gutters>
                                    <v-col cols="12" sm="10">
                                        <div>
                                            <v-text-field type="number" v-model="speedaltData.max" min="0" step=".01"
                                                style="" class="elevation-0 rounded-0" @input="checkdata('max')" outlined>

                                                <template v-slot:append>
                                                    <v-fade-transition leave-absolute>
                                                        <v-icon  v-if="speedaltData.max > speedaltData.min" color="green"
                                                            style="margin-left: 0.2em">mdi-check-bold</v-icon>
                                                        <v-icon v-else color="red"
                                                            style="margin-left: 0.2em">mdi-alert-circle-outline</v-icon>
                                                    </v-fade-transition>
                                                </template>
                                            </v-text-field>
                                        </div>


                                    </v-col>


                                    <v-col cols="12" sm="2">
                                        <v-text-field placeholder="Knots" disabled class="elevation-0 rounded-0" filled
                                            outlined></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" sm="3">
                                <v-subheader class="pl-0" style="text-align: left;">Speed Minimum</v-subheader>
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-row no-gutters>
                                    <v-col cols="12" sm="10">
                                        <div>
                                            <v-text-field type="number" v-model="speedaltData.min" min="0" step=".01"
                                                style="" class="elevation-0 rounded-0" @input="checkdata('min')" outlined>
                                                <template v-slot:append>
                                                    <v-fade-transition leave-absolute>

                                                        <v-icon v-if="speedaltData.max > speedaltData.min"  color="green"
                                                            style="margin-left: 0.2em">mdi-check-bold</v-icon>
                                                        <v-icon v-else color="red"
                                                            style="margin-left: 0.2em">mdi-alert-circle-outline</v-icon>
                                                    </v-fade-transition>
                                                </template></v-text-field>
                                        </div>


                                    </v-col>


                                    <v-col cols="12" sm="2">
                                        <v-text-field placeholder="Knots" disabled class="elevation-0 rounded-0" filled
                                            outlined></v-text-field>
                                    </v-col>
                                </v-row>
                            </v-col>

                        </v-row>
                        <v-row no-gutters>
                            <v-col cols="12" sm="3">
                                <v-subheader class="pl-0" style="text-align: left;">Email Adresses</v-subheader>
                            </v-col>
                            <v-col cols="12" sm="9">
                                <v-row no-gutters>
                                    <v-col cols="12">
                                        <div>
                                            <v-textarea @input="checkdata('emails')" class="elevation-0 rounded-0 "
                                                name="input-7-1" v-model="speedaltData.emails" outlined
                                                auto-grow></v-textarea>
                                            <div class="hint_">
                                                *dipisahkan menggunakan spasi<br />E.g. noc@sailink.co.id
                                                skyreach@sailink.co.id
                                            </div>
                                        </div>

                                    </v-col>
                                </v-row>


                            </v-col>
                        </v-row>
                    </v-card>
                    <v-btn @click="speedaltDataPost()" :disabled="changes_ && (speedaltData.max > speedaltData.min) ? false : true" color="primary"
                        class="elevation-0 rounded-0" small block>
                        Update Speed Notification
                    </v-btn>
                </div>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>
  
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "../../plugins/axios";
import $ from "jquery";
export default {
    props: ['speedalt_id'],
    data() {
        return {
            isEnabled: null,
            speedmodal: null,
            change: false,
            loadernavplus: require("@/assets/img/load100new.gif"),
        };
    },
    methods: {
        ...mapActions(["fetchNotif", "removeSpeedalt", "fetchNotif", "SpeedaltPost"]),
        checkdata(statis) {

            if (this.speedmodal[statis] == this.speedaltData[statis]) {
                this.change = false;
            } else {
                this.change = true;
            }
        },

        checkdata_isEnabled(statis) {
            // console.log(statis);
            console.log(this.isEnabled);
            console.log(this.isEnabled_speed);
            if (this.isEnabled == parseInt(this.isEnabled_speed)) {
                this.change = false;
            } else {
                this.change = true;
            }
        },
        async speedaltDataPost() {
            this.change = false;
            const data = {
                id: this.speedaltID,
                isEnabled: this.isEnabled,
                speed_min: this.speedaltData.min,
                speed_max: this.speedaltData.max,
                emails: this.speedaltData.emails,
                token: localStorage.getItem("token"),
            };
            this.SpeedaltPost(data);
        },

        async speedFetchStatic() {
            console.log(this.speedalt_id);
            try {
                const response = await axios.get(
                    "/api/v1/navplus/speed/" + this.speedalt_id,
                    {
                        headers: {
                            Authorization: "Bearer" + localStorage.getItem("token"),
                        },
                    }
                );

                console.log(response);
                if (response.data.data) {
                    if (response.data.data.isEnabled != null) {
                        this.isEnabled = response.data.data.isEnabled
                    } else {
                        this.isEnabled = 0
                    }
                }


                this.speedmodal = response.data.data;
            } catch (e) {
                console.log(e);
            }
        },
    },

    mounted() {
        console.log(this.speedalt_id)
        this.speedFetchStatic();
    },

    computed: {
        ...mapGetters(["speedaltopen", "speedaltData", "speedaltID", "speedalt_success", "isEnabled_speed"]),
        changes_() {
            return this.change;
        },
    },
};
</script>
  
<style lang="css" scoped>
.hint_ {
    font-size: 0.8em;
    text-align: left;
    margin-top: -3em;
    padding: 1em;
    padding-left: 0em;
    padding-bottom: 2em;
}
</style>
  