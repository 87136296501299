<template>
  <v-app>
    <v-dialog v-model="nameopen" width="800px" persistent>
      <v-card class="rounded-0 elevation-0 pb-2">

        <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0">
          <v-icon class="mr-2" small>mdi-cog</v-icon>Change Vessel Name
          <v-spacer></v-spacer>
          <v-btn small class="elevation-0 rounded-0" icon @click="closeNamethur()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-toolbar v-if="namemodal" class="elevation-0 rounded-0" dense color="#fff">
          <v-toolbar-subtitle style="font-size: 0.8em;text-align: left;">{{ namemodal.vessel_pt }} </v-toolbar-subtitle>


          <v-spacer></v-spacer>
        </v-toolbar>

        <v-divider v-if="nameData"></v-divider>
        <v-container style="margin-top: 0em" v-if="!nameData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
          </v-row>
        </v-container>
        <v-card-text v-if="nameData">
          <v-alert v-if="alert_" class="elevation-0 rounded-0 mt-5" text dense color="teal" icon="mdi-check-bold"
            style="font-size: 0.86em;text-align: left;">
            {{ alert_message }}
          </v-alert>

          <v-form class="mt-5" ref="form" v-model="valid" lazy-validation>
            <v-row no-gutters>
              <v-col cols="12" sm="2">
                <v-subheader class="pl-0" style="text-align: left;">Vessel Name</v-subheader>
              </v-col>
              <v-col cols="12" sm="10">
      
                    <v-text-field @input="checkdata()" class="rounded-0" :value="nameData.name" v-model="nameData.name"
                      :rules="nameRules" required outlined block :disabled="postData_ == true">
                      <template v-slot:append>
                        <v-fade-transition leave-absolute>
                          <v-progress-circular v-if="postData_" size="24" color="primary"
                            indeterminate></v-progress-circular>
                        </v-fade-transition>
                      </template>
                    </v-text-field>
              </v-col>
            </v-row>

          </v-form>

          <v-btn style="margin-top: -0.6em;" :disabled="changes_ && valid ? false : true" class="elevation-0 rounded-0" @click="
            changes_ && valid
              ? (namethur_confirm = true)
              : console.log('disable')
            " color="primary" block>
            Update vessel name
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog class="rounded-0 elevation-0" v-model="namethur_confirm" persistent max-width="290">
      <v-card class="rounded-0 elevation-0">
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text style="text-align: left">Vessel name will be change</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="rounded-0" color="grey" text @click="namethur_confirm = false">
            No
          </v-btn>
          <v-btn class="rounded-0" color="primary" text @click="
            (namethur_confirm = false), ChangeVesselName(nameData.id)
            ">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "../../plugins/axios";
export default {
  props: ["nameid"],
  data() {
    return {
      loadernavplus: require("@/assets/img/load100new.gif"),
      change: false,
      namemodal: null,
      nameRules: [
        (v) => !!v || "Vessel name cannot empty",
        (v) =>
          (v && v.length > 1) || "Vessel name must be more than 1 characters",
      ],
      valid: null,
      isChange_: null,
      namethur_confirm: false,
      alert_: false,
      alert_type: "",
      alert_message: "",
      alert_icon: "",
      postData_: false,
    };
  },
  mounted() {
    this.vnameFetchStatic();
  },
  methods: {
    ...mapActions(["addnameopen", "addNameData", "fetchMySailink", "removeName", "unsetMySailink"]),

    checkdata() {
      if (this.namemodal.name == this.nameData.name) {
        this.change = false;
      } else {
        this.change = true;
      }
    },

    addChange() {
      this.isChange_ = true;
    },
    closeNamethur() {
      this.postData_ = false;
      this.alert_ = false;
      this.removeName()
    },

    async ChangeVesselName(id) {
      var mother = this;
      this.postData_ = true;
      this.alert_ = false;
      this.change = false;
      const data = {
        id: id,
        name: this.nameData.name,
      };
      try {
        const response = await axios.post("/api/v1/navplus/vessel/name", data, {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        });
        console.log(response);
        if ((response.message = "Retrieved successfully")) {
          (this.alert_ = true),
            (this.alert_type = "success"),
            (this.alert_message = "Vessel name has been changed"),
            (this.alert_icon = "mdi-check-circle"),
            (this.postData_ = false);
          this.vnameFetchStatic();
          this.unsetMySailink()
          this.fetchMySailink(localStorage.getItem("token"));
          setTimeout(function () {
            mother.alert_ = false;
          }, 4000);
        } else {
          (this.alert_ = true),
            (this.alert_type = "error"),
            (this.alert_message = "Something went wrong"),
            (this.alert_icon = "mdi-alert-circle"),
            (this.postData_ = false);

          this.fetchMySailink(localStorage.getItem("token"));
          setTimeout(function () {
            mother.alert_ = false;
          }, 4000);
        }
      } catch (error) {
        (this.alert_ = true),
          (this.alert_type = "error"),
          (this.alert_message = "Check your connection"),
          (this.alert_icon = "mdi-alert-circle"),
          (this.postData_ = false);
      }
    },

    async vnameFetchStatic() {
      console.log(this.nameid.id);
      try {
        const response = await axios.get(
          "/api/v1/navplus/vessel/name/" + this.nameid.id,
          {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
            },
          }
        );

        this.namemodal = response.data.data;
      } catch (e) {
        console.log(e);
      }
    },
  },

  computed: {
    ...mapGetters(["nameopen", "nameData"]),
    changes_() {
      return this.change;
    },
  },
};
</script>
