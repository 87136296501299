<template>
  <v-dialog v-model="rbsOpen" class="elevation-0 rounded-0" width="750" persistent>
    <v-card class="elevation-0 rounded-0 pb-3">
      <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0" dense>
        Sailogger Reboot Command

        <v-spacer></v-spacer>

        <v-btn class="rounded-0 elevation-0" small icon @click="closeRBSM()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar v-if="rbsData" class="elevation-0 rounded-0">

        <v-toolbar-subtitle style="text-align: left">{{ rbsData.vessel_number }}
          <br />
          <div v-if="rbsData" style="text-align: left">
            <v-chip v-if="rbsData.latest_status == 'FAILED'" class="ma-0 elevation-0 rounded-0" color="red" x-small label
              text-color="white">
              <v-icon x-small left> mdi-close-thick </v-icon>
              Failed to change Settings at {{ rbsData.last_updated }}, please
              resubmit
            </v-chip>
            <v-chip v-if="rbsData.latest_status == 'ON AIR' " class="ma-0 elevation-0 rounded-0" color="yellow" x-small
              label text-color="black">
              <v-icon x-small left> mdi-clock </v-icon>
              Waiting for Confirmation, sent at {{ rbsData.last_updated }}
            </v-chip>
            <v-chip v-if="rbsData.latest_status == 'CONFIRMED'" class="ma-0 elevation-0 rounded-0" color="green" x-small
              label text-color="white">
              <v-icon x-small left> mdi-check-bold </v-icon>
              Updated at {{ rbsData.last_updated }}
            </v-chip>
            <v-chip v-if="rbsData.latest_status == 'DELIVERED'" class="ma-0 mr-1 elevation-0 rounded-0" color="green" x-small
              label text-color="white">
              <v-icon x-small left> mdi-check-bold </v-icon>
              Delivered
            </v-chip>
            <v-chip v-if="rbsData.latest_status == 'DELIVERED' " class="ma-0 elevation-0 rounded-0" color="yellow" x-small
              label text-color="black">
              <v-icon x-small left> mdi-clock </v-icon>
              Waiting for Confirmation
            </v-chip>
          </div>
        </v-toolbar-subtitle>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider v-if="rbsData"></v-divider>
      <v-container style="margin-top: 2em" v-if="!rbsData" fill-height fluid>
        <v-row align="center" justify="center">
          <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
        </v-row>
      </v-container>
      <v-card-text v-if="rbsData" style="margin-top:2em">
        <v-btn v-if="rbsData.latest_status != 'ON AIR' && rbsData.latest_status != 'DELIVERED'" small
          :style="rbsData.latest_status == 'ON AIR' ? 'cursor:not-allowed' : 'cursor:pointer'" :disabled="false"
          class="elevation-0 rounded-0" @click="rbsDataPost()" color="primary" block>
          Send Reboot Command
        </v-btn>
        <v-btn v-if="rbsData.latest_status == 'ON AIR' || rbsData.latest_status == 'DELIVERED'" small
          :style="rbsData.latest_status == 'ON AIR' ? 'cursor:not-allowed' : 'cursor:pointer'" :disabled="true"
          class="elevation-0 rounded-0" color="primary" block>
          Send Reboot Command
        </v-btn>
      </v-card-text>
      <v-card v-if="rbsData" style="border: solid 1px #e0e0e0" class="ma-3 datatabledetail rounded-0 mt-0 mb-0"
        elevation="0">
        <v-toolbar elevation="0" style="max-height: 70px;" color="#f7f7f7" fixed dense>
          Report List

          <v-spacer></v-spacer>
        </v-toolbar>
        <v-toolbar elevation="0" style="max-height: 70px" color="#FFF" fixed dense>
          <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0 mr-2" color="#ffff"
                v-bind="attrs" v-on="on">
                {{ kFormatter(itemsPerPage)
                }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list style="border: solid 1px #e0e0e0">
              <v-list-item @click="itemsPerPage = 10"><v-list-item-title>10</v-list-item-title></v-list-item>
              <v-list-item @click="itemsPerPage = 25"><v-list-item-title>25</v-list-item-title></v-list-item>
              <v-list-item @click="itemsPerPage = 50"><v-list-item-title>50</v-list-item-title></v-list-item>
              <v-list-item @click="itemsPerPage = 100"><v-list-item-title>100</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>

          <v-spacer style="width: 50%"></v-spacer>
          <v-text-field style="border: solid 1px #e0e0e0" width="40" v-model="search" hide-details
            prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
            class="elevation-0 rounded-0"></v-text-field>
        </v-toolbar>
        <v-divider></v-divider>
        <v-layout v-resize="onResize" column style="">

          <v-data-table :search="search" style="margin-top: 0px" v-if="rbs_output" :headers="headers" :items="rbs_output"
            hide-default-footer hide-default-header :page.sync="page" :items-per-page="itemsPerPage"
            class="elevation-0 dataTable trackingTable_sailinkdesktop" @page-count="pageCount = $event"
            @pagination="intoFilter">
            <template v-slot:header>
              <th class="pa-1 pt-2 pl-2 pb-2 tableHeader_" style="text-align: left;">No</th>
              <th class="pa-1 pt-2 pl-2 pb-2 tableHeader_" style="text-align: left;">
                Content
              </th>
              <th class="pa-1 pt-2 pl-2 pb-2 tableHeader_" style="text-align: left;">
                dateTime (GMT+7)
              </th>
            </template>
            <template v-slot:item="row">
              <tr>
                <td class="pa-2" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.index + 1 }}
                </td>
                <td class="pa-2" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.content }}
                </td>
                <td class="pa-2" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.dateTime_local }}
                </td>
              </tr>
            </template>
          </v-data-table>

          <v-divider></v-divider>
          <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
            <p class="mt-3" style="font-size: 0.8em">
              Showing {{ showingDatafrom }} to {{ showingDatato }} of
              {{ totalFilter }} entries
            </p>
            <v-spacer></v-spacer>
            <v-pagination color="primary" v-if="showmaptable" v-model="page" :length="pageCount"
              :total-visible="7"></v-pagination>
          </v-toolbar>
        </v-layout>

      </v-card>
    </v-card>
  </v-dialog>
</template>
 
<script>
import { mapGetters, mapActions } from "vuex";
import axios from "../../plugins/axios";
export default {
  data() {
    return {
      search: "",
      rbsmodal: null,
      change: null,
      loadernavplus: require("@/assets/img/load100new.gif"),
      fetchInterval: null,
      headers: [
        {
          text: "#",
          value: "index",
        },
        { text: "Content", value: "content" },
        { text: "dateTime (GMT+7)", value: "dateTime_local" },
      ],
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    };
  },
  mounted() {
    this.rbsFetchStatic()

  },
  methods: {
    ...mapActions(["rbsFetch", "rbsPost", "rbsRemove"]),
    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
    async rbsDataPost() {
      const data = {
        thuraya_number: this.rbsData.number,
        token: localStorage.getItem("token"),
      };
      this.rbsPost(data)
      var mother = this;
      var intervalSeconds = 0.5;
      this.fetchInterval = setInterval(function () {
        mother.rbsDataFetch();
      }, intervalSeconds * 1000);
    },

    stopFetch() {
      console.log("STOP FETCH STATUS REBOOT SAILOGGER");
      clearInterval(this.fetchInterval);
      this.on = false;
    },

    closeRBSM() {
      this.rbsRemove()
      this.stopFetch()
    },

    async rbsDataFetch() {
      console.log("FETCH TERUS STATUS REBOOT SAILOGGER");
      console.log("STATUS :");
      console.log(this.rbsData.latest_status);
      // this.wlModal(true);
      const data = {
        id: this.rbsID,
        token: localStorage.getItem("token"),
      };
      this.rbsFetch(data);
      var mother = this
      setTimeout(function () {


        if (mother.rbsData) {
          if (mother.rbsData.latest_status != "ON AIR" && mother.rbsData.latest_status != "DELIVERED") {
            mother.stopFetch();
            mother.rbsFetchStatic();
          }
        } else {
          if (mother.rbsmodal.latest_status != "ON AIR" && mother.rbsData.latest_status != "DELIVERED") {
            mother.stopFetch();
            mother.rbsFetchStatic();
          }
        }
      }, 5000);

    },

    async rbsFetchStatic() {
      try {
        const response = await axios.get(
          "https://navigatorplus.sailink.id/api/v1/navplus/sailogger/" +
          this.rbsID +
          "/reboot",
          {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
            },
          }
        );
        console.log(response)
        this.rbsmodal = response.data.data;
        var mother = this;
        var intervalSeconds = 0.5;
        if (response.data.data.latest_status == "ON AIR" || mother.rbsData.latest_status == "DELIVERED") {
          this.fetchInterval = setInterval(function () {
            mother.rbsDataFetch();
          }, intervalSeconds * 1000);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },

  computed: {
    ...mapGetters(["rbsData", "rbsID", "rbsOpen", "user_allowed", "rbs_output"]),
    //  changes_() {
    //   return this.change;
    // },
  },
};
</script>
 
<style lang="scss" scoped></style>
 


