<template>
  <v-app-bar :style="'border-bottom: solid 1px #E0E0E0;'" class="rounded-0 pa-0 elevation-0" dense :color="'#ffff'"
             fixed>
    <div v-if="isServices">
      <img alt="Navplus logo" class="logo-navplus navigatorlogo_desktop" :src="image"/>
      <img alt="Navplus logo" style="width:2em" class=" navigatorlogo_mobile" :src="imagec"/>
    </div>
    <div v-if="!isServices">
      <img alt="Sailink logo" class="logo-sailink sailinklogo_desktop " :src="imageb"/>
      <img alt="Sailink logo" style="width:2em;" class="sailinklogo_mobile " :src="imaged"/>
    </div>

    <v-spacer></v-spacer>
    <v-btn v-if="outsidelogin_" href="https://sailink.id/service" :color="'#3490DC'" dark small style="color:#fff"
           class="elevation-0 rounded-0 mr-2 pl-0 homebtn_desktop">
      <v-icon>mdi-menu-left</v-icon>
      Previous Version
      <img alt="Sailink logo" style="width:1.3em;" class="ml-2" :src="iconcurrent"/>

    </v-btn>
    <v-btn v-if="!isHome" to="/" :color="'#343A40'" dark small style="color:#fff"
           class="elevation-0 rounded-0 mr-2 homebtn_desktop">
      <v-icon class="mr-1" x-small>mdi-home</v-icon>
      Home
    </v-btn>

    <v-btn v-if="isLoggedIn && isHome" to="/navigatorplus" :color="'#343A40'" dark small
           class="elevation-0 rounded-0 mr-2 dashboard_desktop">
      <v-icon class="mr-1" x-small>mdi-view-dashboard</v-icon>
      Dashboard
    </v-btn>

    <v-btn v-if="!isLoggedIn && !isLoginView && !isLoginOutsideView" to="/login" :color="'#343A40'" dark small
           class="elevation-0 rounded-0 mr-2 loginbtn_desktop">
      <v-icon class="mr-1" x-small>mdi-login</v-icon>
      Login
    </v-btn>


    <v-menu v-if="user_data_ && !user_allowed" content-class="elevation-0 rounded-0 pa-0" offset-y :right="true">
      <template v-slot:activator="{ on, attrs }">
        <v-btn :style="'border: solid 1px #e0e0e0;color:#1e1e1e'" :color="'#F7F7F7'" v-bind="attrs" v-on="on" small
               class=" elevation-0 rounded-0 pa-1 userbtn_mobile">
          <v-icon x-small>mdi-menu</v-icon>
        </v-btn>
        <v-btn :style="'border: solid 1px #e0e0e0;color:#1e1e1e'" :color="'#F7F7F7'" v-bind="attrs" v-on="on" small
               class=" elevation-0 rounded-0 pa-1 userbtn_desktop">
          <v-icon x-small>mdi-account</v-icon>
          &nbsp;
          <v-text v-if="user_data_">{{ user_data_.name }}</v-text>
          &nbsp;
          <v-icon small>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list :style="'font-size: 0.9em;min-width:12em;border: solid 1px #E0E0E0;background-color:#fff;color:#0000'"
              class="pa-1">
        <v-list-item v-if="user_data_" :style="'font-size:0.8em;color:#000'" class="pa-0 ma-0 pl-1">
          {{ user_data_.email }}
        </v-list-item>
        <v-divider v-if="user_data_.email"></v-divider>
        <v-list-item :style="'color:#000'" class="pa-0 ma-0 pl-1" @click="editProfile_()">
          Edit Profile
        </v-list-item>
        <v-list-item :style="'color:#000'" class="pa-0 ma-0 pl-1" @click="changePassword_()">
          Change Password
        </v-list-item>
        <v-list-item :style="'color:#000'" class="pa-0 ma-0 pl-1" @click="handleClick">
          Logout
        </v-list-item>
      </v-list>
    </v-menu>

    <v-menu v-if="isLoggedIn && user_allowed" content-class="elevation-0 rounded-0 pa-0" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-btn :style="'border: solid 1px #e0e0e0;color:#1e1e1e'" :color="'#F7F7F7'" v-bind="attrs" v-on="on" small
               class=" elevation-0 rounded-0 pa-1 userbtn_mobile">
          <v-icon x-small>mdi-menu</v-icon>
        </v-btn>
        <v-btn :style="'border: solid 1px #e0e0e0;color:#1e1e1e'" :color="'#F7F7F7'" v-bind="attrs" v-on="on" small
               class=" elevation-0 rounded-0 pa-1 userbtn_desktop">
          <v-icon x-small>mdi-account</v-icon>
          &nbsp;
          <v-text v-if="user_allowed">{{ user_allowed.name }}</v-text>
          &nbsp;
          <v-icon small>mdi-chevron-down</v-icon>
        </v-btn>
      </template>
      <v-list :style="'font-size: 0.9em;min-width:12em;border: solid 1px #E0E0E0;background-color:#fff;color:#0000'"
              class="pa-1">
        <v-list-item v-if="user_allowed" :style="'font-size:0.8em;color:#000'" class="pa-0 ma-0 pl-1">
          {{ user_allowed.email }}
        </v-list-item>
        <v-divider v-if="user_allowed.email"></v-divider>
        <v-list-item :style="'color:#000'" class="pa-0 ma-0 pl-1" @click="editProfile_()">
          Edit Profile
        </v-list-item>
        <v-list-item :style="'color:#000'" class="pa-0 ma-0 pl-1" @click="changePassword_()">
          Change Password
        </v-list-item>
        <v-list-item :style="'color:#000'" class="pa-0 ma-0 pl-1" @click="handleClick">
          Logout
        </v-list-item>
      </v-list>
    </v-menu>

    <template v-if="isServices" v-slot:extension>
      <v-tabs :style="'border-top: solid 1px #E0E0E0;'" class="rounded-0 elevation-0" :background-color="'#f7f7f7'"
              show-arrows :dark="false" left :color="'#174A8A'">
        <v-tabs-slider :color="'#174A8A'"></v-tabs-slider>

        <v-tab to="/navigatorplus">
          <div style="font-size: 0.85em;">Navigator+</div>
        </v-tab>
        <v-tab to="/mysailink">
          <div style="font-size: 0.85em;">My Sailink</div>
        </v-tab>
        <v-tab to="/reports">
          <div style="font-size: 0.85em;">Reports</div>
        </v-tab>
        <v-tab to="/snapshot">

          <div v-if="snap_inactive" style="font-size: 0.85em;">
            <v-badge :content="snap_inactive" :value="snap_inactive"
                     color="red" inline>Snapshot
            </v-badge>
          </div>

          <div v-else style="font-size: 0.85em;">Snapshot
          </div>


        </v-tab>
        <v-tab to="/mythuraya">
          <div style="font-size: 0.85em;">My Thuraya</div>
        </v-tab>
        <v-tab to="/manifest">
          <div style="font-size: 0.85em;">Manifest</div>
        </v-tab>
        <v-tab v-if="user_allowed && !user_allowed.allowed['hide iot list']" to="/sailogger">
          <div style="font-size: 0.85em;">Sailogger</div>
        </v-tab>
        <v-tab to="/orbcomm">
          <div style="font-size: 0.85em;">OrbComm</div>
        </v-tab>
        <v-tab v-if="user_allowed && user_allowed.role_id != 4" to="/admin">
          <div style="font-size: 0.85em;">Admin</div>
        </v-tab>
        <v-tab v-if="user_allowed && user_allowed.allowed['view crew list']" to="/crewwatch">
          <div style="font-size: 0.85em;">Crew Watch</div>
        </v-tab>
        <v-tab v-if="user_allowed && user_allowed.allowed['view summary']" to="/summary">
          <div style="font-size: 0.85em;">Summary</div>
        </v-tab>

      </v-tabs>
    </template>
    <EditProfileModal/>
    <EditPassModal/>
    <EditApiModal v-if="editapi"/>

  </v-app-bar>
</template>


<script>
import {mapGetters, mapActions} from "vuex";

import EditProfileModal from "@/components/modals/editprofile_modal.vue";
import EditPassModal from "@/components/modals/editpass_modal.vue";
import EditApiModal from "@/components/modals/apiaccess_modal.vue";

export default {
  name: "NavBar",
  props: ["user"],
  components: {
    EditProfileModal,
    EditPassModal,
    EditApiModal,
    // useOnline
  },
  data() {
    return {

      buka: null,
      image: require("@/assets/img/logo.svg"),
      iconcurrent: require("@/assets/img/navplus_outlined3.png"),
      image_white: require("@/assets/img/logo_white.svg"),
      imageb: require("@/assets/img/logo_sailink.svg"),
      imageb_white: require("@/assets/img/logo_sailink_white.svg"),
      imagec: require("@/assets/img/logo_mobile.svg"),
      imagec_white: require("@/assets/img/logo_mobile_white.svg"),
      imaged: require("@/assets/img/logo_sailink_mobile.svg"),
      imaged_white: require("@/assets/img/logo_sailink_mobile_white.svg"),
      services: false,
      home: true,
      account: false,
      ServiceisActive: false,
      HomeisActive: false,
      AccountisActive: false,
      navigator: true,
      mysailink: false,
      reports: false,
      mythuraya: false,
      manifest: false,
      iot: false,
      admin: false,
      userNames: localStorage.getItem("name"),
      root: null
    };
  },

  mounted() {
    this.root = document.documentElement;

  },

  watch: {},

  methods: {
    ...mapActions([
      "addoutsidelogin_",
      "addisDarks",
      "fetchUSERGET",
      "setEditPass",
      "setEditProfile",
      "fetchUserData",
      "setEditPassID",
      "fetchEditPass",
      "fetchEditProf",
      "resetNavplusState",
      "resetMysailinkState",
      "resetMythurayaState",
      "resetReportState",
      "resetSnapshotState",
      "resetIotState",
      "resetManifestState",
      "resetAdminState"
    ]),

    editProfile_() {
      this.fetchEditProf(this.user_allowed.id)
    },

    changePassword_() {
      this.fetchEditPass(this.user_allowed.id)
    },


    handleClick() {
      this.resetNavplusState();
      this.resetMysailinkState();
      this.resetMythurayaState();
      this.resetReportState();
      this.resetSnapshotState();
      this.resetIotState();
      this.resetManifestState();
      this.resetAdminState();
      localStorage.removeItem("token");
      localStorage.removeItem("user_data");
      localStorage.setItem("name", "");
      localStorage.setItem("auth", false);
      this.$router.push("/login");
      window.location.reload();
    },

  },
  computed: {
    ...mapGetters([
      "editapi",
      "outsidelogin_",
      "user_allowed",
      "userPass",
      "editprofile",
      "editpass",
      "userprof",
      "userData",
      "statusInternet",
      "Datauser",
      "isDarks",
      "snap_inactive"
    ]),

    isHome() {
      return this.$route.name === "home";
    },

    user_data_() {
      let data
      if (localStorage.getItem("auth") == 'true') {
        data = JSON.parse(localStorage.getItem("user_data"))
      } else {
        data = null
      }
      return data
    },

    isServices() {
      return this.$route.meta.navbar;
    },

    isLoginView() {
      return this.$route.name === "login";
    },

    isLoginOutsideView() {
      return this.$route.name === "outsidelogin";
    },

    isPrivacyView() {
      return this.$route.name == "privacy";
    },

    isServicesfirst() {
      return this.$route.path === "/navigatorplus";
    },

    isEditProfile() {
      return this.$route.path === "/editprofile";
    },

    isChangePw() {
      return this.$route.path === "/changepassword";
    },

    isHomefirst() {
      return this.$route.path !== "/" && "/sailink";
    },
    isLoggedIn() {
      return (
          localStorage.getItem("token") || this.$route.path === "/navigatorplus"
      );
    },

  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/navigationBar.scss";
@import "@/assets/css/navigationBar.css";
</style>
