<template>
  <v-dialog v-model="hsOpen" width="90vw" persistent>
    <v-card class="elevation-0 rounded-0">
      <v-toolbar  class="elevation-0 rounded-0" color="#f7f7f7" dense >
        <v-col class="header_modal">
          <v-row><v-toolbar-title>SOS History</v-toolbar-title></v-row>
        </v-col>

        <v-spacer></v-spacer>

        <v-btn class="elevation-0 rounded-0" small icon  @click="hsRemove()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar v-if="hsData"  class="elevation-0 rounded-0" color="#ffff"  dense>
        <v-toolbar-subtitle >{{
          hsData.vessel_number
        }}</v-toolbar-subtitle>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider v-if="hsData"></v-divider>

      <v-container style="margin-top:0" v-if="!hsData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>

      <v-card-text class="mt-4"  v-if="hsData">
        <v-data-table
        style="border: solid 1px #e0e0e0;border-bottom:none"
          v-if="hsData.messages"
          :items="hsData.messages"
            :items-per-page="itemsPerPage"
            hide-default-footer
            hide-default-header
            :page.sync="page"
            @page-count="pageCount = $event"
          class="elevation-0 rounded-0 image"
        >
          <template v-slot:item="row">
            <tr>
              <td style="background-color: #ffff;" >
                <v-card class="elevation-0 rounded-0"  style="border: solid 1px #e0e0e0;width:30em;padding: 1em;">
                <v-toolbar style="border: solid 1px #e0e0e0;" class="elevation-0 rounded-0" dense color="#f7f7f7" >
                    {{row.item.dateTime_local}}
                </v-toolbar>
                <v-responsive min-width="100%" min-height="60%">
                  <v-lazy>
            <client-only>
              <div style="border: solid 1px #e0e0e0;" id="map">
                <l-map
                ref="maphistory"
                @fullscreenchange="triggerFullscreen"
                style="height: 25em"
                    :zoom="6"
                    :options="{minZoom: 3, zoomControl: false, doubleClickZoom: true,scrollWheelZoom:false }"
                    :center=" [parseFloat(row.item.latitude), parseFloat(row.item.longitude)]"
                  >
                    <l-tile-layer
                      :url="url"
                      :attribution="attribution"
                    ></l-tile-layer>
                    <l-control-zoom position="bottomright"></l-control-zoom>
                    <l-control-fullscreen
      position="topright"
      :options="{ title: { false: 'Go big!', true: 'Be regular' } }"
    />
                    <v-rotated-marker  
      :lat-lng="[parseFloat(row.item.latitude), parseFloat(row.item.longitude)]"
      :rotationAngle="parseInt(row.item.heading)"
    >
      <l-icon
        :iconAnchor="[8, 5.81]"
        :iconSize:="iconSize"
        class-name="someExtraClass"
        
      >
        <svg
          id="a"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 139.77 162.56"
        >
          <path  
            :fill="'red'"
            stroke="#FFF"
            stroke-width="10"
            class="b"
            d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z"
          />
        </svg>
      </l-icon>
      <l-popup>
        <table>
                            <tr>
                              <td><b>Latitude</b></td>
                              <td>:</td>
                              <td>
                                {{ row.item.latitude }}
                              </td>
                            </tr>
                            <tr>
                              <td><b>Longitude</b></td>
                              <td>:</td>
                              <td>{{ row.item.longitude }}</td>
                            </tr>
                            <tr>
                              <td><b>DMS</b></td>
                              <td>:</td>
                              <td>{{ row.item.dms}}</td>
                            </tr>
                            <tr>
                              <td><b>Speed</b></td>
                              <td>:</td>
                              <td>{{ row.item.speed + "&nbsp;knots" }}</td>
                            </tr>
                            <tr>
                              <td><b>Heading</b></td>
                              <td>:</td>
                              <td>{{ row.item.heading + "&deg;" }}</td>
                            </tr>
                            <tr>
                              <td><b>Altitude</b></td>
                              <td>:</td>
                              <td>{{ row.item.altitude }}</td>
                            </tr>
                            <tr>
                              <td><b>Last Update</b></td>
                              <td>:</td>
                              <td>{{ row.item.updated_at }}</td>
                            </tr>
                          </table>
                         
                        <v-btn class="elevation-0 rounded-0" style="cursor:default" color="#f9d6d5" label block>SOS ALERT</v-btn>
                      
      </l-popup>
    </v-rotated-marker>
                  </l-map>
              </div>
              </client-only>
              </v-lazy>
                </v-responsive>
          
                 
                </v-card>
              </td>
            </tr>
          </template>
        </v-data-table>
        
        <v-toolbar style="border: solid 1px #e0e0e0"  dense class="elevation-0 rounded-0" color="#F7F7F7">
      
    
    <v-spacer></v-spacer>
    <v-pagination
      class="pt-2 pb-2"
      color="primary"
      v-model="page"
      :length="pageCount"
      :total-visible="7"
    ></v-pagination>
  </v-toolbar>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import {  LControlZoom, LMap, LTileLayer ,LIcon,LPopup} from "vue2-leaflet";
  import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import LControlFullscreen from "vue2-leaflet-fullscreen";
export default {
  components: {
    LMap,
    LIcon,
    LTileLayer,
    LControlZoom,
    LPopup,
    LControlFullscreen,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
  },

  data() {
    return {
      itemsPerPage: 4,
      page: 1,
      pageCount: 0,
      url: "https://maps.skyreach.id/hot/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 3,
      center: [47.41322, -1.219482],
      bounds: null,
      loadernavplus: require("@/assets/img/load100new.gif"),
    };
  },
  methods: {
    ...mapActions(["hsFetch", "hsRemove"]),
    triggerFullscreen(){
      if(this.$refs["maphistory"].mapObject._isFullscreen != true){
        this.$refs.maphistory.mapObject.scrollWheelZoom.disable();
      }else{
        this.$refs.maphistory.mapObject.scrollWheelZoom.enable();
      }
    },
  },

  computed: {
    ...mapGetters(["hsData", "hsOpen"]),
  },
};
</script>

<style lang="scss" scoped>
.image tbody {
  display: flex;
  white-space: initial;
  flex-wrap: wrap;
  text-align: center;
  width: 105vw;
  margin: 1em;
  padding: 2em 2em 1em 1em;
}

.image th {
  background-color: #0479f5;
  color: #fff;
}
</style>
