<template>
  <v-app>
    <v-container fill-height fluid style="margin-top: 0; height: 100%; background-color: #aad3df" class="pa-0">
      <l-map v-if="loading_map" ref="myMap" style="z-index: 0; height: 100%" :zoom="dynamicZoom" :center="dynamicCenter"
        :options="{ minZoom: 1, zoomControl: false, doubleClickZoom: true }" @click="targetActiveFunc">
        <l-tile-layer :url="isDarks ? url_dark : url" :attribution="attribution"></l-tile-layer>
        <l-control-zoom position="bottomright"></l-control-zoom>
        <l-control-polyline-measure :options="{
          showUnitControl: false,
          showClearControl: true,
          showBearings: true,
          unit: unit,
          clearMeasurementsOnStop: false,
        }" position="bottomright" />
        <l-control-fullscreen position="bottomright" :options="{ title: { false: 'Go big!', true: 'Be regular' } }" />

        <v-rotated-marker :lat-lng="[-1.035738275367759, 117.70794492139282]" :icon="loaderImage">
        </v-rotated-marker>
      </l-map>
      <l-map v-if="sailinkLocation || thurayaLocation" ref="myMap" style="z-index: 0; height: 100%" :zoom="dynamicZoom"
        :center="dynamicCenter" :options="{ minZoom: 1, zoomControl: false, doubleClickZoom: true }"
        @click="targetActiveFunc" @update:zoom="zoomUpdated" @update:center="centerUpdated" @ready="fetchDataMap_()">
        <l-tile-layer @ready="if_trackline()" :url="isDarks ? url_dark : url" :attribution="attribution"></l-tile-layer>

        <l-control-zoom position="bottomright"></l-control-zoom>
        <l-control-polyline-measure :options="{
          showUnitControl: false,
          showClearControl: true,
          showBearings: true,
          unit: unit,
          clearMeasurementsOnStop: false,
        }" position="bottomright" />
        <l-control-fullscreen position="bottomright" :options="{ title: { false: 'Go big!', true: 'Be regular' } }" />
        <l-control class="searchvsMobile" position="topright">
          <div class="searchvsMobile">
            <v-expansion-panels class="elevation-0 rounded-0" style="width: 45vw">
              <v-expansion-panel class="elevation-0 rounded-0 pa-0">
                <v-expansion-panel-header class="elevation-0 rounded-0 pa-1 ma-1" style="height: 2em">Search
                  Vessel
                </v-expansion-panel-header>
                <v-expansion-panel-content class="elevation-0 rounded-0 pa-0" width="20" style="text-align: left">
                  <div>
                    <div>
                      <v-card :width="258" elevation="0" style="
                          display: flex;
                          padding: 0;
                          height: 2.5em;
                          border-right: solid 1px #e0e0e0;
                        " class="rounded-0" color="#F7F7F7">
                        <v-text-field style="border-right: solid 1px #e0e0e0; color: #fff" width="100%" light dense
                          v-model="search" hide-details prepend-inner-icon="mdi-magnify" placeholder="Search Vessel" solo
                          clearable class="elevation-0 rounded-0" @click="expand = !expand"></v-text-field>
                        <v-btn class="elevation-0 rounded-0" small icon @click="expand = !expand">
                          <v-icon>{{
                            expand ? "mdi-chevron-up" : "mdi-chevron-down"
                          }}
                          </v-icon>
                        </v-btn>
                      </v-card>
                    </div>
                    <v-expand-transition>
                      <div v-show="expand">
                        <v-card :width="220" style="
                            overflow: auto;
                            margin-top: 0.6em;
                            max-height: 76vh;
                          " elevation="0" class="rounded-0">
                          <v-container v-if="!sidebarVessel" fluid>
                            <v-row align="center" justify="center">
                              <v-col></v-col>
                              <v-col><img alt="Navplus logo" width="100px" :src="loadernavplus"
                                  class="justify-center" /></v-col>
                              <v-col></v-col>
                            </v-row>
                          </v-container>
                          <div style="
                              background-color: #f7f7f7;
                              padding: 0.6em;
                              display: flex;
                              justify-content: space-between;
                            ">
                            <v-chip style="border: solid 1px #e0e0e0" color="#ffff" class="rounded-0 pa-1 ma-0"
                              small>Total:
                              <v-img :src="iconTotal" class="iconTotal_" />
                              {{
                                kFormatter(sidebarVessel.length)
                              }}
                            </v-chip>
                          </div>

                          <v-data-table v-if="sidebarVessel" :items="sidebarVessel" :headers="headers" hide-default-header
                            class="elevation-0" style="overflow-x: hidden" :search="search" sort-by="none"
                            hide-default-footer :page.sync="page" items-per-page="3" @page-count="pageCount = $event">
                            <template v-slot:item="row">
                              <tr v-if="row.item.printSl == true ||
                                row.item.printTh == true
                                ">
                                <td class="src_vessel_img pa-2">
                                  <v-img @click="
                                    row.item.identifier != null
                                      ? showinfosideSailink(row.item)
                                      : showinfosideThuraya(row.item)
                                    " :src="'https://navigatorplus.sailink.id/' +
    row.item.photo
    " style="cursor: pointer" class="imgSideBar_"></v-img>
                                </td>
                                <td style="white-space: nowrap; padding: 0em" class="pa-2">
                                  <v-card-subtitle style="
                                      text-align: left;
                                      margin-bottom: -1em;
                                    " class="pa-0">
                                    <div style="
                                        width: 10em;
                                        white-space: initial;
                                        line-height: normal;
                                      ">
                                      <b>{{ row.item.name }}</b>
                                    </div>
                                    <br />
                                    <div style="
                                        font-size: 0.9em;
                                        margin-top: -1.7em;
                                        width: 10em;
                                        white-space: initial;
                                        line-height: normal;
                                      ">
                                      {{ row.item.pt_name }}
                                    </div>
                                    <br />

                                    <v-btn style="margin-top: -3em" class="rounded-0 pa-1 mr-1" elevation="0" dark
                                      color="primary" x-small @click="showinfosideSailink(row.item)"
                                      v-if="row.item.identifier != null">
                                      Sailink
                                    </v-btn>
                                    <v-btn style="margin-top: -3em" class="rounded-0 pa-1"
                                      v-if="row.item.thuraya_number != null" elevation="0" dark color="#38C172" x-small
                                      @click="showinfosideThuraya(row.item)">
                                      Thuraya
                                    </v-btn>
                                  </v-card-subtitle>
                                </td>
                              </tr>
                            </template>
                          </v-data-table>
                          <div style="
                              background-color: #f7f7f7;
                              padding: 0.6em;
                              display: flex;
                              justify-content: space-between;
                            ">
                            <v-pagination color="primary" v-model="page" :length="pageCount"
                              total-visible="7"></v-pagination>
                          </div>
                        </v-card>
                      </div>
                    </v-expand-transition>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>

              <v-expansion-panel v-if="tr_vdowninfo && thuraya_dataFetch" class="elevation-0 rounded-0 pa-0">
                <v-expansion-panel-header class="elevation-0 rounded-0 pa-1 ma-1" style="height: 2em">
                  <div style="background-color: #fff5bb; padding: 0.3em" v-if="trackingOffData">
                    Tracking Off ({{ trackingOffData.length }})
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="elevation-0 rounded-0 pa-0" width="20" style="text-align: left">
                  <v-card :max-height="485" class="dinfo-dd rounded-0" elevation="0">
                    <v-container v-if="!trackingOffData" fluid>
                      <v-row align="center" justify="center">
                        <v-col></v-col>
                        <v-col><img alt="Navplus logo" width="100px" :src="loadernavplus"
                            class="justify-center" /></v-col>
                        <v-col></v-col>
                      </v-row>
                    </v-container>
                    <div style="
                        background-color: #f7f7f7;
                        padding: 0.6em;
                        display: flex;
                        justify-content: space-between;
                      ">
                      <v-text-field style="border: solid 1px #e0e0e0; margin-left: 0.5em" width="80" light dense
                        v-model="searchToff" hide-details placeholder="Search" solo clearable
                        class="elevation-0 rounded-0"></v-text-field>
                    </div>
                    <v-data-table v-if="trackingOffData" :items="trackingOffData" :headers="headers" hide-default-header
                      class="elevation-0" :search="searchToff" sort-by="none" hide-default-footer :page.sync="pageToff"
                      :items-per-page="3" @page-count="pageCountToff = $event">
                      <template v-slot:item="row">
                        <tr style="cursor: pointer" @click="
                          showinfosideThuraya(row.item),
                          (toffshow = null),
                          (vsatdownshow = null)
                          ">
                          <td style="white-space: nowrap; padding: 0em" class="pa-2">
                            <v-card-subtitle style="text-align: left" class="pa-1">
                              <div style="
                                  width: 10em;
                                  white-space: initial;
                                  line-height: normal;
                                ">
                                <b>{{ row.item.name }}</b>
                              </div>
                              <br />
                              <div style="
                                  font-size: 0.7em;
                                  margin-top: -1.8em;
                                  width: 25em;
                                  white-space: initial;
                                  line-height: normal;
                                ">
                                Down since :
                                {{ thurayadateDiffInDays(row.item) }}
                              </div>
                            </v-card-subtitle>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    <div style="
                        background-color: #f7f7f7;
                        padding: 0.6em;
                        display: flex;
                        justify-content: space-between;
                      ">
                      <v-pagination style="margin-left: -0.5em" color="primary" v-model="pageToff" :length="pageCountToff"
                        total-visible="3"></v-pagination>
                      <!-- <v-chip color="primary" dark class="rounded-0 pa-1" small ><v-img :src="iconTotal" style="margin-right:0.5em;width:0.5em"/>{{kFormatter(sidebarVessel.length)}}</v-chip> -->
                    </div>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="tr_vdowninfo && sailink_dataFetch" class="elevation-0 rounded-0 pa-0">
                <v-expansion-panel-header class="elevation-0 rounded-0 pa-1 ma-1" style="height: 2em; color: #fff">
                  <div style="background-color: #ff0000; padding: 0.3em">
                    VSAT Down ({{ vsatdownData ? vsatdownData.length : ".." }})
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content class="elevation-0 rounded-0 pa-0" width="20" style="text-align: left">
                  <v-card class="dinfo-dd rounded-0" elevation="0">
                    <div style="
                        background-color: #f7f7f7;
                        padding: 0.6em;
                        display: flex;
                        justify-content: space-between;
                      ">
                      <v-menu offset-y>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0"
                            color="#ffff" v-bind="attrs" v-on="on">
                            {{
                              kFormatter(itemsPerPageToff)
                            }}
                            <v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
                          </v-btn>
                        </template>
                        <v-list>
                          <v-list-item @click="itemsPerPageToff = 4">
                            <v-list-item-title>4</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="itemsPerPageToff = 6">
                            <v-list-item-title>6</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="itemsPerPageToff = 10">
                            <v-list-item-title>10</v-list-item-title>
                          </v-list-item>
                          <v-list-item @click="itemsPerPageToff = trackingOffData.length">
                            <v-list-item-title>All</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                      <v-text-field style="border: solid 1px #e0e0e0; margin-left: 0.5em" width="80" light dense
                        v-model="searchSailink" hide-details placeholder="Search" solo class="elevation-0 rounded-0"
                        clearable></v-text-field>
                    </div>
                    <v-container v-if="!sidebarVessel" fluid>
                      <v-row align="center" justify="center">
                        <v-col></v-col>
                        <v-col><img alt="Navplus logo" width="100px" :src="loadernavplus"
                            class="justify-center" /></v-col>
                        <v-col></v-col>
                      </v-row>
                    </v-container>
                    <v-data-table v-if="vsatdownData" :items="vsatdownData" :headers="headers" hide-default-header
                      class="elevation-0" :search="searchSailink" sort-by="none" hide-default-footer
                      :page.sync="pageSailink" :items-per-page="itemsPerPageSailink"
                      @page-count="pageCountSailink = $event">
                      <template v-slot:item="row">
                        <tr style="cursor: pointer" @click="
                          showinfosideSailink(row.item),
                          (vsatdownshow = null),
                          (toffshow = null)
                          ">
                          <td style="white-space: nowrap; padding: 0em" class="pa-2">
                            <v-card-subtitle style="text-align: left" class="pa-1">
                              <div style="
                                  width: 10em;
                                  white-space: initial;
                                  line-height: normal;
                                ">
                                <b>{{ row.item.name }}</b>
                              </div>
                              <br />
                              <div style="
                                  font-size: 0.7em;
                                  margin-top: -1.8em;
                                  width: 25em;
                                  white-space: initial;
                                  line-height: normal;
                                ">
                                Down since : {{ dateDiffInDays(row.item) }}
                              </div>
                            </v-card-subtitle>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>

                    <div style="
                        background-color: #f7f7f7;
                        padding: 0.6em;
                        display: flex;
                        justify-content: space-between;
                      ">
                      <v-pagination style="margin-left: -0.5em" color="primary" v-model="pageSailink"
                        :length="pageCountSailink" total-visible="3"></v-pagination>
                    </div>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </l-control>
        <l-control class="sideControls searchvsDesktop" style="display: flex" position="topright">
          <div class="nomarginSearch searchvsDesktop">
            <div>
              <v-card :width="258" elevation="0" :style="isDarks
                ? 'display: flex; padding: 0; height: 2.5em;border-right: solid 0px #1e1e1e;'
                : 'display: flex; padding: 0; height: 2.5em;border-right: solid 1px #E0E0E0;'
                " class="rounded-0" :color="isDarks ? 'primary' : '#F7F7F7'">
                <v-text-field :style="isDarks
                  ? 'border-right: solid 0px #1e1e1e;'
                  : 'border-right: solid 1px #E0E0E0;'
                  " width="80" :dark="isDarks ? true : false" light dense v-model="search" hide-details
                  prepend-inner-icon="mdi-magnify" placeholder="Search Vessel" v-on:keyup.native="expand = true" solo
                  clearable class="elevation-0 rounded-0" @click="expand = !expand"></v-text-field>
                <v-btn :color="isDarks ? '#ffff' : 'black'" class="elevation-0 rounded-0" small icon
                  @click="expand = !expand">
                  <v-icon>{{
                    expand ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}
                  </v-icon>
                </v-btn>
              </v-card>
            </div>
            <v-expand-transition>
              <div v-show="expand">
                <v-card :width="220" style="overflow: auto; margin-top: 0.6em; max-height: 76vh" elevation="0"
                  class="rounded-0">
                  <v-container v-if="!sidebarVessel" fluid>
                    <v-row align="center" justify="center">
                      <v-col></v-col>
                      <v-col><img alt="Navplus logo" width="100px" :src="loadernavplus" class="justify-center" /></v-col>
                      <v-col></v-col>
                    </v-row>
                  </v-container>
                  <div style="
                      background-color: #f7f7f7;
                      padding: 0.6em;
                      display: flex;
                      justify-content: space-between;
                    ">
                    <v-menu offset-y c>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0"
                          color="#ffff" v-bind="attrs" v-on="on">
                          {{
                            kFormatter(itemsPerPage)
                          }}
                          <v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
                        </v-btn>
                      </template>
                      <v-list class="elevation-0">
                        <v-list-item @click="itemsPerPage = 4">
                          <v-list-item-title>4</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="itemsPerPage = 6">
                          <v-list-item-title>6</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="itemsPerPage = 10">
                          <v-list-item-title>10</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="itemsPerPage = sidebarVessel.length">
                          <v-list-item-title>All</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-chip style="border: solid 1px #e0e0e0" color="#ffff" class="rounded-0 pa-1 ma-0" small>Total:
                      <v-img :src="iconTotal" class="iconTotal_" />
                      {{
                        kFormatter(sidebarVessel.length)
                      }}
                    </v-chip>
                  </div>

                  <v-data-table v-if="sidebarVessel" :items="sidebarVessel" :headers="headers" hide-default-header
                    class="elevation-0" style="overflow-x: hidden" :search="search" sort-by="none" hide-default-footer
                    :page.sync="page" :items-per-page="itemsPerPage" @page-count="pageCount = $event">
                    <template v-slot:item="row">
                      <tr v-if="row.item.printSl == true || row.item.printTh == true
                        ">
                        <td class="src_vessel_img pa-2">
                          <v-img @click="
                            row.item.identifier != null
                              ? showinfosideSailink(row.item)
                              : showinfosideThuraya(row.item)
                            " :lazy-src="loadernavplus" :src="'https://navigatorplus.sailink.id/' +
    row.item.photo
    " class="imgSideBar_" style="cursor: pointer"></v-img>
                        </td>
                        <td style="white-space: nowrap; padding: 0em" class="pa-2">
                          <v-card-subtitle style="text-align: left; margin-bottom: -1em" class="pa-0">
                            <div style="
                                width: 10em;
                                white-space: initial;
                                line-height: normal;
                              ">
                              <b>{{ row.item.name }}</b>
                            </div>
                            <br />
                            <div style="
                                font-size: 0.9em;
                                margin-top: -1.7em;
                                width: 10em;
                                white-space: initial;
                                line-height: normal;
                              ">
                              {{ row.item.pt_name }}
                            </div>
                            <br />

                            <v-btn style="margin-top: -3em" class="rounded-0 pa-1 mr-1" elevation="0" dark color="primary"
                              x-small @click="showinfosideSailink(row.item)" v-if="row.item.identifier != null">
                              Sailink
                            </v-btn>
                            <v-btn style="margin-top: -3em" class="rounded-0 pa-1" v-if="row.item.thuraya_number != null"
                              elevation="0" dark color="#38C172" x-small @click="showinfosideThuraya(row.item)">
                              Thuraya
                            </v-btn>
                          </v-card-subtitle>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <div style="
                      background-color: #f7f7f7;
                      padding: 0.6em;
                      display: flex;
                      justify-content: space-between;
                    ">
                    <v-pagination color="primary" v-model="page" :length="pageCount" total-visible="7"></v-pagination>
                  </div>
                </v-card>
              </div>
            </v-expand-transition>
          </div>
        </l-control>
        <!-- <l-circle
      :lat-lng="returnLatLong(pointerlatlng, typeMarker)"
      :radius="40"
      :color="'red'"
    /> -->
        <l-moving-rotated-marker v-if="drivePath" ref="driveMarker" :lat-lng="driveLatLng"
          :rotationAngle="driveRotationAngle" :duration="3000" :icon="svgMarkerMov(pointerlatlng, typeMarker)" />
        <v-rotated-marker v-if="sailinkLocation" v-for="marker in sailinkLocation"
          :lat-lng="returnLatLong(marker, 'sailink')" :rotationAngle="returnRotation(marker, 'sailink')"
          :key="'sailink' + marker.id" :marker="marker" :id="marker.name" :ref="'sailinkMarker' + marker.name"
          identity="sailink" @click="markerClicked(marker, 'sailink')">
          <l-icon :icon-size="[40 / 5, 100 / 5]" :icon-anchor="[40 / 5 / 2, 100 / 5 / 2]" class-name="someExtraClass">
            <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 100">
              <path :fill="!sailinkLocationUpdate
                ? marker.fill
                : !sailinkLocationUpdate[marker.name.toUpperCase()]
                  ? marker.fill
                  : sailinkLocationUpdate[marker.name.toUpperCase()]
                    ? sailinkLocationUpdate[marker.name.toUpperCase()].fill
                    : marker.fill
                " stroke="#FFF" stroke-width="10"
                d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" />
            </svg>
          </l-icon>
        </v-rotated-marker>

        <v-rotated-marker v-for="marker in thurayaLocation" :lat-lng="returnLatLong(marker, 'thuraya')" :rotationAngle="user_allowed.allowed['is super admin'] &&
          tr_falert &&
          marker.blink == true &&
          blinKing &&
          marker.fill != 'red'
          ? 0
          : tr_falert &&
            marker.blink == true &&
            blinKing &&
            marker.fill == 'red'
            ? 0
            : returnRotation(marker, 'thuraya')
          " :key="'thuraya' + marker.id" :marker="marker" :id="marker.name" :ref="'thurayaMarker' + marker.name"
          identity="thuraya" @click="markerClicked(marker, 'thuraya')">
          <l-icon v-if="user_allowed.allowed['is super admin'] &&
            tr_falert &&
            marker.blink == true &&
            blinKing &&
            marker.fill == 'orange'
            " :icon-size="[19, 19]" :icon-anchor="[9.5, 9.5]" :icon-url="alertyellow">
          </l-icon>
          <l-icon v-else-if="user_allowed.allowed['is super admin'] &&
            tr_falert &&
            marker.blink == true &&
            blinKing &&
            marker.fill == '#828784'
            " :icon-size="[19, 19]" :icon-anchor="[9.5, 9.5]" :icon-url="alertyellow">
          </l-icon>
          <l-icon v-else-if="tr_falert &&
            marker.blink == true &&
            blinKing &&
            marker.fill == 'red'
            " :icon-size="[19, 19]" :icon-anchor="[9.5, 9.5]" :icon-url="alertred">
          </l-icon>
          <l-icon v-else-if="!tr_falert || marker.blink == false || !blinKing" :icon-size="[40 / 5, 100 / 5]"
            :icon-anchor="[40 / 5 / 2, 100 / 5 / 2]" class-name="someExtraClass">
            <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 100">
              <path :fill="marker.fill" stroke="#FFF" stroke-width="10"
                d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" />
            </svg>
          </l-icon>
          <l-icon v-else :icon-size="[40 / 5, 100 / 5]" :icon-anchor="[40 / 5 / 2, 100 / 5 / 2]"
            class-name="someExtraClass">
            <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 100">
              <path :fill="marker.fill" stroke="#FFF" stroke-width="10"
                d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" />
            </svg>
          </l-icon>
        </v-rotated-marker>

        <v-rotated-marker v-if="targetActive" :lat-lng="returnLatLong(pointerlatlng, typeMarker)" :icon="markerimage"
          style="z-index:0 !important">
        </v-rotated-marker>
        <v-rotated-marker v-if="targetActive" :lat-lng="returnLatLong(pointerlatlng, typeMarker)"
          :rotationAngle="returnRotation(pointerlatlng, typeMarker)" :key="'markerCenter' + pointerlatlng.id"
          :id="pointerlatlng.name" identity="markerCenter" @click="markerClicked(pointerlatlng, typeMarker)"
          :icon="svgMarker(pointerlatlng, typeMarker)">
        </v-rotated-marker>
        <v-rotated-marker v-if="targetActive" :lat-lng="returnLatLong(pointerlatlng, typeMarker)"
          :icon="targetimage"></v-rotated-marker>

        <div v-if="tr_flabel">
          <v-rotated-marker v-for="marker in sailinkLocation" :lat-lng="returnLatLong(marker, 'sailink')"
            :key="'sailinkLabel' + marker.id" identity="sailinkLabel" @click="markerClicked(marker, 'sailink')">
            <l-icon :icon-size="[100, 40]" :icon-anchor="[100 / 2, 20 / 2]">
              <div v-if="tr_flabel" style="
                  margin-top: -1em;
                  min-width: 8em;
                  height: 20px;
                  white-space: nowrap;
                ">
                <p class="markerLabel" style="
                    white-space: nowrap;
                    font-family: OpenSans, sans-serif !important;
                  ">
                  {{
                    marker.name
                  }}<b style="font-weight:600" v-if="marker.speed && marker.speed != 0.0 && marker.speed != 0
    ">
                    [{{ marker.speed }} Kn]</b>
                </p>
              </div>
            </l-icon>
          </v-rotated-marker>
        </div>
        <div v-if="tr_flabel">
          <v-rotated-marker v-for="marker in thurayaLocation" :lat-lng="returnLatLong(marker, 'thuraya')"
            :key="'thurayaLabel' + marker.id" identity="thurayaLabel" @click="markerClicked(marker, 'thuraya')">
            <l-icon :icon-size="[100, 40]" :icon-anchor="[100 / 2, 20 / 2]">
              <div v-if="tr_flabel" style="
                  margin-top: -1em;
                  min-width: 8em;
                  height: 20px;
                  white-space: nowrap;
                ">
                <p class="markerLabel" style="
                    white-space: nowrap;
                    font-family: OpenSans, sans-serif !important;
                  ">
                  {{
                    marker.name
                  }}<b style="font-weight:600" v-if="marker.speed && marker.speed != 0.0 && marker.speed != 0
    ">
                    [{{ marker.speed }} Kn]</b><b style="font-weight:600" v-if="marker.rpmLabel && !user_allowed.allowed['hide iot list']
                      ">
                    {{ marker.rpmLabel }}</b>
                </p>
              </div>
            </l-icon>
          </v-rotated-marker>
        </div>

        <!--EIRP-->
        <div v-if="SKYR1 && EIRP">
          <l-polygon :key="sky1" v-for="(m, sky1) in paths_cns11" :lat-lngs="m" :color="polygon.skyr1" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.skyr1">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Skyreach1</strong><br />
                    <strong>Level: {{ gain_cns11[sky1] }}</strong><br />
                    <strong>[{{ sky1 }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>

        <div v-if="SKYR2 && EIRP">
          <l-polygon :key="sky2" v-for="(m, sky2) in paths_apstar5" :lat-lngs="m" :color="polygon.skyr2" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.skyr2">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Skyreach2</strong><br />
                    <strong>Level: {{ gain_apstar5[sky2] }}</strong><br />
                    <strong>[{{ sky2 }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>
        <div v-if="APST9 && EIRP">
          <l-polygon :key="apst9" v-for="(m, apst9) in paths_apstar9" :lat-lngs="m" :color="polygon.apst9" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.apst9">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Apstar 9</strong><br />
                    <strong>Level: {{ gain_apstar9[apst9] }}</strong><br />
                    <strong>[{{ apst9 }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>
        <div v-if="ABS2A && EIRP">
          <l-polygon :key="abs" v-for="(m, abs) in paths_abs" :lat-lngs="m" :color="polygon.abs2a" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.abs2a">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Abs 2A</strong><br />
                    <strong>Level: {{ gain_abs[abs] }}</strong><br />
                    <strong>[{{ abs }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>

        <div v-if="SR1MR && EIRP">
          <l-polygon :key="ocn" v-for="(m, ocn) in paths_ocn" :lat-lngs="m" :color="polygon.sr1mr" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.sr1mr">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : SR1 Maritime</strong><br />
                    <strong>Level: {{ gain_ocn[ocn] }}</strong><br />
                    <strong>[{{ ocn }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>

        <div v-if="SR2MR && EIRP">

          <l-polygon :key="hts" v-for="(m, hts) in paths_hts" :lat-lngs="m" :color="polygon.sr1mr" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.sr1mr">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Skyreach_2_HTS_BEAM4</strong><br />
                    <strong>Level: {{ gain_hts[hts] }}</strong><br />
                    <strong>[{{ hts }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>

        <div v-if="BEAM3 && EIRP">

          <l-polygon :key="beam3" v-for="(m, beam3) in paths_beam3" :lat-lngs="m" :color="polygon.beam3" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.beam3">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Skyreach_2_HTS_BEAM3</strong><br />
                    <strong>Level: {{ gain_beam3[beam3] }}</strong><br />
                    <strong>[{{ beam3 }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>

        </div>

        <div v-if="HRZ && EIRP">

          <l-polygon :key="hrz" v-for="(m, hrz) in paths_hrz" :lat-lngs="m" :color="polygon.hrz" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.hrz">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Skyreach_Intelsat_HTS</strong><br />
                    <strong>Level: {{ gain_hrz[hrz] }}</strong><br />
                    <strong>[{{ hrz }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>

        </div>

        <div v-if="UTL70B && EIRP">

          <l-polygon :key="utl" v-for="(m, utl) in paths_utl" :lat-lngs="m" :color="polygon.utl" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.utl">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Skyreach_Eutelsat</strong><br />
                    <strong>Level: {{ gain_utl[utl] }}</strong><br />
                    <strong>[{{ utl }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>

        </div>


        <!--GNT-->
        <div v-if="SKYR1 && GNT">
          <l-polygon :key="sky1gnt" v-for="(m, sky1gnt) in paths_cns11gnt" :lat-lngs="m" :color="polygon.skyr1"
            :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.skyr1">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Skyreach1</strong><br />
                    <strong>Level: {{ gain_cns11gnt[sky1gnt] }}</strong><br />
                    <strong>[{{ sky1gnt }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>

        <div v-if="SKYR2 && GNT">
          <l-polygon :key="sky2gnt" v-for="(m, sky2gnt) in paths_apstar5gnt" :lat-lngs="m" :color="polygon.skyr2"
            :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.skyr2">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Skyreach2</strong><br />
                    <strong>Level: {{ gain_apstar5gnt[sky2gnt] }}</strong><br />
                    <strong>[{{ sky2gnt }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>

        <div v-if="APST9 && GNT">
          <l-polygon :key="apst9gnt" v-for="(m, apst9gnt) in paths_apstar9gnt" :lat-lngs="m" :color="polygon.apst9"
            :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.apst9">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Apstar 9</strong><br />
                    <strong>Level: {{ gain_apstar9gnt[apst9gnt] }}</strong><br />
                    <strong>[{{ apst9gnt }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>

        <div v-if="ABS2A && GNT">
          <l-polygon :key="absgnt" v-for="(m, absgnt) in paths_absgnt" :lat-lngs="m" :color="polygon.abs2a" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.abs2a">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : ABS 2A</strong><br />
                    <strong>Level: {{ gain_absgnt[absgnt] }}</strong><br />
                    <strong>[{{ absgnt }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>

        <div v-if="SR1MR && GNT">
          <l-polygon :key="ocngnt" v-for="(m, ocngnt) in paths_ocngnt" :lat-lngs="m" :color="polygon.sr1mr" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.sr1mr">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : SR1 Maritime</strong><br />
                    <strong>Level: {{ gain_ocngnt[ocngnt] }}</strong><br />
                    <strong>[{{ ocngnt }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>
        <div v-if="SR2MR && GNT">
          <l-polygon :key="htsgnt" v-for="(m, htsgnt) in paths_htsgnt" :lat-lngs="m" :color="polygon.sr1mr" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.sr1mr">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Skyreach_2_HTS_BEAM4</strong><br />
                    <strong>Level: {{ gain_htsgnt[htsgnt] }}</strong><br />
                    <strong>[{{ htsgnt }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>
        <div v-if="BEAM3 && GNT">
          <l-polygon :key="beam3gnt" v-for="(m, beam3gnt) in paths_beam3gnt" :lat-lngs="m" :color="polygon.beam3"
            :weight="0.8" :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.beam3">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Skyreach_2_HTS_BEAM3</strong><br />
                    <strong>Level: {{ gain_beam3gnt[beam3gnt] }}</strong><br />
                    <strong>[{{ beam3gnt }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>
        <div v-if="HRZ && GNT">
          <l-polygon :key="hrzgnt" v-for="(m, hrzgnt) in paths_hrzgnt" :lat-lngs="m" :color="polygon.hrz" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.hrz">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Skyreach_Intelsat_HTS</strong><br />
                    <strong>Level: {{ gain_hrzgnt[hrzgnt] }}</strong><br />
                    <strong>[{{ hrzgnt }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>
        <div v-if="UTL70B && GNT">
          <l-polygon :key="utlgnt" v-for="(m, utlgnt) in paths_utlgnt" :lat-lngs="m" :color="polygon.utl" :weight="0.8"
            :opacity="0.9" :fillOpacity="0.03" :fillColor="polygon.utl">
            <l-popup>
              <div id="content">
                <div class="popup_polygon">
                  <img :src="satellite" alt="loading" style="height: 25px; margin-top: 20px; margin-right: 10px" />
                  <div style="text-align: left; margin-top: 20px">
                    <strong>SatNet : Skyreach_Eutelsat</strong><br />
                    <strong>Level: {{ gain_utlgnt[utlgnt] }}</strong><br />
                    <strong>[{{ utlgnt }}]</strong>
                  </div>
                </div>
              </div>
            </l-popup>
          </l-polygon>
        </div>
        <div v-if="drawerdata">
          <l-control v-if="drawerdataUp[drawerdata.name] ||
            drawerdataUpThur[drawerdata.name]" class="drawerMobile" position="bottomleft">
            <div>
              <v-slide-x-transition>
                <v-card elevation="0" class="mx-auto" style="margin-bottom: 1.4em;" v-show="drawers && targetActive" tile
                  transition="scroll-x-transition">
                  <v-btn width="10" x-small v-show="!hidden" color="#FFFFFF" absolute right style="
                    top: 0%;
                    left: 100%;
                    width: 2em;
                    border: solid 1px #e0e0e0;
                  " fab elevation="0" class="rounded-0 min-button pr-5" @click="targetActiveFunc()">
                    <v-icon style="right: -0.5em">mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-card width="85vw" v-if="drawerdata" elevation="0" style="overflow: hidden;">
                    <v-row class="pa-4">
                      <v-col cols="12" class="pa-0">
                        <v-card class="elevation-0 rounded-0 pa-0 ma-0">
                          <v-card-subtitle class="pa-0 ma-0">
                            <div class="text-wrap">
                              <strong>{{ drawerdata.name }}</strong>
                            </div>
                            <br />
                            <div style="margin-top: -1.5em" class="text-wrap">
                              {{ drawerdata.pt }}
                            </div>
                          </v-card-subtitle>
                        </v-card>
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                    <v-row class="pa-4">
                      <v-col v-if="typeMarker == 'thuraya'" cols="6" class="pa-0">
                        <table style="text-align:left">
                          <tr>
                            <td><b>Lat,Lon</b></td>
                            <td>:</td>
                            <td>
                              <div style="width:7em">
                                {{
                                  drawerdataUpThur[drawerdata.name].lat + "," + drawerdataUpThur[drawerdata.name].lon
                                }}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><b>DMS</b></td>
                            <td>:</td>
                            <td>
                              <div style="width:7em">{{ drawerdataUpThur[drawerdata.name].dms }}</div>
                            </td>
                          </tr>
                          <tr>
                            <td><b>Speed</b></td>
                            <td>:</td>
                            <td>
                              {{ drawerdataUpThur[drawerdata.name].speed + "&nbsp;Knot" }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Heading</b></td>
                            <td>:</td>
                            <td>{{ drawerdataUpThur[drawerdata.name].heading + "&deg;" }}</td>
                          </tr>
                        </table>
                      </v-col>
                      <v-col v-if="typeMarker == 'sailink'" cols="6" class="pa-0">
                        <table style="text-align:left">
                          <tr>
                            <td><b>Lat,Lon</b></td>
                            <td>:</td>
                            <td>
                              <div style="width:7em">
                                {{ drawerdataUp[drawerdata.name].lat + "," + drawerdataUp[drawerdata.name].lon }}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><b>DMS</b></td>
                            <td>:</td>
                            <td>
                              <div style="width:7em">{{ drawerdataUp[drawerdata.name].dms }}</div>
                            </td>
                          </tr>
                          <tr>
                            <td><b>Speed</b></td>
                            <td>:</td>
                            <td>
                              {{ drawerdataUp[drawerdata.name].speed + "&nbsp;Knot" }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Heading</b></td>
                            <td>:</td>
                            <td>{{ drawerdataUp[drawerdata.name].heading + "&deg;" }}</td>
                          </tr>
                        </table>
                      </v-col>
                      <v-col v-if="typeMarker == 'sailink' && !satNet" cols="6" class="pa-0">
                        <img alt="Navplus logo" width="30px" :src="loadernavplus" class="justify-center" />
                      </v-col>
                      <v-col v-if="typeMarker == 'thuraya'" cols="6" class="pa-0">
                        <table style="text-align:left">
                          <tr>
                            <td><b>Thuraya Number</b></td>
                            <td>:</td>
                            <td>+{{ drawerdata.thuraya_number }}</td>
                          </tr>

                          <tr>
                            <td><b>Service Plan</b></td>
                            <td>:</td>
                            <td>{{ drawerdata.rateplan }}</td>
                          </tr>
                        </table>
                      </v-col>
                      <v-col v-if="typeMarker == 'sailink' && satNet" cols="6" class="pa-0">
                        <table style="text-align:left">
                          <tr>
                            <td><b>SatNet</b></td>
                            <td>:</td>
                            <td>
                              {{ drawerdata.satnet }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>EIRP</b></td>
                            <td>:</td>
                            <td v-if="satNet.eirp">
                              {{ satNet.eirp }}
                            </td>
                            <td v-if="!satNet.eirp">- dBW</td>
                          </tr>
                          <tr>
                            <td><b>G/T+</b></td>
                            <td>:</td>
                            <td v-if="satNet.gnt">{{ satNet.gnt }}</td>
                            <td v-if="!satNet.gnt">- dB/K</td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                    <v-row class="pa-4 pt-0">
                      <v-col v-if="!weaTher" cols="12" class="pa-0">
                        <img alt="Navplus logo" width="30px" :src="loadernavplus" class="justify-center" />
                      </v-col>
                      <v-col v-if="weaTher" cols="12" class="pa-0">
                        <table style="text-align:left">
                          <tr>
                            <td><b>Weather</b></td>
                            <td>:</td>
                            <td style="display: flex">
                              <img style="width: 1.5em; margin-right: 0.3em" :src="'/weather/' + weaTher.iconSrc" />
                              <div style="margin-top: 0.2em">
                                {{ weaTher.weather }}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><b>Wind</b></td>
                            <td>:</td>
                            <td>{{ weaTher.windSpeed }}<img v-if="weaTher.windIcon" style="
                                            width: 1.5em;
                                            margin-right: 0.3em;
                                            margin-left:0.3em;
                                          " :src="'/weather/wind/' + weaTher.windIcon" />
                              <v-tooltip class="rounded-0 pa-0" right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text class="weather_style" v-on="on">{{
                                    weaTher.windDirection
                                  }}
                                  </v-text>
                                </template>
                                <span style="text-align:left;font-size:0.8em">The direction from which it originates,
                                  measured<br />in degrees clockwise from due north (at 10m).</span></v-tooltip>
                            </td>
                          </tr>
                          <tr>
                            <td><b>Wave</b></td>
                            <td>:</td>
                            <td>{{ weaTher.waveHeight }}<img v-if="weaTher.waveFromIcon" style="
                                            width: 1.5em;
                                            margin-right: 0.3em;
                                            margin-left:0.3em;
                                          " :src="'/weather/wind/' + weaTher.waveFromIcon" />
                              <v-tooltip class="rounded-0 pa-0" right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text class="weather_style" v-on="on">{{
                                    weaTher.waveFromDirection
                                  }}
                                  </v-text>
                                </template>
                                <span style="text-align:left;font-size:0.8em">The direction of the combined wind
                                  waves<br />and
                                  swells are moving in.</span></v-tooltip>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>

                    <v-divider></v-divider>
                    <div class="pa-1">
                      <v-chip :style="'border: dotted 2px #7C7E7E;color:black;width:100%;margin-top:1em;font-size:0.8em'
                        " label class="rounded-0" :color="'#FBFBDF'" v-if="typeMarker == 'thuraya' && drawerdata.rpm"
                        small>RPM : (S) {{
                          drawerdata.rpm.split("/")[1]
                        }} | (P) {{ drawerdata.rpm.split("/")[2] }} [{{ drawerdata.rpm.split("/")[0] }}]

                      </v-chip>
                    </div>
                    <div class="pa-1 pt-0">
                      <v-chip :style="'border: solid 1px #e0e0e0;color:black;width:100%'
                        " label class="rounded-0" :color="isDarks ? '#1e1e1e' : '#F7F7F7'" v-if="drawerdataUp[drawerdata.name] &&
    typeMarker == 'sailink'
    " small>
                        [Last Update :{{
                          drawerdataUp[drawerdata.name]["updated"]
                        }}
                        ]
                      </v-chip>
                      <v-chip :style="'border: solid 1px #e0e0e0;color:black'" label class="rounded-0"
                        :color="isDarks ? '#1e1e1e' : '#F7F7F7'" v-if="!drawerdataUp[drawerdata.name] &&
                            typeMarker == 'sailink'
                            " small>
                        [Last Update :{{ drawerdata.last_up }} ]
                      </v-chip>

                      <v-chip :style="'border: solid 1px #e0e0e0;color:black;width:100%'
                        " label class="rounded-0" :color="isDarks ? '#1e1e1e' : '#F7F7F7'" v-if="drawerdataUpThur[drawerdata.name] &&
    typeMarker == 'thuraya'
    " small>
                        [Last Update :{{
                          drawerdataUpThur[drawerdata.name]["updated"]
                        }}
                        ]
                      </v-chip>
                      <v-chip :style="'border: solid 1px #e0e0e0;color:black;width:100%'
                        " label class="rounded-0" :color="isDarks ? '#1e1e1e' : '#F7F7F7'" v-if="!drawerdataUpThur[drawerdata.name] &&
      typeMarker == 'thuraya'
      " small></v-chip>

                      <v-btn class="rounded-0 mt-1" style="margin-top: 0" dark block x-small color="primary" elevation="0"
                        @click="
                          typeMarker == 'sailink'
                            ? moreDetail(drawerdata)
                            : moreDetailThuraya(drawerdata)
                          ">
                        More Detail &nbsp;&nbsp;<v-icon x-small>mdi-arrow-expand-right</v-icon>
                      </v-btn>
                    </div>
                  </v-card>
                </v-card>
              </v-slide-x-transition>
            </div>
          </l-control>

          <l-control v-else class="drawerMobile" position="bottomleft">
            <div>
              <v-slide-x-transition>
                <v-card elevation="0" class="mx-auto" style="margin-bottom: 1.4em;" v-show="drawers && targetActive" tile
                  transition="scroll-x-transition">
                  <v-btn width="10" x-small v-show="!hidden" color="#FFFFFF" absolute right style="
                    top: 0%;
                    left: 100%;
                    width: 2em;
                    border: solid 1px #e0e0e0;
                  " fab elevation="0" class="rounded-0 min-button pr-5" @click="targetActiveFunc()">
                    <v-icon style="right: -0.5em">mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-card width="85vw" v-if="drawerdata" elevation="0" style="overflow: hidden;">
                    <v-row class="pa-4">
                      <v-col cols="12" class="pa-0">
                        <v-card class="elevation-0 rounded-0 pa-0 ma-0">
                          <v-card-subtitle class="pa-0 ma-0">
                            <div class="text-wrap">
                              <strong>{{ drawerdata.name }}</strong>
                            </div>
                            <br />
                            <div style="margin-top: -1.5em" class="text-wrap">
                              {{ drawerdata.pt }}
                            </div>
                          </v-card-subtitle>
                        </v-card>
                        <v-divider></v-divider>
                      </v-col>
                    </v-row>
                    <v-row class="pa-4">
                      <v-col cols="6" class="pa-0">
                        <table style="text-align:left">
                          <tr>
                            <td><b>Lat,Lon</b></td>
                            <td>:</td>
                            <td>
                              <div style="width:7em">
                                {{ drawerdata.lat + "," + drawerdata.lon }}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><b>DMS</b></td>
                            <td>:</td>
                            <td>
                              <div style="width:7em">{{ drawerdata.dms }}</div>
                            </td>
                          </tr>
                          <tr>
                            <td><b>Speed</b></td>
                            <td>:</td>
                            <td>
                              {{ drawerdata.speed + "&nbsp;Knot" }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Heading</b></td>
                            <td>:</td>
                            <td>{{ drawerdata.heading + "&deg;" }}</td>
                          </tr>
                        </table>
                      </v-col>
                      <v-col v-if="typeMarker == 'sailink' && !satNet" cols="6" class="pa-0">
                        <img alt="Navplus logo" width="30px" :src="loadernavplus" class="justify-center" />
                      </v-col>
                      <v-col v-if="typeMarker == 'thuraya'" cols="6" class="pa-0">
                        <table style="text-align:left">
                          <tr>
                            <td><b>Thuraya Number</b></td>
                            <td>:</td>
                            <td>+{{ drawerdata.thuraya_number }}</td>
                          </tr>

                          <tr>
                            <td><b>Service Plan</b></td>
                            <td>:</td>
                            <td>{{ drawerdata.rateplan }}</td>
                          </tr>
                        </table>
                      </v-col>
                      <v-col v-if="typeMarker == 'sailink' && satNet" cols="6" class="pa-0">
                        <table style="text-align:left">
                          <tr>
                            <td><b>SatNet</b></td>
                            <td>:</td>
                            <td>
                              {{ drawerdata.satnet }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>EIRP</b></td>
                            <td>:</td>
                            <td v-if="satNet.eirp">
                              {{ satNet.eirp }}
                            </td>
                            <td v-if="!satNet.eirp">- dBW</td>
                          </tr>
                          <tr>
                            <td><b>G/T+</b></td>
                            <td>:</td>
                            <td v-if="satNet.gnt">{{ satNet.gnt }}</td>
                            <td v-if="!satNet.gnt">- dB/K</td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>
                    <v-row class="pa-4 pt-0">
                      <v-col v-if="!weaTher" cols="12" class="pa-0">
                        <img alt="Navplus logo" width="30px" :src="loadernavplus" class="justify-center" />
                      </v-col>
                      <v-col v-if="weaTher" cols="12" class="pa-0">
                        <table style="text-align:left">
                          <tr>
                            <td><b>Weather</b></td>
                            <td>:</td>
                            <td style="display: flex">
                              <img style="width: 1.5em; margin-right: 0.3em" :src="'/weather/' + weaTher.iconSrc" />
                              <div style="margin-top: 0.2em">
                                {{ weaTher.weather }}
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td><b>Wind</b></td>
                            <td>:</td>
                            <td>{{ weaTher.windSpeed }}<img v-if="weaTher.windIcon" style="
                                            width: 1.5em;
                                            margin-right: 0.3em;
                                            margin-left:0.3em;
                                          " :src="'/weather/wind/' + weaTher.windIcon" />
                              <v-tooltip class="rounded-0 pa-0" right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text class="weather_style" v-on="on">{{
                                    weaTher.windDirection
                                  }}
                                  </v-text>
                                </template>
                                <span style="text-align:left;font-size:0.8em">The direction from which it originates,
                                  measured<br />in degrees clockwise from due north (at 10m).</span></v-tooltip>
                            </td>
                          </tr>
                          <tr>
                            <td><b>Wave</b></td>
                            <td>:</td>
                            <td>{{ weaTher.waveHeight }}<img v-if="weaTher.waveFromIcon" style="
                                            width: 1.5em;
                                            margin-right: 0.3em;
                                            margin-left:0.3em;
                                          " :src="'/weather/wind/' + weaTher.waveFromIcon" />
                              <v-tooltip class="rounded-0 pa-0" right>
                                <template v-slot:activator="{ on, attrs }">
                                  <v-text class="weather_style" v-on="on">{{
                                    weaTher.waveFromDirection
                                  }}
                                  </v-text>
                                </template>
                                <span style="text-align:left;font-size:0.8em">The direction of the combined wind
                                  waves<br />and
                                  swells are moving in.</span></v-tooltip>
                            </td>
                          </tr>
                        </table>
                      </v-col>
                    </v-row>

                    <v-divider></v-divider>
                    <div class="pa-1">
                      <v-chip :style="'border: dotted 2px #7C7E7E;color:black;width:100%;margin-top:1em;font-size:0.8em'
                        " label class="rounded-0" :color="'#FBFBDF'" v-if="typeMarker == 'thuraya' && drawerdata.rpm"
                        small>RPM : (S) {{
                          drawerdata.rpm.split("/")[1]
                        }} | (P) {{ drawerdata.rpm.split("/")[2] }} [{{ drawerdata.rpm.split("/")[0] }}]

                      </v-chip>
                    </div>
                    <div class="pa-1 pt-0">

                      <v-chip :style="'border: solid 1px #e0e0e0;color:black;width:100%'" label class="rounded-0"
                        :color="isDarks ? '#1e1e1e' : '#F7F7F7'" small>
                        [Last Update :{{
                          typeMarker == "sailink"
                          ? drawerdata.last_up
                          : drawerdata.updated
                        }}
                        ]
                      </v-chip>

                      <v-btn class="rounded-0 mt-1" style="margin-top: 0" dark block x-small color="primary" elevation="0"
                        @click="
                        typeMarker == 'sailink'
                          ? moreDetail(drawerdata)
                          : moreDetailThuraya(drawerdata)
                          ">
                        More Detail &nbsp;&nbsp;<v-icon x-small>mdi-arrow-expand-right</v-icon>
                      </v-btn>
                    </div>
                  </v-card>
                </v-card>
              </v-slide-x-transition>
            </div>
          </l-control>
        </div>
        <l-control position="topleft" class="drawerControl mapsatnetDesktop" style="
            display: flex;
            justify-content: space-between;
            margin-left: -0.2em;
          ">
          <div v-if="drawerdata">
            <div v-if="drawerdataUp[drawerdata.name] ||
              drawerdataUpThur[drawerdata.name]
              " class="drawerDesktop">
              <v-slide-x-transition>
                <v-card elevation="0" class="mx-auto" height="150vh" style="margin-top: -1.5vh"
                  v-show="drawers && targetActive" tile transition="scroll-x-transition">
                  <v-btn width="10" x-small v-show="!hidden" :color="isDarks ? '#3C4048' : '#FFFFFF'" absolute right
                    :style="isDarks
                      ? 'top: 21%; left: 93%; width: 2em;border: solid 1px #3C4048;color:#1976D2'
                      : 'top: 21%; left: 93%; width: 2em;border: solid 1px #E0E0E0;color:black'
                      " fab elevation="0" class="rounded-0 min-button" @click="targetActiveFunc()">
                    <v-icon style="right: -0.5em">mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-navigation-drawer :color="isDarks ? '#3C4048' : '#ffff'" width="280" permanent v-if="drawerdata"
                    elevation="0">
                    <v-list>
                      <v-list-item style="text-align: left">
                        <v-list-item-content>
                          <v-list-item-title :style="isDarks ? 'color:#ffff' : 'color:black'" class="text-wrap">
                            {{ drawerdata.name }}
                          </v-list-item-title>
                          <v-list-item-subtitle :style="isDarks ? 'color:#ffff' : 'color:black'" class="text-wrap">{{
                            drawerdata.pt
                          }}
                          </v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                    </v-list>
                    <v-divider :color="isDarks ? 'black' : '#e0e0e0'"></v-divider>
                    <v-list style="margin-left: -1em" nav dense>
                      <v-list-item-group v-model="selectedItem" color="primary">
                        <v-list-item v-if="typeMarker == 'thuraya'">
                          <v-card :color="isDarks ? '#3C4048' : '#ffff'" elevation="0" class="mx-auto" width="700" tile>
                            <v-list-item :style="isDarks ? 'color:#ffff' : 'color:black'" three-line>
                              <v-list-item-content style="text-align: left">
                                <v-list-item-title>
                                  <table>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-phone</v-icon>
                                      </td>
                                      <td><b>Thuraya Number</b></td>
                                      <td>:</td>
                                      <td>+{{ drawerdata.thuraya_number }}</td>
                                    </tr>

                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-cellphone-dock
                                        </v-icon>
                                      </td>
                                      <td><b>Service Plan</b></td>
                                      <td>:</td>
                                      <td>{{ drawerdata.rateplan }}</td>
                                    </tr>
                                  </table>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-list-item>

                        <v-list-item style="overflow-x: visible">
                          <v-card :color="isDarks ? '#3C4048' : '#ffff'" elevation="0" class="mx-auto" width="700" tile>
                            <v-list-item v-if="typeMarker == 'thuraya'" :style="isDarks ? 'color:#ffff' : 'color:black'"
                              three-line>
                              <v-list-item-content style="text-align: left">
                                <v-list-item-title>
                                  <table>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-latitude</v-icon>
                                      </td>
                                      <td><b>Lat,Lon</b></td>
                                      <td>:</td>
                                      <td v-if="drawerdataUpThur[
                                        drawerdata.name.toUpperCase()
                                      ]
                                        ">
                                        {{
                                          drawerdataUpThur[
                                            drawerdata.name.toUpperCase()
                                          ].lat +
                                          "," +
                                          drawerdataUpThur[
                                            drawerdata.name.toUpperCase()
                                          ].lon
                                        }}
                                      </td>
                                      <td v-if="!drawerdataUpThur[
                                          drawerdata.name.toUpperCase()
                                          ]
                                          ">
                                        {{
                                          drawerdata.lat + "," + drawerdata.lon
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-longitude</v-icon>
                                      </td>
                                      <td><b>DMS</b></td>
                                      <td>:</td>
                                      <td v-if="drawerdataUpThur[
                                        drawerdata.name.toUpperCase()
                                      ]
                                        ">
                                        {{
                                          drawerdataUpThur[
                                            drawerdata.name.toUpperCase()
                                          ].dms
                                        }}
                                      </td>
                                      <td v-if="!drawerdataUpThur[
                                          drawerdata.name.toUpperCase()
                                          ]
                                          ">
                                        {{ drawerdata.dms }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-speedometer</v-icon>
                                      </td>
                                      <td><b>Speed</b></td>
                                      <td>:</td>
                                      <td v-if="drawerdataUpThur[
                                        drawerdata.name.toUpperCase()
                                      ]
                                        ">
                                        {{
                                          drawerdataUpThur[
                                            drawerdata.name.toUpperCase()
                                          ].speed + "&nbsp;Knot"
                                        }}
                                      </td>
                                      <td v-if="!drawerdataUpThur[
                                          drawerdata.name.toUpperCase()
                                          ]
                                          ">
                                        {{ drawerdata.speed + "&nbsp;Knot" }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-navigation</v-icon>
                                      </td>
                                      <td><b>Heading</b></td>
                                      <td>:</td>
                                      <td v-if="drawerdataUpThur[
                                        drawerdata.name.toUpperCase()
                                      ]
                                        ">
                                        {{
                                          drawerdataUpThur[
                                            drawerdata.name.toUpperCase()
                                          ].heading + "&deg;"
                                        }}
                                      </td>
                                      <td v-if="!drawerdataUpThur[
                                          drawerdata.name.toUpperCase()
                                          ]
                                          ">
                                        {{ drawerdata.heading + "&deg;" }}
                                      </td>
                                    </tr>
                                  </table>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                            <v-list-item v-if="typeMarker == 'sailink'" :style="isDarks ? 'color:#ffff' : 'color:black'"
                              three-line>
                              <v-list-item-content style="text-align: left">
                                <v-list-item-title>
                                  <table>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-latitude</v-icon>
                                      </td>
                                      <td><b>Lat,Lon</b></td>
                                      <td>:</td>
                                      <td v-if="drawerdataUp[
                                        drawerdata.name.toUpperCase()
                                      ]
                                        ">
                                        {{
                                          drawerdataUp[
                                            drawerdata.name.toUpperCase()
                                          ].lat +
                                          "," +
                                          drawerdataUp[
                                            drawerdata.name.toUpperCase()
                                          ].lon
                                        }}
                                      </td>
                                      <td v-if="!drawerdataUp[
                                          drawerdata.name.toUpperCase()
                                          ]
                                          ">
                                        {{
                                          drawerdata.lat + "," + drawerdata.lon
                                        }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-longitude</v-icon>
                                      </td>
                                      <td><b>DMS</b></td>
                                      <td>:</td>
                                      <td v-if="drawerdataUp[
                                        drawerdata.name.toUpperCase()
                                      ]
                                        ">
                                        {{
                                          drawerdataUp[
                                            drawerdata.name.toUpperCase()
                                          ].dms
                                        }}
                                      </td>
                                      <td v-if="!drawerdataUp[
                                          drawerdata.name.toUpperCase()
                                          ]
                                          ">
                                        {{ drawerdata.dms }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-speedometer</v-icon>
                                      </td>
                                      <td><b>Speed</b></td>
                                      <td>:</td>
                                      <td v-if="drawerdataUp[
                                        drawerdata.name.toUpperCase()
                                      ]
                                        ">
                                        {{
                                          drawerdataUp[
                                            drawerdata.name.toUpperCase()
                                          ].speed + "&nbsp;Knot"
                                        }}
                                      </td>
                                      <td v-if="!drawerdataUp[
                                          drawerdata.name.toUpperCase()
                                          ]
                                          ">
                                        {{ drawerdata.speed + "&nbsp;Knot" }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-navigation</v-icon>
                                      </td>
                                      <td><b>Heading</b></td>
                                      <td>:</td>
                                      <td v-if="drawerdataUp[
                                        drawerdata.name.toUpperCase()
                                      ]
                                        ">
                                        {{
                                          drawerdataUp[
                                            drawerdata.name.toUpperCase()
                                          ].heading + "&deg;"
                                        }}
                                      </td>
                                      <td v-if="!drawerdataUp[
                                          drawerdata.name.toUpperCase()
                                          ]
                                          ">
                                        {{ drawerdata.heading + "&deg;" }}
                                      </td>
                                    </tr>
                                  </table>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-list-item>
                        
                        <v-list-item v-if="typeMarker == 'sailink' && !satNet">
                          <v-card :color="isDarks ? '#3C4048' : '#ffff'" elevation="0" class="mx-auto" width="700" tile>
                            <v-list-item :style="isDarks ? 'color:#ffff' : 'color:black'" three-line>
                              <v-list-item-content style="text-align: left">
                                <v-list-item-title>
                                  <table>
                                    <tr>
                                      <td style="padding-left: 5.6em">
                                        <img alt="Navplus logo" width="50px" :src="loadernavplus"
                                          class="justify-center" />
                                      </td>
                                    </tr>
                                  </table>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-list-item>
                        <v-list-item v-if="typeMarker == 'sailink' && satNet">
                          <v-card :color="isDarks ? '#3C4048' : '#ffff'" elevation="0" class="mx-auto" width="700" tile>
                            <v-list-item :style="isDarks ? 'color:#ffff' : 'color:black'" three-line>
                              <v-list-item-content style="text-align: left">
                                <v-list-item-title>
                                  <table>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-satellite-variant
                                        </v-icon>
                                      </td>
                                      <td><b>SatNet</b></td>
                                      <td>:</td>
                                      <td>
                                        {{ drawerdata.satnet }}
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-satellite-uplink
                                        </v-icon>
                                      </td>
                                      <td><b>EIRP</b></td>
                                      <td>:</td>
                                      <td v-if="satNet.eirp">
                                        {{ satNet.eirp }}
                                      </td>
                                      <td v-if="!satNet.eirp">- dBW</td>
                                    </tr>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-satellite-uplink
                                        </v-icon>
                                      </td>
                                      <td><b>G/T+</b></td>
                                      <td>:</td>
                                      <td v-if="satNet.gnt">
                                        {{ satNet.gnt }}
                                      </td>
                                      <td v-if="!satNet.gnt">- dB/K</td>
                                    </tr>
                                  </table>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-list-item>

                        <v-list-item v-if="!weaTher">
                          <v-card :color="isDarks ? '#3C4048' : '#ffff'" elevation="0" class="mx-auto" width="700" tile>
                            <v-list-item :style="isDarks ? 'color:#ffff' : 'color:black'" three-line>
                              <v-list-item-content style="text-align: left">
                                <v-list-item-title>
                                  <table>
                                    <tr>
                                      <td style="padding-left: 5.6em">
                                        <img alt="Navplus logo" width="50px" :src="loadernavplus"
                                          class="justify-center" />
                                      </td>
                                    </tr>
                                  </table>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-list-item>

                        <v-list-item v-if="weaTher">
                          <v-card :color="isDarks ? '#3C4048' : '#ffff'" elevation="0" class="mx-auto" width="700" tile>
                            <v-list-item :style="isDarks ? 'color:#ffff' : 'color:black'" three-line>
                              <v-list-item-content style="text-align: left">
                                <v-list-item-title>
                                  <table>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-weather-hazy</v-icon>
                                      </td>
                                      <td><b>Weather</b></td>
                                      <td>:</td>
                                      <td style="display: flex">
                                        <img style="
                                            width: 1.5em;
                                            margin-right: 0.3em;
                                          " :src="'/weather/' + weaTher.iconSrc" />
                                        <div style="margin-top: 0.2em">
                                          {{ weaTher.weather }}
                                        </div>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-weather-windy
                                        </v-icon>
                                      </td>
                                      <td><b>Wind</b></td>
                                      <td>:</td>
                                      <td>{{ weaTher.windSpeed }}<img v-if="weaTher.windIcon" style="
                                            width: 1.5em;
                                            margin-right: 0.3em;
                                            margin-left:0.3em;
                                          " :src="'/weather/wind/' + weaTher.windIcon" />
                                        <v-tooltip class="rounded-0 pa-0" right>
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text class="weather_style" v-on="on">{{ weaTher.windDirection }}
                                            </v-text>
                                          </template>
                                          <span style="text-align:left;font-size:0.8em">The direction from which it
                                            originates, measured<br />in degrees clockwise from due north (at 10m).</span>
                                        </v-tooltip>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td style="padding-right: 0.3em">
                                        <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-waves</v-icon>
                                      </td>
                                      <td><b>Wave</b></td>
                                      <td>:</td>
                                      <td>{{ weaTher.waveHeight }}<img v-if="weaTher.waveFromIcon" style="
                                            width: 1.5em;
                                            margin-right: 0.3em;
                                            margin-left:0.3em;
                                          " :src="'/weather/wind/' + weaTher.waveFromIcon" />
                                        <v-tooltip class="rounded-0 pa-0" right>
                                          <template v-slot:activator="{ on, attrs }">
                                            <v-text class="weather_style" v-on="on">{{ weaTher.waveFromDirection }}
                                            </v-text>
                                          </template>
                                          <span style="text-align:left;font-size:0.8em">The direction of the combined wind
                                            waves<br />and swells are moving in.</span></v-tooltip>
                                      </td>
                                    </tr>
                                  </table>
                                </v-list-item-title>
                              </v-list-item-content>
                            </v-list-item>
                          </v-card>
                        </v-list-item>
                      </v-list-item-group>
                    </v-list>
                    <v-divider :color="isDarks ? 'black' : '#e0e0e0'"></v-divider>
                    <template>
                      <div class="pa-2">
                        <v-chip :style="isDarks
                          ? 'border: solid 1px #1e1e1e;color:#ffff'
                          : 'border: solid 1px #e0e0e0;color:black'
                          " label class="rounded-0" :color="isDarks ? '#1e1e1e' : '#F7F7F7'" v-if="drawerdataUp[drawerdata.name] &&
    typeMarker == 'sailink'
    " small>
                          [Last Update :{{
                            drawerdataUp[drawerdata.name]["updated"]
                          }}
                          ]
                        </v-chip>
                        <v-chip :style="isDarks
                          ? 'border: solid 1px #1e1e1e;color:#ffff'
                          : 'border: solid 1px #e0e0e0;color:black'
                          " label class="rounded-0" :color="isDarks ? '#1e1e1e' : '#F7F7F7'" v-if="!drawerdataUp[drawerdata.name] &&
      typeMarker == 'sailink'
      " small>
                          [Last Update :{{ drawerdata.last_up }} ]
                        </v-chip>

                        <v-chip :style="isDarks
                          ? 'border: solid 1px #1e1e1e;color:#ffff'
                          : 'border: solid 1px #e0e0e0;color:black'
                          " label class="rounded-0" :color="isDarks ? '#1e1e1e' : '#F7F7F7'" v-if="drawerdataUpThur[drawerdata.name] &&
    typeMarker == 'thuraya'
    " small>
                          [Last Update :{{
                            drawerdataUpThur[drawerdata.name]["updated"]
                          }}
                          ]
                        </v-chip>
                        <v-chip :style="isDarks
                          ? 'border: solid 1px #1e1e1e;color:#ffff'
                          : 'border: solid 1px #e0e0e0;color:black'
                          " label class="rounded-0" :color="isDarks ? '#1e1e1e' : '#F7F7F7'" v-if="!drawerdataUpThur[drawerdata.name] &&
      typeMarker == 'thuraya'
      " small>
                          [Last Update :{{ drawerdata.updated }} ]
                        </v-chip>
                        <v-chip :style="'border: dotted 2px #7C7E7E;color:black;width:100%;margin-top:1em;font-size:0.8em'
                          " label class="rounded-0" :color="'#FBFBDF'" v-if="typeMarker == 'thuraya' && drawerdata.rpm"
                          small>RPM : (S) {{
                            drawerdata.rpm.split("/")[1]
                          }} | (P) {{ drawerdata.rpm.split("/")[2] }} [{{
  drawerdata.rpm.split("/")[0]
}}]

                        </v-chip>


                        <v-btn class="rounded-0" style="margin-top: 1em" dark block small color="primary" elevation="0"
                          @click="
                          typeMarker == 'sailink'
                            ? moreDetail(drawerdata)
                            : moreDetailThuraya(drawerdata)
                            ">
                          More Detail &nbsp;&nbsp;<v-icon x-small>mdi-arrow-expand-right</v-icon>
                        </v-btn>
                      </div>
                    </template>
                  </v-navigation-drawer>
                </v-card>
              </v-slide-x-transition>
            </div>
            <div v-else class="drawerDesktop">
              <v-slide-x-transition>
                <v-card elevation="0" class="mx-auto" height="150vh" style="margin-top: -1.5vh"
                  v-show="drawers && targetActive" tile transition="scroll-x-transition">
                  <v-btn width="10" x-small v-show="!hidden" :color="isDarks ? '#3C4048' : '#FFFFFF'" absolute right
                    :style="isDarks
                      ? 'top: 21%; left: 93%; width: 2em;border: solid 1px #3C4048;color:#1976D2'
                      : 'top: 21%; left: 93%; width: 2em;border: solid 1px #E0E0E0;color:black'
                      " fab elevation="0" class="rounded-0 min-button" @click="setdrawers(false)">
                    <v-icon style="right: -0.5em">mdi-chevron-left</v-icon>
                  </v-btn>
                  <v-navigation-drawer :color="isDarks ? '#3C4048' : '#ffff'" width="280" permanent v-if="drawerdata"
                    elevation="0">
                    <div v-if="drawerdata">
                      <v-list>
                        <v-list-item style="text-align: left">
                          <v-list-item-content>
                            <v-list-item-title :style="isDarks ? 'color:#ffff' : 'color:black'" class="text-wrap">
                              {{ drawerdata.name }}
                            </v-list-item-title>
                            <v-list-item-subtitle :style="isDarks ? 'color:#ffff' : 'color:black'" class="text-wrap">{{
                              drawerdata.pt
                            }}
                            </v-list-item-subtitle>
                          </v-list-item-content>
                        </v-list-item>
                      </v-list>
                      <v-divider :color="isDarks ? 'black' : '#e0e0e0'"></v-divider>
                      <v-list style="margin-left: -1em" nav dense>
                        <v-list-item-group v-model="selectedItem" color="primary">
                          <v-list-item v-if="typeMarker == 'thuraya'">
                            <v-card :color="isDarks ? '#3C4048' : '#ffff'" elevation="0" class="mx-auto" width="700" tile>
                              <v-list-item :style="isDarks ? 'color:#ffff' : 'color:black'" three-line>
                                <v-list-item-content style="text-align: left">
                                  <v-list-item-title>
                                    <table>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-phone</v-icon>
                                        </td>
                                        <td><b>Thuraya Number</b></td>
                                        <td>:</td>
                                        <td>+{{ drawerdata.thuraya_number }}</td>
                                      </tr>

                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-cellphone-dock
                                          </v-icon>
                                        </td>
                                        <td><b>Service Plan</b></td>
                                        <td>:</td>
                                        <td>{{ drawerdata.rateplan }}</td>
                                      </tr>
                                    </table>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-list-item>

                          <v-list-item style="overflow-x: visible">
                            <v-card :color="isDarks ? '#3C4048' : '#ffff'" elevation="0" class="mx-auto" width="700" tile>
                              <v-list-item v-if="typeMarker == 'thuraya'" :style="isDarks ? 'color:#ffff' : 'color:black'"
                                three-line>
                                <v-list-item-content style="text-align: left">
                                  <v-list-item-title>
                                    <table>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-latitude</v-icon>
                                        </td>
                                        <td><b>Lat,Lon</b></td>
                                        <td>:</td>
                                        <td>
                                          {{
                                            drawerdata.lat + "," + drawerdata.lon
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-longitude</v-icon>
                                        </td>
                                        <td><b>DMS</b></td>
                                        <td>:</td>

                                        <td>{{ drawerdata.dms }}</td>
                                      </tr>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-speedometer
                                          </v-icon>
                                        </td>
                                        <td><b>Speed</b></td>
                                        <td>:</td>

                                        <td>
                                          {{ drawerdata.speed + "&nbsp;Knot" }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-navigation</v-icon>
                                        </td>
                                        <td><b>Heading</b></td>
                                        <td>:</td>

                                        <td>
                                          {{ drawerdata.heading + "&deg;" }}
                                        </td>
                                      </tr>
                                    </table>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                              <v-list-item v-if="typeMarker == 'sailink'" :style="isDarks ? 'color:#ffff' : 'color:black'"
                                three-line>
                                <v-list-item-content style="text-align: left">
                                  <v-list-item-title>
                                    <table>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-latitude</v-icon>
                                        </td>
                                        <td><b>Lat,Lon</b></td>
                                        <td>:</td>

                                        <td>
                                          {{
                                            drawerdata.lat + "," + drawerdata.lon
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-longitude</v-icon>
                                        </td>
                                        <td><b>DMS</b></td>
                                        <td>:</td>

                                        <td>{{ drawerdata.dms }}</td>
                                      </tr>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-speedometer
                                          </v-icon>
                                        </td>
                                        <td><b>Speed</b></td>
                                        <td>:</td>

                                        <td>
                                          {{ drawerdata.speed + "&nbsp;Knot" }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-navigation</v-icon>
                                        </td>
                                        <td><b>Heading</b></td>
                                        <td>:</td>

                                        <td>
                                          {{ drawerdata.heading + "&deg;" }}
                                        </td>
                                      </tr>
                                    </table>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-list-item>
                          <v-list-item v-if="typeMarker == 'thuraya' && !satNet">

                            <v-card :color="'#f4f4f4'" elevation="0" class="mx-auto" width="700" tile>
                              <v-list-item :style="isDarks ? 'color:#ffff' : 'color:black'" three-line>
                                <v-list-item-content style="text-align: left">
                                  <v-list-item-title>
                                    <table>
                                      <tr>
                                        <td style="padding-left: 5.6em">
                                          <img alt="Navplus logo" width="50px" :src="loadernavplus"
                                            class="justify-center" />
                                        </td>
                                      </tr>
                                    </table>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-list-item>
                          <v-list-item v-if="typeMarker == 'thuraya' && satNet">
                            <v-card  elevation="0" class="mx-auto" width="700" tile>
                              <div class="box-title">VSAT</div>
                              <v-list-item :style="isDarks ? 'color:#ffff' : 'color:black'" class="box-overlay" three-line>
                                <v-list-item-content style="text-align: left">
                                  <v-list-item-title>
                                    <table>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-satellite-variant
                                          </v-icon>
                                        </td>
                                        <td><b>SatNet</b></td>
                                        <td>:</td>
                                        <td>
                                          {{ drawerdata.satnet }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-satellite-uplink
                                          </v-icon>
                                        </td>
                                        <td><b>EIRP</b></td>
                                        <td>:</td>
                                        <td v-if="satNet.eirp">
                                          {{ satNet.eirp }}
                                        </td>
                                        <td v-if="!satNet.eirp">- dBW</td>
                                      </tr>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-satellite-uplink
                                          </v-icon>
                                        </td>
                                        <td><b>G/T+</b></td>
                                        <td>:</td>
                                        <td v-if="satNet.gnt">
                                          {{ satNet.gnt }}
                                        </td>
                                        <td v-if="!satNet.gnt">- dB/K</td>
                                      </tr>
                                    </table>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-list-item>
                          <v-list-item v-if="typeMarker == 'sailink' && !satNet">
                            <v-card :color="isDarks ? '#3C4048' : '#ffff'" elevation="0" class="mx-auto" width="700" tile>
                              <v-list-item :style="isDarks ? 'color:#ffff' : 'color:black'" three-line>
                                <v-list-item-content style="text-align: left">
                                  <v-list-item-title>
                                    <table>
                                      <tr>
                                        <td style="padding-left: 5.6em">
                                          <img alt="Navplus logo" width="50px" :src="loadernavplus"
                                            class="justify-center" />
                                        </td>
                                      </tr>
                                    </table>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-list-item>
                          <v-list-item v-if="typeMarker == 'sailink' && satNet">
                            <v-card :color="isDarks ? '#3C4048' : '#ffff'" elevation="0" class="mx-auto" width="700" tile>
                              <v-list-item :style="isDarks ? 'color:#ffff' : 'color:black'" three-line>
                                <v-list-item-content style="text-align: left">
                                  <v-list-item-title>
                                    <table>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-satellite-variant
                                          </v-icon>
                                        </td>
                                        <td><b>SatNet</b></td>
                                        <td>:</td>
                                        <td>
                                          {{ drawerdata.satnet }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-satellite-uplink
                                          </v-icon>
                                        </td>
                                        <td><b>EIRP</b></td>
                                        <td>:</td>
                                        <td v-if="satNet.eirp">
                                          {{ satNet.eirp }}
                                        </td>
                                        <td v-if="!satNet.eirp">- dBW</td>
                                      </tr>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-satellite-uplink
                                          </v-icon>
                                        </td>
                                        <td><b>G/T+</b></td>
                                        <td>:</td>
                                        <td v-if="satNet.gnt">
                                          {{ satNet.gnt }}
                                        </td>
                                        <td v-if="!satNet.gnt">- dB/K</td>
                                      </tr>
                                    </table>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-list-item>

                          <v-list-item v-if="!weaTher">
                            <v-card :color="isDarks ? '#3C4048' : '#ffff'" elevation="0" class="mx-auto" width="700" tile>
                              <v-list-item :style="isDarks ? 'color:#ffff' : 'color:black'" three-line>
                                <v-list-item-content style="text-align: left">
                                  <v-list-item-title>
                                    <table>
                                      <tr>
                                        <td style="padding-left: 5.6em">
                                          <img alt="Navplus logo" width="50px" :src="loadernavplus"
                                            class="justify-center" />
                                        </td>
                                      </tr>
                                    </table>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-list-item>

                          <v-list-item v-if="weaTher">
                            <v-card :color="isDarks ? '#3C4048' : '#ffff'" elevation="0" class="mx-auto" width="700" tile>
                              <v-list-item :style="isDarks ? 'color:#ffff' : 'color:black'" three-line>
                                <v-list-item-content style="text-align: left">
                                  <v-list-item-title>
                                    <table>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-weather-hazy
                                          </v-icon>
                                        </td>
                                        <td><b>Weather</b></td>
                                        <td>:</td>
                                        <td style="display: flex">
                                          <img style="
                                            width: 1.5em;
                                            margin-right: 0.3em;
                                          " :src="'/weather/' + weaTher.iconSrc" />
                                          <div style="margin-top: 0.2em">
                                            {{ weaTher.weather }}
                                          </div>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-weather-windy
                                          </v-icon>
                                        </td>
                                        <td><b>Wind</b></td>
                                        <td>:</td>
                                        <td>{{ weaTher.windSpeed }}<img v-if="weaTher.windIcon" style="
                                            width: 1.5em;
                                            margin-right: 0.3em;
                                            margin-left:0.3em;
                                          " :src="'/weather/wind/' + weaTher.windIcon" />
                                          <v-tooltip class="rounded-0 pa-0" right>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-text class="weather_style" v-on="on">{{ weaTher.windDirection }}
                                              </v-text>
                                            </template>
                                            <span style="text-align:left;font-size:0.8em">The direction from which it
                                              originates, measured<br />in degrees clockwise from due north (at
                                              10m).</span>
                                          </v-tooltip>
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding-right: 0.3em">
                                          <v-icon :color="isDarks ? 'primary' : 'black'" x-small>mdi-waves</v-icon>
                                        </td>
                                        <td><b>Wave</b></td>
                                        <td>:</td>
                                        <td>{{ weaTher.waveHeight }}<img v-if="weaTher.waveFromIcon" style="
                                            width: 1.5em;
                                            margin-right: 0.3em;
                                            margin-left:0.3em;
                                          " :src="'/weather/wind/' + weaTher.waveFromIcon" />
                                          <v-tooltip class="rounded-0 pa-0" right>
                                            <template v-slot:activator="{ on, attrs }">
                                              <v-text class="weather_style" v-on="on">{{ weaTher.waveFromDirection }}
                                              </v-text>
                                            </template>
                                            <span style="text-align:left;font-size:0.8em">The direction of the combined
                                              wind
                                              waves<br />and swells are moving in.</span></v-tooltip>
                                        </td>
                                      </tr>
                                    </table>
                                  </v-list-item-title>
                                </v-list-item-content>
                              </v-list-item>
                            </v-card>
                          </v-list-item>
                        </v-list-item-group>
                      </v-list>
                      <v-divider :color="isDarks ? 'black' : '#e0e0e0'"></v-divider>
                      <template>
                        <div class="pa-2">
                          <v-chip :style="isDarks
                            ? 'border: solid 1px #1e1e1e;color:#ffff'
                            : 'border: solid 1px #e0e0e0;color:black'
                            " label class="rounded-0" :color="isDarks ? '#1e1e1e' : '#F7F7F7'" small>
                            [Last Update :{{
                              typeMarker == "sailink"
                              ? drawerdata.last_up
                              : drawerdata.updated
                            }}
                            ]
                          </v-chip>

                          <v-chip :style="'border: dotted 2px #7C7E7E;color:black;width:100%;margin-top:1em;font-size:0.8em'
                            " label class="rounded-0" :color="'#FBFBDF'"
                            v-if="typeMarker == 'thuraya' && drawerdata.rpm" small>RPM : (S) {{
                              drawerdata.rpm.split("/")[1]
                            }} | (P) {{ drawerdata.rpm.split("/")[2] }} [{{
  drawerdata.rpm.split("/")[0]
}}]

                          </v-chip>

                          <v-btn class="rounded-0" style="margin-top: 1em" dark block small color="primary" elevation="0"
                            @click="
                            typeMarker == 'sailink'
                              ? moreDetail(drawerdata)
                              : moreDetailThuraya(drawerdata)
                              ">
                            More Detail &nbsp;&nbsp;<v-icon x-small>mdi-arrow-expand-right</v-icon>
                          </v-btn>
                        </div>
                      </template>
                    </div>
                  </v-navigation-drawer>
                </v-card>
              </v-slide-x-transition>
            </div>
          </div>
          <div class="mapsatnetDesktop" v-if="tr_linfo" style="margin-left: 0.9em">
            <v-card width="135" elevation="0" class="rounded-0">
              <div style="
                  background-color: #f7f7f7;
                  height: 2.2em;
                  border-bottom: 1px solid #e0e0e0;
                  font-size: 1.2em;
                  padding-top: 1em;
                " class="elevation-0 pa-0">
                <div style="padding-top: 0.4em">Legend</div>
              </div>
              <div class="legend_iner">
                <ul class="list_legend">
                  <li class="listlist_legend">
                    <img :src="upimg" class="vs_image" />&nbsp;SAILINK UP
                  </li>
                  <li class="listlist_legend">
                    <img :src="downimg" class="vs_image" />&nbsp;SAILINK DOWN
                  </li>
                  <li class="listlist_legend">
                    <img :src="offimg" class="vs_image" />&nbsp;SAILINK Off-Hire
                  </li>
                  <li class="listlist_legend">
                    <img :src="suspendimg" class="vs_image" />&nbsp;SAILINK
                    Suspended
                  </li>
                  <li class="listlist_legend">
                    <img :src="thurupimg" class="vs_image" />&nbsp;Thuraya UP
                  </li>
                  <li class="listlist_legend">
                    <img :src="thurinactiveimg" class="vs_image" />&nbsp;Thuraya
                    INACTIVE
                  </li>
                </ul>
              </div>
            </v-card>
          </div>
          <div class="mapsatnetDesktop">
            <label class="dropdown">
              <div :style="isDarks
                ? 'border: solid 1px #1e1e1e;'
                : 'border: solid 1px #E0E0E0;'
                " @click="mapclick = !mapclick" :class="isDarks ? 'dd-button-dark' : 'dd-button'">
                <v-icon :color="isDarks ? 'primary' : 'black'">mdi-map-legend</v-icon>
                <v-text style="margin-top: 0.3em">MAP</v-text>
                <v-icon :color="isDarks ? 'primary' : 'black'">{{
                  mapclick ? "mdi-chevron-up" : "mdi-chevron-down"
                }}
                </v-icon>
              </div>

              <input type="checkbox" class="dd-input" id="test" />

              <ul :style="isDarks
                  ? 'border: solid 1px #1e1e1e;background-color:#3C4048;color:#ffff'
                  : 'border: solid 1px #E0E0E0;background-color:#ffff;color:black'
                " class="dd-menu">
                <li class="bot_li" @click="setBMKG">
                  <input type="checkbox" v-model="tr_bmkg" @change="triggerBMKG" />&nbsp;&nbsp;BMKG
                </li>
                <li class="bot_li" @click="setFLABEL">
                  <input type="checkbox" v-model="tr_flabel" @change="triggerFLABEL" />&nbsp;&nbsp;Fleet Label
                </li>
                <li v-if="user_allowed.allowed['is super admin']" class="bot_li" @click="setFALERT">
                  <input type="checkbox" v-model="tr_falert" @change="triggerFALERT" />&nbsp;&nbsp;Fleet Allert
                </li>
                <li v-if="user_allowed.allowed['is super admin'] &&
                  (!thuraya_dataFetch || !sailink_dataFetch)
                  " class="bot_li">
                  <input disabled type="checkbox" v-model="tr_vdowninfo" />&nbsp;&nbsp;Vessel Down Info
                </li>
                <li v-if="user_allowed.allowed['is super admin'] &&
                  (thuraya_dataFetch || sailink_dataFetch)
                  " class="bot_li" @click="setVDOWN">
                  <input type="checkbox" v-model="tr_vdowninfo" @change="triggerVDOWN" />&nbsp;&nbsp;Vessel Down Info
                </li>
                <li v-if="user_allowed.allowed['is super admin']" class="bot_li" @click="setLINFO">
                  <input type="checkbox" v-model="tr_linfo" @change="triggerLINFO" />&nbsp;&nbsp;Legend Info
                </li>
              </ul>
            </label>
          </div>
          <div class="mapsatnetDesktop" v-if="user_allowed.role_id == 2 || user_allowed.role_id == 5">
            <label class="dropdown">
              <div :style="isDarks
                ? 'border: solid 1px #1e1e1e;'
                : 'border: solid 1px #E0E0E0;'
                " @click="satnetclick = !satnetclick" :class="isDarks ? 'dd-button-dark' : 'dd-button'">
                <v-icon :color="isDarks ? 'primary' : 'black'">mdi-satellite-variant</v-icon>
                <v-text style="margin-top: 0.3em">SatNet</v-text>
                <v-icon :color="isDarks ? 'primary' : 'black'">{{
                  satnetclick ? "mdi-chevron-up" : "mdi-chevron-down"
                }}
                </v-icon>
              </div>
              <input type="checkbox" class="dd-input" />

              <ul :style="isDarks
                  ? 'border: solid 1px #1e1e1e;background-color:#3C4048;color:#ffff'
                  : 'border: solid 1px #E0E0E0;background-color:#ffff;color:black'
                " class="dd-menu">
                <li :style="isDarks
                  ? 'background-color: #3C4048;'
                  : 'background-color: #F7F7F7;'
                  " class="uper_li" @click="setEIRP_DT">
                  <input type="checkbox" v-model="EIRP" @change="triggerEIRP" />&nbsp;EIRP
                </li>
                <li :style="isDarks
                  ? 'background-color: #3C4048;'
                  : 'background-color: #F7F7F7;'
                  " class="uper_li" @click="setGNT_DT">
                  <input type="checkbox" v-model="GNT" @change="triggerGNT" />&nbsp;G/T+
                </li>
                <li v-if="isDarks == 0" class="divider"></li>
                <li class="bot_li" @click="
                  !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                  SKYR1 ? setSKYR1(false) : setSKYR1(true)
                  ">
                  <input type="checkbox" v-model="SKYR1" @change="triggerSKYR1" />&nbsp;Skyreach 1
                </li>
                <li class="bot_li" @click="
                  !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                  SKYR2 ? setSKYR2(false) : setSKYR2(true)
                  ">
                  <input type="checkbox" v-model="SKYR2" @change="triggerSKYR2" />&nbsp;Skyreach 2
                </li>
                <li class="bot_li" @click="
                  !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                  APST9 ? setAPST9(false) : setAPST9(true)
                  ">
                  <input type="checkbox" v-model="APST9" @change="triggerAPST9" />&nbsp;Apstar 9
                </li>
                <li class="bot_li" @click="
                  !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                  ABS2A ? setABS2A(false) : setABS2A(true)
                  ">
                  <input type="checkbox" v-model="ABS2A" value="ABS2A" @change="triggerABS2A" />&nbsp;ABS 2A
                </li>
                <li class="bot_li" @click="
                  !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                  SR1MR ? setSR1MR(false) : setSR1MR(true)
                  ">
                  <input type="checkbox" v-model="SR1MR" value="SR1MR" @change="triggerSR1MR" />&nbsp;SR1 Maritime
                </li>
                <li class="bot_li" @click="
                  !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                  SR2MR ? setSR2MR(false) : setSR2MR(true)
                  ">
                  <input type="checkbox" v-model="SR2MR" value="SR2MR" @change="triggerSR2MR" />&nbsp;SR2 HTS BEAM#4
                </li>
                <li class="bot_li" @click="
                  !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                  BEAM3 ? setBEAM3(false) : setBEAM3(true)
                  ">
                  <input type="checkbox" v-model="BEAM3" value="BEAM3" @change="triggerBEAM3" />&nbsp;SR2 HTS BEAM#3
                </li>
                <li class="bot_li" @click="
                  !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                  HRZ ? setHRZ(false) : setHRZ(true)
                  ">
                  <input type="checkbox" v-model="HRZ" value="HRZ" @change="triggerHRZ" />&nbsp;HORIZON 3E
                </li>
                <li class="bot_li" @click="
                  !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                  UTL70B ? setUTL70B(false) : setUTL70B(true)
                  ">
                  <input type="checkbox" v-model="UTL70B" value="UTL70B" @change="triggerUTL70B" />&nbsp;Utelsat 70B
                </li>
              </ul>
            </label>
          </div>
          <div class="mapsatnetDesktop" v-if="tr_vdowninfo" style="margin-left: 0.9em">
            <div v-if="trackingOffData" class="dinfo-btn" @click="toffshow = !toffshow">
              <img :src="thuraya_white" style="width: 1.8em" />
              <v-text style="margin-top: 0.3em">Tracking Off ({{ trackingOffData.length }})</v-text>
              <v-icon>{{
                toffshow ? "mdi-chevron-up" : "mdi-chevron-down"
              }}
              </v-icon>
            </div>

            <v-expand-transition>
              <div v-show="toffshow">
                <v-card :max-height="485" class="dinfo-dd rounded-0" elevation="0">
                  <v-container v-if="!trackingOffData" fluid>
                    <v-row align="center" justify="center">
                      <v-col></v-col>
                      <v-col><img alt="Navplus logo" width="100px" :src="loadernavplus" class="justify-center" /></v-col>
                      <v-col></v-col>
                    </v-row>
                  </v-container>
                  <div style="
                      background-color: #f7f7f7;
                      padding: 0.6em;
                      display: flex;
                      justify-content: space-between;
                    ">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0"
                          color="#ffff" v-bind="attrs" v-on="on">
                          {{
                            kFormatter(itemsPerPageToff)
                          }}
                          <v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="itemsPerPageToff = 4">
                          <v-list-item-title>4</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="itemsPerPageToff = 6">
                          <v-list-item-title>6</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="itemsPerPageToff = 10">
                          <v-list-item-title>10</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="itemsPerPageToff = trackingOffData.length">
                          <v-list-item-title>All</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-text-field style="border: solid 1px #e0e0e0; margin-left: 0.5em" width="80" light dense
                      v-model="searchToff" hide-details placeholder="Search" solo clearable
                      class="elevation-0 rounded-0"></v-text-field>
                  </div>
                  <v-data-table v-if="trackingOffData" :items="trackingOffData" :headers="headers" hide-default-header
                    class="elevation-0" :search="searchToff" sort-by="none" hide-default-footer :page.sync="pageToff"
                    :items-per-page="itemsPerPageToff" @page-count="pageCountToff = $event">
                    <template v-slot:item="row">
                      <tr style="cursor: pointer" @click="
                        showinfosideThuraya(row.item),
                        (toffshow = null),
                        (vsatdownshow = null)
                        ">
                        <td style="white-space: nowrap; padding: 0em" class="pa-2">
                          <v-card-subtitle style="text-align: left" class="pa-1">
                            <div style="
                                width: 10em;
                                white-space: initial;
                                line-height: normal;
                              ">
                              <b>{{ row.item.name }}</b>
                            </div>
                            <br />
                            <div style="
                                font-size: 0.7em;
                                margin-top: -1.8em;
                                width: 25em;
                                white-space: initial;
                                line-height: normal;
                              ">
                              Down since : {{ thurayadateDiffInDays(row.item) }}
                            </div>
                          </v-card-subtitle>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>
                  <div style="
                      background-color: #f7f7f7;
                      padding: 0.6em;
                      display: flex;
                      justify-content: space-between;
                    ">
                    <v-pagination style="margin-left: -0.5em" color="primary" v-model="pageToff" :length="pageCountToff"
                      total-visible="3"></v-pagination>
                    <!-- <v-chip color="primary" dark class="rounded-0 pa-1" small ><v-img :src="iconTotal" style="margin-right:0.5em;width:0.5em"/>{{kFormatter(sidebarVessel.length)}}</v-chip> -->
                  </div>
                </v-card>
              </div>
            </v-expand-transition>
          </div>
          <div class="mapsatnetDesktop" v-if="tr_vdowninfo" style="margin-left: 0.9em">
            <div v-if="!vsatdownData" class="dinfo-btn-vsat" @click="vsatdownshow = !vsatdownshow">
              <img :src="sailink_white" style="
                  width: 1.3em;
                  height: 1.3em;
                  margin-top: 0.4em;
                  margin-left: 0.2em;
                " />
              <v-text style="margin-top: 0.3em">VSAT Down (..)</v-text>
              <v-icon dark>{{
                vsatdownshow ? "mdi-chevron-up" : "mdi-chevron-down"
              }}
              </v-icon>
            </div>
            <div v-if="vsatdownData" class="dinfo-btn-vsat" @click="vsatdownshow = !vsatdownshow">
              <img :src="sailink_white" style="
                  width: 1.3em;
                  height: 1.3em;
                  margin-top: 0.4em;
                  margin-left: 0.2em;
                " />
              <v-text style="margin-top: 0.3em">VSAT Down ({{ vsatdownData.length }})</v-text>
              <v-icon dark>{{
                vsatdownshow ? "mdi-chevron-up" : "mdi-chevron-down"
              }}
              </v-icon>
            </div>

            <v-expand-transition>
              <div v-show="vsatdownshow">
                <v-card class="dinfo-dd rounded-0" elevation="0">
                  <div style="
                      background-color: #f7f7f7;
                      padding: 0.6em;
                      display: flex;
                      justify-content: space-between;
                    ">
                    <v-menu offset-y>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0"
                          color="#ffff" v-bind="attrs" v-on="on">
                          {{
                            kFormatter(itemsPerPageToff)
                          }}
                          <v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
                        </v-btn>
                      </template>
                      <v-list>
                        <v-list-item @click="itemsPerPageToff = 4">
                          <v-list-item-title>4</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="itemsPerPageToff = 6">
                          <v-list-item-title>6</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="itemsPerPageToff = 10">
                          <v-list-item-title>10</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="itemsPerPageToff = trackingOffData.length">
                          <v-list-item-title>All</v-list-item-title>
                        </v-list-item>
                      </v-list>
                    </v-menu>
                    <v-text-field style="border: solid 1px #e0e0e0; margin-left: 0.5em" width="80" light dense
                      v-model="searchSailink" hide-details placeholder="Search" solo class="elevation-0 rounded-0"
                      clearable></v-text-field>
                  </div>
                  <v-container v-if="!sidebarVessel" fluid>
                    <v-row align="center" justify="center">
                      <v-col></v-col>
                      <v-col><img alt="Navplus logo" width="100px" :src="loadernavplus" class="justify-center" /></v-col>
                      <v-col></v-col>
                    </v-row>
                  </v-container>
                  <v-data-table v-if="vsatdownData" :items="vsatdownData" :headers="headers" hide-default-header
                    class="elevation-0" :search="searchSailink" sort-by="none" hide-default-footer
                    :page.sync="pageSailink" :items-per-page="itemsPerPageSailink"
                    @page-count="pageCountSailink = $event">
                    <template v-slot:item="row">
                      <tr style="cursor: pointer" @click="
                        showinfosideSailink(row.item),
                        (vsatdownshow = null),
                        (toffshow = null)
                        ">
                        <td style="white-space: nowrap; padding: 0em" class="pa-2">
                          <v-card-subtitle style="text-align: left" class="pa-1">
                            <div style="
                                width: 10em;
                                white-space: initial;
                                line-height: normal;
                              ">
                              <b>{{ row.item.name }}</b>
                            </div>
                            <br />
                            <div style="
                                font-size: 0.7em;
                                margin-top: -1.8em;
                                width: 25em;
                                white-space: initial;
                                line-height: normal;
                              ">
                              Down since : {{ dateDiffInDays(row.item) }}
                            </div>
                          </v-card-subtitle>
                        </td>
                      </tr>
                    </template>
                  </v-data-table>

                  <div style="
                      background-color: #f7f7f7;
                      padding: 0.6em;
                      display: flex;
                      justify-content: space-between;
                    ">
                    <v-pagination style="margin-left: -0.5em" color="primary" v-model="pageSailink"
                      :length="pageCountSailink" total-visible="3"></v-pagination>
                  </div>
                </v-card>
              </div>
            </v-expand-transition>
          </div>
        </l-control>

        <l-control position="bottomleft" v-if="!thuraya_dataFetch || !sailink_dataFetch">
          <div :style="drawers ? 'margin-left:21vw' : 'margin-left:0vw'" v-if="!tr_bmkg">
            <img style="margin-left: 2em; margin-bottom: 1.5em" alt="Navplus logo" width="80em" :src="loadernavplus"
              class="justify-center" />
          </div>
        </l-control>

        <l-control position="topleft" class="mapsatnetMobile" style="margin-top: 0em">
          <div class="mapsatnetMobile">
            <v-expansion-panels class="elevation-0 rounded-0" style="width: 45vw">
              <v-expansion-panel class="elevation-0 rounded-0 pa-0">
                <v-expansion-panel-header class="elevation-0 rounded-0 pa-1 ma-1" style="height: 2em">MAP
                </v-expansion-panel-header>
                <v-expansion-panel-content class="elevation-0 rounded-0 pa-0" width="20" style="text-align: left">
                  <div @click="setBMKG">
                    <input type="checkbox" v-model="tr_bmkg" @change="triggerBMKG" />&nbsp;&nbsp;BMKG
                  </div>
                  <div @click="setFLABEL">
                    <input type="checkbox" v-model="tr_flabel" @change="triggerFLABEL" />&nbsp;&nbsp;Fleet Label
                  </div>
                  <div v-if="user_allowed.allowed['is super admin']" @click="setFALERT">
                    <input type="checkbox" v-model="tr_falert" @change="triggerFALERT" />&nbsp;&nbsp;Fleet Allert
                  </div>
                  <div v-if="user_allowed.allowed['is super admin'] &&
                    (!thuraya_dataFetch || !sailink_dataFetch)
                    ">
                    <input disabled type="checkbox" v-model="tr_vdowninfo" />&nbsp;&nbsp;Vessel Down Info
                  </div>
                  <div v-if="user_allowed.allowed['is super admin'] &&
                    (thuraya_dataFetch || sailink_dataFetch)
                    " @click="setVDOWN">
                    <input type="checkbox" v-model="tr_vdowninfo" @change="triggerVDOWN" />&nbsp;&nbsp;Vessel Down Info
                  </div>
                  <div v-if="user_allowed.allowed['is super admin']" @click="setLINFO">
                    <input type="checkbox" v-model="tr_linfo" @change="triggerLINFO" />&nbsp;&nbsp;Legend Info
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="user_allowed.role_id == 2 || user_allowed.role_id == 5"
                class="elevation-0 rounded-0 pa-0">
                <v-expansion-panel-header class="elevation-0 rounded-0 pa-1 ma-1" style="height: 2em">SatNet
                </v-expansion-panel-header>
                <v-expansion-panel-content class="elevation-0 rounded-0 pa-0" width="20" style="text-align: left">
                  <div @click="setEIRP_DT">
                    <input type="checkbox" v-model="EIRP" @change="triggerEIRP" />&nbsp;EIRP
                  </div>
                  <div @click="setGNT_DT">
                    <input type="checkbox" v-model="GNT" @change="triggerGNT" />&nbsp;G/T+
                  </div>
                  <div class="divider"></div>
                  <div @click="
                    !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                    SKYR1 ? setSKYR1(false) : setSKYR1(true)
                    ">
                    <input type="checkbox" v-model="SKYR1" @change="triggerSKYR1" />&nbsp;Skyreach 1
                  </div>
                  <div @click="
                    !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                    SKYR2 ? setSKYR2(false) : setSKYR2(true)
                    ">
                    <input type="checkbox" v-model="SKYR2" @change="triggerSKYR2" />&nbsp;Skyreach 2
                  </div>
                  <div @click="
                    !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                    APST9 ? setAPST9(false) : setAPST9(true)
                    ">
                    <input type="checkbox" v-model="APST9" @change="triggerAPST9" />&nbsp;Apstar 9
                  </div>
                  <div @click="
                    !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                    ABS2A ? setABS2A(false) : setABS2A(true)
                    ">
                    <input type="checkbox" v-model="ABS2A" value="ABS2A" @change="triggerABS2A" />&nbsp;ABS 2A
                  </div>
                  <div @click="
                    !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                    SR1MR ? setSR1MR(false) : setSR1MR(true)
                    ">
                    <input type="checkbox" v-model="SR1MR" value="SR1MR" @change="triggerSR1MR" />&nbsp;SR1 Maritime
                  </div>
                  <div @click="
                    !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                    SR2MR ? setSR2MR(false) : setSR2MR(true)
                    ">
                    <input type="checkbox" v-model="SR2MR" value="SR2MR" @change="triggerSR2MR" />&nbsp;SR2 HTS BEAM#4
                  </div>
                  <div @click="
                    !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                    BEAM3 ? setBEAM3(false) : setBEAM3(true)
                    ">
                    <input type="checkbox" v-model="BEAM3" value="BEAM3" @change="triggerBEAM3" />&nbsp;SR2 HTS BEAM#3
                  </div>
                  <div @click="
                    !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                    HRZ ? setHRZ(false) : setHRZ(true)
                    ">
                    <input type="checkbox" v-model="HRZ" value="HRZ" @change="triggerHRZ" />&nbsp;HORIZON 3E
                  </div>
                  <div @click="
                    !EIRP && GNT ? setEIRP(false) : setEIRP(true),
                    UTL70B ? setUTL70B(false) : setUTL70B(true)
                    ">
                    <input type="checkbox" v-model="UTL70B" value="UTL70B" @change="triggerUTL70B" />&nbsp;Utelsat 70B
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="tr_linfo" class="elevation-0 rounded-0 pa-0">
                <v-expansion-panel-header class="elevation-0 rounded-0 pa-1 ma-1" style="height: 2em">Legend
                </v-expansion-panel-header>
                <v-expansion-panel-content class="elevation-0 rounded-0 pa-0" width="20" style="text-align: left">
                  <div class="legend_iner">
                    <ul class="list_legend">
                      <li class="listlist_legend">
                        <img :src="upimg" class="vs_image" />&nbsp;SAILINK UP
                      </li>
                      <li class="listlist_legend">
                        <img :src="downimg" class="vs_image" />&nbsp;SAILINK
                        DOWN
                      </li>
                      <li class="listlist_legend">
                        <img :src="offimg" class="vs_image" />&nbsp;SAILINK
                        Off-Hire
                      </li>
                      <li class="listlist_legend">
                        <img :src="suspendimg" class="vs_image" />&nbsp;SAILINK
                        Suspended
                      </li>
                      <li class="listlist_legend">
                        <img :src="thurupimg" class="vs_image" />&nbsp;Thuraya
                        UP
                      </li>
                      <li class="listlist_legend">
                        <img :src="thurinactiveimg" class="vs_image" />&nbsp;Thuraya INACTIVE
                      </li>
                    </ul>
                  </div>
                </v-expansion-panel-content>
              </v-expansion-panel>
              <v-expansion-panel v-if="tr_bmkg" class="elevation-0 rounded-0 pa-0">
                <v-expansion-panel-header class="elevation-0 rounded-0 pa-1 ma-1" style="height: 2em">Wave
                  Category
                </v-expansion-panel-header>
                <v-expansion-panel-content class="elevation-0 rounded-0 pa-0" width="20" style="text-align: center">
                  <v-card class="pa-0 rounded-0" elevation="0" width="160">
                    <v-card-text class="pa-2" style="text-align: left; font-size: 0.9em">
                      <div>
                        <span style="color: #718beb">&#9724;</span>&nbsp;&nbsp;Calm/Smooth 0 - 0.5 m
                      </div>
                      <div>
                        <span style="color: #79d057">&#9724;</span>&nbsp;&nbsp;Slight 0.5 - 1.25 m
                      </div>
                      <div>
                        <span style="color: #6a808c">&#9724;</span>&nbsp;&nbsp;Moderate 1.25 - 2.5 m
                      </div>
                      <div>
                        <span style="color: #e37833">&#9724;</span>&nbsp;&nbsp;Rough 2.5 - 4 m
                      </div>
                      <div>
                        <span style="color: #b1303b">&#9724;</span>&nbsp;&nbsp;Very Rough 4 - 6 m
                      </div>
                      <div>
                        <span style="color: #cf3bc9">&#9724;</span>&nbsp;&nbsp;High 6 - 9 m
                      </div>
                      <div>
                        <span style="color: #000000">&#9724;</span>&nbsp;&nbsp;Very High 9 - 14 m
                      </div>
                    </v-card-text>
                    <v-card-actions style="display: block; background-color: #f7f7f7">
                      <p id="opacityLabel" class="text-center" for="opacityControl" style="font-size: 12px">
                        Opacity (<span id="opacityPercentage">{{ slider }}</span>%)
                      </p>
                      <v-slider style="margin-top: -2em" v-model="slider" hint="Im a hint" max="100" min="0"></v-slider>
                      <div style="margin-top: -4em; font-size: 0.9em">
                        Source by
                        <a target="_blank" href="https://www.bmkg.go.id/">BMKG</a>
                      </div>
                    </v-card-actions>
                  </v-card>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
        </l-control>
        <l-control v-if="tr_bmkg" class="bmkgMobile" position="bottomleft" style="display: flex">
          <div class="bmkgMobile">
            <v-card max-width="84vw" elevation="0" class="rounded-0 pa-0">
              <v-toolbar class="elevation-0 rounded-0 pa-0" color="#F7F7F7" dense height="35"
                style="border-bottom: 1px solid #e0e0e0">
                <v-spacer></v-spacer>
                <v-toolbar-title style="font-size: 1.2em">BMKG
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-title primary-title class="justify-center pa-0">
                <div>
                  <v-chip-group style="margin-left: 0.2em" v-model="selectChip" active-class="primary"
                    class="pa-1 text-center" column>
                    <v-chip class="rounded-0 pa-2" label small>TODAY</v-chip>
                    <v-chip class="rounded-0 pa-2" label small>TOMMOROW</v-chip>
                    <v-chip class="rounded-0 pa-2" label small>{{
                      d2.toDateString().toUpperCase()
                    }}
                    </v-chip>
                    <v-chip class="rounded-0 pa-2" label small>{{
                      d3.toDateString().toUpperCase()
                    }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </v-card-title>
            </v-card>
          </div>
        </l-control>
        <l-control v-if="tr_bmkg" class="bmkgControls bmkgDesktop" position="bottomleft" style="display: flex">
          <div class="bmkgDesktop">
            <v-card :class="drawers
              ? 'bmkgWaveDrawer pa-0 rounded-0'
              : 'bmkgWaveCat pa-0 rounded-0'
              " elevation="0" min-width="160" :color="isDarks ? '#3C4048' : '#ffff'">
              <v-toolbar class="elevation-0" :color="isDarks ? '#3C4048' : '#f7f7f7'"
                style="border-bottom: 1px solid #e0e0e0" dense height="35">
                <v-spacer></v-spacer>
                <v-toolbar-title :style="isDarks
                  ? 'font-size: 1.2em;color:#ffff'
                  : 'font-size: 1.2em;color:black'
                  ">Wave Category
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-text class="pa-2" :style="isDarks
                ? 'text-align: left; font-size: 0.9em;color:#ffff'
                : 'text-align: left; font-size: 0.9em;color:black'
                ">
                <div>
                  <span style="color: #718beb">&#9724;</span>&nbsp;&nbsp;Calm/Smooth 0 - 0.5 m
                </div>
                <div>
                  <span style="color: #79d057">&#9724;</span>&nbsp;&nbsp;Slight
                  0.5 - 1.25 m
                </div>
                <div>
                  <span style="color: #6a808c">&#9724;</span>&nbsp;&nbsp;Moderate 1.25 - 2.5 m
                </div>
                <div>
                  <span style="color: #e37833">&#9724;</span>&nbsp;&nbsp;Rough
                  2.5 - 4 m
                </div>
                <div>
                  <span style="color: #b1303b">&#9724;</span>&nbsp;&nbsp;Very
                  Rough 4 - 6 m
                </div>
                <div>
                  <span style="color: #cf3bc9">&#9724;</span>&nbsp;&nbsp;High 6
                  - 9 m
                </div>
                <div>
                  <span style="color: #000000">&#9724;</span>&nbsp;&nbsp;Very
                  High 9 - 14 m
                </div>
              </v-card-text>
              <v-card-actions style="display: block; background-color: #f7f7f7">
                <p id="opacityLabel" class="text-center" for="opacityControl" style="font-size: 12px">
                  Opacity (<span id="opacityPercentage">{{ slider }}</span>%)
                </p>
                <v-slider style="margin-top: -2em" v-model="slider" hint="Im a hint" max="100" min="0"></v-slider>
                <div style="margin-top: -4em; font-size: 0.9em">
                  Source by
                  <a target="_blank" href="https://www.bmkg.go.id/">BMKG</a>
                </div>
              </v-card-actions>
            </v-card>
          </div>
          <v-div :class="drawers
            ? 'bmkgChangeDrawers bmkgDesktop'
            : 'bmkgChangeDate bmkgDesktop'
            ">
            <v-card :color="isDarks ? '#3C4048' : '#ffff'" min-width="525" elevation="0" height="80"
              class="rounded-0 pa-0">
              <v-toolbar class="elevation-0 rounded-0 pa-0" :color="isDarks ? '#3C4048' : '#F7F7F7'" dense height="35"
                style="border-bottom: 1px solid #e0e0e0">
                <v-spacer></v-spacer>
                <v-toolbar-title :style="isDarks
                  ? 'font-size: 1.2em;color:#ffff'
                  : 'font-size: 1.2em;color:black'
                  ">BMKG
                </v-toolbar-title>
                <v-spacer></v-spacer>
              </v-toolbar>
              <v-card-title primary-title class="justify-center pa-0">
                <div>
                  <v-chip-group style="margin-left: 0.2em" v-model="selectChip" active-class="primary"
                    class="pa-1 text-center" column>
                    <v-chip class="rounded-0 pa-2" label small>TODAY</v-chip>
                    <v-chip class="rounded-0 pa-2" label small>TOMMOROW</v-chip>
                    <v-chip class="rounded-0 pa-2" label small>{{
                      d2.toDateString().toUpperCase()
                    }}
                    </v-chip>
                    <v-chip class="rounded-0 pa-2" label small>{{
                      d3.toDateString().toUpperCase()
                    }}
                    </v-chip>
                  </v-chip-group>
                </div>
              </v-card-title>
            </v-card>
          </v-div>
        </l-control>

        <l-geo-json v-if="tr_bmkg" ref="myGeoJson" :geojson="geojson" :options="optionsBmkg"
          :options-style="{ fillOpacity: slider / 100, opacity: 0 }" />
      </l-map>
    </v-container>
  </v-app>
</template>

<script>
import $ from "jquery";
import { mapGetters, mapActions } from "vuex";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import LMovingRotatedMarker from 'vue2-leaflet-rotatedmarker';
import L from "leaflet";
import {
  LMap,
  LTileLayer,
  LIcon,
  LControlZoom,
  LControl,
  LPolygon,
  LPopup,
  LGeoJson,
  LCircle
} from "vue2-leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import LControlPolylineMeasure from "vue2-leaflet-polyline-measure";
import {
  cns11,
  cns11gain,
  cns11_gnt,
  cns11_gntgain,
} from "@/assets/js/cns11.js";
import {
  apstar5,
  apstar5gain,
  apstar5_gnt,
  apstar5_gntgain,
} from "@/assets/js/apstar5.js";
import {
  apstar92,
  apstar92gain,
  apstar92_gnt,
  apstar92_gntgain,
} from "@/assets/js/apstar9.js";

import {
  hts,
  htsgain,
  hts_gnt,
  hts_gntgain,
} from "@/assets/js/hts.js";

import { abs, absgain, abs_gnt, abs_gntgain } from "@/assets/js/abs.js";
import { ocn, ocngain, ocn_gnt, ocn_gntgain } from "@/assets/js/oceanbeam.js";
import { beam3, beam3gain, beam3_gnt, beam3_gntgain } from "@/assets/js/beam3.js";
import { horizon, horizongain, horizon_gnt, horizon_gntgain } from "@/assets/js/horizon.js";
import { utelsat, utelsatgain, utelsat_gnt, utelsat_gntgain } from "@/assets/js/utelsat.js";

const iconUrl = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDIuODggMy40NiI+PGRlZnM+PHN0eWxlPi5ke2ZpbGw6IzAwMDBGRjtzdHJva2U6I2ZmZjtzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6LjJweDt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9ImEiLz48ZyBpZD0iYiI+PGcgaWQ9ImMiPjxwYXRoIGNsYXNzPSJkIiBkPSJNMi42OSwzLjI2bC0uOTYtLjQ1Yy0uMTktLjA5LS40LS4wOS0uNTksMGwtLjk2LC40NUwxLjQ0LC4yNmwxLjI1LDNaIi8+PC9nPjwvZz48L3N2Zz4='

export default {
  components: {
    LMap,
    LTileLayer,
    LIcon,
    LControlZoom,
    LControl,
    LPolygon,
    LPopup,
    LGeoJson,
    LControlPolylineMeasure,
    LControlFullscreen,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
    LMovingRotatedMarker,
    LCircle
  },
  data() {
    return {
      tipemark: "",
      timeouttrack: null,
      firstpolyline: null,
      IntervalMoving: null,
      IntervalTracking: null,
      countLength: 0,
      group1: L.featureGroup(),
      circle1: L.circleMarker(),
      drivePath: null,
      driveLatLng: null,
      driveRotationAngle: null,
      driveMarker: null,
      iconmov: L.icon({
        iconUrl: iconUrl,
        iconSize: [17, 22],
        iconAnchor: [8.5, 11.5],
        popupAnchor: [0, -25]
      }),
      itemPageData: [
        { title: "4", number: 4 },
        { title: "5", number: 5 },
        { title: "10", number: 10 },
      ],
      fetchLivePromise: null,
      satnetclick: false,
      mapclick: false,
      itemsPerPage: 4,
      pingClicked: null,
      page: 1,
      pageCount: 0,
      itemsPerPageToff: 4,
      pageToff: 1,
      pageCountToff: 0,
      itemsPerPageSailink: 4,
      searchToff: "",
      pageSailink: 1,
      pageCountSailink: 0,
      searchSailink: "",
      hoverMarker: null,
      iconsize: [40 / 4, 100 / 4],
      iconanchor: [40 / 4 / 2, 100 / 4 / 2],
      weatherCode: {
        Cerah: "Clear",
        "Cerah Berawan": "Mostly Clear",
        Berawan: "Partly Cloudy",
        "Berawan Tebal": "Mostly Cloudy",
        Asap: "Smoke",
        Kabut: "Fog",
        "Hujan Ringan": "Light Rain",
        "Hujan Sedang": "Moderate Rain",
        "Hujan Lebat": "Heavy Rain",
        "Hujan Lokal": "Local Rain",
        "Hujan Badai": "Rain Storm",
        "No Data": "No Data",
      },

      windCode: {
        Utara: "N",
        "Utara Timur Laut": "NNE",
        "Timur Laut": "NE",
        "Timur Timur Laut": "ENE",
        Timur: "E",
        "Timur Tenggara": "ESE",
        Tenggara: "SE",
        "Selatan Tenggara": "SSE",
        Selatan: "S",
        "Selatan Barat Daya": "SSW",
        "Barat Daya": "SW",
        "Barat Barat daya": "WSW",
        Barat: "W",
        "Barat Barat Laut": "WNW",
        "Barat Laut": "NW",
        "Utara Barat Laut": "NNW",
      },

      waveCode: {
        Tenang: "Calm/Smooth",
        Rendah: "Slight",
        Sedang: "Moderate",
        Tinggi: "Rough",
        "Sangat Tinggi": "Very rough",
        Ekstrem: "High",
        "Sangat Ekstrem": "Very high",
      },
      d2: null,
      d3: null,
      min: 0.0,
      max: 1,
      slider: 50,
      geojson: null,
      on: false,
      iconTotal: require("@/assets/img/totalKapal.svg"),
      satellite: require("@/assets/img/satellite.png"),
      blinkInterval: null,
      upimg: require("@/assets/img/up.png"),
      downimg: require("@/assets/img/down.png"),
      offimg: require("@/assets/img/off-hire.png"),
      suspendimg: require("@/assets/img/suspend.png"),
      thurupimg: require("@/assets/img/thurup.png"),
      thurinactiveimg: require("@/assets/img/thurinactive.png"),
      alertimg_red: require("@/assets/img/alert_red.png"),
      alertimg: require("@/assets/img/alert.png"),
      targetimage: L.icon({
        iconUrl: "./target-svgrepo.svg",
        iconSize: [320 / 5, 320 / 5],
        iconAnchor: [320 / 5 / 2, 320 / 5 / 2],
      }),
      targetimage_dark: L.icon({
        iconUrl: "./target-svgrepo_dark.svg",
        iconSize: [320 / 5, 320 / 5],
        iconAnchor: [320 / 5 / 2, 320 / 5 / 2],
      }),
      centerimage: L.icon({
        iconUrl: "./marker-vessel.svg",
        iconSize: [40 / 4, 100 / 4],
        iconAnchor: [40 / 4 / 2, 100 / 4 / 2],
      }),
      markerimage: L.icon({
        id: "try",
        iconUrl: "./animate_circle.gif",
        iconSize: [256 / 3, 256 / 3],
        iconAnchor: [256 / 3 / 2, 256 / 3 / 2],
        className: "opacityMarker",
      }),
      markerimage_dark: L.icon({
        id: "try",
        iconUrl: "./animate_circle_dark.gif",
        iconSize: [256 / 3, 256 / 3],
        iconAnchor: [256 / 3 / 2, 256 / 3 / 2],
        className: "opacityMarker",
      }),
      loaderImage: L.icon({
        id: "try",
        iconUrl: "./load100new.gif",
        iconSize: [256 / 3, 256 / 3],
        iconAnchor: [256 / 3 / 2, 256 / 3 / 2],
      }),
      markerPNG: require("@/assets/img/marker-vessel.svg"),
      interval: null,
      interval2: null,
      loadernavplus: require("@/assets/img/load100new.gif"),
      thuraya_white: require("@/assets/img/thuraya_white.png"),
      sailink_white: require("@/assets/img/sailink_white.png"),
      alertyellow: require("@/assets/img/alert.png"),
      alertred: require("@/assets/img/alert_red.png"),
      // satellite: require("@/assets/img/satellite.png"),
      showSatNet: false,
      showMapAdd: false,
      expand: false,
      vsatdownshow: false,
      toffshow: false,
      status_marker: "",
      unit: "nauticalmiles",
      fillColor: "#e4ce7f",
      search: "",
      headers: [
        {
          text: "",
          value: "id",
          width: "1%",
        },
        { text: "", value: "name", align: "start", width: "1%" },
      ],
      items: [
        { text: "My Files", icon: "mdi-folder" },
        { text: "Shared with me", icon: "mdi-account-multiple" },
        { text: "Starred", icon: "mdi-star" },
        { text: "Recent", icon: "mdi-history" },
        { text: "Offline", icon: "mdi-check-circle" },
        { text: "Uploads", icon: "mdi-upload" },
        { text: "Backups", icon: "mdi-cloud-upload" },
      ],
      satnet: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" },
      ],
      customText: "Foobar",
      url: "https://maps.skyreach.id/sailink/{z}/{x}/{y}.png",
      url_dark:
        "https://tiles.stadiamaps.com/tiles/alidade_smooth_dark/{z}/{x}/{y}{r}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      center: [0.696317155834329, 117.81332979281757],
      markerLatLng: [-3.5547103954268433, 109.08356479517639],
      staticAnchor: [16, 37],
      iconSize: 64,
      iconColor: "#FFC700",
      polygon: {
        skyr1: "green",
        skyr2: "blue",
        apst9: "purple",
        abs2a: "orange",
        sr1mr: "#F7038B",
        beam3: "#DB6B0F",
        hrz: "#DBC70f",
        utl: "#0CAD93"
      },
      paths_cns11: cns11,
      gain_cns11: cns11gain,
      paths_cns11gnt: cns11_gnt,
      gain_cns11gnt: cns11_gntgain,
      paths_apstar5: apstar5,
      gain_apstar5: apstar5gain,
      paths_apstar5gnt: apstar5_gnt,
      gain_apstar5gnt: apstar5_gntgain,
      paths_apstar9: apstar92,
      gain_apstar9: apstar92gain,
      paths_apstar9gnt: apstar92_gnt,
      gain_apstar9gnt: apstar92_gntgain,
      paths_abs: abs,
      gain_abs: absgain,
      paths_absgnt: abs_gnt,
      gain_absgnt: abs_gntgain,
      paths_ocn: ocn,
      gain_ocn: ocngain,
      paths_ocngnt: ocn_gnt,
      gain_ocngnt: ocn_gntgain,
      paths_hts: hts,
      gain_hts: htsgain,
      paths_htsgnt: hts_gnt,
      gain_htsgnt: hts_gntgain,
      paths_beam3: beam3,
      gain_beam3: beam3gain,
      paths_beam3gnt: beam3_gnt,
      gain_beam3gnt: beam3_gntgain,
      paths_hrz: horizon,
      gain_hrz: horizongain,
      paths_hrzgnt: horizon_gnt,
      gain_hrzgnt: horizon_gntgain,
      paths_utl: utelsat,
      gain_utl: utelsatgain,
      paths_utlgnt: utelsat_gnt,
      gain_utlgnt: utelsat_gntgain,
      selectChip: 0,
    };
  },

  watch: {
    // whenever question changes, this function will run
    // trackline(newQuestion, oldQuestion) {
    //   this.draw_Circle()

    // },

    // trackline_thur(newQuestion, oldQuestion) {
    //   this.draw_Circle_thur()
    // }
  },

  async mounted() {
    this.fetchUSERGET(localStorage.getItem("token"));
    this.updateSnapStatus_(localStorage.getItem("token"));

    // if (userallowed_.allowed["show legend"] || this.tr_linfo) {
    //   this.setlinfo(true);
    //console.log(userallowed_.allowed["show legend"]);
    //console.log("LEGEND SHOW");
    // }

    //console.log(userallowed_.allowed["show marker label"]);
    //console.log("LABEL SHOW");

    //console.log("USER DATA DI NAVPLUS");
    //console.log(this.Datauser);

    window.eirpCheck = function (eirp) {
      if (eirp.checked) {
        this.EIRP = "EIRP";
        document.getElementById("test").checked = true;
      }
    };

    // var vm = this;
    // this.$refs.myMap.mapObject.on("click", function (e) {
    //   console.log("BISA NJIR");
    //   vm.targetActive = false;
    // });

    this.fetchJsonWave();

    if (!this.sailinkLocation || !this.thurayaLocation) {
      this.fetchVessel(localStorage.getItem("token"));
    }

    // this.updateVessel(localStorage.getItem("token"));
    // this.updateLocation(localStorage.getItem("token"));
    // this.updateLocationThuraya(localStorage.getItem("token"));
    const response = await fetch(
      "https://peta-maritim.bmkg.go.id/public_api/static/wilayah_perairan.json"
    );
    const data = await response.json();
    this.geojson = data;
    const today = new Date();
    this.d2 = new Date(today);
    this.d3 = new Date(today);
    this.d2.setDate(this.d2.getDate() + 2);
    this.d3.setDate(this.d3.getDate() + 3);

    //console.log('ZOOMAP_')
    //console.log(this.zoomMap_)

    // if(this.pointerlatlng){
    //console.log('MASUK POINTER')
    // this.centerMaplatlng_(this.pointerlatlng,this.typeMarker)

    // }
  },

  methods: {
    ...mapActions([
      "updateSnapStatus_",
      "removetrackline_thur",
      "showdateThuraya",
      "removetrackline",
      "showtrackline",
      "showtrackline_thur",
      "fetchUSERGET",
      "removeShowThuraya",
      "addcenterMapPeta_",
      "addzoomMap_",
      "addthuraya_executed",
      "addsailink_executed",
      "set_setkondisi_Marker",
      "setPremis",
      "setSR1MR",
      "setSR2MR",
      "setABS2A",
      "setAPST9",
      "setSKYR2",
      "setSKYR1",
      "setBEAM3",
      "setHRZ",
      "setUTL70B",
      "setEIRP",
      "setGNT",
      "setflabel",
      "setbmkgtr",
      "setlinfo",
      "setfalert",
      "setcenterMap",
      "setdrawerdata",
      "settargetactive",
      "setdrawers",
      "setvdowninfo",
      "setdays",
      "fetchJsonWave",
      "fetchVessel",
      "updateVessel",
      "updateLocation",
      "addblinking",
      "fetchSatNet",
      "fetchWeather",
      "searchVesselInput",
      "searchVesselID_Input",
      "searchIDThuraya_Input",
      "searchThurayaInput",
      "trylah",
      "eirpOn",
      "gntOn",
      "addSkyreach1Con",
      "addSkyreach2Con",
      "addApstar9Con",
      "addAbs2Con",
      "addOceanBeamCon",
      "sliderBMKG",
      "setpointerlatlng",
      "settypeMarker",
      "updateLocationThuraya",
      "setsetloading_map",
      "removeSatNet",
      "removeWeather",
      "removeShowMap",
    ]),

    readyCenterAll() {
      if (!this.sailinkLocation) {
        this.$refs.MapFirst.mapObject.setView(
          [4.937306747322523, 118.48291601505498],
          5
        );
      }
    },
    async fetchDataMap_() {
      this.setsetloading_map(null);
      // console.log(this.user_allowed)
      if (
        this.user_allowed.allowed["show marker alert"] ||
        !this.user_allowed.allowed["is super admin"]
      ) {
        // console.log("show lah");

        this.setfalert(true);
        this.onAlert();
      } else {
        // console.log("hide lah");
        this.setfalert(false);
        this.offAlert();
      }

      if (this.user_allowed.allowed["show marker label"] || this.tr_flabel) {
        this.setflabel(true);
      } else {
        this.setflabel(false);
      }

      if (this.user_allowed.allowed["show legend"] || this.tr_linfo) {
        this.setlinfo(true);
      } else {
        this.setlinfo(false);
      }
    },

    removeTrackingLine() {
      var myMap = this.$refs.myMap.mapObject;
      this.driveMarker = null
      this.drivePath = null
      this.driveLatLng = null,
        clearTimeout(this.timeouttrack);
      myMap.removeLayer(this.group1)
    },

    markerClicked(marker, type) {
      this.centerMap_(marker, type);
      this.setdrawers(true),
        this.settargetactive(true),
        console.log('MARKER DI CLICK')
      if (type == "sailink") {
        // this.removetrackline_thur()
        // this.removeTrackingLine();
        this.drawer_sailink(marker);
        // this.fetchTrackSail(marker);
        this.tipemark = type;
      } else {
        // this.removetrackline();
        // this.removeTrackingLine();
        this.drawer_thuraya(marker);
        // this.fetchTrackThur(marker);
        this.tipemark = type;
      }
      this.setpointerlatlng(marker);
      this.settypeMarker(type);
    },

    fetchTrackSail(marker) {
      console.log(marker)
      clearInterval(this.IntervalMoving)
      const data = {
        id: marker.id,
        range: '24',
        token: localStorage.getItem("token"),
      };

      this.showtrackline(data);
      var mama = this;
      this.timeouttrack = setTimeout(() => {
        mama.fetchTrackSail(marker)
      }, 90000);
    },


    fetchTrackThur(marker) {
      console.log(marker)
      clearInterval(this.IntervalMoving)
      const data = {
        id: marker.id,
        range: '24',
        token: localStorage.getItem("token"),
      };

      this.showtrackline_thur(data);
      var mama = this;
      this.timeouttrack = setTimeout(() => {
        mama.fetchTrackThur(marker)
      }, 90000);

    },

    initialdata_moving(type) {
      console.log('masuk ke initial data moving')
      var mother = this
      console.log("========== INI ADALAH TYPE =========")
      console.log(type)
      if (type == 'sailink') {
        mother.drivePath = mother.trackline.tracking.slice()
        mother.driveLatLng = L.latLng([mother.drivePath[0].lat, mother.drivePath[0].lng])
        mother.driveRotationAngle = mother.drivePath[0].heading
      } else {
        console.log('MASUK KE INIT DATA THURAYA')
        mother.drivePath = mother.trackline_thur.tracking.slice()
        mother.driveLatLng = L.latLng([mother.drivePath[0].lat, mother.drivePath[0].lng])
        mother.driveRotationAngle = mother.drivePath[0].heading
      }

    },

    newAnimation(type) {
      this.drivePath = null;
      console.log('masuk ke new animation')
      this.initialdata_moving(type)
      clearInterval(this.IntervalMoving);

    },


    //moving marker
    movingStart() {
      var mother = this
      this.IntervalMoving = setInterval(function () {
        mother.simulate()
      }, 0.2);

    },


    //moving marker
    simulate() {
      this.countLength = this.countLength + 1
      console.log(this.countLength)
      console.log(this.drivePath)


      if (!this.drivePath.length) {
        this.$refs.driveMarker.mapObject.setLatLng(L.latLng([this.drivePath[0].lat, this.drivePath[0].lng]))
        this.drivePath = this.drivePath.slice()
        return
      }
      let point = this.drivePath.shift()
      this.driveLatLng = L.latLng([point.lat, point.lng])
      this.driveRotationAngle = point.heading
      console.log(this.driveRotationAngle)

      var mother = this
      if (this.drivePath.length == 0 || !this.drivePath) {
        clearInterval(this.IntervalMoving);
        // this.drivePath=null;
        this.newAnimation(this.tipemark)
        setTimeout(
          function () {
            mother.movingStart()
          }, 1000);
      }

    },

    if_trackline() {
      // if (this.trackline) {
      //   this.draw_Circle();
      // }

      // if (this.trackline_thur) {
      //   this.draw_Circle_thur();
      // }
    },

    draw_Circle() {
      console.log('====== ULANG TERUS DRAW CIRCLE =======================')
      var myMap = this.$refs.myMap.mapObject;
      var latLong = this.trackline.tracking;
      var pointList = this.trackline.tracking;


      //       var circle = L.circleMarker(this.returnLatLong(this.pointerlatlng, this.typeMarker), {radius: 0}).addTo(myMap);


      // var newRadius = 40;
      // var interval = setInterval(function() {
      //    var currentRadius = circle.getRadius();
      //    console.debug("currentRadius", currentRadius)
      //    if (currentRadius < newRadius) {
      //        circle.setRadius(++currentRadius);
      //        console.debug("new ", circle.getRadius())
      //    } else {
      //       //  clearTimeout(interval);
      //        circle.setRadius(0);
      //    }
      // }, 10);

      var mama = this;

      this.group1.clearLayers()

      // if(myMap.hasLayer(this.firstpolyline)){
      //  myMap.removeLayer(this.firstpolyline)
      // }


      this.firstpolyline = new L.Polyline(pointList, {
        color: "#FFFF",
        weight: 3,
        opacity: 1,
        smoothFactor: 1,
      });
      this.firstpolyline.addTo(mama.group1);
      // Here's where you iterate over the array of coordinate objects.
      console.log("INI ADALAH LATLONG LATLONG")

      // console.log(latLong.lng[latLong.length-1])
      var abc = 0;
      latLong.forEach(function (coord) {
        abc++
        if (
          coord.lat != latLong[latLong.length - 1].lat &&
          coord.lng != latLong[latLong.length - 1].lng
        ) {
          var circle = L.circle(coord, {
            color: "#343A40",
            fillColor: "#343A40",
            fillOpacity: 0.3,
            opacity: 0.6,
            weight: 2,
            radius: 1100,
          }).addTo(mama.group1)
            .bindPopup(
              "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
              coord.lat +
              "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
              coord.lng +
              "</td></tr><tr>" +
              "<td><b>DMS</b></td><td>:</td>" +
              "<td>" +
              coord.dms +
              "</td>" +
              "</tr>" +
              "<tr>" +
              "<td><b>Speed</b></td>" +
              "<td>:</td>" +
              " <td>" +
              coord.speed +
              "&nbsp;knots </td>" +
              "</tr>" +
              "<tr>" +
              " <td><b>Heading</b></td>" +
              "<td>:</td>" +
              "<td>" +
              coord.heading +
              "</td>" +
              " </tr>" +
              "<tr>" +
              "<td><b>Last Update</b></td>" +
              "<td>:</td>" +
              "<td>" +
              coord.updated +
              "</td>" +
              "</tr>" +
              "</table>"
            );
        }
      });

      myMap.addLayer(this.group1);


      var mom = this
      this.newAnimation('sailink')
      setTimeout(
        function () {
          mom.movingStart()
        }, 1000);


      // this.IntervalTracking = setInterval(function () {
      //     mom.draw_Circle()
      //       }, 300000);


    },


    draw_Circle_thur() {
      var myMap = this.$refs.myMap.mapObject;
      var latLong = this.trackline_thur.tracking;
      var pointList = this.trackline_thur.tracking;

      var mama = this;

      this.group1.clearLayers()

      //if(myMap.hasLayer(this.firstpolyline)){
      //  myMap.removeLayer(this.firstpolyline)
      //}


      this.firstpolyline = new L.Polyline(pointList, {
        color: "#FFFF",
        weight: 3,
        opacity: 1,
        smoothFactor: 1,
      });
      this.firstpolyline.addTo(mama.group1);
      // Here's where you iterate over the array of coordinate objects.
      var abc = 0;
      latLong.forEach(function (coord) {
        abc++
        if (
          coord.lat != latLong[latLong.length - 1].lat &&
          coord.lng != latLong[latLong.length - 1].lng
        ) {
          var circle = L.circle(coord, {
            color: "#343A40",
            fillColor: "#343A40",
            fillOpacity: 0.3,
            opacity: 0.6,
            weight: 2,
            radius: 1100,
          }).addTo(mama.group1)
            .bindPopup(
              "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
              coord.lat +
              "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
              coord.lng +
              "</td></tr><tr>" +
              "<td><b>DMS</b></td><td>:</td>" +
              "<td>" +
              coord.dms +
              "</td>" +
              "</tr>" +
              "<tr>" +
              "<td><b>Speed</b></td>" +
              "<td>:</td>" +
              " <td>" +
              coord.speed +
              "&nbsp;knots </td>" +
              "</tr>" +
              "<tr>" +
              " <td><b>Heading</b></td>" +
              "<td>:</td>" +
              "<td>" +
              coord.heading +
              "</td>" +
              " </tr>" +
              "<tr>" +
              "<td><b>Last Update</b></td>" +
              "<td>:</td>" +
              "<td>" +
              coord.updated +
              "</td>" +
              "</tr>" +
              "</table>"
            );
        }
      });

      myMap.addLayer(this.group1);


      var mom = this
      this.newAnimation('thuraya')
      setTimeout(
        function () {
          mom.movingStart()
        }, 1000);


    },

    zoomUpdated(zoom) {
      //console.log('ZOOM EVENT BERJALAN');
      this.addzoomMap_(zoom);
    },

    centerUpdated(center) {

      this.addcenterMapPeta_(center);
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
    triggerVDOWN(event) {
      if (event.target.checked == true) {
        this.setvdowninfo(true);
      } else {
        this.setvdowninfo(false);
      }
    },

    setVDOWN() {
      if (this.tr_vdowninfo == true) {
        this.setvdowninfo(false);
      } else {
        this.setvdowninfo(true);
      }
    },

    triggerLINFO(event) {
      if (event.target.checked == true) {
        this.setlinfo(true);
        this.setPremis("show legend");
      } else {
        this.setlinfo(false);
        this.setPremis("show legend");
      }
    },

    setLINFO() {
      if (this.tr_linfo == true) {
        this.setlinfo(false);
        this.setPremis("show legend");
      } else {
        this.setlinfo(true);
        this.setPremis("show legend");
      }
    },

    triggerBMKG(event) {
      if (event.target.checked == true) {
        this.setbmkgtr(true);
      } else {
        this.setbmkgtr(false);
      }
    },

    setBMKG() {
      if (this.tr_bmkg == true) {
        this.setbmkgtr(false);
      } else {
        this.setbmkgtr(true);
      }
    },

    triggerFLABEL(event) {
      if (event.target.checked == true) {
        this.setflabel(true);
        this.setPremis("show marker label");
      } else {
        this.setflabel(false);
        this.setPremis("show marker label");
      }
    },

    setFLABEL() {
      if (this.tr_flabel == true) {
        this.setPremis("show marker label");
        this.setflabel(false);
      } else {
        this.setPremis("show marker label");
        this.setflabel(true);
      }
    },

    triggerFALERT(event) {
      if (event.target.checked == true) {
        this.setPremis("show marker alert");

        this.setfalert(true);
        this.onAlert();
      } else {
        this.setPremis("show marker alert");

        this.setfalert(false);
        this.offAlert();
      }
    },

    setFALERT() {
      if (this.tr_falert == true) {
        this.setPremis("show marker alert");
        this.setfalert(false);
        this.onAlert();
      } else {
        this.setPremis("show marker alert");
        this.setfalert(true);
        this.offAlert();
      }
    },

    triggerEIRP(event) {
      if (event.target.checked == true) {
        this.setEIRP(true);
      } else {
        this.setEIRP(false);
      }
    },

    setEIRP_DT() {
      if (this.EIRP == true) {
        this.setEIRP(false);
      } else {
        this.setEIRP(true);
      }
    },

    triggerGNT(event) {
      if (event.target.checked == true) {
        this.setGNT(true);
      } else {
        this.setGNT(false);
      }
    },

    setGNT_DT() {
      if (this.GNT == true) {
        this.setGNT(false);
      } else {
        this.setGNT(true);
      }
    },

    triggerSKYR1(event) {
      if (event.target.checked == true) {
        this.setSKYR1(true);
      } else {
        this.setSKYR1(false);
      }
    },

    setSKYR1_DT() {
      if (this.SKYR1 == true) {
        this.setSKYR1(false);
      } else {
        this.setSKYR1(true);
      }
    },

    triggerSKYR2(event) {
      if (event.target.checked == true) {
        this.setSKYR2(true);
      } else {
        this.setSKYR2(false);
      }
    },

    setSKYR2_DT() {
      if (this.SKYR2 == true) {
        this.setSKYR2(false);
      } else {
        this.setSKYR2(true);
      }
    },

    setAPST9_DT() {
      if (this.APST9 == true) {
        this.setAPST9(false);
      } else {
        this.setAPST9(true);
      }
    },

    triggerAPST9(event) {
      if (event.target.checked == true) {
        this.setAPST9(true);
      } else {
        this.setAPST9(false);
      }
    },

    setABS2A_DT() {
      if (this.ABS2A == true) {
        this.setABS2A(false);
      } else {
        this.setABS2A(true);
      }
    },

    triggerABS2A(event) {
      if (event.target.checked == true) {
        this.setABS2A(true);
      } else {
        this.setABS2A(false);
      }
    },

    setSR1MR_DT() {
      if (this.SR1MR == true) {
        this.setSR1MR(false);
      } else {
        this.setSR1MR(true);
      }
    },

    triggerSR1MR(event) {
      if (event.target.checked == true) {
        this.setSR1MR(true);
      } else {
        this.setSR1MR(false);
      }
    },


    triggerSR2MR(event) {
      if (event.target.checked == true) {
        this.setSR2MR(true);
      } else {
        this.setSR2MR(false);
      }
    },

    triggerBEAM3(event) {
      if (event.target.checked == true) {
        this.setBEAM3(true);
      } else {
        this.setBEAM3(false);
      }
    },

    triggerHRZ(event) {
      if (event.target.checked == true) {
        this.setHRZ(true);
      } else {
        this.setHRZ(false);
      }
    },

    triggerUTL70B(event) {
      if (event.target.checked == true) {
        this.setUTL70B(true);
      } else {
        this.setUTL70B(false);
      }
    },

    targetActiveFunc(event) {
      //console.log("click peta");
      clearInterval(this.IntervalMoving);

      this.removetrackline();
      this.removetrackline_thur();
      this.removeTrackingLine();

      this.settargetactive(false);
      this.setdrawers(false);
      this.setpointerlatlng(null);

    },

    svgMarker(marker, type) {
      var markerFill;
      if (type == "sailink") {
        if (!this.sailinkLocationUpdate) {
          markerFill = marker.fill;
        } else if (!this.sailinkLocationUpdate[marker.name.toUpperCase()]) {
          markerFill = marker.fill;
        } else if (this.sailinkLocationUpdate[marker.name.toUpperCase()]) {
          markerFill =
            this.sailinkLocationUpdate[marker.name.toUpperCase()].fill;
        } else {
          markerFill = marker.fill;
        }
      } else {
        markerFill = marker.fill;
      }
      const svgIcon = L.divIcon({
        html:
          `
  <svg version="1.1" id="a" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 40 100" style="enable-background:new 0 0 40 100;" xml:space="preserve">
<path stroke="#FFF"  stroke-width="10" fill="` +
          markerFill +
          `" class="st0" d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z"/>
</svg>`,
        className: "",
        iconSize: [40 / 5, 100 / 5],
        iconAnchor: [40 / 5 / 2, 100 / 5 / 2],
      });

      return svgIcon;
    },

    svgMarkerMov(marker, type) {
      var markerFill;
      if (type == "sailink") {
        if (!this.sailinkLocationUpdate) {
          markerFill = marker.fill;
        } else if (!this.sailinkLocationUpdate[marker.name.toUpperCase()]) {
          markerFill = marker.fill;
        } else if (this.sailinkLocationUpdate[marker.name.toUpperCase()]) {
          markerFill =
            this.sailinkLocationUpdate[marker.name.toUpperCase()].fill;
        } else {
          markerFill = marker.fill;
        }
      } else {
        markerFill = marker.fill;
      }
      const svgIcon = L.divIcon({
        html:
          `
  <svg version="1.1" id="a" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 122 147" style="enable-background:new 0 0 122 147;" xml:space="preserve">
<path stroke="#343A40"  stroke-width="22" fill="` +
          markerFill +
          `" class="st0" d="M61-0.1L-0.3,147.1l0,0c39-21.2,83.6-21.2,122.6,0l0,0L61-0.1z"/>
</svg>`,
        className: "",
        iconSize: [122 / 9, 147 / 9],
        iconAnchor: [122 / 9 / 2, 147 / 9 / 2],
      });

      return svgIcon;
    },

    dateDiffInDays(marker) {
      // console.log(marker)
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      const _MS_PER_HOUR = 1000 * 60 * 60;
      const _MS_PER_MINUTE = 1000 * 60;

      let mName = marker.name;
      let mmName = mName.toUpperCase();

      let refMarker = this.$refs["sailinkMarker" + mmName][0].$attrs.marker;
      var last_up = refMarker.last_up.split(" ", 2).join("T");

      var date1 = new Date(last_up);
      var date2 = new Date();

      var a = date1;
      var b = date2;
      //console.log(a);
      //console.log(b);

      // Discard the time and time-zone information.
      var utc1 = Date.UTC(
        a.getFullYear(),
        a.getMonth(),
        a.getDate(),
        a.getHours(),
        a.getMinutes(),
        a.getSeconds()
      );
      var utc2 = Date.UTC(
        b.getFullYear(),
        b.getMonth(),
        b.getDate(),
        b.getHours(),
        b.getMinutes(),
        b.getSeconds()
      );

      // console.log("DATE 1 STRING UTC == "+utc1.toString());
      // console.log("DATE 2 STRING UTC == "+utc2.toString());

      var m = Math.floor((utc2 - utc1) / _MS_PER_MINUTE);
      var diff = m + " Minutes Ago";

      if (m > 60) {
        var h = Math.floor((utc2 - utc1) / _MS_PER_HOUR);
        diff = h + " Hours Ago";

        if (h > 24) {
          var d = Math.floor((utc2 - utc1) / _MS_PER_DAY);
          diff = d + " Days Ago";
        }
      }

      return diff;
    },

    thurayadateDiffInDays(marker) {
      const _MS_PER_DAY = 1000 * 60 * 60 * 24;
      const _MS_PER_HOUR = 1000 * 60 * 60;
      const _MS_PER_MINUTE = 1000 * 60;

      let mName = marker.name;
      let mmName = mName.toUpperCase();
      let refMarker = this.$refs["thurayaMarker" + mmName][0].$attrs.marker;
      var last_up = refMarker.updated.split(" ", 2).join("T");

      var date1 = new Date(last_up);
      var date2 = new Date();

      var a = date1;
      var b = date2;
      //console.log(a);
      //console.log(b);

      // Discard the time and time-zone information.
      var utc1 = Date.UTC(
        a.getFullYear(),
        a.getMonth(),
        a.getDate(),
        a.getHours(),
        a.getMinutes(),
        a.getSeconds()
      );
      var utc2 = Date.UTC(
        b.getFullYear(),
        b.getMonth(),
        b.getDate(),
        b.getHours(),
        b.getMinutes(),
        b.getSeconds()
      );

      var m = Math.floor((utc2 - utc1) / _MS_PER_MINUTE);
      var diff = m + " Minutes Ago";

      if (m > 60) {
        var h = Math.floor((utc2 - utc1) / _MS_PER_HOUR);
        diff = h + " Hours Ago";

        if (h > 24) {
          var d = Math.floor((utc2 - utc1) / _MS_PER_DAY);
          diff = d + " Days Ago";
        }
      }

      return diff;
    },

    showinfosideSailink(m) {
      let mName = m.name;
      let mmName = mName.toUpperCase();
      let refMarker = this.$refs["sailinkMarker" + mmName][0].$attrs.marker;

      this.setdrawerdata(refMarker);
      this.markerClicked(refMarker, "sailink");
      this.setdrawers(true),
        this.settargetactive(true),
        (this.status_marker = "sailink");
    },

    showinfosideThuraya(m) {
      let mName = m.name;
      let mmName = mName.toUpperCase();
      let refMarkerThuraya =
        this.$refs["thurayaMarker" + mmName][0].$attrs.marker;

      this.setdrawerdata(refMarkerThuraya);
      this.markerClicked(refMarkerThuraya, "thuraya");
      this.setdrawers(true),
        this.settargetactive(true),
        (this.status_marker = "thuraya");
    },

    returnLatLong(marker, typeMarker) {
      if (typeMarker == "sailink") {
        if (!this.sailinkPositionUpdate) {
          return [parseFloat(marker.lat), parseFloat(marker.lon)];
        } else if (!this.sailinkPositionUpdate[marker.name.toUpperCase()]) {
          return [parseFloat(marker.lat), parseFloat(marker.lon)];
        } else if (this.sailinkPositionUpdate[marker.name.toUpperCase()]) {
          return [
            parseFloat(
              this.sailinkPositionUpdate[marker.name.toUpperCase()].lat
            ),
            parseFloat(
              this.sailinkPositionUpdate[marker.name.toUpperCase()].lon
            ),
          ];
        }
      } else {
        if (!this.thuraya_positions_update) {
          return [parseFloat(marker.lat), parseFloat(marker.lon)];
        } else if (!this.thuraya_positions_update[marker.name.toUpperCase()]) {
          return [parseFloat(marker.lat), parseFloat(marker.lon)];
        } else if (this.thuraya_positions_update[marker.name.toUpperCase()]) {
          return [
            parseFloat(
              this.thuraya_positions_update[marker.name.toUpperCase()].lat
            ),
            parseFloat(
              this.thuraya_positions_update[marker.name.toUpperCase()].lon
            ),
          ];
        }
      }
    },

    returnRotation(marker, typeMarker) {
      if (typeMarker == "sailink") {
        if (!this.drawerdataUp) {
          return parseInt(marker.heading);
        } else if (!this.drawerdataUp[marker.name.toUpperCase()]) {
          return parseInt(marker.heading);
        } else if (this.drawerdataUp[marker.name.toUpperCase()]) {
          return parseInt(this.drawerdataUp[marker.name.toUpperCase()].heading);
        }
      } else {
        if (!this.drawerdataUpThur) {
          return parseInt(marker.heading);
        } else if (!this.drawerdataUpThur[marker.name.toUpperCase()]) {
          return parseInt(marker.heading);
        } else if (this.drawerdataUpThur[marker.name.toUpperCase()]) {
          return parseInt(
            this.drawerdataUpThur[marker.name.toUpperCase()].heading
          );
        }
      }
    },

    drawer_sailink(marker) {
      this.fetchsatnet(marker);
      this.checkWeather(marker);
      // this.fetchweather(marker);
      this.setdrawerdata(marker);
      (this.typeMarker = "sailink");
    },

    drawer_thuraya(marker) {
      console.log("===== MARKER THURAYA CLICK ======")
      console.log(marker)
      this.setdrawerdata(marker);
      this.checkWeather(marker);

      if(marker.satnet != null){
        this.fetchsatnet(marker);
      }
      // this.fetchweather(marker);
      (this.typeMarker = "thuraya");
    },

    trylatlng(e) {
      //console.log(e);
    },

    async fetchsatnet(m) {
      this.removeSatNet();
      const data = {
        lat: m.lat,
        lon: m.lon,
        satnet: m.satnet,
      };
      this.fetchSatNet(data);
    },

    async fetchweather(m) {
      this.removeWeather();
      var lastUpdated;
      var currentdate;

      var fetch = false;
      if (m.weatherLastUpdated != null) {
        console.log("weather is updated already " + m.weatherLastUpdated);

        lastUpdated = new Date(m.weatherLastUpdated);
        currentdate = new Date();

        console.log("current date == " + currentdate);
        console.log("weather last updated == " + lastUpdated);

        let ms1 = currentdate.getTime();
        let ms2 = lastUpdated.getTime();

        let diff = (ms1 - ms2) / 1000 / 60;
        //let diff = (ms1 - ms2) / 1000;
        console.log("diff == " + diff + " minute");

        if (diff > 15) {
          fetch = true;
        }

      } else {
        fetch = true;
      }


      const data = {
        lat: m.lat,
        lon: m.lon,
        satnet: m.satnet,
        m: m,
        fetch: fetch,
        data: m.weather,
      };

      this.fetchWeather(data);
      console.log('LOG CUACA');
      console.log(this.cuaca);
    },

    checkWeather(m) {
      // console.log("data marker when clicked ========");
      // console.log(m);


      // if (m.weatherLastUpdated == null) {

      // } else {
      //   console.log(weather blom updated);
      // }
      // console.log(m.last_up)
      // var lastUpdated = m.last_up;
      // var lastUpdated = new Date(lastUpdated); //yyyy-mm-dd hh:mm:ss
      // var currentdate = new Date(); //fetch the current date value

      // console.log("current date == "+currentdate);
      // console.log("weather last updated == "+lastUpdated);

      // let ms1 = currentdate.getTime();
      // let ms2 = lastUpdated.getTime();

      // let diff = (ms1 - ms2) / 1000 / 60;
      // console.log("diff == "+diff+" minute");

      this.fetchweather(m);
    },

    centerMap_(marker, markertype) {
      //console.log("CENTER MAP_");
      var latlon = this.returnLatLong(marker, markertype);
      //console.log(latlon)

      if (this.$refs.myMap.mapObject._zoom <= 8) {
        this.$refs.myMap.mapObject.setView(latlon, 8);
      } else if (this.$refs.myMap.mapObject._zoom > 8) {
        this.$refs.myMap.mapObject.setView(
          latlon,
          this.$refs.myMap.mapObject._zoom
        );
      }
    },

    centerMaplatlng_(marker, markertype) {
      //console.log("CENTER MAP_");
      var latlon = this.returnLatLong(marker, markertype);
      //console.log(latlon)

      this.$refs.myMap.mapObject.setView(latlon, this.zoomMap_);
      this.addzoomMap_(this.zoomMap_);
    },

    // initialdata_moving: function () {
    //  this.$root.$emit("initialdata_moving"); //like this
    // },

    recenterMap(lat, lon) {
      if (this.$refs.myMap.mapObject._zoom <= 8) {
        this.$refs.myMap.mapObject.setView([lat, lon], 8);
      } else if (this.$refs.myMap.mapObject._zoom > 8) {
        this.$refs.myMap.mapObject.setView(
          [lat, lon],
          this.$refs.myMap.mapObject._zoom
        );
      }
    },

    stopintervalsailink: function () {
      this.$root.$emit("stopinterval_sailink"); //like this
    },

    clearmoving: function () {
      this.$root.$emit("clearmoving"); //like this
    },

    fromMap_Mooving: function () {
      this.$root.$emit("fromMap_Mooving"); //like this
    },

    fromMap_Mooving_Thuraya: function () {
      this.$root.$emit("fromMap_Mooving_Thuraya"); //like this
    },


    moreDetail(item) {
      this.fromMap_Mooving();
      this.addsailink_executed(false);
      this.removeShowMap();
      this.set_setkondisi_Marker("sailink");
      //console.log('JALAN SAMPAI SINI')
      this.searchVesselID_Input(item.id);
      this.searchVesselInput(item.name);

      this.$router.push("/mysailink");

    },

    moreDetailThuraya(item) {
      this.fromMap_Mooving_Thuraya();
      this.addthuraya_executed(false);
      this.removeShowThuraya();
      this.set_setkondisi_Marker("thuraya");
      this.searchIDThuraya_Input(item.id);
      this.searchThurayaInput(item.name);
      this.$router.push("/mythuraya");
    },

    onFALERT() {
      if (this.tr_falert) {
        this.onAlert();
      } else {
        this.offAlert();
      }
    },

    onAlert() {
      //console.log("on Alert");
      var mother = this;
      this.on = true;
      var intervalSeconds = 0.5;
      this.blinkInterval = setInterval(function () {
        if (this.on) {
          mother.addblinking(true);
        } else {
          mother.addblinking(false);
        }
        this.on = !this.on;
      }, intervalSeconds * 1000);
    },

    offAlert() {
      //onsole.log("off Alert");
      clearInterval(this.blinkInterval);
      this.on = false;
    },
  },

  computed: {
    ...mapGetters([
      "trackline",
      "showmaptracking_thur",
      "trackline_thur",
      "drawerdataUp",
      "drawerdataUpThur",
      "isDarks",
      "sailink_dataFetch",
      "thuraya_dataFetch",
      "user_allowed",
      "zoomMap_",
      "centerMapPeta_",
      "kondisi_Marker",
      "Datauser",
      "SKYR1",
      "SKYR2",
      "APST9",
      "ABS2A",
      "SR1MR",
      "SR2MR",
      "BEAM3",
      "HRZ",
      "UTL70B",
      "EIRP",
      "GNT",
      "tr_flabel",
      "tr_falert",
      "tr_bmkg",
      "tr_linfo",
      "zoom",
      "center",
      "pointerlatlng",
      "typeMarker",
      "drawers",
      "drawerdata",
      "targetActive",
      "tr_vdowninfo",
      "days",
      "sliderBMKGdata",
      "Skyreach1Con",
      "Skyreach2Con",
      "Apstar9Con",
      "Abs2Con",
      "OceanBeamCon",
      "sailinkLocation",
      "sailinkLocationUpdate",
      "vsatdownData",
      "trackingOffData",
      "sailinkPositionUpdate",
      "thurayaLocation",
      "thuraya_positions_update",
      "sidebarVessel",
      "satNet",
      "weaTher",
      "isAuth",
      "userToken",
      "userData",
      "eirp",
      "gnt",
      "blinKing",
      "waveData",
      "selection",
      "user",
      "loading_map",
      "cuaca",
    ]),
    dynamicSize() {
      return [this.iconSize, this.iconSize * 1.15];
    },
    dynamicAnchor() {
      return [this.iconSize / 2, this.iconSize * 1.15];
    },
    optionsBmkg() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },

    dynamicCenter() {
      return this.centerMapPeta_;
    },

    dynamicZoom() {
      return this.zoomMap_;
    },

    styleFunction() {
      const fillColor = this.fillColor;
      return () => {
        //console.log(this.sliderBMKGdata);
        return {
          weight: 2,
          color: "#ECEFF1",
          opacity: 0,
          fillColor: fillColor,
          fillOpacity: this.sliderBMKGdata / 100,
        };
      };
    },

    highlightFeature(e) {
      return (feature, layer) => {
        //console.log(feature);
        layer = e.target;
        layer.setStyle({
          weight: 4,
          color: "black",
          fillOpacity: 0.9,
        });
      };
    },

    onEachFeatureFunction() {
      var color = "gray";
      var wave = "";
      var changeDate = this.selectChip;
      var home = this;

      return (feature, layer) => {
        var WP_1 = feature.properties.WP_1;
        var WP_IMM = feature.properties.WP_IMM;
        var link =
          "https://peta-maritim.bmkg.go.id/public_api/perairan/" +
          WP_1 +
          "_" +
          WP_IMM +
          ".json";

        $.getJSON(link, function (area) {
          let valid_from = new Date(area.data[changeDate].valid_from);
          let valid_to = new Date(area.data[changeDate].valid_to);
          let weatherCat =
            home.weatherCode[
            area.data[changeDate].weather == null
              ? "No Data"
              : area.data[changeDate].weather
            ];
          let windFrom = home.windCode[area.data[changeDate].wind_from];
          let windTo = home.windCode[area.data[changeDate].wind_to];
          let waveCat = home.waveCode[area.data[changeDate].wave_cat];
          var content = "";
          content += "<div>";
          content +=
            '<p style="padding:0;white-space: initial;"><strong><b>' +
            area.name +
            "</b></strong></p>";
          content +=
            '<table  style="border-collapse: collapse;text-align:center;">';
          content += '<tr class="bold">';
          content += "<td >Valid from</td>";
          content +=
            "<td>: " +
            valid_from.toLocaleDateString("en-GB", {
              timeZone: "Asia/Jakarta",
            }) +
            " " +
            valid_from.toLocaleTimeString("en-GB", {
              timeZone: "Asia/Jakarta",
            }) +
            " ICT</td>";
          content += "</tr>";
          content += '<tr class="bold">';
          content += "<td>Valid to</td>";
          content +=
            "<td>: " +
            valid_to.toLocaleDateString("en-GB", { timeZone: "Asia/Jakarta" }) +
            " " +
            valid_to.toLocaleTimeString("en-GB", { timeZone: "Asia/Jakarta" }) +
            " ICT</td>";
          content += "</tr>";
          content += "</table>";
          content += "<br>";
          content +=
            '<table class="mt-2" style="background-color:#F7F7F7; border: 1px solid #fff;">';
          content += '<tr class="middle">';
          content +=
            "<td style='padding:1px;border: 1px solid #fff;'><strong><i class='bx bx-cloud'></i>&nbsp;&nbsp;Weather</strong></td>";
          content +=
            "<td style='padding:1px;border: 1px solid #fff;'><strong><i class='bx bx-wind'></i>&nbsp;&nbsp;Wind</strong></td>";
          content +=
            "<td style='padding:1px;border: 1px solid #fff;'><strong><i class='bx bx-water'></i>&nbsp;&nbsp;Waves</strong></td>";
          content += "</tr>";
          content += '<tr class="middle bold">';
          content +=
            '<td style="padding: 2px;border: 1px solid #fff;">' +
            weatherCat +
            "</td>";
          content += '<td style="padding: 2px;border: 1px solid #fff;">';
          content +=
            area.data[0].wind_speed_min + " - " + area.data[0].wind_speed_max;
          content += "<br>";
          content += windFrom + " -> " + windTo;
          content += "</td>";
          content += '<td style="padding: 2px;border: 1px solid #fff;">';
          content += waveCat;
          content += "<br>";
          content += area.data[0].wave_desc;
          content += "</td>";
          content += "</tr>";
          content += "</table>";
          content += "</div>";
          layer.bindPopup(content, { permanent: false, sticky: true });
        });

        if (changeDate == 0) {
          wave = this.waveData[WP_1].today;
        } else if (changeDate == 1) {
          wave = this.waveData[WP_1].tommorow;
        } else if (changeDate == 2) {
          wave = this.waveData[WP_1].h2;
        } else if (changeDate == 3) {
          wave = this.waveData[WP_1].h3;
        }
        if (wave == "Tenang") {
          color = "#718BEB";
        }
        if (wave == "Rendah") {
          color = "#79D057";
        }
        if (wave == "Sedang") {
          color = "#6A808C";
        }
        if (wave == "Tinggi") {
          color = "#E37833";
        }
        if (wave == "Sangat Tinggi") {
          color = "#B1303B";
        }
        if (wave == "Ekstrem") {
          color = "#CF3BC9";
        }
        if (wave == "Sangat Ekstrem") {
          color = "#000000";
        }
        layer.setStyle({ fillColor: color });
      };
    },
  },
};
</script>

<style scopped>
@import "@/assets/css/navigator.css";

.v-expansion-panel::before {
  box-shadow: none !important;
}

.v-expansion-panel-content__wrap {
  padding: 0 5px 6px;
  flex: 1 1 auto;
  max-width: 100%;
  box-shadow: none;
}

.v-expansion-panel-header {
  align-items: center;
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
  display: flex;
  font-size: 0.7rem;
  line-height: 1;
  min-height: 2px;

  outline: none;
  padding: 16px 24px;
  position: relative;
  transition: 0.3s min-height cubic-bezier(0.25, 0.8, 0.5, 1);
  width: 100%;
}

.v-expansion-panel--active>.v-expansion-panel-header {
  min-height: 2px;
}

.v-list-item:hover {
  background-color: transparent;
  cursor: default;
}

.v-list-item {
  cursor: default;
  overflow-x: hidden;
  overflow-y: hidden;
}

.weather_style {
  cursor: pointer;
  text-decoration-line: underline;
  text-decoration-style: dashed;
}


.box-overlay {
    padding: 1em 1em 0.5em 0.5em;
    border: 0.2em dotted grey;
    position: relative;
    margin-top: -10px;
}

.box-title {
    position: relative;
    font-size: 1em;
    font-weight: 500;
    background-color: #FFF;
    z-index: 1000;
    display: inline-block;
    transform: translateX(-5%);
    right: 30%;
    padding-right: 0.3em;
}



</style>
