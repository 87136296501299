<template>
  <v-dialog v-model="gfOpen" width="90vw" persistent>
    <v-card class="elevation-0 rounded-0">
      <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense>
        <v-col class="header_modal">
          <v-row><v-toolbar-title>Geofencing Config</v-toolbar-title></v-row>
        </v-col>

        <v-spacer></v-spacer>

        <v-btn class="elevation-0 rounded-0" small icon @click="gfRemove()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar v-if="gfData" class="elevation-0 rounded-0" color="#ffff">
        <v-toolbar-subtitle style="text-align: left"
          >{{ gfData.vessel_number }}<br />
          <div v-if="gfData" style="text-align: left">
            <v-chip
              v-if="gfData.latest_status == 'FAILED'"
              class="ma-0 elevation-0 rounded-0 mb-3"
              color="red"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-close-thick </v-icon>
              Failed to change Settings at {{ gfData.last_updated }}, please
              resubmit
            </v-chip>
            <v-chip
              v-if="gfData.latest_status == 'ON AIR'"
              class="ma-0 elevation-0 rounded-0 mb-3"
              color="yellow"
              x-small
              label
              text-color="black"
            >
              <v-icon x-small left> mdi-clock </v-icon>
              Waiting for Confirmation, sent at {{ gfData.last_updated }}
            </v-chip>
            <v-chip
              v-if="gfData.latest_status == 'CONFIRMED'"
              class="ma-0 elevation-0 rounded-0 mb-3"
              color="green"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-check-bold </v-icon>
              Updated at {{ gfData.last_updated }}
            </v-chip>
          </div></v-toolbar-subtitle
        >

        <v-spacer></v-spacer>
      </v-toolbar>

      <v-divider v-if="gfData"></v-divider>

      <v-container style="margin-top: 0" v-if="!gfData" fill-height fluid>
        <v-row align="center" justify="center">
          <v-col
            ><img
              alt="Navplus logo"
              width="70px"
              :src="loadernavplus"
              class="justify-center"
          /></v-col>
        </v-row>
      </v-container>

      <v-card-text
        id="div_gf"
        :style="gfData ? 'height:450px' : ''"
        v-if="gfData"
        class="overflow-y-auto"
      >
        <v-card class="elevation-0 rounded-0 mt-4">
          <v-tabs
            v-model="mothertab"
            background-color="#ffff"
            show-arrows
            color="primary"
          >
            <v-tabs-slider
              color="primary"
              style="display: none"
            ></v-tabs-slider>

            <v-tab
              :disabled="(mothertab != 'restricted-zone' && gfData.latest_status == 'ON AIR') ? true : false"
              href="#restricted-zone"
              @click="tab_restricted == 'petas0' ? fetcharea(1, 0) : ''"
              :style="
                mothertab == 'restricted-zone'
                  ? 'background-color: #f7f7f7;border: solid 1px #e0e0e0;border-bottom: none;border-right:none'
                  : 'background-color: #ffff;border: solid 1px #e0e0e0;border-bottom: none;border-right:none'
              "
            >
              Restricted Zone
            </v-tab>
            <v-tab
            :disabled="(mothertab != 'radio-silence' && gfData.latest_status == 'ON AIR') ? true : false"
              href="#radio-silence"
              @click="tab_radiosilence == 'petas20' ? fetcharea(2, 0) : ''"
              :style="
                mothertab == 'radio-silence'
                  ? 'background-color: #f7f7f7;border: solid 1px #e0e0e0;border-bottom: none;'
                  : 'background-color: #ffff;border: solid 1px #e0e0e0;border-bottom: none;'
              "
            >
              Radio Silence
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="mothertab">
            <v-tab-item :value="'restricted-zone'">
              <v-card
                style="border: solid 1px #e0e0e0; border-top: none"
                class="elevation-0 rounded-0"
              >
                <v-tabs
                  v-model="tab_restricted"
                  background-color="#F7F7F7"
                  show-arrows
                  color="primary"
                >
                  <v-tabs-slider color="primary"></v-tabs-slider>

                  <v-tab
                  :disabled="((tab_restricted != '#petas' + index)  && (mothertab == 'restricted-zone') && (gfData.latest_status == 'ON AIR')) ? true : false"
                    :href="'#petas' + index"
                    @click="fetcharea(1, index)"
                    v-for="(item, index) in gfData.cbValue[1]"
                    :key="item"
                  >
                    <v-checkbox v-model="gfData.cbValue[1][index]"></v-checkbox
                    >Area {{ index + 1 }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab_restricted">
                  <v-tab-item
                    :value="'petas' + index"
                    v-for="(petas, index) in petas"
                    :key="petas"
                  >
                    <v-card
                      class="elevation-0 rounded-0"
                      style="border-top: solid 1px #e0e0e0"
                      flat
                    >
                      <v-card-text>
                        <v-card
                          v-if="mothertab == 'restricted-zone'"
                          class="elevation-0 rounded-0"
                          style="border: solid 1px #e0e0e0"
                        >
                          <v-responsive min-width="100%" min-height="60%">
                            <v-lazy>
                              <client-only>
                                <div id="map">
                                  <l-map
                                    ref="geoMap"
                                    style="height: 40em"
                                    :zoom="4"
                                    :center="[
                                      -0.6850542973682809, 116.72291980522503,
                                    ]"
                                    :options="{
                                      minZoom: 3,
                                      zoomControl: false,
                                      doubleClickZoom: true,
                                      scrollWheelZoom: false,
                                    }"
                                    @click="addMarker"
                                  >
                                    <l-tile-layer
                                      :url="url"
                                      :attribution="attribution"
                                    ></l-tile-layer>
                                    <l-control-zoom
                                      @ready="draw_Polyline()"
                                      position="bottomright"
                                    ></l-control-zoom>
                                    <l-control-fullscreen
                                      position="topright"
                                      :options="{
                                        title: {
                                          false: 'Go big!',
                                          true: 'Be regular',
                                        },
                                      }"
                                    />
                                    <l-control
                                      position="bottomleft"
                                      style="display: flex"
                                    >
                                      <v-card
                                        v-if="gfData.latest_status != 'ON AIR'"
                                        class="elevation-0 rounded-0 ETAcard_sailinkdesktop"
                                        height="fit-content"
                                      >
                                        <v-toolbar
                                          class="elevation-0 rounded-0"
                                          dense
                                          color="#FFFF"
                                        >
                                          <v-btn
                                            :disabled="
                                              !isChange_ ? true : false
                                            "
                                            @click="fetcharea(1, index)"
                                            class="elevation-0 rounded-0"
                                            :color="'primary'"
                                            small
                                            >RESET</v-btn
                                          >
                                          <v-btn
                                            :disabled="
                                              gfpolyPath.length == 0
                                                ? true
                                                : false
                                            "
                                            @click="cleargf()"
                                            class="elevation-0 rounded-0 ml-1"
                                            color="primary"
                                            small
                                            >CLEAR</v-btn
                                          >
                                          <v-btn
                                            :disabled="
                                              gfpolyPath.length < 3 ||
                                              gfpolyPath.length > 99 ||
                                              !isChange_
                                                ? true
                                                : false
                                            "
                                            @click="gfDataPost(1, index)"
                                            class="elevation-0 rounded-0 ml-1"
                                            color="primary"
                                            small
                                            >SUBMIT</v-btn
                                          >
                                        </v-toolbar>
                                      </v-card>
                                      <v-card
                                        v-if="gfData.latest_status == 'ON AIR'"
                                        class="elevation-0 rounded-0 ETAcard_sailinkdesktop"
                                        height="fit-content"
                                      >
                                        <v-toolbar
                                          class="elevation-0 rounded-0"
                                          dense
                                          color="#FFFF"
                                        >
                                          <v-btn
                                            :disabled="true"
                                            @click="fetcharea(1, index)"
                                            class="elevation-0 rounded-0"
                                            :color="'primary'"
                                            small
                                            >RESET</v-btn
                                          >
                                          <v-btn
                                            :disabled="true"
                                            @click="cleargf()"
                                            class="elevation-0 rounded-0 ml-1"
                                            color="primary"
                                            small
                                            >CLEAR</v-btn
                                          >
                                          <v-btn
                                            :disabled="true"
                                            @click="gfDataPost(1, index)"
                                            class="elevation-0 rounded-0 ml-1"
                                            color="primary"
                                            small
                                            >SUBMIT</v-btn
                                          >
                                        </v-toolbar>
                                      </v-card>
                                    </l-control>

                                    <l-marker
                                    
                                      :draggable=" gfData.latest_status == 'ON AIR'? false :true"
                                      :key="marker"
                                      v-for="(marker, index) in gfpolyPath"
                                      :lat-lng="[marker.lat, marker.lng]"
                                      @update:latLng="dragStartHandler"
                                      @update:lat-lng="dragMarker(index)"
                                    >
                                      <l-icon
                                        :icon-size="[40 / 1.3, 100 / 1.3]"
                                        :icon-anchor="[
                                          40 / 1.3 / 2,
                                          100 / 1.3 / 2,
                                        ]"
                                        class-name="someExtraClass"
                                      >
                                        <div
                                        :style="gfData.latest_status == 'ON AIR'?'position: relative;text-align: center;color: #454545;':'position: relative;text-align: center;color: white;'"
                                        >
                                          <svg
                                            id="a"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 288.32 341.71"
                                          >
                                            <path
                                              :fill="gfData.latest_status == 'ON AIR' ? '#DDDDDD' : '#1A77D2'"
                                              stroke="#ffff"
                                              stroke-width="7"
                                              d="M144.16,0C64.54,0,0,64.54,0,144.16c0,70.41,50.48,129.02,117.22,141.64l26.94,55.92,26.94-55.92c66.74-12.62,117.22-71.23,117.22-141.64C288.32,64.54,223.78,0,144.16,0Z"
                                            />
                                          </svg>
                                          <p
                                            style="
                                              position: absolute;
                                              font-size: 12px;
                                              top: 37%;
                                              left: 50%;
                                              transform: translate(-50%, -50%);
                                            "
                                          >
                                            {{ index + 1 }}
                                          </p>
                                        </div>
                                      </l-icon>
                                    </l-marker>

                                    <l-polygon
                                      :lat-lngs="gfpolyPath"
                                      :color="gfData.latest_status == 'ON AIR' ? 'grey' : 'red'"
                                      :fillColor="gfData.latest_status == 'ON AIR' ? 'grey' : 'red'"
                                      :fillOpacity="0.3"
                                    ></l-polygon>
                                  </l-map>
                                </div>
                              </client-only>
                            </v-lazy>
                          </v-responsive>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-tab-item>
            <v-tab-item :value="'radio-silence'">
              <v-card
                style="border: solid 1px #e0e0e0; border-top: none"
                class="elevation-0 rounded-0"
              >
                <v-tabs
                  v-model="tab_radiosilence"
                  background-color="#F7F7F7"
                  show-arrows
                  color="primary"
                >
                  <v-tabs-slider color="primary"></v-tabs-slider>

                  <v-tab
                  :disabled="((tab_radiosilence != '#petas2' + index)  && (mothertab == 'radio-silence') && (gfData.latest_status == 'ON AIR')) ? true : false"
                    :href="'#petas2' + index"
                    @click="fetcharea(2, index)"
                    v-for="(item, index) in gfData.cbValue[2]"
                    :key="item"
                  >
                    <v-checkbox v-model="gfData.cbValue[2][index]"></v-checkbox
                    >Area {{ index + 1 }}
                  </v-tab>
                </v-tabs>
                <v-tabs-items v-model="tab_radiosilence">
                  <v-tab-item
                    :value="'petas2' + index"
                    v-for="(petas, index) in petas_2"
                    :key="petas"
                  >
                    <v-card
                      class="elevation-0 rounded-0"
                      style="border-top: solid 1px #e0e0e0"
                      flat
                    >
                      <v-card-text>
                        <v-card
                          v-if="mothertab == 'radio-silence'"
                          class="elevation-0 rounded-0"
                          style="border: solid 1px #e0e0e0"
                        >
                          <v-responsive min-width="100%" min-height="60%">
                            <v-lazy>
                              <client-only>
                                <div id="map">
                                  <l-map
                                    ref="geoMap2"
                                    style="height: 40em"
                                    :zoom="4"
                                    :center="[
                                      -0.6850542973682809, 116.72291980522503,
                                    ]"
                                    :options="{
                                      minZoom: 3,
                                      zoomControl: false,
                                      doubleClickZoom: true,
                                      scrollWheelZoom: false,
                                    }"
                                    @click="addMarker"
                                  >
                                    <l-tile-layer
                                      :url="url"
                                      :attribution="attribution"
                                    ></l-tile-layer>
                                    <l-control-zoom
                                      @ready="draw_Polyline()"
                                      position="bottomright"
                                    ></l-control-zoom>
                                    <l-control-fullscreen
                                      position="topright"
                                      :options="{
                                        title: {
                                          false: 'Go big!',
                                          true: 'Be regular',
                                        },
                                      }"
                                    />
                                    <l-control
                                      position="bottomleft"
                                      style="display: flex"
                                    >
                                      <v-card
                                        v-if="gfData.latest_status != 'ON AIR'"
                                        class="elevation-0 rounded-0 ETAcard_sailinkdesktop"
                                        height="fit-content"
                                      >
                                        <v-toolbar
                                          class="elevation-0 rounded-0"
                                          dense
                                          color="#FFFF"
                                        >
                                          <v-btn
                                            :disabled="
                                              !isChange_ ? true : false
                                            "
                                            @click="fetcharea(2, index)"
                                            class="elevation-0 rounded-0"
                                            :color="'primary'"
                                            small
                                            >RESET</v-btn
                                          >
                                          <v-btn
                                            :disabled="
                                              gfpolyPath.length == 0
                                                ? true
                                                : false
                                            "
                                            @click="cleargf()"
                                            class="elevation-0 rounded-0 ml-1"
                                            color="primary"
                                            small
                                            >CLEAR</v-btn
                                          >
                                          <v-btn
                                            :disabled="
                                              gfpolyPath.length < 3 ||
                                              gfpolyPath.length > 99 ||
                                              !isChange_
                                                ? true
                                                : false
                                            "
                                            @click="gfDataPost(2, index)"
                                            class="elevation-0 rounded-0 ml-1"
                                            color="primary"
                                            small
                                            >SUBMIT</v-btn
                                          >
                                        </v-toolbar>
                                      </v-card>
                                      <v-card
                                        v-if="gfData.latest_status == 'ON AIR'"
                                        class="elevation-0 rounded-0 ETAcard_sailinkdesktop"
                                        height="fit-content"
                                      >
                                        <v-toolbar
                                          class="elevation-0 rounded-0"
                                          dense
                                          color="#FFFF"
                                        >
                                          <v-btn
                                            :disabled="true"
                                            @click="fetcharea(2, index)"
                                            class="elevation-0 rounded-0"
                                            :color="'primary'"
                                            small
                                            >RESET</v-btn
                                          >
                                          <v-btn
                                            :disabled="true"
                                            @click="cleargf()"
                                            class="elevation-0 rounded-0 ml-1"
                                            color="primary"
                                            small
                                            >CLEAR</v-btn
                                          >
                                          <v-btn
                                            :disabled="true"
                                            @click="gfDataPost(2, index)"
                                            class="elevation-0 rounded-0 ml-1"
                                            color="primary"
                                            small
                                            >SUBMIT</v-btn
                                          >
                                        </v-toolbar>
                                      </v-card>
                                    </l-control>

                                    <l-marker
                                      :draggable="gfData.latest_status == 'ON AIR'? false : true"
                                      :key="marker"
                                      v-for="(marker, index) in gfpolyPath"
                                      :lat-lng="[marker.lat, marker.lng]"
                                      @update:latLng="dragStartHandler"
                                      @update:lat-lng="dragMarker(index)"
                                    >
                                      <l-icon
                                        :icon-size="[40 / 1.3, 100 / 1.3]"
                                        :icon-anchor="[
                                          40 / 1.3 / 2,
                                          100 / 1.3 / 2,
                                        ]"
                                        class-name="someExtraClass"
                                      >
                                        <div
                                          :style="gfData.latest_status == 'ON AIR'?'position: relative;text-align: center;color: #454545;':'position: relative;text-align: center;color: white;'"
                                        >
                                          <svg
                                            id="a"
                                            xmlns="http://www.w3.org/2000/svg"
                                            viewBox="0 0 288.32 341.71"
                                          >
                                            <path
                                            :fill="gfData.latest_status == 'ON AIR' ? '#DDDDDD' : '#1A77D2'"
                                              stroke="#ffff"
                                              stroke-width="7"
                                              d="M144.16,0C64.54,0,0,64.54,0,144.16c0,70.41,50.48,129.02,117.22,141.64l26.94,55.92,26.94-55.92c66.74-12.62,117.22-71.23,117.22-141.64C288.32,64.54,223.78,0,144.16,0Z"
                                            />
                                          </svg>
                                          <p
                                            style="
                                              position: absolute;
                                              font-size: 12px;
                                              top: 37%;
                                              left: 50%;
                                              transform: translate(-50%, -50%);
                                            "
                                          >
                                            {{ index + 1 }}
                                          </p>
                                        </div>
                                      </l-icon>
                                    </l-marker>

                                    <l-polygon
                                      :lat-lngs="gfpolyPath"
                                      :color="gfData.latest_status == 'ON AIR' ? 'grey' : 'red'"
                                      :fillColor="gfData.latest_status == 'ON AIR' ? 'grey' : 'red'"
                                      :fillOpacity="0.3"
                                    ></l-polygon>
                                  </l-map>
                                </div>
                              </client-only>
                            </v-lazy>
                          </v-responsive>
                        </v-card>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs-items>
              </v-card>
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";
import L from "leaflet";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import axios from "../../plugins/axios";
import {
  LMap,
  LTileLayer,
  LControlZoom,
  LControl,
  LMarker,
  LPolygon,
  LIcon,
} from "vue2-leaflet";
export default {
  components: {
    LMap,
    LTileLayer,
    LControlZoom,
    LMarker,
    LControlFullscreen,
    LControl,
    LPolygon,
    LIcon,
  },
  data() {
    return {
      on: null,
      url: "https://maps.skyreach.id/hot/{z}/{x}/{y}.png",
      changes: false,
      clear_: true,
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      mothertab: null,
      tab: null,
      update_latlng: null,
      tab_restricted: null,
      tab_radiosilence: null,
      polyPathStatic: null,
      gfmodal: null,
      fetchInterval: null,
      loadernavplus: require("@/assets/img/load100new.gif"),
      petas: [
        "Area 1",
        "Area 2",
        "Area 3",
        "Area 4",
        "Area 5",
        "Area 6",
        "Area 7",
        "Area 8",
        "Area 9",
        "Area 10",
      ],
      petas_2: [
        "Areas 1",
        "Areas 2",
        "Areas 3",
        "Areas 4",
        "Areas 5",
        "Areas 6",
        "Areas 7",
        "Areas 8",
        "Areas 9",
        "Areas 10",
      ],
    };
  },

  mounted() {
    console.log(this.gfID);
    this.gfFetchStatic();
  },

  methods: {
    ...mapActions([
      "gfFetchInterval",
      "gfRemove",
      "gfFetchArea",
      "removepolyPath",
      "gfFetch",
      "gfPost",
    ]),
    dragStartHandler: function (e) {
      console.log(e);
      this.update_latlng = e;
      // this.gfpolyPath[0]=this.update_latlng;
      // var latlng = e.target.getLatLng();
      // console.log(latlng)
    },

    cleargf() {
      this.clear_ = false;
      this.changes = true;
      this.removepolyPath();
    },

    fetcharea(type, area) {
      this.clear_ = true;
      this.changes = false;

      var data = {
        id: this.gfID,
        type: type,
        area: area,
        token: localStorage.getItem("token"),
      };

      this.gfFetchArea(data);
    },

    draw_Polyline() {
      $(".leaflet-container").css("cursor", "crosshair");
      var mother = this;
      if (this.mothertab == "restricted-zone") {
        for (let i = 0; i < 10; i++) {
          if (mother.tab_radiosilence == "petas" + i) {
            mother.fetcharea(1, i);
          }
        }
      } else if (this.mothertab == "radio-silence") {
        for (let i = 0; i < 10; i++) {
          if (mother.tab_radiosilence == "petas2" + i) {
            mother.fetcharea(2, i);
          }
        }
      } else {
        console.log("nothing");
      }
    },

    async gfDataPost(zone, area) {
      $("#div_gf").scrollTop(0);

      this.change = false;
      var data = {
        thuraya_number: this.gfData.number,
        poly: this.gfpolyPath,
        type: zone,
        area: area,
        enable: this.gfData.cbValue[zone][area],
        token: localStorage.getItem("token"),
      };

      console.log(data);
      this.gfPost(data);
      var mother = this;
      var intervalSeconds = 0.5;
      this.fetchInterval = setInterval(function () {
        mother.gfDataFetch();
      }, intervalSeconds * 1000);
    },

    async gfFetchStatic() {
      try {
        var data = {
          id: this.gfID,
          token: localStorage.getItem("token"),
        };
        //console.log(data)
        const response = await axios.get(
          "/api/v1/navplus/thuraya/" + data.id + "/fetchGeofencing",
          {
            headers: {
              Authorization: "Bearer" + data.token,
            },
          }
        );
        console.log(response.data.data);
        this.polyPathStatic = response.data.data.polyPath;
        this.gfmodal = response.data.data;
        var mother = this;
        var intervalSeconds = 0.5;
        if (response.data.data.latest_status == "ON AIR") {
          this.fetchInterval = setInterval(function () {
            mother.gfDataFetch();
          }, intervalSeconds * 1000);
        }
      } catch (e) {
        console.log(e);
      }
    },

    addMarker(event) {
      if(this.gfData.latest_status == 'ON AIR'){
        console.log('blocked')
      }else{
        this.gfpolyPath.push(event.latlng);
      if (this.gfpolyPath === this.polyPathStatic) {
        console.log("The arrays have the same elements.");
        this.changes = false;
      } else {
        console.log("The arrays have different elements.");
        this.changes = true;
      }
      }
     
    },

    dragMarker(index) {
      console.log(index);
      //  console.log(event.latlng)
      console.log(this.gfpolyPath);
      this.gfpolyPath[index] = this.update_latlng;
      this.gfpolyPath.push(this.update_latlng);
      this.gfpolyPath.pop();
      if (this.gfpolyPath === this.polyPathStatic) {
        console.log("The arrays have the same elements.");
        this.changes = false;
      } else {
        console.log("The arrays have different elements.");
        this.changes = true;
      }

      // this.draw_Polyline()
    },

    stopFetch() {
      console.log("STOP FETCH STATUS GEOFENCING");
      clearInterval(this.fetchInterval);
      this.on = false;
    },

    async gfDataFetch() {
      console.log("FETCH TERUS STATUS GEOFENCING");
      console.log("STATUS :");
      console.log(this.gfData.latest_status);
      const data = {
        id: this.gfID,
        token: localStorage.getItem("token"),
      };
      this.gfFetchInterval(data);
      var mother = this;
      setTimeout(function () {
        if (mother.gfData) {
          if (mother.gfData.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.gfFetchStatic();
          }
        } else {
          if (mother.gfmodal.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.gfFetchStatic();
          }
        }
      }, 5000);
    },
  },
  computed: {
    ...mapGetters(["gfData", "gfOpen", "gfID", "gfpolyPath"]),
    isChange_() {
      return this.changes;
    },
    isClear_() {
      return this.clear_;
    },
  },
};
</script>

<style lang="scss" scoped></style>
