<template>
  <v-app class="container_tabledata" color="#fff">
    <v-card id="top_mysailink" elevation="0">
      <v-alert v-if="OffMysailink" class="elevation-0 rounded-0" prominent type="warning" icon="mdi-web">
        <v-row align="center">
          <v-col class="grow">
            Connection timeout , please check your internet connection
          </v-col>
          <v-col class="shrink">
            <v-btn small @click="reload_page()" class="elevation-0 rounded-0">Reload</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-layout v-resize="onResize" column style="">
        <loadingTable v-if="!sailinkData" />
        <v-card v-if="sailinkData" class="ma-3 elevation-0 rounded-0" style="border: solid 1px #e0e0e0">
          <v-toolbar elevation="0" class="pa-0" color="#F7F7F7" fixed dense>
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0" color="#ffff"
                  v-bind="attrs" v-on="on">
                  {{ kFormatter(itemsPerPage)
                  }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item @click="itemsPerPage = 10"><v-list-item-title>10</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 25"><v-list-item-title>25</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 50"><v-list-item-title>50</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 100"><v-list-item-title>100</v-list-item-title></v-list-item>
              </v-list>
            </v-menu>
            <v-spacer style="width: 70%"></v-spacer>

            <v-text-field style="border: solid 1px #e0e0e0" width="40" v-model="search" hide-details
              prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable class="elevation-0 rounded-0"
              @click="nulsearch()"></v-text-field>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table style="margin-top: 0px" :search="showMapData()" :headers="headers" :items="sailinkData"
            hide-default-footer :page.sync="page" :items-per-page="itemsPerPage"
            class="elevation-0 rounded-0 dataTable desktopTable_sailink" @page-count="pageCount = $event"
            @pagination="yourMethod" hide-default-header>
            <template v-slot:header>
              <tr>
                <th class="pa-0 pt-2 pl-1" style="
                    text-align: left;
                    color: #626364;
                    font-size: 0.9em;
                    height: 3em;
                  ">
                  No
                </th>
                <th v-if="user_allowed.allowed['edit vessel detail']" class="pa-0 pt-2 pl-1" style="
                    text-align: left;
                    color: #626364;
                    font-size: 0.9em;
                    height: 3em;
                  "></th>
                <th class="pa-0 pt-2 pl-1" style="
                    text-align: left;
                    color: #626364;
                    font-size: 0.9em;
                    height: 3em;
                  ">
                  Vessel Name
                </th>
                <th v-if="user_allowed.allowed['show quota bar']" class="pa-0 pt-2 pl-1" style="
                    text-align: left;
                    color: #626364;
                    font-size: 0.9em;
                    height: 3em;
                  ">
                  Quota
                </th>
                <th class="pa-0 pt-2 pl-1" style="
                    text-align: left;
                    color: #626364;
                    font-size: 0.9em;
                    height: 3em;
                  ">
                  Company
                </th>
                <th v-if="user_allowed.role_id <= 2" class="pa-0 pt-2 pl-1" style="
                    text-align: left;
                    color: #626364;
                    font-size: 0.9em;
                    height: 3em;
                  ">
                  Group
                </th>
                <th class="pa-0 pt-2 pl-1" style="
                    text-align: left;
                    color: #626364;
                    font-size: 0.9em;
                    height: 3em;
                  ">
                  S/N
                </th>
                <th class="pa-0 pt-2 pl-1" style="
                    text-align: left;
                    color: #626364;
                    font-size: 0.9em;
                    height: 3em;
                  "></th>
              </tr>
            </template>

            <template v-slot:item="row">
              <tr class="p-0">
                <td width="2%" class="pa-0 pl-1" style="white-space: nowrap; text-align: left">
                  {{ row.item.index + 1 }}
                </td>
                <td width="2%" v-if="user_allowed.allowed['edit vessel detail']" class="pa-0 pl-1"
                  style="white-space: nowrap; text-align: left">
                  <v-btn class="elevation-0 rounded-0 pa-0 ma-0" fab dark x-small color="primary"
                    @click="sailnameFetch(row.item)">
                    <v-icon x-small>mdi-cog</v-icon>
                  </v-btn>
                </td>
                <td width="17%" class="pa-0 pl-1" style="white-space: nowrap; padding: 1em; text-align: left">
                  {{ row.item.name }}
                  <v-chip class="rounded-0 pa-1 ml-1" v-if="row.item.status != 'ACTIVE'" dark x-small>{{ row.item.status
                  }}</v-chip>
                </td>
                <td width="8%" class="pa-0 pl-1 pr-5" style="white-space: nowrap; text-align: left"
                  v-if="user_allowed.allowed['show quota bar']">
                  <row v-if="row.item.quota.quota &&
                    user_allowed.allowed['show quota bar']
                    ">
                    <p style="font-size: 12px; margin-bottom: 0px">
                      {{ row.item.quota.quota }}/{{ row.item.quota.usage }}
                    </p>
                    <v-progress-linear height="10" :value="row.item.quota.percentage"
                      :color="row.item.quota.fill"></v-progress-linear>
                  </row>
                  <row v-if="!row.item.quota.quota ||
                    !user_allowed.allowed['show quota bar']
                    ">
                    -
                  </row>
                </td>

                <td width="16%" class="pa-0 pl-1" style="white-space: nowrap; padding: 1em; text-align: left">
                  {{ row.item.pt_name }}
                </td>
                <td width="10%" v-if="user_allowed.role_id <= 2" class="pa-0 pl-1" style="white-space: nowrap">
                  <div class="grouptxt" v-if="!row.item.pt_group">-</div>
                  <div v-if="row.item.pt_group">{{ row.item.pt_group }}</div>
                </td>
                <td width="3%" class="pa-0 pl-1" style="white-space: nowrap; text-align: left">
                  {{ row.item.serial_number }}
                </td>
                <td width="42%" class="pa-0 pl-2" style="white-space: nowrap">


                  <!--MRTG DESKTOP-->

                  <v-btn width="20%" v-if="row.item.mrtg != null" color="#38C072" dark v-bind="attrs" v-on="on" small class="ma-1 elevation-0 rounded-0 pa-1"
                    @click="sendDataMrtgSingle(row.item)">
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-chart-bar</v-icon>MRTG</div>
                  </v-btn>

                  <v-menu content-class="elevation-0 rounded-0 pa-0" v-if="row.item.mrtg == null && row.item.mrtg_id &&
                    row.item.mrtg_id_2 &&
                    row.item.service == 'hybrid'
                    " offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn width="20%" color="#38C072" dark v-bind="attrs" v-on="on" @click="
                        
                        mrtgclicked != row.item.id
                          ? set_mrtgclicked(row.item.id)
                          : set_mrtgclicked(null)
                        " small class="ma-1 elevation-0 rounded-0 pa-1">
                        <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-chart-bar</v-icon>MRTG</div>
                        <v-icon class="ml-0" small>{{
                          mrtgclicked == row.item.id
                          ? "mdi-chevron-up"
                          : "mdi-chevron-down"
                        }}</v-icon>
                      </v-btn>
                    </template>
                    <v-list style="font-size: 0.9em" color="#38C072" class="pa-0">
                      <v-list-item style="color: #ffff" class="pa-0 ma-0 pl-2" @click="sendDataMrtg('metered', row.item)">
                        Metered
                      </v-list-item>
                      <v-list-item style="color: #ffff" class="pa-0 ma-0 pl-2"
                        @click="sendDataMrtg('unmetered', row.item)">
                        Unmetered
                      </v-list-item>
                    </v-list>
                  </v-menu>

                

                  <v-btn width="20%" v-if="row.item.mrtg == null && row.item.mrtg_id &&
                    !row.item.mrtg_id_2 &&
                    row.item.service == 'hybrid'
                    " color="#38C072" dark v-bind="attrs" v-on="on" small class="ma-1 elevation-0 rounded-0 pa-1"
                    @click="sendDataMrtgSingle(row.item)">
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-chart-bar</v-icon>MRTG</div>
                  </v-btn>

                  <v-btn width="20%" style="cursor: not-allowed" v-if="row.item.mrtg == null && !row.item.mrtg_id &&
                    !row.item.mrtg_id_2 &&
                    !row.item.service
                    " color="#99D9CA" dark v-bind="attrs" v-on="on" small class="ma-1 elevation-0 rounded-0 pa-1">
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-chart-bar</v-icon>MRTG</div>
                  </v-btn>

                  <v-btn width="20%" v-if="row.item.mrtg == null && !row.item.mrtg_id &&
                    row.item.mrtg_id_2 &&
                    row.item.service == 'hybrid'
                    " color="#38C072" dark v-bind="attrs" v-on="on" small class="ma-1 elevation-0 rounded-0 pa-1"
                    @click="sendDataMrtgSingle(row.item)">
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-chart-bar</v-icon>MRTG</div>
                  </v-btn>

                  <v-btn width="20%" v-if=" row.item.mrtg == null && row.item.mrtg_id && row.item.service == 'metered'" color="#38C072" dark
                    v-bind="attrs" v-on="on" small class="ma-1 elevation-0 rounded-0 pa-1"
                    @click="sendDataMrtgSingle(row.item)">
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-chart-bar</v-icon>MRTG</div>
                  </v-btn>

                  <v-btn width="20%" v-if="row.item.mrtg == null && row.item.mrtg_id && row.item.service == 'unmetered'" color="#38C072" dark
                    v-bind="attrs" v-on="on" small class="ma-1 elevation-0 rounded-0 pa-1"
                    @click="sendDataMrtgSingle(row.item)">
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-chart-bar</v-icon>MRTG</div>
                  </v-btn>

                  <v-btn width="20%" v-if="row.item.mrtg == null && !row.item.mrtg_id && row.item.service == 'unmetered'" color="#90D6C5" dark
                    v-bind="attrs" v-on="on" small class="ma-1 elevation-0 rounded-0 pa-1">
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-chart-bar</v-icon>MRTG</div>
                  </v-btn>

                  <v-btn width="20%" v-if="row.item.mrtg == null && !row.item.mrtg_id && row.item.service == 'metered'" color="#90D6C5" dark
                    v-bind="attrs" v-on="on" small class="ma-1 elevation-0 rounded-0 pa-1">
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-chart-bar</v-icon>MRTG</div>
                  </v-btn>

                  <v-btn width="20%" v-if="row.item.mrtg == null && !row.item.mrtg_id &&
                    !row.item.mrtg_id_2 &&
                    row.item.service
                    " color="#90D6C5" dark v-bind="attrs" v-on="on" small class="ma-1 elevation-0 rounded-0 pa-1">
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-chart-bar</v-icon>MRTG</div>
                  </v-btn>




                  <v-btn width="15%" @click="pingClick(row.item)" color="#717A82" dark role="button"
                    class="pingbtn ma-1 elevation-0 rounded-0 pa-1" small>
                    <div v-if="!pingData[row.item.name] && pingClicked != row.item.name
                      ">
                      <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-bullhorn</v-icon>PING</div>
                    </div>

                    <v-progress-linear v-if="!pingData[row.item.name] && pingClicked == row.item.name
                      " color="#fff" indeterminate height="6"></v-progress-linear>

                    <div v-if="pingData[row.item.name]">
                      {{ pingData[row.item.name].ping }}
                    </div>
                  </v-btn>


                  <v-menu content-class="elevation-0 rounded-0 pa-0"  offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn width="29%" color="#6C757D" role="button"
                    class="ma-1 elevation-0 rounded-0 pa-1"  dark v-bind="attrs" v-on="on" small>
                    <div style="font-size: 0.85em;"> <v-icon class="mr-2" x-small>mdi-wrench</v-icon>Notification
                        <v-icon class="ml-0" small>{{"mdi-chevron-down"}}</v-icon></div>
                      </v-btn>
                    </template>
                    <v-list style="font-size: 0.9em" color="#6C757D" class="pa-0">
                      <v-list-item v-if="(row.item.service == 'hybrid' ||
                      row.item.service == 'metered') &&
                    user_allowed.role_id != 4
                    " style="color: #ffff" class="pa-0 ma-0 pl-2"  @click="sendDataNotif(row.item)" >
                       Quota Alert
                      </v-list-item>
                      <v-list-item style="color: #ffff" class="pa-0 ma-0 pl-2" @click="sendDataSpeed(row.item)">
                      Speed Alert
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <!--Notification-->
                  <!-- <v-btn width="29%" v-if="(row.item.service == 'hybrid' ||
                      row.item.service == 'metered') &&
                    user_allowed.role_id != 4
                    " dark @click="sendDataNotif(row.item)" color="#6C757D" role="button"
                    class="ma-1 elevation-0 rounded-0 pa-1" small>
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-wrench</v-icon>Notification</div>
                  </v-btn> -->

                  <!-- <v-btn width="29%" v-else dark style="cursor: not-allowed" color="#A0A6AB" role="button"
                    class="ma-1 elevation-0 rounded-0 pa-1" small>
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-wrench</v-icon>Notification</div>
                  </v-btn> -->

                  

                  <!--Show On Map-->
                  <v-btn width="28%" dark v-if="row.item.status == 'ACTIVE' ||
                    row.item.status == 'OFF-HIRE'
                    " @click="
    addsailink_executed(false),
    showMap(row.item.id, row.item.name),
    (show = !show)
    " color="primary" class="ma-1 elevation-0 rounded-0 pa-1" small>
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-map</v-icon>Show On Map</div>
                  </v-btn>
                  <v-btn width="28%" v-else dark color="#9CBDDE" class="ma-1 elevation-0 rounded-0 pa-1"
                    style="cursor: not-allowed" small>
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-map</v-icon>Show On Map</div>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table style="margin-top: 0px" :search="showMapData()" :headers="headers" :items="sailinkData"
            hide-default-footer :page.sync="page" :items-per-page="itemsPerPage"
            class="elevation-0 rounded-0 mobileTable_sailink" @page-count="pageCount = $event"
            @pagination="yourMethod"><template v-slot:item="row">
              <tr style="margin-top: 0.4em; margin-bottom: 0.4em">
                <td style="border-bottom: none; text-align: left">
                  <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" width="87vw">
                    <v-toolbar dense class="elevation-0 rounded-0">
                      <v-toolbar-subtitle>{{
                        row.item.index + 1
                      }}</v-toolbar-subtitle>
                      <v-spacer></v-spacer>
                      <v-btn v-if="user_allowed.allowed['edit vessel detail']" class="elevation-0 rounded-0 pa-0 ma-0" fab
                        dark x-small color="primary" @click="sailnameFetch(row.item)">
                        <v-icon small>mdi-cog</v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                      <table>
                        <tr>
                          <td>Vessel Name</td>
                          <td>:</td>
                          <td>{{ row.item.name }}</td>
                        </tr>
                        <tr>
                          <td>Quota</td>
                          <td>:</td>
                          <td v-if="user_allowed.allowed['show quota bar']">
                            <row v-if="row.item.quota.quota &&
                              user_allowed.allowed['show quota bar']
                              ">
                              <p style="font-size: 12px; margin-bottom: 0px">
                                {{ row.item.quota.quota }}/{{
                                  row.item.quota.usage
                                }}
                              </p>
                              <v-progress-linear height="10" :value="row.item.quota.percentage"
                                :color="row.item.quota.fill"></v-progress-linear>
                            </row>
                            <row v-if="!row.item.quota.quota ||
                              !user_allowed.allowed['show quota bar']
                              ">
                              -
                            </row>
                          </td>
                        </tr>
                        <tr>
                          <td>Company</td>
                          <td>:</td>
                          <td>{{ row.item.pt_name }}</td>
                        </tr>
                        <tr>
                          <td>Group</td>
                          <td>:</td>
                          <td>{{ row.item.pt_group }}</td>
                        </tr>
                        <tr>
                          <td>S/N</td>
                          <td>:</td>
                          <td>{{ row.item.serial_number }}</td>
                        </tr>
                        <tr>
                          <td colspan="3"><v-divider></v-divider></td>
                        </tr>
                        <tr>
                          <td colspan="3" class="pa-1">

                            <!--MRTG MOBILE-->

                            <v-btn v-if="row.item.mrtg != null" color="#38C072" dark v-bind="attrs" v-on="on" small
                              class="ma-1 elevation-0 rounded-0 pa-1" @click="sendDataMrtgSingle(row.item)">
                              MRTG
                            </v-btn>

                            <v-menu content-class="elevation-0 rounded-0 pa-0" v-if="row.item.mrtg == null && row.item.mrtg_id &&
                              row.item.mrtg_id_2 &&
                              row.item.service == 'hybrid'
                              " offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="#38C072" dark v-bind="attrs" v-on="on" @click="
                                   mrtgclicked != row.item.id
                          ? set_mrtgclicked(row.item.id)
                          : set_mrtgclicked(null)
                                  " small class="ma-1 elevation-0 rounded-0 pa-1">
                                  <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-chart-bar</v-icon>MRTG
                                  </div>
                                  <v-icon class="ml-0" small>{{
                                    mrtgclicked == row.item.id
                                    ? "mdi-chevron-up"
                                    : "mdi-chevron-down"
                                  }}</v-icon>
                                </v-btn>
                              </template>
                              <v-list style="font-size: 0.9em" color="#38C072" class="pa-3">
                                <v-list-item style="color: #ffff" class="pa-0 ma-0"
                                  @click="sendDataMrtg('metered', row.item)">
                                  Metered
                                </v-list-item>
                                <v-list-item style="color: #ffff" class="pa-0 ma-0"
                                  @click="sendDataMrtg('unmetered', row.item)">
                                  Unmetered
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-btn v-if="row.item.mrtg == null && row.item.mrtg_id &&
                              !row.item.mrtg_id_2 &&
                              row.item.service == 'hybrid'
                              " color="#38C072" dark v-bind="attrs" v-on="on" small
                              class="ma-1 elevation-0 rounded-0 pa-1" @click="sendDataMrtgSingle(row.item)">
                              MRTG
                            </v-btn>

                            <v-btn style="cursor: not-allowed" v-if="row.item.mrtg == null && !row.item.mrtg_id &&
                              !row.item.mrtg_id_2 &&
                              !row.item.service
                              " color="#99D9CA" dark v-bind="attrs" v-on="on" small
                              class="ma-1 elevation-0 rounded-0 pa-1">
                              MRTG
                            </v-btn>

                            <v-btn v-if="row.item.mrtg == null && !row.item.mrtg_id &&
                              row.item.mrtg_id_2 &&
                              row.item.service == 'hybrid'
                              " color="#38C072" dark v-bind="attrs" v-on="on" small
                              class="ma-1 elevation-0 rounded-0 pa-1" @click="sendDataMrtgSingle(row.item)">
                              MRTG
                            </v-btn>

                            <v-btn v-if="row.item.mrtg == null && row.item.mrtg_id &&
                              row.item.service == 'metered'
                              " color="#38C072" dark v-bind="attrs" v-on="on" small
                              class="ma-1 elevation-0 rounded-0 pa-1" @click="sendDataMrtgSingle(row.item)">
                              MRTG
                            </v-btn>

                            <v-btn v-if="row.item.mrtg == null && row.item.mrtg_id &&
                              row.item.service == 'unmetered'
                              " color="#38C072" dark v-bind="attrs" v-on="on" small
                              class="ma-1 elevation-0 rounded-0 pa-1" @click="sendDataMrtgSingle(row.item)">
                              MRTG
                            </v-btn>

                            <v-btn v-if="row.item.mrtg == null && !row.item.mrtg_id &&
                              row.item.service == 'unmetered'
                              " color="#90D6C5" dark v-bind="attrs" v-on="on" small
                              class="ma-1 elevation-0 rounded-0 pa-1">
                              MRTG
                            </v-btn>

                            <v-btn v-if="row.item.mrtg == null && !row.item.mrtg_id &&
                              row.item.service == 'metered'
                              " color="#90D6C5" dark v-bind="attrs" v-on="on" small
                              class="ma-1 elevation-0 rounded-0 pa-1">
                              MRTG
                            </v-btn>

                            <v-btn v-if="row.item.mrtg == null && !row.item.mrtg_id &&
                              !row.item.mrtg_id_2 &&
                              row.item.service
                              " color="#90D6C5" dark v-bind="attrs" v-on="on" small
                              class="ma-1 elevation-0 rounded-0 pa-1">
                              MRTG
                            </v-btn>




                            <v-btn @click="pingClick(row.item)" color="#717A82" dark role="button"
                              class="pingbtn ma-1 elevation-0 rounded-0 pa-1" small>
                              <div v-if="!pingData[row.item.name] &&
                                pingClicked != row.item.name
                                ">
                                <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-bullhorn</v-icon>PING
                                </div>

                              </div>

                              <v-progress-linear v-if="!pingData[row.item.name] &&
                                pingClicked == row.item.name
                                " color="#fff" indeterminate height="6"></v-progress-linear>

                              <div v-if="pingData[row.item.name]">
                                {{ pingData[row.item.name].ping }}
                              </div>
                            </v-btn>

                            <!--Notification-->
                            <v-btn v-if="(row.item.service == 'hybrid' ||
                                row.item.service == 'metered') &&
                              user_allowed.role_id != 4
                              " dark @click="sendDataNotif(row.item)" color="#6C757D" role="button"
                              class="ma-1 elevation-0 rounded-0 pa-1" small>
                              <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-wrench</v-icon>Notification
                              </div>
                            </v-btn>

                            <v-btn v-else dark style="cursor: not-allowed" color="#A0A6AB" role="button"
                              class="ma-1 elevation-0 rounded-0 pa-1" small>
                              <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-wrench</v-icon>Notification
                              </div>
                            </v-btn>

                            <!--Show On Map-->
                            <v-btn dark v-if="row.item.status == 'ACTIVE'" @click="
                              addsailink_executed(false),
                              showMap(row.item.id, row.item.name),
                              (show = !show)
                              " color="primary" class="ma-1 elevation-0 rounded-0 pa-1" small>
                              <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-map</v-icon>Show On Map
                              </div>
                            </v-btn>
                            <v-btn v-else dark color="#9CBDDE" class="ma-1 elevation-0 rounded-0 pa-1"
                              style="cursor: not-allowed" small>
                              <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-map</v-icon>Show On Map
                              </div>
                            </v-btn>
                          </td>
                        </tr>
                      </table>
                    </v-card-text>
                  </v-card>
                </td>
              </tr>
            </template></v-data-table>
          <v-divider></v-divider>
          <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
            <p class="mt-3" style="font-size: 0.8em;text-align: left;">
              Showing {{ showingDatafrom }} to {{ showingDatato }} of
              {{ totalFilter }} entries
            </p>
            <v-spacer></v-spacer>
            <v-pagination class="pt-2 pb-2" color="primary" v-if="sailinkData" v-model="page" :length="pageCount"
              :total-visible="7"></v-pagination>
          </v-toolbar>
        </v-card>
      </v-layout>
      <div id="bottom_table"></div>
    </v-card>
    <ShowonMapLoad v-if="!showmaptable && showmapclick" />
    <ShowonMap v-if="showmaptable" />
    <MrtgModal />
    <NameModal v-if="nameopen" :nameid="nameModal" />
    <NotifModal v-if="notifopen" :notif_id="notif_id" />
    <SpeedModal v-if="speedaltopen" :speedalt_id="speed_id" />
  </v-app>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import ShowonMap from "@/components/showonmap/ShowonMap.vue";
import { mapGetters, mapActions } from "vuex";
import * as easings from "vuetify/lib/services/goto/easing-patterns";
import MrtgModal from "@/components/modals/mrtg_modal.vue";
import NameModal from "@/components/modals/name_modal.vue";
import NotifModal from "@/components/modals/notif_modal.vue";
import SpeedModal from "@/components/modals/speedalert_modal.vue";
import loadingTable from "@/components/progress/loadingTable.vue";
import ShowonMapLoad from "@/components/skeleton/ShowonMapLoad.vue";

export default {
  components: {
    ShowonMap,
    MrtgModal,
    NameModal,
    NotifModal,
    loadingTable,
    ShowonMapLoad,
    SpeedModal,
  },
  data() {
    return {
      nameModal: null,
      notif_id: null,
      speed_id:null,
      type: "number",
      number: 9999,
      selector: "#first",
      selections: ["#first", "#second", "#third"],
      selected: "Button",
      elements: ["Button", "Radio group"],
      duration: 300,
      offset: 0,
      easing: "easeInOutCubic",
      easings: Object.keys(easings),
      loading: null,
      alert_: null,
      items: [5, 10, 15, "All"],
      pingData: [],
      itemsPerPage: 10,
      pingClicked: null,
      page: 1,
      pageCount: 0,
      ip_adr: null,
      ln_status: null,
      show: false,
      selectedNotif: "",
      search: "",
      search_name: localStorage.getItem("search_name"),
      search_nav: localStorage.getItem("search_nav"),
      index: "",
      switch1: true,
      loadernavplus: require("@/assets/img/load100new.gif"),
      totalFilter: null,
      headerselse: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "No",
        },
        { text: "Vessel Name", value: "name", sortable: false },
        { text: "Company", value: "pt_name", sortable: false },
        { text: "Group", value: "pt_group", sortable: false },
        { text: "S/N", value: "serial_number", sortable: false },
        { text: "", value: "good", sortable: false },
      ],
      headerselse_else: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "No",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "Edit Vessel Name",
        },
        { text: "Vessel Name", value: "name", sortable: false },
        { text: "Company", value: "pt_name", sortable: false },
        { text: "Group", value: "pt_group", sortable: false },
        { text: "S/N", value: "serial_number", sortable: false },
        { text: "", value: "good", sortable: false },
      ],
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "index",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "Edit Vessel Name",
        },
        { text: "Vessel Name", value: "name", sortable: false },
        { text: "Quota", value: "Quota", sortable: false },
        { text: "Company", value: "pt_name", sortable: false },
        { text: "Group", value: "pt_group", sortable: false },
        { text: "S/N", value: "serial_number", sortable: false },
        { text: "", value: "good", sortable: false },
      ],
    };
  },

  mounted() {
    this.fetchUSERGET(localStorage.getItem("token"));

    var mother = this;
    console.log(mother.sailink_executed);

    if (mother.searchVesselID && !mother.sailink_executed) {
      console.log("show map ke trigger");
      mother.showMap(mother.searchVesselID, mother.searchVessel);
    }

    console.log(this.sailinkData);
  },

  created() {
    this.fetchMySailink(localStorage.getItem("token"));
  },

  methods: {
    ...mapActions([
      "set_mrtgclicked",
      "Inputexpsailink_title",
      "fetchUSERGET",
      "addsailink_executed",
      "removeSearch",
      "fetchMySailink",
      "updateVesselName",
      "fetchMRTG",
      "removeMRTG",
      "showonMapSailink",
      "ipAdr",
      "lnStatus",
      "showondate",
      "addShowClick",
      "setDataName",
      "removeName",
      "setUpStaticName",
      "fetchNotif",
      "fetchSpeedAlert",
      "removeShowMap",
      "vnameFetchSailink",
      "searchVesselName_Input",
    ]),

    yourMethod(pagination) {
      this.totalFilter = pagination.itemsLength;
    },

    reload_page() {
      window.location.reload();
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    onResize() {
      if (window.innerWidth < 769) this.isMobile = true;
      else this.isMobile = false;
    },
    toggleAll() {
      if (this.selected.length) this.selected = [];
      else this.selected = this.desserts.slice();
    },
    changeSort(column) {
      console.log(column);
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    nulsearch() {
      this.removeSearch();
    },
    showMapData() {
      if (this.searchVessel) {
        return this.searchVessel;
      } else {
        return this.search;
      }
    },

    sailnameFetch(item) {
      this.nameModal = item;
      const dataname = {
        id: item.id,
        token: localStorage.getItem("token"),
      };

      this.vnameFetchSailink(dataname);
    },

    async sendDataMrtg(rule, data) {
      if (rule == "metered") {
        const datamrtg = {
          id: data.id,
          mrtg: data.mrtg_id,
          token: localStorage.getItem("token"),
          status_mrtg: "Metered",
        };

        this.fetchMRTG(datamrtg);
      }

      if (rule == "unmetered") {
        const datamrtg = {
          id: data.id,
          mrtg: data.mrtg_id_2,
          token: localStorage.getItem("token"),
          status_mrtg: "Unmetered",
        };

        this.fetchMRTG(datamrtg);
      }
    },

    async sendDataMrtgSingle(data) {
      const datamrtg = {
        id: data.id,
        mrtg: data.mrtg_id,
        token: localStorage.getItem("token"),
        status_mrtg: "Metered",
      };
      this.fetchMRTG(datamrtg);
    },

    async sendDataNotif(item) {
      this.notif_id = item.id;
      const data = {
        id: item.id,
        mrtg_id: item.mrtg_id,
        token: localStorage.getItem("token"),
      };
      this.fetchNotif(data);
    },

    async sendDataSpeed(item) {
      this.speed_id = item.id;
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.fetchSpeedAlert(data);
    },

    async changeVesselName(id) {
      this.loading = true;
      const data = {
        id: id,
        name: this.nameChangeData,
        token: localStorage.getItem("token"),
      };
      this.updateVesselName(data);
      this.fetchMySailink(data.token);
    },

    removeObjectWithId(arr, id) {
      const objWithIdIndex = arr.findIndex((obj) => obj.id === id);

      if (objWithIdIndex > -1) {
        arr.splice(objWithIdIndex, 1);
      }

      return arr;
    },

    async pingClick(item) {
      this.pingClicked = item.name;
      console.log("PING CLICK");
      console.log("ITEM ID == " + item.id);
      console.log("ITEM NAME == " + item.name);
      this.$delete(this.pingData, item.name);
      console.log("before");
      console.log(this.pingData);

      const data = {
        id: item.id,
      };
      const response = await axios.post(
        "https://navigatorplus.sailink.id/api/v1/navplus/sailink/fetchPing",
        data,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      console.log("AFTER");
      console.log(this.pingData);

      this.$set(this.pingData, item.name, response.data.data);
    },

    async showdate(item, range) {
      const data = {
        id: item,
        range: range,
        token: localStorage.getItem("token"),
      };

      this.showondate(data);
    },

    clearmoving_sailink: function () {
      this.$root.$emit("clearmoving_sailink"); //like this
    },

    showMap(item, name) {
      this.clearmoving_sailink();
      this.Inputexpsailink_title(name);
      if (!this.sailink_executed) {
        $("html, body").animate(
          {
            scrollTop: $("#bottom_table").offset().top,
          },
          1000
        );
      }

      this.addShowClick();
      const data = {
        id: item,
        token: localStorage.getItem("token"),
      };
      this.showonMapSailink(data);
      this.ipAdr(data);
      this.lnStatus(data.id);
      this.showdate(item, "24");
    },
  },

  computed: {
    ...mapGetters([
      "mrtgclicked",
      "searchVesselName",
      "OffMysailink",
      "user_allowed",
      "sailink_executed",
      "Datauser",
      "hovershowmap",
      "sailinkData",
      "mrtgSailink",
      "showmaptable",
      "searchVessel",
      "searchVesselID",
      "showmapclick",
      "status_mrtg",
      "mrtgopen",
      "alertSailink",
      "nameopen",
      "nameopen ",
      "message_name",
      "color_name ",
      "nameData",
      "nameChangeData",
      "icon_name",
      "msg_name",
      "nmopen",
      "StaticName",
      "notifopen",
      "speedaltopen",
    ]),
    target() {
      return 62000000;
    },

    showingDatafrom() {
      if (this.totalFilter < 1) {
        return 0;
      } else {
        return 1;
      }
    },

    showingDatato() {
      if (this.totalFilter < this.itemsPerPage) {
        return this.totalFilter;
      } else {
        return this.itemsPerPage;
      }
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      };
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/data.scss";

.vue2leaflet-map {
  z-index: 1;
}

.v-pagination__item {
  background: transparent;
  border-radius: 0px;
  font-size: 0.8rem;
  height: 1.6em;
  margin: 0px;
  min-width: 1.6em;
  padding: 0 5px;
  text-decoration: none;
  transition: 0.3s cubic-bezier(0, 0, 0.2, 1);
  width: auto;
  box-shadow: none;
}

.v-pagination__navigation {
  box-shadow: none;
  border-radius: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  height: 1.2em;
  width: 1.2em;
  margin: 0.2em 0.4em;
}

.v-pagination__item--active {
  box-shadow: none;
}

.v-data-table>.v-data-table__wrapper>table>tbody>tr>td,
.v-data-table>.v-data-table__wrapper>table>tbody>tr>th,
.v-data-table>.v-data-table__wrapper>table>thead>tr>td,
.v-data-table>.v-data-table__wrapper>table>thead>tr>th,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>td,
.v-data-table>.v-data-table__wrapper>table>tfoot>tr>th {
  padding-left: 0.4em;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.leaflet-popup-content-wrapper {
  padding: 1px;
  text-align: left;
  border-radius: 0;
  box-shadow: none;
}

.v-dialog {
  box-shadow: none;
  border-radius: 0;
}

.v-list-item {
  align-items: center;
  display: flex;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 2em;
  outline: none;
  padding: 0 16px;
  position: relative;
  text-decoration: none;
}

$base-color: #edecff;
$shine-color: #ccdbe3;
$animation-duration: 2s;
$avatar-offset: 52 + 16;

@mixin background-gradient {
  background-image: linear-gradient(90deg,
      $base-color 0px,
      $shine-color 40px,
      $base-color 80px);
  background-size: 600px;
}

.line {
  float: left;
  width: 90%;
  height: 16px;
  border-radius: 0;
  margin-top: 0.7em;
  @include background-gradient;
  animation: shine-lines $animation-duration infinite ease-out;
}

@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}

.v-text-field.v-text-field--solo.v-input--dense>.v-input__control {
  min-height: 8px;
}

@media (min-width: 280px) and (max-width: 319px) {
  .mobileTable_sailink {
    display: block;
  }

  .desktopTable_sailink {
    display: none;
  }
}

/* Mobile Devices */
@media (min-width: 320px) and (max-width: 480px) {
  .mobileTable_sailink {
    display: block;
  }

  .desktopTable_sailink {
    display: none;
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .mobileTable_sailink {
    display: none;
  }

  .desktopTable_sailink {
    display: block;
  }
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .mobileTable_sailink {
    display: none;
  }

  .desktopTable_sailink {
    display: block;
  }
}

/* Desktops, large screens */
@media (min-width: 1025px) and (max-width: 1200px) {
  .mobileTable_sailink {
    display: none;
  }

  .desktopTable_sailink {
    display: block;
  }
}

/* Extra large screens / TV */
@media screen and (min-width: 1201px) {
  .mobileTable_sailink {
    display: none;
  }

  .desktopTable_sailink {
    display: block;
  }
}

.dataTable_Sailink tbody {
  flex-direction: column;
}
</style>
