import axios from "../../plugins/axios";

const getDefaultState = () => {
    return {
        reports_data: null,
        OffReports:null,
    }
}

const state = getDefaultState();

const getters = {
    reportsData: (state) => state.reports_data,
    OffReports:(state) => state.OffReports
};

const actions = {
    async fetchReports({commit}, data) {
        try{
            const response = await axios.get(
                "/api/v1/navplus/sailink",
                {
                    headers: {
                        Authorization: "Bearer" + data,
                    },
                }
            );
            //console.log(response);
            commit("setReports", response.data.data.vessels.map((item, index) =>
                Object.assign(item, {index})
            ));
        commit("setOffReports",null)
        }catch(e){
        commit("setOffReports",true)
        }
        
    },

    resetReportState({commit}) {
        commit("resetState");
    },


};

const mutations = {
    setReports: (state, reports) => (state.reports_data = reports),
    setOffReports:(state, OffReports) => (state.OffReports = OffReports),
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
