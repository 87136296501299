import axios from "../../plugins/axios";

const getDefaultState = () => {
  return {
    snapshot_data: null,
    snapshotfolder: null,
    snapshotpict: null,
    loadingimage: null,
    loadingfolder: null,
    vesselData: null,
    filterlist: null,
    folder_select: null,
    snapshot_executed: false,
    snapshotclick: null,
    dirLabel_: null,
    OffSnapshot: null,
    errorsnap: false,
    notifsnapopen: null,
    notifsnapData: null,
    notifsnapID: null,
    notifsn_success: null,
  };
};

const state = getDefaultState();

const getters = {
  folderSelect: (state) => state.folder_select,
  snapshotData: (state) => state.snapshot_data,
  filterList: (state) => state.filterlist,
  snapshotfolder: (state) => state.snapshotfolder,
  snapshotpict: (state) => state.snapshotpict,
  loadingimage: (state) => state.loadingimage,
  loadingfolder: (state) => state.loadingfolder,
  vesselData: (state) => state.vesselData,
  snapshot_executed: (state) => state.snapshot_executed,
  snapshotclick: (state) => state.snapshotclick,
  dirLabel_: (state) => state.dirLabel_,
  OffSnapshot: (state) => state.OffSnapshot,
  errorsnap: (state) => state.errorsnap,
  notifsnapopen: (state) => state.notifsnapopen,
  notifsnapData: (state) => state.notifsnapData,
  notifsnapID: (state) => state.notifsnapID,
  notifsn_success: (state) => state.notifsn_success,
};

const actions = {
  async fetchSnapshot({ commit }, data) {
    try {
      const response = await axios.get("/api/v1/navplus/snapshot", {
        headers: {
          Authorization: "Bearer" + data,
        },
      });
      console.log(response.data.data.vessels);
      commit(
        "setSnapshot",
        response.data.data.vessels.map((item, index) =>
          Object.assign(item, { index })
        )
      );
      commit("setOffSnapshot", null);
    } catch (e) {
      commit("setOffSnapshot", true);
    }
  },

  async snapshotStatus({ commit }, data) {
    try {
      const response = await axios.get("/api/v1/navplus/snapshotStatus ", {
        headers: {
          Authorization: "Bearer" + data,
        },
      });
      console.log("FETCH SNAPSHOT STATUS");
      console.log(response.data.data);
      commit(
        "setSnapshot",
        response.data.data.vessels.map((item, index) =>
          Object.assign(item, { index })
        )
      );
      commit("setOffSnapshot", null);
    } catch (e) {
      commit("setOffSnapshot", true);
    }

    setTimeout(() => {
      actions.snapshotStatus({ commit }, localStorage.getItem("token"));
    }, 300000);
  },

  async fetchFolder({ commit }, data) {
    commit("setsnapshotclick", true);
    commit("setloadingfolder", null);
    commit("setsnapshotfolder", null);
    commit("setsnapshotpict", null);
    commit("setfilterlist", null);
    commit("setloadingimage", true);

    try {
      const response = await axios.get("/api/v1/navplus/snapshot/" + data.id, {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      });
      commit("setloadingfolder", true);
      commit("setsnapshotfolder", response.data.data);
      commit("setfilterlist", response.data.data.list);
      commit("folderonSelect", response.data.data.dir_label[0]);
      var dirLabel = response.data.data.dir_label.map((p) => ({
        ...p,
        label_search: p,
      }));
      commit("setdirLabel_", dirLabel);
      commit("setsnapshot_executed", false);

      // const datapict = {
      //     id:data.id,
      //     label: response.data.data.dir_label[0],
      //     token: data.token
      // };

      commit("setsnapshot_executed", false);
      // var action = actions
      // setTimeout(
      // function() {
      //         action.FolderClick({ commit },datapict)
      // }, 1000);
    } catch (error) {
      console.log(error);
    }
  },

  async fetchNotifSnap({ commit }, data) {
    console.log(data.id);
    commit("setnotifsnapID", data.id);
    commit("setnotifsnapopen", true);
    const response = await axios.get(
      "/api/v1/navplus/snapshotContact/" + data.id,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    commit("setnotifsnapData", response.data.data);
  },

  async notifSnapPost({ commit }, data) {
    const datanotif = {
      enabled: data.email_enabled,
      emails: data.emails,
    };
    console.log(datanotif);
    const response = await axios.post(
      "/api/v1/navplus/snapshotContact/" + data.id,
      datanotif,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    if (response.data.data) {
      commit("set_notifsn_success", true);
    }

    commit("setnotifData", response.data.data);

    setTimeout(() => {
      commit("set_notifsn_success", null);
    }, 2000);
  },

  removeSnapNotif({ commit }) {
    commit("setnotifsnapID", null);
    commit("setnotifsnapopen", null);
    commit("setnotifsnapData", null);
  },

  setFolderSelect({ commit }, data) {
    commit("folderonSelect", data);
  },

  addsnapshot_executed({ commit }, data) {
    commit("setsnapshot_executed", data);
  },

  removeErrorsnap({ commit }) {
    commit("seterrorsnap", false);
  },

  async FolderClick({ commit }, data) {
    commit("seterrorsnap", false);
    commit("setsnapshotpict", null);
    commit("setloadingimage", true);
    commit("folderonSelect", data.label);
    try {
      console.log(data.label);
      const response = await axios.get(
        "/api/v1/navplus/snapshot/" + data.id + "/" + data.label + "/kosong",
        {
          headers: {
            Authorization: "Bearer" + data.token,
            "Access-Control-Allow-Origin": "*",
            "Content-Type": "application/json",
          },
        }
      );

      console.log(response);
      commit("setsnapshotpict", response.data.data);
      commit("seterrorsnap", false);
    } catch (e) {
      console.log(e);
      console.log("FOLDERCLICK");
      commit("seterrorsnap", true);
    }
  },

  async FilterClick({ commit }, data) {
    //console.log(data.id)
    commit("setsnapshotpict", null);
    commit("setloadingimage", true);
    const response = await axios.get(
      "/api/v1/navplus/snapshot/" + data.id + "/" + data.label + "/" + data.ch,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    //console.log(response)
    commit("setsnapshotpict", response.data.data);
  },

  vesselDataSet({ commit }, data) {
    commit("setvesselData", data);
  },

  closeShow({ commit }) {
    //console.log('CLOSE SHOW SNAPSHOT')
    commit("setsnapshotclick", null);
    commit("setloadingfolder", null);
    commit("folderonSelect", null);
    commit("setsnapshotfolder", null);
    commit("setfilterlist", null);
    commit("setsnapshotpict", null);
    commit("setloadingimage", null);
    commit("setvesselData", null);
  },

  resetSnapshotState({ commit }) {
    commit("resetState");
  },
};

const mutations = {
  setdirLabel_: (state, dirLabel_) => (state.dirLabel_ = dirLabel_),
  folderonSelect: (state, folderonselect) =>
    (state.folder_select = folderonselect),
  setsnapshotclick: (state, snapshotclick) =>
    (state.snapshotclick = snapshotclick),
  setsnapshot_executed: (state, snapshot_executed) =>
    (state.snapshot_executed = snapshot_executed),
  setSnapshot: (state, snapshot) => (state.snapshot_data = snapshot),
  setsnapshotfolder: (state, snapshotfolder) =>
    (state.snapshotfolder = snapshotfolder),
  setfilterlist: (state, filterlist) => (state.filterlist = filterlist),
  setsnapshotpict: (state, snapshotpict) => (state.snapshotpict = snapshotpict),
  setloadingimage: (state, loadingimage) => (state.loadingimage = loadingimage),
  setloadingfolder: (state, loadingfolder) =>
    (state.loadingfolder = loadingfolder),
  setvesselData: (state, vesselData) => (state.vesselData = vesselData),
  setOffSnapshot: (state, OffSnapshot) => (state.OffSnapshot = OffSnapshot),
  seterrorsnap: (state, errorsnap) => (state.errorsnap = errorsnap),
  setnotifsnapopen: (state, notifsnapopen) =>
    (state.notifsnapopen = notifsnapopen),
  setnotifsnapData: (state, notifsnapData) =>
    (state.notifsnapData = notifsnapData),
  setnotifsnapID: (state, notifsnapID) => (state.notifsnapID = notifsnapID),
  set_notifsn_success: (state, notifsn_success) =>
    (state.notifsn_success = notifsn_success),
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
