import api from "../../plugins/axios";
import axios from "axios";
import router from "../../router";
import Vue from "vue";
//import "./login";

const getDefaultState = () => {
    return {
        user_allowed: null,
        centerMap: [-0.1292586012050707, 113.39020523630768],
        center: [1.3310588280309024, 117.1091316858859],
        loading_map: true,
        zoom: 5,
        drawerdata: null,
        drawers: null,
        targetActive: false,
        tr_vdowninfo: null,
        tr_bmkg: null,
        tr_linfo: null,
        tr_flabel: null,
        tr_falert: null,
        VDOWNINFO: null,
        wave_data: null,
        set_token: null,
        vsatdown_data: null,
        slider_bmkg: 50,
        tracking_off: null,
        sailink_locations: null,
        sailink_locations_update: null,
        sailink_positions_update: null,
        thuraya_locations: null,
        thuraya_positions_update: null,
        sidebar_vessel: null,
        satnet: null,
        weather: null,
        blinking: null,
        eirp: null,
        gnt: null,
        Skyreach1Con: null,
        Skyreach2Con: null,
        Apstar9Con: null,
        Abs2Con: null,
        OceanBeamCon: null,
        TotalVsat: 0,
        TotalOff: 0,
        selection: 1,
        changeDateData: 1,
        days: 0,
        statusInternet: true,
        pointerlatlng: null,
        typeMarker: null,
        zoomMap: 5,
        urlMap: "https://maps.skyreach.id/hot/{z}/{x}/{y}.png",
        attributionMap:
            '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        EIRP: null,
        GNT: null,
        SKYR1: null,
        SKYR2: null,
        APST9: null,
        ABS2A: null,
        SR1MR: null,
        SR2MR: null,
        BEAM3: null,
        UTL70B: null,
        HRZ: null,
        kondisi_Marker: null,
        zoomMap_: 5,
        centerMapPeta_: [-0.7893, 118],
        sailink_dataFetch: null,
        thuraya_dataFetch: null,
        isDarks: parseInt(localStorage.getItem("nightMode")),
        drawerdataUp: null,
        drawerdataUpThur: null,
        cuaca: [],
        snap_inactive: null

    };
};

const state = getDefaultState();

const getters = {
    cuaca: (state) => state.cuaca,
    drawerdata: (state) => state.drawerdata,
    VDOWNINFO: (state) => state.VDOWNINFO,
    waveData: (state) => state.wave_data,
    sliderBMKGdata: (state) => state.slider_bmkg,
    tokenPage: (state) => state.sailink_locations,
    blinKing: (state) => state.blinking,
    sailinkLocation: (state) => state.sailink_locations,
    vsatdownData: (state) => state.vsatdown_data,
    trackingOffData: (state) => state.tracking_off,
    sailinkLocationUpdate: (state) => state.sailink_locations_update,
    sailinkPositionUpdate: (state) => state.sailink_positions_update,
    thurayaLocation: (state) => state.thuraya_locations,
    thuraya_positions_update: (state) => state.thuraya_positions_update,
    sidebarVessel: (state) => state.sidebar_vessel,
    satNet: (state) => state.satnet,
    weaTher: (state) => state.weather,
    apiKey: (state) => state.apiKey,
    eirp: (state) => state.eirp,
    gnt: (state) => state.gnt,
    Skyreach1Con: (state) => state.Skyreach1Con,
    Skyreach2Con: (state) => state.Skyreach2Con,
    Apstar9Con: (state) => state.Apstar9Con,
    Abs2Con: (state) => state.Abs2Con,
    OceanBeamCon: (state) => state.OceanBeamCon,
    TotalVsat: (state) => state.TotalVsat,
    TotalOff: (state) => state.TotalOff,
    changeDateData: (state) => state.changeDateData,
    selection: (state) => state.selection,
    days: (state) => state.days,
    statusInternet: (state) => state.statusInternet,
    tr_vdowninfo: (state) => state.tr_vdowninfo,
    drawers: (state) => state.drawers,
    targetActive: (state) => state.targetActive,
    pointerlatlng: (state) => state.pointerlatlng,
    typeMarker: (state) => state.typeMarker,
    centerMap: (state) => state.centerMap,
    zoomMap: (state) => state.zoomMap,
    urlMap: (state) => state.urlMap,
    attributionMap: (state) => state.attributionMap,
    center: (state) => state.center,
    zoom: (state) => state.zoom,
    tr_bmkg: (state) => state.tr_bmkg,
    tr_linfo: (state) => state.tr_linfo,
    tr_flabel: (state) => state.tr_flabel,
    tr_falert: (state) => state.tr_falert,
    EIRP: (state) => state.EIRP,
    GNT: (state) => state.GNT,
    SKYR1: (state) => state.SKYR1,
    SKYR2: (state) => state.SKYR2,
    APST9: (state) => state.APST9,
    ABS2A: (state) => state.ABS2A,
    SR1MR: (state) => state.SR1MR,
    SR2MR: (state) => state.SR2MR,
    BEAM3: (state) => state.BEAM3,
    UTL70B: (state) => state.UTL70B,
    HRZ: (state) => state.HRZ,
    loading_map: (state) => state.loading_map,
    kondisi_Marker: (state) => state.kondisi_Marker,
    zoomMap_: (state) => state.zoomMap_,
    centerMapPeta_: (state) => state.centerMapPeta_,
    user_allowed: (state) => state.user_allowed,
    sailink_dataFetch: (state) => (state.sailink_dataFetch),
    thuraya_dataFetch: (state) => (state.thuraya_dataFetch),
    isDarks: (state) => (state.isDarks),
    drawerdataUp: (state) => (state.drawerdataUp),
    drawerdataUpThur: (state) => (state.drawerdataUpThur),
    snap_inactive: (state) => (state.snap_inactive)

};

const actions = {
    addblinking({commit}, data) {
        commit("setBlinking", data);
    },

    sliderBMKG({commit}, data) {
        //console.log(data);
        commit("setsliderbmkg", data);
    },

    async fetchJsonWave({commit}) {
        const response = await axios.get(
            "https://peta-maritim.bmkg.go.id/public_api/overview/gelombang.json"
        );
        commit("setWaveData", response.data);
    },

    async fetchVessel({commit}, data) {
        try {
            const response = await api.get("/api/v1/navplus/gmaps/index", {
            });

            const excluded = [
                74,
                43,
                93,
                85,
                76,
                102,
                129,
                79,
                72,
                108,
                66,
                132,
                67,
                90,
                71,
                87,
                77,
                153,
                106,
                88, // phoenix alpha
                // 175, // marina 2230
            ];

            var sailink_locations = [];
            var sailink_source = response.data.data.locations.navplus;
            // console.log(sailink_source.length)
            for (var b = 0; b < sailink_source.length; b++) {    
                if (
                    (sailink_source[b]["pt"] == "PT Golden Vesta") ||
                    (sailink_source[b]["pt"] ==
                        "PT Pelayaran Nasional Indonesia" &&
                        response.data.data.user.email.includes("pelni.co.id"))
                ) {
                    continue;
                } else {
                    sailink_locations.push(sailink_source[b]);
                }
            }

            commit("setSailink", sailink_locations);
            commit("setdrawerdataUp", sailink_locations);
            commit("setsnap_inactive", response.data.data.snap_inactive_count)
            //console.log('USER INCLUDE')
            //console.log(response.data.data.user)
            //console.log(response.data.data.locations.thuraya);
            var thuraya_locations = [];
            for (var a in response.data.data.locations.thuraya) {
                if (
                    !response.data.data.locations.thuraya[a].rateplan.includes(
                        "SLTrack"
                    ) ||
                    excluded.includes(response.data.data.locations.thuraya[a]["id"]) 
                ) {
                    continue;
                } else {
                    thuraya_locations.push(response.data.data.locations.thuraya[a]);
                }
            }

            commit("setThuraya", thuraya_locations);
            commit("setdrawerdataUpThur", thuraya_locations)
            commit("setSidebar", response.data.data.sidebar_vessel);
            var trackingoff = [];
            for (var i in response.data.data.locations.thuraya) {
                if (
                    (response.data.data.locations.thuraya[i].fill == "red" ||
                        response.data.data.locations.thuraya[i].fill == "#828784") &&
                    response.data.data.locations.thuraya[i].rateplan.includes("SLTrack")
                ) {
                    trackingoff.push(response.data.data.locations.thuraya[i]);
                }
            }
            commit("trackingOff", trackingoff);
            var totaltoff = 0;
            for (var b in response.data.data.locations.navplus) {
                if (
                    response.data.data.locations.thuraya[b].fill == "red" ||
                    response.data.data.locations.thuraya[b].fill == "#828784"
                    // && response.data.data.locations.thuraya[b].rateplan.includes("SLTrack")
                ) {
                    totaltoff = totaltoff + 1;
                }
            }
            commit("setTotalOff", totaltoff);
        } catch (e) {
            console.log(e)
            // localStorage.removeItem("token");
            // localStorage.removeItem("user_data");
            // localStorage.setItem("name", "");
            // localStorage.setItem("auth", false);
            // router.push("/");
            // window.location.reload();
        }
    },

    async updateVessel({commit}, data) {
        const response = await api.get("/api/v1/navplus/gmaps/refreshMarker", {
        });
        ////console.log("UPDATE SAILINK COLOR PER 3 DETIK");
        console.log(response.data.data)
        commit("setSailinkUpdate", response.data.data.locations);
        var obj = response.data.data.locations;
        var result = Object.values(obj);
        var vsatdown = [];

        for (var i in result) {
            if (result[i].fill == "red") {
                vsatdown.push(result[i]);
            }
        }

        commit("vsatdown", vsatdown);
        commit("setOnline", true);

        setTimeout(() => {
            actions.updateVessel({commit}, localStorage.getItem("token"));
        }, 1000);
    },

    setdays({commit}, data) {
        ////console.log(data);
        commit("setDays", data);
    },

    async updateLocation({commit}, data) {
        const response = await api.get(
            "/api/v1/navplus/gmaps/refreshMarkerPositions",
            {
                
            }
        );
        console.log(response.data.data)
        commit("setdrawerdataUp", response.data.data.locations)
        commit("setSailinkPositionUpdate", response.data.data.locations);

        setTimeout(() => {
            actions.updateLocation({commit}, localStorage.getItem("token"));
        }, 180000);
    },

    async updateLocationThuraya({commit}, data) {
        // const response = await api.get(
        //     "/api/v1/navplus/gmaps/refreshMarkerPositionsThuraya",
        //     {
        //     }
        // );
        // commit("setdrawerdataUpThur", response.data.data.thuraya_locations)
        // commit("setThurayaPositionUpdate", response.data.data.thuraya_locations);
        // const toff = await api.get("/api/v1/navplus/gmaps/index", {

        // });
        // commit("setsnap_inactive", toff.data.data.snap_inactive_count)
        // var trackingoff = [];
        // for (var i in toff.data.data.locations.thuraya) {
        //     if (
        //         (toff.data.data.locations.thuraya[i].fill == "red" ||
        //             toff.data.data.locations.thuraya[i].fill == "#828784") &&
        //         toff.data.data.locations.thuraya[i].rateplan.includes("SLTrack")
        //     ) {
        //         trackingoff.push(toff.data.data.locations.thuraya[i]);
        //     }
        // }
        // commit("trackingOff", trackingoff);
        // setTimeout(() => {
        //     actions.updateLocationThuraya({commit}, localStorage.getItem("token"));
        // }, 600000);
    },


    async updateSnapStatus_({commit}, data) {
        const snap_status = await api.get("/api/v1/navplus/gmaps/index", {

        });
        commit("setsnap_inactive", snap_status.data.data.snap_inactive_count)

        setTimeout(() => {
            actions.updateSnapStatus_({commit}, localStorage.getItem("token"));
        }, 300000);
    },

    async fetchSatNet({commit}, data) {
        const response = await api.post(
            "/api/v1/navplus/gmaps/fetchSatnetData",
            data,
            {
            }
        );
        commit("setSatNet", response.data.data);
    },

    async fetchWeather({commit}, data) {
        var response, weatherData;
        if (data.fetch) {
            response = await api.post(
                "/api/v1/navplus/gmaps/fetchWeatherCurrent",
                data,
                {
                }
            );

            //console.log("WEATHER");
            console.log('WEATHER YANG DI DAPAT');
            console.log(response.data);
            console.log(response.data.data.dateTime);
            data.m.weatherLastUpdated = response.data.data.dateTime;
            data.m.weather = response.data.data;
            weatherData = response.data.data;
        } else {
            console.log("weather exist");
            console.log(data);
            weatherData = data.data;
        }

        // Vue.set(state.cuaca, response.data.data.dateTime, response.data.data);
        commit("setWeather", weatherData);
    },

    async fetchUSERGET({commit}, data) {
        console.log('MASUK FETCHGET')
        try {
            const response = await api.get("/api/login_navplus", {
            });
            console.log(response);
            if (response.data.status != true) {
                localStorage.removeItem("token");
                localStorage.removeItem("user_data");
                localStorage.setItem("name", "");
                localStorage.setItem("auth", 0);
                router.push("/login");
                window.location.reload();
            } else {
                commit("setUserAllowed", response.data.user);
            }

        } catch (e) {
            // localStorage.removeItem("token");
            // localStorage.removeItem("user_data");
            // localStorage.setItem("name", "");
            // localStorage.setItem("auth", 0);
            // router.push("/login");
            // window.location.reload();
        }


        // console.log("DAPAT USER");
        // console.log(response.data.user.allowed["is super admin"]);
    },

    removeSatNet({commit}) {
        commit("setSatNet", null);
    },

    removeWeather({commit}) {
        commit("setWeather", null);
    },

    eirpOn({commit}, data) {
        commit("setBlinking", data);
    },

    gntOn({commit}, data) {
        //console.log(data);
        commit("setgnt", data);
    },

    addSkyreach1Con({commit}, data) {
        commit("setSkyreach1Con", data);
    },

    addSkyreach2Con({commit}, data) {
        commit("setSkyreach2Con", data);
    },

    addApstar9Con({commit}, data) {
        commit("setApstar9Con", data);
    },

    addAbs2Con({commit}, data) {
        commit("setAbs2Con", data);
    },

    addOceanBeamCon({commit}, data) {
        commit("setOceanBeamCon", data);
    },

    addTotal({commit}, data) {
        //console.log(data);
        commit("setTotalVsat", data);
        commit("setTotalOff", data);
    },

    setvdowninfo({commit}, data) {
        //console.log("TRIGGER VSATDOWN");
        //console.log(data);
        commit("setvdowninfo_tr", data);
    },

    setbmkgtr({commit}, data) {
        commit("setbmkg_tr", data);
    },

    setlinfo({commit}, data) {
        commit("setlinfo_tr", data);
    },

    setfalert({commit}, data) {
        //console.log("FALERT");
        //console.log(data);
        commit("setfalert_tr", data);
    },

    setflabel({commit}, data) {
        commit("setflabel_tr", data);
    },

    setdrawers({commit}, data) {
        commit("setdrawersdata", data);
    },
    settargetactive({commit}, data) {
        commit("settargetactivedata", data);
    },
    setdrawerdata({commit}, data) {
        commit("setdrawerdatadata", data);
    },
    setpointerlatlng({commit}, data) {
        commit("setpointerlatlngdata", data);
    },
    settypeMarker({commit}, data) {
        commit("settypeMarkerdata", data);
    },
    setcenterMap({commit}, data) {
        commit("setcentermap", null);
        commit("setcentermap", data);
    },
    setZoomMap({commit}, data) {
        commit("setzoommap", null);
        commit("setzoommap", data);
    },

    setEIRP({commit}, data) {
        //console.log("EIRP JALAN");
        //console.log(data);
        commit("setEIRP_tr", data);
    },

    setGNT({commit}, data) {
        //console.log("GNT JALAN");
        //console.log(data);
        commit("setGNT_tr", data);
    },

    setSKYR1({commit}, data) {
        //console.log("SKYR1 JALAN");
        //console.log(data);
        commit("setSKYR1_tr", data);
    },

    setSKYR2({commit}, data) {
        //console.log("SKYR2 JALAN");
        //console.log(data);
        commit("setSKYR2_tr", data);
    },

    setAPST9({commit}, data) {
        //console.log("APST9 JALAN");
        //console.log(data);
        commit("setAPST9_tr", data);
    },

    setABS2A({commit}, data) {
        //console.log("ABS2A JALAN");
        //console.log(data);
        commit("setABS2A_tr", data);
    },

    setSR1MR({commit}, data) {
        //console.log("SR1MR JALAN");
        //console.log(data);
        commit("setSR1MR_tr", data);
    },

    setSR2MR({commit}, data) {
        //console.log("SR2MR JALAN");
        //console.log(data);
        commit("setSR2MR_tr", data);
    },

    setBEAM3({commit}, data) {
        //console.log("BEAM3 JALAN");
        //console.log(data);
        commit("setBEAM3_tr", data);
    },

    setHRZ({commit}, data) {
        //console.log("HRZ JALAN");
        //console.log(data);
        commit("setHRZ_tr", data);
    },

    setUTL70B({commit}, data) {
        //console.log("UTL70B JALAN");
        //console.log(data);
        commit("setUTL70B_tr", data);
    },

    setsetloading_map({commit}, data) {
        commit("setloading_map", data);
    },

    async setPremis({commit}, data) {
        // console.log("CHECK UNCHECK PREMISSIONS");
        // console.log(data);

        const response = await api.post(
            "/api/v1/navplus/user/setPermission",
            {
                permission: data,
            },
            {

            }
        );
        // console.log(response);
        commit("setUserAllowed", response.data.data.user);
        // console.log(commit);
    },

    set_setkondisi_Marker({commit}, data) {
        //console.log('SET KONDISI')
        commit("setkondisi_Marker", data);
    },

    resetNavplusState({commit}) {
        commit("resetState");
    },

    addzoomMap_({commit}, data) {
        // console.log("zoom map di set");
        // console.log(data);
        commit("setzooMap_", data);
    },

    addcenterMapPeta_({commit}, data) {
        // console.log("center map di set");
        // console.log(data);
        commit("setcenterMapPeta_", data);
    },

    addisDarks({commit}, data) {
        commit("setisDarks", data)
    }
};

const mutations = {
    setzoommap: (state, zoom) => (state.zoom = zoom),
    setcentermap: (state, center) => (state.center = center),
    settypeMarkerdata: (state, typeMarker) => (state.typeMarker = typeMarker),
    setpointerlatlngdata: (state, pointerlatlng) => (state.pointerlatlng = pointerlatlng),
    setdrawerdatadata: (state, drawerdata) => (state.drawerdata = drawerdata),
    setvdowninfo_tr: (state, tr_vdowninfo) => (state.tr_vdowninfo = tr_vdowninfo),
    setWaveData: (state, wavedata) => (state.wave_data = wavedata),
    setsliderbmkg: (state, sliderbmkg) => (state.slider_bmkg = sliderbmkg),

    setSailink(state, sailink) {
        let sailinkArr = [];

        function setWithDelay(id) {
            if (id == sailink.length) {
                state.sailink_dataFetch = true;
                return;
            }
            // console.log("index dalam set with delay == "+id);
            sailinkArr.push(sailink[id]);
            state.sailink_locations = sailinkArr;
            // console.log(state.sailink_locations)
            setTimeout(() => {
                setWithDelay(parseInt(id) + 1);
            }, 1);
        }

        setWithDelay(0);
    },

    // setMarkerAll(state, thuraya, sailink)
    // {
    //   functionSailink()
    //   {
    //     if end (
    //       functionThuraya(0)
    //       return;
    //     )
    //   }

    //   functionThuraya()
    //   {
    //     if end (
    //       cek down();
    //       return
    //     )
    //   }

    //   functionSailink(0)
    // }

    setThuraya(state, thuraya) {
        (state.thuraya_locations = thuraya)
        let thurayaArr = [];

        function setWithDelay(id) {
            if (id == thuraya.length) {
                state.thuraya_dataFetch = true
                return;
            }
            // console.log("index dalam set with delay == "+id);
            thurayaArr.push(thuraya[id]);
            state.thuraya_locations = thurayaArr;
            // console.log(state.thuraya_locations)
            setTimeout(() => {
                setWithDelay(parseInt(id) + 1);
            }, 1);
        }

        setWithDelay(0);

    },

    vsatdown: (state, vsatdowndata) => (state.vsatdown_data = vsatdowndata),
    trackingOff: (state, trackingoffdata) =>
        (state.tracking_off = trackingoffdata),
    setToken: (state, token) => (state.set_token = token),
    setSailinkUpdate: (state, sailinkupdate) =>
        (state.sailink_locations_update = sailinkupdate),
    setSailinkPositionUpdate: (state, sailinkpositionupdate) =>
        (state.sailink_positions_update = sailinkpositionupdate),
    setThurayaPositionUpdate: (state, thurayapositionupdate) =>
        (state.thuraya_positions_update = thurayapositionupdate),
    setdrawerdataUp: (state, drawerdataUp) =>
        (state.drawerdataUp = drawerdataUp),
    setdrawerdataUpThur: (state, drawerdataUpThur) => (state.drawerdataUpThur = drawerdataUpThur),

    setSidebar: (state, sidebar) => (state.sidebar_vessel = sidebar),
    setsnap_inactive: (state, snap_inactive) => (state.snap_inactive = snap_inactive),
    setSatNet: (state, satnet) => (state.satnet = satnet),
    setWeather: (state, weather) => (state.weather = weather),
    setBlinking: (state, blinking) => (state.blinking = blinking),
    seteirp: (state, eirp) => (state.eirp = eirp),
    setgnt: (state, gnt) => (state.gnt = gnt),
    setSkyreach1Con: (state, Skyreach1Con) => (state.Skyreach1Con = Skyreach1Con),
    setSkyreach2Con: (state, Skyreach2Con) => (state.Skyreach2Con = Skyreach2Con),
    setApstar9Con: (state, Apstar9Con) => (state.Apstar9Con = Apstar9Con),
    setAbs2Con: (state, Abs2Con) => (state.Abs2Con = Abs2Con),
    setOceanBeamCon: (state, OceanBeamCon) => (state.OceanBeamCon = OceanBeamCon),
    setTotalVsat: (state, TotalVsat) => (state.TotalVsat = TotalVsat),
    setTotalOff: (state, TotalOff) => (state.TotalOff = TotalOff),
    setDays: (state, days) => (state.days = days),
    setOnline: (state, statusInternet) => (state.statusInternet = statusInternet),
    setdrawersdata: (state, drawers) => (state.drawers = drawers),
    settargetactivedata: (state, targetActive) =>
        (state.targetActive = targetActive),
    setbmkg_tr: (state, tr_bmkg) => (state.tr_bmkg = tr_bmkg),
    setlinfo_tr: (state, tr_linfo) => (state.tr_linfo = tr_linfo),
    setflabel_tr: (state, tr_flabel) => (state.tr_flabel = tr_flabel),
    setfalert_tr: (state, tr_falert) => (state.tr_falert = tr_falert),
    setEIRP_tr: (state, EIRP) => (state.EIRP = EIRP),
    setSKYR1_tr: (state, SKYR1) => (state.SKYR1 = SKYR1),
    setSKYR2_tr: (state, SKYR2) => (state.SKYR2 = SKYR2),
    setAPST9_tr: (state, APST9) => (state.APST9 = APST9),
    setABS2A_tr: (state, ABS2A) => (state.ABS2A = ABS2A),
    setSR1MR_tr: (state, SR1MR) => (state.SR1MR = SR1MR),
    setSR2MR_tr: (state, SR2MR) => (state.SR2MR = SR2MR),
    setBEAM3_tr: (state, BEAM3) => (state.BEAM3 = BEAM3),
    setUTL70B_tr: (state, UTL70B) => (state.UTL70B = UTL70B),
    setHRZ_tr: (state, HRZ) => (state.HRZ = HRZ),
    setGNT_tr: (state, GNT) => (state.GNT = GNT),
    setloading_map: (state, loading_map) => (state.loading_map = loading_map),
    setkondisi_Marker: (state, kondisi_Marker) =>
        (state.kondisi_Marker = kondisi_Marker),
    setzooMap_: (state, zoomMap_) => (state.zoomMap_ = zoomMap_),
    setcenterMapPeta_: (state, centerMapPeta_) =>
        (state.centerMapPeta_ = centerMapPeta_),
    setUserAllowed: (state, user_allowed) => (state.user_allowed = user_allowed),
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
    setisDarks: (state, isDarks) => (state.isDarks = isDarks),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
