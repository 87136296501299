<template>
  <v-app>
    <v-main :style="isDarks ? 'background-color:#050505' : 'background-color:#f4f4f4'">
      <section>

        <v-carousel height="fit-content" :show-arrows="false" class="pa-2"
                    hide-delimiter-background
                    delimiter-icon="mdi-minus" cycle>
          <v-carousel-item :style="isDarks ? 'border: solid 1px #050505;' : 'border: solid 1px #E0E0E0;'"
                           v-for="item in items" :key="item">
            <v-img
                max-height="560"
                max-width="100vw"
                :src="item.imagelink"

            ></v-img>
            <!-- <v-sheet
            :style="'background: url('+item.imagelink+');color:#ffff'"
          color="#EEEDFF"
          height="100%"
          
          tile
        > -->
            <!-- <v-row
              class="fill-height"
              align="center"
              justify="center"
            >
              <div class=" text-carousel">
                 <h2>{{ item.title }}</h2>
                          <p style="text-align: left">
                           {{item.text}}
                          </p>

                        <v-btn dark  target="_blank"
                                  :href="item.link" outlined small class="elevation-0 rounded-0">{{item.btn}}</v-btn>


              </div>
            </v-row> -->
            <!-- </v-sheet> -->
          </v-carousel-item>
        </v-carousel>
      </section>
      <section :style="isDarks ? 'background-color: #050505' :'background-color: #f4f4f4'">
        <v-layout column wrap class="my-5" align-center>
          <v-flex xs12>
            <v-container grid-list-xl style="margin-top:0">
              <v-layout row wrap align-center>
                <v-flex xs12 md4>
                  <v-card
                      elevation="0"
                      :style="isDarks ? 'border: solid 1px #1e1e1e;': 'border: solid 1px #E0E0E0;'"
                      class="mx-auto rounded-0"
                      max-width="344"
                      :color="isDarks ? '#1E1E1E' :'#EDECFF'"
                      hover
                      link
                      href=""
                  >
                    <v-img
                        @click="show = !show"
                        :src="skyway"
                        height="200px"
                    ></v-img>
                    <v-card-text :style="isDarks ? 'color:#fff' :'color:black'" @click="show = !show">
                      <v-card-title style="text-align: left">
                        SKYWAY<br/>(Land Fixed VSAT)
                      </v-card-title>
                      <v-card-subtitle>
                        <p style="text-align: left">
                          Internet Frekuensi C-Band atau Ku-Band
                        </p>
                      </v-card-subtitle>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn icon @click="show = !show">
                        <v-icon>{{
                            show ? "mdi-chevron-up" : "mdi-chevron-down"
                          }}
                        </v-icon>
                      </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                      <div v-show="show">
                        <v-divider></v-divider>

                        <v-card-text
                            :style="isDarks ? 'color:#fff;height: 20rem;flex-wrap: wrap;text-align: left;' : 'color:black;height: 20rem;flex-wrap: wrap;text-align: left;'"
                        >
                          SKYWAY (Land Fixed VSAT), pengalaman Skyreach dalam
                          instalasi & operasional Fixed VSAT di darat telah
                          terbukti pada banyak proyek sejak awal Skyreach
                          didirikan, baik itu pada pemerintahan, lembaga,
                          berbagai perusahaan, dan proyek lainnya. Menggunakan
                          SCPC atau TDMA pada jaringan terbaik C-Band atau
                          Ku-Band yang kami berikan, Skyreach mampu melakukan
                          instalasi & mempertahankan layanan prima di mana saja
                          hingga daerah terpencil sekalipun di seluruh cakupan
                          wilayah Indonesia.
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-row>
                            <!-- <v-col>
                              <v-btn
                                elevation="0"
                                target="_blank"
                                href="https://www.tokopedia.com/ptskyreach"
                                color="green"
                                dark
                                class="rounded-0"
                                block
                                ><img
                                  style="
                                    max-height: 15px;
                                    image-rendering: -webkit-optimize-contrast;
                                  "
                                  src="https://sailink.id/img/tokped.png"
                                  alt="tokopedia"
                                />&nbsp;&nbsp;Tokopedia</v-btn
                              >
                            </v-col>
                            <v-col>
                              <v-btn
                              class="rounded-0"
                                elevation="0"
                                target="_blank"
                                href="https://skyreach.id/kontak/"
                                color="primary"
                                dark
                                block
                                >Contact Us</v-btn
                              >
                            </v-col> -->
                          </v-row>
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card
                      elevation="0"
                      :style="isDarks ? 'border: solid 1px #1e1e1e;': 'border: solid 1px #E0E0E0;'"
                      class="mx-auto rounded-0"
                      max-width="344"
                      :color="isDarks ? '#1E1E1E' : '#EDECFF'"
                      hover
                      link
                      href=""
                  >
                    <v-img
                        @click="showdisc = !showdisc"
                        :src="discovery"
                        height="200px"
                    ></v-img>
                    <v-card-text :style="isDarks ? 'color:#fff' :'color:black'" @click="showdisc = !showdisc">
                      <v-card-title style="text-align: left">
                        Discovery<br/>(Land Mobility VSAT)
                      </v-card-title>
                      <v-card-subtitle>
                        <p style="text-align: left">
                          Internet Satellite News Gathering (SNG)
                        </p>
                      </v-card-subtitle>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn icon @click="showdisc = !showdisc">
                        <v-icon>{{
                            showdisc ? "mdi-chevron-up" : "mdi-chevron-down"
                          }}
                        </v-icon>
                      </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                      <div v-show="showdisc">
                        <v-divider></v-divider>

                        <v-card-text
                            :style="isDarks ? 'color:#fff;height: 20rem;flex-wrap: wrap;text-align: left;' : 'color:black;height: 20rem;flex-wrap: wrap;text-align: left;'"
                        >
                          Discovery (Land Mobility VSAT), Skyreach telah diakui
                          secara internasional untuk pengiriman teknologi Satcom
                          On The Move (SOTM) terbaru dalam proyek pemerintah &
                          militer. Layanan mobilitas darat harus memberikan
                          kemudahan bagi pengguna untuk ditempatkan di mana saja
                          & kapan saja dengan mudah.
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-row>
                            <!-- <v-col>
                              <v-btn
                              class="rounded-0"
                                elevation="0"
                                target="_blank"
                                href="https://www.tokopedia.com/ptskyreach"
                                color="green"
                                dark
                                block
                                ><img
                                  style="
                                    max-height: 15px;
                                    image-rendering: -webkit-optimize-contrast;
                                  "
                                  src="https://sailink.id/img/tokped.png"
                                  alt="tokopedia"
                                />&nbsp;&nbsp;Tokopedia</v-btn
                              >
                            </v-col>
                            <v-col>
                              <v-btn
                              class="rounded-0"
                                elevation="0"
                                target="_blank"
                                color="primary"
                                dark
                                block
                                href="https://skyreach.id/kontak/"
                                >Contact Us</v-btn
                              >
                            </v-col> -->
                          </v-row>
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                  </v-card>
                </v-flex>
                <v-flex xs12 md4>
                  <v-card
                      elevation="0"
                      :style="isDarks ? 'border: solid 1px #1e1e1e;': 'border: solid 1px #E0E0E0;'"
                      class="mx-auto rounded-0"
                      max-width="344"
                      :color="isDarks ? '#1E1E1E' :'#EDECFF'"
                      hover
                      link
                      href=""
                  >
                    <v-img
                        @click="showant = !showant"
                        :src="sailink"
                        height="200px"
                    ></v-img>
                    <v-card-text :style="isDarks ? 'color:#fff' :'color:black'" @click="showant = !showant">
                      <v-card-title style="text-align: left">
                        Antena VSAT <br/>Gyro SAILINK by Skyreach
                      </v-card-title>

                      <v-card-subtitle>
                        <p style="text-align: left">
                          Internet on Ship | CCTV VOIP Kapal
                        </p>
                      </v-card-subtitle>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>

                      <v-btn icon @click="showant = !showant">
                        <v-icon>{{
                            showant ? "mdi-chevron-up" : "mdi-chevron-down"
                          }}
                        </v-icon>
                      </v-btn>
                    </v-card-actions>

                    <v-expand-transition>
                      <div v-show="showant">
                        <v-divider></v-divider>
                        <v-card-text
                            :style="isDarks ? 'color:#fff;height: 20rem;flex-wrap: wrap;text-align: left;' : 'color:black;height: 20rem;flex-wrap: wrap;text-align: left;'"
                        >
                          SAILINK® (Maritime Connectivity) by Skyreach telah
                          berhasil menetapkan standar layanan konektivitas
                          maritim untuk pasar Indonesia hingga internasional dan
                          dipercayai langsung oleh beberapa perusahaan kapal
                          laut terbesar di Indonesia. Skyreach menyediakan
                          solusi L-Band sebagai pelengkap konektivitas maritim
                          SAILINK® dan berkomitmen untuk menjadi mitra utama
                          produk Thuraya MarineStar.
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-text>
                          <v-row>
                            <!-- <v-col>
                              <v-btn
                              class="rounded-0"
                                elevation="0"
                                target="_blank"
                                href="https://www.tokopedia.com/ptskyreach"
                                color="green"
                                dark
                                block
                                ><img
                                  style="
                                    max-height: 15px;
                                    image-rendering: -webkit-optimize-contrast;
                                  "
                                  src="https://sailink.id/img/tokped.png"
                                  alt="tokopedia"
                                />&nbsp;&nbsp;Tokopedia</v-btn
                              >
                            </v-col>
                            <v-col>
                              <v-btn
                              class="rounded-0"
                                elevation="0"
                                target="_blank"
                                href="https://skyreach.id/kontak/"
                                color="primary"
                                dark
                                block
                                >Contact Us</v-btn
                              >
                            </v-col> -->
                          </v-row>
                        </v-card-text>
                      </div>
                    </v-expand-transition>
                  </v-card>
                </v-flex>
              </v-layout>
            </v-container>
          </v-flex>
        </v-layout>
      </section>
      <v-footer dark padless>
        <v-card flat tile width="100%" class=" white--text text-center">
          <v-card-text>
            <v-img alt="platformName" class="ml-3" contain height="4em" position="center" :src="logo_skyreach"></v-img>
            <a target="_blank" href="https://www.facebook.com/skyreach.id/">
              <v-btn style="font-size: 35px ;" class="mx-4 rounded-0 elevation-0 " icon>
                <v-icon>mdi-facebook</v-icon>
              </v-btn>
            </a>
            <a target="_blank" href="https://www.youtube.com/channel/UCPB8S0ZrFnZnJj16x4P3_lQ">
              <v-btn style="font-size: 35px ;" class="mx-4 rounded-0 elevation-0 " icon>
                <v-icon>mdi-youtube</v-icon>
              </v-btn>
            </a>
            <a target="_blank" href="https://www.instagram.com/skyreachid/">
              <v-btn class="mx-4 rounded-0 elevation-0 " icon>
                <v-icon>mdi-instagram</v-icon>
              </v-btn>
            </a>
            <a target="_blank" href="https://www.linkedin.com/company/pt-skyreach/">
              <v-btn class="mx-4 rounded-0 elevation-0 " icon>
                <v-icon>mdi-linkedin</v-icon>
              </v-btn>
            </a>
          </v-card-text>

          <v-card-text style="margin-top:0" class="pt-0 text-left text-md-center">
            <v-expansion-panels accordion v-model="panel" multiple>

              <v-flex xs12>
                <v-container grid-list-xl>
                  <v-layout row wrap align-center>
                    <v-flex style="max-height:10em" xs12 md4>

                      <h3 class="d-none d-md-block py-2">Kantor Pusat</h3>
                      <li>
                        <a target="_blank" class="white--text" href="http://maps.google.com/?q= Hermina Tower">
                          Hermina Tower
                          Lantai 20, Suite A/B
                          Jl. HBR Motik No. 4, Kemayoran
                          DKI Jakarta - 10610</a>
                      </li>


                    </v-flex>

                    <v-flex style="max-height:10em" xs12 md4>

                      <h3 class="d-none d-md-block py-2">Kantor Cabang
                        Batam</h3>

                      <li>
                        <a target="_blank" class="white--text"
                           href="http://maps.google.com/?q= Jl. Komp. Bumi Indah Blok III No. 06 Batam">Jl. Komp. Bumi
                          Indah Blok III No. 06,
                          Nagoya Batam,
                          Kec. Lubuk Baja</a>
                      </li>


                    </v-flex>

                    <v-flex style="max-height:10em" xs12 md4>

                      <h3 class="d-none d-md-block py-2">Call Center</h3>

                      <li>

                        Phone: +6221 - 3 100 100

                      </li>
                      <li>
                        Email: info@skyreach.id
                      </li>


                    </v-flex>


                  </v-layout>
                </v-container>
              </v-flex>


            </v-expansion-panels>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-text class="white--text">
            Copyright SAILINK by Skyreach
          </v-card-text>
        </v-card>
      </v-footer>
      <!-- <FooterCompVue /> -->

    </v-main>
  </v-app>
</template>

<script>
// import FooterCompVue from "@/components/footers/FooterComp.vue";
import FooterFirstVue from "@/components/footers/FooterFirst.vue";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "App",
  components: {
    // FooterCompVue,
    // FooterFirstVue
  },
  data: function () {
    return {
      skyway: require("@/assets/img/skyway1.jpg"),
      sailink: require("@/assets/img/sailink.jpg"),
      sailink_dark: require("@/assets/img/sailink_dark.jpg"),
      discovery: require("@/assets/img/discovery.jpg"),
      model: 0,
      colors: ["primary", "secondary", "yellow darken-2", "red", "orange"],
      items: [
        {
          title: 'Skyreach Technology',
          src: require("@/assets/img/Untitled_1.53.1.jpg"),
          text: "The Skyreach development team managed to provide benefits and services without compromise so you don't have to worry about anything",
          btn: "See More",
          link: 'https://www.skyreach.id/teknologi/',
          imagelink: 'slider1.jpg'
        },
        {
          title: 'Improve Your Business',
          src: require("@/assets/img/Untitled_1.51.1.jpg"),
          text: 'We facilitate the best communication services and technology in various sectors to ensure you get the seamless connectivity',
          btn: 'See More',
          link: 'https://www.skyreach.id/industri/?sct=maritime',
          imagelink: 'slider2.jpg'
        },
        // {
        //   src: require("@/assets/img/Untitled_1.51.1.jpg"),
        //   text:'Untuk mendukung konektivitas pada sektor industri yang dituju, Skyreach menyadari untuk terus mempertahankan kualitas layanan dengan teknologi terbaik yang mempengaruhi kelangsungan bisnis pelanggan'
        // }
      ],
      show: false,
      showdisc: false,
      showant: false,
      title: "Endorfine",
      logo_skyreach: require("@/assets/img/Logo-Skyreach-White.png"),
      imageLink: {
        main: "https://sailink.id/img/bg3-slim.jpg",
        sub_main: "https://techbiz.id/wp-content/uploads/2019/09/SAILINK.jpg",
        logo: "https://sailink.id/img/sailink-only.png",
        social_cover:
            "https://www.skyreach.co.id/assets/img/carousel/41_teleporthosting/teleport_1_800x533.jpg",
      },
      email: "",
      emailRules: [
        (v) => {
          return !!v || "E-mail is required";
        },
        (v) =>
            /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
            "E-mail must be valid",
      ],
      subscribed: false,
    };
  },

  mounted() {
    // this.fetchUSERGET(localStorage.getItem("token"))
  },

  methods: {
    ...mapActions([
      "fetchUSERGET",]),

    subscribe: function () {
      this.subscribed = !this.subscribed;
    },

  },

  computed: {
    ...mapGetters([
      "isDarks"
    ]),
    imgHeight: function () {
      var offset = 320;
      console.log("new image height is " + (this.pageHeight - offset));
      return this.pageHeight - offset;
    },
  },

};
</script>

<style scoped>
.backgroundapp {
  background-color: #f4f4f4;
}

.backgroundapp_dark {
  background-color: #050505;
}

.finedTitle {
  font-weight: 900;
  text-shadow: 2px 2px #000000;
}

.social-icon {
  font-size: 21px;
  color: white;
}

.appny {
  top: 13vh;
}

.imgCarousel {
  height: 625px;
}

@media screen and (max-width: 2560px) {
  .appny {
    top: 5.5vh;
    height: 94.5vh;
  }

  .carousel {
    height: 70vh;
  }

  .imgCarousel {
    height: 70vh;
  }
}

@media screen and (max-width: 414px) {
  .appny {
    top: 8vh;
  }
}

@media screen and (max-width: 412px) {
  .appny {
    top: 8vh;
  }
}

@media screen and (max-width: 393px) {
  .appny {
    top: 7vh;
  }
}

@media screen and (max-width: 390px) {
  .appny {
    top: 8vh;
  }
}

@media screen and (max-width: 375px) {
  .appny {
    top: 10vh;
  }
}

@media screen and (max-width: 360px) {
  .appny {
    top: 9vh;
  }
}

.v-main__wrap {
  flex: 1 1 auto;
  max-width: 100%;
  position: relative;
  background-color: #fff;
}

.text-carousel {
  width: 50%;
  font-size: 0.8em;
  text-align: left
}

</style>
