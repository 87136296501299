<template>
  <Bar
    class="chartBar"
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from "vue-chartjs";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale
);

export default {
  name: "BarChart",
  components: { Bar },
  props: {
    dataChart: [],
    chartId: {
      type: String,
      default: "bar-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 500,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => {},
    },
    plugins: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const vm = this
    return {
      chartData: {
        labels: this.dataChart.labels,
        datasets: this.dataChart.datasets,
      },
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: "top",
          },
          title: {
            display: true,
            text:
              "Daily ( " +
              this.dataChart.startTime +
              " - " +
              this.dataChart.endTime +
              " )",
          },
          tooltip: {
                            callbacks: {
                                label: function(context) {
                                   
                                    let label = ' ' + context.dataset.label + ' : ' + context.parsed.y.toFixed(2) + ' ' + vm.dataChart.unit;
                                    return label;
                                }
                            },
                        },
        },
      
        scales: { 
                                
          y: {
             
              title: {
                  display: true,
                  font: {
                      weight: 'bold'
                  },
                  text: 'Traffic ('+this.dataChart.unit+')',
                  test: 'Berhasil',
              },
              stacked: true,
              ticks: {

               
                  callback: function(value, index, values) {
                    
                      return value + ' ' + vm.dataChart.unit;
                  }
              },
          },
        },
      },
    };
  },
};
</script>

<style>
.chartBar {
  display: block;
  box-sizing: border-box;
  height: 20em;
  width: 100%;
}
</style>
