<template>
    <v-card class="elevation-0 rounded-0 mt-0">
      <v-card
      id="title_map_rpmskeleton"
        color="#fff"
        class="ma-3 elevation-0 rounded-0 mt-0 pb-3"
        style="border: solid 1px #e0e0e0"
      >
        <v-card class="elevation-0 rounded-0" color="#fff">
          <v-toolbar
            color="#F7F7F7"
            elevation="0"
            dense
            class="elevation-0 rounded-0"
          >
          <v-toolbar-title> RPM Report </v-toolbar-title>
          </v-toolbar>
          <v-toolbar color="#F7F7F7" class="elevation-0 rounded-0" dense>
          Loading Vessel Name...
          </v-toolbar>
          <v-divider></v-divider>
          <v-card color="#F7F7F7"  style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-3">
            <v-toolbar color="#F7F7F7" class="elevation-0 rounded-0" dense>
              Loading Range...
              <v-spacer></v-spacer>
              <v-menu content-class="elevation-0 rounded-0" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  style="border: solid 1px #e0e0e0"
                  color="#fff"
                  class="elevation-0 rounded-0"
                  v-bind="attrs"
                  v-on="on"
                  small
                >
                  <i class="bx bx-time"></i>
                  &nbsp; &nbsp;
                  <v-text >---</v-text>
                  <!-- <v-card-text v-if="!rpmData">
                    <div class="snippet" data-title=".dot-pulse">
                      <div class="stage">
                        <div class="dot-pulse"></div>
                      </div>
                    </div>
                  </v-card-text> -->
                  &nbsp; &nbsp;
                  <i class="bx bxs-chevron-down"></i>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item>
                  <v-btn block plain small 
                    >Loading...</v-btn
                  >
                </v-list-item>
              
                
               
              </v-list>
            </v-menu>
            <v-btn
              style="cursor:not-allowed"
              class="mx-2 rounded-0 elevation-0"
              fab
              dark
              x-small
              color="primary"
            >
              <v-icon small dark> mdi-refresh </v-icon>
            </v-btn>  
          </v-toolbar>
  
            <v-card   style="border: solid 1px #e0e0e0;padding: 10px" class="elevation-0 rounded-0 ma-3" >
                <v-container
            id="map_sailink"
            fill-height
            fluid
            style="margin-top: 0; height: 20vh; "
          >
            <v-row align="center" justify="center">
              <v-col
                ><img
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
              /></v-col>
            </v-row>
          </v-container>
            </v-card>
  
            <v-card  style="border: solid 1px #e0e0e0;padding: 10px" class="elevation-0 rounded-0 ma-3" >
            
                <v-container
            id="map_sailink"
            fill-height
            fluid
            style="margin-top: 0; height: 20vh; "
          >
            <v-row align="center" justify="center">
              <v-col
                ><img
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
              /></v-col>
            </v-row>
          </v-container>
            </v-card>
  
            <v-card  style="border: solid 1px #e0e0e0;padding: 10px" class="elevation-0 rounded-0 ma-3" >
              <v-toolbar   style="border: solid 1px #e0e0e0" elevation="0" class="pa-0" color="#F7F7F7" fixed dense>
          
              
               
              </v-toolbar>
              <v-container
            id="map_sailink"
            fill-height
            fluid
            style="margin-top: 0; height: 20vh; "
          >
            <v-row align="center" justify="center">
              <v-col
                ><img
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
              /></v-col>
            </v-row>
          </v-container>
              <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
             
            </v-toolbar>
  
              
            </v-card>
          </v-card>
        </v-card>
      </v-card>
    
  
       
    </v-card>
  </template>
  
  <script>
  
  import { mapGetters, mapActions } from "vuex";
  import $ from "jquery";

  
  export default {

  
    data() {
      return {
        totalFilterOutput:null,
        time_Uptime:'22',
        dateopen: false,
        dates:[],
        datepickerID:null,
        loadernavplus: require("@/assets/img/load100new.gif"),
        option1: { name: "1" },
        option2: { name: "2" },
        dmap: null,
        dmaplat: null,
        dmaplon: null,
        dmapdata: [],
        zoom: 5,
        itemsPerPageOutput: 100,
        pageOutput: 1,
        pageCountOutput: 0,
        searchOutput: "",
      };
    },
    mounted() {
      
      //  this.$nextTick(() => {
      //   this.detailRPM()
      //  })
     
        $("html, body").animate(
          {
            scrollTop: $("#title_map_rpmskeleton").offset().top - 108,
          },
          800
        );
     
    },
   
  
    
  };
  </script>
  
  <style lang="scss" scoped></style>
  