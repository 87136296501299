<template>

  <app>
    <v-progress-linear
    v-if="loadingLogin"
    indeterminate
    :color="'primary'"
  ></v-progress-linear>
  <div :style="isDarks? 'background-color: #050505;height:100vh;padding:2em;padding-top:15vh' :'background-color: #F7F7F7;height:100vh;padding:2em;padding-top:15vh'" >

      <v-flex sm12 md4 offset-md4>
        <v-card :style="isDarks ? 'border: solid 1px #1e1e1e' :'border: solid 1px #e0e0e0'" :color="isDarks ? '#1e1e1e' : '#ffff'"   elevation="0" class="rounded-0" light tag="section" >
          <v-card-title>
            <v-layout align-center justify-space-between>
              <h3 class="headline"></h3>
              <v-flex>
                <v-img
                  alt="platformName"
                  class="ml-3"
                  contain
                  height="40px"
                  position="center"
                  :src="isDarks ? image_white : image"
                ></v-img>
              </v-flex>
            </v-layout>
          </v-card-title>
          <v-card-text>
            
            <p :style="isDarks ? 'color:#ffff' : 'color:black'">
              My Account. My personalized experience. sailink.id is your one
              destination which provides 24/7 access to all the services to
              help manage your vessels.
            </p>
            <v-form v-model="valid"  style="margin-top: 30px;padding-left:2em;padding-right:2em;padding-bottom:4em" @submit.prevent="handleSubmit"  lazy-validation>
              <v-text-field
              :dark ="isDarks ? true :false"
                class="rounded-0 "
                type="text"
                v-model="emailorname"
                placeholder="Email or Username"
                
                outlined
                :rules="nameRules"
                dense
                append-icon="mdi-account"
              />

              <v-text-field
              :dark ="isDarks ? true :false"
                class="rounded-0"
                style="margin-top: -0.2em"
                v-model="password"
                placeholder="Password"
                :rules="passRules"
                outlined
                :type="passhow ? 'text' : 'password'"
                @click:append="
            (passhow = !passhow)
          "
                dense
                :append-icon="passhow ? 'mdi-eye' : 'mdi-eye-off'"
              />

              <v-btn type="submit" v-if="valid" @click="handleSubmit" class="rounded-0" block color="primary" elevation="0" x-large
                  >Login</v-btn>
                  <v-btn disabled v-if="!valid"  class="rounded-0" block color="primary" elevation="0" x-large
                  >Login</v-btn>
              
              <AlertBox
                style="margin-top: 1em;"
                v-if="error"
                :message="error"
                :color="color"
                :icon="icon"
              />
               
            </v-form>
        
          </v-card-text>
        </v-card>
      </v-flex>
      <v-flex sm12 md4 offset-md4>
        <v-layout align-center justify-space-between>
          <p class="caption my-3">
            <router-link to="/privacy" >Privacy Policy</router-link>
          </p>
          <p class="caption my-3">Powered by <a href="https://www.skyreach.id/" target="_blank">PT Skyreach</a></p>
        </v-layout>
      </v-flex>
   
  </div>
</app>


</template>

<script>
import axios from "axios";
import AlertBox from "@/components/alert/AlertBox.vue";
import { mapGetters, mapActions } from "vuex";

export default {
name: "LoginComp",
components: {
  AlertBox,
},
data() {
  return {
    valid: false,
    passRules: [
      v => !!v || 'Password is required',
    ],
    nameRules: [
      v => !!v || 'Email or Username is required',
    ],
    passhow:false,
    loadingLogin:false,
    emailorname: "",
    password: "",
    image: require("@/assets/img/logo.svg"),
    image_white: require("@/assets/img/logo_white.svg"),
    error: "",
    color: "#FF0000",
    icon: "mdi-alert",
  };
},
methods: {
  ...mapActions([
    "setEditProfile","fetchUserData","setUserData","setsetloading_map"]),


  async handleSubmit() {
    this.loadingLogin=true
    this.error=false
    try {
      const response = await axios.post(
        "https://navigatorplus.sailink.id/api/login",
        {
          email: this.emailorname,
          password: this.password,
        }
      );
      //console.log(response);
      localStorage.setItem("token", response.data.token);
      localStorage.setItem("auth", true);
      this.setsetloading_map(true)
      this.loadingLogin=false
      this.$router.push("/navigatorplus");
      this.fetchUserData()
    } catch (e) {
      //console.log(e)
      this.loadingLogin=false
      this.error = 'invalid username/password';
      
    }
  },

  
},

computed: {
    ...mapGetters([
    "isDarks",])
  }
};
</script>

<style >
@import "@/assets/css/login.css";
a{
text-decoration: none;
}



.login_container{
background-color: #f4f4f4;width: 100vw;
}


.v-text-field input {
    flex: 1 1 auto;
    line-height: 20px;
    padding: 0.2em !important;
    max-width: 100%;
    min-width: 0px;
    width: 100%;
}
</style>
