<template>
  <v-app class="container_tabledata" color="#fff">
    <v-card id="top_iot" elevation="0">
      <v-alert v-if="OffIot" class="elevation-0 rounded-0" prominent type="warning" icon="mdi-web">
        <v-row align="center">
          <v-col class="grow">
            Connection timeout , please check your internet connection
          </v-col>
          <v-col class="shrink">
            <v-btn small @click="reload_page()" class="elevation-0 rounded-0">Reload</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-layout v-resize="onResize" column style="">
        <loadingTable v-if="!iotData" />
        <v-card v-if="iotData" class="ma-3 elevation-0 rounded-0" style="border: solid 1px #e0e0e0">
          <v-toolbar elevation="0" class="pa-0" color="#f7f7f7" fixed dense>
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0" color="#ffff"
                  v-bind="attrs" v-on="on">
                  {{ kFormatter(itemsPerPage)
                  }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item @click="itemsPerPage = 10"><v-list-item-title>10</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 25"><v-list-item-title>25</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 50"><v-list-item-title>50</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 100"><v-list-item-title>100</v-list-item-title></v-list-item>
              </v-list>
            </v-menu>
            <v-spacer style="width: 70%"></v-spacer>
            <v-text-field style="border: solid 1px #e0e0e0" width="40" v-model="search" hide-details
              prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
              class="elevation-0 rounded-0"></v-text-field>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table v-if="iotData" :search="showIOTData()" :headers="headers" style="margin-top: 0px" :items="iotData"
            hide-default-footer hide-default-header :page.sync="page" :items-per-page="itemsPerPage"
            class="elevation-0 rounded-0 dataTable desktopTable_iot" @page-count="pageCount = $event"
            @pagination="yourMethod">
            <template v-slot:header>
              <th class="pa-0 pt-2 pl-1" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                ">
                No
              </th>
              <th class="pa-0 pt-2 pl-1" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                ">
                Vessel Name
              </th>
              <th class="pa-0 pt-2 pl-1" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                ">
                Company
              </th>
              <th class="pa-0 pt-2 pl-1" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                ">
                Group
              </th>
              <th class="pa-0 pt-2 pl-1" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                ">
                Device State
              </th>
              <th class="pa-0 pt-2 pl-1" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                ">
                Current GPIO Status
              </th>
              <th class="pa-0 pt-2 pl-1" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                ">
                Manage
              </th>
            </template>
            <template v-slot:item="row">
              <tr>
                <td width="2%" class="pa-0 pl-1" style="white-space: nowrap; text-align: left">
                  {{ row.item.index + 1 }}
                </td>
                <td width="10%" class="pa-0 pl-1" style="white-space: nowrap; text-align: left">
                  {{ row.item.name }}
                </td>
                <td width="15%" class="pa-0 pl-1" style="white-space: wrap; text-align: left">
                  {{ row.item.pt_name }}
                </td>
                <td width="6%" class="pa-0 pl-1" style="white-space: wrap; text-align: left">
                  <div class="grouptxt" v-if="!row.item.pt_group">-</div>
                  <div v-if="row.item.pt_group">{{ row.item.pt_group }}</div>
                </td>
                <td width="10%" v-if="row.item.ae3.on && row.item.ae3.off" class="pa-0 pl-1"
                  style="white-space: nowrap; text-align: left">
                  <div class="devicestate">
                    <div>UP Since {{ row.item.ae3.on }}</div>
                    <div>DOWN at {{ row.item.ae3.off }}</div>
                  </div>
                </td>
                <td width="10%" v-if="!row.item.ae3.on && !row.item.ae3.off" class="pa-0 pl-1"
                  style="white-space: nowrap; text-align: left">
                  -
                </td>
                <td width="10%" class="pa-0 pl-1" style="white-space: nowrap; text-align: left">
                  <div v-if="row.item.devices.isGpio == true" class="gpiostatus">
                    <v-icon> mdi-lightning-bolt </v-icon>
                    <p class="txtgpio">CUBIC AC Power :</p>
                    <v-chip text-color="white" small v-if="row.item.ae5 == null || row.item.ae5"
                      class="mt-4 elevation-0 ml-2" color="green">PLUGGED</v-chip>
                    <v-chip text-color="white" small v-if="row.item.ae5 == false" class="mt-4 elevation-0 ml-2 blink"
                      color="red">UNPLUGGED</v-chip>
                  </div>

                  <div v-if="row.item.gpio_type == 'sailogger-rpm'" class="gpiostatus">
                    <v-icon> mdi-lightning-bolt </v-icon>
                    <p class="txtgpio">SAILOGGER AC Power :</p>
                    <v-chip text-color="white" small v-if="row.item.ae6" class="mt-4 elevation-0 ml-2"
                      color="green">PLUGGED</v-chip>
                    <v-chip text-color="white" small v-if="row.item.ae6 == false" class="mt-4 elevation-0 ml-2 blink"
                      color="red">UNPLUGGED</v-chip>
                  </div>
                </td>
                <td width="29%" class="pa-3" style="white-space: wrap; text-align: right">
                  <v-row>
                    <v-col cols="6"><v-btn width="100%" v-if="row.item.devices.isGpio" dark
                        @click="(gpioopen = true), sendDataGP(row.item)" color="primary" role="button"
                        class="elevation-0 rounded-0 ma-1" small>
                        <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-cog</v-icon>GPIO History</div>
                      </v-btn></v-col>
                    <v-col cols="6">
                      <v-btn v-if="row.item.devices.sailogger && (user_allowed.role_id <= 2)" width="100%"
                        @click="rbsailoggerFetch(row.item)" dark color="primary" role="button"
                        class="pa-0 elevation-0 rounded-0 ma-1" small>
                        <div style="font-size: 0.85em;" class=" pa-1"><v-icon class="mr-2"
                            x-small>mdi-wrench</v-icon>Reboot Sailogger</div>
                      </v-btn>
                      <v-btn v-if="row.item.devices.rpm" width="100%" @click="
                        addrpm_executed(false),
                        addiot_executed(false),
                        sendDataRPM(row.item.id, '24', false)
                        " dark color="#38C172" role="button" class="pa-0 elevation-0 rounded-0 ma-1" small>

                        <div style="font-size: 0.85em;" class="mr-1 pa-1"><v-icon class="mr-2"
                            x-small>mdi-speedometer</v-icon>Rpm Report</div>
                      </v-btn>
                      <v-btn width="100%" @click="
                        addrpm_executed(false),
                        addiot_executed(false),
                        sendDataRPM(
                          row.item.id,
                          '24',
                          row.item.devices.rpm_live
                        )
                        " v-if="row.item.devices.rpm_live" dark color="#38C172" role="button"
                        class="pa-0 elevation-0 rounded-0 ma-1" small>
                        <div style="font-size: 0.85em;" class="mr-1 pa-1"><v-icon class="mr-2"
                            x-small>mdi-speedometer</v-icon>Rpm Report</div>
                      </v-btn>
                      <v-btn width="100%" @click="
                        addexh_executed(false),
                        sendDataExhaust(row.item.id, '24')
                        " v-if="row.item.devices.exh" dark color="#38C172" role="button"
                        class="pa-0 elevation-0 rounded-0 ma-1" small>
                        <div class="pa-1 pr-0" style="height: 100%; ">
                          <v-icon x-small>mdi-thermometer-low</v-icon>
                        </div>
                        <div class="mr-1 pa-1 pl-0">Exhaust Report</div>
                      </v-btn>
                      <v-btn width="100%" @click="
                        addrpm_executed(false),
                        addiot_executed(false),
                        sendDataIOT(row.item.id, '24')
                        " v-if="row.item.devices.iot" dark color="#38C172" role="button"
                        class="pa-0 elevation-0 rounded-0 ma-1" small>
                        <div class="pa-1 pr-0" style="height: 100%">
                          <v-icon x-small>mdi-speedometer</v-icon><v-icon x-small>mdi-thermometer-low</v-icon>
                        </div>
                        <div class="mr-1 pa-1">IoT Report</div>
                      </v-btn>




                    </v-col>


                  </v-row>
                </td>
                <!-- <td
                  width="24%"
                  class="pa-0 pl-1 pr-2"
                  style="white-space: wrap; text-align: right"
                >
                {{ row.item.devices }}
                  
                 
                  
               
                </td> -->
              </tr>
            </template>
          </v-data-table>
          <v-data-table v-if="iotData" :search="showIOTData()" :headers="headers" style="margin-top: 0px" :items="iotData"
            hide-default-footer hide-default-header :page.sync="page" :items-per-page="itemsPerPage"
            class="elevation-0 rounded-0 mobileTable_iot" @page-count="pageCount = $event"
            @pagination="yourMethod"><template v-slot:item="row">
              <tr style="margin-top: 0.4em; margin-bottom: 0.4em">
                <td style="border-bottom: none; text-align: left">
                  <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" width="87vw">
                    <v-toolbar dense class="elevation-0 rounded-0">
                      <v-toolbar-subtitle>{{
                        row.item.index + 1
                      }}</v-toolbar-subtitle>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                      <table>
                        <tr>
                          <td>Vessel Name</td>
                          <td>:</td>
                          <td>{{ row.item.name }}</td>
                        </tr>
                        <tr>
                          <td>Company</td>
                          <td>:</td>
                          <td>{{ row.item.pt_name }}</td>
                        </tr>
                        <tr>
                          <td>Group</td>
                          <td>:</td>
                          <td>
                            <div class="grouptxt" v-if="!row.item.pt_group">
                              -
                            </div>
                            <div v-if="row.item.pt_group">
                              {{ row.item.pt_group }}
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Device State</td>
                          <td></td>
                          <td v-if="row.item.ae3.on && row.item.ae3.off">
                            <div class="devicestate">
                              <div>UP Since {{ row.item.ae3.on }}</div>
                              <div>DOWN at {{ row.item.ae3.off }}</div>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Current GPIO Status</td>
                          <td>:</td>
                          <td>

                            <div v-if="row.item.devices.isGpio == true" class="gpiostatus">
                              <v-icon x-small> mdi-lightning-bolt </v-icon>
                              <p style="font-size: 0.8em;" class="txtgpio">CUBIC AC Power :</p>
                              <v-chip style="font-size: 0.6em;" text-color="white" small
                                v-if="row.item.ae5 == null || row.item.ae5" class="mt-4 elevation-0 pl-3 ml-1"
                                color="green">PLUGGED</v-chip>
                              <v-chip style="font-size: 0.6em;" text-color="white" small v-if="row.item.ae5 == false"
                                class="mt-4 elevation-0 ml-1 pl-3 blink" color="red">UNPLUGGED</v-chip>
                            </div>
                            <div v-if="row.item.gpio_type == 'sailogger-rpm'" class="gpiostatus">
                              <v-icon x-small> mdi-lightning-bolt </v-icon>
                              <p style="font-size: 0.8em;" class="txtgpio">SAILOGGER AC Power :</p>
                              <v-chip style="font-size: 0.6em;" text-color="white" small v-if="row.item.ae6"
                                class="mt-4 elevation-0 pl-3 ml-1" color="green">PLUGGED</v-chip>
                              <v-chip style="font-size: 0.6em;" text-color="white" small v-if="row.item.ae6 == false"
                                class="mt-4 elevation-0 ml-1 pl-3 blink" color="red">UNPLUGGED</v-chip>
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3"><v-divider></v-divider></td>
                        </tr>
                        <tr>
                          <td colspan="3" class="pa-1">
                            <v-btn v-if="row.item.devices.isGpio == true" dark
                              @click="(gpioopen = true), sendDataGP(row.item)" color="primary" role="button"
                              class="mr-1 elevation-0 rounded-0" small>
                              <div style="font-size: 0.85em;">GPIO History</div>
                            </v-btn>
                            <v-btn @click="
                              addrpm_executed(false),
                              sendDataRPM(row.item.id, 'today')
                              " v-if="row.item.devices.rpm" dark color="#38C172" role="button"
                              class="pa-1 elevation-0 rounded-0" small>
                              RPM Report</v-btn>

                            <v-btn @click="
                              addiot_executed(false),
                              sendDataIOT(row.item.id, 'today')
                              " v-if="row.item.devices.iot" dark color="#38C172" role="button"
                              class="pa-1 elevation-0 rounded-0" small>
                              IoT Report</v-btn>

                            <v-btn @click="
                              addiot_executed(false),
                              sendDataExhaust(row.item.id, 'today')
                              " v-if="row.item.devices.exhaust" dark color="#38C172" role="button"
                              class="pa-1 elevation-0 rounded-0" small>
                              Exhaust Report</v-btn>
                          </td>
                        </tr>
                      </table>
                    </v-card-text>
                  </v-card>
                </td>
              </tr>
            </template></v-data-table>
          <v-divider></v-divider>
          <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">
            <p class="mt-3" style="font-size: 0.8em">
              Showing {{ pageStart }} to {{ pageStop }} of
              {{ totalFilter }} entries
            </p>
            <v-spacer></v-spacer>
            <v-pagination class="pt-2 pb-2" v-model="page" color="primary" :length="pageCount"
              :total-visible="7"></v-pagination>
          </v-toolbar>
        </v-card>
      </v-layout>
      <div id="bottom_table_iot"></div>
    </v-card>
    <!--Modal iot-->
    <v-dialog class="elevation-0 rounded-0" v-model="gpioopen" width="800px">
      <v-card class="elevation-0 rounded-0 pb-1">
        <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" style="text-align: left" dense>
          IOT History for AC Power Plugged/Unplugged

          <v-spacer></v-spacer>

          <v-btn small class="elevation-0 rounded-0" icon @click="(notifopen = false), delGPIO()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-toolbar v-if="dataGPIO" class="elevation-0 rounded-0" color="#FFFF" dense>
          {{ dataGPIO.vessel_number }}

          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider v-if="dataGPIO"></v-divider>
        <v-container style="margin-top: 0" v-if="!dataGPIO" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
          </v-row>
        </v-container>
        <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-3" v-if="dataGPIO">
          <v-toolbar elevation="0" class="pa-0" color="#f7f7f7" fixed dense>
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0" color="#ffff"
                  v-bind="attrs" v-on="on">
                  {{ kFormatter(itemsPerPageGPIO)
                  }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item @click="itemsPerPageGPIO = 5"><v-list-item-title>5</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPageGPIO = 10"><v-list-item-title>10</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPageGPIO = 15"><v-list-item-title>15</v-list-item-title></v-list-item>
                <v-list-item
                  @click="itemsPerPageGPIO = Output.length"><v-list-item-title>All</v-list-item-title></v-list-item>
              </v-list>
            </v-menu>
            <v-spacer style="width: 70%"></v-spacer>

            <v-text-field style="border: solid 1px #e0e0e0" width="40" v-model="searchGPIO" hide-details
              prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
              class="elevation-0 rounded-0"></v-text-field>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table style="margin-top: 0px" :headers="headersGPIO" :items="Output" hide-default-footer
            :page.sync="pageGPIO" :items-per-page="itemsPerPageGPIO" class="elevation-0 rounded-0 dataTable"
            @page-count="pageCountGPIO = $event">
            <template v-slot:item="row">
              <tr>
                <td style="text-align:left">{{ row.item.index + 1 }}</td>
                <td style="text-align:left">{{ row.item.type }}</td>
                <td style="text-align:left">
                  <v-chip small v-if="row.item.status == true
                    " class="mt-4 elevation-0" color="green" text-color="white">{{ row.item.label }}</v-chip>
                  <v-chip small v-if="row.item.status == false
                    " class="mt-4 elevation-0 " text-color="white" color="red">{{ row.item.label }}</v-chip>
                </td>
                <td style="text-align:left">{{ row.item.dateTime_local }}</td>
              </tr>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
            <p class="mt-3" style="font-size: 0.8em">
              Showing {{ showingDatafrom }} to {{ showingDatato }} of
              {{ totalFilter }} entries
            </p>
            <v-spacer></v-spacer>
            <v-pagination v-model="pageGPIO" color="primary" :length="pageCountGPIO" :total-visible="7"></v-pagination>
          </v-toolbar>
        </v-card>
      </v-card>
    </v-dialog>

    <!--Modal IOT End-->
    <RpmShowSkeleton v-if="!rpmData && rpmShow" />
    <IotShowSkeleton v-if="!iotData_ && iotShow_" />
    <ExhShowSkeleton v-if="!exhData && exhShow" />
    <RpmShowVue v-if="rpmData" />
    <IotShowVue v-if="iotData_" />
    <ExhShowVue v-if="exhData" />
    <RebootSailoggerModal v-if="rbsOpen" />
  </v-app>
</template>

<script>
import $ from "jquery";
import axios from "axios";
import * as easings from "vuetify/lib/services/goto/easing-patterns";
import RpmShowVue from "@/components/showpage/RpmShow.vue";
import IotShowVue from "@/components/showpage/IoTShow.vue";
import ExhShowVue from "@/components/showpage/ExhaustShow.vue";
import { mapGetters, mapActions } from "vuex";
import loadingTable from "@/components/progress/loadingTable.vue";
import RpmShowSkeleton from "@/components/skeleton/RpmShowSkeleton.vue";
import IotShowSkeleton from "@/components/skeleton/RpmShowSkeleton.vue";
import ExhShowSkeleton from "@/components/skeleton/RpmShowSkeleton.vue";
import RebootSailoggerModal from "@/components/modals/reboot_sailogger_modal.vue"

// import ModalSkeleton from '@/components/modal/ModalSkeleton.vue'
export default {
  components: {
    ExhShowVue,
    RpmShowVue,
    IotShowVue,
    loadingTable,
    RpmShowSkeleton,
    IotShowSkeleton,
    ExhShowSkeleton,
    RebootSailoggerModal
    // ModalSkeleton
  },
  data() {
    return {
      headersGPIO: [
        {
          text: "#",
          align: "start",
          sortable: false,
        },
        { text: "GPIO Type", value: "index", sortable: false },
        { text: "Status", value: "type", sortable: false },
        { text: "dateTime(GMT+7)", value: "dateTime_local", sortable: false },
      ],
      pageStart: 0,
      pageStop: 0,
      rpmshowclick: false,
      gpioopen: false,
      dataGPIO: null,
      rpmOutput: null,
      itemsPerPage: 10,
      page: 1,
      searchGPIO: null,
      pageCount: 0,
      itemsPerPageGPIO: 10,
      pageGPIO: 1,
      pageCountGPIO: 0,
      Output: null,
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Vessel Name", value: "name" },
        { text: "Company", value: "pt_name" },
        { text: "Group", value: "pt_group" },
        { text: "Device State", value: "ae3" },
        { text: "Current GPIO Status", value: "ae5" },
        { text: "Manage", value: "rpm_search" },
      ],
      // in real case initial search_result = [], and methods: search function inject below data
      iot: null,
      selectedItem: "",
      selectedNotif: "",
      search: "",
      showmaptable: "",
      index: "",
      loadernavplus: require("@/assets/img/load100new.gif"),
      duration: 300,
      offset: 0,
      easing: "easeInOutCubic",
      easings: Object.keys(easings),
      totalFilter: null,
    };
  },
  async created() {
    this.fetchIot(localStorage.getItem("token"));
    console.log('RPM ID NYA ADA')
    console.log(this.rpmreportID);
    if (this.rpmreportID) {
      this.sendDataRPM(this.rpmreportID, "24", false);
    }
  },

  mounted() {
    this.fetchUSERGET(localStorage.getItem("token"));
    // this.$root.$on("sendDataRPM", (id, range) => {
    //   this.sendDataRPM(id, range);
    // });
  },

  methods: {
    ...mapActions([
      "removeExhShow()",
      "addexh_executed",
      "fetchExhaustShow",
      "removeIotShow",
      "fetchIot",
      "fetchRpm",
      "removeSearchIOT",
      "removeRpmShow",
      "removeExhShow",
      "addiot_executed",
      "addrpm_executed",
      "fetchUSERGET",
      "addRpmLive",
      "fetchIotShow",
      "rbsModal",
      "rbsFetch"

    ]),

    //RebootFetch
    rbsailoggerFetch(item) {
      console.log(item)
      this.rbsModal(true);
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.rbsFetch(data);
    },

    reload_page() {
      window.location.reload();
    },

    delGPIO() {
      this.gpioopen = false;
      this.dataGPIO = null;
    },

    yourMethod(pagination) {
      this.totalFilter = pagination.itemsLength;
      this.pageStart = pagination.pageStart + 1
      this.pageStop = pagination.pageStop
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    nulsearch() {
      this.removeSearchIOT();
    },

    showIOTData() {
      if (this.searchIOT) {
        return this.searchIOT;
      } else {
        return this.search;
      }
    },

    sendDataRPM(id, range, isRpmLive) {
      console.log("JALANIN INI");
      console.log(isRpmLive);
      if (!this.rpmreportID) {
        $("html, body").animate(
          {
            scrollTop: $("#bottom_table_iot").offset().top,
          },
          1000
        );
      }

      this.removeRpmShow();
      this.removeIotShow();
      this.removeExhShow();
      this.addRpmLive(isRpmLive);
      console.log(id);
      // this.$vuetify.goTo(this.target, this.options);
      const data = {
        id: id,
        range: range,
        token: localStorage.getItem("token"),
      };
      this.fetchRpm(data);
    },

    sendDataIOT(id, range) {
      console.log("JALANIN IOT");
      $("html, body").animate(
        {
          scrollTop: $("#bottom_table_iot").offset().top,
        },
        1000
      );
      this.removeRpmShow();
      this.removeIotShow();
      this.removeExhShow();
      // this.addRpmLive(isRpmLive);
      console.log(id);
      // this.$vuetify.goTo(this.target, this.options);
      const data = {
        id: id,
        range: range,
        token: localStorage.getItem("token"),
      };
      this.fetchIotShow(data);
    },

    sendDataExhaust(id, range) {
      //   console.log("JALANIN IOT");
      $('html, body').animate({
        scrollTop: $("#bottom_table_iot").offset().top
      }, 1000);
      this.removeRpmShow();
      this.removeIotShow();
      this.removeExhShow()
      // this.addRpmLive(isRpmLive);
      console.log(id);
      // this.$vuetify.goTo(this.target, this.options);
      const data = {
        id: id,
        range: range,
        token: localStorage.getItem("token"),
      };
      this.fetchExhaustShow(data);
    },

    async sendDataGP(item) {
      const response = await axios.get(
        "https://navigatorplus.sailink.id/api/v1/navplus/iot/" + item.id,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      console.log(response);
      this.dataGPIO = response.data.data;
      this.Output = this.dataGPIO.output.map((item, index) =>
        Object.assign(item, { index })
      );
    },

    async sendDataNotif(item) {
      const response = await axios.get(
        "https://navigatorplus.sailink.id/api/v1/navplus/sailink/mrtg/" +
        item.id +
        "/" +
        item.mrtg_id,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      this.selectedNotif = response.data.data;
    },
  },
  computed: {
    ...mapGetters([
      "rbsOpen",
      "exhData",
      "exhShow",
      "exh_executed",
      "OffIot",
      "iotData",
      "rpmreportID",
      "searchIOT",
      "rpmShow",
      "rpmData",
      "iotShow_",
      "iotData_",
      "user_allowed"
    ]),
    target() {
      return 62000000;
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      };
    },

    showingDatafrom() {
      if (this.totalFilter < 1) {
        return 0;
      } else {
        return 1;
      }
    },

    showingDatato() {
      if (this.totalFilter < this.itemsPerPage) {
        return this.totalFilter;
      } else {
        return this.itemsPerPage;
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/data.scss";

.gpiostatus {
  display: flex;
}

.txtgpio {
  margin-top: 15px;
}

.devicestate {
  display: block;
}

.blink {
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

@media (min-width: 280px) and (max-width: 319px) {
  .mobileTable_iot {
    display: block;
  }

  .desktopTable_iot {
    display: none;
  }
}

/* Mobile Devices */
@media (min-width: 320px) and (max-width: 480px) {
  .mobileTable_iot {
    display: block;
  }

  .desktopTable_iot {
    display: none;
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .mobileTable_iot {
    display: none;
  }

  .desktopTable_iot {
    display: block;
  }
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .mobileTable_iot {
    display: none;
  }

  .desktopTable_iot {
    display: block;
  }
}

/* Desktops, large screens */
@media (min-width: 1025px) and (max-width: 1200px) {
  .mobileTable_iot {
    display: none;
  }

  .desktopTable_iot {
    display: block;
  }
}

/* Extra large screens / TV */
@media screen and (min-width: 1201px) {
  .mobileTable_iot {
    display: none;
  }

  .desktopTable_iot {
    display: block;
  }
}
</style>
