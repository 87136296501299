import axios from "../../plugins/axios";

const getDefaultState = () => {
    return {
        admin_data: null,
        crownerData: null,
        ownerName: null,
        ownerVessel: null,
        sailinkvessel_: null,
        OffAdmin:null,
        ownerData: [
            {
                name: "",
                mail: "",
                pts: [],
                pass: "",
                confirmpass: "",
            },
        ],
        cuserData: [
            {
                name: "",
                mail: "",
                vessels: [],
                pass: "",
                confirmpass: "",
            },
        ],
        ownerMail: null,
        ownerPTS: [],
        ownerPass: null,
        ownerconfirmpass: null,
        premisDataPost: [
            {
                permissions: [],
                name: "",
            },
        ],
        companyDataPost: [
            {
                vessels: [
                    {name: 'No Vessel Yet'}
                ],
            },
        ],
        cruserOpen: null,
        premisOpen: null,
        premisData: null,
        companyOpen: null,
        companyData: null,
        crownerOpen: null,
    }
}

const state = getDefaultState();

const getters = {
    adminData: (state) => state.admin_data,
    crownerData: (state) => state.crownerData,
    ownerName: (state) => state.ownerName,
    ownerMail: (state) => state.ownerMail,
    ownerPTS: (state) => state.ownerPTS,
    ownerPass: (state) => state.ownerPass,
    ownerconfirmpass: (state) => state.ownerconfirmpass,
    ownerData: (state) => state.ownerData,
    cuserData: (state) => state.cuserData,
    premisDataPost: (state) => state.premisDataPost,
    companyDataPost: (state) => state.companyDataPost,
    ownerVessel: (state) => state.ownerVessel,
    sailinkvessel_: (state) => state.sailinkvessel_,
    cruserOpen: (state) => state.cruserOpen,
    premisOpen: (state) => state.premisOpen,
    premisData: (state) => state.premisData,
    companyOpen: (state) => state.companyOpen,
    companyData: (state) => state.companyData,
    crownerOpen: (state) => state.crownerOpen,
    OffAdmin:(state)=>state.OffAdmin
};

const actions = {
    async fetchAdmin({commit}, data) {
        //console.log("FETCH ADMIN");
        try{
            const response = await axios.get(
                "/api/v1/navplus/user",
                {
                    headers: {
                        Authorization: "Bearer" + data,
                    },
                }
            );
            commit(
                "setAdmin",
                response.data.data.users.map((item, index) =>
                    Object.assign(item, {index})
                )
            );
            console.log(response);
            commit("setOffAdmin",null)
        }catch(e){
            commit("setOffAdmin",true)
            console.log(e);
        }
        
    },

    set_sailink_Vessel_({commit}, data) {
        //console.log("VESSEL SAILINK SET");
        //console.log(data);
        commit("setsailinkvessel_", data);
    },

    async fetchCreateOwner({commit}, data) {
        commit("setcrownerOpen", true)
        const response = await axios.get(
            "/api/v1/navplus/user/createOwner",
            {
                headers: {
                    Authorization: "Bearer" + data,
                },
            }
        );
        //console.log(response);
        //console.log(commit);
        commit("setcrownerData", response.data.data);
    },

    setcruserOpen({commit}) {
        //console.log("CREATE OPEN");
        commit("setcruserOpen_", true);
    },

    removecruserOpen({commit}) {
        commit("setcruserOpen_", false);
        commit("setownerVessel", null);
    },

    removecrownerOpen({commit}) {
        commit("setcrownerOpen", false);
    },

    async fetchCreateUser({commit}, data) {
        const response = await axios.get(
            "/api/v1/navplus/user/createUser",
            {
                headers: {
                    Authorization: "Bearer" + data,
                },
            }
        );
        //console.log(response.data.data.owners);
        //console.log(commit);
        commit("setownerVessel", response.data.data.owners);
    },

    async fetchPermission({commit}, data) {
        commit("setpremisData", null);
        commit("setpremisOpen", true);
        const response = await axios.get(
            "/api/v1/navplus/user/edit/permission/" +
            data.id,
            {
                headers: {
                    Authorization: "Bearer" + data.token,
                },
            }
        );
        commit("setpremisData", response.data.data);
    },


    async fetchCompany({commit}, data) {
        commit("setcompanyOpen", true)
        commit("setcompanyData", null);
        const response = await axios.get(
            "/api/v1/navplus/user/edit/vessels/" +
            data.id,
            {
                headers: {
                    Authorization: "Bearer" + data.token,
                },
            }
        );
        //console.log(response);
        commit("setcompanyData", response.data);

    },

    removepermission({commit}) {
        commit("setpremisData", null);
        commit("setpremisOpen", null);
    },

    removeCompany({commit}) {
        commit("setcompanyOpen", null);
        commit("setcompanyData", null);
    },

    async postCreateOwner({commit}, data) {
        //console.log("MASUK POST CREATE OWNER");
        const dataOwner = {
            name: data.name,
            email: data.email,
            password: data.password,
            role: data.role,
            pts: data.pts,
            password_confirmation: data.password_confirmation,
        };
        const response = await axios.post(
            "/api/v1/navplus/user/register",
            dataOwner,
            {
                headers: {
                    Authorization: "Bearer" + data.token,
                },
            }
        );
        //console.log(response);
        //console.log(commit);
        commit("setAdmin", null);
        const responseadmin = await axios.get(
            "/api/v1/navplus/user",
            {
                headers: {
                    Authorization: "Bearer" + data,
                },
            }
        );
        commit(
            "setAdmin",
            responseadmin.data.data.users.map((item, index) =>
                Object.assign(item, {index})
            )
        );
    },

    async postCreateUser({commit}, data) {
        //console.log("MASUK POST CREATE USER");
        const dataUser = {
            name: data.name,
            email: data.email,
            password: data.password,
            role: data.role,
            vessels: data.vessels,
            owner: data.owner,
            password_confirmation: data.password_confirmation,
        };
        const response = await axios.post(
            "/api/v1/navplus/user/register",
            dataUser,
            {
                headers: {
                    Authorization: "Bearer" + data.token,
                },
            }
        );
        //console.log(response);
        //console.log(commit);
        commit("setAdmin", null);
    },

    async postUserPremis({commit}, data) {
        //console.log("PREMIS POST");
        //console.log(data);
        commit("setAdmin", null);
        const dataOwner = {
            permissions: data.permissions,
        };
        const response = await axios.post(
            "/api/v1/navplus/user/edit/permission/" + data.id,
            dataOwner,
            {
                headers: {
                    Authorization: "Bearer" + data.token,
                },
            }
        );
        //console.log(response);
        //console.log(commit);
    },

    async postCompany({commit}, data) {
        const data1 = {
            role: data.role,
            items: data.items,
        };

        const response = await axios.post(
            "/api/v1/navplus/user/edit/vessels/" +
            data.id, data1,
            {
                headers: {
                    Authorization: "Bearer" + data.token,
                },
            }
        );
        //console.log(commit)
        //console.log(response);
        commit("setAdmin", null);
    },

    resetAdminState({commit}) {
        commit("resetState");
    },
};

const mutations = {
    setAdmin: (state, admin) => (state.admin_data = admin),
    setOffAdmin:(state, OffAdmin) => (state.OffAdmin = OffAdmin),
    setcrownerData: (state, crownerData) => (state.crownerData = crownerData),
    setownerName: (state, ownerName) => (state.ownerName = ownerName),
    setpremisdatapost: (state, premisDataPost) =>
        (state.premisDataPost = premisDataPost),
    setownerVessel: (state, ownerVessel) => (state.ownerVessel = ownerVessel),
    setsailinkvessel_: (state, sailinkvessel_) =>
        (state.sailinkvessel_ = sailinkvessel_),
    setcruserOpen_: (state, cruserOpen) => (state.cruserOpen = cruserOpen),
    setcuserData: (state, cuserData) => (state.cuserData = cuserData),
    setpremisOpen: (state, premisOpen) => (state.premisOpen = premisOpen),
    setpremisData: (state, premisData) => (state.premisData = premisData),
    setcompanyData: (state, companyData) => (state.companyData = companyData),
    setcompanyOpen: (state, companyOpen) => (state.companyOpen = companyOpen),
    setcrownerOpen: (state, crownerOpen) => (state.crownerOpen = crownerOpen),
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
