<template>
  <v-card class="elevation-0 mt-0 rounded-0">
    <v-card id="title_manifest" color="#ffff" class=" ma-3 elevation-0 rounded-0 mt-0 pb-3"
      style="border: solid 1px #e0e0e0">
      <v-toolbar color="#F7F7F7" dense class="elevation-0 rounded-0">
        <v-toolbar-title class="desktop_showmap">Manifest Report</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn class="mx-2 rounded-0 elevation-0" fab x-small color="#f7f7f7" @click="removeShowManifest()">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>

      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar color="#fff" dense class="elevation-0 rounded-0">
        {{ showmanifest.vessel_name }}
        <v-spacer></v-spacer>
        <v-menu content-class="elevation-0 rounded-0" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn style="border: solid 1px #e0e0e0" color="#fff" class="elevation-0 rounded-0" v-bind="attrs" v-on="on"
              small>
              <i class="bx bx-time"></i>
              &nbsp; &nbsp;
              <v-text v-if="showmanifest.range.range">{{
                showmanifest.range.range
              }}</v-text>
              <v-card-text v-if="!showmanifest.range.range">
                <div class="snippet" data-title=".dot-pulse">
                  <div class="stage">
                    <div class="dot-pulse"></div>
                  </div>
                </div>
              </v-card-text>
              &nbsp; &nbsp;
              <i class="bx bxs-chevron-down"></i>
            </v-btn>
          </template>
          <v-list style="border: solid 1px #e0e0e0">
            <v-list-item>
              <v-btn block plain small @click="showManifest('today')">Today</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn block plain small @click="showManifest('week')">This Week</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn block plain small @click="showManifest('month')">This Month</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn block plain small @click="showManifest('lmonth')">Last Month</v-btn>
            </v-list-item>
            <v-list-item>
              <v-btn block plain small @click="dateopen = true">Custom Range</v-btn>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-divider></v-divider>
      <v-card-text v-if="showmanifest">

        <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0"
          v-if="showmanifest.graph.data.length != 0">
          <ManifestChart v-bind:datachart="showmanifest.graph" v-bind:modalapp="shomanifest" />
        </v-card>

        <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0"
          v-if="showmanifest.graph.data.length == 0">
          <ManifestChartEmpty v-bind:datachart="showmanifest.graph" v-bind:modalapp="shomanifest" />
        </v-card>

        <v-data-table style="border: solid 1px #e0e0e0" :headers="headerM" :items="outputM" :items-per-page="5"
          class="elevation-0 rounded-0 mt-3 dataTable">
          <template v-slot:item="row">
            <tr>
              <td>{{ row.item.index + 1 }}</td>
              <td v-for="item in showmanifest.fish" :key="item">
                {{ row.item[item] }}
              </td>
            </tr>
          </template>
        </v-data-table>


        <v-data-table style="border: solid 1px #e0e0e0;" :items="showmanifest.gmaps" :items-per-page="15"
          class="elevation-0 rounded-0 mt-3">
          <template v-slot:body="{ items }" style="">
            <tbody style=" display: flex !important;
  white-space: initial;
  flex-wrap: wrap;
  text-align: center;
  width: 120vw;
  padding: 1em 1em 0.5em 0.5em;
  flex-direction: row !important;">
              <tr v-for="item in items" :key="item.id">
                <td style="padding:1em;background-color: #ffff;">

                  <v-card width="300" class="elevation-0 mt-0 rounded-0" style="border: solid 1px #e0e0e0">
                    <v-card-text>{{ item.dateTime }}</v-card-text>
                    <v-row :align="align" no-gutters>
                      <v-col sm="12">
                        <v-card class="elevation-0 rounded-0">
                          <l-map ref="myMap" style="height:20em;" :zoom="8"
                            :center="[parseFloat(item.lat), parseFloat(item.lng)]"
                            :options="{ minZoom: 1, zoomControl: false }">
                            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                            <l-control-fullscreen position="bottomright" :options="{ title: { false: 'Go big!', true: 'Be regular' } }" />
                            <l-marker :lat-lng="[parseFloat(item.lat), parseFloat(item.lng)]"></l-marker>
                          </l-map>
                        </v-card>
                      </v-col>
                      <v-col sm="12">
                        <v-card class="elevation-0 rounded-0 ">
                          <!-- {{ row.item.fishes }} -->
                          <v-data-table dense :headers="headersShowManifest" :items="item.fishes" hide-default-footer
                            class="elevation-0 rounded-0 dataTable">
                            <template v-slot:item="row">
              <tr>
                <td style="text-align: left;">
                  {{ row.item.type }}
                </td>
                <td style="text-align: left;">
                  {{ row.item.amount }}
                </td>

              </tr>
          </template>
        </v-data-table>



    </v-card></v-col>

    </v-row>



  </v-card>




  </td>
  </tr>
  </tbody>
  </template>
  </v-data-table>
  </v-card-text>

  <v-card-actions class="justify-end"> </v-card-actions>
  </v-card>
  <!--Modal Custom Range-->
  <v-dialog ref="dialog" v-model="dateopen" :return-value.sync="dates" class="elevation-0 rounded-0" persistent
    width="290px">
    <v-date-picker color="#0B4281" class="elevation-0 rounded-0" v-model="dates" range scrollable>
      <v-spacer></v-spacer>
      <v-btn text color="primary" @click="dateopen = false">
        Cancel
      </v-btn>
      <v-btn text color="primary" @click="customRange()">
        OK
      </v-btn>
    </v-date-picker>
  </v-dialog>
  <!--Modal Custom Range-->
  </v-card>
</template>

<script>
import ManifestChart from "../charts/ManifestChart.vue";
import ManifestChartEmpty from "../charts/ManifestChartEmpty.vue";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import $ from "jquery";
import {
  LMap,
  LTileLayer,
  LMarker
} from "vue2-leaflet";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    ManifestChart,
    ManifestChartEmpty,
    LControlFullscreen
  },
  data() {
    return {
      url: "https://maps.skyreach.id/hot/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      dates: [],
      output: [
        {
          Kerapu: 0,
          Cakalang: 0,
          Bawal: 0,
          Tongkol: 0,
          "Kerapu Bintang": 0,
        },
        {
          Kerapu: 11,
          Cakalang: 29,
          Bawal: 13,
          Tongkol: 15,
          "Kerapu Bintang": 16,
        },
      ],

      headersShowManifest: [
        { text: "Item", value: "type" },
        { text: "Amount", value: "amount" },
      ],
      dateopen: null,
      loadernavplus: require("@/assets/img/load100new.gif"),
    };
  },
  mounted() {
    var mama = this;
    if (!mama.manifest_executed) {
      mama.addmanifest_executed(true);
      $("html, body").animate(
        {
          scrollTop: $("#title_manifest").offset().top - 109,
        },
        800
      );
    }
  },
  methods: {
    ...mapActions(["fetchShowManifest", "removeShowManifest", "addmanifest_executed"]),
    customRange() {
      this.dateopen = false
      var range_date = this.dates.join(" ");
      console.log(range_date);
      this.showManifest(range_date);
    },
    showManifest(range) {
      const data = {
        range: range,
        thuraya_number: this.thurNumber,
        token: localStorage.getItem("token"),
      };
      this.fetchShowManifest(data);
      this.pushtry();

      // this.headerManifest.push({ text: "Name", value: "name", sortable: false });
    },
    pushtry() {
      for (var i = 0; i < this.showmanifest.fish.length; i++) {
        // if (this.showmanifest.fish[i] == 1) ;a.push(5);
        console.log("DARI MODAL MANIFEST");
        console.log(this.showmanifest.fish[i]);
      }
    },
  },
  computed: {
    ...mapGetters([
      "manifestData",
      "showmanifest",
      "thurNumber",
      "headerM",
      "outputM",
      "showmanifestclick",
      "clickmanifest",
      "manifest_executed"
    ]),
  },
};
</script>

<style lang="scss" scoped>
image tbody {
  display: flex;
  white-space: initial;
  flex-wrap: wrap;
  text-align: center;
  width: 93em;
  padding: 1em 1em 0.5em 0.5em;
}


.innertable tbody {
  display: block !important;
}
</style>
