<template>
    <v-dialog v-model="rbOpen" class="elevation-0 rounded-0" width="550" persistent>
    <v-card class="elevation-0 rounded-0">
      <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0" dense >
        Thuraya Reboot Command

        <v-spacer></v-spacer>

        <v-btn class="rounded-0 elevation-0" small icon  @click="closeRBM()">
          <v-icon >mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar v-if="rbData" class="elevation-0 rounded-0" >
      
        <v-toolbar-subtitle style="text-align: left"
          >{{ rbData.vessel_number }}
          <br />
          <div v-if="rbData" style="text-align: left">
            <v-chip
              v-if="rbData.latest_status == 'FAILED'"
              class="ma-0 elevation-0 rounded-0"
              color="red"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-close-thick </v-icon>
              Failed to change Settings at {{ rbData.last_updated }}, please
              resubmit
            </v-chip>
            <v-chip
              v-if="rbData.latest_status == 'ON AIR'"
              class="ma-0 elevation-0 rounded-0"
              color="yellow"
              x-small
              label
              text-color="black"
            >
              <v-icon x-small left> mdi-clock </v-icon>
              Waiting for Confirmation, sent at {{ rbData.last_updated }}
            </v-chip>
            <v-chip
              v-if="rbData.latest_status == 'CONFIRMED'"
              class="ma-0 elevation-0 rounded-0"
              color="green"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-check-bold </v-icon>
              Updated at {{ rbData.last_updated }}
            </v-chip>
          </div>
        </v-toolbar-subtitle>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider v-if="rbData"></v-divider>
      <v-container style="margin-top: 2em" v-if="!rbData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>
      <v-card-text v-if="rbData"  style="margin-top:2em">
        <v-btn v-if="rbData.latest_status != 'ON AIR'" small :style="rbData.latest_status == 'ON AIR'?'cursor:not-allowed':'cursor:pointer'" :disabled="false" class="elevation-0 rounded-0" @click="rbDataPost()" color="primary" block>
          Send Reboot Command
        </v-btn>
        <v-btn v-if="rbData.latest_status == 'ON AIR'" small :style="rbData.latest_status == 'ON AIR'?'cursor:not-allowed':'cursor:pointer'" :disabled="true" class="elevation-0 rounded-0" color="primary" block>
          Send Reboot Command
        </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>
 </template>
 
 <script>
 import { mapGetters, mapActions } from "vuex";
 import axios from "../../plugins/axios";
 export default {
   data() {
     return {
       rbmodal:null,
       change: null,
     
       loadernavplus: require("@/assets/img/load100new.gif"),
       fetchInterval: null,
     };
   },
   mounted() {
    this.rbFetchStatic()

   },
   methods: {
     ...mapActions(["rbFetch", "rbPost", "rbRemove"]),

     async rbDataPost() {  
        const data = {
          thuraya_number: this.rbData.number,
          token:localStorage.getItem("token"),
        };       
        this.rbPost(data)    
       var mother = this;
       var intervalSeconds = 0.5;
       this.fetchInterval = setInterval(function () {
         mother.rbDataFetch();
       }, intervalSeconds * 1000);
     },
 
     stopFetch() {
       console.log("STOP FETCH STATUS REBOOT THURAYA");
       clearInterval(this.fetchInterval);
       this.on = false;
     },

     closeRBM(){
      this.rbRemove()
      this.stopFetch()
     },
 
     async rbDataFetch() {
       console.log("FETCH TERUS STATUS REBOOT THURAYA");
       console.log("STATUS :");
       console.log(this.rbData.latest_status);
       // this.wlModal(true);
       const data = {
         id: this.rbID,
         token: localStorage.getItem("token"),
       };
       this.rbFetch(data);
       var mother=this
       setTimeout(function () {
      

       if (mother.rbData) {
          if (mother.rbData.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.rbFetchStatic();
          }
        } else {
          if (mother.rbmodal.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.rbFetchStatic();
          }
        }
     }, 5000);
      
     },

     async rbFetchStatic() {
      try {
        const response = await axios.get(
            "https://navigatorplus.sailink.id/api/v1/navplus/thuraya/" +
            this.rbID +
              "/reboot",
            {
              headers: {
                Authorization: "Bearer" + localStorage.getItem("token"),
              },
            }
          );
        console.log(response)
        this.rbmodal = response.data.data;
        var mother = this;
        var intervalSeconds = 0.5;
        if (response.data.data.latest_status == "ON AIR") {
          this.fetchInterval = setInterval(function () {
            mother.rbDataFetch();
          }, intervalSeconds * 1000);
        }
      } catch (e) {
        console.log(e);
      }
    },
   },
 
   computed: {
     ...mapGetters(["rbData", "rbID", "rbOpen","user_allowed"]),
    //  changes_() {
    //   return this.change;
    // },
   },
 };
 </script>
 
 <style lang="scss" scoped></style>
 


