import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import LoginView from "@/views/LoginView.vue";
import NavigatorView from "@/views/NavigatorView.vue";
import MySailinkView from "@/views/MySailinkView.vue";
import ReportView from "@/views/ReportView.vue";
import SnapshotView from "@/views/SnapshotView.vue";
import MyThurayaView from "@/views/MyThurayaView.vue";
import ManifestView from "@/views/ManifestView.vue";
import SailoggerView from "@/views/SailoggerView.vue";
import CrewwatchView from "@/views/CrewwatchView.vue";
import AdminView from "@/views/AdminView.vue";
import OutsideLogin from "@/views/OutsideLogin.vue";
import PrivacyPolicy from "../views/PrivacyView.vue";
import SummaryView from "../views/SummaryView.vue";
import OrbCommView from "@/views/OrbCommView.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
        meta: {
            auth: true,
            title: "SAILINK :: All in One Maritime Solutions",
            icon: "/lock.png",
            navbar: false,
        },
    },
    {
        path: "/index.html",
        name: "home-index",
        component: HomeView,
        meta: {
            auth: true,
            title: "SAILINK :: All in One Maritime Solutions",
            icon: "/lock.png",
            navbar: false,
        },
    },
    {
        path: "/login",
        name: "login",
        component: LoginView,
        meta: {
            auth: true,
            title: "SAILINK :: Login",
            navbar: false,
        },
    },
    {
        path: "/navigatorplus",
        name: "navigatorplus",
        component: NavigatorView,
        meta: {
            auth: true,
            title: "Navigator+ :: Dashboard",
            navbar: true,
        },
    },
    {
        path: "/mysailink",
        name: "mysailink",
        component: MySailinkView,
        meta: {
            auth: true,
            title: "Navigator+ :: Dashboard",
            navbar: true,
        },
    },
    {
        path: "/reports",
        name: "reports",
        component: ReportView,
        meta: {
            auth: true,
            title: "Navigator+ :: Dashboard",
            navbar: true,
        },
    },
    {
        path: "/snapshot",
        name: "snapshot+",
        component: SnapshotView,
        meta: {
            auth: true,
            title: "Navigator+ :: Dashboard",
            navbar: true,
        },
    },
    {
        path: "/mythuraya",
        name: "mythuraya",
        component: MyThurayaView,
        meta: {
            auth: true,
            title: "Navigator+ :: Dashboard",
            navbar: true,
        },
    },
    {
        path: "/manifest",
        name: "manifest",
        component: ManifestView,
        meta: {
            auth: true,
            title: "Navigator+ :: Dashboard",
            navbar: true,
        },
    },
    {
        path: "/sailogger",
        name: "sailogger",
        component: SailoggerView,
        meta: {
            auth: true,
            title: "Navigator+ :: Dashboard",
            navbar: true,
        },
    },
    {
        path: "/orbcomm",
        name: "orbcomm",
        component: OrbCommView,
        meta: {
            auth: true,
            title: "Navigator+ :: Dashboard",
            navbar: true,
        },
    },
    {
        path: "/crewwatch",
        name: "crewwatch",
        component: CrewwatchView,
        meta: {
            auth: true,
            title: "Navigator+ :: Dashboard",
            navbar: true,
        },
    },
    {
        path: "/admin",
        name: "admin",
        component: AdminView,
        meta: {
            auth: true,
            title: "Navigator+ :: Dashboard",
            navbar: true,
        },
    },
    {
        path: "/privacy",
        name: "privacy",
        component: PrivacyPolicy,
        props: true,
        meta: {
            title: "Privacy and Policy",
            navbar: false,
        },
    },
    {
        path: "/Summary",
        name: "summary",
        component: SummaryView,
        props: true,
        meta: {
            auth: true,
            title: "Navigator+ :: Dashboard",
            navbar: true,
        },
    },
    {
        path: "/outsidelogin/:token",
        name: "outsidelogin",
        component: OutsideLogin,
        props: true,
        meta: {
            title: "Login",
            navbar: false,
        },
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

router.beforeEach((to, form, next) => {
    const isAuthenticated = JSON.parse(localStorage.getItem("auth"));
    if (to.name !== "login" && to.name !== "home" && to.name !== "privacy" && to.name !== "outsidelogin" && !isAuthenticated) {
        next({name: "home"});
        window.location.reload();
    }
    if (to.name === "login" && isAuthenticated) next({name: "home"});
    else next();
});
export default router;
