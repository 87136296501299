<template>
  <v-app>
    <NavigationBar/>
    <router-view :style="isServices ? 'margin-top:6em' : 'margin-top:3em'"/>
  </v-app>
</template>

<script>
import NavigationBar from "./views/NavigationBar.vue";
import {mapGetters} from "vuex";

export default {
  name: "App",
  components: {
    NavigationBar,
  },
  data() {
    return {
      loadernavplus: require("@/assets/img/load100new.gif"),
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        document.title = to.meta.title || 'Some Default Title';
      }
    },
  },
  computed: {
    ...mapGetters([
      "loading_map",
    ]),
    isServices() {
      return this.$route.meta.navbar;
    },
  }
};
</script>

<style>
@import "@/assets/css/main.css";

:root {
  --leaflet: #AAD3DF;
  --appwrap: #f7f7f7;
  --aback: #ffff;
  --acolor: #232323;
  --tfield: #ffff
}

/* .v-input__slot{
  background-color:var(--tfield) !important;
} */

/* .text-input-blue .v-text-field__slot input {
   color: rgb(255, 255, 255) !important;
} */

.leaflet-container {
  background: var(--leaflet) !important;
  outline-offset: 1px;
}

.v-application--wrap {
  background-color: var(--appwrap) !important;
}

.v-text-field.v-text-field--solo .v-input__control {
  min-height: 0px !important;
  padding: 0;
}

.leaflet-bar a {
  background-color: var(--aback) !important;
  border-bottom: 0px solid #ccc !important;
  color: var(--acolor) !important;
}

.leaflet-control-fullscreen-button .leaflet-bar-part {
  color: #ffff !important;
}
</style>
