<template>
     <v-dialog v-model="mnOpen" width="700px" persistent>
      <v-card  class="elevation-0 rounded-0" style="overflow-y: hidden">
        <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0" dense >
          Manifest Listing Config

          <v-spacer></v-spacer>

          <v-btn small class="elevation-0 rounded-0" icon  @click="closeManifest()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-toolbar v-if="mnData"  class="elevation-0 rounded-0" color="#fff" >
          <v-toolbar-subtitle style="text-align: left"
          >{{ mnData.vessel_number }}
          <br />
          <div v-if="mnData" style="text-align: left">
            <v-chip
              v-if="mnData.latest_status == 'FAILED'"
              class="ma-0 elevation-0 rounded-0"
              color="red"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-close-thick </v-icon>
              Failed to change Settings at {{ mnData.last_updated }}, please
              resubmit
            </v-chip>
            <v-chip
              v-if="mnData.latest_status == 'ON AIR'"
              class="ma-0 elevation-0 rounded-0"
              color="yellow"
              x-small
              label
              text-color="black"
            >
              <v-icon x-small left> mdi-clock </v-icon>
              Waiting for Confirmation, sent at {{ mnData.last_updated }}
            </v-chip>
            <v-chip
              v-if="mnData.latest_status == 'CONFIRMED'"
              class="ma-0 elevation-0 rounded-0"
              color="green"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-check-bold </v-icon>
              Updated at {{ mnData.last_updated }}
            </v-chip>
          </div>
        </v-toolbar-subtitle>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider v-if="mnData"></v-divider>
        <v-container style="margin-top: 2em" v-if="!mnmodal" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>
        <v-card-text
        v-if="mnmodal"
        id="div_mn"
        :style="mnData ? 'height:450px' : ''"
        width="100"
        class="overflow-y-auto"
      >
      <v-container style="margin-top:2em" v-if="!mnData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>
        <v-card-text
          v-if="
            mnData && mnData.latest_content && mnData.latest_status != 'ON AIR'
          "
          style="margin-top: 55px"
        >
          <v-row no-gutters style="flex-wrap: nowrap">
            <v-col
              cols="1"
              style="min-width: 9rem; max-width: 40%; margin: 5px"
            >
              <v-row v-for="(item, i) in mnData.latest_content" :key="i">
                <v-col style="margin-top: -35px">
                  <v-text-field
                    class="elevation-0 rounded-0"
                    :value="'Type ' + (i + 1)"
                    filled
                    outlined
                    readonly
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="1"
              style="min-width: 100px; max-width: 100%; margin: 5px"
              class="flex-grow-1 flex-shrink-0"
            >
              <v-row v-for="(item, i) in mnData.latest_content" :key="i">
                <v-col style="margin-top: -35px">
                  <v-text-field
                  @input="checkdata(i)"
                  v-if="user_allowed.allowed['edit listing']"
                    class="elevation-0 rounded-0"
                    v-model="mnData.latest_content[i]"
                    :value="item"
                    :placeholder="'Name ' + (i+1)"
                    
                    outlined
                    dense
                  ></v-text-field>
                  <v-text-field
                  @input="checkdata(i)"
                  v-if="!user_allowed.allowed['edit listing']"
                    class="elevation-0 rounded-0"
                    v-model="mnData.latest_content[i]"
                    :value="item"
                    :placeholder="'Name ' + (i = i + 1)"
                    
                    outlined
                    dense
                    disabled
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
  
          <v-btn
          :disabled="changes_ ? false : true"
          small
            v-if="user_allowed.allowed['edit listing']"
            class="elevation-0 rounded-0"
            style="margin-top: -15px"
            @click="mnDataPost()"
            color="primary"
            block
          >
            Update Vessel Listing Configuration
          </v-btn>
          <v-btn
          disabled
            small
            class="elevation-0 rounded-0"
            v-if="!user_allowed.allowed['edit listing']"
            style="margin-top: -15px;cursor:not-allowed"
            color="primary"
            block
          >
            Update Vessel Listing Configuration
          </v-btn>
        </v-card-text>
        <v-card-text
          v-if="
            mnData && mnData.latest_content && mnData.latest_status == 'ON AIR'
          "
          style="margin-top: 55px"
        >
          <v-row no-gutters style="flex-wrap: nowrap">
            <v-col
              cols="1"
              style="min-width: 9rem; max-width: 40%; margin: 5px"
            >
              <v-row v-for="(item, i) in mnData.latest_content" :key="i">
                <v-col style="margin-top: -35px">
                  <v-text-field
                  class="elevation-0 rounded-0"
                    :value="'Type ' + (i = i + 1)"
                    filled
                    outlined
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              cols="1"
              style="min-width: 100px; max-width: 100%; margin: 5px"
              class="flex-grow-1 flex-shrink-0"
            >
              <v-row v-for="(item, i) in mnData.latest_content" :key="i">
                <v-col style="margin-top: -35px">
                  <v-text-field
                  class="elevation-0 rounded-0"
                    v-model="mnData.latest_content[i]"
                    :value="item"
                    :placeholder="'Name ' + (i = i + 1)"
                    filled
                    outlined
                    disabled
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-btn small disabled  class="elevation-0 rounded-0" style="margin-top: -15px;cursor:not-allowed" color="primary"  block>
            Update Vessel Listing Configuration
          </v-btn>
        </v-card-text>
    </v-card-text>
        
      </v-card>
    </v-dialog>
  </template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  import axios from "../../plugins/axios";
  import $ from "jquery";
  export default {
    data() {
      return {
        mnmodal:null,
        change:null,
        loadernavplus: require("@/assets/img/load100new.gif"),
        fetchInterval: null,
      };
    },
    mounted() {
      console.log('Manifest Listing Data')
      console.log(this.mnData)
      this.mnFetchStatic()

    },
    methods: {
      ...mapActions(["mnFetch", "mnPost", "mnRemove"]),

      checkdata(value) {
      // console.log(value)
      // console.log(this.mnmodal["latest_content"][value]);
      // console.log(this.mnData['latest_content'][value]);
      if (this.mnmodal["latest_content"][value] == this.mnData.latest_content[value]) {
        this.change = false;
      } else {
        this.change = true;
      }
    },

      async mnDataPost() {
        $("#div_mn").scrollTop(0);

        const data = {
        thuraya_number: this.mnData.number,
        types: this.mnData.latest_content,
        token: localStorage.getItem("token"),
      };
  
        this.mnPost(data);
  
        var mother = this;
        var intervalSeconds = 0.5;
        this.fetchInterval = setInterval(function () {
          mother.mnDataFetch();
        }, intervalSeconds * 1000);
      },
  
      stopFetch() {
        console.log("STOP FETCH STATUS WHITELIST");
        clearInterval(this.fetchInterval);
        this.on = false;
      },

      closeManifest(){
        this.stopFetch()
        this.mnRemove()
      },
  
      async mnDataFetch() {
        console.log("FETCH TERUS STATUS MANIFEST");
        console.log("STATUS :");
        console.log(this.mnData.latest_status);
        // this.wlModal(true);
        const data = {
          id: this.mnID,
          token: localStorage.getItem("token"),
        };
        this.mnFetch(data);
        var mother=this
        setTimeout(function () {
          if (mother.mnData) {
          if (mother.mnData.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.mnFetchStatic();
          }
        } else {
          if (mother.mnmodal.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.mnFetchStatic();
          }
        }

       
      }, 5000);
       
      },

      async mnFetchStatic() {
      try {
        const response = await axios.get(
            "https://navigatorplus.sailink.id/api/v1/navplus/thuraya/" +
            this.mnID +
              "/listing",
            {
              headers: {
                Authorization: "Bearer" + localStorage.getItem("token"),
              },
            }
          );

       
        this.mnmodal = response.data.data;
        var mother = this;
        var intervalSeconds = 0.5;
        if (response.data.data.latest_status == "ON AIR") {
          this.fetchInterval = setInterval(function () {
            mother.mnDataFetch();
          }, intervalSeconds * 1000);
        }
      } catch (e) {
        console.log(e);
      }
    },
    },
  
    computed: {
      ...mapGetters(["mnData", "mnID", "mnOpen","user_allowed"]),
      changes_() {
      return this.change;
    },
    },
  };
  </script>
  
  <style lang="scss" scoped></style>
  