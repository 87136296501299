<template>
  <v-app>
    <v-dialog
      class="elevation-0 rounded-0"
      v-model="premisOpen"
      width="80vh"
      persistent
    >
      <v-card class="elevation-0 rounded-0">
        <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense>
          <v-col class="header_modal">
            <v-row class="notifquota"
              ><v-toolbar-title>Permission</v-toolbar-title></v-row
            >
          </v-col>

          <v-spacer></v-spacer>

          <v-btn
            small
            class="rounded-0 elevation-0"
            icon
            @click="close_Premis_()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>

        <v-card-text style="margin-top: 20px">
          <v-card
            style="border: 1px solid #9e9e9e"
            height="fit-content"
            class="elevation-0 rounded-0 pa-0 pb-0"
          >
            <v-toolbar
              class="elevation-0 rounded-0"
              height="30"
              dense
              color="#f7f7f7"
            >
              <v-toolbar-subtitle>Navigator+ Permissions</v-toolbar-subtitle>
            </v-toolbar>
            <v-divider></v-divider>
            <v-card-text>
              <v-progress-linear
                v-if="!premisData"
                indeterminate
                color="#1F7AD3"
                height="6px"
              ></v-progress-linear>

              <v-select
                class="elevation-0 rounded-0 pa-0 ma-2 mb-0"
                @change="add_changePremis_active()"
                v-if="premisData"
                v-model="premisData.allowed"
                :items="premisData.permissions_fitur"
                :value="items"
                item-text="name"
                item-value="id"
                :menu-props="{ maxHeight: '400' }"
                label="Select one or more premission"
                multiple
                persistent-hint
              ></v-select>
            </v-card-text>
          </v-card>
        </v-card-text>

        <v-card-actions v-if="premisData" style="margin-top: -10px">
          <v-btn
            
            small
            class="elevation-0 rounded-0"
            :disabled="changePremis_active?false:true"
            :color="'primary'"
            :style="
              changePremis_active ? 'cursor:pointer' : 'cursor:not-allowed'
            "
            block
            @click="
              changePremis_active
                ? (premis_confirm = true)
                : console.log('empty')
            "
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      class="rounded-0 elevation-0"
      v-model="premis_confirm"
      persistent
      max-width="290"
    >
      <v-card v-if="premisData" class="rounded-0 elevation-0">
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text style="text-align: left"
          >Permission for {{ premisData.user.email }} will be
          update</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="premis_confirm = false"> No </v-btn>
          <v-btn
            color="green"
            text
            @click="postPremis(premisData.user.id), close_Premis_()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      changePremis_active: null,
      premis_confirm: null,
    };
  },
  methods: {
    ...mapActions(["postUserPremis", "removepermission", "fetchAdmin"]),
    close_Premis_() {
      this.removepermission();
      this.changePremis_active = null;
      this.premis_confirm = null;
    },
    add_changePremis_active() {
      this.changePremis_active = true;
    },
    postPremis(id) {
      const dataPremis = {
        id: id,
        permissions: this.premisData.allowed,
        token: localStorage.getItem("token"),
      };
      this.postUserPremis(dataPremis);
      var mother = this;
      setTimeout(function () {
        mother.fetchAdmin(localStorage.getItem("token"));
      }, 5000);
    },
  },
  computed: {
    ...mapGetters(["premisOpen", "premisData", "premisDataPost"]),
  },
};
</script>

<style lang="scss" scoped></style>
