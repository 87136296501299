<template>
  <v-card class="elevation-0 mt-0 rounded-0">
    <v-card
      id="title_manifest"
      color="#ffff"
      class="ma-3 elevation-0 rounded-0 mt-0 pb-3"
      style="border: solid 1px #e0e0e0"
    >
      <v-toolbar color="#F7F7F7" dense class="elevation-0 rounded-0">
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar color="#fff" dense class="elevation-0 rounded-0"> </v-toolbar>
      <v-divider></v-divider>
      <v-card-text>
        <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0">
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col
                ><img
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
              /></v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0">
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col
                ><img
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
              /></v-col>
            </v-row>
          </v-container>
        </v-card>

        <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0">
          <v-container fill-height fluid>
            <v-row align="center" justify="center">
              <v-col
                ><img
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
              /></v-col>
            </v-row>
          </v-container>
        </v-card>
        <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0">
        </v-card>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script>
import ManifestChart from "../charts/ManifestChart.vue";
import ManifestChartEmpty from "../charts/ManifestChartEmpty.vue";
import $ from "jquery";

export default {
  data() {
    return {
      loadernavplus: require("@/assets/img/load100new.gif"),
    };
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
image tbody {
  display: flex;
  white-space: initial;
  flex-wrap: wrap;
  text-align: center;
  width: 93em;
  padding: 1em 1em 0.5em 0.5em;
}
</style>
