<template>
  <div>
  <v-dialog
    class="elevation-0 rounded-0"
    v-model="sosOpen"
    width="650px"
    persistent
  >
    <v-card class="elevation-0 rounded-0" style="overflow-y: hidden">
      <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">
      SOS Contact List
        <v-spacer></v-spacer>

        <v-btn class="elevation-0 rounded-0" small icon @click="closeSos()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar v-if="sosData" color="#fff" class="elevation-0 rounded-0">
        <v-toolbar-subtitle style="text-align: left"
          >{{ sosData.vessel_number }}
          <br />
          <div v-if="sosData" style="text-align: left">
            <v-chip
              v-if="sosData.latest_status == 'FAILED'"
              class="ma-0 elevation-0 rounded-0"
              color="red"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-close-thick </v-icon>
              Failed to change Settings at {{ sosData.last_updated }}, please
              resubmit
            </v-chip>
            <v-chip
              v-if="sosData.latest_status == 'ON AIR'"
              class="ma-0 elevation-0 rounded-0"
              color="yellow"
              x-small
              label
              text-color="black"
            >
              <v-icon x-small left> mdi-clock </v-icon>
              Waiting for Confirmation, sent at {{ sosData.last_updated }}
            </v-chip>
            <v-chip
              v-if="sosData.latest_status == 'CONFIRMED'"
              class="ma-0 elevation-0 rounded-0"
              color="green"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-check-bold </v-icon>
              Updated at {{ sosData.last_updated }}
            </v-chip>
          </div>
        </v-toolbar-subtitle>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider v-if="sosData"></v-divider>

      <v-container style="margin-top: 0" v-if="!sosmodal" fill-height fluid>
        <v-row align="center" justify="center">
          <v-col
            ><img
              alt="Navplus logo"
              width="70px"
              :src="loadernavplus"
              class="justify-center"
          /></v-col>
        </v-row>
      </v-container>

      <v-card-text
        id="div_sos"
        v-if="sosmodal"
        :style="sosData ? 'height:450px' : ''"
        width="100"
        class="overflow-y-auto"
      >
        <v-container style="margin-top: 0" v-if="!sosData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>
        <div v-if="sosData">
          <div
            style="
              display: flex;
              padding: 2em;
              margin-top: -3em;
              margin-left: -2.3em;
            "
          >
            <v-switch
              style="margin-left: 0"
              v-if="sosData && user_allowed.allowed['edit sos contact']"
              v-model="sosData.sosTestEnabled"
              @change="checkEnabled()"
              hide-details
              inset
              :label="
                sosData.sosTestEnabled
                  ? `SOS Test [Enabled]`
                  : `SOS Test [Disabled]`
              "
            ></v-switch>
            <v-switch
              style="margin-left: 0"
              v-if="sosData && !user_allowed.allowed['edit sos contact']"
              disabled
              v-model="sosData.sosTestEnabled"
              hide-details
              inset
              :label="
                sosData.sosTestEnabled == null
                  ? `SOS Test [Disabled]`
                  : `SOS Test [Enabled]`
              "
            ></v-switch>
            <v-spacer></v-spacer>
          </div>
          <div
            v-if="sosData.latest_status !== 'ON AIR'"
            style="margin-top: 15px"
          >
            <v-row no-gutters style="flex-wrap: nowrap">
              <v-col
                cols="1"
                style="min-width: 9rem; max-width: 40%; margin: 5px"
              >
                <v-row>
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      value="Phone 1"
                      filled
                      outlined
                      persistent-hint
                      readonly
                      dense
                      hint="E.g. +6281317772131"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="1"
                style="min-width: 100px; max-width: 100%; margin: 5px"
                class="flex-grow-1 flex-shrink-0"
              >
                <v-row>
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      value="SKYREACH"
                      :placeholder="'Contact Name ' + (a = i + 2)"
                      filled
                      outlined
                      readonly
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="1"
                style="min-width: 100px; max-width: 100%; margin: 5px"
                class="flex-grow-1 flex-shrink-0"
              >
                <v-row>
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      value="1005"
                      :placeholder="'Phone Number ' + (a = i + 2)"
                      filled
                      outlined
                      readonly
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters style="flex-wrap: nowrap; margin-top: 30px">
              <v-col
                cols="1"
                style="min-width: 9rem; max-width: 40%; margin: 5px"
              >
                <v-row v-for="(item, i) in sosData.latest_phone" :key="i">
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      :value="'Phone ' + (a = i + 2)"
                      outlined
                      readonly
                      filled
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="1"
                style="min-width: 100px; max-width: 100%; margin: 5px"
                class="flex-grow-1 flex-shrink-0"
              >
                <v-row v-for="(item, i) in sosData.latest_name" :key="i">
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      v-if="user_allowed.allowed['edit sos contact']"
                      @input="checkdata('latest_name', i)"
                      v-model="sosData.latest_name[i]"
                      :value="item"
                      :placeholder="'Contact Name ' + (a = i + 2)"
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="elevation-0 rounded-0"
                      v-if="!user_allowed.allowed['edit sos contact']"
                      @input="checkdata('latest_name', i)"
                      v-model="sosData.latest_name[i]"
                      :value="item"
                      :placeholder="'Contact Name ' + (a = i + 2)"
                      disabled
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="1"
                style="min-width: 100px; max-width: 100%; margin: 5px"
                class="flex-grow-1 flex-shrink-0"
              >
                <v-row v-for="(item, i) in sosData.latest_phone" :key="i">
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      v-if="user_allowed.allowed['edit sos contact']"
                      @input="checkdata('latest_phone', i)"
                      v-model="sosData.latest_phone[i]"
                      :value="item"
                      :placeholder="'Phone Number ' + (a = i + 2)"
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="elevation-0 rounded-0"
                      v-if="!user_allowed.allowed['edit sos contact']"
                      @input="checkdata('latest_phone', i)"
                      v-model="sosData.latest_phone[i]"
                      :value="item"
                      :placeholder="'Phone Number ' + (a = i + 2)"
                      disabled
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row no-gutters style="flex-wrap: nowrap; margin-top: 30px">
              <v-col
                cols="1"
                style="min-width: 9rem; max-width: 40%; margin: 5px"
              >
                <v-row v-for="(item, i) in sosData.latest_mail" :key="i">
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      :value="'E-mail ' + (a = i + 1)"
                      v-if="user_allowed.allowed['edit sos contact']"
                      outlined
                      readonly
                      filled
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="elevation-0 rounded-0"
                      :placeholder="'E-mail ' + (a = i + 1)"
                      v-if="!user_allowed.allowed['edit sos contact']"
                      outlined
                      disabled
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="1"
                style="min-width: 100px; max-width: 100%; margin: 5px"
                class="flex-grow-1 flex-shrink-0"
              >
                <v-row v-for="(item, i) in sosData.latest_mail" :key="i">
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      v-model="sosData.latest_mail[i]"
                      @input="checkdata('latest_mail', i)"
                      :value="item"
                      :placeholder="'Enter Email Address ' + (a = i + 1)"
                      v-if="user_allowed.allowed['edit sos contact']"
                      outlined
                      persistent-hint
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="elevation-0 rounded-0"
                      v-model="sosData.latest_mail[i]"
                      @input="checkdata('latest_mail', i)"
                      :value="item"
                      :placeholder="'Enter Email Address ' + (a = i + 1)"
                      v-if="!user_allowed.allowed['edit sos contact']"
                      outlined
                      disabled
                      persistent-hint
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-btn
              v-if="user_allowed.allowed['edit sos contact']"
              small
              class="elevation-0 rounded-0"
              @click="
                user_allowed.allowed['edit sos contact']
                  ? sosDataPost()
                  : console.log('disabled')
              "
              :disabled="changes_ ? false : true"
              color="primary"
              :style="
                user_allowed.allowed['edit sos contact']
                  ? 'cursor:pointer'
                  : 'cursor:not-allowed'
              "
              block
            >
              Update SOS Contact Configuration
            </v-btn>
            <v-btn
              v-if="!user_allowed.allowed['edit sos contact']"
              small
              class="elevation-0 rounded-0"
              :disabled="true"
              color="primary"
              :style="
                user_allowed.allowed['edit sos contact']
                  ? 'cursor:pointer'
                  : 'cursor:not-allowed'
              "
              block
            >
              Update SOS Contact Configuration
            </v-btn>
          </div>

          <div
            v-if="sosData.latest_status == 'ON AIR'"
            style="margin-top: 15px"
          >
            <v-row no-gutters style="flex-wrap: nowrap">
              <v-col
                cols="1"
                style="min-width: 9rem; max-width: 40%; margin: 5px"
              >
                <v-row>
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      placeholder="Phone 1"
                      filled
                      outlined
                      persistent-hint
                      disabled
                      dense
                      hint="E.g. +6281317772131"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="1"
                style="min-width: 100px; max-width: 100%; margin: 5px"
                class="flex-grow-1 flex-shrink-0"
              >
                <v-row>
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      value="SKYREACH"
                      :placeholder="'Contact Name ' + (a = i + 2)"
                      filled
                      outlined
                      disabled
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="1"
                style="min-width: 100px; max-width: 100%; margin: 5px"
                class="flex-grow-1 flex-shrink-0"
              >
                <v-row>
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      value="1005"
                      :placeholder="'Phone Number ' + (a = i + 2)"
                      filled
                      outlined
                      disabled
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
            <v-row no-gutters style="flex-wrap: nowrap; margin-top: 30px">
              <v-col
                cols="1"
                style="min-width: 9rem; max-width: 40%; margin: 5px"
              >
                <v-row v-for="(item, i) in sosData.latest_phone" :key="i">
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      :placeholder="'Phone ' + (a = i + 2)"
                      filled
                      outlined
                      disabled
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="1"
                style="min-width: 100px; max-width: 100%; margin: 5px"
                class="flex-grow-1 flex-shrink-0"
              >
                <v-row v-for="(item, i) in sosData.latest_name" :key="i">
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      v-if="user_allowed.allowed['edit sos contact']"
                      disabled
                      v-model="sosData.latest_name[i]"
                      :value="item"
                      :placeholder="'Contact Name ' + (a = i + 2)"
                      filled
                      outlined
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="elevation-0 rounded-0"
                      v-if="!user_allowed.allowed['edit sos contact']"
                      v-model="sosData.latest_name[i]"
                      :value="item"
                      :placeholder="'Contact Name ' + (a = i + 2)"
                      disabled
                      filled
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="1"
                style="min-width: 100px; max-width: 100%; margin: 5px"
                class="flex-grow-1 flex-shrink-0"
              >
                <v-row v-for="(item, i) in sosData.latest_phone" :key="i">
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      v-if="user_allowed.allowed['edit sos contact']"
                      v-model="sosData.latest_phone[i]"
                      :value="item"
                      :placeholder="'Phone Number ' + (a = i + 2)"
                      filled
                      outlined
                      disabled
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="elevation-0 rounded-0"
                      v-if="!user_allowed.allowed['edit sos contact']"
                      v-model="sosData.latest_phone[i]"
                      :value="item"
                      :placeholder="'Phone Number ' + (a = i + 2)"
                      filled
                      disabled
                      outlined
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-row no-gutters style="flex-wrap: nowrap; margin-top: 30px">
              <v-col
                cols="1"
                style="min-width: 9rem; max-width: 40%; margin: 5px"
              >
                <v-row v-for="(item, i) in sosData.latest_mail" :key="i">
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      :placeholder="'E-mail ' + (a = i + 1)"
                      v-if="user_allowed.allowed['edit sos contact']"
                      filled
                      outlined
                      disabled
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="elevation-0 rounded-0"
                      :placeholder="'E-mail ' + (a = i + 1)"
                      v-if="!user_allowed.allowed['edit sos contact']"
                      filled
                      outlined
                      disabled
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="1"
                style="min-width: 100px; max-width: 100%; margin: 5px"
                class="flex-grow-1 flex-shrink-0"
              >
                <v-row v-for="(item, i) in sosData.latest_mail" :key="i">
                  <v-col style="margin-top: -35px">
                    <v-text-field
                      class="elevation-0 rounded-0"
                      v-model="sosData.latest_mail[i]"
                      :value="item"
                      :placeholder="'Enter Email Address ' + (a = i + 1)"
                      v-if="user_allowed.allowed['edit sos contact']"
                      filled
                      outlined
                      disabled
                      persistent-hint
                      dense
                    ></v-text-field>
                    <v-text-field
                      class="elevation-0 rounded-0"
                      v-model="sosData.latest_mail[i]"
                      :value="item"
                      :placeholder="'Enter Email Address ' + (a = i + 1)"
                      v-if="!user_allowed.allowed['edit sos contact']"
                      filled
                      outlined
                      disabled
                      persistent-hint
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>

            <v-btn
              v-if="sosData.latest_status == 'ON AIR'"
              small
              class="elevation-0 rounded-0"
              @click="
                user_allowed.allowed['edit sos contact']
                  ? sosDataPost()
                  : console.log('disabled')
              "
              :disabled="true"
              color="primary"
              :style="
                user_allowed.allowed['edit sos contact']
                  ? 'cursor:pointer'
                  : 'cursor:not-allowed'
              "
              block
            >
              Update SOS Contact Configuration
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>

  </v-dialog>
  <v-snackbar
  class="elevation-0 rounded-0"
  elevation="0"
      v-model="snackbar"
    >
    <strong>{{sosData.sosTestEnabled? 'Enable':'Disable'}}</strong> SOS Test Success

      <template v-slot:action="{ attrs }">
        <v-btn
          color="primary"
          text
        
          absolute
      centered
      right
      tile
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "../../plugins/axios";
import $ from "jquery";
export default {
  data() {
    return {
      snackbar:false,
      switch1: false,
      sosmodal: null,
      change: false,
      loadernavplus: require("@/assets/img/load100new.gif"),
      fetchInterval: null,
    };
  },
  watch: {
    switch1(newValue) {
      //called whenever switch1 changes
      var isValue;
      if (this.sosmodal.sosTestEnabled == null) {
        isValue = false;
      } else {
        isValue = true;
      }
      console.log(newValue);
      console.log(isValue);

      if (isValue == newValue) {
        this.change = false;
      } else {
        this.change = true;
      }

      const data = {
        thuraya_number: this.sosData.number,
        enable: newValue,
        token: localStorage.getItem("token"),
      };

      this.sosEPost(data);
    },
  },
  mounted() {
    this.sosFetchStatic();
  },
  methods: {
    ...mapActions(["sosFetch", "sosPost", "sosRemove", "sosEPost"]),
    async sosDataPost() {
      $("#div_sos").scrollTop(0);
      const data = {
        thuraya_number: this.sosData.number,
        names: this.sosData.latest_name,
        phones: this.sosData.latest_phone,
        emails: this.sosData.latest_mail,
        token: localStorage.getItem("token"),
      };

      this.sosPost(data);

      var mother = this;
      var intervalSeconds = 0.5;
      this.fetchInterval = setInterval(function () {
        mother.sosDataFetch();
      }, intervalSeconds * 1000);
    },

    stopFetch() {
      console.log("STOP FETCH STATUS WHITELIST");
      clearInterval(this.fetchInterval);
      this.on = false;
    },

    closeSos() {
      this.stopFetch();
      this.sosRemove();
    },

    checkEnabled() {
     
      console.log(this.sosData.sosTestEnabled);
      const data = {
        thuraya_number: this.sosData.number,
        enable: this.sosData.sosTestEnabled,
        token: localStorage.getItem("token"),
      };
      this.snackbar = true;
      this.sosEPost(data);
    },

    checkdata(statis, value) {
      // console.log(value)
      // console.log(this.sosmodal[statis][value]);
      // console.log(this.sosData[statis][value]);
      if (this.sosmodal[statis][value] == this.sosData[statis][value]) {
        this.change = false;
      } else {
        this.change = true;
      }
    },

    async sosDataFetch() {
      console.log("FETCH TERUS STATUS ADMIN PASSWORD");
      console.log("STATUS :");
      console.log(this.sosData.latest_status);
      // this.wlModal(true);
      const data = {
        id: this.sosID,
        token: localStorage.getItem("token"),
      };
      this.sosFetch(data);
      var mother = this;
      setTimeout(function () {
        if (mother.sosData) {
          if (mother.sosData.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.sosFetchStatic();
          }
        } else {
          if (mother.sosmodal.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.sosFetchStatic();
          }
        }
      }, 5000);
    },

    async sosFetchStatic() {
      try {
        const response = await axios.get(
          "https://navigatorplus.sailink.id/api/v1/navplus/thuraya/" +
            this.sosID +
            "/sos",
          {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
            },
          }
        );

        this.sosmodal = response.data.data;
        var mother = this;
        var intervalSeconds = 0.5;
        if (response.data.data.latest_status == "ON AIR") {
          this.fetchInterval = setInterval(function () {
            mother.sosDataFetch();
          }, intervalSeconds * 1000);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },

  computed: {
    ...mapGetters(["sosData", "sosID", "sosOpen", "user_allowed"]),
    changes_() {
      return this.change;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-sheet.v-snack__wrapper {
    border-radius: none;
}
</style>
