<template>
  <div v-if="editpass">
    <v-dialog max-width="600" v-model="editpass" class="elevation-0 rounded-0" persistent>
      <v-card class="elevation-0 rounded-0" ref="form">
        <v-toolbar class="elevation-0 rounded-0" elevation="0" dense color="#F7F7F7" >
          <v-toolbar-title v-if="editpassdata.sameUser == true"
            >Change Your Password</v-toolbar-title
          >
          <v-toolbar-title v-if="editpassdata.sameUser != true"
            >Change Password For {{ editpassdata.user.name }}</v-toolbar-title
          >

          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-container v-if="!editpassdata" style="margin-top:0"   fill-height fluid>
        <v-row align="center" justify="center">
          <v-col
            ><img
              alt="Navplus logo"
              width="70px"
              :src="loadernavplus"
              class="justify-center"
          /></v-col>
        </v-row>
      </v-container>
        <v-card-text v-if="editpassdata" style="margin-top: 55px; margin-bottom: -50px">
          <v-form v-model="valid"    lazy-validation>
          <v-text-field
          @input="change_=true"
          class="elevation-0 rounded-0"
            v-if="editpassdata.sameUser"
            label="Old Password"
            v-model="oldpass"
            :append-icon="oldshow ? 'mdi-eye' : 'mdi-eye-off'"
            :type="oldshow ? 'text' : 'password'"
            :rules="oldRules"
            counter
            minlength="8"
            hint="At least 8 characters"
            @click:append="
              (oldshow = !oldshow)
            "
            outlined
          ></v-text-field>
          <v-text-field
           @input="change_=true"
          class="elevation-0 rounded-0"
            :value="newpass"
            label="New Password"
            v-model="newpass"
            counter
            :rules="passwordRules"
            minlength="8"
            hint="At least 8 characters"
            :append-icon="newshow ? 'mdi-eye' : 'mdi-eye-off'"
            :type="newshow ? 'text' : 'password'"
            @click:append="
              (newshow = !newshow)
            "
            outlined
          ></v-text-field>
          <v-text-field
          class="elevation-0 rounded-0"
          @input="change_=true"
            label="Confirm Password"
            v-model="confirmpass"
            :rules="[ v => !!v || 'type confirm password',
        v => v === newpass || 'The password confirmation does not match.']"
            counter
            minlength="8"
            :append-icon="confirshow ? 'mdi-eye' : 'mdi-eye-off'"
            :type="confirshow ? 'text' : 'password'"
            hint="At least 8 characters"
            @click:append="
              (confirshow = !confirshow)
            "
            outlined
          ></v-text-field>
        </v-form>
          <v-divider class="mt-12"></v-divider>

          <v-alert
            v-if="alert_"
            style="margin: 10px"
            dismissible
            dense
            prominent
            type="success"
            >Password Changed</v-alert
          >
        </v-card-text>
        <v-card-actions v-if="editpassdata" style="margin-top: 2em">
          <v-btn
           v-if="editpassdata.sameUser"
          :disabled="valid && change_ && newpass && confirmpass && oldpass ? false : true"
          small
          class="elevation-0 rounded-0"
            color="primary"
            @click="pass_confirm = true"
            style="width: 49%"
            
          >
            Update Password
          </v-btn>
          <v-btn
           v-if="!editpassdata.sameUser"
          :disabled="valid && change_ && newpass && confirmpass  ? false : true"
          small
          class="elevation-0 rounded-0"
            color="primary"
            @click="pass_confirm = true"
            style="width: 49%"
            
          >
            Update Password
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
          small
          class="elevation-0 rounded-0"
            color="red"
            dark
            @click="setEditPass(null),closeEditPass()"
            
            style="width: 49%"
          >
            cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="#4CAF50" style="padding: 1em" dark>
        <v-alert dense prominent type="success">Profile has change</v-alert>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pass_notsame" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Not Same! </v-card-title>
        <v-card-text style="text-align: left"
          >Confirmation password must be identical to password, continue edit or leave</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="pass_notsame = false">
            continue
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
             setEditPass(null),
                (newpass = 'New Password'),
                (oldpass = 'Your Old Password'),
                (confirmpass = 'New Password'),
                (oldshow = false),
                (newshow = false),
                (confirshow = false), (pass_notsame = false)
            "
          >
            leave
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pass_confirm" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text style="text-align: left"
          >Password will updated after you click yes</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="pass_confirm = false">
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
            editpassdata.sameUser ? upMyPassword() : upPassword(), (pass_confirm = false)
            "
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pass_nochange" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> No Changes! </v-card-title>
        <v-card-text style="text-align: left"
          >You did not change anything, wanna continue edit or
          leave?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="pass_nochange = false">
            continue
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
             setEditPass(null),
                (newpass = 'New Password'),
                (oldpass = 'Your Old Password'),
                (confirmpass = 'New Password'),
                (oldshow = false),
                (newshow = false),
                (confirshow = false),(pass_nochange = false)"
          >
            leave
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pass_empty" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Empty Input! </v-card-title>
        <v-card-text style="text-align: left"
          >You sending empty field, please write something or
          leave?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="pass_empty = false">
            continue
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
             setEditPass(null),
                (newpass = 'New Password'),
                (oldpass = 'Your Old Password'),
                (confirmpass = 'New Password'),
                (oldshow = false),
                (newshow = false),
                (confirshow = false),(pass_empty = false)"
          >
            leave
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="pass_notrule" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Wrong way! </v-card-title>
        <v-card-text style="text-align: left"
          >Password must be 8 characters at least, follow rule or
          leave?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="pass_empty = false">
            continue
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
             setEditPass(null),
                (newpass = 'New Password'),
                (oldpass = 'Your Old Password'),
                (confirmpass = 'New Password'),
                (oldshow = false),
                (newshow = false),
                (confirshow = false),(pass_notrule = false)"
          >
            leave
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="#4CAF50" style="padding: 1em" dark>
        <v-alert dense prominent type="success" style="margin-top: 1em"
          >Password data has change</v-alert
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
export default {
  data: () => ({
    change_ :false,
    valid:false,
    loadernavplus: require("@/assets/img/load100new.gif"),
    oldshow: false,
    newshow: false,
    confirshow: false,
    alert_: null,
    alert_2: null,
    alert_3: null,
    user: null,
    oldpass: "",
    newpass: "",
    userpass: null,
    confirmpass: "",
    pass_empty : null,
    pass_confirm:null,
    pass_notsame:null,
    pass_nochange:null,
    pass_notrule:null,
    oldRules: [
        v => !!v || 'Please type old password.',
        v => (v && v.length >= 8) || 'minimum 8 characters',
      ],
    passwordRules: [
        v => !!v || 'Please type password.',
        v => (v && v.length >= 8) || 'minimum 8 characters',
      ],
    confirmPasswordRules: [
        v => !!v || 'type confirm password',
        v => v === this.newpass || 'The password confirmation does not match.',
      ],
  }),

  async created() {
    // console.log("USER ID BAWAAN ==== "+this.editpassid)
    // const response = await axios.get("login", {
    //   headers: {
    //     Authorization: "Bearer" + localStorage.getItem("token"),
    //   },
    // });
    // this.user = response.data.user;
   
    // this.editPassword();
  },

  mounted(){
    this.valid=false
  },

  methods: {
    ...mapActions(["setEditPass"]),
    async editPassword() {
      const response = await axios.get(
        "v1/navplus/user/edit/pass/" + this.editpassid,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data.data);
      this.userpass = response.data.data;
    },

    async upPassword() {
      const data = {
        password: this.newpass,
        password_confirmation: this.confirmpass,
      };
      const response = await axios.post(
        "v1/navplus/user/edit/pass/" + this.editpassdata.user.id,
        data,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      console.log(response);
      if (response) {
        this.alert_ = true;
      }
    },

    closeEditPass(){
      this.newpass = null
      this.confirmpass =null
      this.oldpass=null
    },

    async upMyPassword() {
      const data = {
        password: this.newpass,
        password_confirmation: this.confirmpass,
        oldpassword: this.oldpass,
      };
      const response = await axios.post(
        "v1/navplus/user/edit/pass/" + this.editpassdata.user.id,
        data,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      console.log(response);
      if (response) {
        this.alert_ = true;
      }
    },
  },
  computed: {
    ...mapGetters(["editpass", "editpassid", "editpassdata", "userprof"]),
  },
};
</script>

<style lang="scss" scoped></style>
