<template>
  <v-app class="container_tabledata" color="#fff">
    <v-card id="top_snapshot" class="rounded-0 elevation-0" elevation="0">
      <v-alert v-if="OffSnapshot" class="elevation-0 rounded-0" prominent type="warning" icon="mdi-web">
        <v-row align="center">
          <v-col class="grow">
            Connection timeout , please check your internet connection
          </v-col>
          <v-col class="shrink">
            <v-btn small @click="reload_page()" class="elevation-0 rounded-0">Reload</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-layout v-resize="onResize" column style="">
        <loadingTable v-if="!snapshotData" />
        <v-card v-if="snapshotData" class="ma-3 elevation-0 rounded-0" style="border: solid 1px #e0e0e0">
          <v-toolbar elevation="0" class="pa-0" color="#F7F7F7" fixed dense>
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0" color="#ffff"
                  v-bind="attrs" v-on="on">
                  {{ kFormatter(itemsPerPage)
                  }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item @click="itemsPerPage = 10"><v-list-item-title>10</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 25"><v-list-item-title>25</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 15"><v-list-item-title>50</v-list-item-title></v-list-item>
                <v-list-item
                  @click="itemsPerPage = reportsData.length"><v-list-item-title>100</v-list-item-title></v-list-item>
              </v-list>
            </v-menu>
            <v-spacer style="width: 70%"></v-spacer>
            <v-text-field style="border: solid 1px #e0e0e0" width="40" v-model="search" hide-details
              prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
              class="elevation-0 rounded-0"></v-text-field>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table style="margin-top: 0px" v-if="snapshotData" :search="search" :headers="headers"
            :items="snapshotData" :items-per-page="itemsPerPage" hide-default-footer hide-default-header :page.sync="page"
            class="elevation-0 rounded-0 dataTable desktopTable_snapshot" @page-count="pageCount = $event"
            @pagination="yourMethod">
            <template v-slot:header>
              <th class="pa-0 pt-2 pl-1" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                ">
                No
              </th>
              <th class="pa-0 pt-2 pl-1" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                ">
                Vessel Name
              </th>
              <th class="pa-0 pt-2 pl-1" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                ">
                Company
              </th>
              <th class="pa-0 pt-2 pl-1" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                "></th>
            </template>
            <template v-slot:item="row">
              <tr>
                <td width="8%" class="pa-1" style="
                    white-space: nowrap;
                    min-width: 2.5em;
                    text-align: left;
                  ">
                  {{ row.item.index + 1 }}
                </td>
                <td width="30%" class="pa-1" style="white-space: nowrap; text-align: left">

                  <v-badge :content="'!'" :value="row.item.snap_status == 'INACTIVE'" color="red">{{ row.item.name
                  }}<v-chip class="rounded-0 pa-1 ml-1"
                      v-if="row.item.snap_status != 'ACTIVE' && row.item.snap_status != 'INACTIVE'" dark x-small>{{
                        row.item.snap_status
                      }}</v-chip></v-badge>

                </td>
                <td width="32%" class="pa-1" style="white-space: nowrap; text-align: left">
                  {{ row.item.pt_name }}
                </td>
                <td width="30%" class="pa-1" style="white-space: nowrap; text-align: left">
                  <v-btn @click="sendDataNotifSnap(row.item.id)" width="46%" dark color="#6C757D" role="button"
                    class="ma-1 elevation-0 rounded-0 pa-1" small>
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-wrench</v-icon>Notification
                    </div>
                  </v-btn>
                  <v-btn width="45%" dark class="elevation-0 rounded-0 pa-1" color="primary"
                    @click="showsnapshotFolder(row.item)" small>
                    <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-image-multiple</v-icon>Show Snapshot
                    </div>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table style="margin-top: 0px" v-if="snapshotData" :search="search" :headers="headers"
            :items="snapshotData" :items-per-page="itemsPerPage" hide-default-footer hide-default-header :page.sync="page"
            class="elevation-0 rounded-0 mobileTable_snapshot" @page-count="pageCount = $event"
            @pagination="yourMethod"><template v-slot:item="row">
              <tr style="margin-top: 0.4em; margin-bottom: 0.4em">
                <td style="border-bottom: none; text-align: left">
                  <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" width="87vw">
                    <v-toolbar dense class="elevation-0 rounded-0">
                      <v-toolbar-subtitle>{{
                        row.item.index + 1
                      }}</v-toolbar-subtitle>
                      <v-spacer></v-spacer>
                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                      <table>
                        <tr>
                          <td>Vessel Name</td>
                          <td>:</td>
                          <td> <v-badge :content="'!'" :value="row.item.snap_status == 'INACTIVE'" color="red">{{
                            row.item.name }}<v-chip class="rounded-0 pa-1 ml-1"
                                v-if="row.item.snap_status != 'ACTIVE' && row.item.snap_status != 'INACTIVE'" dark
                                x-small>{{ row.item.snap_status
                                }}</v-chip></v-badge></td>
                        </tr>
                        <tr>
                          <td>Company</td>
                          <td>:</td>
                          <td>{{ row.item.pt_name }}</td>
                        </tr>
                        <tr>
                          <td colspan="3"><v-divider></v-divider></td>
                        </tr>
                        <tr>
                          <td colspan="3" class="pa-1 pl-0">
                            <v-btn @click="sendDataNotifSnap(row.item.id)" dark color="#6C757D" role="button"
                              class="ma-1 elevation-0 rounded-0 pa-1 ml-0" small>
                              <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-wrench</v-icon>Notification
                              </div>
                            </v-btn>
                            <v-btn dark class="elevation-0 rounded-0 pa-1" color="primary"
                              @click="showsnapshotFolder(row.item)" small>
                              <div style="font-size: 0.85em;"><v-icon class="mr-2" x-small>mdi-image-multiple</v-icon>Show
                                Snapshot
                              </div>
                            </v-btn>
                          </td>
                        </tr>
                      </table>
                    </v-card-text>
                  </v-card>
                </td>
              </tr>
            </template></v-data-table>
          <v-divider></v-divider>
          <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">
            <p class="mt-3" style="font-size: 0.8em;text-align: left;">
              Showing {{ pageStart }} to {{ pageStop }} of
              {{ totalFilter }} entries
            </p>

            <v-spacer></v-spacer>
            <v-pagination color="primary" v-model="page" :length="pageCount" :total-visible="7"></v-pagination>
          </v-toolbar>
        </v-card>
      </v-layout>
      <div id="bottom_table_snapshot"></div>
    </v-card>
    <ShowSnapshotSkeleton v-if="!loadingfolder && snapshotclick" />
    <ShowSnapshot v-if="loadingfolder" />
    <NotifSnapModal v-if="notifsnapopen" />
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";
import loadingTable from "@/components/progress/loadingTable.vue";
import ShowSnapshot from "@/components/showpage/ShowSnapshot.vue";
import ShowSnapshotSkeleton from "@/components/skeleton/ShowSnapshotSkeleton.vue";
import NotifSnapModal from "@/components/modals/notif_snap_modal.vue";
export default {
  inject: {
    theme: {
      default: { isDark: false },
    },
  },
  components: {
    loadingTable,
    ShowSnapshot,
    ShowSnapshotSkeleton,
    NotifSnapModal,
  },
  data() {
    return {
      pageStart: 0,
      pageStop: 0,
      totalFilter: null,
      showpictonclick: null,
      action: null,
      folderOnSelect: null,
      filter_btn_clicked: null,
      display: "none",
      itemsPerPage: 10,
      page: 1,
      pageCount: 0,
      itemsPerPageImage: 25,
      pageImage: 1,
      pageCountImage: 0,
      itemsPerPageFolders: 25,
      pageFolders: 1,
      pageCountFolders: 0,
      pageSize: 1,
      showpict: null,
      listCount: 0,
      snapshotpict_: [],
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Vessel Name", value: "name", sortable: false },
        { text: "Company", value: "pt_name", sortable: false },
        { text: "Show Snapshot", value: "", sortable: false },
      ],
      skeleton_data: [
        { data: "" },
        { data: "" },
        { data: "" },
        { data: "" },
        { data: "" },
        { data: "" },
        { data: "" },
        { data: "" },
      ],
      // in real case initial search_result = [], and methods: search function inject below data
      snapshot: null,
      imageOpen: null,
      imageData: null,
      search: "",
      loadernavplus: require("@/assets/img/load100new.gif"),
      folderclose: require("@/assets/img/close-folder.png"),
      folderloading: require("@/assets/img/loading-folder.png"),
      foldercloseagain: require("@/assets/img/close-folder.png"),
      folderopen: require("@/assets/img/open-folder.png"),
    };
  },
  created() {
    this.fetchSnapshot(localStorage.getItem("token"));
    this.snapshotStatus(localStorage.getItem("token"));
  },
  mounted() {
    this.fetchUSERGET(localStorage.getItem("token"));
    this.updateSnapStatus_(localStorage.getItem("token"));
  },
  methods: {
    ...mapActions([
      "updateSnapStatus_",
      "snapshotStatus",
      "removeErrorsnap",
      "fetchUSERGET",
      "addsnapshot_executed",
      "closeShow",
      "fetchSnapshot",
      "fetchFolder",
      "showSnapshot_",
      "FolderClick",
      "vesselDataSet",
      "FilterClick",
      "fetchNotifSnap"
    ]),

    yourMethod(pagination) {
      console.log(pagination)
      this.totalFilter = pagination.itemsLength;
      this.pageStart = pagination.pageStart + 1
      this.pageStop = pagination.pageStop
    },

    sendDataNotifSnap(item) {
      const data = {
        id: item,
        token: localStorage.getItem("token"),
      };
      this.fetchNotifSnap(data);
    },

    reload_page() {
      window.location.reload();
    },

    async showsnapshotFolder(item) {
      this.removeErrorsnap()
      this.addsnapshot_executed(false);
      $("html, body").animate(
        {
          scrollTop: $("#bottom_table_snapshot").offset().top,
        },
        1000
      );
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };

      this.vesselDataSet(item);
      this.fetchFolder(data);
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
  },
  computed: {
    ...mapGetters([
      "notifsnapopen",
      "OffSnapshot",
      "folderSelect",
      "filterList",
      "snapshotData",
      "snapshotpict",
      "snapshotfolder",
      "loadingimage",
      "loadingfolder",
      "vesselData",
      "snapshotclick",
      "snapshot_executed",
    ]),
    pages() {
      if (this.pageSize == null || this.listCount == null) return 0;
      return Math.ceil(this.listCount / this.pageSize);
    },

    showingDatafrom() {
      if (this.totalFilter < 1) {
        return 0;
      } else {
        return 1;
      }
    },

    showingDatato() {
      if (this.totalFilter < this.itemsPerPage) {
        return this.totalFilter;
      } else {
        return this.itemsPerPage;
      }
    },

    target() {
      return 82000000;
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/snapshotView.css";
</style>
