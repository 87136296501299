<template>
  <v-app class="container_tabledata" color="#fff">
    <v-card elevation="0">
      <v-alert
      v-if="OffSummary"
      class="elevation-0 rounded-0"
      prominent
      type="warning"
      icon="mdi-web"
    >
      <v-row align="center">
        <v-col class="grow">
          Connection timeout , please check your internet connection
        </v-col>
        <v-col class="shrink">
          <v-btn small @click="reload_page()" class="elevation-0 rounded-0">Reload</v-btn>
        </v-col>
      </v-row>
    </v-alert>
      <div id="top_summary"></div>
      <v-layout v-resize="onResize" column style="">
        <loadingTable v-if="!fleetSummary && !sailinkSummary && !thurayaSummary && !terminalList && !phoneList && !rateplanList && !userList && !groupList && !companyList && !iotList && !svList" />
        <v-card v-if="fleetSummary || sailinkSummary || thurayaSummary || terminalList || phoneList || rateplanList || userList || groupList || companyList && iotList && svList" class="ma-3 elevation-0 rounded-0">
         
          <v-row no-gutters>
            <v-col cols="12">
              
              <v-card   class="elevation-0 rounded-0 pa-2 mb-2"  outlined tile>
             <v-card class="elevation-0 rounded-0"  style="margin-top: 0px;border: solid 1px #e0e0e0" >
            
              <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense >
                Fleet Summary
                <v-spacer></v-spacer>
                <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      style="border: solid 1px #e0e0e0"
                        small
                        class="rounded-0 pa-1 elevation-0 ma-0"
                        color="#ffff"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ kFormatter(itemsPerPage)
                        }}<v-icon small style="margin-left: 0.2em"
                          >mdi-unfold-more-horizontal</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="itemsPerPage = 10"
                        ><v-list-item-title>10</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPage = 25"
                        ><v-list-item-title>25</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPage = 50"
                        ><v-list-item-title>50</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPage = 100"
                        ><v-list-item-title>100</v-list-item-title></v-list-item
                      >
                    </v-list>
                  </v-menu>
                  <v-btn
             @click="fetch_FS()"
                   
              class="mx-2 rounded-0 elevation-0 pa-0"
              fab
              dark
              x-small
              color="primary"
            >
              <v-icon small dark> mdi-refresh </v-icon>
            </v-btn>
              </v-toolbar>
              
                <v-divider></v-divider>
                <img
                  v-if="!fleetSummary"
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
              />
                <v-data-table  
                  v-if="fleetSummary"
                  :search="search"
                  :headers="headersFleetSummary"
                  :items="fleetSummary"
                  hide-default-footer
                  :page.sync="page"
                  :items-per-page="itemsPerPage"
                  class="elevation-0 rounded-0 dataTable"
                  @page-count="pageCount = $event"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 10em;text-align:left"
                      >
                        {{ row.item.index + 1 }}
                      </td>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 20em;text-align:left"
                      >
                        {{ row.item[0] }}
                      </td>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 20em;text-align:left"
                      >
                        {{ row.item[1] }}
                        
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        <v-btn  
                          color="primary"
                          @click="addsummary_executed(false),DetailSummary(row.item[2],'FLEET SUMMARY')"
                          role="button"
                          class="pingbtn elevation-0 rounded-0"
                          style="min-width: 23em;"
                          small
                          ><v-icon small>mdi-chevron-double-down</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
          <v-toolbar
          
            dense
            class="elevation-0 rounded-0"
            color="#F7F7F7"
          >
          
            <v-spacer></v-spacer>
            <v-pagination
              class="pt-2 pb-2"
            
                    v-model="page"
                    color="primary"
                    :length="pageCount"
                    :total-visible="7"
                  ></v-pagination>
          </v-toolbar>
                
            </v-card>
           
              </v-card>


            </v-col>
            <v-col cols="6">
              <v-card class="pa-2 mb-2 mr-1" outlined tile>
                <v-card class="elevation-0 rounded-0"  style="margin-top: 0px;border: solid 1px #e0e0e0" >
                  <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense >
                Sailink
                <v-spacer></v-spacer>
                <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      style="margin-top: 0px;border: solid 1px #e0e0e0"
                        small
                        class="rounded-0 pa-1 elevation-0 ma-0"
                        color="#ffff"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ kFormatter(itemsPerPageSailink)
                        }}<v-icon small style="margin-left: 0.2em"
                          >mdi-unfold-more-horizontal</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="itemsPerPageSailink = 10"
                        ><v-list-item-title>10</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageSailink = 25"
                        ><v-list-item-title>25</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageSailink = 50"
                        ><v-list-item-title>50</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageSailink = 100"
                        ><v-list-item-title>100</v-list-item-title></v-list-item
                      >
                    </v-list>
                  </v-menu>
              </v-toolbar>
               <v-divider></v-divider>
                <v-data-table
                  style="margin-top: 0px"
                  v-if="sailinkSummary"
                  :search="searchSailink"
                  :headers="headersSailink"
                  :items="sailinkSummary"
                  hide-default-footer
                  :page.sync="pageSailink"
                  :items-per-page="itemsPerPageSailink"
                  class="elevation-0 rounded-0 dataTable"
                  @page-count="pageCountSailink = $event"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 4em;text-align:left"
                      >
                        {{ row.item.index + 1 }}
                      </td>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 6em;text-align:left"
                      >
                        {{ row.item[0] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        {{ row.item[1] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        <v-btn
                         
                          color="primary"
                          @click="addsummary_executed(false),DetailSummary(row.item[2],'SAILINK')"
                          role="button"
                          class="pingbtn elevation-0 rounded-0"
                          style="min-width: 7em;"
                          small
                          ><v-icon small>mdi-chevron-double-down</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-toolbar
          
          dense
          class="elevation-0 rounded-0"
          color="#F7F7F7"
        >
        
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="pageSailink"
                    color="primary"
                    :length="pageCountSailink"
                    :total-visible="7"
                ></v-pagination>
        </v-toolbar>
               
                </v-card>
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="pa-2 mb-2 ml-1" outlined tile>
                <v-card class="elevation-0 rounded-0"  style="margin-top: 0px;border: solid 1px #e0e0e0" >
                <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7"  dense>
                Thuraya
                <v-spacer></v-spacer>
                <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      style="border: solid 1px #e0e0e0"
                        small
                        class="rounded-0 pa-1 elevation-0 ma-0"
                        color="#ffff"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ kFormatter(itemsPerPageThuraya)
                        }}<v-icon small style="margin-left: 0.2em"
                          >mdi-unfold-more-horizontal</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="itemsPerPageThuraya = 10"
                        ><v-list-item-title>10</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageThuraya = 25"
                        ><v-list-item-title>25</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageThuraya = 50"
                        ><v-list-item-title>50</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageThuraya = 100"
                        ><v-list-item-title>100</v-list-item-title></v-list-item
                      >
                    </v-list>
                  </v-menu>
              </v-toolbar>
              <v-divider></v-divider>
                <v-data-table
                  style="margin-top: 0px"
                  v-if="thurayaSummary"
                  :search="searchThuraya"
                  :headers="headersThuraya"
                  :items="thurayaSummary"
                  hide-default-footer
                  :page.sync="pageThuraya"
                  :items-per-page="itemsPerPageThuraya"
                  class="elevation-0 rounded-0 dataTable"
                  @page-count="pageCountThuraya = $event"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 4em;text-align:left"
                      >
                        {{ row.item.index + 1 }}
                      </td>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 6em;text-align:left"
                      >
                        {{ row.item[0] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        {{ row.item[1] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        <v-btn
                         
                          color="primary"
                          @click="addsummary_executed(false),DetailSummary(row.item[2],'THURAYA')"
                          role="button"
                          class="pingbtn elevation-0 rounded-0"
                          style="min-width: 7em;"
                          small
                          ><v-icon small>mdi-chevron-double-down</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                  <v-toolbar
          
          dense
          class="elevation-0 rounded-0"
          color="#F7F7F7"
        >
        
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="pageThuraya"
                    color="primary"
                    :length="pageCountThuraya"
                    :total-visible="7"
                ></v-pagination>
        </v-toolbar>
      </v-card>
              </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2 mb-2 mr-1" outlined tile>
                  <v-card class="elevation-0 rounded-0"  style="margin-top: 0px;border: solid 1px #e0e0e0" >
              <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense >
                Service List
                <v-spacer></v-spacer>
                <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      style="border: solid 1px #e0e0e0"
                        small
                        class="rounded-0 pa-1 elevation-0 ma-0"
                        color="#ffff"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ kFormatter(itemsPerPageSv)
                        }}<v-icon small style="margin-left: 0.2em"
                          >mdi-unfold-more-horizontal</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="itemsPerPageSv = 10"
                        ><v-list-item-title>10</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageSv = 25"
                        ><v-list-item-title>25</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageSv = 50"
                        ><v-list-item-title>50</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageSv = 100"
                        ><v-list-item-title>100</v-list-item-title></v-list-item
                      >
                    </v-list>
                  </v-menu>
              </v-toolbar>
              <v-divider></v-divider>
                <v-data-table
                  style="margin-top: 0px"
                  v-if="svList"
                  :search="searchSv"
                  :headers="headersSv"
                  :items="svList"
                  hide-default-footer
                  :page.sync="pageSv"
                  :items-per-page="itemsPerPageSv"
                  class="elevation-0 rounded-0 dataTable"
                  @page-count="pageCountSv = $event"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td 
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 4em;text-align:left"
                      >
                        {{ row.item.index + 1 }}
                      </td>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 6em;text-align:left"
                      >
                        {{ row.item[0] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        {{ row.item[1] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        <v-btn
                        
                          color="primary"
                          @click="addsummary_executed(false),DetailSummary(row.item[2],'TERMINAL LIST')"
                          role="button"
                          class="pingbtn elevation-0 rounded-0"
                          style="min-width: 7em;"
                          small
                          ><v-icon small>mdi-chevron-double-down</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-toolbar
          
          dense
          class="elevation-0 rounded-0"
          color="#F7F7F7"
        >
        
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="pageSv"
                    color="primary"
                    :length="pageCountSv"
                    :total-visible="7"
                ></v-pagination>
        </v-toolbar>
                
              </v-card>
              </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2 mb-2 mr-1" outlined tile>
                  <v-card class="elevation-0 rounded-0"  style="margin-top: 0px;border: solid 1px #e0e0e0" >
              <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense >
                Terminal List
                <v-spacer></v-spacer>
                <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      style="border: solid 1px #e0e0e0"
                        small
                        class="rounded-0 pa-1 elevation-0 ma-0"
                        color="#ffff"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ kFormatter(itemsPerPageTerminal)
                        }}<v-icon small style="margin-left: 0.2em"
                          >mdi-unfold-more-horizontal</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="itemsPerPageTerminal = 10"
                        ><v-list-item-title>10</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageTerminal = 25"
                        ><v-list-item-title>25</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageTerminal = 50"
                        ><v-list-item-title>50</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageTerminal = 100"
                        ><v-list-item-title>100</v-list-item-title></v-list-item
                      >
                    </v-list>
                  </v-menu>
              </v-toolbar>
              <v-divider></v-divider>
                <v-data-table
                  style="margin-top: 0px"
                  v-if="terminalList"
                  :search="searchTerminal"
                  :headers="headersTerminal"
                  :items="terminalList"
                  hide-default-footer
                  :page.sync="pageTerminal"
                  :items-per-page="itemsPerPageTerminal"
                  class="elevation-0 rounded-0 dataTable"
                  @page-count="pageCountTerminal = $event"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td 
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 4em;text-align:left"
                      >
                        {{ row.item.index + 1 }}
                      </td>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 6em;text-align:left"
                      >
                        {{ row.item[0] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        {{ row.item[1] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        <v-btn
                        
                          color="primary"
                          @click="addsummary_executed(false),DetailSummary(row.item[2],'TERMINAL LIST')"
                          role="button"
                          class="pingbtn elevation-0 rounded-0"
                          style="min-width: 7em;"
                          small
                          ><v-icon small>mdi-chevron-double-down</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-toolbar
          
          dense
          class="elevation-0 rounded-0"
          color="#F7F7F7"
        >
        
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="pageTerminal"
                    color="primary"
                    :length="pageCountTerminal"
                    :total-visible="7"
                ></v-pagination>
        </v-toolbar>
                
              </v-card>
              </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2 mb-2 mr-1 ml-1" outlined tile>
                  <v-card class="elevation-0 rounded-0"  style="margin-top: 0px;border: solid 1px #e0e0e0" >
              <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense >
                IP Phone List
                <v-spacer></v-spacer>
                <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                         style="border: solid 1px #e0e0e0"
                        class="rounded-0 pa-1 elevation-0 ma-0"
                        color="#ffff"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ kFormatter(itemsPerPagePhone)
                        }}<v-icon small style="margin-left: 0.2em"
                          >mdi-unfold-more-horizontal</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="itemsPerPagePhone = 10"
                        ><v-list-item-title>10</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPagePhone = 25"
                        ><v-list-item-title>25</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPagePhone = 50"
                        ><v-list-item-title>50</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPagePhone = 100"
                        ><v-list-item-title>100</v-list-item-title></v-list-item
                      >
                    </v-list>
                  </v-menu>
              </v-toolbar>
               <v-divider></v-divider>
                <v-data-table
                  style="margin-top: 0px"
                  v-if="phoneList"
                  :search="searchPhone"
                  :headers="headersPhone"
                  :items="phoneList"
                  hide-default-footer
                  :page.sync="pagePhone"
                  :items-per-page="itemsPerPagePhone"
                  class="elevation-0 rounded-0 dataTable"
                  @page-count="pageCountPhone = $event"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 4em;text-align:left"
                      >
                        {{ row.item.index + 1 }}
                      </td>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 6em;text-align:left"
                      >
                        {{ row.item[0] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        {{ row.item[1] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        <v-btn
                        
                          color="primary"
                          @click="addsummary_executed(false),DetailSummary(row.item[2],'IP PHONE LIST')"
                          role="button"
                          class="pingbtn elevation-0 rounded-0"
                          style="min-width: 7em;"
                          small
                          ><v-icon small>mdi-chevron-double-down</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-toolbar
          
          dense
          class="elevation-0 rounded-0"
          color="#F7F7F7"
        >
        
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="pagePhone"
                    color="primary"
                    :length="pageCountPhone"
                    :total-visible="7"
                ></v-pagination>
        </v-toolbar>
                
              </v-card>
            </v-card>
            </v-col>
            <v-col cols="3">
                <v-card class="pa-2 mb-2 mr-1 ml-1" outlined tile>
                  <v-card class="elevation-0 rounded-0"  style="margin-top: 0px;border: solid 1px #e0e0e0" >
              <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense >
                Thuraya Rateplan List
                <v-spacer></v-spacer>
                <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="border: solid 1px #e0e0e0"
                        class="rounded-0 pa-1 elevation-0 ma-0"
                        color="#ffff"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ kFormatter(itemsPerPageRateplan)
                        }}<v-icon small style="margin-left: 0.2em"
                          >mdi-unfold-more-horizontal</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="itemsPerPageRateplan = 10"
                        ><v-list-item-title>10</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageRateplan = 25"
                        ><v-list-item-title>25</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageRateplan = 50"
                        ><v-list-item-title>50</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageRateplan = 100"
                        ><v-list-item-title>100</v-list-item-title></v-list-item
                      >
                    </v-list>
                  </v-menu>
              </v-toolbar>
               <v-divider></v-divider>
                <v-data-table
                  style="margin-top: 0px"
                  v-if="rateplanList"
                  :search="searchRateplan"
                  :headers="headersRateplan"
                  :items="rateplanList"
                  hide-default-footer
                  :page.sync="pageRateplan"
                  :items-per-page="itemsPerPageRateplan"
                  class="elevation-0 rounded-0 dataTable"
                  @page-count="pageCountRateplan = $event"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 4em;text-align:left"
                      >
                        {{ row.item.index + 1 }}
                      </td>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 6em;text-align:left"
                      >
                        {{ row.item[0] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        {{ row.item[1] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        <v-btn
                        
                          color="primary"
                          @click="addsummary_executed(false),DetailSummary(row.item[2],'THURAYA RATEPLAN LIST')"
                          role="button"
                          class="pingbtn elevation-0 rounded-0"
                          style="min-width: 7em;"
                          small
                          ><v-icon small>mdi-chevron-double-down</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-toolbar
          
          dense
          class="elevation-0 rounded-0"
          color="#F7F7F7"
        >
        
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="pageRateplan"
                    color="primary"
                    :length="pageCountRateplan"
                    :total-visible="7"
                ></v-pagination>
        </v-toolbar>
                
              </v-card>
            </v-card>
            </v-col>
            <v-col cols="6">
                <v-card class="pa-2 mb-2 mr-1" outlined tile>
                  <v-card class="elevation-0 rounded-0"  style="margin-top: 0px;border: solid 1px #e0e0e0" >
              <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense >
                Vessel IoT List
                <v-spacer></v-spacer>
                <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      style="border: solid 1px #e0e0e0"
                        small
                        class="rounded-0 pa-1 elevation-0 ma-0"
                        color="#ffff"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ kFormatter(itemsPerPageIot)
                        }}<v-icon small style="margin-left: 0.2em"
                          >mdi-unfold-more-horizontal</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="itemsPerPageIot = 10"
                        ><v-list-item-title>10</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageIot = 25"
                        ><v-list-item-title>25</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageIot = 50"
                        ><v-list-item-title>50</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageIot = 100"
                        ><v-list-item-title>100</v-list-item-title></v-list-item
                      >
                    </v-list>
                  </v-menu>
              </v-toolbar>
              <v-divider></v-divider>
                <v-data-table
                  style="margin-top: 0px"
                  v-if="iotList"
                  :search="searchIot"
                  :headers="headersIot"
                  :items="iotList"
                  hide-default-footer
                  :page.sync="pageIot"
                  :items-per-page="itemsPerPageIot"
                  class="elevation-0 rounded-0 dataTable"
                  @page-count="pageCountIot = $event"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td 
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 4em;text-align:left"
                      >
                        {{ row.item.index + 1 }}
                      </td>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 6em;text-align:left"
                      >
                        {{ row.item[0] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        {{ row.item[1] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        <v-btn
                        
                          color="primary"
                          @click="addsummary_executed(false),DetailSummary(row.item[2],'VESSEL With IOT Devices')"
                          role="button"
                          class="pingbtn elevation-0 rounded-0"
                          style="min-width: 7em;"
                          small
                          ><v-icon small>mdi-chevron-double-down</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-toolbar
          
          dense
          class="elevation-0 rounded-0"
          color="#F7F7F7"
        >
        
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="pageIot"
                    color="primary"
                    :length="pageCountIot"
                    :total-visible="7"
                ></v-pagination>
        </v-toolbar>
                
              </v-card>
              </v-card>
            </v-col>
            <v-col cols="6">
                <v-card class="pa-2 mb-2 ml-1" outlined tile>
                  <v-card class="elevation-0 rounded-0"  style="margin-top: 0px;border: solid 1px #e0e0e0" >
              <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense >
               User List
               <v-spacer></v-spacer>
               <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        style="border: solid 1px #e0e0e0"
                        class="rounded-0 pa-1 elevation-0 ma-0"
                        color="#ffff"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ kFormatter(itemsPerPageUser)
                        }}<v-icon small style="margin-left: 0.2em"
                          >mdi-unfold-more-horizontal</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="itemsPerPageUser = 10"
                        ><v-list-item-title>10</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageUser = 25"
                        ><v-list-item-title>25</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageUser = 50"
                        ><v-list-item-title>50</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageUser = 100"
                        ><v-list-item-title>100</v-list-item-title></v-list-item
                      >
                    </v-list>
                  </v-menu>
              </v-toolbar>
                <v-divider></v-divider>
                <v-data-table
                  style="margin-top: 0px"
                  v-if="userList"
                  :search="searchUser"
                  :headers="headersUser"
                  :items="userList"
                  hide-default-footer
                  :page.sync="pageUser"
                  :items-per-page="itemsPerPageUser"
                  class="elevation-0 rounded-0 dataTable"
                  @page-count="pageCountUser = $event"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 4em;text-align:left"
                      >
                        {{ row.item.index + 1 }}
                      </td>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 6em;text-align:left"
                      >
                        {{ row.item[0] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        {{ row.item[1] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        <v-btn
                        
                          color="primary"
                          @click="addsummary_executed(false),DetailSummary(row.item[2],'USER LIST')"
                          role="button"
                          class="pingbtn elevation-0 rounded-0"
                          style="min-width: 7em;"
                          small
                          ><v-icon small>mdi-chevron-double-down</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-toolbar
          
          dense
          class="elevation-0 rounded-0"
          color="#F7F7F7"
        >
        
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="pageUser"
                    color="primary"
                    :length="pageCountUser"
                    :total-visible="7"
                ></v-pagination>
        </v-toolbar>
      </v-card>
              </v-card>
            </v-col>
            <v-col>
                <v-card class="pa-2 mr-1" outlined tile>
                  <v-card class="elevation-0 rounded-0"  style="margin-top: 0px;border: solid 1px #e0e0e0" >
                <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7"  dense>
                Company Group List
                <v-spacer></v-spacer>
                <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      style="border: solid 1px #e0e0e0"
                        small
                        class="rounded-0 pa-1 elevation-0 ma-0"
                        color="#ffff"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ kFormatter(itemsPerPageGroup)
                        }}<v-icon small style="margin-left: 0.2em"
                          >mdi-unfold-more-horizontal</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="itemsPerPageGroup = 10"
                        ><v-list-item-title>10</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageGroup = 25"
                        ><v-list-item-title>25</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageGroup = 50"
                        ><v-list-item-title>50</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageGroup = 100"
                        ><v-list-item-title>100</v-list-item-title></v-list-item
                      >
                    </v-list>
                  </v-menu>
              </v-toolbar>
                <v-divider></v-divider>
                <v-data-table
                  style="margin-top: 0px"
                  v-if="groupList"
                  :search="searchGroup"
                  :headers="headersGroup"
                  :items="groupList"
                  hide-default-footer
                  :page.sync="pageGroup"
                  :items-per-page="itemsPerPageGroup"
                  class="elevation-0 rounded-0 dataTable"
                  @page-count="pageCountGroup = $event"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 4em;text-align:left"
                      >
                        {{ row.item.index + 1 }}
                      </td>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 6em;text-align:left"
                      >
                        {{ row.item[0] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        {{ row.item[1] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        <v-btn
                       
                          color="primary"
                          @click="DetailCompany(row.item[2],)"
                          role="button"
                          class="pingbtn elevation-0 rounded-0"
                          style="min-width: 7em;"
                          small
                          ><v-icon small>mdi-chevron-double-right</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-toolbar
          
          dense
          class="elevation-0 rounded-0"
          color="#F7F7F7"
        >
        
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="pageGroup"
                    color="primary"
                    :length="pageCountGroup"
                    :total-visible="7"
                ></v-pagination>
        </v-toolbar>
                
              </v-card>
              </v-card>
            </v-col>
            <v-col>
                <v-card class="pa-2 ml-1" outlined tile>
                  <v-card class="elevation-0 rounded-0"  style="margin-top: 0px;border: solid 1px #e0e0e0" >
                <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7"  dense>
                {{CLtitle}}
              <v-spacer>
              </v-spacer>
                <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      style="border: solid 1px #e0e0e0"
                        small
                        class="rounded-0 pa-1 elevation-0 ma-0"
                        color="#ffff"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ kFormatter(itemsPerPageCompany)
                        }}<v-icon small style="margin-left: 0.2em"
                          >mdi-unfold-more-horizontal</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="itemsPerPageCompany = 10"
                        ><v-list-item-title>10</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageCompany = 25"
                        ><v-list-item-title>25</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageCompany = 50"
                        ><v-list-item-title>50</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageCompany = 100"
                        ><v-list-item-title>100</v-list-item-title></v-list-item
                      >
                    </v-list>
                  </v-menu>
                  <v-btn
             @click="fetch_CL()"
                   
              class="mx-2 rounded-0 elevation-0 pa-0"
              fab
              dark
              x-small
              color="primary"
            >
              <v-icon small dark> mdi-refresh </v-icon>
            </v-btn>
              </v-toolbar>
              <v-divider>
                </v-divider>
                <v-data-table
                  style="margin-top: 0px"
                  v-if="companyList"
                  :search="searchCompany"
                  :headers="headersCompany"
                  :items="companyList"
                  hide-default-footer
                  :page.sync="pageCompany"
                  :items-per-page="itemsPerPageCompany"
                  class="elevation-0 rounded-0 dataTable"
                  @page-count="pageCountCompany = $event"
                >
                  <template v-slot:item="row">
                    <tr>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 4em;text-align:left"
                      >
                        {{ row.item.index + 1 }}
                      </td>
                      <td
                        class="pa-0 pl-1"
                        style="white-space: nowrap; min-width: 6em;text-align:left"
                      >
                        {{ row.item[0] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        {{ row.item[1] }}
                      </td>
                      <td class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                        <v-btn
                         
                          color="primary"
                          @click="addsummary_executed(false),DetailSummary(row.item[2],'COMPANY LIST')"
                          role="button"
                          class="pingbtn elevation-0 rounded-0"
                          style="min-width: 7em;"
                          small
                          ><v-icon small>mdi-chevron-double-down</v-icon></v-btn
                        >
                      </td>
                    </tr>
                  </template>
                </v-data-table>
                <v-divider></v-divider>
                <v-toolbar
          
          dense
          class="elevation-0 rounded-0"
          color="#F7F7F7"
        >
        
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="pageCompany"
                    color="primary"
                    :length="pageCountCompany"
                    :total-visible="7"
                ></v-pagination>
        </v-toolbar>
                
              </v-card>
              </v-card>
            </v-col>
          </v-row>

          <v-progress-linear
            v-if="!listcrew && showcrewclick"
            indeterminate
            color="primary"
            height="7"
            striped
          ></v-progress-linear>
          <v-card class="mt-2 elevation-0 rounded-0" style="margin-top: 0px;border: solid 1px #e0e0e0" v-if="!testData && DTClick">
            <img
                 
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center ma-10"
              />  
          </v-card>
          <DetailSummary class="mt-2" v-if="testData"/>
          <div id="bottom_summary"></div>
        </v-card>
      </v-layout>
      
    </v-card>
   
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import loadingTable from "@/components/progress/loadingTable.vue";
import DetailSummary from "@/components/showpage/DetailSummary.vue";
import $ from "jquery";
export default {
  components: {
    loadingTable,
    DetailSummary
  },
  data() {
    return {
      search:'',
      itemsPerPage: 10,
      page: 1,
      pageCount: 0,
      loadernavplus: require("@/assets/img/load100new.gif"),
      headersFleetSummary: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Type", value: "type", width: "20%" },
        { text: "Amount", value: "amount", width: "50%" },
        { text: "", value: "", width: "50%" },
      ],
      searchSailink:'',
      itemsPerPageSailink: 10,
      pageSailink: 1,
      pageCountSailink: 0,
      headersSailink: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Type", value: "type", width: "20%" },
        { text: "Amount", value: "amount", width: "50%" },
        { text: "", value: "", width: "50%" },
      ],
      searchThuraya:'',
      itemsPerPageThuraya: 10,
      pageThuraya: 1,
      pageCountThuraya: 0,
      headersThuraya: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Type", value: "type", width: "20%" },
        { text: "Amount", value: "amount", width: "50%" },
        { text: "", value: "", width: "50%" },
      ],
      searchTerminal:'',
      itemsPerPageTerminal: 10,
      pageTerminal: 1,
      pageCountTerminal: 0,
      headersTerminal: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Type", value: "type", width: "20%" },
        { text: "Amount", value: "amount", width: "50%" },
        { text: "", value: "", width: "50%" },
      ],
      searchPhone:'',
      itemsPerPagePhone: 10,
      pagePhone: 1,
      pageCountPhone: 0,
      headersPhone: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Type", value: "type", width: "20%" },
        { text: "Amount", value: "amount", width: "50%" },
        { text: "", value: "", width: "50%" },
      ],
      searchRateplan:'',
      itemsPerPageRateplan: 10,
      pageRateplan: 1,
      pageCountRateplan: 0,
      headersRateplan: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Type", value: "type", width: "20%" },
        { text: "Amount", value: "amount", width: "50%" },
        { text: "", value: "", width: "50%" },
      ],
      searchUser:'',
      itemsPerPageUser: 10,
      pageUser: 1,
      pageCountUser: 0,
      headersUser: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Type", value: "type", width: "20%" },
        { text: "Amount", value: "amount", width: "50%" },
        { text: "", value: "", width: "50%" },
      ],
      searchGroup:'',
      itemsPerPageGroup: 10,
      pageGroup: 1,
      pageCountGroup: 0,
      headersGroup: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Type", value: "type", width: "20%" },
        { text: "Amount", value: "amount", width: "50%" },
        { text: "", value: "", width: "50%" },
      ],
      searchCompany:'',
      itemsPerPageCompany: 10,
      pageCompany: 1,
      pageCountCompany: 0,
      headersCompany: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Type", value: "type", width: "20%" },
        { text: "Amount", value: "amount", width: "50%" },
        { text: "", value: "", width: "50%" },
      ],
      searchIot:'',
      itemsPerPageIot: 10,
      pageIot: 1,
      pageCountIot: 0,
      headersIot: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Type", value: "type", width: "20%" },
        { text: "Amount", value: "amount", width: "50%" },
        { text: "", value: "", width: "50%" },
      ],
      searchSv:'',
      itemsPerPageSv: 10,
      pageSv: 1,
      pageCountSv: 0,
      headersSv: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Type", value: "type", width: "20%" },
        { text: "Amount", value: "amount", width: "50%" },
        { text: "", value: "", width: "50%" },
      ],
    };
  },

  mounted(){
    this.fetchUSERGET(localStorage.getItem("token"))
    this.fetchSummary(localStorage.getItem("token"));
  },

  methods: {
    ...mapActions(["addDTClick","fetchFS","fetchCL","fetchSummary","fetchUSERGET","detailSummary_","addsummary_executed","categorysummary_","detailCompany_"]),
    fetch_CL(){
      this.fetchCL(localStorage.getItem('token'))
    },
    fetch_FS(){
      this.fetchFS(localStorage.getItem('token'))
    },
    reload_page(){
    window.location.reload()
  },
    DetailSummary(param,category){
      $("html, body").animate(
        {
          scrollTop: $("#bottom_summary").offset().top - 109,
        },
        800
      );
      this.addDTClick(true)
      var data = {
        type : param,
        token: localStorage.getItem("token")
      }
      this.categorysummary_(category)
      this.detailSummary_(data)
    },

    DetailCompany(param){
      var data = {
        type : param,
        token: localStorage.getItem("token")
      }
      this.detailCompany_(data)
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
  },

  computed: {
    ...mapGetters(["OffSummary","DTClick","testData","dataDS","fleetSummary","sailinkSummary","thurayaSummary","terminalList","phoneList","rateplanList","userList","groupList","companyList","iotList","svList","CLtitle"]),
  },
};
</script>

<style lang="scss" scoped></style>
