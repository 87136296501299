/* eslint no-console: "error" */

const getDefaultState = () => {
  return {
    etaControl: false,
    showRouteData_sailink:null,
    showRouteData_thuraya:null,
  };
};

const state = getDefaultState();

const getters = {
  etaControl: (state) => state.etaControl,
  showRouteData_sailink:(state)=>state.showRouteData_sailink,
  showRouteData_thuraya:(state)=>state.showRouteData_thuraya
};

const actions = {

  set_etaControl({commit},data){
    commit("setetacontrol",data);
  },
  
  set_showRouteData_sailink({commit},data){
    commit("setshowRouteData_sailink",data);
  },

  set_showRouteData_thuraya({commit},data){
    commit("setshowRouteData_thuraya",data);
  }
};

const mutations = {
  setondraw: (state, onDraw) => (state.onDraw = onDraw),
  setetacontrol : (state,etaControl) =>(state.etaControl = etaControl),
  setshowRouteData_sailink: (state,showRouteData_sailink) =>(state.showRouteData_sailink = showRouteData_sailink),
  setshowRouteData_thuraya: (state,showRouteData_thuraya) =>(state.showRouteData_thuraya = showRouteData_thuraya),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
