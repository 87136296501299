<template>
<v-dialog  v-model="trackOpen" width="fit-content" persistent>
    <v-card width="500" class="elevation-0 rounded-0">
      <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense >
       Tracking Interval Config

        <v-spacer></v-spacer>

        <v-btn small class="elevation-0 rounded-0" icon  @click="closeTrack()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar
      v-if="trackData"
        color="#fff"
        style="text-align:left"
        class="elevation-0 rounded-0"
        dense
      >
     
          {{ trackData.vessel_number }}
       
          

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-toolbar
      v-if="trackData.latest_status != ''"
        color="#fff"
        style="text-align:left"
        class="elevation-0 rounded-0"
        height="30"
      >
          <div v-if="trackData" style="text-align: left">
            <v-chip
              v-if="trackData.latest_status == 'FAILED'"
              class="ma-0 elevation-0 rounded-0"
              color="red"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-close-thick </v-icon>
              Failed to change Settings at {{ trackData.last_updated }}, please
              resubmit
            </v-chip>
            <v-chip
              v-if="trackData.latest_status == 'ON AIR'"
              class="ma-0 elevation-0 rounded-0"
              color="yellow"
              x-small
              label
              text-color="black"
            >
              <v-icon x-small left> mdi-clock </v-icon>
              Waiting for Confirmation, sent at {{ trackData.last_updated }}
            </v-chip>
            <v-chip
              v-if="trackData.latest_status == 'CONFIRMED'"
              class="ma-0 elevation-0 rounded-0"
              color="green"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-check-bold </v-icon>
              Updated at {{ trackData.last_updated }}
            </v-chip>
          </div>
       

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider v-if="trackData"></v-divider>
    

      <v-container style="margin-top:0" v-if="!trackData" fill-height fluid>
        <v-row align="center" justify="center">
          <v-col
            ><img
              alt="Navplus logo"
              width="70px"
              :src="loadernavplus"
              class="justify-center"
          /></v-col>
        </v-row>
      </v-container>

      <v-card-text style="margin-top: 2em" v-if="trackData">
        <v-card class="rounded-0" elevation="0">
          <v-toolbar style="border: solid 1px #e0e0e0" class="rounded-0" color="#f7f7f7" elevation="0">
            <v-toolbar-subtitle class="mr-3"
              >Interval (minutes)</v-toolbar-subtitle
            >
            <v-spacer></v-spacer>
            <v-text-field
            @input="checkdata()"
            style="border: solid 1px #e0e0e0;background-color:#fff"
              width="40"
              hide-details
              solo
              :disabled="user_allowed.allowed['edit tracking'] || trackData.latest_status != 'ON AIR'? false:true"
              class="elevation-0 rounded-0 pr-2"
              v-model="trackData.latest_content"
              type="number"
              min="3"
              max="14400"
              :value="trackData.latest_content"
              persistent-hint
              oninput="if(Number(this.value) > Number(this.max)) this.value = this.max;"

            ></v-text-field>
         
          </v-toolbar>
          <v-card-subtitle style="text-align: left; margin-left: -17px"
            >Between 1 - 14400 Minutes</v-card-subtitle
          >
        </v-card>
      
          <v-btn
            class="elevation-0 rounded-0 intervalbtn_thurayadesktop "
            v-if="trackData.latest_status != 'ON AIR'"
            @click="
              user_allowed.allowed['edit tracking']
                ? trackDataPost()
                : console.log('disabled')
            "
            style="margin-top: 0px"
            :disabled="user_allowed.allowed['edit tracking'] && changes_ ? false : true"
            color="primary" 
            block
            small
          >
            Update Tracking Interval Configuration
          </v-btn>
          <v-btn
            class="elevation-0 rounded-0 intervalbtn_thurayadesktop"
            v-if="trackData.latest_status == 'ON AIR'"
            style="margin-top: 0px"
            disabled
            color="primary"
            block
            small
          >
            Update Tracking Interval Configuration
          </v-btn>


          <v-btn
            class="elevation-0 rounded-0 intervalbtn_thurayamobile "
            v-if="trackData.latest_status != 'ON AIR'"
            @click="
              user_allowed.allowed['edit tracking']
                ? trackDataPost()
                : console.log('disabled')
            "
            style="margin-top: 0px"
            :disabled="user_allowed.allowed['edit tracking'] && changes_ ? false : true"
            color="primary" 
            block
            small
          >
            Update Tracking Interval
          </v-btn>
          <v-btn
            class="elevation-0 rounded-0 intervalbtn_thurayamobile"
            v-if="trackData.latest_status == 'ON AIR'"
            style="margin-top: 0px"
            disabled
            color="primary"
            block
            small
          >
            Update Tracking Interval 
          </v-btn>
      </v-card-text>
    </v-card>
  </v-dialog>    
</template>
  
  <script>
  import { mapGetters, mapActions } from "vuex";
  import axios from "../../plugins/axios";
import $ from "jquery";
  export default {
    data() {
      return {
        trackmodal: null,
        change: false,
        loadernavplus: require("@/assets/img/load100new.gif"),
        fetchInterval: null,
      };
    },
    mounted() {
      this.trackFetchStatic()
    },
    methods: {
      ...mapActions(["trackFetch", "trackPost", "trackRemove"]),
      async trackDataPost() {
        
        const data = {
              thuraya_number: this.trackData.number,
              interval: this.intervalData,
              token: localStorage.getItem("token"),
        };

        this.trackPost(data)

        var mother = this;
        var intervalSeconds = 0.5;
        this.fetchInterval = setInterval(function () {
          mother.trackDataFetch();
        }, intervalSeconds * 1000);
      },

      checkdata() {
      console.log('TRACK MODAL')
      console.log(this.trackmodal['latest_content']);
      console.log('INTERVAL DATA')
      console.log(this.trackData.latest_content);
      if (this.trackmodal.latest_content == this.trackData.latest_content) {
        this.change = false;
      } else {
        this.change = true;
      }
    },
  
      stopFetch() {
        console.log("STOP FETCH STATUS WHITELIST");
        clearInterval(this.fetchInterval);
        this.on = false;
      },
 
      closeTrack(){
        this.stopFetch()
        this.trackRemove()
      },
  
      async trackDataFetch() {
        console.log("FETCH TERUS STATUS ADMIN PASSWORD");
        console.log("STATUS :");
        console.log(this.trackData.latest_status);
        // this.wlModal(true);
        const data = {
          id: this.trackID,
          token: localStorage.getItem("token"),
        };
        this.trackFetch(data);
        var mother=this
        setTimeout(function () {
       


        if (mother.trackData) {
          if (mother.trackData.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.trackFetchStatic();
          }
        } else {
          if (mother.trackmodal.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.trackFetchStatic();
          }
        }
      }, 5000);
       
      },

      async trackFetchStatic() {
      console.log(this.trackID)
      try {
        const response = await axios.get(
          "/api/v1/navplus/thuraya/" +
          this.trackID +
            "/interval",
          {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
            },
          }
        );
       

        console.log(response)
        
        this.trackmodal = response.data.data;
        var mother = this;
        var intervalSeconds = 0.5;
        if (response.data.data.latest_status == "ON AIR") {
          this.fetchInterval = setInterval(function () {
            mother.trackDataFetch();
          }, intervalSeconds * 1000);
        }
      } catch (e) {
        console.log(e);
      }
    },
    },
  
    computed: {
      ...mapGetters(["trackData", "trackID", "trackOpen","intervalData","user_allowed"]),
      changes_() {
      return this.change;
    },
    },
  };
  </script>
  
  <style lang="scss" scoped>

@media (min-width: 280px) and (max-width: 319px) {
  .intervalbtn_thurayadesktop{
    display:none
  }
  .intervalbtn_thurayamobile{
    display:block
  }
}

/* Mobile Devices */
@media (min-width: 320px) and (max-width: 480px) {
  .intervalbtn_thurayadesktop{
    display:none
  }
  .intervalbtn_thurayamobile{
    display:block
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .intervalbtn_thurayadesktop{
    display:block
  }
  .intervalbtn_thurayamobile{
    display:none
  }
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .intervalbtn_thurayadesktop{
    display:block
  }
  .intervalbtn_thurayamobile{
    display:none
  }
}

/* Desktops, large screens */
@media (min-width: 1025px) and (max-width: 1200px){
  .intervalbtn_thurayadesktop{
    display:block
  }
  .intervalbtn_thurayamobile{
    display:none
  }
}

/* Extra large screens / TV */
@media screen and (min-width: 1201px) {
  .intervalbtn_thurayadesktop{
    display:block
  }
  .intervalbtn_thurayamobile{
    display:none
  }


}
</style>
  
 
 
 
 
 
 
 

