<template>
  <v-dialog v-model="vdOpen" width="500" persistent>
    <v-card class="elevation-0 rounded-0 pb-0" style="overflow-y: hidden">
      <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0" dense>
        Vessel Detail Config

        <v-spacer></v-spacer>

        <v-btn small class="elevation-0 rounded-0" icon @click="closevdModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar v-if="vdData" color="#FFFF" class="elevation-0 rounded-0">
        <v-toolbar-subtitle style="text-align: left"
          >{{ vdData.vessel_number }}
          <br />
          <div v-if="vdData" style="text-align: left">
            <v-chip
              v-if="vdData.latest_status == 'FAILED'"
              class="ma-0 elevation-0 rounded-0"
              color="red"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-close-thick </v-icon>
              Failed to change Settings at {{ vdData.last_updated }}, please
              resubmit
            </v-chip>
            <v-chip
              v-if="vdData.latest_status == 'ON AIR'"
              class="ma-0 elevation-0 rounded-0"
              color="yellow"
              x-small
              label
              text-color="black"
            >
              <v-icon x-small left> mdi-clock </v-icon>
              Waiting for Confirmation, sent at {{ vdData.last_updated }}
            </v-chip>
            <v-chip
              v-if="vdData.latest_status == 'CONFIRMED'"
              class="ma-0 elevation-0 rounded-0"
              color="green"
              x-small
              label
              text-color="white"
            >
              <v-icon x-small left> mdi-check-bold </v-icon>
              Updated at {{ vdData.last_updated }}
            </v-chip>
          </div>
        </v-toolbar-subtitle>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider v-if="vdData"></v-divider>
      <v-container style="margin-top: 2em" v-if="!vdmodal" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>

      <v-card-text
      v-if="vdmodal"
        id="div_vd"
        :style="vdData ? 'height:450px' : ''"
        width="100"
        class="overflow-y-auto"
      >
        <v-container style="margin-top: 2em" v-if="!vdData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>

        <div v-if="vdData" class="ma-0 pa-0">
          <div
            class="elevation-0 rounded-0 mt-2"
            v-if="vdData.latest_status != 'ON AIR'"
          >
            <v-form v-model="valid" lazy-validation>
              <v-row>
                <v-col cols="4">
                  <v-subheader>IMO</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    @input="checkdata('imo', vdData.latest_content.imo)"
                    :disabled="
                      user_allowed.allowed['edit vessel detail'] ? false : true
                    "
                    class="elevation-0 rounded-0"
                    style="margin-left: -20px"
                    :value="vdData.latest_content.imo"
                    v-model="vdData.latest_content.imo"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row style="margin-top: -3em">
                <v-col cols="4">
                  <v-subheader>MMSI</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    @input="checkdata('mmsi', vdData.latest_content.mmsi)"
                    :disabled="
                      user_allowed.allowed['edit vessel detail'] ? false : true
                    "
                    class="elevation-0 rounded-0"
                    style="margin-left: -20px"
                    :value="vdData.latest_content.mmsi"
                    v-model="vdData.latest_content.mmsi"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row style="margin-top: -3em">
                <v-col cols="4">
                  <v-subheader>Callsign</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    @input="
                      checkdata('callsign', vdData.latest_content.callsign)
                    "
                    :disabled="
                      user_allowed.allowed['edit vessel detail'] ? false : true
                    "
                    class="elevation-0 rounded-0"
                    style="margin-left: -20px"
                    :value="vdData.latest_content.callsign"
                    v-model="vdData.latest_content.callsign"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row style="margin-top: -3em">
                <v-col cols="4">
                  <v-subheader style="text-align: left"
                    >Vessel Type</v-subheader
                  >
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    @input="checkdata('type', vdData.latest_content.type)"
                    :disabled="
                      user_allowed.allowed['edit vessel detail'] ? false : true
                    "
                    class="elevation-0 rounded-0"
                    style="margin-left: -20px"
                    :value="vdData.latest_content.type"
                    v-model="vdData.latest_content.type"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row style="margin-top: -3em">
                <v-col cols="4">
                  <v-subheader style="text-align:left">Gross Tonnage</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    @input="
                      checkdata(
                        'gross_tonnage',
                        vdData.latest_content.gross_tonnage
                      )
                    "
                    :disabled="
                      user_allowed.allowed['edit vessel detail'] ? false : true
                    "
                    class="elevation-0 rounded-0"
                    style="margin-left: -20px"
                    :value="vdData.latest_content.gross_tonnage"
                    v-model="vdData.latest_content.gross_tonnage"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row style="margin-top: -3em">
                <v-col cols="4">
                  <v-subheader>Flag State</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    @input="
                      checkdata('flag_state', vdData.latest_content.flag_state)
                    "
                    :disabled="
                      user_allowed.allowed['edit vessel detail'] ? false : true
                    "
                    class="elevation-0 rounded-0"
                    style="margin-left: -20px"
                    :value="vdData.latest_content.flag_state"
                    v-model="vdData.latest_content.flag_state"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row style="margin-top: -3em">
                <v-col cols="4">
                  <v-subheader style="text-align: left"
                    >Vessel Owner<br />Company</v-subheader
                  >
                </v-col>
                <v-col cols="8" style="margin-left: -20px">
                  <v-text-field
                    @input="checkdata('owner', vdData.latest_content.owner)"
                    :disabled="
                      user_allowed.allowed['edit vessel detail'] ? false : true
                    "
                    class="elevation-0 rounded-0"
                    :value="vdData.latest_content.owner"
                    v-model="vdData.latest_content.owner"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row style="margin-top: -3em">
                <v-col cols="4">
                  <v-subheader>First CSO</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    @input="
                      checkdata('cso_first', vdData.latest_content.cso_first)
                    "
                    :disabled="
                      user_allowed.allowed['edit vessel detail'] ? false : true
                    "
                    class="elevation-0 rounded-0"
                    style="margin-left: -20px"
                    :value="vdData.latest_content.cso_first"
                    v-model="vdData.latest_content.cso_first"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row style="margin-top: -3em">
                <v-col cols="4">
                  <v-subheader style="text-align:left">Second CSO</v-subheader>
                </v-col>
                <v-col cols="8">
                  <v-text-field
                    @input="
                      checkdata('cso_second', vdData.latest_content.cso_second)
                    "
                    :disabled="
                      user_allowed.allowed['edit vessel detail'] ? false : true
                    "
                    class="elevation-0 rounded-0"
                    style="margin-left: -20px"
                    :value="vdData.latest_content.cso_second"
                    v-model="vdData.latest_content.cso_second"
                    outlined
                    dense
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-btn
                :disabled="changes_ ? false : true"
                small
                :style="
                  user_allowed.allowed['edit vessel detail']
                    ? 'cursor:pointer'
                    : 'cursor:not-allowed'
                "
                class="elevation-0 rounded-0"
                :color="
                  user_allowed.allowed['edit vessel detail']
                    ? 'primary'
                    : '#7694B6'
                "
                block
                @click="
                  user_allowed.allowed['edit vessel detail']
                    ? vdDataPost()
                    : console.log('disabled')
                "
              >
                Update Vessel Detail
              </v-btn>
            </v-form>
          </div>
          <div
            class="elevation-0 rounded-0 mt-2"
            v-if="vdData.latest_status == 'ON AIR'"
          >
            <v-row>
              <v-col cols="4">
                <v-subheader>IMO</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  class="elevation-0 rounded-0"
                  style="margin-left: -20px"
                  :value="vdData.latest_content.imo"
                  v-model="vdData.latest_content.imo"
                  filled
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row style="margin-top: -3em">
              <v-col cols="4">
                <v-subheader>MMSI</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  class="elevation-0 rounded-0"
                  style="margin-left: -20px"
                  :value="vdData.latest_content.mmsi"
                  v-model="vdData.latest_content.mmsi"
                  filled
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row style="margin-top: -3em">
              <v-col cols="4">
                <v-subheader>Callsign</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  class="elevation-0 rounded-0"
                  style="margin-left: -20px"
                  :value="vdData.latest_content.callsign"
                  v-model="vdData.latest_content.callsign"
                  filled
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row style="margin-top: -3em">
              <v-col cols="4">
                <v-subheader style="text-align: left">Vessel Type</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  class="elevation-0 rounded-0"
                  style="margin-left: -20px"
                  :value="vdData.latest_content.type"
                  v-model="vdData.latest_content.type"
                  filled
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row style="margin-top: -3em">
              <v-col cols="4">
                <v-subheader style="text-align: left">Gross Tonnage</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  class="elevation-0 rounded-0"
                  style="margin-left: -20px"
                  :value="vdData.latest_content.gross_tonnage"
                  v-model="vdData.latest_content.gross_tonnage"
                  filled
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row style="margin-top: -3em">
              <v-col cols="4">
                <v-subheader>Flag State</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  class="elevation-0 rounded-0"
                  style="margin-left: -20px"
                  :value="vdData.latest_content.flag_state"
                  v-model="vdData.latest_content.flag_state"
                  filled
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row style="margin-top: -3em">
              <v-col cols="4">
                <v-subheader style="text-align: left"
                  >Vessel Owner<br />
                  Company</v-subheader
                >
              </v-col>
              <v-col cols="8" style="margin-left: -20px">
                <v-text-field
                  class="elevation-0 rounded-0"
                  :value="vdData.latest_content.owner"
                  v-model="vdData.latest_content.owner"
                  filled
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row style="margin-top: -3em">
              <v-col cols="4">
                <v-subheader style="text-align: left">First CSO</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  class="elevation-0 rounded-0"
                  style="margin-left: -20px"
                  :value="vdData.latest_content.cso_first"
                  v-model="vdData.latest_content.cso_first"
                  filled
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row style="margin-top: -3em">
              <v-col cols="4">
                <v-subheader style="text-align: left">Second CSO</v-subheader>
              </v-col>
              <v-col cols="8">
                <v-text-field
                  class="elevation-0 rounded-0"
                  style="margin-left: -20px"
                  :value="vdData.latest_content.cso_second"
                  v-model="vdData.latest_content.cso_second"
                  filled
                  outlined
                  dense
                  disabled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-btn
              small
              style="cursor: not-allowed"
              class="elevation-0 rounded-0"
              color="primary"
              disabled
              block
            >
              Update Vessel Detail
            </v-btn>
          </div>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "../../plugins/axios";
import $ from "jquery";
const defaultForm = [];
export default {
  data() {
    return {
      vdmodal: null,
      change: false,
      valid: false,
      loadernavplus: require("@/assets/img/load100new.gif"),
      fetchInterval: null,
    };
  },
  mounted() {
    defaultForm.push(this.vdData);
    this.vdFetchStatic();
  },
  methods: {
    ...mapActions(["vdFetch", "vdPost", "vdRemove"]),

    checkdata(statis, value) {
      // console.log(value)
      // console.log(this.vdmodal['latest_content'][statis]);
      // console.log(this.vdData);
      if (this.vdmodal["latest_content"][statis] == value) {
        this.change = false;
      } else {
        this.change = true;
      }
    },

    async vdDataPost() {
      $("#div_vd").scrollTop(0);

      this.change = false;
      const data = {
        thuraya_number: this.vdData.number,
        imo: this.vdData.latest_content.imo,
        mmsi: this.vdData.latest_content.mmsi,
        callsign: this.vdData.latest_content.callsign,
        type: this.vdData.latest_content.type,
        gross_tonnage: this.vdData.latest_content.gross_tonnage,
        flag_state: this.vdData.latest_content.flag_state,
        owner: this.vdData.latest_content.owner,
        cso_first: this.vdData.latest_content.cso_first,
        cso_second: this.vdData.latest_content.cso_second,
        token: localStorage.getItem("token"),
        id: this.vdData.id,
      };
      this.vdPost(data);
      var mother = this;
      var intervalSeconds = 0.5;
      this.fetchInterval = setInterval(function () {
        mother.vdDataFetch();
      }, intervalSeconds * 1000);
    },

    closevdModal() {
      this.vdRemove();
      this.stopFetch();
    },

    stopFetch() {
      console.log("STOP FETCH STATUS VESSEL DATA");
      clearInterval(this.fetchInterval);
      this.on = false;
    },

    async vdDataFetch() {
      console.log("FETCH TERUS STATUS VESSEL DETAIL");
      console.log("STATUS :");
      console.log(this.vdData.latest_status);
      const data = {
        id: this.vdID,
        token: localStorage.getItem("token"),
      };
      this.vdFetch(data);
      var mother = this;
      setTimeout(function () {
        if (mother.vdData) {
          if (mother.vdData.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.vdFetchStatic();
          }
        } else {
          if (mother.vdmodal.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.vdFetchStatic();
          }
        }
      }, 5000);
    },

    async vdFetchStatic() {
      console.log(this.vdID)
      try {
        const response = await axios.get(
          "/api/v1/navplus/thuraya/" + this.vdID + "/vessel_detail",
          {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
            },
          }
        );

        console.log(response)
        
        this.vdmodal = response.data.data;
        var mother = this;
        var intervalSeconds = 0.5;
        if (response.data.data.latest_status == "ON AIR") {
          this.fetchInterval = setInterval(function () {
            mother.vdDataFetch();
          }, intervalSeconds * 1000);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },

  computed: {
    ...mapGetters(["vdData", "vdOpen", "vdID", "user_allowed"]),
    changes_() {
      return this.change;
    },
  },
};
</script>

<style lang="scss" scoped>



.v-subheader {
    align-items: center;
    display: flex;
    height: 48px;
    font-size: 0.875rem;
    font-weight: 400;
    padding: 0px !important;
    /* text-align: left; */
}
</style>
