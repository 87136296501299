/* eslint no-console: "error" */
import axios from "../../plugins/axios";

const getDefaultState = () => {
    return {
        dates: [],
        times: [],
        timein: null,
        dateopen: false,
        timeopen: false,
        picker_disabled:false,
        datepickerID:null,
    }
}

const state = getDefaultState();

const getters = {
  
    dates:(state)=>state.dates,
    times:(state)=>state.times,
    timein:(state)=>state.timein,
    dateopen:(state)=>state.dateopen,
    timeopen:(state)=>state.timeopen,
    picker_disabled:(state)=>state.picker_disabled,
    datepickerID:(state)=>state.datepickerID
};

const actions = {


    open_timepicker({commit}) {
        console.log('DI CLICK')
    

        commit("set_picker_disabled", true);
        commit("set_timeopen", true);
    },

    open_datepicker({commit},data){
        console.log('COSTUM RANGE DI BUKA')
        commit("set_dateopen", true);
        commit("set_datepickerID", data);
    },

    saveTimes({commit},data) {
  
        getDefaultState.times.push(data);
        commit("set_timein", null);
        commit("set_timeopen", false);
        commit("set_picker_disabled", false);

    },

  
    closeCostumRange({commit}) {
        console.log('CLOSE COSTUM RANGE');
        commit("set_dateopen",null);
        getDefaultState.dates = [];
        getDefaultState.times = [];
      },

      removeDateopen({commit}){
        console.log('SET DATE OPEN');
        commit("set_dateopen",null);
      },

      openTimeopen({commit}){
        commit("set_timeopen", true);
      },
      
      resetTimesDates() {
        console.log('di reset')
        getDefaultState.dates = [];
        getDefaultState.times = [];
      },


};

const mutations = {
    set_picker_disabled:(state, picker_disabled) => (state.picker_disabled = picker_disabled),
    set_datepickerID:(state, datepickerID) => (state.datepickerID = datepickerID),
    set_timeopen:(state, timeopen) => (state.timeopen = timeopen),
    set_dateopen:(state, dateopen) => (state.dateopen = dateopen),
    set_timein:(state, timein) => (state.timein = timein),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
