import axios from "../../plugins/axios";

const getDefaultState = () => {
  return {
    thuraya_data: null,
    showmapthuraya: null,
    showmaptracking_thur: null,
    showthurayaclick: null,
    thurayadataTable: null,
    searchIDThuraya: null,
    searchThuraya: null,
    wlID: null,
    vdID: null,
    mnID: null,
    admID: null,
    sosID: null,
    trackData: null,
    intervalData: null,
    vesselUsersThuraya: null,
    rpmreportID: null,
    nameThuraya: null,
    CallCreated_: null,
    ChatCreated_: null,
    CallVessel_: null,
    maptop_thur: null,
    clickdate: null,
    NameThurOpen_: null,
    nameData_Thur: null,
    //CONFIG BUTTON
    //Whitelist
    wlOpen: null,
    wlData: null,
    //Vesseldetail
    vdOpen: null,
    vdData: null,
    vdStatic: null,
    //Manifestlisting
    mnOpen: null,
    mnData: null,

    //Reboot
    rbOpen: null,
    rbID: null,
    rbData: null,

    //SOS BUTTON
    //history
    hsOpen: null,
    hsData: null,

    //geofencing
    gfOpen: null,
    gfData: null,
    gfpolyPath: [],
    gfID: null,

    //sos contact list
    sosData: null,
    sosOpen: null,

    //TRACKING BUTTON
    //gpsmail
    gpsOpen: null,
    gpsData: null,
    gpsID: null,
    gpssend: null,

    //chROOM
    msgopen: null,
    msgData: null,
    chData: null,

    //admin password
    admOpen: null,
    admData: null,

    //tracking interval
    trackOpen: null,
    trackData: null,
    trackID: null,
    intervalData: null,
    trackline_thur: null,

    //callTHR
    callopen: null,

    //cdr Data
    cdrOpen: null,
    cdrData: null,
    cdrID: null,
    cdrChart_: null,
    cdrOutput_: null,
    OffMythuraya: null,

    expthuraya_title: null,

    speedaltopen_thur: null,
    speedaltData_thur: null,
    speedaltID_thur: null,
    speedalt_success_thur: null,
    isEnabled_speed_thur: null,
  };
};

const state = getDefaultState();

const getters = {
  thurayaData: (state) => state.thuraya_data,
  vesselUsersThuraya: (state) => state.vesselUsersThuraya,
  showmapthuraya: (state) => state.showmapthuraya,
  showmaptracking_thur: (state) => state.showmaptracking_thur,
  showthurayaclick: (state) => state.showthurayaclick,
  thurayadataTable: (state) => state.thurayadataTable,
  searchIDThuraya: (state) => state.searchIDThuraya,
  searchThuraya: (state) => state.searchThuraya,
  wlID: (state) => state.wlID,
  mnID: (state) => state.mnID,
  trackData: (state) => state.trackData,
  intervalData: (state) => state.intervalData,
  rpmreportID: (state) => state.rpmreportID,
  nameThuraya: (state) => state.nameThuraya,
  CallCreated_: (state) => state.CallCreated_,
  ChatCreated_: (state) => state.ChatCreated_,
  CallVessel_: (state) => state.CallVessel_,
  maptop_thur: (state) => state.maptop_thur,
  clickdate: (state) => state.clickdate,
  NameThurOpen_: (state) => state.NameThurOpen_,
  nameData_Thur: (state) => state.nameData_Thur,
  vdID: (state) => state.vdID,
  admID: (state) => state.admID,
  sosID: (state) => state.sosID,
  OffMythuraya: (state) => state.OffMythuraya,
  expthuraya_title: (state) => state.expthuraya_title,
  trackline_thur: (state) => state.trackline_thur,

  //CONFIG BUTTON
  //Whitelist
  wlOpen: (state) => state.wlOpen,
  wlData: (state) => state.wlData,
  //Reboot
  rbOpen: (state) => state.rbOpen,
  rbID: (state) => state.rbID,
  rbData: (state) => state.rbData,
  //VesselDetail
  vdOpen: (state) => state.vdOpen,
  vdData: (state) => state.vdData,
  vdStatic: (state) => state.vdStatic,
  //SOS BUTTON
  //history
  hsOpen: (state) => state.hsOpen,
  hsData: (state) => state.hsData,

  //TRACKING BUTTON
  //gpsmail
  gpsOpen: (state) => state.gpsOpen,
  gpsData: (state) => state.gpsData,
  gpsID: (state) => state.gpsID,
  gpssend: (state) => state.gpssend,

  //CHATROOM
  chData: (state) => state.chData,
  msgopen: (state) => state.msgopen,
  msgData: (state) => state.msgData,

  //callTHR
  callopen: (state) => state.callopen,

  //sos Contact list
  sosData: (state) => state.sosData,
  sosOpen: (state) => state.sosOpen,

  //Admin Password
  admOpen: (state) => state.admOpen,
  admData: (state) => state.admData,

  mnOpen: (state) => state.mnOpen,
  mnData: (state) => state.mnData,

  //interval
  trackOpen: (state) => state.trackOpen,
  trackData: (state) => state.trackData,
  trackID: (state) => state.trackID,
  intervalData: (state) => state.intervalData,

  //cdrData
  cdrOpen: (state) => state.cdrOpen,
  cdrData: (state) => state.cdrData,
  cdrID: (state) => state.cdrID,
  cdrChart_: (state) => state.cdrChart_,
  cdrOutput_: (state) => state.cdrOutput_,

  //geofencing
  gfOpen: (state) => state.gfOpen,
  gfData: (state) => state.gfData,
  gfID: (state) => state.gfID,
  gfpolyPath: (state) => state.gfpolyPath,

  speedaltopen_thur: (state) => state.speedaltopen_thur,
  speedaltData_thur: (state) => state.speedaltData_thur,
  speedaltID_thur: (state) => state.speedaltID_thur,
  speedalt_success_thur: (state) => state.speedalt_success_thur,
  isEnabled_speed_thur: (state) => state.isEnabled_speed_thur,
};

const actions = {
  unsetMyThuraya({ commit }) {
    commit("setThurayaData", null);
  },
  async fetchThuraya({ commit }, data) {
    try {
      const response = await axios.get("/api/v1/navplus/thuraya", {
        headers: {
          Authorization: "Bearer" + data,
        },
      });
      commit(
        "setThurayaData",
        response.data.data.vessels.map((item, index) =>
          Object.assign(item, { index })
        )
      );
      commit("setOffMythuraya", null);
    } catch (e) {
      commit("setOffMythuraya", true);
    }
  },

  async showonMapThuraya({ commit }, data) {
    commit("setmaptop", null);
    commit("setshowthurayaclick", true);
    commit("setshowmapthuraya", null);
    commit("setshowmaptracking_thur", null);
    commit("setthurayadataTable", null);
    commit("setvesselUsersThuraya", null);
    const response = await axios.get("/api/v1/navplus/thuraya/" + data.id, {
      headers: {
        Authorization: "Bearer" + data.token,
      },
    });
    //console.log('MAP THURAYA')
    //console.log(response);
    var showmapthuraya_ = response.data.data;
    //console.log(showmapthuraya_)
    commit(
      "setvesselUsersThuraya",
      response.data.data.vesselUsers.map((item, index) =>
        Object.assign(item, { index })
      )
    );
    commit("setshowmapthuraya", response.data.data);
    commit("setshowmaptracking_thur", showmapthuraya_);

    commit("setmaptop", response.data.data.maptop);
    commit("setclickdate", null);

    commit(
      "setthurayadataTable",
      response.data.data.dataTable.map((item, index) =>
        Object.assign(item, { index })
      )
    );
  },

  setNameThuraya({ commit }, data) {
    commit("setnamethuraya", data);
  },

  inputRPMID({ commit }, data) {
    //console.log('JALAN BUKA RPM')
    //console.log(data)
    commit("setrpmid", data);
  },

  removeShowThuraya({ commit }) {
    commit("setshowthurayaclick", null);
    commit("setshowmapthuraya", null);
    commit("setthurayadataTable", null);
    commit("setsearchThuraya", null);
    commit("setsearchIDThuraya", null);
  },

  async showdateThuraya({ commit }, data) {
    console.log("JALAN SAMPE DATE DI MY THURAYA VIEW");
    commit("setshowmaptracking_thur", null);
    commit("setthurayadataTable", null);
    //console.log(data)
    console.log("Range costum");
    console.log(data.range);
    const datasend = {
      id: data.id,
      range: data.range,
    };
    const response = await axios.post(
      "/api/v1/navplus/thuraya/date",
      datasend,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    //console.log(response)
    commit("setshowmaptracking_thur", response.data.data);
    commit("setclickdate", null);

    commit(
      "setthurayadataTable",
      response.data.data.dataTable.map((item, index) =>
        Object.assign(item, { index })
      )
    );
  },

  async showtrackline_thur({ commit }, data) {
    commit("settrackline_thur", null);
    const datadate = {
      id: data.id,
      range: data.range,
    };
    const response = await axios.post(
      "/api/v1/navplus/thuraya/date/trackingOnly",
      datadate,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log("masuk ambil tracking");
    console.log(response);
    commit("settrackline_thur", await response.data.data);
  },

  async DataInterval({ commit }, data) {
    const response = await axios.get(
      "/api/v1/navplus/thuraya/" + data.id + "/interval",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    commit("setwlID", data.id);
    commit("settrackData", response.data.data);
    commit("setintervalData", response.data.data.latest_content);
  },

  searchIDThuraya_Input({ commit }, data) {
    commit("setsearchIDThuraya", data);
  },

  searchThurayaInput({ commit }, data) {
    commit("setsearchThuraya", data);
  },

  removeThuraya({ commit }) {
    commit("setsearchThuraya", null);
  },
  //CONFIG BUTTON

  //Whitelist
  wlModal({ commit }, data) {
    commit("setwlopen", data);
  },

  rbModal({ commit }, data) {
    commit("setrbopen", data);
  },

  wlRemove({ commit }) {
    commit("setwlopen", null);
    commit("setwlData", null);
  },

  gfRemove({ commit }) {
    commit("setgfOpen", null);
    commit("setgfData", null);
    commit("setgfID", null);
  },

  async wlFetch({ commit }, data) {
    commit("setwlID", data.id);
    const response = await axios.get(
      "/api/v1/navplus/thuraya/" + data.id + "/whitelist",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    //console.log(response);
    commit("setwlData", response.data.data);
  },

  async rbFetch({ commit }, data) {
    commit("setrbID", data.id);
    const response = await axios.get(
      "/api/v1/navplus/thuraya/" + data.id + "/reboot",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    commit("setrbData", response.data.data);
  },

  async admPost({ commit }, data) {
    console.log(data);
    const dataadm = {
      thuraya_number: data.thuraya_number,
      passRadio: data.passRadio,
    };
    console.log(dataadm);
    const response = await axios.post("/api/v1/navplus/thuraya/pass", dataadm, {
      headers: {
        Authorization: "Bearer" + data.token,
      },
    });
    console.log(response);
    console.log(commit);
    commit("setadmData", response.data.data);
  },

  async rbPost({ commit }, data) {
    console.log(data);
    const datarb = {
      thuraya_number: data.thuraya_number,
    };
    console.log(datarb);
    const response = await axios.post(
      "/api/v1/navplus/thuraya/reboot",
      datarb,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    console.log(commit);
    commit("setrbData", response.data.data);
  },

  async admFetch({ commit }, data) {
    commit("setadmID", data.id);
    commit("setadmopen", true);
    const response = await axios.get(
      "/api/v1/navplus/thuraya/" + data.id + "/pass",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );

    commit("setadmData", response.data.data);
  },

  admRemove({ commit }) {
    commit("setadmopen", null);
    commit("setadmData", null);
  },

  rbRemove({ commit }) {
    commit("setrbopen", null);
    commit("setrbData", null);
    commit("setrbID", null);
  },

  trackRemove({ commit }) {
    commit("settrackopen", null);
    commit("settrackdata", null);
    commit("settrackinterval", null);
    commit("settrackid", null);
  },

  async cdrFetch({ commit }, data) {
    commit("setcdrOpen", true);
    commit("setcdrData", null);
    console.log(data.id);
    const datacdr = {
      thuraya_number: data.thuraya_number,
      range: data.range,
    };
    const response = await axios.post("/api/v1/navplus/thuraya/cdr", datacdr, {
      headers: {
        Authorization: "Bearer" + data.token,
      },
    });
    console.log(response);

    commit("setcdrData", response.data.data);
    commit("setcdrID", data.id);
    commit("setcdrChart_", response.data.data.chart);
    commit("setcdrOutput_", response.data.data.output);
  },

  async cdrDate({ commit }, data) {
    commit("setcdrChart_", null);
    commit("setcdrOutput_", null);
    console.log(data.id);
    const datacdr = {
      thuraya_number: data.thuraya_number,
      range: data.range,
    };
    const response = await axios.post("/api/v1/navplus/thuraya/cdr", datacdr, {
      headers: {
        Authorization: "Bearer" + data.token,
      },
    });
    console.log(response);

    commit("setcdrData", response.data.data);
    commit("setcdrID", data.id);
    commit("setcdrChart_", response.data.data.chart);
    commit("setcdrOutput_", response.data.data.output);
  },

  cdrRemove({ commit }) {
    commit("setcdrOpen", null);
    commit("setcdrData", null);
    commit("setcdrID", null);
  },

  async trackFetch({ commit }, data) {
    commit("settrackid", data.id);
    commit("settrackopen", true);
    const response = await axios.get(
      "/api/v1/navplus/thuraya/" + data.id + "/interval",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );

    commit("settrackdata", response.data.data);
    commit("settrackinterval", response.data.data.latest_content);
  },

  async trackPost({ commit }, data) {
    const datatrack = {
      thuraya_number: data.thuraya_number,
      interval: data.interval,
    };
    const response = await axios.post(
      "/api/v1/navplus/thuraya/interval",
      datatrack,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    commit("settrackdata", response.data.data);
    commit("settrackinterval", response.data.data.latest_content);
  },

  async vnameFetch({ commit }, data) {
    try {
      const response = await axios.get(
        "/api/v1/navplus/vessel/name/" + data.id,
        {
          headers: {
            Authorization: "Bearer" + data.token,
          },
        }
      );
      console.log(response.data.data);
      commit("setnameData", response.data.data);
    } catch (e) {
      console.log(e);
    }
  },

  async sosFetch({ commit }, data) {
    commit("setsosID", data.id);
    commit("setsosopen", true);

    const response = await axios.get(
      "/api/v1/navplus/thuraya/" + data.id + "/sos",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    commit("setsosdata", response.data.data);

    console.log(commit);
  },

  sosRemove({ commit }) {
    commit("setsosopen", null);
    commit("setsosdata", null);
  },

  async sosPost({ commit }, data) {
    const datasos = {
      thuraya_number: data.thuraya_number,
      names: data.names,
      phones: data.phones,
      emails: data.emails,
    };
    const response = await axios.post("/api/v1/navplus/thuraya/sos", datasos, {
      headers: {
        Authorization: "Bearer" + data.token,
      },
    });
    console.log(response);
    commit("setsosdata", response.data.data);
  },

  async sosEPost({ commit }, data) {
    const datasos = {
      thuraya_number: data.thuraya_number,
      enable: data.enable,
    };
    const response = await axios.post(
      "/api/v1/navplus/thuraya/enableSosTest",
      datasos,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    console.log(commit);
  },

  async mnPost({ commit }, data) {
    const datamn = {
      thuraya_number: data.thuraya_number,
      types: data.types,
    };
    const response = await axios.post(
      "/api/v1/navplus/thuraya/listing",
      datamn,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    commit("setmnData", response.data.data);
  },

  //Manifest Listing
  async mnFetch({ commit }, data) {
    commit("setmnID", data.id);
    commit("setmnopen", true);
    const response = await axios.get(
      "/api/v1/navplus/thuraya/" + data.id + "/listing",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log("manifest listing data", response.data.data);

    commit("setmnData", response.data.data);
  },

  mnRemove({ commit }) {
    commit("setmnopen", null);
    commit("setmnData", null);
  },

  async wlPost({ commit }, data) {
    const datawl = {
      thuraya_number: data.thuraya_number,
      recipients: data.recipients,
      names: data.names,
    };
    const response = await axios.post(
      "/api/v1/navplus/thuraya/whitelist",
      datawl,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    //console.log(response);
    commit("setwlData", response.data.data);
  },

  //Vessel Detail
  async vdFetch({ commit }, data) {
    commit("setvdID", data.id);
    commit("setvdopen", true);
    const response = await axios.get(
      "/api/v1/navplus/thuraya/" + data.id + "/vessel_detail",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    //console.log(response.data.data);
    //console.log(commit)

    commit("setvddata", response.data.data);
    commit("setvdStatic", response.data.data);
    // commit("setwlID",data.id)
  },

  async vdPost({ commit }, data) {
    console.log("Masuk POST");
    console.log(data.thuraya_number);
    const datavd = {
      thuraya_number: data.thuraya_number,
      imo: data.imo,
      mmsi: data.mmsi,
      callsign: data.callsign,
      type: data.type,
      gross_tonnage: data.gross_tonnage,
      flag_state: data.flag_state,
      owner: data.owner,
      cso_first: data.cso_first,
      cso_second: data.cso_second,
    };
    const response = await axios.post(
      "/api/v1/navplus/thuraya/vessel_detail",
      datavd,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );

    console.log(response);
    commit("setvddata", response.data.data);
  },

  async gfPost({ commit }, data) {
    console.log("Masuk POST");
    console.log(data);
    const datagf = {
      thuraya_number: data.thuraya_number,
      poly: data.poly,
      type: data.type,
      area: data.area,
      enable: data.enable,
    };
    const response = await axios.post(
      "/api/v1/navplus/thuraya/pushGeofencing",
      datagf,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );

    console.log(response);
    commit("setgfData", response.data.data);
  },

  async gpsPost({ commit }, data) {
    commit("setgpssend", null);
    try {
      const datagps = {
        id: data.id,
        trackingContact: data.trackingContact,
        gpsEmailEnabled: data.gpsEmailEnabled,
      };
      const response = await axios.post(
        "/api/v1/navplus/thuraya/email",
        datagps,
        {
          headers: {
            Authorization: "Bearer" + data.token,
          },
        }
      );
      commit("setgpsdata", response.data.data);
      commit("setgpssend", true);
    } catch (e) {
      console.log(e);
    }
  },

  vdRemove({ commit }) {
    commit("setvdopen", null);
    commit("setvddata", null);
  },

  //SOS Button

  //History
  async hsFetch({ commit }, data) {
    commit("sethsopen", true);
    //console.log(data)
    const response = await axios.get(
      "/api/v1/navplus/thuraya/" + data.id + "/fetchSosHistory",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    //console.log(response);
    commit("sethsdata", response.data.data);
    //console.log(commit)
  },

  //Geofencing
  async gfFetch({ commit }, data) {
    commit("setgfOpen", true);
    commit("setgfID", null);
    console.log(data);
    commit("setgfID", data.id);
    //console.log(data)
    const response = await axios.get(
      "/api/v1/navplus/thuraya/" + data.id + "/fetchGeofencing",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    commit("setgfData", response.data.data);
    commit("setgfpolyPath", response.data.data.polyPath);
    //console.log(commit)
  },

  async gfFetchInterval({ commit }, data) {
    commit("setgfOpen", true);
    commit("setgfID", null);
    console.log(data);
    commit("setgfID", data.id);
    //console.log(data)
    const response = await axios.get(
      "/api/v1/navplus/thuraya/" + data.id + "/fetchGeofencing",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    commit("setgfData", response.data.data);

    //console.log(commit)
  },

  hsRemove({ commit }) {
    commit("sethsopen", null);
    commit("sethsdata", null);
  },

  //TRACKING BUTTON
  //gpshistory

  async gpsFetch({ commit }, data) {
    commit("setgpsid", data.id);
    commit("setgpsopen", true);
    const response = await axios.get(
      "/api/v1/navplus/thuraya/" + data.id + "/email",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    //console.log(response);
    commit("setgpsdata", response.data.data);
  },

  gpsRemove({ commit }) {
    commit("setgpsopen", null);
    commit("setgpsdata", null);
    commit("setgpssend", null);
  },

  async chRoomFetch({ commit }, data) {
    commit("setmsgopen", true);
    const response = await axios.get("/api/v1/navplus/sms/fetch/" + data.id, {
      headers: {
        Authorization: "Bearer" + data.token,
      },
    });
    //console.log(response);
    //console.log('DATA CHATROOM')
    //console.log(data.data)
    commit("setchdata", response.data);
    commit("setmsgData", data.data);
    //console.log(commit)
  },

  removechRoom({ commit }, data) {
    commit("setcallopen", null);
    commit("setmsgopen", data);
    commit("setchdata", null);
    commit("setmsgData", null);
    //console.log('CLOSE CLICK')
  },

  setCallOpen({ commit }) {
    commit("setcallopen", true);
  },

  Inputexpthuraya_title({ commit }, data) {
    commit("setexpthuraya_title", data);
  },

  removeCallOpen({ commit }) {
    commit("setcallopen", null);
  },

  setCallCreated_Data({ commit }, data) {
    commit("setCallCreated_", data.thuraya_number);
    commit("setCallVessel_", data.name);
  },

  setChatCreated_Data({ commit }, data) {
    commit("setChatCreated_", data);
  },

  unsetChatCreated_Data({ commit }) {
    commit("setChatCreated_", null);
    commit("setchdata", null);
    commit("setmsgData", null);
  },

  unsetmaptracking_({ commit }) {
    commit("setshowmaptracking_thur", null);
  },

  unsetCallCreated_Data({ commit }) {
    commit("setCallCreated_", null);
    commit("setCallVessel_", null);
  },

  set_clickdate({ commit }, data) {
    //console.log('CLICK DATE SET')
    //console.log(data)
    commit("setclickdate", data);
  },

  resetMythurayaState({ commit }) {
    commit("resetState");
  },

  addNameData({ commit }, data) {
    console.log(data);
    commit("setnameData", data);
  },

  addNameThurOpen_({ commit }, data) {
    console.log(data);
    commit("setNameThurOpen_", data);
  },

  async gfFetchArea({ commit }, data) {
    console.log(data);
    const datasend = {
      id: data.id,
      type: data.type,
      area: data.area,
    };
    const response = await axios.post(
      "/api/v1/navplus/thuraya/fetchGeofencingPoly",
      datasend,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );

    console.log(response);
    commit("setgfpolyPath", response.data.data.polyPath);
  },

  removepolyPath({ commit }) {
    commit("setgfpolyPath", []);
  },

  removetrackline_thur({ commit }) {
    commit("settrackline_thur", null);
  },

  async SpeedaltPost_thur({ commit }, data) {
    const dataspeed = {
      id: data.id,
      isEnabled: data.isEnabled,
      speed_min: data.speed_min,
      speed_max: data.speed_max,
      emails: data.emails,
    };
    console.log(dataspeed);
    const response = await axios.post(
      "/api/v1/navplus/speed/" + data.id,
      dataspeed,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    if (response.data.data) {
      commit("set_notifspeed_success_thur", true);
    }
    commit("setspeedaltData", response.data.data);
    setTimeout(() => {
      commit("set_notifspeed_success_thur", null);
    }, 2000);
  },

  removeSpeedalt_thur({ commit }) {
    // commit("setnotifData", null)
    commit("setspeedaltopen_thur", null);
  },

  async fetchSpeedAlert_thur({ commit }, data) {
    // console.log(data.id)
    console.log("MASUK SPEED ALERT");
    commit("setspeedaltID_thur", data.id);
    commit("setspeedaltopen_thur", true);
    console.log(data);
    const response = await axios.get("/api/v1/navplus/speed/" + data.id, {
      headers: {
        Authorization: "Bearer" + data.token,
      },
    });
    // console.log('MASUK KE FETCH DATA SPEED');
    console.log(response);
    if (response.data.data) {
      if (response.data.data.isEnabled != null) {
        commit(
          "setisEnabled_speed_thur",
          parseInt(response.data.data.isEnabled)
        );
      } else {
        console.log("masuk ke isi o");
        commit("setisEnabled_speed_thur", parseInt(0));
      }
    }

    commit("setspeedaltData_thur", response.data.data);
  },
};

const mutations = {
  settrackline_thur: (state, trackline_thur) =>
    (state.trackline_thur = trackline_thur),
  setThurayaData: (state, thuraya_data) => (state.thuraya_data = thuraya_data),
  setvesselUsersThuraya: (state, vesselUsersThuraya) =>
    (state.vesselUsersThuraya = vesselUsersThuraya),
  setshowmapthuraya: (state, showmapthuraya) =>
    (state.showmapthuraya = showmapthuraya),
  setshowmaptracking_thur: (state, showmaptracking_thur) =>
    (state.showmaptracking_thur = showmaptracking_thur),
  setnameData: (state, nameData_Thur) => (state.nameData_Thur = nameData_Thur),
  setNameThurOpen_: (state, NameThurOpen_) =>
    (state.NameThurOpen_ = NameThurOpen_),
  setOffMythuraya: (state, OffMythuraya) => (state.OffMythuraya = OffMythuraya),
  setshowthurayaclick: (state, showthurayaclick) =>
    (state.showthurayaclick = showthurayaclick),
  setthurayadataTable: (state, thurayadataTable) =>
    (state.thurayadataTable = thurayadataTable),
  setsearchIDThuraya: (state, searchIDThuraya) =>
    (state.searchIDThuraya = searchIDThuraya),
  setsearchThuraya: (state, searchThuraya) =>
    (state.searchThuraya = searchThuraya),
  setrpmid: (state, rpmreportID) => (state.rpmreportID = rpmreportID),
  setnamethuraya: (state, nameThuraya) => (state.nameThuraya = nameThuraya),
  setmaptop: (state, maptop_thur) => (state.maptop_thur = maptop_thur),
  setclickdate: (state, clickdate) => (state.clickdate = clickdate),
  setvdID: (state, vdID) => (state.vdID = vdID),
  setmnID: (state, mnID) => (state.mnID = mnID),
  setadmID: (state, admID) => (state.admID = admID),
  setexpthuraya_title: (state, expthuraya_title) =>
    (state.expthuraya_title = expthuraya_title),

  //CONFIG BUTTON

  //geofencing
  setgfOpen: (state, gfOpen) => (state.gfOpen = gfOpen),
  setgfData: (state, gfData) => (state.gfData = gfData),
  setgfID: (state, gfID) => (state.gfID = gfID),
  setgfpolyPath: (state, gfpolyPath) => (state.gfpolyPath = gfpolyPath),

  //whitelist
  setwlopen: (state, wlOpen) => (state.wlOpen = wlOpen),
  setwlData: (state, wlData) => (state.wlData = wlData),

  //whitelist
  setrbopen: (state, rbOpen) => (state.rbOpen = rbOpen),
  setrbID: (state, rbID) => (state.rbID = rbID),
  setrbData: (state, rbData) => (state.rbData = rbData),

  //manifest
  setmnopen: (state, mnOpen) => (state.mnOpen = mnOpen),
  setmnData: (state, mnData) => (state.mnData = mnData),

  //manifest
  setadmopen: (state, admOpen) => (state.admOpen = admOpen),
  setadmData: (state, admData) => (state.admData = admData),

  //Vessel Detail
  setvdopen: (state, vdOpen) => (state.vdOpen = vdOpen),
  setvddata: (state, vdData) => (state.vdData = vdData),
  setvdStatic: (state, vdStatic) => (state.vdStatic = vdStatic),

  //SOS BUTTON

  //history
  sethsopen: (state, hsOpen) => (state.hsOpen = hsOpen),
  sethsdata: (state, hsData) => (state.hsData = hsData),

  //TRACKING BUTTOON
  //gpsmail
  setgpsopen: (state, gpsOpen) => (state.gpsOpen = gpsOpen),
  setgpsdata: (state, gpsData) => (state.gpsData = gpsData),
  setgpssend: (state, gpssend) => (state.gpssend = gpssend),

  //sos contact
  setsosopen: (state, sosOpen) => (state.sosOpen = sosOpen),
  setsosdata: (state, sosData) => (state.sosData = sosData),
  setsosID: (state, sosID) => (state.sosID = sosID),

  //cdr
  setcdrOpen: (state, cdrOpen) => (state.cdrOpen = cdrOpen),
  setcdrData: (state, cdrData) => (state.cdrData = cdrData),
  setcdrID: (state, cdrID) => (state.cdrID = cdrID),

  //interval tracking
  settrackopen: (state, trackOpen) => (state.trackOpen = trackOpen),
  settrackdata: (state, trackData) => (state.trackData = trackData),
  settrackinterval: (state, intervalData) =>
    (state.intervalData = intervalData),
  settrackid: (state, trackID) => (state.trackID = trackID),

  //CHATROOM
  setchdata: (state, chData) => (state.chData = chData),
  setmsgData: (state, msgData) => (state.msgData = msgData),
  setmsgopen: (state, msgopen) => (state.msgopen = msgopen),
  setgpsid: (state, gpsID) => (state.gpsID = gpsID),

  setwlID: (state, wlID) => (state.wlID = wlID),
  settrackData: (state, trackData) => (state.trackData = trackData),
  setintervalData: (state, intervalData) => (state.intervalData = intervalData),

  setcallopen: (state, callopen) => (state.callopen = callopen),
  setCallCreated_: (state, CallCreated_) => (state.CallCreated_ = CallCreated_),
  setCallVessel_: (state, CallVessel_) => (state.CallVessel_ = CallVessel_),

  setChatCreated_: (state, ChatCreated_) => (state.ChatCreated_ = ChatCreated_),

  setcdrChart_: (state, cdrChart_) => (state.cdrChart_ = cdrChart_),
  setcdrOutput_: (state, cdrOutput_) => (state.cdrOutput_ = cdrOutput_),

  setspeedaltopen_thur: (state, speedaltopen_thur) =>
    (state.speedaltopen_thur = speedaltopen_thur),
  setspeedaltID_thur: (state, speedaltID_thur) =>
    (state.speedaltID_thur = speedaltID_thur),
  setspeedaltData_thur: (state, speedaltData_thur) =>
    (state.speedaltData_thur = speedaltData_thur),
  setisEnabled_speed_thur: (state, isEnabled_speed_thur) =>
    (state.isEnabled_speed_thur = isEnabled_speed_thur),
  set_notifspeed_success_thur: (state, speedalt_success_thur) =>
    (state.speedalt_success_thur = speedalt_success_thur),

  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
