<template>
 <v-app>
  <v-dialog class="elevation-0 rounded-0" v-model="companyOpen" width="80vh" persistent>
    <v-card class="elevation-0 rounded-0">
      <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7" >
     <v-toolbar-title v-if="!companyData">Update Company</v-toolbar-title>
     <v-toolbar-title v-if="companyData">Update {{companyData.data.user.role_id == 3 ? 'Company' : 'Vessel'}}</v-toolbar-title>
      

        <v-spacer></v-spacer>

        <v-btn
          small
          class="elevation-0 rounded-0"
          icon
         
          @click="close_Company_()"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
     
      <v-card-text style="margin-top: 20px">
        <v-card
          style="border: 1px solid #9e9e9e"
          height="fit-content"
          class="elevation-0 rounded-0 pa-0 pb-0"
        >
          <v-toolbar
            class="elevation-0 rounded-0"
           
            height="30"
            dense
            color="#f7f7f7"
           
          >
            <v-toolbar-subtitle v-if="companyData">{{companyData.data.user.role_id == 3  ? 'Navigator+ Company':'Navigator+ Vessel Owned'}}</v-toolbar-subtitle>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card-text >
            <v-progress-linear
        v-if="!companyData"
        indeterminate
        color="#1F7AD3"
        height="6px"
      ></v-progress-linear>
    
            <v-select
            @change="add_changeCompany_active()"
            v-if="companyData"
              v-model="companyData.data.owned"
              :items="companyData.data.items"
              :value="items"
              item-text="name"
              item-value="id"
              :menu-props="{ maxHeight: '400' }"
              label="Select one or more premission"
              multiple
              persistent-hint
              class="elevation-0 rounded-0 pa-0 ma-2 mb-0"
            ></v-select>
          
          </v-card-text>
        </v-card>
      </v-card-text>

      <v-card-actions v-if="companyData" style="margin-top: -10px"
        >
        <v-btn
         
          small
          class="elevation-0 rounded-0"
          :disabled="changeCompany_active?false:true"
          :color="'primary'"
          :style="changeCompany_active ? 'cursor:pointer':'cursor:not-allowed'"
          block
          @click="changeCompany_active ? company_confirm=true :console.log('empty')"
        >
          Update
        </v-btn>
 </v-card-actions
      >
    </v-card>
  </v-dialog>
  <v-dialog class="rounded-0 elevation-0" v-model="company_confirm" persistent max-width="290">
      <v-card v-if="companyData" class="rounded-0 elevation-0">
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text style="text-align: left"
          >{{companyData.data.user.role_id == 3 ? 'Company' : 'Vessel'}} for   {{ companyData.data.user.email }} will be update</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="company_confirm = false">
            No
          </v-btn>
          <v-btn
            color="green"
            text
            @click="
              (vesselPost(companyData.data.user.id),close_Company_())
            "
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
 </v-app>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data(){
    return{
      changeCompany_active:null,
      company_confirm:null
    }
  },
  methods: {
    ...mapActions(["postCompany", "removeCompany", "fetchAdmin"]),
    add_changeCompany_active(){
      this.changeCompany_active=true
    },

    close_Company_(){
      this.removeCompany(),
      this.changeCompany_active=null
      this.company_confirm=null
    },
    async vesselPost(user_id) {
      var datavessel = {
        id: user_id,
        role: this.companyData.data.user.role_id,
        token: localStorage.getItem("token"),
        items: this.companyData.data.owned
      };

      this.postCompany(datavessel);
      var mother = this;
      setTimeout(function () {
        mother.fetchAdmin(localStorage.getItem("token"));
      }, 5000);
    },
  },
  computed: {
    ...mapGetters(["companyOpen", "companyData", "companyDataPost"]),
  },
};
</script>

<style lang="scss" scoped></style>
