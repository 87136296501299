<template>
    <v-card id="card_map_sailink" class="elevation-0 mt-0 rounded-0">
      <v-card
        id="top_loader_map"
        class="container_showonmap ma-3 elevation-0 rounded-0 mt-0 pb-3"
        style="border: solid 1px #e0e0e0"
    
      >
        <v-toolbar  color="#F7F7F7" elevation="0" dense>
       
        </v-toolbar>
        <v-divider></v-divider>
        <v-card
          class="flexposisi pa-3 rounded-0"
          height="fit-content"
          elevation="0"
          style="margin-top: 0"
        >
          <v-card
            class="datacard elevation-0 rounded-0"
            color="#F7F7F7"
            elevation="0"
            style="border: solid 1px #e0e0e0"
          >
            <v-simple-table class="elevation-0 rounded-0" dense>
              <template v-slot:default>
                <tbody>
                    <tr>
                        <v-container
              id="map_sailink"
              fill-height
              fluid
              style="margin-top: 0; height: 90vh;"
            >
              <v-row align="center" justify="center">
                <v-col
                  ><img
                    alt="Navplus logo"
                    width="70px"
                    :src="loadernavplus"
                    class="justify-center"
                /></v-col>
              </v-row>
            </v-container>
                    </tr>
                  
                </tbody>
              </template>
            </v-simple-table>
           
            <v-divider></v-divider>
  
          </v-card>
  
          <v-card
            color="#F7F7F7"
            id="mapcard"
            class="mapcard rounded-0"
            elevation="0"
            style="border: solid 1px #e0e0e0"
          >
            <v-toolbar color="#F7F7F7" class="rounded-0" dense elevation="0">
           
            
            </v-toolbar>
            <v-divider></v-divider>
            <v-container
              id="map_sailink"
              fill-height
              fluid
              style="margin-top: 0; height: 90vh;"
            >
              <v-row align="center" justify="center">
                <v-col
                  ><img
                    alt="Navplus logo"
                    width="70px"
                    :src="loadernavplus"
                    class="justify-center"
                /></v-col>
              </v-row>
            </v-container>
  
     
            <v-divider></v-divider>
            <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
             
            </v-toolbar>
          </v-card>
        </v-card>
  
        <v-card
          style="border: solid 1px #e0e0e0"
          class="ma-3 datatabledetail rounded-0 mt-0 mb-0"
          elevation="0"
        >
          <v-toolbar
           
            elevation="0"
            style="max-height: 70px"
            color="#F7F7F7"
            fixed
            dense
          >
       
          
          
  
          </v-toolbar>
          <v-divider></v-divider>
          <v-layout v-resize="onResize" column style="">
            <v-container
              id="map_sailink"
           
              fill-height
              fluid
              style="height: 250px"
              class="elevation-0"
            >
              <v-row align="center" justify="center">
                <v-col
                  ><img
                    alt="Navplus logo"
                    width="70px"
                    :src="loadernavplus"
                    class="justify-center"
                /></v-col>
              </v-row>
            </v-container>
       
            <v-divider></v-divider>
            <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
            
             
            </v-toolbar>
          </v-layout>
  
          
        </v-card>
  
      
  
  
    </v-card>
    </v-card>
  </template>
  
  <script>
import $ from "jquery";
export default{
    data(){
        return{
            loadernavplus: require("@/assets/img/load100new.gif"),
        }
    },
    mounted(){
      
    }
}
</script>