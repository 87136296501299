/* eslint no-console: "error" */
import axios from "../../plugins/axios";

const getDefaultState = () => {
  return {
    sailink_data: null,
    quotaData: null,
    alert: null,
    mrtg: null,
    showmaptable: null,
    vesselUsers: null,
    showmaptracking: null,
    showmapclick: null,
    rangeshowmap: null,
    tableTracking: null,
    ip_adr: null,
    ln_status: null,
    weather_data: null,
    searchVessel: null,
    searchVesselID: null,
    searchVesselName: null,
    mrtgopen: null,
    status_mrtg: null,
    nameopen: null,
    message_name: null,
    color_name: null,
    nameData: null,
    nameChangeData: null,
    icon_name: null,
    msg_name: null,
    nmopen: null,
    alertColor: null,
    tlpopen: null,
    StaticName: null,
    notifopen: null,
    startFrom: false,
    notifData: null,
    serviceQuota: null,
    hovershowmap: null,
    sailink_executed: false,
    thuraya_executed: false,
    manifest_executed: false,
    notifID: null,
    trackPath: null,
    OffMysailink: null,
    expsailink_title: null,
    trackline: null,
    notifqt_success: null,
    mrtgclicked: null,
    switch_true: null,
    satnet_output: null,
    speedaltopen: null,
    speedaltData: null,
    speedaltID: null,
    speedalt_success: null,
    isEnabled_speed: null,
  };
};

const state = getDefaultState();

const getters = {
  switch_true: (state) => state.switch_true,
  satnet_output: (state) => state.satnet_output,
  notifqt_success: (state) => state.notifqt_success,
  trackline: (state) => state.trackline,
  quotaData: (state) => state.quotaData,
  sailinkData: (state) => state.sailink_data,
  alertSailink: (state) => state.alert,
  mrtgSailink: (state) => state.mrtg,
  showmaptable: (state) => state.showmaptable,
  vesselUsers: (state) => state.vesselUsers,
  showmaptracking: (state) => state.showmaptracking,
  showmapclick: (state) => state.showmapclick,
  rangeshowmap: (state) => state.rangeshowmap,
  tableTracking: (state) => state.tableTracking,
  ip_adr: (state) => state.ip_adr,
  ln_status: (state) => state.ln_status,
  weather_data: (state) => state.weather_data,
  searchVessel: (state) => state.searchVessel,
  searchVesselID: (state) => state.searchVesselID,
  searchVesselName: (state) => state.searchVesselName,
  mrtgopen: (state) => state.mrtgopen,
  status_mrtg: (state) => state.status_mrtg,
  hovershowmap: (state) => state.hovershowmap,
  sailink_executed: (state) => state.sailink_executed,
  nameopen: (state) => state.nameopen,
  message_name: (state) => state.message_name,
  color_name: (state) => state.color_name,
  nameData: (state) => state.nameData,
  nameChangeData: (state) => state.nameChangeData,
  icon_name: (state) => state.icon_name,
  msg_name: (state) => state.msg_name,
  nmopen: (state) => state.nmopen,
  alertColor: (state) => state.alertColor,
  tlpopen: (state) => state.tlpopen,
  StaticName: (state) => state.StaticName,
  notifopen: (state) => state.notifopen,
  startFrom: (state) => state.startFrom,
  notifData: (state) => state.notifData,
  serviceQuota: (state) => state.serviceQuota,
  thuraya_executed: (state) => state.thuraya_executed,
  manifest_executed: (state) => state.manifest_executed,
  notifID: (state) => state.notifID,
  trackPath: (state) => state.trackPath,
  OffMysailink: (state) => state.OffMysailink,
  expsailink_title: (state) => state.expsailink_title,
  mrtgclicked: (state) => state.mrtgclicked,
  speedaltopen: (state) => state.speedaltopen,
  speedaltData: (state) => state.speedaltData,
  speedaltID: (state) => state.speedaltID,
  speedalt_success: (state) => state.speedalt_success,
  isEnabled_speed: (state) => state.isEnabled_speed,
};

const actions = {
  async fetchMySailink({ commit }, data) {
    try {
      const response = await axios.get("/api/v1/navplus/sailink", {
        headers: {
          Authorization: "Bearer" + data,
        },
      });
      commit(
        "setMySailink",
        response.data.data.vessels.map((item, index) =>
          Object.assign(item, { index })
        )
      );
      commit("setOffMysailink", null);
    } catch (e) {
      console.log(e);
      commit("setOffMysailink", true);
    }
  },

  set_mrtgclicked({ commit }, data) {
    commit("setmrtgclicked", data);
  },

  unsetMySailink({ commit }) {
    commit("setMySailink", null);
  },

  async fetchMRTG({ commit }, data) {
    commit("setmrtgopen", true);
    const response = await axios.get(
      "/api/v1/navplus/sailink/mrtg/" + data.id + "/" + data.mrtg,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    //console.log(response);
    commit("setMRTG", response.data.data);
    commit("setstatus_mrtg", data.status_mrtg);
  },

  async vnameFetchSailink({ commit }, data) {
    commit("setnmopen", true);
    commit("setnameopen", true);
    try {
      const response = await axios.get(
        "/api/v1/navplus/vessel/name/" + data.id,
        {
          headers: {
            Authorization: "Bearer" + data.token,
          },
        }
      );
      console.log(response.data.data);
      commit("setnameDataSailink", response.data.data);
    } catch (e) {
      console.log(e);
    }
  },

  async notifPost({ commit }, data) {
    const datanotif = {
      enabled: data.enabled,
      batas: data.batas,
      emails: data.emails,
    };
    const response = await axios.post(
      "/api/v1/navplus/email/limit/" + data.id,
      datanotif,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    if (response.data.data) {
      commit("set_notifqt_success", true);
    }
    commit("setnotifData", response.data.data);
    setTimeout(() => {
      commit("set_notifqt_success", null);
    }, 2000);
  },

  async SpeedaltPost({ commit }, data) {
    const dataspeed = {
      id: data.id,
      isEnabled: data.isEnabled,
      speed_min: data.speed_min,
      speed_max: data.speed_max,
      emails: data.emails,
    };
    console.log(dataspeed);
    const response = await axios.post(
      "/api/v1/navplus/speed/" + data.id,
      dataspeed,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    if (response.data.data) {
      commit("set_notifspeed_success", true);
    }
    commit("setspeedaltData", response.data.data);
    setTimeout(() => {
      commit("set_notifspeed_success", null);
    }, 2000);
  },

  async fetchSpeedAlert({ commit }, data) {
    // console.log(data.id)
    console.log("MASUK SPEED ALERT");
    commit("setspeedaltID", data.id);
    commit("setspeedaltopen", true);
    console.log(data);
    const response = await axios.get("/api/v1/navplus/speed/" + data.id, {
      headers: {
        Authorization: "Bearer" + data.token,
      },
    });
    // console.log('MASUK KE FETCH DATA SPEED');
    console.log(response);
    if (response.data.data) {
      if (response.data.data.isEnabled != null) {
        commit("setisEnabled_speed", parseInt(response.data.data.isEnabled));
      } else {
        console.log("masuk ke isi o");
        commit("setisEnabled_speed", parseInt(0));
      }
    }

    commit("setspeedaltData", response.data.data);
  },

  async fetchNotif({ commit }, data) {
    console.log(data.id);
    commit("setnotifID", data.id);
    commit("setnotifopen", true);
    const response = await axios.get("/api/v1/navplus/email/limit/" + data.id, {
      headers: {
        Authorization: "Bearer" + data.token,
      },
    });

    commit("setnotifData", response.data.data);
  },

  async post_switch_satnet({ commit }, data) {
    commit("set_switch_true", true);
    const datasatnet = {
      ip_addr: data.ip_addr,
      iq_series: data.iq_series,
      satnet_id: data.satnet_id,
    };
    const response = await axios.post(
      "/api/v1/navplus/sailink/pushSwitchSatnet",
      datasatnet,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    if (response.data.data) {
      commit("set_switch_true", false);
      commit("set_satnet_output", response.data.data.output);
    }
    console.log("RESPONSE DARI POST SATNET");
    console.log(response);
  },

  removeSatnetOutput({ commit }) {
    commit("set_satnet_output", null);
  },

  removeNotif({ commit }) {
    commit("setnotifData", null);
    commit("setnotifopen", null);
  },

  removeSpeedalt({ commit }) {
    // commit("setnotifData", null)
    commit("setspeedaltopen", null);
  },

  removeMRTG({ commit }) {
    commit("setMRTG", null);
    commit("setmrtgclicked", null);
    commit("setmrtgopen", null);
    commit("setstatus_mrtg", null);
  },

  addsailink_executed({ commit }, data) {
    commit("setsailink_executed", data);
  },

  addthuraya_executed({ commit }, data) {
    commit("setthuraya_executed", data);
  },

  Inputexpsailink_title({ commit }, data) {
    commit("setexpsailink_title", data);
  },

  addmanifest_executed({ commit }, data) {
    commit("setmanifest_executed", data);
  },

  async updateVesselName({ commit }, data) {
    const dataName = {
      id: data.id,
      name: data.name,
    };
    //console.log(dataName)
    const response = await axios.post("/api/v1/navplus/vessel/name", dataName, {
      headers: {
        Authorization: "Bearer" + data.token,
      },
    });

    //console.log(response);
    commit("setAlert", response.data.message);
    commit("seticon_name", "mdi-check-circle");

    if (response.data.message == "Retrieved successfully") {
      commit("setalertColor", "green");
      commit("setmsg_name", "Name update sucsessfully");
    } else if (response.data.message != "Retrieved successfully") {
      commit("setalertColor", "yellow");
      commit("setmsg_name", "Cant change the name check the data");
    } else {
      commit("setalertColor", "red");
      commit("setmsg_name", "Please check your connection");
    }
  },

  setDataName({ commit }, data) {
    //console.log('JALAN KOK yang ke dua')
    //console.log(data)
    commit("setnmopen", true);
    commit("setnameopen", true);
    commit("setnameDataSailink", data);
    commit("setnameChangeData", data.name);
    //console.log('macet')
  },

  setUpStaticName({ commit }, data) {
    commit("setStaticName", data);
  },

  removeName({ commit }) {
    commit("setnameopen", null);
    commit("setnmopen", null);
    commit("setnameDataSailink", null);
    commit("setnameChangeData", null);
    commit("setAlert", null);
    commit("seticon_name", null);
    commit("setcolor_name", null);
    commit("setmsg_name", null);
  },

  async showonMapSailink({ commit }, data) {
    commit("setshowmaptable", null);
    commit("setvesselUsers", null);
    commit("settableTracking", null);
    commit("setQuotaData", null);
    try {
      const response = await axios.get("/api/v1/navplus/sailink/" + data.id, {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      });
      console.log("RESPON DARI FETCH API KE SERVER");
      console.log(response);
      var dataTable = await response.data.data;

      commit("setshowmaptable", dataTable);
      commit(
        "setvesselUsers",
        dataTable.vesselUsers.map((item, index) =>
          Object.assign(item, { index })
        )
      );
      console.log("TABEL TRACKING/DATATABLE");
      console.log(dataTable.dataTable);
      commit(
        "settableTracking",
        dataTable.dataTable.map((item, index) => Object.assign(item, { index }))
      );
    } catch (e) {
      console.log(e);
      console.log("ERROR RESPON DARI FETCH API KE SERVER");
    }
  },

  async ipAdr({ commit }, data) {
    commit("setip_adr", null);
    const response = await axios.get(
      "/api/v1/navplus/sailink/" + data.id + "/fetchIp",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );

    commit("setip_adr", await response.data.data);
  },

  async lnStatus({ commit }, data) {
    commit("setln_status", null);
    const response = await axios.get(
      "/api/v1/navplus/sailink/" + data + "/fetchStatus",
      {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      }
    );
    commit("setln_status", await response.data.data);
  },

  async showondate({ commit }, data) {
    commit("setshowmaptracking", null);
    const datadate = {
      id: data.id,
      range: data.range,
    };
    const response = await axios.post(
      "/api/v1/navplus/sailink/date",
      datadate,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log("masuk ambil tracking");
    console.log(response);
    commit("setshowmaptracking", await response.data.data);
    commit("settrackPath", response.data.data.tracking.slice());

    commit(
      "settableTracking",
      response.data.data.dataTable.map((item, index) =>
        Object.assign(item, { index })
      )
    );
  },

  async showtrackline({ commit }, data) {
    commit("settrackline", null);
    const datadate = {
      id: data.id,
      range: data.range,
    };
    const response = await axios.post(
      "/api/v1/navplus/sailink/date/trackingOnly",
      datadate,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log("masuk ambil tracking");
    console.log(response);
    commit("settrackline", await response.data.data);
  },

  removetrackline({ commit }) {
    commit("settrackline", null);
  },

  async showweather({ commit }, data) {
    commit("setweather_data", null);
    const datawth = {
      id: data.id,
      msg_id: data.msg_id,
      type: data.type,
    };
    const response = await axios.post(
      "/api/v1/navplus/weather/fetchRow",
      datawth,
      {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      }
    );
    //console.log(response);
    commit("setweather_data", await response.data.data);
  },

  searchVesselInput({ commit }, data) {
    commit("setsearchVessel", data);
  },

  searchVesselID_Input({ commit }, data) {
    commit("setsearchVesselID", data);
  },

  searchVesselName_Input({ commit }, data) {
    commit("setsearchVesselName", data);
  },

  removeSearch({ commit }) {
    commit("setsearchVessel", null);
  },

  removeShowMap({ commit }) {
    commit("setshowmaptable", null);
    commit("setvesselUsers", null);
    commit("settableTracking", null);
    commit("setshowmapclick", null);
    commit("setsearchVessel", null);
    commit("setsearchVesselID", null);
  },

  addShowClick({ commit }) {
    commit("setshowmapclick", true);
  },

  fetchTlOpen({ commit }) {
    commit("settlpopen", true);
  },

  removetl({ commit }) {
    commit("settlpopen", null);
  },

  async fetchQuota({ commit }, data) {
    commit("setservicequota", data.service);
    const response = await axios.get(
      "/api/v1/navplus/sailink/fetchNfaMonthly/" +
        data.vessel_id +
        "/" +
        data.service +
        "",
      {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      }
    );
    //console.log(response)
    commit("setQuotaData", await response.data.data);
  },

  removeQuota({ commit }) {
    commit("setQuotaData", null);
    commit("setservicequota", null);
  },

  resetMysailinkState({ commit }) {
    commit("resetState");
  },
};

const mutations = {
  settrackline: (state, trackline) => (state.trackline = trackline),
  set_switch_true: (state, switch_true) => (state.switch_true = switch_true),
  set_satnet_output: (state, satnet_output) =>
    (state.satnet_output = satnet_output),
  setthuraya_executed: (state, thuraya_executed) =>
    (state.thuraya_executed = thuraya_executed),
  setMySailink: (state, mysailink) => (state.sailink_data = mysailink),
  setsailink_executed: (state, sailink_executed) =>
    (state.sailink_executed = sailink_executed),
  updateMySailink: (state, mysailink) => (state.sailink_data = mysailink),
  setMRTG: (state, mrtg) => (state.mrtg = mrtg),
  setAlert: (state, alert) => (state.alert = alert),
  setshowmaptable: (state, showmaptable) => (state.showmaptable = showmaptable),
  setvesselUsers: (state, vesselUsers) => (state.vesselUsers = vesselUsers),
  setsearchVesselName: (state, searchVesselName) =>
    (state.searchVesselName = searchVesselName),
  settableTracking: (state, tableTracking) =>
    (state.tableTracking = tableTracking),
  setip_adr: (state, ip_adr) => (state.ip_adr = ip_adr),
  setln_status: (state, ln_status) => (state.ln_status = ln_status),
  setshowmaptracking: (state, showmaptracking) =>
    (state.showmaptracking = showmaptracking),
  settrackPath: (state, trackPath) => (state.trackPath = trackPath),
  setweather_data: (state, weather_data) => (state.weather_data = weather_data),
  setsearchVessel: (state, searchVessel) => (state.searchVessel = searchVessel),
  setsearchVesselID: (state, searchVesselID) =>
    (state.searchVesselID = searchVesselID),
  setshowmapclick: (state, showmapclick) => (state.showmapclick = showmapclick),
  setmrtgopen: (state, mrtgopen) => (state.mrtgopen = mrtgopen),
  setstatus_mrtg: (state, status_mrtg) => (state.status_mrtg = status_mrtg),
  setnameopen: (state, nameopen) => (state.nameopen = nameopen),
  setnameDataSailink: (state, nameData) => (state.nameData = nameData),
  setnameChangeData: (state, nameChangeData) =>
    (state.nameChangeData = nameChangeData),
  seticon_name: (state, icon_name) => (state.icon_name = icon_name),
  setcolor_name: (state, color_name) => (state.color_name = color_name),
  setmsg_name: (state, msg_name) => (state.msg_name = msg_name),
  setnmopen: (state, nmopen) => (state.nmopen = nmopen),
  setalertColor: (state, alertColor) => (state.alertColor = alertColor),
  settlpopen: (state, tlpopen) => (state.tlpopen = tlpopen),
  setStaticName: (state, StaticName) => (state.StaticName = StaticName),
  setnotifopen: (state, notifopen) => (state.notifopen = notifopen),
  setspeedaltopen: (state, speedaltopen) => (state.speedaltopen = speedaltopen),
  setspeedaltID: (state, speedaltID) => (state.speedaltID = speedaltID),
  setspeedaltData: (state, speedaltData) => (state.speedaltData = speedaltData),
  setisEnabled_speed: (state, isEnabled_speed) =>
    (state.isEnabled_speed = isEnabled_speed),
  setstartFrom: (state, startFrom) => (state.startFrom = startFrom),
  setnotifData: (state, notifData) => (state.notifData = notifData),
  setnotifID: (state, notifID) => (state.notifID = notifID),
  setQuotaData: (state, quotaData) => (state.quotaData = quotaData),
  setservicequota: (state, serviceQuota) => (state.serviceQuota = serviceQuota),
  sethovershowmap: (state, hovershowmap) => (state.hovershowmap = hovershowmap),
  setmanifest_executed: (state, manifest_executed) =>
    (state.manifest_executed = manifest_executed),
  setOffMysailink: (state, OffMysailink) => (state.OffMysailink = OffMysailink),
  setexpsailink_title: (state, expsailink_title) =>
    (state.expsailink_title = expsailink_title),
  set_notifqt_success: (state, notifqt_success) =>
    (state.notifqt_success = notifqt_success),
  set_notifspeed_success: (state, speedalt_success) =>
    (state.speedalt_success = speedalt_success),
  setmrtgclicked: (state, mrtgclicked) => (state.mrtgclicked = mrtgclicked),
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
