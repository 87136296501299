<template>
  <v-app class="container_tabledata" color="#fff">
    <v-card elevation="0">
      <v-alert v-if="OffAdmin" class="elevation-0 rounded-0" prominent type="warning" icon="mdi-web">
        <v-row align="center">
          <v-col class="grow">
            Connection timeout , please check your internet connection
          </v-col>
          <v-col class="shrink">
            <v-btn small @click="reload_page()" class="elevation-0 rounded-0">Reload</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-layout v-resize="onResize" column style="">
        <loadingTable v-if="!adminData" />
        <v-card v-if="adminData" class="ma-3 elevation-0 rounded-0" style="border: solid 1px #E0E0E0;">
          <v-toolbar elevation="0" class="pa-0" color="#f7f7f7" fixed dense>
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="border: solid 1px #e0e0e0;" small class="rounded-0 pa-1 elevation-0 ma-0" color="#ffff"
                  v-bind="attrs" v-on="on">
                  {{ kFormatter(itemsPerPage)
                  }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0;">
                <v-list-item @click="itemsPerPage = 10"><v-list-item-title>10</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 25"><v-list-item-title>25</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 50"><v-list-item-title>50</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 100"><v-list-item-title>100</v-list-item-title></v-list-item>
              </v-list>
            </v-menu>
            <v-spacer style="width: 60%"></v-spacer>
            <v-text-field style="border: solid 1px #e0e0e0;" width="40" v-model="search" hide-details
              prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
              class="elevation-0 rounded-0"></v-text-field>
            <v-btn v-if="user_allowed.allowed['create user']" @click="createUserGet()" color="#30A662" dark
              class="elevation-0 rounded-0 mr-1 desktopbtn_create" style="margin: 5px; text-decoration: none" small>
              <div style="font-size: 0.85em;"> <v-icon class="mr-1" small>mdi-account-multiple-plus</v-icon>Create User
              </div>
            </v-btn>
            <v-btn v-if="user_allowed.allowed['create ship owner']" @click="createOwnerGet()"
              class="elevation-0 rounded-0 desktopbtn_create" color="#30A662" dark
              style="text-decoration: none; margin: 5px" small>
              <div style="font-size: 0.85em;"> <v-icon class="mr-1" small>mdi-account-plus</v-icon>Create Owner</div>
            </v-btn>

            <v-btn v-if="user_allowed.allowed['create user']" @click="createUserGet()" color="#30A662" dark
              class="elevation-0 rounded-0 mr-1 mobilebtn_create" style="margin: 5px; text-decoration: none" x-small>
              <v-icon x-small>mdi-plus-thick</v-icon>User</v-btn>
            <v-btn v-if="user_allowed.allowed['create ship owner']" @click="createOwnerGet()"
              class="elevation-0 rounded-0 mobilebtn_create" color="#30A662" dark
              style="text-decoration: none; margin: 5px" x-small><v-icon x-small>mdi-plus-thick</v-icon>Owner</v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table style="margin-top: 0px" v-if="adminData" :search="search" :headers="headers" hide-default-footer
            hide-default-header :page.sync="page" :items-per-page="itemsPerPage" :items="adminData"
            @page-count="pageCount = $event" @pagination="yourMethod"
            class="elevation-0 rounded-0 dataTable desktopTable_admin">
            <template v-slot:header>
              <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">No</th>
              <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em"></th>
              <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Name</th>
              <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Email</th>
              <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Username</th>
              <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Role</th>
              <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Group</th>
              <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Enabled</th>
              <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Profile</th>
              <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Premission</th>
              <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Company / Vessel
              </th>
            </template>
            <template v-slot:item="row">
              <tr>
                <td style="white-space: nowrap; min-width: 2.5em;text-align:left" class="pa-0 pl-1">
                  {{ row.item.index + 1 }}
                </td>
                <td style="white-space: nowrap;text-align:left" class="pa-0 pl-1">
                  <v-btn v-if="!row.item.editable" class="mx-2 rounded-0 elevation-0" fab dark x-small color="#9CBDDE"
                    style="cursor: not-allowed">
                    <v-icon small dark>mdi-shield-lock-outline</v-icon>
                  </v-btn>
                  <v-btn v-if="row.item.editable" class="mx-2 rounded-0 elevation-0" fab dark x-small color="primary"
                    @click="fetchEditPass(row.item.id), setEditPass(true)">
                    <v-icon dark>mdi-shield-lock-outline</v-icon>
                  </v-btn>
                </td>

                <td style="white-space: nowrap;text-align:left" class="pa-0 pl-1">
                  {{ row.item.name }}
                </td>
                <td style="white-space: nowrap;text-align:left" class="pa-0 pl-1">
                  {{ row.item.email }}
                </td>
                <td style="white-space: nowrap;text-align:left" class="pa-0 pl-1">
                  {{ row.item.username }}
                </td>
                <td style="white-space: nowrap;text-align:left" class="pa-0 pl-1">
                  {{ row.item.role_name }}
                </td>
                <td style="white-space: nowrap;text-align:left" class="pa-0 pl-1">
                  <div class="grouptxt" v-if="!row.item.group_name">-</div>
                  <div v-if="row.item.group_name">{{ row.item.pt_group }}</div>
                </td>
                <td style="white-space: nowrap;text-align:left" class="pa-0 enabled pl-1"
                  v-if="row.item.isEnabled && row.item.editable == true">
                  <i class="bx bxs-check-square"></i>Yes
                </td>
                <td style="white-space: nowrap;text-align:left" class="pa-0 enabled pl-1"
                  v-if="row.item.isEnabled && row.item.editable != true"></td>
                <td style="white-space: nowrap;text-align:left" class="pa-0 enabled pl-1"
                  v-if="!row.item.isEnabled && row.item.editable != true"></td>
                <td style="white-space: nowrap;text-align:left" class="pa-0 enabled pl-1"
                  v-if="!row.item.isEnabled && row.item.editable == true">
                  <i class="bx bx-check-square"></i>No
                </td>
                <td style="white-space: nowrap;text-align:left" class="pa-0 pr-1 pl-1">
                  <v-btn v-if="row.item.editable != true" dark style="cursor: not-allowed"
                    class="elevation-0 rounded-0 ma-0" color="#9CBDDE" small block>
                    <v-icon small>mdi-pencil-box-multiple-outline</v-icon>
                  </v-btn>
                  <v-btn v-if="row.item.editable == true" @click="fetchEditProf(row.item.id)" dark
                    class="elevation-0 rounded-0 ma-0" color="primary" small block>
                    <v-icon small>mdi-pencil-box-multiple-outline</v-icon>
                  </v-btn>
                </td>
                <td style="white-space: nowrap;text-align:left" class="pa-0 pr-1 pl-1">
                  <v-btn v-if="row.item.editable != true" dark style="cursor: not-allowed"
                    class="elevation-0 rounded-0 ma-0" color="#9CBDDE" small block><v-icon
                      small>mdi-account-key-outline</v-icon></v-btn>
                  <v-btn v-if="row.item.editable == true" dark color="primary" class="elevation-0 rounded-0 ma-0"
                    style="text-decoration: none" @click="premisGet(row.item.id)" small block>
                    <v-icon small>mdi-account-key-outline</v-icon>
                  </v-btn>
                </td>
                <td style="white-space: nowrap;text-align:left" class="pa-0 pr-1 pl-1">
                  <v-btn v-if="row.item.editable != true || row.item.isAdmin" dark block color="#9CBDDE"
                    class="elevation-0 rounded-0 ma-0" style="cursor: not-allowed" small>
                    <v-icon small>mdi-cog-outline</v-icon>
                    <v-icon small>mdi-ferry</v-icon>
                  </v-btn>
                  <v-btn v-if="row.item.editable == true && !row.item.isAdmin" dark block color="primary"
                    class="elevation-0 rounded-0 ma-0" style="text-decoration: none" @click="vesselGet(row.item.id)"
                    small>
                    <v-icon small>mdi-cog-outline</v-icon>
                    <v-icon small>mdi-ferry</v-icon>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table style="margin-top: 0px" v-if="adminData" :search="search" :headers="headers" hide-default-footer
            hide-default-header :page.sync="page" :items-per-page="itemsPerPage" :items="adminData"
            @page-count="pageCount = $event" @pagination="yourMethod"
            class="elevation-0 rounded-0 mobileTable_admin"><template v-slot:item="row">

              <tr style="margin-top:0.4em;margin-bottom:0.4em">
                <td style="border-bottom:none;text-align:left">
                  <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" width="87vw">
                    <v-toolbar dense class="elevation-0 rounded-0">
                      <v-toolbar-subtitle>{{ row.item.index + 1 }}</v-toolbar-subtitle>
                      <v-spacer></v-spacer>



                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                      <table>

                        <tr>
                          <td>Name</td>
                          <td>:</td>
                          <td>{{ row.item.name }}</td>
                        </tr>
                        <tr>
                          <td>Email</td>
                          <td>:</td>
                          <td>{{ row.item.email }}</td>
                        </tr>
                        <tr>
                          <td>Username</td>
                          <td>:</td>
                          <td>{{ row.item.username }}</td>
                        </tr>
                        <tr>
                          <td>Role</td>
                          <td>:</td>
                          <td>{{ row.item.role_name }}</td>
                        </tr>
                        <tr>
                          <td>Group</td>
                          <td>:</td>
                          <td>
                            <div class="grouptxt" v-if="!row.item.group_name">-</div>
                            <div v-if="row.item.group_name">{{ row.item.pt_group }}</div>
                          </td>
                        </tr>
                        <tr>
                          <td>Enabled</td>
                          <td>:</td>
                          <td style="white-space: nowrap" class="pa-0 enabled"
                            v-if="row.item.isEnabled && row.item.editable == true">
                            <i class="bx bxs-check-square"></i>Yes
                          </td>
                          <td style="white-space: nowrap" class="pa-0 enabled"
                            v-if="row.item.isEnabled && row.item.editable != true"></td>
                          <td style="white-space: nowrap" class="pa-0 enabled"
                            v-if="!row.item.isEnabled && row.item.editable != true"></td>
                          <td style="white-space: nowrap" class="pa-0 enabled"
                            v-if="!row.item.isEnabled && row.item.editable == true">
                            <i class="bx bx-check-square"></i>No
                          </td>
                        </tr>

                        <tr>
                          <td colspan="3"><v-divider></v-divider></td>
                        </tr>
                        <tr>
                          <td colspan="3" class="pa-1">

                            <v-btn v-if="row.item.editable != true" dark style="cursor: not-allowed"
                              class="elevation-0 rounded-0 ma-0" color="#9CBDDE" small>
                              <v-icon small>mdi-pencil-box-multiple-outline</v-icon>
                            </v-btn>
                            <v-btn v-if="row.item.editable == true" @click="fetchEditProf(row.item.id)" dark
                              class="elevation-0 rounded-0 ma-0" color="primary" small>
                              <v-icon small>mdi-pencil-box-multiple-outline</v-icon>
                            </v-btn>


                            <v-btn v-if="row.item.editable != true" dark style="cursor: not-allowed"
                              class="elevation-0 rounded-0 ma-0" color="#9CBDDE" small><v-icon
                                small>mdi-account-key-outline</v-icon></v-btn>
                            <v-btn v-if="row.item.editable == true" dark color="primary"
                              class="elevation-0 rounded-0 ma-0" style="text-decoration: none"
                              @click="premisGet(row.item.id)" small>
                              <v-icon small>mdi-account-key-outline</v-icon>
                            </v-btn>


                            <v-btn v-if="row.item.editable != true || row.item.isAdmin" dark color="#9CBDDE"
                              class="elevation-0 rounded-0 ma-0" style="cursor: not-allowed" small>
                              <v-icon small>mdi-cog-outline</v-icon>
                              <v-icon small>mdi-ferry</v-icon>
                            </v-btn>
                            <v-btn v-if="row.item.editable == true && !row.item.isAdmin" dark color="primary"
                              class="elevation-0 rounded-0 ma-0" style="text-decoration: none"
                              @click="vesselGet(row.item.id)" small>
                              <v-icon small>mdi-cog-outline</v-icon>
                              <v-icon small>mdi-ferry</v-icon>
                            </v-btn>

                          </td>
                        </tr>



                      </table>
                    </v-card-text>
                  </v-card>



                </td>
              </tr>

            </template></v-data-table>
          <v-divider></v-divider>
          <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">

            <p class="mt-3" style="font-size:0.8em"> Showing {{ showingDatafrom }} to {{ showingDatato }} of
              {{ totalFilter }} entries</p>
            <v-spacer></v-spacer>
            <v-pagination v-model="page" color="primary" :length="pageCount" :total-visible="7"></v-pagination>
          </v-toolbar>

        </v-card>
      </v-layout>
    </v-card>

    <createowner_modal />
    <createuser_modal v-if="cruserOpen" />
    <permission_modal />
    <company_modal />
  </v-app>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import loadingTable from "@/components/progress/loadingTable.vue";
import createuser_modal from "@/components/modals/createuser_modal.vue";
import permission_modal from "@/components/modals/permission_modal.vue";
import company_modal from "@/components/modals/company_modal.vue";
import createowner_modal from "@/components/modals/createowner_modal.vue";

export default {
  components: {
    loadingTable,
    createuser_modal,
    permission_modal,
    company_modal,
    createowner_modal,
  },
  data() {
    return {
      totalFilter: null,
      itemsPerPage: 10,
      trying: [],
      page: 1,
      pageCount: 0,
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: " ",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Name", value: "name" },
        { text: "Email", value: "email" },
        { text: "Username", value: "username" },
        { text: "Role", value: "role_name" },
        { text: "Group", value: "pt_group" },
        { text: "Enabled", value: "Enabled" },
        { text: "Profile", value: "Profile" },
        { text: "Permission", value: "Permission" },
        { text: "Company/Vessel", value: "Company/Vessel" },
      ],

      selectedItem: "",
      selectedNotif: "",
      search: "",
      showmaptable: "",
      index: "",
    };
  },
  async created() {
    this.fetchAdmin(localStorage.getItem("token"));

  },
  mounted() {
    this.fetchUSERGET(localStorage.getItem("token"))
  },
  methods: {
    ...mapActions([
      "fetchUSERGET",
      "fetchCompany",
      "fetchPermission",
      "setcruserOpen",
      "postCreateOwner",
      "set_sailink_Vessel_",
      "postCreateUser",
      "fetchAdmin",
      "fetchEditPass",
      "setEditPass",
      "setEditProfile",
      "fetchEditProf",
      "fetchCreateOwner",
      "fetchCreateUser",
      "postUserPremis",
    ]),

    reload_page() {
      window.location.reload()
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    yourMethod(pagination) {
      this.totalFilter = pagination.itemsLength

    },

    delcrownerdata() {
      this.crownerOpen = null;
      this.crownerData = null;
    },

    async createUserGet() {
      this.setcruserOpen();
      this.fetchCreateUser(localStorage.getItem("token"));
    },

    async createOwnerGet() {
      this.fetchCreateOwner(localStorage.getItem("token"));
    },

    async vesselGet(user_id) {
      const data = {
        id: user_id,
        token: localStorage.getItem("token"),
      };
      this.fetchCompany(data);
    },

    async premisGet(user_id) {
      const data = {
        id: user_id,
        token: localStorage.getItem("token"),
      };
      this.fetchPermission(data);
    },
  },
  computed: {
    ...mapGetters([
      "OffAdmin",
      "user_allowed",
      "cruserOpen",
      "cuserData",
      "sailinkvessel_",
      "ownerVessel",
      "companyDataPost",
      "userprof",
      "editprofdata",
      "ownerName",
      "ownerData",
      "ownerMail",
      "ownerPTS",
      "ownerPass",
      "ownerconfirmpass",
      "adminData",
      "crownerData",
      "premisDataPost",
    ]),

    showingDatafrom() {
      if (this.totalFilter < 1) {
        return 0
      } else {
        return 1
      }

    },

    showingDatato() {
      if (this.totalFilter < this.itemsPerPage) {
        return this.totalFilter
      } else {
        return this.itemsPerPage
      }

    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/data.scss";
@import "@/assets/css/adminView.css";
</style>
