import axios from "../../plugins/axios";

const getDefaultState = () => {
    return {
        manifest_data: null,
        showmanifest: null,
        thurNumber: null,
        headerM: [],
        outputM: null,
        showmanifestclick: null,
        clickmanifest: null,
        OffManifest:null,
    }
}
const state = getDefaultState();

const getters = {
    manifestData: (state) => state.manifest_data,
    showmanifest: (state) => state.showmanifest,
    thurNumber: (state) => state.thurNumber,
    headerM: (state) => state.headerM,
    outputM: (state) => state.outputM,
    showmanifestclick: (state) => state.showmanifestclick,
    clickmanifest: (state) => state.clickmanifest,
    OffManifest:(state) => state.OffManifest
};

const actions = {
    async fetchManifest({commit}, data) {
        try{

        
        const response = await axios.get(
            "/api/v1/navplus/manifest",
            {
                headers: {
                    Authorization: "Bearer" + data,
                },
            }
        );
        //console.log(response);
        commit(
            "setManifest",
            response.data.data.vessels.map((item, index) =>
                Object.assign(item, {index})
            )
        );
        commit("setOffManifest",null)
    }catch(e){
        commit("setOffManifest",true)   
        }
    },

    async fetchShowManifest({commit}, data) {
        commit("setHeaderEmpty", []);
        commit("setHeaderFirst", true);
        commit("setshowmanifestclick", true);
        commit("setclickmanifest", true);
        commit("setshowmanifest", null);
        commit("setthurnumber", null);
        var dataManifest = {
            range: data.range,
            thuraya_number: data.thuraya_number,
        };
        const response = await axios.post(
            "/api/v1/navplus/manifest",
            dataManifest,
            {
                headers: {
                    Authorization: "Bearer" + data.token,
                },
            }
        );
        //console.log(response);
        //console.log("IKAN=========");
        for (var i = 0; i < response.data.data.fish.length; i++) {
            // if (this.showmanifest.fish[i] == 1) ;a.push(5);
            //console.log(response.data.data.fish[i]);
        }
        commit("setthurnumber", data.thuraya_number);
        commit("setshowmanifest", response.data.data);
        commit(
            "setOutput",
            response.data.data.output.map((item, index) =>
                Object.assign(item, {index})
            )
        );
        commit("setHeader", response.data.data);
    },

    removeShowManifest({commit}) {
        commit("setthurnumber", null);
        commit("setshowmanifest", null);
        commit("setOutput", null);
        commit("setHeaderEmpty", []);
        commit("setshowmanifestclick", null);
        commit("setclickmanifest", false);
    },

    resetManifestState({commit}) {
        commit("resetState");
    },
};

const mutations = {
    setOffManifest:(state,OffManifest) =>(state.OffManifest = OffManifest),
    setManifest: (state, manifest) => (state.manifest_data = manifest),
    setshowmanifest: (state, showmanifest) => (state.showmanifest = showmanifest),
    setthurnumber: (state, thurNumber) => (state.thurNumber = thurNumber),
    setOutput: (state, outputM) => (state.outputM = outputM),
    setshowmanifestclick: (state, showmanifestclick) =>
        (state.showmanifestclick = showmanifestclick),
    setclickmanifest: (state, clickmanifest) =>
        (state.clickmanifest = clickmanifest),
    setHeaderFirst(state, headerM) {
        console.log(headerM);
        state.headerM.push({text: "#", value: "index", sortable: true});
    },
    setHeaderEmpty(state, headerM) {
        console.log(headerM);
        state.headerM.length = 0;
    },
    setHeader(state, headerM) {
        for (var i = 0; i < headerM.fish.length; i++) {
            state.headerM.push({
                text: headerM.fish[i],
                value: headerM.fish[i],
                sortable: true,
            });
        }
    },

    resetState(state) {
        Object.assign(state, getDefaultState());
    },

};

export default {
    state,
    getters,
    actions,
    mutations,
};
