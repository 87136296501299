<template>
   <app>
 
  <div style="background-color: #F7F7F7;height:100vh;padding:2em;padding-top:25vh" >

      <v-flex sm12 md4 offset-md4>
        <v-card style="border: solid 1px #e0e0e0"   elevation="0" class="rounded-0 pa-6" light tag="section" >

              <h3 class="headline"></h3>
              <v-card-text>
                
                <v-img
                  alt="platformName"
                  class="ma-50"
                  contain
                  height="8em"
                  position="center"
                  :src="image"
                ></v-img>
              </v-card-text>
              
              <v-progress-linear
    indeterminate
    color="#343A40"
  ></v-progress-linear>
           
          <v-card-text>   
            <h2>
             Checking for user info
            </h2>
          </v-card-text>
        </v-card>
      </v-flex>
     
   
  </div>
</app>
</template>

<script>
import { mapActions } from "vuex";
import axios from "../plugins/axios_outside";
export default {
    data() {
  return {   
    image: require("@/assets/img/logo.svg"),
  };
},
  mounted() {
    console.log(this.$route.params.token);
    // this.getUserData(this.$route.params.token);
    this.fetchOutside(this.$route.params.token)
  },

  methods: {
    ...mapActions([
    "setEditProfile","fetchUserData","setUserData","setsetloading_map","fetchOutside_","addoutsidelogin_"]),
    getUserData(token) {
     
      localStorage.setItem("token", token);
      localStorage.setItem("auth", true);
      this.setsetloading_map(true)
      this.$router.push("/navigatorplus");
      this.fetchUserData()
    },

    async fetchOutside(token) { 
      try{
        const response = await axios.post("/api/login_by_id", 
          {
          'token':token,
         
        });

        // this.getUserData(response.data.token)
        if(response.data.status == 'Token is Valid'){
          this.getUserData(response.data.token)
          this.addoutsidelogin_(true)
        }else{
          this.$router.push("/");
        }
      }catch(e){
        this.$router.push("/");
        console.log(e)
      }
        
    },
  },
};
</script>

<style lang="scss" scoped></style>
