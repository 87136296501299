<template>
  <v-app class="container_tabledata" color="#fff">
    <v-card elevation="0">
      <v-alert
      v-if="OffReports"
      class="elevation-0 rounded-0"
      prominent
      type="warning"
      icon="mdi-web"
    >
      <v-row align="center">
        <v-col class="grow">
          Connection timeout , please check your internet connection
        </v-col>
        <v-col class="shrink">
          <v-btn small @click="reload_page()" class="elevation-0 rounded-0">Reload</v-btn>
        </v-col>
      </v-row>
    </v-alert>
      <v-layout v-resize="onResize" column style="">
        <loadingTable v-if="!reportsData" />
      <v-card v-if="reportsData" class="ma-3 elevation-0 rounded-0" style="border: solid 1px #E0E0E0;">
        <v-toolbar
          
          elevation="0"
            class="pa-0"
            color="#F7F7F7"
            fixed
            dense

        >
        <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                style="border: solid 1px #e0e0e0"
                  small
                  class="rounded-0 pa-1 elevation-0 ma-0"
                  color="#ffff"
                  v-bind="attrs"
                  v-on="on"
                >
                  {{ kFormatter(itemsPerPage)
                  }}<v-icon small style="margin-left: 0.2em"
                    >mdi-unfold-more-horizontal</v-icon
                  >
                </v-btn>
              </template>
              <v-list style="border: solid 1px #E0E0E0;">
                <v-list-item @click="itemsPerPage =  10"
                  ><v-list-item-title>10</v-list-item-title></v-list-item
                >
                <v-list-item @click="itemsPerPage = 25"
                  ><v-list-item-title>25</v-list-item-title></v-list-item
                >
                <v-list-item @click="itemsPerPage = 50"
                  ><v-list-item-title>50</v-list-item-title></v-list-item
                >
                <v-list-item @click="itemsPerPage = 100"
                  ><v-list-item-title>100</v-list-item-title></v-list-item
                >
              </v-list>
            </v-menu>
            <v-spacer style="width: 70%"></v-spacer>
   

            <v-text-field
            style="border: solid 1px #e0e0e0;"
              width="40"
              v-model="search"
              hide-details
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              solo
              clearable
              class="elevation-0 rounded-0"
              @click="nulsearch()"
            ></v-text-field>
        </v-toolbar>
        <v-divider></v-divider>
        <v-data-table
          style="margin-top: 0px"
          v-if="reportsData"
          :search="search"
          :headers="headers"
          :items="reportsData"
          :items-per-page="itemsPerPage"
          hide-default-footer
          hide-default-header
          @pagination="yourMethod"
          :page.sync="page"
          class="elevation-0 rounded-0 dataTable desktopTable_reports"
          @page-count="pageCount = $event"
        >
        <template  v-slot:header>
        <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">No</th>
        <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Vessel Name</th>
        <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Company</th>
        <th class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">Reports</th>
      </template>
          <template v-slot:item="row">
            <tr class="pa-0">
              <td width="4%" class="pa-0 pl-1" style="white-space: nowrap;text-align:left ">
                {{ row.item.index+1 }}
              </td>
              <td width="29%"  class="pa-0 pl-1" style="white-space: nowrap; padding: 1em;text-align:left ">
                {{ row.item.name }}
              </td>
              <td width="17%" class="pa-0 pl-1" style="white-space: nowrap; padding: 1em;text-align:left">
                {{ row.item.pt_name }}
              </td>

              <td  width="50%" class="pa-0 pl-1" style="white-space: nowrap; padding: 1em;text-align:left">
             
                  <v-menu content-class="elevation-0 rounded-0 pa-0" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                      width="25%"
                        dark
                        class="ma-1 ml-0 elevation-0 rounded-0 pa-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                      <div style="font-size: 0.85em;">Application<v-icon class="ml-2" small>mdi-chevron-down</v-icon></div>
                        
                      </v-btn>
                    </template>
                    <v-list
                    style="font-size: 0.9em"
                      color="#1665B4"
                      class="pa-0"
                    >
                      <v-list-item
                      style="color: #ffff"
                        class="pa-0 ma-0 pl-2"
                        @click="
                            (appopen = true),
                              sendModalApp(
                                'metered',
                                row.item,
                                'Daily',
                                'LAST 24 HOURS'
                              )
                          "
                      >
                      Metered
                      </v-list-item>
                      <v-list-item style="color: #ffff"
                        class="pa-0 ma-0 pl-2"  @click="
                            (appopen = true),
                              sendModalApp(
                                'unmetered',
                                row.item,
                                'Daily',
                                'LAST 24 HOURS'
                              )
                          ">
                      Unmetered
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                  width="15%"
                    dark
                    color="primary"
                    @click="
                      (srcOpen = true),
                        sendModalSource(row.item, 'Daily', 'LAST 24 HOURS')
                    "
                    class="ma-1 elevation-0 rounded-0 pa-1"
                    small
                  >
                  <div style="font-size: 0.85em;">Source</div>
                    
                  </v-btn>
                  <v-btn
                  width="26%"
                    dark
                    class="ma-1 elevation-0 rounded-0 pa-1"
                    color="primary"
                    @click="
                      (dstOpen = true),
                        sendModalDst(row.item, 'Daily', 'LAST 24 HOURS')
                    "
                    small
                  >
                    <div style="font-size: 0.85em;">Destination</div>
                  </v-btn>
                  <v-btn
                  width="27%"
                    dark
                    class="ma-1 elevation-0 rounded-0 pa-1"
                    color="primary"
                    @click="
                      (convOpen = true),
                        sendModalConv(row.item, 'Daily', 'LAST 24 HOURS', inout)
                    "
                    small
                    > <div style="font-size: 0.85em;">Conversation</div>
                  </v-btn>
               
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-data-table
        style="margin-top: 0px"
          v-if="reportsData"
          :search="search"
          :headers="headers"
          :items="reportsData"
          :items-per-page="itemsPerPage"
          hide-default-footer
          hide-default-header
          @pagination="yourMethod"
          :page.sync="page"
          class="elevation-0 rounded-0 mobileTable_reports"
          @page-count="pageCount = $event"
          ><template v-slot:item="row">
    
      <tr style="margin-top:0.4em;margin-bottom:0.4em" >
        <td style="border-bottom:none;text-align:left" >
        <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" width="87vw">
          <v-toolbar
           dense
           class="elevation-0 rounded-0"
          >
            <v-toolbar-subtitle>{{row.item.index+1}}</v-toolbar-subtitle>
            <v-spacer></v-spacer>
           
                

          </v-toolbar>
          <v-divider></v-divider>
      <v-card-text>
              <table>
           
            <tr><td>Vessel Name</td><td>:</td><td>{{row.item.name}}</td></tr>
            <tr><td>Company</td><td>:</td><td>{{row.item.pt_name}}</td></tr>
            <tr><td colspan="3"><v-divider></v-divider></td></tr>
            <tr><td  colspan="3" class="pa-1">
                  <v-menu content-class="elevation-0 rounded-0 pa-0" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                    
                        dark
                        class="ma-1 ml-0 elevation-0 rounded-0 pa-1"
                        color="primary"
                        v-bind="attrs"
                        v-on="on"
                        small
                      >
                      Application &nbsp;
                        <v-icon small>mdi-chevron-down</v-icon>
                      </v-btn>
                    </template>
                    <v-list
                    style="font-size: 0.9em"
                      color="#1665B4"
                      class="pa-0"
                    >
                      <v-list-item
                      style="color: #ffff"
                        class="pa-0 ma-0 pl-2"
                        @click="
                            (appopen = true),
                              sendModalApp(
                                'metered',
                                row.item,
                                'Daily',
                                'LAST 24 HOURS'
                              )
                          "
                      >
                      Metered
                      </v-list-item>
                      <v-list-item style="color: #ffff"
                        class="pa-0 ma-0 pl-2"  @click="
                            (appopen = true),
                              sendModalApp(
                                'unmetered',
                                row.item,
                                'Daily',
                                'LAST 24 HOURS'
                              )
                          ">
                      Unmetered
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn
                 
                    dark
                    color="primary"
                    @click="
                      (srcOpen = true),
                        sendModalSource(row.item, 'Daily', 'LAST 24 HOURS')
                    "
                    class="ma-1 elevation-0 rounded-0 pa-1"
                    small
                  >
                    Source
                  </v-btn>
                  <v-btn
                 
                    dark
                    class="ma-1 elevation-0 rounded-0 pa-1"
                    color="primary"
                    @click="
                      (dstOpen = true),
                        sendModalDst(row.item, 'Daily', 'LAST 24 HOURS')
                    "
                    small
                  >
                    <div style="font-size: 0.85em;">Destination</div>
                  </v-btn>
                  <v-btn
                
                    dark
                    class="ma-1 elevation-0 rounded-0 pa-1"
                    color="primary"
                    @click="
                      (convOpen = true),
                        sendModalConv(row.item, 'Daily', 'LAST 24 HOURS', inout)
                    "
                    small
                    ><div style="font-size: 0.85em;">Conversation</div>
                  </v-btn></td></tr>
        
            
          </table>
            </v-card-text>
          </v-card>
          
          
          
        </td>
      </tr>
   
  </template></v-data-table>
        <v-divider></v-divider>
        <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">




<p class="mt-3" style="font-size: 0.8em">
              Showing {{pageStart}} to {{ pageStop }} of
              {{totalFilter}} entries
            </p>
<v-spacer></v-spacer>
<v-pagination
              color="primary"
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
</v-toolbar>
     
      </v-card>


      <!--Modal App-->
      <v-dialog v-model="appopen" max-height="30vh" width="80vw" persistent>
        <v-card color="#fffff"  class="elevation-0 rounded-0 pb-2">
          <v-toolbar color="#f7f7f7"  class="elevation-0 rounded-0" dense>
            <v-col class="header_modal">
              <v-row
                ><v-toolbar-title
                  >Application Report
                  Summary</v-toolbar-title
                ></v-row
              >
            </v-col>

            <v-spacer></v-spacer>

            <v-btn small class="elevation-0 rounded-0" icon  @click="(appopen = false), delmodalapp()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-toolbar v-if="modalapp" class="elevation-0 rounded-0" color="#fff" dense>
            <v-toolbar-subtitle >{{
              modalapp.vessel_pt
            }}</v-toolbar-subtitle>

            <v-spacer></v-spacer>

          </v-toolbar>
          <v-divider v-if="modalapp"></v-divider>
          <v-container style="margin-top:0" v-if="!modalapp && !pesanapp" fill-height fluid>
            <v-row align="center" justify="center">
              <v-col
                ><img
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
              /></v-col>
            </v-row>
          </v-container>
           
          <v-card v-if="modalapp" class="elevation-0 rounded-0" >

            <v-card  style="border: solid 1px #e0e0e0" class="ma-2 elevation-0 rounded-0">
            <v-toolbar dense class="elevation-0 rounded-0"><v-spacer></v-spacer>  <v-menu offset-y content-class="elevation-0 rounded-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn  style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0" color="#ffff" v-bind="attrs" v-on="on" small>
                  <i class="bx bx-time"></i>
                  &nbsp; &nbsp;
                  <v-text v-if="labeldur">{{ labeldur }}</v-text>
                  <v-card-text v-if="!labeldur">
                    <div class="snippet" data-title=".dot-pulse">
                      <div class="stage">
                        <div class="dot-pulse"></div>
                      </div>
                    </div>
                  </v-card-text>
                  &nbsp; &nbsp;
                  <i class="bx bxs-chevron-down"></i>
                </v-btn>
              </template>
              <v-list  style="border: solid 1px #e0e0e0">
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalApp(
                        modalapprule,
                        modalappid,
                        'hourly',
                        'LAST HOUR'
                      ),
                        delmodalapp()
                    "
                    >Last Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalApp(
                        modalapprule,
                        modalappid,
                        '6hour',
                        'LAST 6 HOUR'
                      ),
                        delmodalapp()
                    "
                    >Last 6 Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalApp(
                        modalapprule,
                        modalappid,
                        'Daily',
                        'LAST 24 HOUR'
                      ),
                        delmodalapp()
                    "
                    >Last 24 Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalApp(modalapprule, modalappid, 'today', 'TODAY'),
                        delmodalapp()
                    "
                    >Today</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalApp(
                        modalapprule,
                        modalappid,
                        'yesterday',
                        'YESTERDAY'
                      ),
                        delmodalapp()
                    "
                    >Yesterday</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalApp(
                        'metered',
                        modalappid,
                        'Weekly',
                        'LAST 7 DAYS'
                      ),
                        delmodalapp()
                    "
                    >Last 7 Days</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalApp(
                        'metered',
                        modalappid,
                        'Monthly',
                        'LAST 30 DAYS'
                      ),
                        delmodalapp()
                    "
                    >Last 30 Days</v-btn
                  >
                </v-list-item>
              </v-list>
            </v-menu></v-toolbar>
           
            <div v-if="modalapp">
              <AppChart
            height="350"
            
              v-if="modalapp.chart.datasets"
              v-bind:datachart="modalapp.chart"
              v-bind:modalapp="modalapp"
            />
            </div>
            
          </v-card>
          
          <v-card v-if="modalapp" class="ma-2 mb-0 elevation-0 rounded-0" style="border: solid 1px #e0e0e0">
            
              <v-data-table
              v-if="modalapp.chart.datasets"
              :headers="headersecond"
              :items="modalapp.dataTable"
              hide-default-footer
            :page.sync="pageApp"
            :items-per-page="itemsPerPageApp"
            class="elevation-0 rounded-0 dataTable"
            @page-count="pageCountApp = $event"
            >
              <template v-slot:item="row">
                <tr>
                  <!-- <td>{{row.index}}</td> -->
                  <td>{{ row.item.Name }}</td>
                  <td>{{ row.item.volume }}</td>
                  <td>{{ row.item.traffic }}</td>
                </tr>
              </template>
            </v-data-table>
            <div v-if="modalapp && modalapp.message" class="ma-10">
              No Data Available<br/>
              Please Select Another Timeframe
            </div>
          </v-card>

          </v-card>
        </v-card>
      </v-dialog>
      <!--Modal App End-->

      <!--Modal Source-->
      <v-dialog v-model="srcOpen" max-height="30vh" width="80vw" persistent>
        <v-card class="elevation-0 rounded-0 pa-0 pb-2" color="#ffff">
          <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7"  dense>
            <v-col class="header_modal">
              <v-row
                ><v-toolbar-title>
               Source Report
                  Summary</v-toolbar-title
                ></v-row
              >
            </v-col>
            <v-spacer></v-spacer>

            <v-btn small class="elevation-0 rounded-0" icon  @click="(srcOpen = false), delsrcdata()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-toolbar v-if="srcData" class="elevation-0 rounded-0" dense color="#ffff">      
            <v-toolbar-subtitle >{{
              srcData.vessel_pt
            }}</v-toolbar-subtitle>
            <v-spacer></v-spacer>
           
          </v-toolbar>
          <v-divider v-if="srcData"></v-divider>
          <v-container style="margin-top:0" v-if="!srcData" fill-height fluid>
            <v-row align="center" justify="center">
              <v-col
                ><img
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
              /></v-col>
            </v-row>
          </v-container>
     
            <v-card v-if="srcData" class="elevation-0 rounded-0 ma-0 pa-0 ma-2 mb-0"  style="border: solid 1px #e0e0e0"> 
              <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">
                <v-spacer>
                </v-spacer>
                <v-menu offset-y content-class="elevation-0 rounded-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn   style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0" v-bind="attrs" v-on="on" small color="#fff ">
                  <i class="bx bx-time"></i>
                  &nbsp; &nbsp;
                  <v-text v-if="labeldur">{{ labeldur }}</v-text>
                  <v-card-text v-if="!labeldur">
                    <div class="snippet" data-title=".dot-pulse">
                      <div class="stage">
                        <div class="dot-pulse"></div>
                      </div>
                    </div>
                  </v-card-text>
                  &nbsp; &nbsp;
                  <i class="bx bxs-chevron-down"></i>
                </v-btn>
              </template>
              <v-list   style="border: solid 1px #e0e0e0">
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalSource(modalsrcid, 'hourly', 'LAST HOUR'),
                        delsrcdata()
                    "
                    >Last Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalSource(modalsrcid, '6hour', 'LAST 6 HOURS'),
                        delsrcdata()
                    "
                    >Last 6 Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalSource(modalsrcid, 'today', 'LAST 24 HOURS'),
                        delsrcdata()
                    "
                    >Last 24 Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalSource(modalsrcid, 'Daily', 'TODAY'),
                        delsrcdata()
                    "
                    >Today</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalSource(modalsrcid, 'yesterday', 'YESTERDAY'),
                        delsrcdata()
                    "
                    >Yesterday</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalSource(modalsrcid, 'Weekly', 'LAST 7 DAYS'),
                        delsrcdata()
                    "
                    >Last 7 Days</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalSource(modalsrcid, 'Monthly', 'LAST 30 HOURS'),
                        delsrcdata()
                    "
                    >Last 30 Days</v-btn
                  >
                </v-list-item>
              </v-list>
            </v-menu>
              </v-toolbar>
              <v-divider></v-divider>
              <v-data-table
                
                :headers="headersSRC"
                :items="srcData.dataTable"
                hide-default-footer
            :page.sync="pageSource"
            :items-per-page="itemsPerPageSource"
            class="elevation-0 rounded-0 dataTable"
            @page-count="pageCountSource = $event"
              >
                <template v-slot:item="row">
                  <tr class="pa-0">
                    <!-- <td>{{row.index}}</td> -->
                    <td class="pa-0 pl-1">{{ row.item.Name }}</td>
                    <td class="pa-0 pl-1">{{ row.item.ip }}</td>
                    <td class="pa-0 pl-1">{{ row.item.volume }}</td>
                    <td class="pa-0 pl-1">{{ row.item.traffic }}</td>
                  </tr>
                </template>
              </v-data-table>
             
          
          </v-card>

            
        </v-card>
      </v-dialog>
      <!--Modal Source End-->

      <!--Modal Dest-->
      <v-dialog v-model="dstOpen" width="80vw" persistent>
        <v-card class="elevation-0 rounded-0 pb-2" color="#ffff">
          <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0" dense >
            <v-col class="header_modal">
              <v-row
                ><v-toolbar-title
                  >Destination Report Summary</v-toolbar-title
                ></v-row
              >
            </v-col>

            <v-spacer></v-spacer>

            <v-btn small class="elevation-0 rounded-0" icon  @click="(dstOpen = false), delmodaldest()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-toolbar  v-if="dstData" class="elevation-0 rounded-0"  dense color="#ffff">
            <v-toolbar-subtitle >{{
              dstData.vessel_pt
            }}</v-toolbar-subtitle>
            <v-spacer></v-spacer>
          
          </v-toolbar>
          <v-divider v-if="dstData"></v-divider>
          <v-container style="margin-top:0" v-if="!dstData" fill-height fluid>
            <v-row align="center" justify="center">
              <v-col
                ><img
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
              /></v-col>
            </v-row>
          </v-container>
          <v-card v-if="dstData"  style="border: solid 1px #e0e0e0" class="m-2 ma-2 mb-0 elevation-0 rounded-0">
          <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">
            <v-spacer></v-spacer>
            <v-menu offset-y content-class="elevation-0 rounded-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn   style="border: solid 1px #e0e0e0" class="rounded-0 elevation-0" color="#fff" v-bind="attrs" v-on="on" small>
                  <i class="bx bx-time"></i>
                  &nbsp; &nbsp;
                  <v-text v-if="labeldur">{{ labeldur }}</v-text>
                  <v-card-text v-if="!labeldur">
                    <div class="snippet" data-title=".dot-pulse">
                      <div class="stage">
                        <div class="dot-pulse"></div>
                      </div>
                    </div>
                  </v-card-text>
                  &nbsp; &nbsp;
                  <i class="bx bxs-chevron-down"></i>
                </v-btn>
              </template>
              <v-list   style="border: solid 1px #e0e0e0">
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalDst(destid, 'hourly', 'LAST HOUR'),
                        delmodaldest()
                    "
                    >Last Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalDst(destid, '6hour', 'LAST 6 HOURS'),
                        delmodaldest()
                    "
                    >Last 6 Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalDst(destid, 'today', 'LAST 24 HOURS'),
                        delmodaldest()
                    "
                    >Last 24 Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalDst(destid, 'Daily', 'TODAY'), delmodaldest()
                    "
                    >Today</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalDst(destid, 'yesterday', 'YESTERDAY'),
                        delmodaldest()
                    "
                    >Yesterday</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalDst(destid, 'Weekly', 'LAST 7 DAYS'),
                        delmodaldest()
                    "
                    >Last 7 Days</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalDst(destid, 'Monthly', 'LAST 30 DAYS'),
                        delmodaldest()
                    "
                    >Last 30 Days</v-btn
                  >
                </v-list-item>
              </v-list>
            </v-menu>
          </v-toolbar>
          <v-divider></v-divider>
              <v-data-table
                
                hide-default-footer
                :headers="headersDst"
                :items="dstData.dataTable"
                :page.sync="pageDest"
            :items-per-page="itemsPerPageDest"
            class="elevation-0 rounded-0 dataTable"
            @page-count="pageCountDest = $event"
              >
                <template v-slot:item="row">
                  <tr>
                    <!-- <td>{{row.index}}</td> -->
                    <td>{{ row.item.Name }}</td>
                    <td>{{ row.item.ip }}</td>
                    <td>{{ row.item.volume }}</td>
                    <td>{{ row.item.traffic }}</td>
                  </tr>
                </template>
              </v-data-table>
             
          </v-card>

          <!-- <v-card-actions class="justify-end"> </v-card-actions> -->
        </v-card>
      </v-dialog>
      <!--Modal Dest End-->

      <!--Modal Conversation-->
      <v-dialog v-model="convOpen" width="80vw" persistent>
        <v-card class="elevation-0 rounded-0 pb-2" color="#ffff">
          <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0"  dense>
            <v-col class="header_modal">
              <v-row
                ><v-toolbar-title
                  >Conversation Report
                  Summary</v-toolbar-title
                ></v-row
              >
            </v-col>

            <v-spacer></v-spacer>

            <v-btn small class="elevation-0 rounded-0" icon  @click="(convOpen = false), delmodalConv()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-toolbar v-if="convData" class="elevation-0 rounded-0" color="#ffff" dense>
            <v-toolbar-subtitle >{{
              convData.vessel_pt
            }}</v-toolbar-subtitle>
            <v-spacer></v-spacer>
           
          </v-toolbar>
          <v-divider v-if="convData"></v-divider>
          <v-container style="margin-top:0" v-if="!convData" fill-height fluid>
            <v-row align="center" justify="center">
              <v-col
                ><img
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
              /></v-col>
            </v-row>
          </v-container>

          
            <v-card style="border: solid 1px #e0e0e0"  class="elevation-0 rounded-0 ma-2 mb-0" v-if="convData">
              <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">
                <v-spacer></v-spacer>
                <v-menu content-class="elevation-0 rounded-0" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn   style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0" v-bind="attrs" v-on="on" small color="#ffff">
                  <i class="bx bx-time"></i>
                  &nbsp; &nbsp;
                  <v-text v-if="labeldur">{{ labeldur }}</v-text>
                  <v-card-text v-if="!labeldur">
                    <div class="snippet" data-title=".dot-pulse">
                      <div class="stage">
                        <div class="dot-pulse"></div>
                      </div>
                    </div>
                  </v-card-text>
                  &nbsp; &nbsp;
                  <i class="bx bxs-chevron-down"></i>
                </v-btn>
              </template>
              <v-list   style="border: solid 1px #e0e0e0">
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, 'hourly', 'LAST HOUR', inout),
                        delmodalConv()
                    "
                    >Last Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, '6hour', 'LAST 6 HOURS', inout),
                        delmodalConv()
                    "
                    >Last 6 Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, 'today', 'LAST 24 HOURS', inout),
                        delmodalConv()
                    "
                    >Last 24 Hour</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, 'Daily', 'TODAY', inout),
                        delmodalConv()
                    "
                    >Today</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, 'yesterday', 'YESTERDAY', inout),
                        delmodalConv()
                    "
                    >Yesterday</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, 'Weekly', 'LAST 7 DAYS', inout),
                        delmodalConv()
                    "
                    >Last 7 Days</v-btn
                  >
                </v-list-item>
                <v-list-item>
                  <v-btn
                    block
                    plain
                    small
                    @click="
                      sendModalConv(convid, 'Monthly', 'LAST 30 DAYS', inout),
                        delmodalConv()
                    "
                    >Last 30 Days</v-btn
                  >
                </v-list-item>
              </v-list>
            </v-menu>
              </v-toolbar>
              <v-divider></v-divider>
              <v-toolbar
          elevation="0"
            class="pa-0"
            color="#FFFF"
            fixed
            dense

        >
        <v-radio-group style="margin-top: 1.2em;margin-left:-1em" class="pl-0" v-model="inout" row>
              <v-radio
              class="ml-0"
           
                label="IN"
                value="IN"
                @click="
                  sendModalConv(convid, time, labeldur, inout), delmodalConv()
                "
              ></v-radio>
              <v-radio
                label="OUT"
                value="OUT"
                @click="
                  sendModalConv(convid, time, labeldur, inout), delmodalConv()
                "
              ></v-radio>
            </v-radio-group>
            <v-spacer style="width: 50%"></v-spacer>
            
            <v-text-field
              style="border: solid 1px #e0e0e0"
              width="40"
              v-model="searchcon"
              hide-details
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              solo
              clearable
              class="elevation-0 rounded-0"
           
            ></v-text-field>
        </v-toolbar>
        <v-divider></v-divider>
              <v-data-table
                :search="searchcon"
                :headers="headersConv"
                :items="convData.dataTable"
                hide-default-footer
                :page.sync="pageConv"
            :items-per-page="itemsPerPageConv"
            class="elevation-0 rounded-0 dataTable"
            @page-count="pageCountConv = $event"
              >
                <template v-slot:item="row">
                  <tr>
                    <!-- <td>{{row.index}}</td> -->
                    <td>{{ row.item.src }}</td>
                    <td>{{ row.item.dst }}</td>
                    <td>{{ row.item.app }}</td>
                    <td>{{ row.item.port }}</td>
                    <td>{{ row.item.prot }}</td>
                    <td>{{ row.item.dscp }}</td>
                    <td>{{ row.item.traffic }}</td>
                  </tr>
                </template>
              </v-data-table>
              <v-divider></v-divider>
          <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
            <v-spacer></v-spacer>
            <v-pagination
            v-if="convData"
              class="pt-2 pb-2"
              color="primary"
              v-model="pageConv"
              :length="pageCountConv"
              :total-visible="7"
            ></v-pagination>
          </v-toolbar>
          </v-card>
</v-card>
        
      </v-dialog>
      <!--Modal Conversation End-->
    </v-layout>
    </v-card>
  </v-app>
</template>

<script>
import axios from "axios";
import loadingTable from "@/components/progress/loadingTable.vue"
// import TableSkeleton from "@/components/skeleton/TableSkeleton.vue";
import AppChart from "@/components/charts/AppChart.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    // TableSkeleton,
    loadingTable,
    AppChart,
  },
  data() {
    return {
      pageStart:0,
      pageStop:0,
      totalFilter:null,
      pesanapp:null,
      itemsPerPage: 10,
      pageSource:1,
      itemsPerPageSource:5,
      pageCountSource: 0,
      page: 1,
      pageCount: 0,
      pageApp:1,
      itemsPerPageApp:5,
      pageCountApp: 0,
      pageConv:1,
      itemsPerPageConv:5,
      pageCountConv: 0,
      pageDest:1,
      itemsPerPageDest:5,
      pageCountDest: 0,
      headersConv: [
        // {
        // text: '#',
        // align: 'start',
        // sortable: false,
        // value: 'id',
        // },
        { text: "Source", value: "name" },
        { text: "Destination", value: "ip" },
        { text: "Application", value: "pt_name" },
        { text: "Port", value: "pt_name" },
        { text: "Protocol", value: "pt_name" },
        { text: "DSCP", value: "pt_name" },
        { text: "Traffic", value: "pt_name" },
      ],
      headersSRC: [
        // {
        // text: '#',
        // align: 'start',
        // sortable: false,
        // value: 'id',
        // },
        { text: "Name", value: "name" },
        { text: "IP", value: "ip" },
        { text: "Traffic", value: "pt_name" },
        { text: "% of Traffic", value: "pt_name" },
      ],
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Vessel Name", value: "name", width: "30%", sortable: false },
        { text: "Company", value: "pt_name", width: "20%", sortable: false },
        { text: "Reports", value: "good", width: "50%", sortable: false },
      ],
      headersecond: [
        // {
        // text: '#',
        // align: 'start',
        // sortable: false,
        // value: 'id',
        // },
        { text: "Name", value: "name" },
        { text: "Traffic", value: "pt_name" },
        { text: "% of Traffic", value: "pt_name" },
      ],
      headersDst: [
        // {
        // text: '#',
        // align: 'start',
        // sortable: false,
        // value: 'id',
        // },
        { text: "Name", value: "name" },
        { text: "IP", value: "ip" },
        { text: "Traffic", value: "pt_name" },
        { text: "% of Traffic", value: "pt_name" },
      ],
      loadernavplus: require("@/assets/img/load100new.gif"),
      noway: require("@/assets/img/wrongway_navplus.png"),
      modalapp: null,
      modalappun: null,
      modalsource: null,
      modaldst: null,
      modalconv: null,
      appopen: false,
      srcOpen: false,
      srcData: null,
      dstOpen: false,
      dstData: null,
      convOpen: null,
      convData: null,
      modalappid: null,
      modalapprule: null,
      modalsrcid: null,
      destid: null,
      convid: null,
      labeldur: null,
      time: null,
      inout: "IN",
      search: "",
      searchcon: "",
    };
  },
  async created() {
    this.fetchReports(localStorage.getItem("token"));
  },
  mounted(){
    this.fetchUSERGET(localStorage.getItem("token"))
  },
  methods: {
   
    ...mapActions(["fetchReports","fetchUSERGET"]),

    reload_page(){
    window.location.reload()
  },
  
    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    yourMethod(pagination) {
      this.totalFilter = pagination.itemsLength
      this.pageStart = pagination.pageStart+1
      this.pageStop =  pagination.pageStop

  },

    delmodalConv() {
      (this.convData = null), (this.convid = null), (this.labeldur = null);
    },
    delmodalapp() {
      (this.modalapp = null),
        (this.modalappun = null),
        (this.modalappid = null),
        (this.modalapprule = null),
        (this.labeldur = null);
        this.pesanapp =  null
    },

    delsrcdata() {
      (this.modalsrcid = null), (this.srcData = null);
      this.labeldur = null;
    },

    delmodaldest() {
      (this.destid = null), (this.dstData = null), (this.labeldur = null);
    },

    onButtonClick(item) {
      console.log("click on " + item.no);
    },

    async sendModalApp(rule, data, time, range) {
      if (rule == "metered") {
        const response = await axios.get(
          "https://navigatorplus.sailink.id/api/v1/navplus/nta/app/" +
            data.id +
            "/" +
            data.nfa_id +
            "/" +
            time,
          {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
            },
          }
        );
        console.log(response);
       
        this.modalapp = response.data.data;
        this.pesanapp = response.data.message
        this.modalapprule = rule;
        this.labeldur = range;
      }
      if (rule == "unmetered") {
        const response = await axios.get(
          "https://navigatorplus.sailink.id/api/v1/navplus/nta/app/" +
            data.id +
            "/" +
            data.nfa_id_2 +
            "/" +
            time,
          {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
            },
          }
        );
        this.pesanapp = response.data.message
        this.modalapp = response.data.data;
        this.modalapprule = rule;
        this.labeldur = range;
      }
      this.modalappid = data;
    },
    async sendModalSource(item, time, range) {
      const response = await axios.get(
        "https://navigatorplus.sailink.id/api/v1/navplus/nta/src/" +
          item.id +
          "/" +
          item.nfa_id +
          "/" +
          time,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      (this.srcData = response.data.data), (this.modalsrcid = item);
      this.labeldur = range;
    },
    async sendModalDst(item, time, range) {
      const response = await axios.get(
        "https://navigatorplus.sailink.id/api/v1/navplus/nta/dst/" +
          item.id +
          "/" +
          item.nfa_id +
          "/" +
          time,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      console.log(response.data.data);
      this.dstData = response.data.data;
      this.destid = item;
      this.labeldur = range;
    },
    async sendModalConv(item, time, range, inout) {
      const response = await axios.get(
        "https://navigatorplus.sailink.id/api/v1/navplus/nta/conv/" +
          item.id +
          "/" +
          item.nfa_id +
          "/" +
          time +
          "/" +
          inout,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );

      this.convData = response.data.data;
      this.convid = item;
      this.labeldur = range;
      this.time = time;
    },
  },


    computed:{
    ...mapGetters([
    "reportsData",
    "OffReports"
    ]),
  
    showingDatafrom(){
      if( this.totalFilter < 1 ){
        return 0
      }else{
       return 1
      }
        
    },
   
    showingDatato(){
      if( this.totalFilter < this.itemsPerPage ){
        return this.totalFilter
      }else{
       return this.itemsPerPage
      }
        
    },
  
  }
};
</script>

<style lang="scss">
@import "@/assets/scss/data.scss";
.v-dialog {
  box-shadow: none;
  border-radius:0;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td,
.v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
.v-data-table > .v-data-table__wrapper > table > thead > tr > td,
.v-data-table > .v-data-table__wrapper > table > thead > tr > th,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > td,
.v-data-table > .v-data-table__wrapper > table > tfoot > tr > th {
  padding-left: 0.4em;
  transition: height 0.2s cubic-bezier(0.4, 0, 0.6, 1);
}

.v-text-field.v-text-field--solo.v-input--dense > .v-input__control {
    min-height: 8px;
}


@media (min-width: 280px) and (max-width: 319px) {
  .mobileTable_reports{
    display:block
  }
  .desktopTable_reports{
    display:none
  }
}

/* Mobile Devices */
@media (min-width: 320px) and (max-width: 480px) {
  .mobileTable_reports{
    display:block
  }
  .desktopTable_reports{
    display:none
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .mobileTable_reports{
    display:none
  }
  .desktopTable_reports{
    display:block
  }
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .mobileTable_reports{
    display:none
  }
  .desktopTable_reports{
    display:block
  }
}

/* Desktops, large screens */
@media (min-width: 1025px) and (max-width: 1200px){
  .mobileTable_reports{
    display:none
  }
  .desktopTable_reports{
    display:block
  }
}

/* Extra large screens / TV */
@media screen and (min-width: 1201px) {
  .mobileTable_reports{
    display:none
  }
  .desktopTable_reports{
    display:block
  }


}
</style>
