import axios from "../../plugins/axios";

const getDefaultState = () => {
    return {
        callData:null
    }
       
}

const state = getDefaultState();

const getters = {
    callData: (state) => state.callData,
};

const actions = {
    async fetchCallData({commit}, data) {
    
        try{
            const response = await axios.get(
                "/api/v1/navplus/call/get/"+data.thuraya_number,
                {
                    headers: {
                        Authorization: "Bearer" + data.token,
                    },
                }
            );
            console.log(response);
            commit("setCallData",response.data.data)
        }catch(e){
            commit("setCallData",true)
            console.log(e);
        }
        
    },

    async storeCallData({commit}, data) {
    
        try{
            const response = await axios.get(
                "/api/v1/navplus/call/store/"+data.thuraya_number+"/"+data.duration+"/"+data.quota,
                {
                    headers: {
                        Authorization: "Bearer" + data.token,
                    },
                }
            );
            console.log('UDAH MASUK STORE')
            console.log(response);
            // commit("setCallData",response.data.data)
        }catch(e){
            // commit("setCallData",true)
            console.log(e);
        }
        
    },

    removeCallData({commit}){
        commit("setCallData",null)
    }

};

const mutations = {
    setCallData: (state, callData) => (state.callData = callData),
    resetState(state) {
        Object.assign(state, getDefaultState());
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
