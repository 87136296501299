<template>
    <v-card class="elevation-0 rounded-0 mt-0">
      <v-card
        id="title_map_exh"
        v-if="exhData"
        color="#fff"
        class="ma-3 elevation-0 rounded-0 mt-0 pb-3"
        style="border: solid 1px #e0e0e0"
      >
        <v-card> </v-card>
        <v-card class="elevation-0 rounded-0" color="#fff">
          <v-toolbar
            color="#F7F7F7"
            elevation="0"
            dense
            class="elevation-0 rounded-0"
          >
            <v-toolbar-title> Exhaust Report </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn
              class="mx-2 rounded-0 elevation-0"
              fab
              x-small
              color="#F7F7F7"
              @click="closeExhShow()"
            >
              <v-icon dark> mdi-close </v-icon>
            </v-btn>
          </v-toolbar>
          <v-toolbar
            height="120"
            style="text-align: left"
            color="#F7F7F7"
            class="elevation-0 rounded-0 hederrpm_mobile"
          >
            {{ exhData.vessel_number }}
          </v-toolbar>
          <v-toolbar
            style="text-align: left"
            color="#F7F7F7"
            class="elevation-0 rounded-0 hederrpm_desktop"
            dense
          >
            {{ exhData.vessel_number }}
          </v-toolbar>
  
          <v-divider></v-divider>
          <v-card
            color="#F7F7F7"
            style="border: solid 1px #e0e0e0"
            class="elevation-0 rounded-0 ma-3"
          >
            <v-card
              color="#F7F7F7"
              class="rounded-0 toolbar_rpmbobile pa-2"
              elevation="0"
            >
              <v-menu content-class="elevation-0 rounded-0" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="border: solid 1px #e0e0e0"
                    color="#fff"
                    class="elevation-0 rounded-0"
                    v-bind="attrs"
                    v-on="on"
                    small
                    width="45%"
                  >
                    <i class="bx bx-time"></i>
                    &nbsp; &nbsp;
                    <v-text v-if="exhData">{{ exhData.range.range }}</v-text>
                    <v-card-text v-if="!exhData">
                      <div class="snippet" data-title=".dot-pulse">
                        <div class="stage">
                          <div class="dot-pulse"></div>
                        </div>
                      </div>
                    </v-card-text>
                    &nbsp; &nbsp;
                    <i class="bx bxs-chevron-down"></i>
                  </v-btn>
                </template>
                <v-list style="border: solid 1px #e0e0e0">
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, 'today')"
                      >Today</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, '24')"
                      >Last 24 Hour</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, '7')"
                      >Last 7 Days</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, '30')"
                      >Last 30 Days</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, 'week')"
                      >This Week</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, 'month')"
                      >This Month</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, 'lmonth')"
                      >Last Month</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      plain
                      small
                      @click="(dateopen = true), (datepickerID = exhID)"
                      >Custom Range</v-btn
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
  
              <v-btn
                width="45%"
                @click="sendDataEXH(exhID, 'today')"
                class="mx-2 rounded-0 elevation-0 pa-0"
                fab
                dark
                x-small
                color="primary"
              >
                <v-icon small dark> mdi-refresh </v-icon>
              </v-btn>
            </v-card>
            <v-card
              color="#F7F7F7"
              class="rounded-0 toolbar_rpmbobile"
              elevation="0"
            >
              <v-chip
                x-small
                v-if="!exhData"
                style="border: solid 1px #e0e0e0"
                color="#ffff"
                class="rounded-0"
                label
                >- - -</v-chip
              >
              <v-chip
                x-small
                style="border: solid 1px #e0e0e0"
                color="#ffff"
                class="rounded-0 pa-0"
                v-if="exhData"
                label
                >{{ exhData.range.range_actual }}</v-chip
              >
            </v-card>
  
            <v-toolbar
              color="#F7F7F7"
              class="elevation-0 rounded-0 toolbar_rpmdesktop"
              dense
            >
              {{ exhData.range.range_actual }}
              <v-spacer></v-spacer>
              <v-menu content-class="elevation-0 rounded-0" offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    style="border: solid 1px #e0e0e0"
                    color="#fff"
                    class="elevation-0 rounded-0"
                    v-bind="attrs"
                    v-on="on"
                    small
                  >
                    <i class="bx bx-time"></i>
                    &nbsp; &nbsp;
                    <v-text v-if="exhData">{{ exhData.range.range }}</v-text>
                    <v-card-text v-if="!exhData">
                      <div class="snippet" data-title=".dot-pulse">
                        <div class="stage">
                          <div class="dot-pulse"></div>
                        </div>
                      </div>
                    </v-card-text>
                    &nbsp; &nbsp;
                    <i class="bx bxs-chevron-down"></i>
                  </v-btn>
                </template>
                <v-list style="border: solid 1px #e0e0e0">
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, 'today')"
                      >Today</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, '24')"
                      >Last 24 Hour</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, '7')"
                      >Last 7 Days</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, '30')"
                      >Last 30 Days</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, 'week')"
                      >This Week</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, 'month')"
                      >This Month</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn block plain small @click="sendDataEXH(exhID, 'lmonth')"
                      >Last Month</v-btn
                    >
                  </v-list-item>
                  <v-list-item>
                    <v-btn
                      block
                      plain
                      small
                      @click="(dateopen = true), (datepickerID = exhID)"
                      >Custom Range</v-btn
                    >
                  </v-list-item>
                </v-list>
              </v-menu>
              <v-btn
                @click="sendDataEXH(exhID, 'today')"
                class="mx-2 rounded-0 elevation-0"
                fab
                dark
                x-small
                color="primary"
              >
                <v-icon small dark> mdi-refresh </v-icon>
              </v-btn>
            </v-toolbar>
  
            <v-card
          v-if="exhData"
            style="border: solid 1px #e0e0e0; padding: 10px"
            class="elevation-0 rounded-0 ma-3"
     >
  
        
  
            <ExhChartVue
            height="300"
              v-if="exhData"
              v-bind:datachart="exhData.chart"
              v-bind:index="0"
              v-bind:title="'Port Exhaust 1 Report'"
              v-bind:time_Uptime="portTime_value"
            />

            <ExhChartVue
            height="300"
              v-if="exhData"
              v-bind:datachart="exhData.chart"
              v-bind:index="1"
              v-bind:title="'Starboard Exhaust 2 Report'"
              v-bind:time_Uptime="portTime_value"
            />
  
           
  
          
          </v-card>
  
             <v-card
              v-if="exhData"
              style="border: solid 1px #e0e0e0; padding: 10px"
              class="elevation-0 rounded-0 ma-3"
            >
              <l-map
                v-if="!exhMaptop"
                style="height: 38em; width: 100%"
                ref="mapempty"
                @fullscreenchange="triggerFullscreenEmpty"
                :zoom="4"
                :center="[-0.1292586012050707, 113.39020523630768]"
                :options="{
                  minZoom: 3,
                  zoomControl: false,
                  doubleClickZoom: true,
                  scrollWheelZoom: false,
                }"
              >
                <l-tile-layer
                  :url="url"
                  :attribution="attribution"
                ></l-tile-layer>
                <l-control-zoom position="bottomright"></l-control-zoom>
                <l-control-fullscreen
                  position="topright"
                  :options="{ title: { false: 'Go big!', true: 'Be regular' } }"
                />
              </l-map>
              <l-map
                v-if="exhMaptop"
                @fullscreenchange="triggerFullscreen"
                ref="map"
                :options="{
                  minZoom: 3,
                  zoomControl: false,
                  doubleClickZoom: true,
                  scrollWheelZoom: false,
                }"
                style="height: 38em; width: 100%"
                :zoom="8"
                :center="
                  exhMaptop.lat
                    ? [parseFloat(exhMaptop.lat), parseFloat(exhMaptop.lon)]
                    : [-0.1292586012050707, 113.39020523630768]
                "
              >
                <l-tile-layer
                  :url="url"
                  :attribution="attribution"
                ></l-tile-layer>
                <l-control-zoom position="bottomright"></l-control-zoom>
                <l-control-fullscreen
                  position="topright"
                  :options="{ title: { false: 'Go big!', true: 'Be regular' } }"
                />
                <v-rotated-marker
                  ref="myMarker"
                  v-if="exhMaptop.lat"
                  :lat-lng="[
                    parseFloat(exhMaptop.lat),
                    parseFloat(exhMaptop.lon),
                  ]"
                  :rotationAngle="exhMaptop.rotation"
                >
                  <l-icon
                    :icon-size="[40 / 4, 100 / 4]"
                    :icon-anchor="[40 / 4 / 2, 100 / 4 / 2]"
                    class-name="someExtraClass"
                  >
                    <svg
                      id="a"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 40 100"
                    >
                      <path
                        :fill="exhMaptop.fill"
                        stroke="#FFF"
                        stroke-width="10"
                        d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z"
                      />
                    </svg>
                  </l-icon>
                  <l-popup @ready="detailRPM(), draw_Circle()">
                    <table>
                      <tr>
                        <td><b>Latitude</b></td>
                        <td>:</td>
                        <td>
                          {{ exhMaptop.lat }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Longitude</b></td>
                        <td>:</td>
                        <td>{{ exhMaptop.lon }}</td>
                      </tr>
                      <tr>
                        <td><div style="height:1em"></div></td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><b>Heading</b></td>
                        <td>:</td>
                        <td>{{ exhMaptop.rotation + "&deg;" }}</td>
                      </tr>
                      <tr>
                        <td><b>Speed</b></td>
                        <td>:</td>
                        <td>
                          {{ exhOutput[0].speed + "&nbsp;knots" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Distance</b></td>
                        <td>:</td>
                        <td>{{ exhOutput[0].distance + " Nmi" }}</td>
                      </tr>
                    </table>
                    <br />
                    <table>
                      <tr>
                        <td></td>
                        <td></td>
                        <td class="pl-3"><b>(P)</b> Exhaust1</td>
                        <td class="pl-3"><b>(S)</b> Exhaust2</td>
                      </tr>
                      <tr>
                        <td><b>Current</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ exhOutput[0].current.split("/")[0] + " RPM" }}
                        </td>
                        <td class="pl-3">
                          {{ exhOutput[0].current.split("/")[1] + " RPM" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Average</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ exhOutput[0].avg.split("/")[0] + " RPM" }}
                        </td>
                        <td class="pl-3">
                          {{ exhOutput[0].avg.split("/")[1] + " RPM" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Min</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ exhOutput[0].min.split("/")[0] + " RPM" }}
                        </td>
                        <td class="pl-3">
                          {{ exhOutput[0].min.split("/")[1] + " RPM" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Max</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ exhOutput[0].max.split("/")[0] + " RPM" }}
                        </td>
                        <td class="pl-3">
                          {{ exhOutput[0].max.split("/")[1] + " RPM" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Uptime</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{
                            exhOutput[0].w_hour.split("/")[0] + " Minutes"
                          }}
                        </td>
                        <td class="pl-3">
                          {{
                            exhOutput[0].w_hour.split("/")[1] + " Minutes"
                          }}
                        </td>
                      </tr>
                    </table>
                    <br />
                    <table>
                      <tr>
                        <td><b>Interval</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ exhOutput[0].interval }}
                        </td>
                      </tr>
                    </table>
                    <br />
                    <table>
                      <tr>
                        <td><b>Updated</b></td>
                        <td class="pl-2">:</td>
                        <td class="pl-2">
                          {{ exhOutput[0].dateTime_local }}
                        </td>
                      </tr>
                    </table>
                  </l-popup>
                </v-rotated-marker>
                <l-moving-rotated-marker
                v-if="drivePath"
                  ref="driveMarker"
                  :lat-lng="driveLatLng"
                  :rotationAngle="driveRotationAngle"
                  :duration="3000"
                  :icon="icon"
                />
              </l-map>
            </v-card>
  
             <v-card
              style="border: solid 1px #e0e0e0; padding: 10px"
              class="elevation-0 rounded-0 ma-3"
            >
              <v-toolbar
                style="border: solid 1px #e0e0e0"
                elevation="0"
                class="pa-0"
                color="#F7F7F7"
                fixed
                dense
              >
                <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      style="border: solid 1px #e0e0e0"
                      small
                      class="rounded-0 pa-1 elevation-0 ma-0 mr-2"
                      color="#ffff"
                      v-bind="attrs"
                      v-on="on"
                    >
                      {{ kFormatter(itemsPerPageOutput)
                      }}<v-icon small style="margin-left: 0.2em"
                        >mdi-unfold-more-horizontal</v-icon
                      >
                    </v-btn>
                  </template>
                  <v-list style="border: solid 1px #e0e0e0">
                    <v-list-item @click="itemsPerPageOutput = 10"
                      ><v-list-item-title>10</v-list-item-title></v-list-item
                    >
                    <v-list-item @click="itemsPerPageOutput = 25"
                      ><v-list-item-title>25</v-list-item-title></v-list-item
                    >
                    <v-list-item @click="itemsPerPageOutput = 50"
                      ><v-list-item-title>50</v-list-item-title></v-list-item
                    >
                    <v-list-item @click="itemsPerPageOutput = 100"
                      ><v-list-item-title>100</v-list-item-title></v-list-item
                    >
                  </v-list>
                </v-menu>
  
                <v-btn
                  color="primary"
                  class="elevation-0 rounded-0 exportrpm_desktop"
                  @click="createCSV()"
                  small
                  ><v-icon small>mdi-file-move</v-icon> &nbsp;Export CSV</v-btn
                >
               
                <v-btn
                  color="primary"
                  class="elevation-0 rounded-0 exportrpm_mobile"
                  @click="createCSV()"
                  x-small
                  ><v-icon small>mdi-file-move</v-icon>CSV</v-btn
                >
                <v-btn   :href="'https://sailink.id/cetak/iot/pdf/'+ exhID +'/'+ exp_range" color="primary" dark  small style="color:#fff" class="elevation-0 rounded-0  ml-2 pdfthuraya_desktop" target="_blank">
         <v-icon x-small>mdi-file-move</v-icon> Export PDF
        </v-btn>
                
                <v-spacer style="width: 50%"></v-spacer>
                <v-text-field
                  style="border: solid 1px #e0e0e0"
                  width="40"
                  v-model="searchOutput"
                  hide-details
                  prepend-inner-icon="mdi-magnify"
                  placeholder="Search"
                  solo
                  clearable
                  class="elevation-0 rounded-0"
                ></v-text-field>
              </v-toolbar>
              <v-data-table
                style="border: solid 1px #e0e0e0"
                v-if="exhOutput"
                :headers="headers"
                :search="searchOutput"
                :items="exhOutput"
                hide-default-footer
                hide-default-header
                :page.sync="pageOutput"
                :items-per-page="itemsPerPageOutput"
                class="elevation-0 rounded-0 dataTable output_desktop"
                @page-count="pageCountOutput = $event"
                @pagination="intoFilter"
              >
                <template v-slot:header>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">#</th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Current<br />(&deg;C)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Average<br />(&deg;C)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">Min<br />(&deg;C)</th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">Max<br />(&deg;C)</th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Interval<br />(Minutes)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Uptime<br />(Minutes)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Latitude,<br />Longitude
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Heading<br />(Degree)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Speed<br />(Knots)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Distance<br />(Nmi)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    dateTime<br />(GMT+7)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">Detail</th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_"></th>
                </template>
                <template v-slot:item="row">
                  <tr class="pa-0 ma-0">
                    <td
                      width="2%"
                      class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center"
                    >
                      {{ row.item.index + 1 }}
                    </td>
                    <td
                      width="7%"
                      class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center"
                    >
                      <b>(P)</b> {{ row.item.current.split("/")[0] }}<br /><b
                        >(S)</b
                      >
                      {{ row.item.current.split("/")[1] }}
                    </td>
                    <td
                      width="7%"
                      class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center"
                    >
                      {{ row.item.avg.split("/")[0] }}<br />{{
                        row.item.avg.split("/")[1]
                      }}
                    </td>
                    <td
                      width="7%"
                      class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center"
                    >
                      {{ row.item.min.split("/")[0] }}<br />{{
                        row.item.min.split("/")[1]
                      }}
                    </td>
                    <td
                      width="7%"
                      class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center"
                    >
                      {{ row.item.max.split("/")[0] }}<br />{{
                        row.item.max.split("/")[1]
                      }}
                    </td>
                    <td
                      width="7%"
                      class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center"
                    >
                      {{ row.item.interval }}
                    </td>
                    <td
                      class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center"
                    >
                      {{ row.item.w_hour.split("/")[0] }}<br />{{
                        row.item.w_hour.split("/")[1]
                      }}
                    </td>
                    <td width="7%" class="pa-0 ma-0" style="text-align: center">
                      {{ row.item.latitude }},{{ row.item.longitude }}
                    </td>
                    <td
                      width="7%"
                      class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center"
                    >
                      {{ row.item.heading }}
                    </td>
                    <td
                      width="7%"
                      class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center"
                    >
                      {{ row.item.speed }}
                    </td>
                    <td
                      width="7%"
                      class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center"
                    >
                      {{ row.item.distance }}
                    </td>
                    <td width="7%" class="pa-0 ma-0" style="text-align: center">
                      {{ row.item.dateTime_local }}
                    </td>
                    <td width="5%" class="pa-0 ma-0" style="text-align: center">
                      -
                    </td>
                    <td
                      width="7%"
                      style="white-space: nowrap; text-align: center"
                    >
                      <v-btn
                        v-if="row.item.showMap == true"
                        fab
                        dark
                        x-small
                        color="primary"
                        class="mx-2 rounded-0 elevation-0"
                        @click="
                          detailRPMMAP(
                            row.item.latitude,
                            row.item.longitude,
                            row.item
                          )
                        "
                      >
                        <v-icon small dark> mdi-map </v-icon>
                      </v-btn>
                      <v-btn
                        v-else
                        fab
                        dark
                        x-small
                        color="#DAE7ED"
                        class="mx-2 rounded-0 elevation-0"
                        style="cursor: not-allowed"
                      >
                        <v-icon small dark> mdi-map </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-data-table
                style="border: solid 1px #e0e0e0"
                v-if="exhOutput"
                :headers="headers"
                :search="searchOutput"
                :items="exhOutput"
                hide-default-footer
                hide-default-header
                :page.sync="pageOutput"
                :items-per-page="itemsPerPageOutput"
                class="elevation-0 rounded-0 output_mobile"
                @page-count="pageCountOutput = $event"
                @pagination="intoFilter"
                ><template v-slot:item="row">
                  <tr style="margin-top: 0.4em; margin-bottom: 0.4em">
                    <td style="border-bottom: none; text-align: center">
                      <v-card
                        style="border: solid 1px #e0e0e0"
                        class="elevation-0 rounded-0 ma-1"
                        max-width="87vw"
                      >
                        <v-toolbar dense class="elevation-0 rounded-0">
                          <v-toolbar-subtitle>{{
                            row.item.index + 1
                          }}</v-toolbar-subtitle>
                          <v-spacer></v-spacer>
                          <v-btn
                            v-if="row.item.showMap == true"
                            fab
                            dark
                            x-small
                            color="primary"
                            class="mx-2 rounded-0 elevation-0"
                            @click="
                              detailRPMMAP(
                                row.item.latitude,
                                row.item.longitude,
                                row.item
                              )
                            "
                          >
                            <v-icon small dark> mdi-map </v-icon>
                          </v-btn>
                          <v-btn
                            v-else
                            fab
                            dark
                            x-small
                            color="#DAE7ED"
                            class="mx-2 rounded-0 elevation-0"
                            style="cursor: not-allowed"
                          >
                            <v-icon small dark> mdi-map </v-icon>
                          </v-btn>
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-card-text>
                          <table>
                            <tr>
                              <td>Current (RPM)</td>
                              <td>:</td>
                              <td>
                                <b>(P)</b> {{ row.item.current.split("/")[0]
                                }}<br /><b>(S)</b>
                                {{ row.item.current.split("/")[1] }}
                              </td>
                            </tr>
                            <tr>
                              <td>Average (RPM)</td>
                              <td>:</td>
                              <td>
                                {{ row.item.avg.split("/")[0] }}<br />{{
                                  row.item.avg.split("/")[1]
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Min (RPM)</td>
                              <td>:</td>
                              <td>
                                {{ row.item.min.split("/")[0] }}<br />{{
                                  row.item.min.split("/")[1]
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Max (RPM)</td>
                              <td>:</td>
                              <td>
                                {{ row.item.max.split("/")[0] }}<br />{{
                                  row.item.max.split("/")[1]
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Interval (Minutes)</td>
                              <td>:</td>
                              <td>{{ row.item.interval }}</td>
                            </tr>
                            <tr>
                              <td>Uptime (Minutes)</td>
                              <td>:</td>
                              <td>{{ row.item.uptime }}</td>
                            </tr>
                            <tr>
                              <td>Lat,<br />lng</td>
                              <td>:</td>
                              <td>
                                {{ row.item.latitude }},<br />{{
                                  row.item.longitude
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Heading (Degree)</td>
                              <td>:</td>
                              <td>{{ row.item.heading }}</td>
                            </tr>
                            <tr>
                              <td>Speed (Knots)</td>
                              <td>:</td>
                              <td>{{ row.item.speed }}</td>
                            </tr>
                            <tr>
                              <td>Distance (Nmi)</td>
                              <td>:</td>
                              <td>{{ row.item.distance }}</td>
                            </tr>
                            <tr>
                              <td>dateTime (GMT+7)</td>
                              <td>:</td>
                              <td>{{ row.item.dateTime_local }}</td>
                            </tr>
                            <tr>
                              <td>Detail</td>
                              <td>:</td>
                              <td>-</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
                <p class="mt-3" style="font-size: 0.8em">
                  Showing {{ showingDatafromOutput }} to
                  {{ showingDatatoOutput }} of {{ totalFilterOutput }} entries
                </p>
                <v-spacer></v-spacer>
                <v-pagination
                  color="primary"
                  v-model="pageOutput"
                  :length="pageCountOutput"
                  :total-visible="7"
                ></v-pagination>
              </v-toolbar>
            </v-card>
          </v-card>
        </v-card>
      </v-card>
      <!--Modal Map-->
       <v-dialog v-model="dmap" min-width="90vw" persistent>
        <v-card color="#ffff" class="elevation-0 rounded-0 pb-1">
          <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0" dense>
            <v-col class="header_modal">
              <v-row
                ><v-toolbar-title
                  >RPM MAP | {{ exhData.vessel_number }}
                </v-toolbar-title></v-row
              >
            </v-col>
  
            <v-spacer></v-spacer>
  
            <v-btn small class="elevation-0 rounded-0" icon @click="dmap = null">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card
            class="elevation-0 rounded-0 ma-2 mb-1 pa-2"
            style="border: solid 1px #e0e0e0"
          >
            <v-responsive min-width="100%" min-height="60%">
              <v-lazy>
                <client-only>
                  <div id="map">
                    <l-map
                      v-if="dmap"
                      @fullscreenchange="triggerFullscreenModal"
                      ref="mapmodal"
                      style="height: 40em"
                      :zoom="8"
                      :center="[parseFloat(dmaplat), parseFloat(dmaplon)]"
                      :options="{
                        minZoom: 3,
                        zoomControl: false,
                        doubleClickZoom: true,
                        scrollWheelZoom: false,
                      }"
                    >
                      <l-tile-layer
                        :url="url"
                        :attribution="attribution"
                      ></l-tile-layer>
                      <l-control-zoom position="bottomright"></l-control-zoom>
                      <l-control-fullscreen
                        position="topright"
                        :options="{
                          title: { false: 'Go big!', true: 'Be regular' },
                        }"
                      />
                      <v-rotated-marker
                        ref="myMarkerMap"
                        :lat-lng="[parseFloat(dmaplat), parseFloat(dmaplon)]"
                        :rotationAngle="dmapdata.rotation"
                      >
                        <l-icon
                          :icon-size="[40 / 4, 100 / 4]"
                          :icon-anchor="[40 / 4 / 2, 100 / 4 / 2]"
                          class-name="someExtraClass"
                        >
                          <svg
                            id="a"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 40 100"
                          >
                            <path
                              :fill="exhMaptop.fill"
                              stroke="#FFF"
                              stroke-width="10"
                              d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z"
                            />
                          </svg>
                        </l-icon>
                        <l-popup @ready="logReady()">
                          <table>
                            <tr>
                              <td><b>Latitude</b></td>
                              <td>:</td>
                              <td>
                                {{ dmaplat }}
                              </td>
                            </tr>
                            <tr>
                              <td><b>Longitude</b></td>
                              <td>:</td>
                              <td>{{ dmaplon }}</td>
                            </tr>
                            <tr>
                              <td><div style="height:1em"></div></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr>
                              <td><b>Heading</b></td>
                              <td>:</td>
                              <td>{{ dmapdata.rotation + "&deg;" }}</td>
                            </tr>
                            <tr>
                              <td><b>Speed</b></td>
                              <td>:</td>
                              <td>
                                {{ dmapdata.speed + "&nbsp;knots" }}
                              </td>
                            </tr>
                            <tr>
                              <td><b>Distance</b></td>
                              <td>:</td>
                              <td>{{ dmapdata.distance + " Nmi" }}</td>
                            </tr>
                          </table>
                          <br />
                          <table>
                            <tr>
                              <td></td>
                              <td></td>
                              <td class="pl-3"><b>(P)</b> Exhaust1</td>
                              <td class="pl-3"><b>(S)</b> Exhaust2</td>
                            </tr>
                            <tr>
                              <td><b>Current</b></td>
                              <td class="pl-3">:</td>
                              <td class="pl-3">
                                {{ dmapdata.current.split("/")[0] + " RPM" }}
                              </td>
                              <td class="pl-3">
                                {{ dmapdata.current.split("/")[1] + " RPM" }}
                              </td>
                            </tr>
                            <tr>
                              <td><b>Average</b></td>
                              <td class="pl-3">:</td>
                              <td class="pl-3">{{ dmapdata.avg.split("/")[0] + " RPM" }}</td>
                              <td class="pl-3">{{ dmapdata.avg.split("/")[1] + " RPM" }}</td>
                            </tr>
                            <tr>
                              <td><b>Min</b></td>
                              <td class="pl-3">:</td>
                              <td class="pl-3">{{ dmapdata.min.split("/")[0] + " RPM" }}</td>
                              <td class="pl-3">{{ dmapdata.min.split("/")[1] + " RPM" }}</td>
                            </tr>
                            <tr>
                              <td><b>Max</b></td>
                              <td class="pl-3">:</td>
                              <td class="pl-3">
                                {{ dmapdata.max.split("/")[0] + " RPM" }}
                              </td>
                              <td class="pl-3">
                                {{ dmapdata.max.split("/")[1] + " RPM" }}
                              </td>
                            </tr>
                            <tr>
                              <td><b>Uptime</b></td>
                              <td class="pl-3">:</td>
                              <td class="pl-3">
                                {{ dmapdata.w_hour.split("/")[0] + " Minutes" }}
                              </td>
                              <td class="pl-3">
                                {{ dmapdata.w_hour.split("/")[1] + " Minutes" }}
                              </td>
                            </tr>
                          </table>
                          <br />
                          <table>
                            <tr>
                              <td><b>Interval</b></td>
                              <td class="pl-3">:</td>
                              <td class="pl-3">
                                {{ dmapdata.interval }}
                              </td>
                            </tr>
                          </table>
                          <br />
                          <table>
                            <tr>
                              <td><b>Updated</b></td>
                              <td class="pl-2">:</td>
                              <td class="pl-2">
                                {{ dmapdata.dateTime_local }}
                              </td>
                            </tr>
                          </table>
                        </l-popup>
                      </v-rotated-marker>
                    </l-map>
                  </div>
                </client-only>
              </v-lazy>
            </v-responsive>
          </v-card>
        </v-card>
      </v-dialog>
      <!--Modal Map-->
  
       <v-dialog
        ref="dialog"
        v-model="dateopen"
        :return-value.sync="dates"
        class="elevation-0 rounded-0"
        persistent
        width="290px"
      >
        <v-date-picker
          color="primary"
          class="elevation-0 rounded-0"
          v-model="dates"
          range
          scrollable
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dateopen = false"> Cancel </v-btn>
          <v-btn text color="primary" @click="customRange()"> OK </v-btn>
        </v-date-picker>
      </v-dialog>
    </v-card>
  </template>
  
  <script>
  import $ from "jquery";
  import RpmChartVue from "../charts/RpmChart.vue";
  import RpmChartLive from "../charts/RpmChartLive.vue";
  import { mapSettingsShow } from "@/constants/mapSettings";
  import { mapGetters, mapActions } from "vuex";
  import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
  import { LMap, LTileLayer, LIcon, LPopup, LControlZoom } from "vue2-leaflet";
  import jsPDF from "jspdf";
  import autoTable from "jspdf-autotable";
  import LControlFullscreen from "vue2-leaflet-fullscreen";
  import LMovingRotatedMarker from "vue2-leaflet-rotatedmarker";
  import ExhChartVue from "../charts/ExhChart.vue";
  
  const iconUrl =
    "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDIuODggMy40NiI+PGRlZnM+PHN0eWxlPi5ke2ZpbGw6IzAwMDBGRjtzdHJva2U6I2ZmZjtzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6LjJweDt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9ImEiLz48ZyBpZD0iYiI+PGcgaWQ9ImMiPjxwYXRoIGNsYXNzPSJkIiBkPSJNMi42OSwzLjI2bC0uOTYtLjQ1Yy0uMTktLjA5LS40LS4wOS0uNTksMGwtLjk2LC40NUwxLjQ0LC4yNmwxLjI1LDNaIi8+PC9nPjwvZz48L3N2Zz4=";
  
  export default {
    props: ["exhData", "exhOutput", "rpmshowclick"],
  
    components: {
      ExhChartVue,
      LMap,
      LTileLayer,
      LIcon,
      LPopup,
      "v-rotated-marker": Vue2LeafletRotatedMarker,
      LControlZoom,
      LControlFullscreen,
      LMovingRotatedMarker,
      RpmChartVue
    },
  
    data() {
      return {
        exp_range:'today',
       
        countLength: 0,
        IntervalMoving: null,
        IntervalLive: null,
        fetchInterval: null,
        dataTableTimeout: null,
        timeoutInterval: null,
        icon: L.icon({
          iconUrl: iconUrl,
          iconSize: [15, 20],
          iconAnchor: [7.5, 10],
          popupAnchor: [0, -25],
        }),
        drivePath: null,
        driveLatLng: null,
        driveRotationAngle: null,
        driveMarker: null,
        totalFilterOutput: null,
        time_Uptime: "22",
        dateopen: false,
        dates: [],
        datepickerID: null,
        loadernavplus: require("@/assets/img/load100new.gif"),
        option1: { name: "1" },
        option2: { name: "2" },
        dmap: null,
        dmaplat: null,
        dmaplon: null,
        dmapdata: [],
        headers: [
          {
            text: "#",
            align: "start",
            sortable: false,
            value: "id",
          },
          { text: "Current (RPM)", value: "current" },
          { text: "Average (RPM)", value: "avg" },
          { text: "Min (RPM)", value: "min" },
          { text: "Max (RPM)", value: "max" },
          { text: "Interval (Minutes)", value: "interval" },
          { text: "Uptime (Minutes)", value: "w_hour" },
          { text: "Latitude, Longtitude", value: "latitude" },
          { text: "Heading (Degree)", value: "heading" },
          { text: "Speed (Knots)", value: "speed" },
          { text: "Distance (Nmi)", value: "distance" },
          { text: "dateTime (GMT+7)", value: "dateTime_local" },
          {
            text: "",
            align: "start",
            sortable: false,
            value: "",
          },
        ],
        url: "https://maps.skyreach.id/hot/{z}/{x}/{y}.png",
        attribution:
          '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
        zoom: 5,
        itemsPerPageOutput: 100,
        pageOutput: 1,
        pageCountOutput: 0,
        searchOutput: "",
      };
    },
    mounted() {
      //  this.$nextTick(() => {
      //   this.detailRPM()
      //  })
    // this.initialdata_movingfirst();
      var mama = this;
      if (!mama.exh_executed) {
        console.log("jalan title map");
        mama.addexh_executed(true);
        $("html, body").animate(
          {
            scrollTop: $("#title_map_exh").offset().top - 109,
          },
          800
        );
      } else {
        console.log("Muncul"), console.log(mama.exh_executed);
      }
    },
    methods: {
      ...mapActions([
        "fetchExhaustShow",
        "fetchIotShow",
        "addexhOutput",
        "fetchRpmLive",
        "fetchIot",
        "fetchRpm",
        "removeIotShow",
        "removeSearchIOT",
        "removeExhShow",
        "set_port_time",
        "addexh_executed",
        "fetchRpmLiveTable",
      ]),
  
      initialdata_moving() {
        var mother = this;
  
        mother.drivePath = mother.exhData.tracking.slice();
        mother.driveLatLng = L.latLng([
          mother.drivePath[0].lat,
          mother.drivePath[0].lng,
        ]);
        mother.driveRotationAngle = mother.drivePath[0].heading;
      },
      
        //moving marker
     newAnimation(){
      this.drivePath=null;
      clearInterval(this.IntervalMoving);
      this.initialdata_moving()
    },
      
  
      movingStart() {
        var mother = this;
        this.IntervalMoving = setInterval(function () {
          mother.simulate();
        }, 350);
      },
  
      simulate() {
        this.countLength = this.countLength + 1;
        // console.log(this.countLength)
        // console.log(this.drivePath)
        // console.log(this.exhData.tracking.length)
          if (!this.drivePath.length) {
            this.$refs.driveMarker.mapObject.setLatLng(
              L.latLng([this.drivePath[0].lat, this.drivePath[0].lng])
            );
            this.drivePath = this.drivePath.slice();
            return;
          }
          let point = this.drivePath.shift();
          this.driveLatLng = L.latLng([point.lat, point.lng]);
          this.driveRotationAngle = point.heading;
          // console.log(this.driveRotationAngle)
          
          if (this.drivePath.length==0 || !this.drivePath) {
            clearInterval(this.IntervalMoving);
            this.drivePath=null;
          }
          
        
      },
  
      logReady() {
        this.$refs.myMarkerMap.mapObject.fire("click");
      },
  
      triggerFullscreen() {
        if (this.$refs["map"].mapObject._isFullscreen != true) {
          this.$refs.map.mapObject.scrollWheelZoom.disable();
        } else {
          this.$refs.map.mapObject.scrollWheelZoom.enable();
        }
      },
  
      triggerFullscreenModal() {
        if (this.$refs["mapmodal"].mapObject._isFullscreen != true) {
          this.$refs.mapmodal.mapObject.scrollWheelZoom.disable();
        } else {
          this.$refs.mapmodal.mapObject.scrollWheelZoom.enable();
        }
      },
  
      isLiveFunc() {
        console.log("RANGE YANG DI PRINT");
        console.log(this.exhData.range.range);
        const data = {
          latestDateTime: this.dateTimeLive,
          dev_id: this.exhData.dev_id,
          token: localStorage.getItem("token"),
        };
  
        this.IntervalLive = setInterval(() => {
          this.fetchRpmLive(data);
          this.pushChartLive();
        }, 1000);
  
        this.timeoutInterval = this.rpmInterval*60*1000;
  
        if (this.exhData.range.range.toLowerCase() == "today") {
          this.dataTableTimeout = setTimeout(() => {
            this.rpmUpdateTable();
          }, this.timeoutInterval);
        }
      },
  
      rpmUpdateTable() {
        this.timeoutInterval = this.rpmInterval*60*1000;
  
        const data_dt = {
          latestDateTimeDT: this.dateTimeTable,
          dev_id: this.exhData.dev_id,
          token: localStorage.getItem("token"),
        };
  
        this.fetchRpmLiveTable(data_dt);
        this.pushDTLive();
  
        if (this.exhData.range.range.toLowerCase() == "today") {
          this.dataTableTimeout = setTimeout(() => {
            this.rpmUpdateTable();
          }, this.timeoutInterval);
        }
      },
  
      pushDTLive() {
        var mama = this;
        if (this.rpmUpdate && this.rpmUpdate.update == true) {
          var dataOutput = {
            id: this.rpmUpdate.id,
            rawData:
              '{"data":"RPM\\/RPI-AT 10003 1102\\/1200;1105\\/1205;1100\\/1200;1110\\/1210;5;1110\\/1210;20230310073620;10120;5\\/5 2023-03-20 10:47:22 0529.6658 S 10518.5044 E"}',
            dev_id: this.rpmUpdate.dev_id,
            content: this.rpmUpdate.content,
            current: this.rpmUpdate.current,
            avg: this.rpmUpdate.avg,
            min: this.rpmUpdate.min,
            max: this.rpmUpdate.max,
            interval: this.rpmUpdate.interval,
            max_all: this.rpmUpdate.max_all,
            up_since: this.rpmUpdate.up_since,
            uptime: this.rpmUpdate.uptime,
            w_hour: this.rpmUpdate.w_hour,
            latitude: this.rpmUpdate.latitude,
            longitude: this.rpmUpdate.longitude,
            heading: this.rpmUpdate.heading,
            distance: this.rpmUpdate.distance,
            speed: this.rpmUpdate.speed,
            dateTime: this.rpmUpdate.dateTime,
            dateTime_local: this.rpmUpdate.dateTime_local,
            created_at: this.rpmUpdate.created_at,
            updated_at: this.rpmUpdate.updated_at,
            showMap: this.rpmUpdate.showMap,
            index: 0,
          };
  
          this.exhOutput.unshift(dataOutput);
          this.draw_Circle();
          this.exhData.chart.label.push(this.rpmUpdate.chart.dateTime_label);
  
          for (var i = 0; i < this.exhData.chart.datasets[0].length; i++) {
            if (this.exhData.chart.datasets[0][i]["label"] == "Current") {
              this.exhData.chart.datasets[0][i]["data"].push(
                mama.rpmUpdate.chart.current[0]
              );
            }
  
            if (this.exhData.chart.datasets[0][i]["label"] == "Max") {
              this.exhData.chart.datasets[0][i]["data"].push(
                mama.rpmUpdate.chart.max[0]
              );
            }
  
            if (this.exhData.chart.datasets[0][i]["label"] == "Min") {
              this.exhData.chart.datasets[0][i]["data"].push(
                mama.rpmUpdate.chart.min[0]
              );
            }
  
            if (this.exhData.chart.datasets[0][i]["label"] == "Average") {
              this.exhData.chart.datasets[0][i]["data"].push(
                mama.rpmUpdate.chart.avg[0]
              );
            }
          }
  
          for (var i = 0; i < this.exhData.chart.datasets[1].length; i++) {
            if (this.exhData.chart.datasets[1][i]["label"] == "Current") {
              this.exhData.chart.datasets[1][i]["data"].push(
                mama.rpmUpdate.chart.current[1]
              );
            }
  
            if (this.exhData.chart.datasets[1][i]["label"] == "Max") {
              this.exhData.chart.datasets[1][i]["data"].push(
                mama.rpmUpdate.chart.max[1]
              );
            }
  
            if (this.exhData.chart.datasets[1][i]["label"] == "Min") {
              this.exhData.chart.datasets[1][i]["data"].push(
                mama.rpmUpdate.chart.min[1]
              );
            }
  
            if (this.exhData.chart.datasets[1][i]["label"] == "Average") {
              this.exhData.chart.datasets[1][i]["data"].push(
                mama.rpmUpdate.chart.avg[1]
              );
            }
          }
  
          this.addexhOutput(this.exhOutput);
          console.log(this.exhData.chart.datasets[0][0]["data"]);
        }
      },
  
      pushChartLive() {
        // console.log('Didalam LIVE CHART PUSH')
        // console.log(this.liveChart)
        // console.log(this.chartLiveData_)
        //  console.log('DIBAWAH ADALAH DATASETS 0')
        // console.log(this.chartLiveData_['datasets'][0][0]['data'])
  
        var data1 = Math.random();
        var data2 = Math.random();
        if (this.liveChart && this.liveChart.update == true) {
          this.chartLiveData_["label"].push(this.liveChart.dateTime);
          this.chartLiveData_["datasets"][0][0]["data"].push(
            this.liveChart.current[0]
          );
          this.chartLiveData_["datasets"][1][0]["data"].push(
            this.liveChart.current[1]
          );
        }
  
        // this.chartliveData
      },
  
      triggerFullscreenEmpty() {
        if (this.$refs["mapempty"].mapObject._isFullscreen != true) {
          this.$refs.mapempty.mapObject.scrollWheelZoom.disable();
        } else {
          this.$refs.mapempty.mapObject.scrollWheelZoom.enable();
        }
      },
  
      intoFilter(pagination) {
        this.totalFilterOutput = pagination.itemsLength;
      },
  
      draw_Circle() {
        var myMap = this.$refs.map.mapObject;
        console.log(this.exhOutput);
  
        if (this.isRpmLive) {
          this.isLiveFunc();
        } else {
          console.log("rpm biasa");
        }
  
        var data = [];
        for (var i in this.exhOutput) {
          if (
            (this.exhOutput[i].latitude != null || "-") &&
            (this.exhOutput[i].longitude != null || "-")
          ) {
            data.push({
              lat: this.exhOutput[i].latitude,
              lon: this.exhOutput[i].longitude,
              heading: this.exhOutput[i].heading,
              speed: this.exhOutput[i].speed,
              distance: this.exhOutput[i].distance,
              current: this.exhOutput[i].current,
              avg: this.exhOutput[i].avg,
              min: this.exhOutput[i].min,
              max: this.exhOutput[i].max,
              w_hour: this.exhOutput[i].w_hour,
              interval: this.exhOutput[i].interval,
              dateTime_local: this.exhOutput[i].dateTime_local,
            });
          }
        }
  
        console.log(data);
  
        var latLong = data;
        var pointList = data;
        var mama = this;
        var firstpolyline = new L.Polyline(pointList, {
          color: "#FEE800",
          weight: 4,
          opacity: 1,
          smoothFactor: 1,
        });
        firstpolyline.addTo(myMap);
        // Here's where you iterate over the array of coordinate objects.
        latLong.forEach(function (coord) {
          if (
            coord.lat != mama.exhMaptop.lat &&
            coord.lng != mama.exhMaptop.lon
          ) {
            var circle = L.circle(coord, {
              color: "#1DC01D",
              fillColor: "#1DC01D",
              fillOpacity: 0.5,
              radius: 1800,
            })
              .addTo(myMap)
              .bindPopup(
                "<table>" +
                  "<tr>" +
                  "<td><b>Latitude</b></td><td>:</td>" +
                  "<td>" +
                  coord.lat +
                  "</td>" +
                  "</tr>" +
                  "<tr>" +
                  "<td><b>Longitude</b></td>" +
                  "<td>:</td>" +
                  "<td>" +
                  coord.lon +
                  "</td>" +
                  "</tr>" +
                  "<tr>" +
                  "<td><b>Heading</b></td>" +
                  "<td>:</td>" +
                  "<td>" +
                  coord.heading +
                  "</td>" +
                  "</tr>" +
                  "<tr>" +
                  "<td><b>Speed</b></td>" +
                  "<td>:</td>" +
                  "<td>" +
                  coord.speed +
                  "</td>" +
                  "</tr>" +
                  "<tr>" +
                  " <td><b>Distance</b></td>" +
                  "<td>:</td>" +
                  "<td>" +
                  coord.distance +
                  " Nmi</td>" +
                  "</tr>" +
                  "</table>" +
                  "<br />" +
                  "<table>" +
                  "<tr>" +
                  "<td></td>" +
                  "<td></td>" +
                  "<td><b>(P)</b> RPM1</td>" +
                  "<td><b>(S)</b> RPM2</td>" +
                  "</tr>" +
                  "<tr>" +
                  "<td><b>Current</b></td>" +
                  "<td>:</td>" +
                  "<td>" +
                  coord.current.split("/")[0] +
                  " RPM</td>" +
                  "<td>" +
                  coord.current.split("/")[1] +
                  " RPM</td>" +
                  "</tr>" +
                  "<tr>" +
                  "<td><b>Average</b></td>" +
                  "<td>:</td>" +
                  "<td>" +
                  coord.avg.split("/")[0] +
                  " RPM</td>" +
                  "<td>" +
                  coord.avg.split("/")[1] +
                  " RPM</td>" +
                  "</tr>" +
                  "<tr>" +
                  "<td><b>Min</b></td>" +
                  "<td>:</td>" +
                  "<td>" +
                  coord.min.split("/")[0] +
                  " RPM</td>" +
                  "<td>" +
                  coord.min.split("/")[1] +
                  " RPM</td>" +
                  "</tr>" +
                  "<tr>" +
                  "<td><b>Max</b></td>" +
                  "<td>:</td>" +
                  "<td>" +
                  coord.max.split("/")[0] +
                  " RPM" +
                  "</td>" +
                  "<td>" +
                  +coord.max.split("/")[1] +
                  " RPM" +
                  "</td>" +
                  "</tr>" +
                  "<tr>" +
                  " <td><b>Uptime</b></td>" +
                  "<td>:</td>" +
                  "<td>" +
                  +coord.w_hour.split("/")[0] +
                  " Minutes</td>" +
                  "<td>" +
                  coord.w_hour.split("/")[1] +
                  " Minutes</td>" +
                  "</tr>" +
                  "</table>" +
                  "<br />" +
                  "<table>" +
                  "<tr>" +
                  "<td><b>Interval</b></td>" +
                  "<td>:</td>" +
                  "<td>" +
                  coord.interval +
                  "</td>" +
                  "</tr>" +
                  "</table>" +
                  "<br />" +
                  "<table>" +
                  "<tr>" +
                  "<td><b>Updated</b></td>" +
                  "<td>:</td>" +
                  "<td>" +
                  coord.dateTime_local +
                  "</td>" +
                  "</tr>" +
                  "</table>"
              );
          }
        });
        var mom = this
      this.newAnimation()
      setTimeout(
    function() {
      mom.movingStart()
    }, 1000);
      },
  
      closeExhShow() {
        
        // clearInterval(this.IntervalLive);
        // clearInterval(this.IntervalMoving);
        // clearTimeout(this.dataTableTimeout);
        
  
        $("html, body").animate(
          {
            scrollTop: $("#top_iot").offset().top - 140,
          },
          800
        );
        this.removeExhShow()
        // this.removeSearchIOT();
        this.addexh_executed(false);
      },
  
      kFormatter(num) {
        return Math.abs(num) > 999
          ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
          : Math.sign(num) * Math.abs(num);
      },
  
      customRange() {
        this.dateopen = false;
        var range_date = this.dates.join(" ");
        console.log(range_date);
        this.sendDataEXH(this.datepickerID, range_date);
      },
  
      sendDataEXH(id, range) {
        this.exp_range=range
        console.log("JALANIN INI rpm nya");
        this.removeExhShow();
        console.log(id);
        const data = {
          id: id,
          range: range,
          token: localStorage.getItem("token"),
        };
        this.fetchExhaustShow(data);
        // var mother = this;
        // var intervalSeconds = 0.5;
        // this.fetchInterval = setInterval(function () {
        //   mother.initialdata_moving();
        // }, intervalSeconds);
      },
  
      createPDF() {
        // let pdfName = parts[0] + "_" + parts[1] + "_Tracking_Reports";
        let pdfName = "test";
        var doc = new jsPDF({
          orientation: "l",
          unit: "mm",
          format: "a4",
          putOnlyUsedFonts: true,
        });
  
        doc.setProperties({
          title: pdfName,
          subject: "Its a Subject",
          author: "Me!",
          creator: "My Company Name",
        });
  
        var output = this.exhOutput;
  
        let info = [];
        output.forEach((element) => {
          info.push([
            [element.index + 1],
            [element.current],
            [element.avg],
            [element.speed],
            [element.min],
            [element.max],
            [element.w_hour],
            [element.latitude],
            [element.longitude],
            [element.heading],
            [element.speed],
            [element.distance],
            [element.dateTime_Local],
          ]);
        });
  
        console.log(info);
        doc.text("RPM Report", 15, 5);
        doc.text(this.exhData.vessel_number, 15, 10, { fontSize: 9 });
  
        autoTable(doc, {
          head: [
            [
              "#",
              "Current (RPM)",
              "Average (RPM)",
              "Min (RPM)",
              "Max (RPM)",
              "Interval (Minutes)",
              "Uptime (Minutes)",
              "Latitude, Longitude",
              "Heading (Degree)",
              "Speed (Knots)",
              "Distance (Nmi)",
              "dateTime(GMT+7)",
            ],
          ],
          body: info,
          theme: "striped",
        });
  
        doc.save(pdfName + ".pdf");
        // doc.output("dataurlnewwindow");
      },
  
      createCSV() {
        // console.log(this.showmaptable.vessel_pt);
        // var parts = this.showmaptable.vessel_pt.split(" ");
        // console.log(parts);
  
        // let pdfName = parts[0] + "_" + parts[1] + "_Tracking_Reports";
  
        let csvName = this.exhData.vessel_number;
  
        var output = this.exhOutput;
        let data = [];
  
        output.forEach((element) => {
          data.push([
            [element.index + 1],
            [element.current],
            [element.avg],
            [element.speed],
            [element.min],
            [element.max],
            [element.w_hour],
            [element.latitude],
            [element.longitude],
            [element.heading],
            [element.speed],
            [element.distance],
            [element.dateTime_Local],
          ]);
        });
  
        let csvContent =
          "data:text/csv;charset=utf-8," +
          data.map((e) => e.join(",")).join("\n");
  
        var encodedUri = encodeURI(csvContent);
        var link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", csvName + ".csv");
        document.body.appendChild(link);
        link.click();
      },
  
      detailRPM() {
        if (this.$refs.myMarker) {
          this.$refs.myMarker.mapObject.fire("click");
        } else {
          console.log("No Marker");
        }
      },
  
      detailRPMMAP(lat, lon, data) {
        console.log("Open Popup Map");
        this.dmap = true;
        this.dmaplat = lat;
        this.dmaplon = lon;
        console.log(data);
        this.dmapdata = {
          rotation: data.heading,
          speed: data.speed,
          distance: data.distance,
          current: data.current,
          avg: data.avg,
          min: data.min,
          max: data.max,
          w_hour: data.w_hour,
          interval: data.interval,
          dateTime_local: data.dateTime_local,
        };
        console.log("Latitude,Longitude Detail Map");
        console.log(this.dmaplat);
        console.log(this.dmaplon);
  
        //     this.$nextTick(() => {
        //       this.$refs.myMarkerMap.mapObject.fire('click');
        //  })
      },
    },
    computed: {
      ...mapGetters([
        "exhData",
        "exhShow",
        "exhOutput",
        "exhID",
        "exh_executed",
        "exhMaptop"
        
      ]),
      mapConfig() {
        return {
          ...mapSettingsShow,
          center: this.mapCenter,
        };
      },
  
      showingDatafromOutput() {
        if (this.totalFilterOutput < 1) {
          return 0;
        } else {
          return 1;
        }
      },
  
      showingDatatoOutput() {
        if (this.totalFilterOutput < this.itemsPerPageOutput) {
          return this.totalFilterOutput;
        } else {
          return this.itemsPerPageOutput;
        }
      },
  
      portTime_value() {
        var port = this.exhOutput;
  
        console.log("WORKING HOUR");
        let port_minutes = [];
        port.forEach((element) => {
          port_minutes.push([[element.w_hour.split("/")[0]]]);
        });
        console.log(port_minutes);
        console.log(port_minutes.reduce((a, b) => parseInt(a) + parseInt(b), 0));
  
        var total_port = port_minutes.reduce(
          (a, b) => parseInt(a) + parseInt(b),
          0
        );
        var minutes = total_port;
        var uptime = "";
        var th = Math.floor(minutes / 60);
        if (minutes < 60) {
          uptime = total_port + " Minutes";
        } else if (minutes >= 60 && minutes < 1440) {
          let m = minutes % 60;
          uptime = th + " Hours " + m + " Minutes";
        } else if (minutes >= 1440 && minutes < 43200) {
          let d = Math.floor(minutes / 1440);
          let h = Math.floor((minutes % 1440) / 60);
          let m = minutes % 60;
          uptime =
            d + " Days " + h + " Hours " + m + " Minutes (" + th + " Hours)";
        } else if (minutes > 43200) {
          let mo = Math.floor(minutes / 43200);
          let d = Math.floor((minutes % 43200) / 1440);
          let h = Math.floor((minutes % 1440) / 60);
          let m = minutes % 60;
          uptime =
            mo +
            " Months " +
            d +
            " Days " +
            h +
            " Hours " +
            m +
            " Minutes (" +
            th +
            " Hours)";
        }
  
        console.log(uptime);
        this.time_Uptime = uptime;
        console.log(this.time_Uptime);
        return uptime;
      },
  
      mapCenter() {
        return {
          lat: parseFloat(this.exhMaptop.lat),
          lng: parseFloat(this.exhMaptop.lon),
        };
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  @media (min-width: 280px) and (max-width: 319px) {
    .hederrpm_desktop {
      display: none;
    }
  
    .hederrpm_mobile {
      display: block;
    }
  
    .toolbar_rpmbobile {
      display: block;
    }
    .toolbar_rpmdesktop {
      display: none;
    }
  
    .exportrpm_mobile {
      display: block;
    }
  
    .exportrpm_desktop {
      display: none;
    }
  
    .output_mobile {
      display: block;
    }
  
    .output_desktop {
      display: none;
    }
  }
  
  /* Mobile Devices */
  @media (min-width: 320px) and (max-width: 480px) {
    .hederrpm_desktop {
      display: none;
    }
  
    .hederrpm_mobile {
      display: block;
    }
  
    .toolbar_rpmbobile {
      display: block;
    }
    .toolbar_rpmdesktop {
      display: none;
    }
  
    .exportrpm_mobile {
      display: block;
    }
  
    .exportrpm_desktop {
      display: none;
    }
  
    .output_mobile {
      display: block;
    }
  
    .output_desktop {
      display: none;
    }
  }
  
  /* iPads, Tablets */
  @media (min-width: 481px) and (max-width: 768px) {
    .hederrpm_desktop {
      display: block;
    }
  
    .hederrpm_mobile {
      display: none;
    }
  
    .exportrpm_mobile {
      display: none;
    }
  
    .exportrpm_desktop {
      display: block;
    }
  
    .output_mobile {
      display: none;
    }
  
    .output_desktop {
      display: block;
    }
  }
  
  /* Small screens, laptops */
  @media (min-width: 769px) and (max-width: 1024px) {
    .hederrpm_desktop {
      display: block;
    }
  
    .hederrpm_mobile {
      display: none;
    }
  
    .toolbar_rpmbobile {
      display: none;
    }
    .toolbar_rpmdesktop {
      display: block;
    }
  
    .exportrpm_mobile {
      display: none;
    }
  
    .exportrpm_desktop {
      display: block;
    }
  
    .output_mobile {
      display: none;
    }
  
    .output_desktop {
      display: block;
    }
  }
  
  /* Desktops, large screens */
  @media (min-width: 1025px) and (max-width: 1200px) {
    .hederrpm_desktop {
      display: block;
    }
  
    .hederrpm_mobile {
      display: none;
    }
  
    .toolbar_rpmbobile {
      display: none;
    }
    .toolbar_rpmdesktop {
      display: block;
    }
  
    .exportrpm_mobile {
      display: none;
    }
  
    .exportrpm_desktop {
      display: block;
    }
  
    .output_mobile {
      display: none;
    }
  
    .output_desktop {
      display: block;
    }
  }
  
  /* Extra large screens / TV */
  @media screen and (min-width: 1201px) {
    .hederrpm_desktop {
      display: block;
    }
  
    .hederrpm_mobile {
      display: none;
    }
  
    .toolbar_rpmbobile {
      display: none;
    }
    .toolbar_rpmdesktop {
      display: block;
    }
  
    .exportrpm_mobile {
      display: none;
    }
  
    .exportrpm_desktop {
      display: block;
    }
  
    .output_mobile {
      display: none;
    }
  
    .output_desktop {
      display: block;
    }
  }
  </style>
  