<template>
  <v-card class="elevation-0 rounded-0 mt-0">
    <v-card id="title_map_rpm" v-if="rpmData" color="#fff" class="ma-3 elevation-0 rounded-0 mt-0 pb-3"
      style="border: solid 1px #e0e0e0">
      <v-card> </v-card>
      <!-- {{rpmData}} -->
      <v-card class="elevation-0 rounded-0" color="#fff">
        <v-toolbar color="#F7F7F7" elevation="0" dense class="elevation-0 rounded-0">
          <v-toolbar-title> RPM Report </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn class="mx-2 rounded-0 elevation-0" fab x-small color="#F7F7F7" @click="closeRpmShow()">
            <v-icon dark> mdi-close </v-icon>
          </v-btn>
        </v-toolbar>

        <v-toolbar height="120" style="text-align: left" color="#F7F7F7" class="elevation-0 rounded-0 hederrpm_mobile">
          {{ rpmData.vessel_number }}
        </v-toolbar>
        <v-toolbar style="text-align: left" color="#F7F7F7" class="elevation-0 rounded-0 hederrpm_desktop" dense>
          {{ rpmData.vessel_number }}
        </v-toolbar>

        <v-divider></v-divider>
        <v-card color="#F7F7F7" style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-3">
          <v-card color="#F7F7F7" class="rounded-0 toolbar_rpmbobile pa-2" elevation="0">
            <v-menu content-class="elevation-0 rounded-0" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="border: solid 1px #e0e0e0" color="#fff" class="elevation-0 rounded-0" v-bind="attrs"
                  v-on="on" small width="45%">
                  <i class="bx bx-time"></i>
                  &nbsp; &nbsp;
                  <v-text v-if="rpmData">{{ rpmData.range.range }}</v-text>
                  <v-card-text v-if="!rpmData">
                    <div class="snippet" data-title=".dot-pulse">
                      <div class="stage">
                        <div class="dot-pulse"></div>
                      </div>
                    </div>
                  </v-card-text>
                  &nbsp; &nbsp;
                  <i class="bx bxs-chevron-down"></i>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, 'today')">Today</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, '24')">Last 24 Hour</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, '7')">Last 7 Days</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, '30')">Last 30 Days</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, 'week')">This Week</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, 'month')">This Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, 'lmonth')">Last Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="(dateopen = true), (datepickerID = rpmID)">Custom Range</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn width="45%" @click="sendDataRPM(rpmID, 'today')" class="mx-2 rounded-0 elevation-0 pa-0" fab dark
              x-small color="primary">
              <v-icon small dark> mdi-refresh </v-icon>
            </v-btn>
          </v-card>
          <v-card color="#F7F7F7" class="rounded-0 toolbar_rpmbobile" elevation="0">
            <v-chip x-small v-if="!rpmData" style="border: solid 1px #e0e0e0" color="#ffff" class="rounded-0" label>- -
              -</v-chip>
            <v-chip x-small style="border: solid 1px #e0e0e0" color="#ffff" class="rounded-0 pa-0" v-if="rpmData" label>{{
              rpmData.range.range_actual }}</v-chip>
          </v-card>

          <v-toolbar color="#F7F7F7" class="elevation-0 rounded-0 toolbar_rpmdesktop" dense>
            {{ rpmData.range.range_actual }}
            <v-spacer></v-spacer>
            <v-menu content-class="elevation-0 rounded-0" offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="border: solid 1px #e0e0e0" color="#fff" class="elevation-0 rounded-0" v-bind="attrs"
                  v-on="on" small>
                  <i class="bx bx-time"></i>
                  &nbsp; &nbsp;
                  <v-text v-if="rpmData">{{ rpmData.range.range }}</v-text>
                  <v-card-text v-if="!rpmData">
                    <div class="snippet" data-title=".dot-pulse">
                      <div class="stage">
                        <div class="dot-pulse"></div>
                      </div>
                    </div>
                  </v-card-text>
                  &nbsp; &nbsp;
                  <i class="bx bxs-chevron-down"></i>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, 'today')">Today</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, '24')">Last 24 Hour</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, '7')">Last 7 Days</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, '30')">Last 30 Days</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, 'week')">This Week</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, 'month')">This Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="sendDataRPM(rpmID, 'lmonth')">Last Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="(dateopen = true), (datepickerID = rpmID)">Custom Range</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-btn @click="sendDataRPM(rpmID, 'today')" class="mx-2 rounded-0 elevation-0" fab dark x-small
              color="primary">
              <v-icon small dark> mdi-refresh </v-icon>
            </v-btn>
          </v-toolbar>

          <v-card v-if="isRpmLive && rpmData" style="border: solid 1px #e0e0e0; padding: 10px"
            class="elevation-0 rounded-0 ma-3">
            <v-card style="
                border: solid 1px #e0e0e0;
                padding: 10px;
                text-align: center;
              " class="elevation-0 rounded-0 ma-1">
              <div style="font-size: 0.9em"><b>Port</b> RPM Live 1 Report</div>
              <canvas class="rpmLiveChart" id="chartLive1"></canvas>
            </v-card>
            <v-card style="
                border: solid 1px #e0e0e0;
                padding: 10px;
                text-align: center;
              " class="elevation-0 rounded-0 ma-1 mt-2">
              <div style="font-size: 0.9em">
                <b>Starboard</b> RPM Live 2 Report
              </div>
              <canvas class="rpmLiveChart" id="chartLive2"></canvas>
            </v-card>
          </v-card>

          <v-card v-if="isRpmLive" style="border: solid 1px #e0e0e0; padding: 10px" class="elevation-0 rounded-0 ma-3">
            <v-card style="padding: 0px" class="elevation-0 rounded-0 ma-1">
              <l-map v-if="!rpmMaptop" style="height: 38em; width: 100%" ref="mapempty"
                @fullscreenchange="triggerFullscreenEmpty" :zoom="6" :center="[-0.1292586012050707, 113.39020523630768]"
                :options="{
                  minZoom: 3,
                  zoomControl: false,
                  doubleClickZoom: true,
                  scrollWheelZoom: false,
                }">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-control-zoom position="bottomright"></l-control-zoom>
                <l-control-fullscreen position="topright"
                  :options="{ title: { false: 'Go big!', true: 'Be regular' } }" />
              </l-map>
              <l-map v-if="rpmMaptop" @fullscreenchange="triggerFullscreen" ref="map" :options="{
                minZoom: 3,
                zoomControl: false,
                doubleClickZoom: true,
                scrollWheelZoom: false,
              }" style="height: 38em; width: 100%" :zoom="8" :center="rpmMaptop.lat
    ? [parseFloat(rpmMaptop.lat), parseFloat(rpmMaptop.lon)]
    : [-0.1292586012050707, 113.39020523630768]
  ">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-control-zoom position="bottomright"></l-control-zoom>
                <l-control-fullscreen position="topright"
                  :options="{ title: { false: 'Go big!', true: 'Be regular' } }" />
                <v-rotated-marker ref="myMarker" v-if="rpmMaptop.lat" :lat-lng="[
                  parseFloat(rpmMaptop.lat),
                  parseFloat(rpmMaptop.lon),
                ]" :rotationAngle="rpmMaptop.rotation">
                  <l-icon :icon-size="[40 / 4, 100 / 4]" :icon-anchor="[40 / 4 / 2, 100 / 4 / 2]"
                    class-name="someExtraClass">
                    <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 100">
                      <path :fill="rpmMaptop.fill" stroke="#FFF" stroke-width="10"
                        d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" />
                    </svg>
                  </l-icon>
                  <l-popup @ready="detailRPM(), draw_Circle()">

                    <table>
                      <tr>
                        <td><b>Latitude</b></td>
                        <td class="pl-3">:</td>
                        <td colspan="2" class="pl-3">
                          {{ rpmMaptop.lat }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Longitude</b></td>
                        <td class="pl-3">:</td>
                        <td colspan="2" class="pl-3">{{ rpmMaptop.lon }}</td>
                      </tr>
                      <tr>
                        <td>
                          <div style="height: 1em"></div>
                        </td>
                        <td class="pl-3"></td>
                        <td colspan="2" class="pl-3"></td>
                      </tr>
                      <tr>
                        <td><b>Heading</b></td>
                        <td class="pl-3">:</td>
                        <td colspan="2" class="pl-3">{{ rpmMaptop.rotation + "&deg;" }}</td>
                      </tr>
                      <tr>
                        <td><b>Speed</b></td>
                        <td class="pl-3">:</td>
                        <td colspan="2" class="pl-3">
                          {{ rpmData.output[0].speed + " Knots" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Distance</b></td>
                        <td class="pl-3">:</td>
                        <td colspan="2" class="pl-3">{{ rpmData.output[0].distance + " Nmi" }}</td>
                      </tr>
                      <tr>
                        <td>
                          <div style="height: 1em"></div>
                        </td>
                        <td class="pl-3"></td>
                        <td colspan="2" class="pl-3"></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td class="pl-3"><b>(P)</b> RPM1</td>
                        <td class="pl-3"><b>(S)</b> RPM2</td>
                      </tr>
                      <tr>
                        <td><b>Current</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].current.split("/")[0] + " RPM" }}
                        </td>
                        <td class="pl-3">
                          {{ rpmData.output[0].current.split("/")[1] + " RPM" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Average</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].avg.split("/")[0] + " RPM" }}
                        </td>
                        <td class="pl-3">
                          {{ rpmData.output[0].avg.split("/")[1] + " RPM" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Min</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].min.split("/")[0] + " RPM" }}
                        </td>
                        <td class="pl-3">
                          {{ rpmData.output[0].min.split("/")[1] + " RPM" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Max</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].max.split("/")[0] + " RPM" }}
                        </td>
                        <td class="pl-3">
                          {{ rpmData.output[0].max.split("/")[1] + " RPM" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Uptime</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{
                            rpmData.output[0].w_hour.split("/")[0] + " Minutes"
                          }}
                        </td>
                        <td class="pl-3">
                          {{
                            rpmData.output[0].w_hour.split("/")[1] + " Minutes"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style="height: 1em"></div>
                        </td>
                        <td class="pl-3"></td>
                        <td colspan="2" class="pl-3"></td>
                      </tr>
                      <tr>
                        <td><b>Interval</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3" colspan="2">
                          {{ rpmData.output[0].interval + " Minutes" }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style="height: 1em"></div>
                        </td>
                        <td class="pl-3"></td>
                        <td colspan="2" class="pl-3"></td>
                      </tr>

                    </table>
                  </l-popup>
                </v-rotated-marker>
                <l-moving-rotated-marker v-if="drivePath" ref="driveMarker" :lat-lng="driveLatLng"
                  :rotationAngle="driveRotationAngle" :duration="3000" :icon="icon" />
              </l-map>
            </v-card>
          </v-card>

          <v-card v-if="rpmData && (rpmData.multiple == true)" style="border: solid 1px #e0e0e0; padding: 10px"
            class="elevation-0 rounded-0 ma-3">
            <v-card style="
                border: solid 1px #e0e0e0;
                padding: 10px;
                text-align: center;
              " v-for="( chart, index) in rpmData.chart.datasets" :key="index" class="elevation-0 rounded-0 ma-1">
              <div style="font-size: 0.9em"><b>{{ rpmData.chart.title[index] }}</b> RPM {{ index + 1 }} Report</div>
              <div style="font-size: 0.9em">
                <b>Working Hours</b> {{ portTime_value }}
              </div>
              <RpmChartVue v-if ="!rpmData.showSpeed" style="margin-top: -1.5em" height="250" v-bind:datachart="chart"
                v-bind:labelchart="rpmData.chart.label" />
                <RpmChartSpeed v-if ="rpmData.showSpeed" style="margin-top: -1.5em" height="250" v-bind:datachart="chart"
                v-bind:labelchart="rpmData.chart.label" />
            </v-card>
          </v-card>

          <v-card v-if="rpmData && (rpmData.multiple == false)" style="border: solid 1px #e0e0e0; padding: 10px"
            class="elevation-0 rounded-0 ma-3">
            <v-card style="
                border: solid 1px #e0e0e0;
                padding: 10px;
                text-align: center;
              " class="elevation-0 rounded-0 ma-1">
              <div style="font-size: 0.9em">RPM Report</div>
              <div style="font-size: 0.9em">
                <b>Working Hours</b> {{ portTime_value }}
              </div>
              <RpmChartVue  v-if ="!rpmData.showSpeed" style="margin-top: -1.5em" height="250" v-bind:datachart="rpmData.chart.datasets"
                v-bind:labelchart="rpmData.chart.label" />
                <RpmChartSpeed  v-if ="rpmData.showSpeed" style="margin-top: -1.5em" height="250" v-bind:datachart="rpmData.chart.datasets"
                v-bind:labelchart="rpmData.chart.label" />

            </v-card>
          </v-card>
          <v-card v-if="!isRpmLive" style="border: solid 1px #e0e0e0; padding: 10px" class="elevation-0 rounded-0 ma-3">
            <v-card style="border: solid 1px #e0e0e0; padding: 0px" class="elevation-0 rounded-0 ma-1">
              <l-map v-if="!rpmMaptop" style="height: 38em; width: 100%" ref="mapempty"
                @fullscreenchange="triggerFullscreenEmpty" :zoom="4" :center="[-0.1292586012050707, 113.39020523630768]"
                :options="{
                  minZoom: 3,
                  zoomControl: false,
                  doubleClickZoom: true,
                  scrollWheelZoom: false,
                }">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-control-zoom position="bottomright"></l-control-zoom>
                <l-control-fullscreen position="topright"
                  :options="{ title: { false: 'Go big!', true: 'Be regular' } }" />
              </l-map>
              <l-map v-if="rpmMaptop" @fullscreenchange="triggerFullscreen" ref="map" :options="{
                minZoom: 3,
                zoomControl: false,
                doubleClickZoom: true,
                scrollWheelZoom: false,
              }" style="height: 38em; width: 100%" :zoom="8" :center="rpmMaptop.lat
    ? [parseFloat(rpmMaptop.lat), parseFloat(rpmMaptop.lon)]
    : [-0.1292586012050707, 113.39020523630768]
  ">
                <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                <l-control-zoom position="bottomright"></l-control-zoom>
                <l-control-fullscreen position="topright"
                  :options="{ title: { false: 'Go big!', true: 'Be regular' } }" />
                <v-rotated-marker ref="myMarker" v-if="rpmMaptop.lat" :lat-lng="[
                  parseFloat(rpmMaptop.lat),
                  parseFloat(rpmMaptop.lon),
                ]" :rotationAngle="rpmMaptop.rotation">
                  <l-icon :icon-size="[40 / 4, 100 / 4]" :icon-anchor="[40 / 4 / 2, 100 / 4 / 2]"
                    class-name="someExtraClass">
                    <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 100">
                      <path :fill="rpmMaptop.fill" stroke="#FFF" stroke-width="10"
                        d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" />
                    </svg>
                  </l-icon>
                  <l-popup v-if="rpmData.multiple == true" @ready="detailRPM(), draw_Circle()">
                    <table>
                      <tr>
                        <td><b>Latitude</b></td>
                        <td class="pl-3">:</td>
                        <td colspan='2' class="pl-3">
                          {{ rpmMaptop.lat }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Longitude</b></td>
                        <td class="pl-3">:</td>
                        <td colspan='2' class="pl-3">{{ rpmMaptop.lon }}</td>
                      </tr>
                      <tr>
                        <td>
                          <div style="height: 1em"></div>
                        </td>
                        <td class="pl-3"></td>
                        <td colspan='2' class="pl-3"></td>
                      </tr>
                      <tr>
                        <td><b>Heading</b></td>
                        <td class="pl-3">:</td>
                        <td colspan='2' class="pl-3">{{ rpmMaptop.rotation + "&deg;" }}</td>
                      </tr>
                      <tr>
                        <td><b>Speed</b></td>
                        <td class="pl-3">:</td>
                        <td colspan='2' class="pl-3">
                          {{ rpmData.output[0].speed + " Knots" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Distance</b></td>
                        <td class="pl-3">:</td>
                        <td colspan='2' class="pl-3">{{ rpmData.output[0].distance + " Nmi" }}</td>
                      </tr>
                      <tr>
                        <td>
                          <div style="height: 1em"></div>
                        </td>
                        <td class="pl-3"></td>
                        <td colspan='2' class="pl-3"></td>
                      </tr>
                      <tr>
                        <td></td>
                        <td></td>
                        <td class="pl-3"><b>(P)</b> RPM1</td>
                        <td class="pl-3"><b>(S)</b> RPM2</td>
                      </tr>
                      <tr>
                        <td><b>Current</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].current.split("/")[0] + " RPM" }}
                        </td>
                        <td class="pl-3">
                          {{ rpmData.output[0].current.split("/")[1] + " RPM" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Average</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].avg.split("/")[0] + " RPM" }}
                        </td>
                        <td class="pl-3">
                          {{ rpmData.output[0].avg.split("/")[1] + " RPM" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Min</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].min.split("/")[0] + " RPM" }}
                        </td>
                        <td class="pl-3">
                          {{ rpmData.output[0].min.split("/")[1] + " RPM" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Max</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].max.split("/")[0] + " RPM" }}
                        </td>
                        <td class="pl-3">
                          {{ rpmData.output[0].max.split("/")[1] + " RPM" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Uptime</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{
                            rpmData.output[0].w_hour.split("/")[0] + " Minutes"
                          }}
                        </td>
                        <td class="pl-3">
                          {{
                            rpmData.output[0].w_hour.split("/")[1] + " Minutes"
                          }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style="height: 1em"></div>
                        </td>
                        <td class="pl-3"></td>
                        <td colspan='2' class="pl-3"></td>
                      </tr>
                      <tr>
                        <td><b>Interval</b></td>
                        <td class="pl-3">:</td>
                        <td colspan="2" class="pl-3">
                          {{ rpmData.output[0].interval + " Minutes" }}
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <div style="height: 1em"></div>
                        </td>
                        <td class="pl-3"></td>
                        <td colspan='2' class="pl-3"></td>
                      </tr>
                      <tr v-if="rpmData.isWeather">
                        <td><b>Weather</b></td>
                        <td class="pl-3">:</td>
                        <td colspan="2" class="pl-3">
                          {{ rpmData.output[0].weather }}
                        </td>

                      </tr>
                      <tr v-if="rpmData.isWeather">
                        <td><b>Wind</b></td>
                        <td class="pl-3">:</td>
                        <td colspan="2" class="pl-3">
                          {{ rpmData.output[0].wind }}
                        </td>

                      </tr>
                      <tr v-if="rpmData.isWeather">
                        <td><b>Waves</b></td>
                        <td class="pl-3">:</td>
                        <td colspan="2" class="pl-3">
                          {{ rpmData.output[0].wave }}
                        </td>

                      </tr>
                      <tr v-if="rpmData.isWeather">
                        <td>
                          <div style="height: 1em"></div>
                        </td>
                        <td class="pl-3"></td>
                        <td colspan='2' class="pl-3"></td>
                      </tr>
                      <tr>
                        <td><b>Updated</b></td>
                        <td class="pl-3">:</td>
                        <td colspan="2" class="pl-3">
                          {{ rpmData.output[0].dateTime_local }}
                        </td>

                      </tr>




                    </table>


                  </l-popup>
                  <l-popup v-if="rpmData.multiple != true" @ready="detailRPM(), draw_Circle()">

                    <table>
                      <tr>
                        <td><b>Latitude</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmMaptop.lat }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Longitude</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">{{ rpmMaptop.lon }}</td>
                      </tr>
                      <tr>
                        <td>
                          <div style="height: 1em"></div>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><b>Heading</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">{{ rpmMaptop.rotation + "&deg;" }}</td>
                      </tr>
                      <tr>
                        <td><b>Speed</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].speed + "&nbsp;knots" }}
                        </td>
                      </tr>
                      <tr>
                        <td><b>Distance</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">{{ rpmData.output[0].distance + " Nmi" }}</td>
                      </tr>
                      <tr>
                        <td>
                          <div style="height: 1em"></div>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><b>Current</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].current + " RPM" }}
                        </td>

                      </tr>
                      <tr>
                        <td><b>Average</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].avg + " RPM" }}
                        </td>

                      </tr>
                      <tr>
                        <td><b>Min</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].min + " RPM" }}
                        </td>

                      </tr>
                      <tr>
                        <td><b>Max</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].max + " RPM" }}
                        </td>

                      </tr>
                      <tr>
                        <td><b>Uptime</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{
                            rpmData.output[0].w_hour + " Minutes"
                          }}
                        </td>

                      </tr>
                      <tr>
                        <td>
                          <div style="height: 1em"></div>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><b>Interval</b></td>
                        <td class="pl-3">:</td>
                        <td class="pl-3">
                          {{ rpmData.output[0].interval + " Minutes" }}
                        </td>
                      </tr>
                      <tr>
                        <td>
                          <div style="height: 1em"></div>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td><b>Updated</b></td>
                        <td class="pl-3">:</td>
                        <td colspan="2" class="pl-3">
                          {{ rpmData.output[0].dateTime_local }}
                        </td>

                      </tr>

                    </table>

                  </l-popup>
                </v-rotated-marker>
                <l-moving-rotated-marker v-if="drivePath" ref="driveMarker" :lat-lng="driveLatLng"
                  :rotationAngle="driveRotationAngle" :duration="3000" :icon="icon" />
              </l-map> </v-card></v-card>

          <v-card style="border: solid 1px #e0e0e0; padding: 10px" class="elevation-0 rounded-0 ma-3">
            <v-card style="padding: 0px" class="elevation-0 rounded-0 ma-1">
              <v-toolbar style="border: solid 1px #e0e0e0" elevation="0" class="pa-0" color="#F7F7F7" fixed dense>
                <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0 mr-2"
                      color="#ffff" v-bind="attrs" v-on="on">
                      {{ kFormatter(itemsPerPageOutput)
                      }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
                    </v-btn>
                  </template>
                  <v-list style="border: solid 1px #e0e0e0">
                    <v-list-item @click="itemsPerPageOutput = 10"><v-list-item-title>10</v-list-item-title></v-list-item>
                    <v-list-item @click="itemsPerPageOutput = 25"><v-list-item-title>25</v-list-item-title></v-list-item>
                    <v-list-item @click="itemsPerPageOutput = 50"><v-list-item-title>50</v-list-item-title></v-list-item>
                    <v-list-item
                      @click="itemsPerPageOutput = 100"><v-list-item-title>100</v-list-item-title></v-list-item>
                  </v-list>
                </v-menu>

                <v-btn color="primary" class="elevation-0 rounded-0 exportrpm_desktop" @click="createCSV()" small><v-icon
                    small>mdi-file-move</v-icon> &nbsp;Export CSV</v-btn>
                <v-btn :href="'https://sailink.id/cetak/rpm/pdf/' +
                  rpmID +
                  '/' +
                  exp_range
                  " color="primary" dark small style="color: #fff"
                  class="elevation-0 rounded-0 ml-2 pdfthuraya_desktop" target="_blank">
                  <v-icon x-small>mdi-file-move</v-icon> Export PDF
                </v-btn>
                <v-btn :href="'https://sailink.id/cetak/kml/rpm/' + rpmID + '/' + exp_range" color="primary" dark small
                  style="color:#fff" class="elevation-0 rounded-0 pt-1 ml-2 pdfsailink_desktop" target="_blank">
                  <v-icon x-small>mdi-file-move</v-icon> Export KML
                </v-btn>
                <v-btn color="primary" class="elevation-0 rounded-0 exportrpm_mobile" @click="createCSV()" x-small><v-icon
                    small>mdi-file-move</v-icon>CSV</v-btn>
                <v-btn color="primary" class="elevation-0 rounded-0 ml-3 exportrpm_mobile" @click="createPDF()"
                  x-small><v-icon small>mdi-file-move</v-icon>PDF</v-btn>
                <v-spacer style="width: 50%"></v-spacer>
                <v-text-field style="border: solid 1px #e0e0e0" width="40" v-model="searchOutput" hide-details
                  prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
                  class="elevation-0 rounded-0"></v-text-field>
              </v-toolbar>
              <v-data-table style="border: solid 1px #e0e0e0" v-if="rpmOutput"
                :headers="!rpmData.isWeather ? headers : headers_weather" :search="searchOutput" :items="rpmOutput"
                multi-sort hide-default-footer hide-default-header :page.sync="pageOutput"
                :items-per-page="itemsPerPageOutput" class="elevation-0 rounded-0 dataTable output_desktop"
                @page-count="pageCountOutput = $event" @pagination="intoFilter" :expanded.sync="expanded"
                :single-expand="singleExpand">
                <template v-slot:header>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">#</th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Current<br />(RPM)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Average<br />(RPM)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Min<br />(RPM)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Max<br />(RPM)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Interval<br />(Minutes)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Uptime<br />(Minutes)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Latitude,<br />Longitude
                  </th>
                  <th v-if="rpmData.isWeather" class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Weather<br />Status
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Heading<br />(Degree)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Speed<br />(Knots)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    Distance<br />(Nmi)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                    dateTime<br />(GMT+7)
                  </th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">Detail</th>
                  <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_"></th>
                </template>
                <template v-slot:item="{ item, expand, isExpanded }">
                  <tr class="pa-0 ma-0">
                    <td width="2%" class="pa-0 ma-0" style="white-space: nowrap; text-align: center">
                      {{ item.index + 1 }}
                    </td>
                    <td v-if="rpmData.multiple == true" width="7%" class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center">
                      <b>(P)</b>
                      {{
                        parseInt(item.current.split("/")[0])
                          .toLocaleString()
                          .replace(/,/g, ".")
                      }}<br /><b>(S)</b>
                      {{
                        parseInt(item.current.split("/")[1])
                          .toLocaleString()
                          .replace(/,/g, ".")
                      }}

                    </td>
                    <td v-if="rpmData.multiple == false || rpmData.multiple != true" width="7%" class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center">

                      {{
                        parseInt(item.current)
                          .toLocaleString()
                          .replace(/,/g, ".")
                      }}

                    </td>
                    <td v-if="rpmData.multiple == false || rpmData.multiple != true" width="7%" class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center">
                      {{
                        parseInt(item.avg)
                          .toLocaleString()
                          .replace(/,/g, ".")
                      }}
                    </td>
                    <td v-if="rpmData.multiple == true" width="7%" class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center">
                      {{
                        parseInt(item.avg.split("/")[0])
                          .toLocaleString()
                          .replace(/,/g, ".")
                      }}<br />{{
  parseInt(item.avg.split("/")[1])
    .toLocaleString()
    .replace(/,/g, ".")
}}
                    </td>
                    <td v-if="rpmData.multiple == true" width="7%" class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center">
                      {{
                        parseInt(item.min.split("/")[0])
                          .toLocaleString()
                          .replace(/,/g, ".")
                      }}<br />{{
  parseInt(item.min.split("/")[1])
    .toLocaleString()
    .replace(/,/g, ".")
}}
                    </td>
                    <td v-if="rpmData.multiple == false || rpmData.multiple != true" width="7%" class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center">
                      {{
                        parseInt(item.min)
                          .toLocaleString()
                          .replace(/,/g, ".")
                      }}
                    </td>
                    <td v-if="rpmData.multiple == true" width="7%" class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center">
                      {{
                        parseInt(item.max.split("/")[0])
                          .toLocaleString()
                          .replace(/,/g, ".")
                      }}<br />{{
  parseInt(item.max.split("/")[1])
    .toLocaleString()
    .replace(/,/g, ".")
}}
                    </td>
                    <td v-if="rpmData.multiple == false || rpmData.multiple != true" width="7%" class="pa-0 ma-0"
                      style="white-space: nowrap; text-align: center">
                      {{
                        parseInt(item.max)
                          .toLocaleString()
                          .replace(/,/g, ".")
                      }}
                    </td>
                    <td width="7%" class="pa-0 ma-0" style="white-space: nowrap; text-align: center">
                      {{ item.interval }}
                    </td>
                    <td class="pa-0 ma-0" style="white-space: nowrap; text-align: center">
                      {{ item.w_hour.split("/")[0] }}<br />{{
                        item.w_hour.split("/")[1]
                      }}
                    </td>
                    <td v-if="!rpmData.isWeather" width="7%" class="pa-2 ma-0" style="text-align: center">
                      {{ item.latitude }},{{ item.longitude }}
                    </td>
                    <td v-if="rpmData.isWeather" width="7%" class="pa-2 ma-0" style="text-align: center">
                      {{ item.latitude }},<br />{{ item.longitude }}
                    </td>
                    <td v-if="rpmData.isWeather" width="12%" class="pa-0 ma-0" style="text-align: center">
                      {{ item.weather }}
                      <v-btn @click="
                        !isExpanded
                          ? (expand(!isExpanded), weatherClick(
                            rpmData.vessel_id,
                            item.id,
                            'rpm',
                            item.dateTime_local
                          ))
                          : (removeDetailWeather_(item.dateTime_local),
                            expand(!isExpanded))
                        " icon x-small :color="!isExpanded ? 'green' : 'red'" class="rounded-0 elevation-0">
                        <v-icon dark>
                          {{ !isExpanded ? "mdi-plus-box" : "mdi-minus-box" }}</v-icon>
                      </v-btn>

                    </td>
                    <td width="7%" class="pa-0 ma-0" style="white-space: nowrap; text-align: center">
                      {{ item.heading }}
                    </td>
                    <td width="7%" class="pa-0 ma-0" style="white-space: nowrap; text-align: center">
                      {{ item.speed }}
                    </td>
                    <td width="7%" class="pa-0 ma-0" style="white-space: nowrap; text-align: center">
                      {{ item.distance }}
                    </td>
                    <td width="7%" class="pa-0 ma-0" style="text-align: center">
                      {{ item.dateTime_local }}
                    </td>
                    <td width="5%" class="pa-0 ma-0" style="text-align: center">
                      <v-btn v-if="rpmDetailbtn && rpmDetailbtn[item.index] == true" fab dark x-small color="green"
                        class="mx-2 rounded-0 elevation-0" @click="
                          !isExpanded
                            ? (expand(!isExpanded),
                              fetchDetailItem(
                                rpmData.intervalArr[item.index],
                                rpmData.dateTimeArr[item.index]
                              ))
                            : (removeDetailOutput_(item.dateTime_local),
                              expand(!isExpanded))
                          ">
                        <v-icon small dark>
                          {{ !isExpanded ? "mdi-plus" : "mdi-minus" }}</v-icon>
                      </v-btn>
                      <div v-if="rpmDetailbtn && rpmDetailbtn[item.index] != true">
                        -
                      </div>
                    </td>
                    <td width="7%" style="white-space: nowrap; text-align: center">
                      <v-btn v-if="item.showMap == true" fab dark x-small color="primary"
                        class="mx-2 rounded-0 elevation-0" @click="
                          detailRPMMAP(item.latitude, item.longitude, item)
                          ">
                        <v-icon small dark> mdi-map </v-icon>
                      </v-btn>
                      <v-btn v-else fab dark x-small color="#DAE7ED" class="mx-2 rounded-0 elevation-0"
                        style="cursor: not-allowed">
                        <v-icon small dark> mdi-map </v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </template>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" style="background-color: #c1c1c1">
                    <v-card class="elevation-0 rounded-0 pa-1" width="100%">
                      <v-card class="elevation-0 rounded-0 pa-2" style="border: solid 1px #e0e0e0">
                        <v-card v-if="detailItem[item.dateTime_local]" class="elevation-0 rounded-0 pa-2"
                          style="border: solid 1px #e0e0e0">
                          <table style="width: 100%; border: solid 1px #e0e0e0">
                            <tr>
                              <th>Average</th>
                              <th>Min</th>
                              <th>Max</th>
                            </tr>
                            <tr>
                              <td>
                                <b>(P)</b>
                                {{
                                  detailItem[item.dateTime_local]["stat"][0].avg
                                }}
                              </td>
                              <td>
                                {{
                                  detailItem[item.dateTime_local]["stat"][0].min
                                }}
                              </td>
                              <td>
                                {{
                                  detailItem[item.dateTime_local]["stat"][0].max
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>(S)</b>
                                {{
                                  detailItem[item.dateTime_local]["stat"][1].avg
                                }}
                              </td>
                              <td>
                                {{
                                  detailItem[item.dateTime_local]["stat"][1].min
                                }}
                              </td>
                              <td>
                                {{
                                  detailItem[item.dateTime_local]["stat"][1].max
                                }}
                              </td>
                            </tr>
                          </table>
                        </v-card>

                        <v-card v-if="detailWeather[item.dateTime_local]" class="elevation-0 rounded-0 pa-2"
                          style="border: solid 1px #e0e0e0">
                          <v-toolbar class="elevation-0 text-center" dense color="#F7F7F7" elevation="2">
                            <v-spacer> Weather & Wave </v-spacer></v-toolbar>
                          <v-progress-linear v-if="!detailWeather[item.dateTime_local]" indeterminate color="primary"
                            height="7" striped></v-progress-linear>
                          <v-row v-if="detailWeather[item.dateTime_local]" no-gutters style="flex-wrap: nowrap">
                            <v-col cols="1" style="min-width: 100px; max-width: 100%; margin: 5px"
                              class="flex-grow-1 flex-shrink-0">
                              <v-card class="pa-2" outlined tile>
                                <v-card-text>
                                  <v-simple-table>
                                    <template v-slot:default>
                                      <tr>
                                        <td style="padding: 6px">Weather</td>
                                        <td style="padding: 6px">
                                          {{ detailWeather[item.dateTime_local].weather }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 6px">Wind Direction</td>
                                        <td style="padding: 6px">
                                          {{ detailWeather[item.dateTime_local].windDirection }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 6px">Wind Speed</td>
                                        <td style="padding: 6px">
                                          {{ detailWeather[item.dateTime_local].windSpeed }}
                                        </td>
                                      </tr>
                                    </template>
                                  </v-simple-table>
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col cols="1" style="min-width: 100px; max-width: 100%; margin: 5px"
                              class="flex-grow-1 flex-shrink-0">
                              <v-card class="pa-2" outlined tile>
                                <v-card-text>
                                  <v-simple-table>
                                    <template v-slot:default>
                                      <tr>
                                        <td style="padding: 6px">Temperature</td>
                                        <td style="padding: 6px">
                                          {{ detailWeather[item.dateTime_local].temperature }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 6px">Humidity</td>
                                        <td style="padding: 6px">
                                          {{ detailWeather[item.dateTime_local].humidity }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 6px">Dew Point</td>
                                        <td style="padding: 6px">
                                          {{ detailWeather[item.dateTime_local].dewPoint }}
                                        </td>
                                      </tr>
                                    </template>
                                  </v-simple-table>
                                </v-card-text>
                              </v-card>
                            </v-col>
                            <v-col cols="1" style="min-width: 100px; max-width: 100%; margin: 5px"
                              class="flex-grow-1 flex-shrink-0">
                              <v-card class="pa-2" outlined tile>
                                <v-card-text>
                                  <v-simple-table>
                                    <template v-slot:default>
                                      <tr>
                                        <td style="padding: 6px">Wave Height</td>
                                        <td style="padding: 6px">
                                          {{
                                            detailWeather[item.dateTime_local]
                                              .waveSignificantHeight
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 6px">Wave From Direction</td>
                                        <td style="padding: 6px">
                                          {{
                                            detailWeather[item.dateTime_local].waveFromDirection
                                          }}
                                        </td>
                                      </tr>
                                      <tr>
                                        <td style="padding: 6px">
                                          <p style="height: 2px"></p>
                                        </td>
                                        <td style="padding: 6px">
                                          <p style="height: 2px"></p>
                                        </td>
                                      </tr>
                                    </template>
                                  </v-simple-table>
                                </v-card-text>
                              </v-card>
                            </v-col>
                          </v-row>
                        </v-card>
                        <img v-if="!detailItem[item.dateTime_local] && !detailWeather[item.dateTime_local]"
                          alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center ma-4" />

                        <v-card v-if="detailItem[item.dateTime_local]" class="elevation-0 rounded-0 pa-2 mt-2"
                          style="border: solid 1px #e0e0e0">
                          <canvas class="rpmDetailChart" :id="'chartItem' +
                            item.dateTime_local.split(' ').join('_')
                            "></canvas>
                          <div class="text-center">
                            <v-pagination :class="'pagpag' + item.id"
                              :onload="pgncolor(item.id, detailItem[item.dateTime_local].data[0].length, (detailItem[item.dateTime_local].redBtn), pageItem_[item.dateTime_local])"
                              :color="detailItem[item.dateTime_local].redBtn.includes(pageItem_[item.dateTime_local] - 1) ? 'red' : 'primary'"
                              v-model="pageItem_[item.dateTime_local]" @input="changeChartDT(item.dateTime_local)"
                              :length="detailItem[item.dateTime_local].data[0].length
                                ">

                            </v-pagination>
                          </div>
                        </v-card>
                      </v-card>
                    </v-card>
                  </td>
                </template>
              </v-data-table>
              <v-data-table style="border: solid 1px #e0e0e0" v-if="rpmOutput" :headers="headers" :search="searchOutput"
                :items="rpmOutput" hide-default-footer hide-default-header :page.sync="pageOutput"
                :items-per-page="itemsPerPageOutput" class="elevation-0 rounded-0 output_mobile"
                @page-count="pageCountOutput = $event" @pagination="intoFilter"><template v-slot:item="row">
                  <tr style="margin-top: 0.4em; margin-bottom: 0.4em">
                    <td style="border-bottom: none; text-align: center">
                      <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" max-width="87vw">
                        <v-toolbar dense class="elevation-0 rounded-0">
                          <v-toolbar-subtitle>{{
                            row.item.index + 1
                          }}</v-toolbar-subtitle>
                          <v-spacer></v-spacer>
                          <v-btn v-if="row.item.showMap == true" fab dark x-small color="primary"
                            class="mx-2 rounded-0 elevation-0" @click="
                              detailRPMMAP(
                                row.item.latitude,
                                row.item.longitude,
                                row.item
                              )
                              ">
                            <v-icon small dark> mdi-map </v-icon>
                          </v-btn>
                          <v-btn v-else fab dark x-small color="#DAE7ED" class="mx-2 rounded-0 elevation-0"
                            style="cursor: not-allowed">
                            <v-icon small dark> mdi-map </v-icon>
                          </v-btn>
                        </v-toolbar>
                        <v-divider></v-divider>
                        <v-card-text>
                          <table>
                            <tr>
                              <td>Current (RPM)</td>
                              <td>:</td>
                              <td>
                                <b>(P)</b> {{ row.item.current.split("/")[0]
                                }}<br /><b>(S)</b>
                                {{ row.item.current.split("/")[1] }}
                              </td>
                            </tr>
                            <tr>
                              <td>Average (RPM)</td>
                              <td>:</td>
                              <td>
                                {{ row.item.avg.split("/")[0] }}<br />{{
                                  row.item.avg.split("/")[1]
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Min (RPM)</td>
                              <td>:</td>
                              <td>
                                {{ row.item.min.split("/")[0] }}<br />{{
                                  row.item.min.split("/")[1]
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Max (RPM)</td>
                              <td>:</td>
                              <td>
                                {{ row.item.max.split("/")[0] }}<br />{{
                                  row.item.max.split("/")[1]
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Interval (Minutes)</td>
                              <td>:</td>
                              <td>{{ row.item.interval }}</td>
                            </tr>
                            <tr>
                              <td>Uptime (Minutes)</td>
                              <td>:</td>
                              <td>{{ row.item.uptime }}</td>
                            </tr>
                            <tr>
                              <td>Lat,<br />lng</td>
                              <td>:</td>
                              <td>
                                {{ row.item.latitude }},<br />{{
                                  row.item.longitude
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Heading (Degree)</td>
                              <td>:</td>
                              <td>{{ row.item.heading }}</td>
                            </tr>
                            <tr>
                              <td>Speed (Knots)</td>
                              <td>:</td>
                              <td>{{ row.item.speed }}</td>
                            </tr>
                            <tr>
                              <td>Distance (Nmi)</td>
                              <td>:</td>
                              <td>{{ row.item.distance }}</td>
                            </tr>
                            <tr>
                              <td>dateTime (GMT+7)</td>
                              <td>:</td>
                              <td>{{ row.item.dateTime_local }}</td>
                            </tr>
                            <tr>
                              <td>Detail</td>
                              <td>:</td>
                              <td>-</td>
                            </tr>
                          </table>
                        </v-card-text>
                      </v-card>
                    </td>
                  </tr>
                </template>
              </v-data-table>
              <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
                <p class="mt-3" style="font-size: 0.8em">
                  Showing {{ showingDatafromOutput }} to
                  {{ showingDatatoOutput }} of {{ totalFilterOutput }} entries
                </p>

                <v-spacer></v-spacer>
                <v-pagination color="primary" v-model="pageOutput" :length="pageCountOutput"
                  :total-visible="7"></v-pagination>
              </v-toolbar>
            </v-card>
          </v-card>
        </v-card>
      </v-card>
    </v-card>
    <!--Modal Map-->
    <v-dialog v-model="dmap" min-width="90vw" persistent>
      <v-card color="#ffff" class="elevation-0 rounded-0 pb-1">
        <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0" dense>
          <v-col class="header_modal">
            <v-row><v-toolbar-title>RPM MAP | {{ rpmData.vessel_number }}
              </v-toolbar-title></v-row>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn small class="elevation-0 rounded-0" icon @click="dmap = null">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card class="elevation-0 rounded-0 ma-2 mb-1 pa-2" style="border: solid 1px #e0e0e0">
          <v-responsive min-width="100%" min-height="60%">
            <v-lazy>
              <client-only>
                <div id="map">
                  <l-map v-if="dmap" @fullscreenchange="triggerFullscreenModal" ref="mapmodal" style="height: 40em"
                    :zoom="8" :center="[parseFloat(dmaplat), parseFloat(dmaplon)]" :options="{
                      minZoom: 3,
                      zoomControl: false,
                      doubleClickZoom: true,
                      scrollWheelZoom: false,
                    }">
                    <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
                    <l-control-zoom position="bottomright"></l-control-zoom>
                    <l-control-fullscreen position="topright" :options="{
                      title: { false: 'Go big!', true: 'Be regular' },
                    }" />
                    <v-rotated-marker ref="myMarkerMap" :lat-lng="[parseFloat(dmaplat), parseFloat(dmaplon)]"
                      :rotationAngle="dmapdata.rotation">
                      <l-icon :icon-size="[40 / 4, 100 / 4]" :icon-anchor="[40 / 4 / 2, 100 / 4 / 2]"
                        class-name="someExtraClass">
                        <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 100">
                          <path :fill="rpmMaptop.fill" stroke="#FFF" stroke-width="10"
                            d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" />
                        </svg>
                      </l-icon>
                      <l-popup v-if="rpmData.multiple == true" @ready="logReady()">

                        <table>
                          <tr>
                            <td><b>Latitude</b></td>
                            <td class="pl-3">:</td>
                            <td colspan="2" class="pl-3">
                              {{ dmaplat }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Longitude</b></td>
                            <td class="pl-3">:</td>
                            <td colspan="2" class="pl-3">{{ dmaplon }}</td>
                          </tr>
                          <tr>
                            <td>
                              <div style="height: 1em"></div>
                            </td>
                            <td class="pl-3"></td>
                            <td colspan="2" class="pl-3"></td>
                          </tr>
                          <tr>
                            <td><b>Heading</b></td>
                            <td class="pl-3">:</td>
                            <td colspan="2" class="pl-3">{{ dmapdata.rotation + "&deg;" }}</td>
                          </tr>
                          <tr>
                            <td><b>Speed</b></td>
                            <td class="pl-3">:</td>
                            <td colspan="2" class="pl-3">
                              {{ dmapdata.speed + " Knots" }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Distance</b></td>
                            <td class="pl-3">:</td>
                            <td colspan="2" class="pl-3">{{ dmapdata.distance + " Nmi" }}</td>
                          </tr>
                          <tr>
                            <td>
                              <div style="height: 1em"></div>
                            </td>
                            <td class="pl-3"></td>
                            <td colspan="2" class="pl-3"></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                            <td class="pl-3"><b>(P)</b> RPM1</td>
                            <td class="pl-3"><b>(S)</b> RPM2</td>
                          </tr>
                          <tr>
                            <td><b>Current</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmapdata.current.split("/")[0] + " RPM" }}
                            </td>
                            <td class="pl-3">
                              {{ dmapdata.current.split("/")[1] + " RPM" }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Average</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmapdata.avg.split("/")[0] + " RPM" }}
                            </td>
                            <td class="pl-3">
                              {{ dmapdata.avg.split("/")[1] + " RPM" }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Min</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmapdata.min.split("/")[0] + " RPM" }}
                            </td>
                            <td class="pl-3">
                              {{ dmapdata.min.split("/")[1] + " RPM" }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Max</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmapdata.max.split("/")[0] + " RPM" }}
                            </td>
                            <td class="pl-3">
                              {{ dmapdata.max.split("/")[1] + " RPM" }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Uptime</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmapdata.w_hour.split("/")[0] + " Minutes" }}
                            </td>
                            <td class="pl-3">
                              {{ dmapdata.w_hour.split("/")[1] + " Minutes" }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div style="height: 1em"></div>
                            </td>
                            <td class="pl-3"></td>
                            <td colspan="2" class="pl-3"></td>
                          </tr>
                          <tr>
                            <td><b>Interval</b></td>
                            <td class="pl-3">:</td>
                            <td colspan="2" class="pl-3">
                              {{ dmapdata.interval + ' Minutes' }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div style="height: 1em"></div>
                            </td>
                            <td class="pl-3"></td>
                            <td colspan="2" class="pl-3"></td>
                          </tr>
                          <tr v-if="rpmData.isWeather">
                            <td><b>Weather</b></td>
                            <td class="pl-3">:</td>
                            <td colspan="2" class="pl-3">
                              {{ dmapdata.weather }}
                            </td>
                          </tr>
                          <tr v-if="rpmData.isWeather">
                            <td><b>Wind</b></td>
                            <td class="pl-3">:</td>
                            <td colspan="2" class="pl-3">
                              {{ dmapdata.wind }}
                            </td>
                          </tr>
                          <tr v-if="rpmData.isWeather">
                            <td><b>Waves</b></td>
                            <td class="pl-3">:</td>
                            <td colspan="2" class="pl-3">
                              {{ dmapdata.wave }}
                            </td>
                          </tr>
                          <tr v-if="rpmData.isWeather">
                            <td>
                              <div style="height: 1em"></div>
                            </td>
                            <td class="pl-3"></td>
                            <td colspan="2" class="pl-3"></td>
                          </tr>
                          <tr>
                            <td><b>Updated</b></td>
                            <td class="pl-3">:</td>
                            <td colspan="2" class="pl-3">
                              {{ dmapdata.dateTime_local }}
                            </td>
                          </tr>
                        </table>
                      </l-popup>
                      <l-popup v-if="rpmData.multiple != true" @ready="logReady()">

                        <table>
                          <tr>
                            <td><b>Latitude</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmaplat }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Longitude</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">{{ dmaplon }}</td>
                          </tr>
                          <tr>
                            <td>
                              <div style="height: 1em"></div>
                            </td>
                            <td class="pl-3"></td>
                            <td class="pl-3"></td>
                          </tr>
                          <tr>
                            <td><b>Heading</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">{{ dmapdata.rotation + "&deg;" }}</td>
                          </tr>
                          <tr>
                            <td><b>Speed</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmapdata.speed + " Knots" }}
                            </td>
                          </tr>
                          <tr>
                            <td><b>Distance</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">{{ dmapdata.distance + " Nmi" }}</td>
                          </tr>
                          <tr>
                            <td>
                              <div style="height: 1em"></div>
                            </td>
                            <td class="pl-3"></td>
                            <td class="pl-3"></td>
                          </tr>

                          <tr>
                            <td><b>Current</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmapdata.current + " RPM" }}
                            </td>

                          </tr>
                          <tr>
                            <td><b>Average</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmapdata.avg + " RPM" }}
                            </td>

                          </tr>
                          <tr>
                            <td><b>Min</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmapdata.min + " RPM" }}
                            </td>

                          </tr>
                          <tr>
                            <td><b>Max</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmapdata.max + " RPM" }}
                            </td>

                          </tr>
                          <tr>
                            <td><b>Uptime</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmapdata.w_hour + " Minutes" }}
                            </td>

                          </tr>
                          <tr>
                            <td>
                              <div style="height: 1em"></div>
                            </td>
                            <td class="pl-3"></td>
                            <td class="pl-3"></td>
                          </tr>
                          <tr>
                            <td><b>Interval</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmapdata.interval + ' Minutes' }}
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div style="height: 1em"></div>
                            </td>
                            <td class="pl-3"></td>
                            <td class="pl-3"></td>
                          </tr>
                          <tr>
                            <td><b>Updated</b></td>
                            <td class="pl-3">:</td>
                            <td class="pl-3">
                              {{ dmapdata.dateTime_local }}
                            </td>
                          </tr>
                        </table>
                      </l-popup>
                    </v-rotated-marker>
                  </l-map>
                </div>
              </client-only>
            </v-lazy>
          </v-responsive>
        </v-card>
      </v-card>
    </v-dialog>
    <!--Modal Map-->

     <!--Modal Date Picker-->
     <v-dialog ref="dialog" v-model="dateopen" :return-value.sync="dates" class="elevation-0 rounded-0" persistent
      width="750">
      <v-card class="elevation-0 rounded-0 pb-1">
        <v-toolbar dense color="#f7f7f7" class="elevation-0 rounded-0">
          Choose Custom Date Range for Tracking
          <v-spacer></v-spacer>
          <v-btn small class="elevation-0 rounded-0" icon @click="closeCostumRange()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card class="elevation-0 rounded-0">
          <v-alert v-if="cant_future" class="elevation-0 rounded-0 ma-2 mt-3" text dense color="red"
            icon="mdi-clock-alert-outline" style="font-size: 0.86em;text-align: left;">
            Cannot predict future | Enter the correct date and time range
          </v-alert>
          <v-alert v-if="cant_backwards" type="warning" class="elevation-0 rounded-0 ma-2 mt-3" text dense
            icon="mdi-history" style="font-size: 0.86em;text-align: left;">
            Backwards date and time range | Enter the correct date and time range
          </v-alert>
        </v-card>
        <v-row width="100%" no-gutters>
          <v-col cols="12" sm="6">
            <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 pa-2 ma-2">
              <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 pa-2 ma-1">
                <v-date-picker @click:date="logtest" :disabled="(testdisabled || dates.length > 1) ? true : false"
                  color="primary" class="elevation-0 rounded-0" v-model="dates" range scrollable>
                </v-date-picker>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 pa-2 ma-2">
              <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0  ma-2">
                <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">From</v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="7">

                      <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="dates[0]" label="Date"
                        prepend-inner-icon="mdi-calendar"></v-text-field>

                    </v-col>
                    <v-col cols="12" sm="5">

                      <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="times[0]" label="Time"
                        prepend-inner-icon="mdi-clock-outline"></v-text-field>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0  ma-2">
                <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">To</v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="7">

                      <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="dates[1]" label="Date"
                        prepend-inner-icon="mdi-calendar"></v-text-field>

                    </v-col>
                    <v-col cols="12" sm="5">

                      <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="times[1]" label="Time"
                        prepend-inner-icon="mdi-clock-outline"></v-text-field>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>


              <v-toolbar class="elevation-0 rounded-0" dense color="#f7f7f7">
                <v-spacer></v-spacer>
                <v-btn small :disabled="dates.length > 1 ? false : true" class="elevation-0 rounded-0 mr-1"
                  @click="resetTimesDates()" color="primary"> Reset </v-btn>
                <v-btn small :disabled="dates.length > 1 ? false : true" class="elevation-0 rounded-0" color="primary"
                  @click="customRange()"> OK </v-btn>
              </v-toolbar>
            </v-card>
          </v-col>
        </v-row>

      </v-card>

    </v-dialog>

    <!--Modal Time Picker-->
    <v-dialog ref="dialog" v-model="timeopen" :return-value.sync="dates" class="elevation-0 rounded-0" persistent
      width="fit-content">
      <v-card class="elevation-0 rounded-0 pa-2">
        <v-card style="border: solid 1px #e0e0e0;display: block;" class="elevation-0 rounded-0 ma-2">
          <v-card style="border: solid 1px #e0e0e0;display: block;" class="elevation-0 rounded-0 ma-2">
            <v-time-picker v-model="timein" ampm-in-title format="24hr">
            </v-time-picker>
          </v-card>

          <v-card style="border: solid 1px #e0e0e0;display: block;" class="elevation-0 rounded-0 ma-2">
            <v-toolbar class="elevation-0 rounded-0" dense color="f7f7f7">
              <v-spacer></v-spacer>
              <v-btn :disabled="!timein ? true : false" class="elevation-0 rounded-0" small color="primary"
                @click="saveTimes()"> Choose Time </v-btn>
            </v-toolbar>
          </v-card>

        </v-card>
      </v-card>
    </v-dialog>
  </v-card>
</template>
<script src="https://cdn.jsdelivr.net/npm/chartjs-plugin-datalabels@2.0.0"></script>
<script>
import axios from "../../plugins/axios";
import $ from "jquery";
import RpmChartVue from "../charts/RpmChart.vue";
import RpmChartSpeed from "../charts/RpmChartSpeed.vue"
import DetailChartVue from "../charts/DetailChart.vue";
import RpmChartLive from "../charts/RpmChartLive.vue";
import { mapSettingsShow } from "@/constants/mapSettings";
import { mapGetters, mapActions } from "vuex";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import { LMap, LTileLayer, LIcon, LPopup, LControlZoom } from "vue2-leaflet";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import LMovingRotatedMarker from "vue2-leaflet-rotatedmarker";

const iconUrl =
  "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDIuODggMy40NiI+PGRlZnM+PHN0eWxlPi5ke2ZpbGw6IzAwMDBGRjtzdHJva2U6I2ZmZjtzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6LjJweDt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9ImEiLz48ZyBpZD0iYiI+PGcgaWQ9ImMiPjxwYXRoIGNsYXNzPSJkIiBkPSJNMi42OSwzLjI2bC0uOTYtLjQ1Yy0uMTktLjA5LS40LS4wOS0uNTksMGwtLjk2LC40NUwxLjQ0LC4yNmwxLjI1LDNaIi8+PC9nPjwvZz48L3N2Zz4=";

export default {
  props: ["rpmData", "rpmOutput", "rpmshowclick"],

  components: {
    LMap,
    LTileLayer,
    LIcon,
    LPopup,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
    RpmChartVue,
    RpmChartSpeed,
    RpmChartLive,
    LControlZoom,
    LControlFullscreen,
    LMovingRotatedMarker,
    DetailChartVue,
  },

  data() {
    return {
      redBtn: [1, 2],
      chartdtArr: [],
      detailItem: [],
      detailWeather: [],
      pageItem_: [],
      expanded: [],
      liveChart1: null,
      liveChart2: null,
      limitChart: 60,
      exp_range: "today",
      chartLiveData_: {
        label: [],
        datasets: [],
      },
      countLength: 0,
      IntervalMoving: null,
      IntervalLive: null,
      fetchInterval: null,
      dataTableTimeout: null,
      timeoutInterval: null,
      icon: L.icon({
        iconUrl: iconUrl,
        iconSize: [15, 20],
        iconAnchor: [7.5, 10],
        popupAnchor: [0, -25],
      }),
      drivePath: null,
      driveLatLng: null,
      driveRotationAngle: null,
      driveMarker: null,
      totalFilterOutput: null,
      time_Uptime: "22",
      dateopen: false,
      timeopen: false,
      cant_future: false,
      cant_backwards: false,
      testdisabled: false,
      dates: [],
      times: [],
      timein: null,
      datepickerID: null,
      loadernavplus: require("@/assets/img/load100new.gif"),
      option1: { name: "1" },
      option2: { name: "2" },
      dmap: null,
      dmaplat: null,
      dmaplon: null,
      dmapdata: [],
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Current (RPM)", value: "current" },
        { text: "Average (RPM)", value: "avg" },
        { text: "Min (RPM)", value: "min" },
        { text: "Max (RPM)", value: "max" },
        { text: "Interval (Minutes)", value: "interval" },
        { text: "Uptime (Minutes)", value: "w_hour" },
        { text: "Latitude, Longtitude", value: "latitude" },
        { text: "Heading (Degree)", value: "heading" },
        { text: "Speed (Knots)", value: "speed" },
        { text: "Distance (Nmi)", value: "distance" },
        { text: "dateTime (GMT+7)", value: "dateTime_local" },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "",
        },
      ],
      headers_weather: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Current (RPM)", value: "current" },
        { text: "Average (RPM)", value: "avg" },
        { text: "Min (RPM)", value: "min" },
        { text: "Max (RPM)", value: "max" },
        { text: "Interval (Minutes)", value: "interval" },
        { text: "Uptime (Minutes)", value: "w_hour" },
        { text: "Latitude, Longtitude", value: "latitude" },
        { text: "Weather Status", value: "weather" },
        { text: "Heading (Degree)", value: "heading" },
        { text: "Speed (Knots)", value: "speed" },
        { text: "Distance (Nmi)", value: "distance" },
        { text: "dateTime (GMT+7)", value: "dateTime_local" },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "",
        },
      ],
      url: "https://maps.skyreach.id/hot/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 5,
      itemsPerPageOutput: 100,
      pageOutput: 1,
      pageCountOutput: 0,
      searchOutput: "",
    };
  },
  mounted() {
    //  this.$nextTick(() => {
    //   this.detailRPM()
    //  })
    // this.initialdata_movingfirst();
    var mama = this;
    if (!mama.rpm_executed) {
      console.log("jalan title map");
      mama.addrpm_executed(true);
      $("html, body").animate(
        {
          scrollTop: $("#title_map_rpm").offset().top - 109,
        },
        800
      );
    } else {
      console.log("Muncul"), console.log(mama.rpm_executed);
    }
  },

  watch: {

    rpmUpdate(newQuestion, oldQuestion) {
      if (this.rpmUpdate && this.rpmUpdate.update == true) {
        var mama = this;
        var dataOutput = {
          id: this.rpmUpdate.id,
          rawData:
            '{"data":"RPM\\/RPI-AT 10003 1102\\/1200;1105\\/1205;1100\\/1200;1110\\/1210;5;1110\\/1210;20230310073620;10120;5\\/5 2023-03-20 10:47:22 0529.6658 S 10518.5044 E"}',
          dev_id: this.rpmUpdate.dev_id,
          content: this.rpmUpdate.content,
          current: this.rpmUpdate.current,
          avg: this.rpmUpdate.avg,
          min: this.rpmUpdate.min,
          max: this.rpmUpdate.max,
          interval: this.rpmUpdate.interval,
          max_all: this.rpmUpdate.max_all,
          up_since: this.rpmUpdate.up_since,
          uptime: this.rpmUpdate.uptime,
          w_hour: this.rpmUpdate.w_hour,
          latitude: this.rpmUpdate.latitude,
          longitude: this.rpmUpdate.longitude,
          heading: this.rpmUpdate.heading,
          distance: this.rpmUpdate.distance,
          speed: this.rpmUpdate.speed,
          dateTime: this.rpmUpdate.dateTime,
          dateTime_local: this.rpmUpdate.dateTime_local,
          created_at: this.rpmUpdate.created_at,
          updated_at: this.rpmUpdate.updated_at,
          showMap: this.rpmUpdate.showMap,
          index: 0,
        };

        this.rpmData.output.unshift(dataOutput);
        // this.draw_Circle();
        this.rpmData.chart.label.push(this.rpmUpdate.chart.dateTime_label);

        for (var i = 0; i < this.rpmData.chart.datasets[0].length; i++) {
          if (this.rpmData.chart.datasets[0][i]["label"] == "Current") {
            this.rpmData.chart.datasets[0][i]["data"].push(
              mama.rpmUpdate.chart.current[0]
            );
          }

          if (this.rpmData.chart.datasets[0][i]["label"] == "Max") {
            this.rpmData.chart.datasets[0][i]["data"].push(
              mama.rpmUpdate.chart.max[0]
            );
          }

          if (this.rpmData.chart.datasets[0][i]["label"] == "Min") {
            this.rpmData.chart.datasets[0][i]["data"].push(
              mama.rpmUpdate.chart.min[0]
            );
          }

          if (this.rpmData.chart.datasets[0][i]["label"] == "Average") {
            this.rpmData.chart.datasets[0][i]["data"].push(
              mama.rpmUpdate.chart.avg[0]
            );
          }
        }

        for (var i = 0; i < this.rpmData.chart.datasets[1].length; i++) {
          if (this.rpmData.chart.datasets[1][i]["label"] == "Current") {
            this.rpmData.chart.datasets[1][i]["data"].push(
              mama.rpmUpdate.chart.current[1]
            );
          }

          if (this.rpmData.chart.datasets[1][i]["label"] == "Max") {
            this.rpmData.chart.datasets[1][i]["data"].push(
              mama.rpmUpdate.chart.max[1]
            );
          }

          if (this.rpmData.chart.datasets[1][i]["label"] == "Min") {
            this.rpmData.chart.datasets[1][i]["data"].push(
              mama.rpmUpdate.chart.min[1]
            );
          }

          if (this.rpmData.chart.datasets[1][i]["label"] == "Average") {
            this.rpmData.chart.datasets[1][i]["data"].push(
              mama.rpmUpdate.chart.avg[1]
            );
          }
        }
        console.log('RPM DATA OUTPUT')
        console.log(this.rpmData.output)
        this.addrpmOutput(this.rpmData.output);
        console.log(this.rpmData.chart.datasets[0][0]["data"]);
      }
    }

  },

  methods: {
    ...mapActions([
      "inputRPMID",
      "fetchrpmOutputDetail",
      "fetchDetailbtn",
      "addrpmOutput",
      "fetchRpmLive",
      "fetchIot",
      "fetchRpm",
      "removeRpmShow",
      "removeSearchIOT",
      "set_port_time",
      "addrpm_executed",
      "fetchRpmLiveTable",
    ]),

    logbenar() {
      console.log("fetch tombol detail");
    },
    pgncolor(id, count, color, item) {
      console.log('WARNA PAGINASI')
      console.log(color)
      console.log('URUTAN PAGINASI')
      console.log(count)
      console.log('ITEM')
      console.log(item)
      var index;
      var warna;
      var kelas;


      setTimeout(function () {
        for (let i = 0; i < count; i++) {
          index = i
          console.log('paginasi ke' + (i + 1))
          if (color.includes(index)) {
            warna = '#FFADAD'
            console.log('warnanya merah')
          } else {
            warna = 'transparent'
            console.log('warnanya biru')
          }
          kelas = '.pagpag' + id
          console.log(kelas)
          $(kelas + ' .v-pagination > li:nth-child(' + (index + 2) + ') .v-pagination__item').css('background', '' + warna + '');
        }

      }, 1000);

    },

    fetchDetailbtn_() {
      var data = {
        dateTimeArr: this.rpmData.dateTimeArr,
        intervalArr: this.rpmData.intervalArr,
        dev_id: this.rpmData.dev_id,
        token: localStorage.getItem("token"),
      };
      this.fetchDetailbtn(data);
    },

    async fetchDetailItem(interval, dateTime) {
      const dataDetail = {
        dev_id: this.rpmData.dev_id,
        interval: interval,
        dateTime: dateTime,
      };
      try {
        const response = await axios.post(
          "/api/v1/navplus/iot/fetchRpmDetail",
          dataDetail,
          {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
            },
          }
        );
        console.log(response);
        var datasets = [
          {
            label: "Port",
            data: response.data.iot.data[0][0],
            backgroundColor: "rgb(17,130,59, 0.1)",
            borderColor: "rgb(17,130,59)",
            fill: false,
            tension: 0.1,
          },
          {
            label: "Starboard",
            data: response.data.iot.data[1][0],
            backgroundColor: "rgb(255, 99, 132, 0.1)",
            borderColor: "rgb(255, 99, 132)",
            fill: false,
            tension: 0.1,
          },
        ];

        this.$set(this.chartdtArr, dateTime, []);

        this.$set(this.detailItem, dateTime, response.data.iot);
        this.$set(this.chartLiveData_.datasets, dateTime, datasets);
        this.$set(
          this.chartLiveData_.label,
          dateTime,
          response.data.iot.label[0]
        );
        this.$set(this.pageItem_, dateTime, 1);
        console.log(this.pageItem_);
        console.log(this.detailItem);
        var ibu = this;
        setTimeout(function () {
          ibu.initChartdt(dateTime);
        }, 1000);
      } catch (e) {
        console.log(e);
      }
    },

    removeDetailOutput_(dateTime) {
      this.$delete(this.chartdtArr, dateTime);
      this.$delete(this.detailItem, dateTime);
      this.$delete(this.chartLiveData_.datasets, dateTime);
      this.$delete(this.chartLiveData_.label, dateTime);
      this.$delete(this.pageItem_, dateTime);
    },

    removeDetailWeather_(dateTime) {

      this.$delete(this.detailWeather, dateTime);

    },


    initChartdt(dateTime) {
      const ctdt = document.getElementById(
        "chartItem" + dateTime.split(" ").join("_")
      );


      this.chartdtArr[dateTime] = new Chart(ctdt, {
        type: "line",
        data: {
          labels: this.detailItem[dateTime].label[0],
          datasets: [
            {
              label: "Port",
              data: this.detailItem[dateTime].data[0][0],
              backgroundColor: "rgb(17,130,59, 0.1)",
              borderColor: "rgb(17,130,59)",
              fill: false,
              tension: 0.1,
            },
            {
              label: "Starboard",
              data: this.detailItem[dateTime].data[1][0],
              backgroundColor: "rgb(255, 99, 132, 0.1)",
              borderColor: "rgb(255, 99, 132)",
              fill: false,
              tension: 0.1,
            },
          ],
        },

        options: {
          interaction: {
            mode: "index",
            intersect: false,
          },
        }
      });

    },

    rpmAddDTchart(chart, label, data) {
      chart.data.labels = [];
      chart.data.datasets = [];
      chart.data.labels = label;
      chart.data.datasets = data;
      chart.update();
    },

    changeChartDT(dateTime) {
      console.log("PAGINATION DI CLICK");
      console.log(this.pageItem_[dateTime]);
      var datas = [
        {
          label: "Port",
          data: this.detailItem[dateTime].data[0][this.pageItem_[dateTime] - 1],
          backgroundColor: "rgb(17,130,59, 0.1)",
          borderColor: "rgb(17,130,59)",
          fill: false,
          tension: 0.1,
        },
        {
          label: "Starboard",
          data: this.detailItem[dateTime].data[1][this.pageItem_[dateTime] - 1],
          backgroundColor: "rgb(255, 99, 132, 0.1)",
          borderColor: "rgb(255, 99, 132)",
          fill: false,
          tension: 0.1,
        },
      ];
      this.rpmAddDTchart(
        this.chartdtArr[dateTime],
        this.detailItem[dateTime].label[this.pageItem_[dateTime] - 1],
        datas
      );

      console.log(this.chartLiveData_);
    },

    //moving marker
    initialdata_moving() {
      var mother = this;
      mother.drivePath = mother.rpmData.tracking.slice();
      mother.driveLatLng = L.latLng([
        mother.drivePath[0].lat,
        mother.drivePath[0].lng,
      ]);
      mother.driveRotationAngle = mother.drivePath[0].heading;
    },

    //moving marker
    newAnimation() {
      this.drivePath = null;
      clearInterval(this.IntervalMoving);
      this.initialdata_moving();
    },

    //moving marker
    movingStart() {
      var mother = this;
      this.IntervalMoving = setInterval(function () {
        mother.simulate();
      }, 350);
    },

    simulate() {
      this.countLength = this.countLength + 1;
      // console.log(this.countLength)
      // console.log(this.drivePath)
      // console.log(this.rpmData.tracking.length)

      if (!this.drivePath.length) {
        this.$refs.driveMarker.mapObject.setLatLng(
          L.latLng([this.drivePath[0].lat, this.drivePath[0].lng])
        );
        this.drivePath = this.drivePath.slice();
        return;
      }
      let point = this.drivePath.shift();
      this.driveLatLng = L.latLng([point.lat, point.lng]);
      this.driveRotationAngle = point.heading;
      // console.log(this.driveRotationAngle)

      if (this.drivePath.length == 0 || !this.drivePath) {
        clearInterval(this.IntervalMoving);
        this.drivePath = null;
      }
    },

    logReady() {
      this.$refs.myMarkerMap.mapObject.fire("click");
    },

    triggerFullscreen() {
      if (this.$refs["map"].mapObject._isFullscreen != true) {
        this.$refs.map.mapObject.scrollWheelZoom.disable();
      } else {
        this.$refs.map.mapObject.scrollWheelZoom.enable();
      }
    },

    triggerFullscreenModal() {
      if (this.$refs["mapmodal"].mapObject._isFullscreen != true) {
        this.$refs.mapmodal.mapObject.scrollWheelZoom.disable();
      } else {
        this.$refs.mapmodal.mapObject.scrollWheelZoom.enable();
      }
    },

    isLiveFunc() {
      console.log("RANGE YANG DI PRINT");
      console.log(this.rpmData.range.range);

      this.IntervalLive = setInterval(() => {
        var data = {
          latestDateTime: this.dateTimeLive,
          dev_id: this.rpmData.dev_id,
          token: localStorage.getItem("token"),
        };
        // console.log('INTERVAL LIVE')
        // console.log(this.dateTimeLive)
        this.fetchRpmLive(data);
        this.pushChartLive();
      }, 1000);

      this.timeoutInterval = this.rpmInterval * 60 * 1000;
      //this.timeoutInterval =  1000;

      if (this.rpmData.range.range.toLowerCase() == "today" || this.rpmData.range.range.toLowerCase() == "last 24 hour") {
        this.dataTableTimeout = setTimeout(() => {
          this.rpmUpdateTable();
        }, this.timeoutInterval);
      }
    },

    rpmUpdateTable() {
      this.timeoutInterval = this.rpmInterval * 60 * 1000;
      //this.timeoutInterval =  1000;

      const data_dt = {
        latestDateTimeDT: this.dateTimeTable,
        dev_id: this.rpmData.dev_id,
        token: localStorage.getItem("token"),
      };


      this.fetchRpmLiveTable(data_dt);
      this.pushDTLive();

      if (this.rpmData.range.range.toLowerCase() == "today" || this.rpmData.range.range.toLowerCase() == "last 24 hour") {
        this.dataTableTimeout = setTimeout(() => {
          this.rpmUpdateTable();
        }, this.timeoutInterval);
      }
    },

    pushDTLive() {
      console.log('PUSH DATA LIVE')
      console.log(this.rpmUpdate)
      console.log(this.rpmData.range.range.toLowerCase())
      var mama = this;
      // if (this.rpmUpdate && this.rpmUpdate.update == true) {
      //   var dataOutput = {
      //     id: this.rpmUpdate.id,
      //     rawData:
      //       '{"data":"RPM\\/RPI-AT 10003 1102\\/1200;1105\\/1205;1100\\/1200;1110\\/1210;5;1110\\/1210;20230310073620;10120;5\\/5 2023-03-20 10:47:22 0529.6658 S 10518.5044 E"}',
      //     dev_id: this.rpmUpdate.dev_id,
      //     content: this.rpmUpdate.content,
      //     current: this.rpmUpdate.current,
      //     avg: this.rpmUpdate.avg,
      //     min: this.rpmUpdate.min,
      //     max: this.rpmUpdate.max,
      //     interval: this.rpmUpdate.interval,
      //     max_all: this.rpmUpdate.max_all,
      //     up_since: this.rpmUpdate.up_since,
      //     uptime: this.rpmUpdate.uptime,
      //     w_hour: this.rpmUpdate.w_hour,
      //     latitude: this.rpmUpdate.latitude,
      //     longitude: this.rpmUpdate.longitude,
      //     heading: this.rpmUpdate.heading,
      //     distance: this.rpmUpdate.distance,
      //     speed: this.rpmUpdate.speed,
      //     dateTime: this.rpmUpdate.dateTime,
      //     dateTime_local: this.rpmUpdate.dateTime_local,
      //     created_at: this.rpmUpdate.created_at,
      //     updated_at: this.rpmUpdate.updated_at,
      //     showMap: this.rpmUpdate.showMap,
      //     index: 0,
      //   };

      //   this.rpmData.output.unshift(dataOutput);
      //   // this.draw_Circle();
      //   this.rpmData.chart.label.push(this.rpmUpdate.chart.dateTime_label);

      //   for (var i = 0; i < this.rpmData.chart.datasets[0].length; i++) {
      //     if (this.rpmData.chart.datasets[0][i]["label"] == "Current") {
      //       this.rpmData.chart.datasets[0][i]["data"].push(
      //         mama.rpmUpdate.chart.current[0]
      //       );
      //     }

      //     if (this.rpmData.chart.datasets[0][i]["label"] == "Max") {
      //       this.rpmData.chart.datasets[0][i]["data"].push(
      //         mama.rpmUpdate.chart.max[0]
      //       );
      //     }

      //     if (this.rpmData.chart.datasets[0][i]["label"] == "Min") {
      //       this.rpmData.chart.datasets[0][i]["data"].push(
      //         mama.rpmUpdate.chart.min[0]
      //       );
      //     }

      //     if (this.rpmData.chart.datasets[0][i]["label"] == "Average") {
      //       this.rpmData.chart.datasets[0][i]["data"].push(
      //         mama.rpmUpdate.chart.avg[0]
      //       );
      //     }
      //   }

      //   for (var i = 0; i < this.rpmData.chart.datasets[1].length; i++) {
      //     if (this.rpmData.chart.datasets[1][i]["label"] == "Current") {
      //       this.rpmData.chart.datasets[1][i]["data"].push(
      //         mama.rpmUpdate.chart.current[1]
      //       );
      //     }

      //     if (this.rpmData.chart.datasets[1][i]["label"] == "Max") {
      //       this.rpmData.chart.datasets[1][i]["data"].push(
      //         mama.rpmUpdate.chart.max[1]
      //       );
      //     }

      //     if (this.rpmData.chart.datasets[1][i]["label"] == "Min") {
      //       this.rpmData.chart.datasets[1][i]["data"].push(
      //         mama.rpmUpdate.chart.min[1]
      //       );
      //     }

      //     if (this.rpmData.chart.datasets[1][i]["label"] == "Average") {
      //       this.rpmData.chart.datasets[1][i]["data"].push(
      //         mama.rpmUpdate.chart.avg[1]
      //       );
      //     }
      //   }
      //   console.log('RPM DATA OUTPUT')
      //   console.log(this.rpmData.output)
      //   this.addrpmOutput(this.rpmData.output);
      //   console.log(this.rpmData.chart.datasets[0][0]["data"]);
      // }
    },

    pushChartLive() {
      if (this.liveChart && this.liveChart.update == true) {
        console.log("JALAN TERUS");
        this.rpmAddDataLive(
          this.liveChart1,
          this.liveChart.dateTime,
          this.liveChart.current[0]
        );
        this.rpmAddDataLive(
          this.liveChart2,
          this.liveChart.dateTime,
          this.liveChart.current[1]
        );
      }
    },

    async weatherClick(id, msgid, type, dateTime) {
      // this.$delete(this.detailWeather, dateTime);
      //console.log(this.weatherData);

      // this.$set(this.showclick_dateTime, dateTime, dateTime);
      const datawth = {
        id: id,
        msg_id: msgid,
        type: type,
      };
      const response = await axios.post(
        "https://navigatorplus.sailink.id/api/v1/navplus/weather/fetchRow",
        datawth,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      console.log(response);
      this.$set(this.detailWeather, dateTime, response.data.data);
      // this.$set(this.weatherData, dateTime, response.data.data);
      //console.log("weather datanya i fetch baru");
      //console.log(this.weatherData);
    },

    rpmAddDataLive(chart, label, data) {
      chart.data.labels.push(label);
      chart.data.datasets.forEach((dataset) => {
        dataset.data.push(data);
      });

      chart.update();

      if (chart.data.datasets[0].data.length >= this.limitChart) {
        this.rpmRemoveData(chart);
      }
    },

    rpmRemoveData(chart) {
      chart.data.labels.shift();
      chart.data.datasets.forEach((dataset) => {
        dataset.data.shift();
      });
      chart.update();
    },

    triggerFullscreenEmpty() {
      if (this.$refs["mapempty"].mapObject._isFullscreen != true) {
        this.$refs.mapempty.mapObject.scrollWheelZoom.disable();
      } else {
        this.$refs.mapempty.mapObject.scrollWheelZoom.enable();
      }
    },

    intoFilter(pagination) {
      this.totalFilterOutput = pagination.itemsLength;
    },

    draw_Circle() {
      this.fetchDetailbtn_();
      var myMap = this.$refs.map.mapObject;
      // console.log(this.rpmOutput);

      if (this.isRpmLive) {
        this.isLiveFunc();
      } else {
        console.log("rpm biasa");
      }

      console.log('ISI RPM OUTPUT')
      console.log(this.rpmData.tracking)

      var data = [];
      for (var i in this.rpmData.tracking) {

        data.push({
          lat: this.rpmData.tracking[i].lat,
          lon: this.rpmData.tracking[i].lng,
          heading: this.rpmData.tracking[i].heading,
          speed: this.rpmData.tracking[i].speed,
          distance: this.rpmData.tracking[i].distance,
          current: this.rpmData.tracking[i].current,
          avg: this.rpmData.tracking[i].avg,
          min: this.rpmData.tracking[i].min,
          max: this.rpmData.tracking[i].max,
          w_hour: this.rpmData.tracking[i].w_hour,
          interval: this.rpmData.tracking[i].interval,
          weather: this.rpmData.tracking[i].weather,
          wind: this.rpmData.tracking[i].wind,
          wave: this.rpmData.tracking[i].wave,
          dateTime_local: this.rpmData.tracking[i].datetime,
        });

      }

      // console.log(data);

      var latLong = data;

      console.log('ISI DARI LATLONG')
      console.log(this.rpmData)
      var pointList = data;
      var mama = this;
      var firstpolyline = new L.Polyline(pointList, {
        color: "#FEE800",
        weight: 4,
        opacity: 1,
        smoothFactor: 1,
      });
      firstpolyline.addTo(myMap);
      // Here's where you iterate over the array of coordinate objects.
      latLong.forEach(function (coord) {

        if (
          coord.lat != mama.rpmMaptop.lat &&
          coord.lng != mama.rpmMaptop.lon
        ) {
          var circle = L.circle(coord, {
            color: "#1DC01D",
            fillColor: "#1DC01D",
            fillOpacity: 0.5,
            radius: 1800,
          })
            .addTo(myMap)
            .bindPopup(
              mama.returnPopup(coord)
            );
        }
      });
      var mom = this;
      this.newAnimation();
      setTimeout(function () {
        mom.movingStart();
      }, 1000);

      if (this.isRpmLive) {
        const ctx = document.getElementById("chartLive1");
        const cty = document.getElementById("chartLive2");

        this.liveChart1 = new Chart(ctx, {
          type: "line",
          data: {
            labels: [],
            datasets: [
              {
                label: "Live Current",
                data: [],
                backgroundColor: "rgb(17,130,59, 0.1)",
                borderColor: "rgb(17,130,59)",
                fill: false,
                tension: 0.1,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: [],
              },
            },
            scales: {
              y: {
                title: {
                  display: true,
                  text: "RPM",
                },
              },
            },

            interaction: {
              mode: "index",
              intersect: false,
            },
          },
        });

        this.liveChart2 = new Chart(cty, {
          type: "line",
          data: {
            labels: [],
            datasets: [
              {
                label: "Live Current",
                data: [],
                backgroundColor: "rgb(17,130,59, 0.1)",
                borderColor: "rgb(17,130,59)",
                fill: false,
                tension: 0.1,
              },
            ],
          },
          options: {
            responsive: true,
            maintainAspectRatio: false,
            plugins: {
              legend: {
                position: "top",
              },
              title: {
                display: true,
                text: [],
              },
            },
            scales: {
              y: {
                title: {
                  display: true,
                  text: "RPM",
                },
              },
            },

            interaction: {
              mode: "index",
              intersect: false,
            },
          },
        });
      }

    },

    returnPopup(coord) {
      if (this.rpmData.isWeather) {
        if (this.rpmData.multiple == true) {
          return (
            "<table>" +
            "<tr>" +
            "<td><b>Latitude</b></td><td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.lat +
            "</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Longitude</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.lon +
            "</td>" +
            "</tr>" +

            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td colspan='2' style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<td><b>Heading</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.heading +
            "&deg;</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Speed</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.speed +
            " Knots</td>" +
            "</tr>" +
            "<tr>" +
            " <td><b>Distance</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.distance +
            " Nmi</td>" +
            "</tr>" +


            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td colspan='2' style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<tr>" +
            "<td></td>" +
            "<td></td>" +
            "<td class='pl-3'><b>(P)</b> RPM1</td>" +
            "<td class='pl-3'><b>(S)</b> RPM2</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Current</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.current.split("/")[0] +
            " RPM</td>" +
            "<td class='pl-3'>" +
            coord.current.split("/")[1] +
            " RPM</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Average</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.avg.split("/")[0] +
            " RPM</td>" +
            "<td class='pl-3'>" +
            coord.avg.split("/")[1] +
            " RPM</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Min</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.min.split("/")[0] +
            " RPM</td>" +
            "<td class='pl-3'>" +
            coord.min.split("/")[1] +
            " RPM</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Max</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.max.split("/")[0] +
            " RPM" +
            "</td>" +
            "<td class='pl-3'>" +
            +coord.max.split("/")[1] +
            " RPM" +
            "</td>" +
            "</tr>" +
            "<tr>" +
            " <td ><b>Uptime</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            +coord.w_hour.split("/")[0] +
            " Minutes</td>" +
            "<td class='pl-3'>" +
            coord.w_hour.split("/")[1] +
            " Minutes</td>" +
            "</tr>" +
            "<tr>" +


            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td colspan='2' style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<td><b>Interval</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.interval +
            " Minutes </td>" +
            "</tr>" +


            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td colspan='2' style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<td><b>Weather</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.weather +
            "</td>" +
            "</tr>" +
            "<td><b>Wind</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.wind +
            "</td>" +
            "</tr>" +
            "<td><b>Waves</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.wave +
            "</td>" +
            "</tr>" +

            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td colspan='2' style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +


            "<tr>" +
            "<td><b>Updated</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.dateTime_local +
            "</td>" +
            "</tr>" +
            "</table>"


          )
        } else {
          return (
            "<table>" +
            "<tr>" +
            "<td><b>Latitude</b></td><td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.lat +
            "</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Longitude</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td  class='pl-3'>" +
            coord.lon +
            "</td>" +
            "</tr>" +

            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td  style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<td><b>Heading</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td  class='pl-3'>" +
            coord.heading +
            "&deg;</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Speed</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td  class='pl-3'>" +
            coord.speed +
            " Knots</td>" +
            "</tr>" +
            "<tr>" +
            " <td><b>Distance</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td  class='pl-3'>" +
            coord.distance +
            " Nmi</td>" +
            "</tr>" +


            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +


            "<tr>" +
            "<td><b>Current</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.current +
            " RPM</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Average</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.avg +
            " RPM</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Min</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.min +
            " RPM</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Max</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.max +
            " RPM" +
            "</td>" +
            "</tr>" +
            "<tr>" +
            " <td ><b>Uptime</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            +coord.w_hour +
            " Minutes</td>" +
            "</tr>" +
            "<tr>" +


            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td  style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<td><b>Interval</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td  class='pl-3'>" +
            coord.interval +
            " Minutes </td>" +
            "</tr>" +


            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td  style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<td><b>Weather</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.weather +
            "</td>" +
            "</tr>" +
            "<td><b>Wind</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.wind +
            "</td>" +
            "</tr>" +
            "<td><b>Waves</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.wave +
            "</td>" +
            "</tr>" +

            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td colspan='2' style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<tr>" +
            "<td><b>Updated</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td  class='pl-3'>" +
            coord.dateTime_local +
            "</td>" +
            "</tr>" +
            "</table>"
          )
        }
      } else {
        if (this.rpmData.multiple == true) {
          return (
            "<table>" +
            "<tr>" +
            "<td><b>Latitude</b></td><td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.lat +
            "</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Longitude</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.lon +
            "</td>" +
            "</tr>" +

            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td colspan='2' style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<td><b>Heading</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.heading +
            "&deg;</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Speed</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.speed +
            " Knots</td>" +
            "</tr>" +
            "<tr>" +
            " <td><b>Distance</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.distance +
            " Nmi</td>" +
            "</tr>" +


            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td colspan='2' style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<tr>" +
            "<td></td>" +
            "<td></td>" +
            "<td class='pl-3'><b>(P)</b> RPM1</td>" +
            "<td class='pl-3'><b>(S)</b> RPM2</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Current</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.current.split("/")[0] +
            " RPM</td>" +
            "<td class='pl-3'>" +
            coord.current.split("/")[1] +
            " RPM</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Average</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.avg.split("/")[0] +
            " RPM</td>" +
            "<td class='pl-3'>" +
            coord.avg.split("/")[1] +
            " RPM</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Min</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.min.split("/")[0] +
            " RPM</td>" +
            "<td class='pl-3'>" +
            coord.min.split("/")[1] +
            " RPM</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Max</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.max.split("/")[0] +
            " RPM" +
            "</td>" +
            "<td class='pl-3'>" +
            +coord.max.split("/")[1] +
            " RPM" +
            "</td>" +
            "</tr>" +
            "<tr>" +
            " <td ><b>Uptime</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            +coord.w_hour.split("/")[0] +
            " Minutes</td>" +
            "<td class='pl-3'>" +
            coord.w_hour.split("/")[1] +
            " Minutes</td>" +
            "</tr>" +
            "<tr>" +


            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td colspan='2' style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<td><b>Interval</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.interval +
            " Minutes </td>" +
            "</tr>" +


            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td colspan='2' style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<tr>" +
            "<td><b>Updated</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td colspan='2' class='pl-3'>" +
            coord.dateTime_local +
            "</td>" +
            "</tr>" +
            "</table>"


          )
        } else {
          return (
            "<table>" +
            "<tr>" +
            "<td><b>Latitude</b></td><td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.lat +
            "</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Longitude</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td  class='pl-3'>" +
            coord.lon +
            "</td>" +
            "</tr>" +

            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td  style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<td><b>Heading</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td  class='pl-3'>" +
            coord.heading +
            "&deg;</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Speed</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td  class='pl-3'>" +
            coord.speed +
            " Knots</td>" +
            "</tr>" +
            "<tr>" +
            " <td><b>Distance</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td  class='pl-3'>" +
            coord.distance +
            " Nmi</td>" +
            "</tr>" +


            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +


            "<tr>" +
            "<td><b>Current</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.current +
            " RPM</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Average</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.avg +
            " RPM</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Min</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.min +
            " RPM</td>" +
            "</tr>" +
            "<tr>" +
            "<td><b>Max</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            coord.max +
            " RPM" +
            "</td>" +
            "</tr>" +
            "<tr>" +
            " <td ><b>Uptime</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td class='pl-3'>" +
            +coord.w_hour +
            " Minutes</td>" +
            "</tr>" +
            "<tr>" +


            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td  style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<td><b>Interval</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td  class='pl-3'>" +
            coord.interval +
            " Minutes </td>" +
            "</tr>" +


            "<tr>" +
            "<tr>" +
            "<td></td>" +
            "<td class='pl-3'></td>" +
            "<td  style='height:1em' class='pl-3'>" +
            "</td>" +
            "</tr>" +

            "<tr>" +
            "<td><b>Updated</b></td>" +
            "<td class='pl-3'>:</td>" +
            "<td  class='pl-3'>" +
            coord.dateTime_local +
            "</td>" +
            "</tr>" +
            "</table>"
          )
        }
      }
    },

    clearLiveRpm() {
      console.log("STOP INTERVAL LIVE");
      clearInterval(this.IntervalLive);
    },

    clearMovingMarker() {
      console.log("STOP INTERVAL MOVING");
      clearInterval(this.IntervalMoving);
    },

    closeRpmShow() {
      this.inputRPMID(null);
      this.clearLiveRpm();
      this.clearMovingMarker();
      clearTimeout(this.dataTableTimeout);

      $("html, body").animate(
        {
          scrollTop: $("#top_iot").offset().top - 140,
        },
        800
      );
      this.removeRpmShow();
      this.removeSearchIOT();
      this.addrpm_executed(false);
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    // customRange() {
    //   this.dateopen = false;
    //   var range_date = this.dates.join(" ");
    //   console.log(range_date);
    //   this.sendDataRPM(this.datepickerID, range_date);
    // },
    logtest(date) {
      console.log('DI CLICK')
      console.log(date)
      this.testdisabled = true;
      this.timeopen = true;
    },

    saveTimes() {
      this.times.push(this.timein);
      this.timein = null;
      this.timeopen = false;
      this.testdisabled = false;
    },

    customRange() {

var tglcurrent = new Date();
var tgldate1 = new Date(this.dates[0] + ' ' + this.times[0] + ':00');
var tgldate2 = new Date(this.dates[1] + ' ' + this.times[1] + ':00');



if (tgldate1.getTime() <= tglcurrent.getTime() && tgldate2.getTime() <= tglcurrent.getTime()) {
  if (tgldate1 > tgldate2) {
    this.cant_backwards = true
    this.resetTimesDates();
    setTimeout(() => {
      this.cant_backwards = false
    }, 5000)
  } else {
    this.dateopen = false;
    this.cant_future = false
    // var range_date = this.dates.join(" ");
    var range1 = this.dates[0].split('-');
    var range1_ = range1.join('_');

    var range2 = this.dates[1].split('-');
    var range2_ = range2.join('_');

    var timerange1 = this.times[0].split(':')
    var timerange1_ = timerange1.join('m')

    var timerange2 = this.times[1].split(':')
    var timerange2_ = timerange2.join('m')

    var range_date = range1_ + ' ' + timerange1_ + ' - ' + range2_ + ' ' + timerange2_;
    console.log(range_date);
    this.resetTimesDates();
    this.sendDataRPM(this.datepickerID, range_date);
  }

} else {
  this.cant_future = true
  this.resetTimesDates();
  setTimeout(() => {
    this.cant_future = false
  }, 5000)
}

},

closeCostumRange() {
this.dateopen = false
this.resetTimesDates();
},

resetTimesDates() {
console.log('di reset')
this.dates = []
this.times = []
},

    sendDataRPM(id, range) {
      this.exp_range = range;
      console.log("JALANIN INI rpm nya");
      this.removeRpmShow();
      console.log(id);
      const data = {
        id: id,
        range: range,
        token: localStorage.getItem("token"),
      };
      this.fetchRpm(data);
      // var mother = this;
      // var intervalSeconds = 0.5;
      // this.fetchInterval = setInterval(function () {
      //   mother.initialdata_moving();
      // }, intervalSeconds);
    },

    createPDF() {
      // let pdfName = parts[0] + "_" + parts[1] + "_Tracking_Reports";
      let pdfName = "test";
      var doc = new jsPDF({
        orientation: "l",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
      });

      doc.setProperties({
        title: pdfName,
        subject: "Its a Subject",
        author: "Me!",
        creator: "My Company Name",
      });

      var output = this.rpmOutput;

      let info = [];
      output.forEach((element) => {
        info.push([
          [element.index + 1],
          [element.current],
          [element.avg],
          [element.speed],
          [element.min],
          [element.max],
          [element.w_hour],
          [element.latitude],
          [element.longitude],
          [element.heading],
          [element.speed],
          [element.distance],
          [element.dateTime_Local],
        ]);
      });

      console.log(info);
      doc.text("RPM Report", 15, 5);
      doc.text(this.rpmData.vessel_number, 15, 10, { fontSize: 9 });

      autoTable(doc, {
        head: [
          [
            "#",
            "Current (RPM)",
            "Average (RPM)",
            "Min (RPM)",
            "Max (RPM)",
            "Interval (Minutes)",
            "Uptime (Minutes)",
            "Latitude, Longitude",
            "Heading (Degree)",
            "Speed (Knots)",
            "Distance (Nmi)",
            "dateTime(GMT+7)",
          ],
        ],
        body: info,
        theme: "striped",
      });

      doc.save(pdfName + ".pdf");
      // doc.output("dataurlnewwindow");
    },

    createCSV() {
      // console.log(this.showmaptable.vessel_pt);
      // var parts = this.showmaptable.vessel_pt.split(" ");
      // console.log(parts);

      // let pdfName = parts[0] + "_" + parts[1] + "_Tracking_Reports";

      let csvName = this.rpmData.vessel_number;

      var output = this.rpmOutput;
      let data = [];

      output.forEach((element) => {
        data.push([
          [element.index + 1],
          [element.current],
          [element.avg],
          [element.speed],
          [element.min],
          [element.max],
          [element.w_hour],
          [element.latitude],
          [element.longitude],
          [element.heading],
          [element.speed],
          [element.distance],
          [element.dateTime_Local],
        ]);
      });

      let csvContent =
        "data:text/csv;charset=utf-8," +
        data.map((e) => e.join(",")).join("\n");

      var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", csvName + ".csv");
      document.body.appendChild(link);
      link.click();
    },

    detailRPM() {
      if (this.$refs.myMarker) {
        this.$refs.myMarker.mapObject.fire("click");
      } else {
        console.log("No Marker");
      }
    },

    detailRPMMAP(lat, lon, data) {
      console.log("Open Popup Map");
      this.dmap = true;
      this.dmaplat = lat;
      this.dmaplon = lon;
      console.log(data);
      this.dmapdata = {
        rotation: data.heading,
        speed: data.speed,
        distance: data.distance,
        current: data.current,
        avg: data.avg,
        min: data.min,
        max: data.max,
        w_hour: data.w_hour,
        interval: data.interval,
        dateTime_local: data.dateTime_local,
        weather: data.weather,
        wind: data.wind,
        wave: data.wave
      };
      console.log("Latitude,Longitude Detail Map");
      console.log(this.dmaplat);
      console.log(this.dmaplon);

      //     this.$nextTick(() => {
      //       this.$refs.myMarkerMap.mapObject.fire('click');
      //  })
    },
  },
  computed: {
    ...mapGetters([
      "rpmDetailbtn",
      "rpmData",
      "rpmShow",
      "rpmOutput",
      "rpmID",
      "rpm_executed",
      "isRpmLive",
      "dateTimeLive",
      "liveChart",
      "dateTimeTable",
      "rpmUpdate",
      "rpmInterval",
      "rpmMaptop",
    ]),
    mapConfig() {
      return {
        ...mapSettingsShow,
        center: this.mapCenter,
      };
    },

    inchartLiveData_() {
      return this.chartLiveData_;
    },

    showingDatafromOutput() {
      if (this.totalFilterOutput < 1) {
        return 0;
      } else {
        return 1;
      }
    },

    showingDatatoOutput() {
      if (this.totalFilterOutput < this.itemsPerPageOutput) {
        return this.totalFilterOutput;
      } else {
        return this.itemsPerPageOutput;
      }
    },



    portTime_value() {
      var port = this.rpmOutput;

      console.log("WORKING HOUR");
      let port_minutes = [];
      port.forEach((element) => {
        port_minutes.push([[element.w_hour.split("/")[0]]]);
      });
      console.log(port_minutes);
      console.log(port_minutes.reduce((a, b) => parseInt(a) + parseInt(b), 0));

      var total_port = port_minutes.reduce(
        (a, b) => parseInt(a) + parseInt(b),
        0
      );
      var minutes = total_port;
      var uptime = "";
      var th = Math.floor(minutes / 60);
      if (minutes < 60) {
        uptime = total_port + " Minutes";
      } else if (minutes >= 60 && minutes < 1440) {
        let m = minutes % 60;
        uptime = th + " Hours " + m + " Minutes";
      } else if (minutes >= 1440 && minutes < 43200) {
        let d = Math.floor(minutes / 1440);
        let h = Math.floor((minutes % 1440) / 60);
        let m = minutes % 60;
        uptime =
          d + " Days " + h + " Hours " + m + " Minutes (" + th + " Hours)";
      } else if (minutes > 43200) {
        let mo = Math.floor(minutes / 43200);
        let d = Math.floor((minutes % 43200) / 1440);
        let h = Math.floor((minutes % 1440) / 60);
        let m = minutes % 60;
        uptime =
          mo +
          " Months " +
          d +
          " Days " +
          h +
          " Hours " +
          m +
          " Minutes (" +
          th +
          " Hours)";
      }

      console.log(uptime);
      this.time_Uptime = uptime;
      console.log(this.time_Uptime);
      return uptime;
    },

    mapCenter() {
      return {
        lat: parseFloat(this.rpmMaptop.lat),
        lng: parseFloat(this.rpmMaptop.lon),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.rpmLiveChart {
  max-height: 250px;
  display: block;
  box-sizing: border-box;
  height: 250px;
  margin-top: -1.5em;
}

.rpmDetailChart {
  max-height: 250px;
  display: block;
  box-sizing: border-box;
  height: 250px;
}

@media (min-width: 280px) and (max-width: 319px) {
  .hederrpm_desktop {
    display: none;
  }

  .hederrpm_mobile {
    display: block;
  }

  .toolbar_rpmbobile {
    display: block;
  }

  .toolbar_rpmdesktop {
    display: none;
  }

  .exportrpm_mobile {
    display: block;
  }

  .exportrpm_desktop {
    display: none;
  }

  .output_mobile {
    display: block;
  }

  .output_desktop {
    display: none;
  }
}

/* Mobile Devices */
@media (min-width: 320px) and (max-width: 480px) {
  .hederrpm_desktop {
    display: none;
  }

  .hederrpm_mobile {
    display: block;
  }

  .toolbar_rpmbobile {
    display: block;
  }

  .toolbar_rpmdesktop {
    display: none;
  }

  .exportrpm_mobile {
    display: block;
  }

  .exportrpm_desktop {
    display: none;
  }

  .output_mobile {
    display: block;
  }

  .output_desktop {
    display: none;
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .hederrpm_desktop {
    display: block;
  }

  .hederrpm_mobile {
    display: none;
  }

  .exportrpm_mobile {
    display: none;
  }

  .exportrpm_desktop {
    display: block;
  }

  .output_mobile {
    display: none;
  }

  .output_desktop {
    display: block;
  }
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .hederrpm_desktop {
    display: block;
  }

  .hederrpm_mobile {
    display: none;
  }

  .toolbar_rpmbobile {
    display: none;
  }

  .toolbar_rpmdesktop {
    display: block;
  }

  .exportrpm_mobile {
    display: none;
  }

  .exportrpm_desktop {
    display: block;
  }

  .output_mobile {
    display: none;
  }

  .output_desktop {
    display: block;
  }
}

/* Desktops, large screens */
@media (min-width: 1025px) and (max-width: 1200px) {
  .hederrpm_desktop {
    display: block;
  }

  .hederrpm_mobile {
    display: none;
  }

  .toolbar_rpmbobile {
    display: none;
  }

  .toolbar_rpmdesktop {
    display: block;
  }

  .exportrpm_mobile {
    display: none;
  }

  .exportrpm_desktop {
    display: block;
  }

  .output_mobile {
    display: none;
  }

  .output_desktop {
    display: block;
  }
}

/* Extra large screens / TV */
@media screen and (min-width: 1201px) {
  .hederrpm_desktop {
    display: block;
  }

  .hederrpm_mobile {
    display: none;
  }

  .toolbar_rpmbobile {
    display: none;
  }

  .toolbar_rpmdesktop {
    display: block;
  }

  .exportrpm_mobile {
    display: none;
  }

  .exportrpm_desktop {
    display: block;
  }

  .output_mobile {
    display: none;
  }

  .output_desktop {
    display: block;
  }
}
</style>
