import axios from "../../plugins/axios";

const state = {
    crewwatch_data: null,
    listcrew: null,
    detailcrew: null,
    OffCrew:null,
};

const getters = {
    crewwatchData: (state) => state.crewwatch_data,
    listcrew: (state) => state.listcrew,
    detailcrew: (state) => state.detailcrew,
    OffCrew:(state) => state.OffCrew
};

const actions = {
    async fetchCrewwatch({commit}, data) {
       try{
        const response = await axios.get("/api/v1/navplus/crew", {
            headers: {
                Authorization: "Bearer" + data,
            },
        });
        //console.log(response);
        commit("setCrewwatch", response.data.data.vessels.map((item, index) =>
            Object.assign(item, {index})
        ));
        commit("setOffCrew",null)
       }catch(e){
        commit("setOffCrew",true)
       }
    },

    async showCrewData({commit}, data) {
        //console.log(data)
        const datacrew = {
            id: data.id,
        };
        const response = await axios.post("/api/v1/navplus/crew/list", datacrew, {
            headers: {
                Authorization: "Bearer" + data.token,
            },
        });
        //console.log(response);
        commit("setlistcrew", response.data.data);
    },

    async showCrewDetail({commit}, data) {
        //console.log(data)
        commit("setdetailcrew", null);
        const datacrew = {
            id: data.id,
            range: 'today',
        };
        const response = await axios.post("/api/v1/navplus/crew/detail", datacrew, {
            headers: {
                Authorization: "Bearer" + data.token,
            },
        });
        //console.log(response);
        //console.log(commit);
        commit("setdetailcrew", response.data.data);
        // commit("setlistcrew", response.data.data);
    },

    removeShowCrew({commit}) {
        commit("setlistcrew", null),
            commit("setdetailcrew", null)
    },
};

const mutations = {
    setOffCrew:(state,OffCrew) => (state.OffCrew = OffCrew),
    setCrewwatch: (state, crewwatch) => (state.crewwatch_data = crewwatch),
    setlistcrew: (state, listcrew) => (state.listcrew = listcrew),
    setdetailcrew: (state, detailcrew) => (state.detailcrew = detailcrew),
};

export default {
    state,
    getters,
    actions,
    mutations,
};
