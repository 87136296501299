import axios from "../../plugins/axios";
// import Vue from 'vue'

const getDefaultState = () => {
  return {
    OffIot: null,
    iot_data: null,
    iotData_: null,
    iotShow_: null,
    rpmData: null,
    rpmShow: null,
    rpmOutput: null,
    iotOutput: null,
    rpmID: null,
    iotID: null,
    searchIOT: "",
    port_time: "",
    rpm_executed: false,
    iot_executed: false,
    exh_executed: false,
    isRpmLive: null,
    dateTimeLive: null,
    dateTimeTable: null,
    liveChart: null,
    rpmUpdate: null,
    rpmInterval: null,
    rpmMaptop: null,
    iotMaptop: null,
    exhData: null,
    exhShow: null,
    exhOutput: null,
    exhID: null,
    exhMaptop: null,
    rpmDetailbtn: null,
    rbsOpen: null,
    rbsData: null,
    rbsID: null,
    rbs_output: null,
  };
};

const state = getDefaultState();

const getters = {
  OffIot: (state) => state.OffIot,
  iotData: (state) => state.iot_data,
  rpmData: (state) => state.rpmData,
  rpmShow: (state) => state.rpmShow,
  rpmOutput: (state) => state.rpmOutput,
  searchIOT: (state) => state.searchIOT,
  rpmID: (state) => state.rpmID,
  iotID: (state) => state.iotID,
  port_time: (state) => state.port_time,
  rpm_executed: (state) => state.rpm_executed,
  iot_executed: (state) => state.iot_executed,
  exh_executed: (state) => state.exh_executed,
  isRpmLive: (state) => state.isRpmLive,
  dateTimeLive: (state) => state.dateTimeLive,
  dateTimeTable: (state) => state.dateTimeTable,
  liveChart: (state) => state.liveChart,
  rpmUpdate: (state) => state.rpmUpdate,
  rpmInterval: (state) => state.rpmInterval,
  rpmMaptop: (state) => state.rpmMaptop,
  iotData_: (state) => state.iotData_,
  iotShow_: (state) => state.iotShow_,
  iotOutput: (state) => state.iotOutput,
  iotMaptop: (state) => state.iotMaptop,
  exhData: (state) => state.exhData,
  exhShow: (state) => state.exhShow,
  exhOutput: (state) => state.exhOutput,
  exhID: (state) => state.exhID,
  exhMaptop: (state) => state.exhMaptop,
  rpmDetailbtn: (state) => state.rpmDetailbtn,
  rbsOpen: (state) => state.rbsOpen,
  rbsData: (state) => state.rbsData,
  rbsID: (state) => state.rbsID,
  rbs_output: (state) => state.rbs_output,
};

const actions = {
  async fetchIot({ commit }, data) {
    try {
      const response = await axios.get("/api/v1/navplus/iot", {
        headers: {
          Authorization: "Bearer" + data,
        },
      });
      //console.log(response);

      function rpmFunction(p1, p2, p3, p4) {
        if (p1 == true || p2 == true || p3 == true) {
          if (p1) {
            return "rpm report";
          } else if (p2) {
            return "iot report";
          } else if (p3) {
            return "rpm report";
          } else if (p4) {
            return "exhaust report";
          }
        } else {
          return "";
        }
      }

      var iot_data = response.data.data.vessels.map((p) => ({
        ...p,
        rpm_search: rpmFunction(
          p.devices.rpm,
          p.devices.iot,
          p.devices.rpm_live,
          p.devices.exh
        ),
      }));
      //console.log(iot_data);

      commit(
        "setIot",
        iot_data.map((item, index) => Object.assign(item, { index }))
      );
      commit("setOffIot", null);
    } catch (e) {
      commit("setOffIot", true);
    }
  },

  async fetchExhaustShow({ commit }, data) {
    commit("setexhShow", true);
    commit("setexhID", null);
    commit("setexhID", data.id);
    const dataIOT = {
      id: data.id,
      range: data.range,
    };
    const response = await axios.post("/api/v1/navplus/iot/exh", dataIOT, {
      headers: {
        Authorization: "Bearer" + data.token,
      },
    });
    console.log(response);
    commit("setexhData", response.data.data);
    commit("setexhMaptop", response.data.data.maptop);
    commit(
      "setexhOutput",
      response.data.data.output.map((item, index) =>
        Object.assign(item, { index })
      )
    );
  },

  async fetchIotShow({ commit }, data) {
    commit("setiotShow_", true);
    const dataIOT = {
      id: data.id,
      range: data.range,
    };
    const response = await axios.post("/api/v1/navplus/iot/iot", dataIOT, {
      headers: {
        Authorization: "Bearer" + data.token,
      },
    });
   
    commit("setiotData_", response.data.data);
    commit("setiotID", data.id);
    commit("setiotMaptop", response.data.data.maptop);
    commit(
      "setiotOutput",
      response.data.data.output.map((item, index) =>
        Object.assign(item, { index })
      )
    );
    console.log(response);
    console.log(commit);
  },

  async fetchRpm({ commit }, data) {
    commit("setrpmshow", true);
    const dataRpm = {
      id: data.id,
      range: data.range,
    };
    const response = await axios.post("/api/v1/navplus/iot/rpm", dataRpm, {
      headers: {
        Authorization: "Bearer" + data.token,
      },
    });
    console.log(response.data.data);
    commit(
      "setrpmoutput",
      response.data.data.output.map((item, index) =>
        Object.assign(item, { index })
      )
    );
    commit("setrpmdata", response.data.data);
    commit("setdateTimeLive", response.data.data.dateTimeNow);
    commit("setdateTimeTable", response.data.data.dateTimeNow);
    commit("setrpmInterval", response.data.data.interval);
    commit("setrpmMaptop", response.data.data.maptop);
    commit("setrpmID", data.id);

    //console.log(commit);
  },

  rbsModal({ commit }, data) {
    commit("setrbsopen", data);
  },

  async fetchDetailbtn({ commit }, data) {
    console.log("MULAI DETAIL BTN");
    console.log(data);
    const dataDetail = {
      dateTimeArr: data.dateTimeArr,
      intervalArr: data.intervalArr,
      dev_id: data.dev_id,
    };
    try {
      const response = await axios.post(
        "/api/v1/navplus/iot/fetchRpmDetailBtn",
        dataDetail,
        {
          headers: {
            Authorization: "Bearer" + data.token,
          },
        }
      );
      console.log(response);
      commit("setrpmDetailbtn", response.data);
    } catch (e) {
      console.log(e);
    }
  },

  async fetchrpmOutputDetail({ commit }, data) {
    console.log("MASUK DETAIL ITEM");
    console.log(data);
    const dataDetail = {
      dev_id: data.dev_id,
      interval: data.interval,
      dateTime: data.dateTime,
    };
    try {
      const response = await axios.post(
        "/api/v1/navplus/iot/fetchRpmDetail",
        dataDetail,
        {
          headers: {
            Authorization: "Bearer" + data.token,
          },
        }
      );
      console.log(response);
      // commit("setrpmDetailbtn", response.data);
    } catch (e) {
      console.log(e);
    }
  },

  addrpmOutput({ commit }, data) {
    commit(
      "setrpmoutput",
      data.map((item, index) => Object.assign(item, { index }))
    );
  },

  async fetchRpmLive({ commit }, data) {
    const dataRpm = {
      latestDateTime: data.latestDateTime,
      dev_id: data.dev_id,
    };
    try {
      const response = await axios.post(
        "/api/v1/navplus/iot/getLatestRpm",
        dataRpm,
        {
          headers: {
            Authorization: "Bearer" + data.token,
          },
        }
      );

      // console.log("DATE TIME YANG DI KIRIM");
      // console.log(data.latestDateTime);
      // console.log("DATE TIME YANG DI TERIMA");
      // console.log(response.data.data.dateTime_local);
      commit("setliveChart", response.data.data);
      commit("setdateTimeLive", response.data.data.dateTime_local);
    } catch (e) {
      console.log("GAGAL FETCH");
    }

    // console.log(commit)
  },

  async fetchRpmLiveTable({ commit }, data) {
    console.log("LATEST DATE TIME DT CHECK RPM");
    console.log(data.latestDateTimeDT);
    const dataRpm = {
      latestDateTimeDT: data.latestDateTimeDT,
      dev_id: data.dev_id,
    };
    const response = await axios.post(
      "/api/v1/navplus/iot/checkRpmUpdate",
      dataRpm,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );

    console.log("FETCH LIVE TABLE");
    console.log(response);
    console.log(commit);
    commit("setrpmUpdate", response.data.data);
    commit("setrpmMaptop", response.data.data.maptop);
    commit("setrpmInterval", response.data.data.interval);
    commit("setdateTimeTable", response.data.data.dateTime_local);
  },

  addrpm_executed({ commit }, data) {
    commit("setrpm_executed", data);
  },

  addiot_executed({ commit }, data) {
    commit("setiot_executed", data);
  },

  addexh_executed({ commit }, data) {
    commit("setexh_executed", data);
  },

  removeRpmShow({ commit }) {
    commit("setrpmoutput", null);
    commit("setrpmdata", null);
    commit("setrpmshow", null);
    commit("setrpmID", null);
  },

  removeExhShow({ commit }) {
    commit("setexhOutput", null);
    commit("setexhData", null);
    commit("setexhShow", null);
    commit("setexhID", null);
    commit("setexhMaptop", null);
  },

  removeIotShow({ commit }) {
    commit("setiotData_", null);
    commit("setiotShow_", null);
    commit("setiotID", null);
    commit("setiotMaptop", null);
    commit("setiotOutput", null);
  },

  inputSearchIOT({ commit }, data) {
    commit("set_searchiot", data);
  },

  removeSearchIOT({ commit }) {
    commit("set_searchiot", null);
  },

  set_port_time({ commit }, data) {
    commit("setport_time", data);
  },

  resetIotState({ commit }) {
    commit("resetState");
  },

  addRpmLive({ commit }, data) {
    commit("setRpmLive", data);
  },

  rbsRemove({ commit }) {
    commit("setrbsopen", null);
    commit("setrbsData", null);
    commit("setrbs_output", null);
    // commit("setrbID", null);
  },

  async rbsFetch({ commit }, data) {
    commit("setrbsID", data.id);
    const response = await axios.get(
      "/api/v1/navplus/sailogger/" + data.id + "/reboot",
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    commit("setrbsData", response.data.data);
    commit(
      "setrbs_output",
      response.data.data.output.map((item, index) =>
        Object.assign(item, { index })
      )
    );
  },

  async rbsPost({ commit }, data) {
    console.log(data);
    const datarbs = {
      thuraya_number: data.thuraya_number,
    };
    console.log(datarbs);
    const response = await axios.post(
      "/api/v1/navplus/sailogger/reboot",
      datarbs,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log(response);
    console.log(commit);
    commit("setrbsData", response.data.data);
  },
};

const mutations = {
  setexhShow: (state, exhShow) => (state.exhShow = exhShow),
  setexhData: (state, exhData) => (state.exhData = exhData),
  setexhOutput: (state, exhOutput) => (state.exhOutput = exhOutput),
  setexhID: (state, exhID) => (state.exhID = exhID),
  setRpmLive: (state, isRpmLive) => (state.isRpmLive = isRpmLive),
  setIot: (state, iot) => (state.iot_data = iot),
  setrpmdata: (state, rpmData) => (state.rpmData = rpmData),
  setiotOutput: (state, iotOutput) => (state.iotOutput = iotOutput),
  setrpmshow: (state, rpmShow) => (state.rpmShow = rpmShow),
  setrpmoutput: (state, rpmOutput) => (state.rpmOutput = rpmOutput),
  set_searchiot: (state, searchIOT) => (state.searchIOT = searchIOT),
  setrpmID: (state, rpmID) => (state.rpmID = rpmID),
  setiotID: (state, iotID) => (state.iotID = iotID),
  setliveChart: (state, liveChart) => (state.liveChart = liveChart),
  setport_time: (state, port_time) => (state.port_time = port_time),
  setrpm_executed: (state, rpm_executed) => (state.rpm_executed = rpm_executed),
  setdateTimeLive: (state, dateTimeLive) => (state.dateTimeLive = dateTimeLive),
  setexh_executed: (state, exh_executed) => (state.exh_executed = exh_executed),
  setexhMaptop: (state, exhMaptop) => (state.exhMaptop = exhMaptop),
  setrpmDetailbtn: (state, rpmDetailbtn) => (state.rpmDetailbtn = rpmDetailbtn),
  setdateTimeTable: (state, dateTimeTable) =>
    (state.dateTimeTable = dateTimeTable),
  setrpmInterval: (state, rpmInterval) => (state.rpmInterval = rpmInterval),
  setrpmUpdate: (state, rpmUpdate) => (state.rpmUpdate = rpmUpdate),
  setrpmMaptop: (state, rpmMaptop) => (state.rpmMaptop = rpmMaptop),
  setiotMaptop: (state, iotMaptop) => (state.iotMaptop = iotMaptop),
  setiot_executed: (state, iot_executed) => (state.iot_executed = iot_executed),
  setiotData_: (state, iotData_) => (state.iotData_ = iotData_),
  setiotShow_: (state, iotShow_) => (state.iotShow_ = iotShow_),
  setOffIot: (state, OffIot) => (state.OffIot = OffIot),
  setrbs_output: (state, rbs_output) => (state.rbs_output = rbs_output),
  resetState(state) {
    Object.assign(state, getDefaultState());
  },

  //reboot sailogger
  setrbsopen: (state, rbsOpen) => (state.rbsOpen = rbsOpen),
  setrbsID: (state, rbsID) => (state.rbsID = rbsID),
  setrbsData: (state, rbsData) => (state.rbsData = rbsData),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
