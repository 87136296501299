<template>
  <div>
    <v-dialog v-model="ChatCreated_" width="990" persistent>
      <v-card class="elevation-0 rounded-0">
        {{ callopen }}
        <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0" dense>
         Vessel Chat Room

          <v-spacer></v-spacer>

          <v-btn
            small
            class="elevation-0 rounded-0"
            icon
            @click="unsetChatCreated_Data()"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-toolbar
          v-if="msgData"
          class="elevation-0 rounded-0"
          color="#ffff"
          dense
        >
          <v-toolbar-subtitle>
            {{ msgData.name }} | {{ msgData.pt_name }} | +{{
              msgData.thuraya_number
            }}
          </v-toolbar-subtitle>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider v-if="msgData"></v-divider>
        <v-container style="margin-top: 0em" v-if="!msgData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>
        <v-card-text
          @ready="testready()"
          id="div_mchat"
          v-if="msgData"
          :style="chData ? 'height:450px;padding: 2em;' : 'padding: 2em;'"
          class="overflow-y-auto"
        >
          <v-row no-gutters>
            <v-col style="border: solid 1px #9e9e9e" cols="12">
              <v-toolbar color="#f7f7f7" style="border-bottom: solid 1px #9e9e9e" dense class="elevation-0 rounded-0">
              <v-spacer style="width:50%"></v-spacer>
              <v-text-field
            style="border: solid 1px #e0e0e0;"
              width="40"
              v-model="searchChData"
              hide-details
              prepend-inner-icon="mdi-magnify"
              placeholder="Search"
              solo
              clearable
              class="elevation-0 rounded-0"

            ></v-text-field>
              </v-toolbar>
              <v-responsive
                v-if="chData"
                class="overflow-y-hidden fill-height"
                height="300"
              >
                <v-card
                  flat
                  width="100%"
                  class="d-flex fill-height elevation-0 rounded-0"
                  color="#fff"
                >
                
                  <v-card-text
                    style="background-color: #f7f7f7"
                    id="div_chat"
                    width="100"
                    class="overflow-y-auto"
                  >
                    <div
                      v-if="chData.messages.messages.length == 0"
                      style="padding: 6em; margin: auto; text-align: center"
                    >
                      <h2>No Message Yet</h2>
                      <p>your message will appear here as soon as they sent</p>
                    </div>
                    
                    <v-data-table
                    v-if="chData.messages.messages.length != 0"
            style="margin-top: 0px;display:block;background-color:#f7f7f7"
            :search="searchChData"
            :headers="headerChat"
            :items="chData.messages.messages"
            hide-default-footer
            :page.sync="page"
            :items-per-page="chData.messages.messages.length"
            class="elevation-0 rounded-0 "
            hide-default-header
          >
        
          <template v-slot:item="row">
              <tr class="pa-0">
                <td 
                 
                  class="pa-0 pl-1"
                  style="white-space: nowrap;border-bottom:none"
                >
               
                <v-list-item
                       
                v-if="row.item.fromVessel==false && row.item.own ==false"
                        class=""
                      >
                        <v-list-item-avatar class="align-self-start mr-2 pa-0">
                          <v-avatar color="#343a40">
                            <v-icon dark> mdi-account-circle </v-icon>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content class="received-messageuser">
                          <v-card
                            color="#343a40"
                            class="flex-none rounded-0 elevation-0"
                          >
                            <v-card-text
                              class="white--text pa-2 d-flex flex-column"
                            >
                              <span
                                class="text-caption"
                                style="text-align: left"
                                >{{ row.item.author }}[{{ row.item.email }}]</span
                              >
                              <span class="align-self-start text-subtitle-1">{{
                                row.item.body
                              }}</span>
                              <span
                                class="text-caption font-italic align-self-end"
                                >{{ row.item.date }}</span
                              >
                            </v-card-text>
                          </v-card>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                      v-if="row.item.fromVessel==true && row.item.own ==false"
                        class=""
                      >
                        <v-list-item-avatar class="align-self-start mr-2 pa-0">
                          <v-avatar color="primary">
                            <v-icon dark> mdi-ferry </v-icon>
                          </v-avatar>
                        </v-list-item-avatar>
                        <v-list-item-content class="received-message">
                          <v-card
                            color="primary"
                            class="flex-none rounded-0 elevation-0"
                          >
                            <v-card-text
                              class="white--text pa-2 d-flex flex-column"
                            >
                              <span
                                v-if="msgData"
                                class="text-caption"
                                style="text-align: left"
                                >{{ msgData.name }}
                              </span>
                              <span class="align-self-start text-subtitle-1">{{
                                row.item.body
                              }}</span>
                              <span
                                class="text-caption font-italic align-self-end"
                                >{{ row.item.date }}</span
                              >
                            </v-card-text>
                          </v-card>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item   v-if="row.item.fromVessel==false && row.item.own ==true">
                        <v-list-item-content class="sent-message justify-end ">
                          <v-card
                            color="#38C172"
                            class="flex-none rounded-0 elevation-0"
                            style="text-align: right"
                          >
                            <v-card-text
                              class="white--text pa-2 d-flex flex-column"
                            >
                              <span class="text-subtitle-1 chat-message">{{
                                row.item.body
                              }}</span>
                              <span
                                class="text-caption font-italic align-self-start"
                                >{{ row.item.date }}</span
                              >
                            </v-card-text>
                          </v-card>
                        </v-list-item-content>
                        <v-list-item-avatar class="align-self-start ml-2 pa-0">
                          <v-avatar color="#38c172">
                            <v-icon dark> mdi-account-circle </v-icon>
                          </v-avatar>
                        </v-list-item-avatar>
                      </v-list-item>
                </td>
                </tr>
                </template>
        
        </v-data-table>
                  
                  </v-card-text>
                </v-card>
              </v-responsive>
            </v-col>
            <v-col cols="5" class="mt-7">
              <v-text-field
                dense
                style="cursor: pointer"
                v-if="userprof.name"
                readonly
                :value="userprof.name"
                filled
                outlined
                class="rounded-0 elevation-0 mr-2"
                label="From"
              ></v-text-field>
            </v-col>
            <v-col cols="5" class="mt-7">
              <v-text-field
                dense
                style="cursor: pointer"
                v-if="msgData"
                readonly
                :value="msgData.name + ' | +' + msgData.thuraya_number"
                filled
                outlined
                class="rounded-0 elevation-0 mr-2"
                label="To"
              ></v-text-field>
            </v-col>
            <v-col cols="2" class="mt-7">
              <v-text-field
                dense
                style="cursor: pointer"
                v-if="msgData"
                readonly
                :value="sisaKarakter"
                filled
                outlined
                class="rounded-0 elevation-0"
                label="Characters Left"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-form ref="form" v-model="valid" lazy-validation>
                <v-textarea
                  @click="bottom_mchat()"
                  @input="addChange()"
                  name="input-7-1"
                  outlined
                  :maxlength="130 - userprof.name.length"
                  class="elevation-0 rounded-0 pa-0"
                  label="Message"
                  hint="Message plus sender name must be 130 characters or less"
                  auto-grow
                  v-model="pesan"
                  :counter="130 - userprof.name.length"
                  :rules="messageRules"
                ></v-textarea>
              </v-form>
            </v-col>
            <v-col cols="12">
              <v-btn
                small
                :style="
                  valid && isChange_ ? 'cursor:pointer' : 'cursor:not-allowed'
                "
                class="elevation-0 rounded-0"
                :disabled="valid && isChange_ ? false : true"
                block
                color="primary"
                @click="
                  valid && isChange_
                    ? (pesan_confirm = true)
                    : console.log('disabled')
                "
              >
                Send SMS
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      class="elevation-0 rounded-0"
      v-model="pesan_confirm"
      persistent
      max-width="290"
    >
      <v-card class="elevation-0 rounded-0">
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text style="text-align: left"
          >Message will send after you click yes</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="rounded-0"
            color="red darken-1"
            text
            @click="pesan_confirm = false"
          >
            No
          </v-btn>
          <v-btn
            class="rounded-0"
            color="green darken-1"
            text
            @click="sendDataMessage()"
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar class="elevation-0 rounded-0" elevation="0" v-model="snackbar">
      <div class="pa-1 pl-7">
        <v-icon class="mr-2" small>{{ iconsnack }}</v-icon
        >{{ pesansnack }}
      </div>

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "../../plugins/axios";
import $ from "jquery";
export default {
  data() {
    return {
       headerChat: [
        { text: "body", value: "body", sortable: false },
        { text: "date", value: "date", sortable: false },
        { text: "author", value: "author", sortable: false },
      ],
      searchChData:'',
      snackbar: false,
      pesansnack: null,
      iconsnack: "mdi-timer-outline",
      pesan_confirm: false,
      messageRules: [
        (v) => !!v || "Message cannot empty",
        (v) =>
          (v || "").length <= 130 || "Message must be 125 characters or less",
      ],
      valid: null,
      loadernavplus: require("@/assets/img/load100new.gif"),
      isChange_: null,
      search: "",

      activeChat: 1,
      pesan: "",

      messageForm: {
        content: "",
        me: true,
        created_at: "11:11am",
      },
    };
  },
  watch: {
    chData: {
      handler: function (newItem) {
        setTimeout(function () {
          $("#div_chat").scrollTop($("#div_chat")[0].scrollHeight);
        }, 1000);
      },

      deep: true,
    },
  },
  methods: {
    ...mapActions(["removechRoom", "unsetChatCreated_Data", "chRoomFetch"]),

    addChange() {
      this.isChange_ = true;
    },

    bottom_mchat() {
      $("#div_mchat").scrollTop($("#div_mchat")[0].scrollHeight);
    },

    async sendDataMessage() {
      this.pesan_confirm = false;
      this.snackbar = true;
      this.pesansnack = "Sending Message";
      $("#div_mchat").scrollTop(0);
      const data = {
        thuraya_number: this.msgData.thuraya_number,

        pesan: this.pesan,
      };

      try {
        const response = await axios.post("/api/v1/navplus/sms/send", data, {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        });

        console.log(response);
        this.alertmsg_ = true;
        this.chroomDataFetch(this.msgData);
        this.snackbar = true;
        this.iconsnack = "mdi-check-all";
        this.pesansnack = "Message Sent";
      } catch (e) {
        console.log(e);
        this.snackbar = true;
        this.iconsnack = "mdi-close-circle-multiple-outline";
        this.pesansnack = "Failed, Check Your Connection";
      }
    },

    chroomDataFetch(item) {
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
        data: item,
      };
      this.chRoomFetch(data);
    },
  },
  computed: {
    ...mapGetters([
      "chData",
      "msgopen",
      "msgData",
      "Datauser",
      "callopen",
      "ChatCreated_",
      "userprof",
      "user_allowed",
    ]),
    sisaKarakter() {
      return 130 - (this.userprof.name.length + this.pesan.length);
    },
  
  },
  mounted() {},
};
</script>

<style lang="scss" scoped>
.chat-message {
  display: unset !important;
  white-space: break-spaces;
}
.chat-screen {
  max-height: 320px;
  overflow-y: auto;
}
.flex-none {
  flex: unset;
}
.received-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 54px;
  right: auto;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #1976d2 transparent transparent transparent;
}

.received-messageuser::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 54px;
  right: auto;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #343a40 transparent transparent transparent;
}
.sent-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 54px;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #38c172 transparent transparent transparent;
}

.vCard {
  display: flex !important;
  flex-direction: column;
}

.vCardText {
  flex-grow: 1;
  overflow: auto;
}

.row-pointer {
  cursor: pointer;
}

.v-sheet.v-snack__wrapper {
  border-radius: none;
}

tbody {
     tr:hover {
        background-color: transparent !important;
     }
  }
</style>
