<template>
  <v-dialog v-model="mrtgopen" max-width="fit-content" persistent>
    <v-card class="elevation-0 rounded-0">

      <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7">
        <v-icon class="mr-2" small>mdi-chart-bar</v-icon> MRTG Traffic
        <v-spacer></v-spacer>
        <v-btn small class="elevation-0 rounded-0" icon @click="removeMRTG()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar v-if="mrtgSailink" class="elevation-0 rounded-0" dense>
        <v-toolbar-subtitle style="font-size: 0.8em;text-align: left;">{{ mrtgSailink.vessel_pt }}</v-toolbar-subtitle>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider v-if="mrtgSailink"></v-divider>
      <v-card v-if="!mrtgSailink" width="50em" height="20em" class="elevation-0 rounded-0">
        <v-container style="margin-top: 0" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
          </v-row>
        </v-container>
      </v-card>

      <div v-if="mrtgSailink && mrtgSailink.isMetered == true" class="ma-2">
        <v-card v-for="(mrtg, index) in mrtgSailink.base64" :key="index"
          class="mx-auto content_modal elevation-0 rounded-0" max-width="45rem" style="border: solid 1px #e0e0e0">
          <v-toolbar color="#f7f7f7" dense class="elevation-0 rounded-0"><v-text>{{ title_mrtg[index]
          }}</v-text></v-toolbar>
          <v-divider></v-divider>
          <v-card class="elevation-0 rounded-0 pa-2">
            <v-card class="elevation-0 rounded-0 pa-2" style="border: solid 1px #e0e0e0">
              <v-img v-bind:src="mrtg[0]"></v-img>
            </v-card>
          </v-card>
        </v-card>
      </div>

      <div v-if="mrtgSailink && mrtgSailink.isMetered == false" class="ma-2">
        <v-card v-for="(mrtg, index) in mrtgSailink.base64" :key="index"
          class="mx-auto content_modal elevation-0 rounded-0" style="border: solid 1px #e0e0e0">
          <v-toolbar color="#f7f7f7" dense class="elevation-0 rounded-0"><v-text>{{ title_mrtg[index]
          }}</v-text></v-toolbar>
          <v-divider></v-divider>
          <v-card class="elevation-0 rounded-0 pa-2">
            <v-row>
              <v-col cols="6">
                <v-card class="elevation-0 rounded-0 pa-2" style="border: solid 1px #e0e0e0">
                  <v-img v-bind:src="mrtg[0]"></v-img>
                </v-card>
              </v-col>
              <v-col cols="6">
                <v-card class="elevation-0 rounded-0 pa-2" style="border: solid 1px #e0e0e0">
                  <v-img v-bind:src="mrtg[1]"></v-img>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-card>
      </div>

      <v-card-actions class="justify-end"> </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      loadernavplus: require("@/assets/img/load100new.gif"),
      title_mrtg: {
        1: "Hourly (1 Minute Average)",
        2: "Daily (5 Minute Average)",
        3: "Weekly (30 Minute Average)",
        4: "Monthly (2 Hour Average)",
        5: "Yearly (1 Day Average)",
      },
    };
  },
  methods: {
    ...mapActions([
      "removeSearch",
      "fetchMySailink",
      "updateVesselName",
      "fetchMRTG",
      "removeMRTG",
      "showonMap",
      "ipAdr",
      "lnStatus",
      "showondate",
      "addShowClick",
    ]),
  },

  computed: {
    ...mapGetters([
      "sailinkData",
      "mrtgSailink",
      "showmaptable",
      "searchVessel",
      "searchVesselID",
      "showmapclick",
      "status_mrtg",
      "mrtgopen",
    ]),
  },
};
</script>

<style lang="scss" scoped>
.v-dialog {
  box-shadow: none;
  border-radius: 0;
}
</style>
