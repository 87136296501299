<template>
  <div>
    <v-card  v-if="!testData">Loading</v-card>
    <v-card v-if="testData" class="elevation-0 rounded-0 pa-2 mb-2" outlined tile>
      <v-toolbar
        id="title_detail_summary"
        style="border: solid 1px #e0e0e0; border-bottom: solid 0px #e0e0e0"
        class="elevation-0 rounded-0"
      >
        <strong>{{ testData.data.title }}</strong>
        <v-spacer></v-spacer>
        <v-btn small class="elevation-0 rounded-0" icon @click="closeDT()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card
        v-if="testData.data && (ctgsummary == 'FLEET SUMMARY' || ctgsummary == 'COMPANY LIST')"
        class="elevation-0 rounded-0"
        style="margin-top: 0px; border: solid 1px #e0e0e0"
      >
        <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense>
          <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="border: solid 1px #e0e0e0"
                small
                class="rounded-0 pa-1 elevation-0 ma-0"
                color="#ffff"
                v-bind="attrs"
                v-on="on"
              >
                {{ kFormatter(itemsPerPage)
                }}<v-icon small style="margin-left: 0.2em"
                  >mdi-unfold-more-horizontal</v-icon
                >
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="itemsPerPage = 10"
                ><v-list-item-title>10</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPage = 25"
                ><v-list-item-title>25</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPage = 50"
                ><v-list-item-title>50</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPage = 100"
                ><v-list-item-title>100</v-list-item-title></v-list-item
              >
            </v-list>
          </v-menu>
          <v-spacer style="width: 40%"></v-spacer>
          <v-text-field
            style="border: solid 1px #e0e0e0"
            width="40"
            v-model="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            solo
            clearable
            class="elevation-0 rounded-0"
          ></v-text-field>
        </v-toolbar>

        <v-divider></v-divider>
        <v-data-table
          :search="search"
          :headers="headers"
          :items="testDataTable"
          hide-default-footer
          :page.sync="page"
          :items-per-page="itemsPerPage"
          class="elevation-0 rounded-0 dataTable"
          @page-count="pageCount = $event"
        >
          <template v-slot:item="row">
            <tr>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 10em; text-align: left"
              >
                {{ row.item.index + 1 }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.name }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.identifier }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.thuraya_number ? row.item.thuraya_number : "-" }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.pt.name }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.pt.group_name }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="page"
            color="primary"
            :length="pageCount"
            :total-visible="7"
          ></v-pagination>
        </v-toolbar>
      </v-card>

      <v-card
        v-if="
          testData.data && (ctgsummary == 'SAILINK' || ctgsummary == 'THURAYA')
        "
        class="elevation-0 rounded-0"
        style="margin-top: 0px; border: solid 1px #e0e0e0"
      >
        <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense>
          <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="border: solid 1px #e0e0e0"
                small
                class="rounded-0 pa-1 elevation-0 ma-0"
                color="#ffff"
                v-bind="attrs"
                v-on="on"
              >
                {{ kFormatter(itemsPerPageSailink)
                }}<v-icon small style="margin-left: 0.2em"
                  >mdi-unfold-more-horizontal</v-icon
                >
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="itemsPerPageSailink = 10"
                ><v-list-item-title>10</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageSailink = 25"
                ><v-list-item-title>25</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageSailink = 50"
                ><v-list-item-title>50</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageSailink = 100"
                ><v-list-item-title>100</v-list-item-title></v-list-item
              >
            </v-list>
          </v-menu>
          <v-spacer style="width: 40%"></v-spacer>
          <v-text-field
            style="border: solid 1px #e0e0e0"
            width="40"
            v-model="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            solo
            clearable
            class="elevation-0 rounded-0"
          ></v-text-field>
        </v-toolbar>

        <v-divider></v-divider>
        <v-data-table
          :search="search"
          :headers="headersSailink"
          :items="testDataTable"
          hide-default-footer
          :page.sync="page"
          :items-per-page="itemsPerPageSailink"
          class="elevation-0 rounded-0 dataTable"
          @page-count="pageCountSailink = $event"
        >
          <template v-slot:item="row">
            <tr>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 10em; text-align: left"
              >
                {{ row.item.index + 1 }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.name }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.identifier }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.thuraya_number ? row.item.thuraya_number : "-" }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.pt.name }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.pt.group_name }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="page"
            color="primary"
            :length="pageCountSailink"
            :total-visible="7"
          ></v-pagination>
        </v-toolbar>
      </v-card>

      <v-card
        v-if="
          testData.data && (ctgsummary == 'TERMINAL LIST')
        "
        class="elevation-0 rounded-0"
        style="margin-top: 0px; border: solid 1px #e0e0e0"
      >
        <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense>
          <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="border: solid 1px #e0e0e0"
                small
                class="rounded-0 pa-1 elevation-0 ma-0"
                color="#ffff"
                v-bind="attrs"
                v-on="on"
              >
                {{ kFormatter(itemsPerPageTerminal)
                }}<v-icon small style="margin-left: 0.2em"
                  >mdi-unfold-more-horizontal</v-icon
                >
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="itemsPerPageTerminal = 10"
                ><v-list-item-title>10</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageTerminal = 25"
                ><v-list-item-title>25</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageTerminal = 50"
                ><v-list-item-title>50</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageTerminal = 100"
                ><v-list-item-title>100</v-list-item-title></v-list-item
              >
            </v-list>
          </v-menu>
          <v-spacer style="width: 40%"></v-spacer>
          <v-text-field
            style="border: solid 1px #e0e0e0"
            width="40"
            v-model="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            solo
            clearable
            class="elevation-0 rounded-0"
          ></v-text-field>
        </v-toolbar>

        <v-divider></v-divider>
        <v-data-table
          :search="search"
          :headers="headersTerminal"
          :items="testDataTable"
          hide-default-footer
          :page.sync="page"
          :items-per-page="itemsPerPageTerminal"
          class="elevation-0 rounded-0 dataTable"
          @page-count="pageCountTerminal = $event"
        >
          <template v-slot:item="row">
            <tr>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 10em; text-align: left"
              >
                {{ row.item.index + 1 }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.name }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.identifier }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.thuraya_number ? row.item.thuraya_number : "-" }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.pt.name }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.pt.group_name }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="page"
            color="primary"
            :length="pageCountTerminal"
            :total-visible="7"
          ></v-pagination>
        </v-toolbar>
      </v-card>

      <v-card
        v-if="
          testData.data && (ctgsummary == 'IP PHONE LIST')
        "
        class="elevation-0 rounded-0"
        style="margin-top: 0px; border: solid 1px #e0e0e0"
      >
        <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense>
          <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="border: solid 1px #e0e0e0"
                small
                class="rounded-0 pa-1 elevation-0 ma-0"
                color="#ffff"
                v-bind="attrs"
                v-on="on"
              >
                {{ kFormatter(itemsPerPageIpphone)
                }}<v-icon small style="margin-left: 0.2em"
                  >mdi-unfold-more-horizontal</v-icon
                >
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="itemsPerPageIpphone = 10"
                ><v-list-item-title>10</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageIpphone = 25"
                ><v-list-item-title>25</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageIpphone = 50"
                ><v-list-item-title>50</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageIpphone = 100"
                ><v-list-item-title>100</v-list-item-title></v-list-item
              >
            </v-list>
          </v-menu>
          <v-spacer style="width: 40%"></v-spacer>
          <v-text-field
            style="border: solid 1px #e0e0e0"
            width="40"
            v-model="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            solo
            clearable
            class="elevation-0 rounded-0"
          ></v-text-field>
        </v-toolbar>

        <v-divider></v-divider>
        <v-data-table
          :search="search"
          :headers="headersIpphone"
          :items="testDataTable"
          hide-default-footer
          :page.sync="page"
          :items-per-page="itemsPerPageIpphone"
          class="elevation-0 rounded-0 dataTable"
          @page-count="pageCountIpphone = $event"
        >
          <template v-slot:item="row">
            <tr>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 10em; text-align: left"
              >
                {{ row.item.index + 1 }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.name }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.identifier }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.thuraya_number ? row.item.thuraya_number : "-" }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.pt.name }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.pt.group_name }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                +{{ row.item.ip_phones[0].number }}
              </td>
            </tr>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="page"
            color="primary"
            :length="pageCountIpphone"
            :total-visible="7"
          ></v-pagination>
        </v-toolbar>
      </v-card>


      <v-card
        v-if="
          testData.data && (ctgsummary == 'THURAYA RATEPLAN LIST')
        "
        class="elevation-0 rounded-0"
        style="margin-top: 0px; border: solid 1px #e0e0e0"
      >
        <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense>
          <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="border: solid 1px #e0e0e0"
                small
                class="rounded-0 pa-1 elevation-0 ma-0"
                color="#ffff"
                v-bind="attrs"
                v-on="on"
              >
                {{ kFormatter(itemsPerPageThurayaRateplan)
                }}<v-icon small style="margin-left: 0.2em"
                  >mdi-unfold-more-horizontal</v-icon
                >
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="itemsPerPageThurayaRateplan = 10"
                ><v-list-item-title>10</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageThurayaRateplan = 25"
                ><v-list-item-title>25</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageThurayaRateplan = 50"
                ><v-list-item-title>50</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageThurayaRateplan = 100"
                ><v-list-item-title>100</v-list-item-title></v-list-item
              >
            </v-list>
          </v-menu>
          <v-spacer style="width: 40%"></v-spacer>
          <v-text-field
            style="border: solid 1px #e0e0e0"
            width="40"
            v-model="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            solo
            clearable
            class="elevation-0 rounded-0"
          ></v-text-field>
        </v-toolbar>

        <v-divider></v-divider>
        <v-data-table
          :search="search"
          :headers="headersThurayaRateplan"
          :items="testDataTable"
          hide-default-footer
          :page.sync="page"
          :items-per-page="itemsPerPageThurayaRateplan"
          class="elevation-0 rounded-0 dataTable"
          @page-count="pageCountThurayaRateplan = $event"
        >
          <template v-slot:item="row">
            <tr>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 10em; text-align: left"
              >
                {{ row.item.index + 1 }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.name }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.identifier }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.thuraya_number ? row.item.thuraya_number : "-" }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.thuraya.rateplan ? row.item.thuraya.rateplan : "-" }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.pt.name }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.pt.group_name }}
              </td>
             
            </tr>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="page"
            color="primary"
            :length="pageCountThurayaRateplan"
            :total-visible="7"
          ></v-pagination>
        </v-toolbar>
      </v-card>


      <v-card
        v-if="
          testData.data && (ctgsummary == 'USER LIST')
        "
        class="elevation-0 rounded-0"
        style="margin-top: 0px; border: solid 1px #e0e0e0"
      >
        <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense>
          <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                style="border: solid 1px #e0e0e0"
                small
                class="rounded-0 pa-1 elevation-0 ma-0"
                color="#ffff"
                v-bind="attrs"
                v-on="on"
              >
                {{ kFormatter(itemsPerPageUserList)
                }}<v-icon small style="margin-left: 0.2em"
                  >mdi-unfold-more-horizontal</v-icon
                >
              </v-btn>
            </template>
            <v-list>
              <v-list-item @click="itemsPerPageUserList = 10"
                ><v-list-item-title>10</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageUserList = 25"
                ><v-list-item-title>25</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageUserList = 50"
                ><v-list-item-title>50</v-list-item-title></v-list-item
              >
              <v-list-item @click="itemsPerPageUserList = 100"
                ><v-list-item-title>100</v-list-item-title></v-list-item
              >
            </v-list>
          </v-menu>
          <v-spacer style="width: 40%"></v-spacer>
          <v-text-field
            style="border: solid 1px #e0e0e0"
            width="40"
            v-model="search"
            hide-details
            prepend-inner-icon="mdi-magnify"
            placeholder="Search"
            solo
            clearable
            class="elevation-0 rounded-0"
          ></v-text-field>
        </v-toolbar>

        <v-divider></v-divider>
        <v-data-table
          :search="search"
          :headers="headersUserList"
          :items="testDataTable"
          hide-default-footer
          :page.sync="page"
          :items-per-page="itemsPerPageUserList"
          class="elevation-0 rounded-0 dataTable"
          @page-count="pageCountUserList = $event"
        >
          <template v-slot:item="row">
            <tr>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 10em; text-align: left"
              >
                {{ row.item.index + 1 }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.name }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.email }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.username }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.role.name }}
              </td>
              <td
                class="pa-0 pl-1"
                style="white-space: nowrap; min-width: 20em; text-align: left"
              >
                {{ row.item.lastLogin }}
              </td>
             
             
            </tr>
          </template>
        </v-data-table>
        <v-divider></v-divider>
        <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
          <v-spacer></v-spacer>
          <v-pagination
            class="pt-2 pb-2"
            v-model="page"
            color="primary"
            :length="pageCountUserList"
            :total-visible="7"
          ></v-pagination>
        </v-toolbar>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import $ from "jquery";
export default {
  data() {
    return {
      search: "",
      itemsPerPage: 10,
      page: 1,
      pageCount: 0,
      itemsPerPageSailink: 10,
      pageSailink: 1,
      pageCountSailink: 0,
      itemsPerPageTerminal: 10,
      pageTerminal: 1,
      pageCountTerminal: 0,
      itemsPerPageIpphone: 10,
      pageIpphone: 1,
      pageCountIpphone: 0,
      itemsPerPageThurayaRateplan: 10,
      pageThurayaRateplan: 1,
      pageCountThurayaRateplan: 0,
      itemsPerPageUserList: 10,
      pageUserList: 1,
      pageCountUserList: 0,
      headers: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Name", value: "name", width: "20%" },
        { text: "Serial Number", value: "idintifier", width: "50%" },
        { text: "Thuraya Number", value: "thuraya_number", width: "50%" },
        { text: "Company", value: "name", width: "50%" },
        { text: "Group", value: "group_name", width: "50%" },
      ],

      headersTerminal: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Name", value: "name", width: "20%" },
        { text: "Serial Number", value: "idintifier", width: "50%" },
        { text: "Thuraya Number", value: "thuraya_number", width: "50%" },
        { text: "Company", value: "name", width: "50%" },
        { text: "Group", value: "group_name", width: "50%" },
      ],

      headersSailink: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Name", value: "name", width: "20%" },
        { text: "Serial Number", value: "idintifier", width: "50%" },
        { text: "Thuraya Number", value: "thuraya_number", width: "50%" },
        { text: "Company", value: "name", width: "50%" },
        { text: "Group", value: "group_name", width: "50%" },
      ],

      headersUserList: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Name", value: "name", width: "20%" },
        { text: "Email", value: "idintifier", width: "50%" },
        { text: "Username", value: "thuraya_number", width: "50%" },
        { text: "Role", value: "name", width: "50%" },
        { text: "Last Login", value: "group_name", width: "50%" },
      ],
      headersIpphone: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Name", value: "name", width: "20%" },
        { text: "Serial Number", value: "idintifier", width: "50%" },
        { text: "Thuraya Number", value: "thuraya_number", width: "50%" },
        { text: "Company", value: "name", width: "50%" },
        { text: "Group", value: "group_name", width: "50%" },
        { text: "PSTN/Mobile Number", value: "group_name", width: "50%" },
      ],

      headersThurayaRateplan: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "index",
        },
        { text: "Name", value: "name", width: "20%" },
        { text: "Serial Number", value: "idintifier", width: "50%" },
        { text: "Thuraya Number", value: "thuraya_number", width: "50%" },
        { text: "Rateplan", value: "name", width: "50%" },
        { text: "Company", value: "name", width: "50%" },
        { text: "Group", value: "group_name", width: "50%" },
       
      ],
    };
  },
  mounted() {
    console.log(this.dataDS["data"]);
    var mama = this;

    if (!mama.summary_executed) {
      //console.log("jalan title map");
      mama.addsummary_executed(true);
      $("html, body").animate(
        {
          scrollTop: $("#title_detail_summary").offset().top - 109,
        },
        800
      );
    } else {
      ////console.log("Muncul"), //console.log(mama.summary_executed);
    }
  },

  methods: {
    ...mapActions(["removedetailSummary_","addsummary_executed"]),
    closeDT(){
      this.removedetailSummary_()
      $("html, body").animate(
        {
          scrollTop: $("#top_summary").offset().top - 109,
        },
        800
      );
    },
    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },
  },
  computed: {
    ...mapGetters([
      "dataDS",
      "testData",
      "summary_executed",
      "testDataTable",
      "ctgsummary",
    ]),
  },
};
</script>

<style lang="scss" scoped></style>
