<template>
    <LineChartGenerator
      :chart-options="chartOptions"
      :chart-data="chartData"
      :chart-id="chartId"
      :dataset-id-key="datasetIdKey"
      :plugins="plugins"
      :css-classes="cssClasses"
      :styles="styles"
      :width="width"
      :height="height"
    />
  </template>
  
  <script>
  import { Line as LineChartGenerator } from "vue-chartjs/legacy";
  import ChartDataLabels from 'chartjs-plugin-datalabels';
  
  
  
  import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
  } from "chart.js";
  
  
  const floatingLabels = {
              id: 'floatingLabels',
              afterDatasetsDraw(chart, args, options) {
                  // console.log(chart);
                  const { ctx, chartArea: { top }, scales: { y,y1 } } = chart;
                  for (let i = 0; i < chart.config.data.datasets[0].data.length; i++) {
                      const xPos = chart.getDatasetMeta(1).data[i].x;
                      const yPos = top + 6;
  
                      ctx.save();
  
                      if (chart.isDatasetVisible(0) === true) {
                          ctx.fillStyle = 'blue';
                      } else {
                          ctx.fillStyle = 'transparent';
                      }
  
                      ctx.textAlign = 'center';
                      ctx.font = 'bolder 12px Arial';
                      ctx.backgroundStyle = 'blue';
  
                      // ctx.rotation = 50;
                      var txt = chart.config.data.datasets[0].data[i];
                      var width = ctx.measureText(txt).width; /// width in pixels
                      ctx.fillRect(xPos, yPos, width, parseInt('bolder 12px Arial', 10));
                      ctx.fillText(txt, xPos, yPos);
                      ctx.restore();
  
                  }
              }
          }
  
  ChartJS.register(
    Title,
    Tooltip,
    Legend,
    LineElement,
    LinearScale,
    CategoryScale,
    PointElement,
    ChartDataLabels
  );
  
  export default {
    name: "LineChart",
    components: {
      LineChartGenerator,
    },
    props: {
      datachart: [],
      labelchart:[],
  
      // index: [],
      chartId: {
        type: String,
        default: "line-chart",
      },
      datasetIdKey: {
        type: String,
        default: "label",
      },
      width: {
        type: Number,
        default: 0,
      },
      height: {
        type: Number,
        default: 450,
      },
      cssClasses: {
        default: "",
        type: String,
      },
      styles: {
        type: Object,
        default: () => {},
      },
      plugins: {
        type: Array,
        default: () => [ChartDataLabels,floatingLabels],
      },
    },
    data() {
      return {
        chartData: {
          labels: this.labelchart,
          // datasets: this.datachart.datasets[this.index],
          datasets: this.datachart,
        },
        
        chartOptions: {
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            legend: {
              position: "top",
              labels:{
                usePointStyle:true,
              },
            },
            title: {
              display: true,
              text: []
            },
            // tooltip: {
            //     yAlign: 'top',
            // }
          },
          // scales: {
          //   y: {
          //     title: {
          //       display: true,
          //       text: "RPM",
          //     },
          //   },
          // },
  
          scales: {
                              y: {
                                  title: {
                                      display: true,
                                      text: 'RPM / Knots',
                                      color: (context) => {
                                          console.log(context.scale.chart.config._config.options.scales.y.title.text.length);
                                          const textLength = context.scale.chart.config._config.options.scales.y.title.text.length;
  
                                          console.log("text lengght"+ (10 * textLength));
                                          const gradient = context.scale.ctx.createLinearGradient(0, 0, 10 * textLength, 0);
                                          gradient.addColorStop(0, 'black');
                                          gradient.addColorStop(0.035, 'black');
                                          gradient.addColorStop(0.035, 'blue');
                                          gradient.addColorStop(1, 'blue');
  
                                          return gradient;
                                      },
                                      font: {
                                          // size: 14,
                                          // weight: 'bold',
                                          // lineHeight: 1.2,
                                      },
                                  },
                                  type: 'linear',
                                  position: 'left',
                                  // grid: {
                                  //     drawOnChartArea: true, // only want the grid lines for one axis to show up
                                  // },
                                  grace: '20%',
                              },
                              y1: {
                                  display: false,
                                  title: {
                                      display: false,
                                      text: 'Knots',
                                  },
                                  type: 'linear',
                                  position: 'right',
                                  grid: {
                                      drawOnChartArea: false, // only want the grid lines for one axis to show up
                                  },
                                  grace: '30%',
                              },
                          },
  
          interaction: {
            mode: "index",
            intersect: false,
          },
        },
  
  
        // options: {
        //               responsive: true,
        //               plugins: {
        //                   legend: {
        //                       position: 'top',
        //                   },
        //                   title: {
        //                       display: true,
        //                       text: con.app.chart.title+' ( '+con.app.info.startTime+' - '+con.app.info.endTime+' )',
        //                   },
        //                   // tooltip: {
        //                   //     yAlign: 'top',
        //                   // }
        //               },
        //               scales: {
        //                   y: {
        //                       title: {
        //                           display: true,
        //                           text: 'Traffic ('+con.app.chart.unit+')',
        //                       },
        //                       // ticks: {
        //                       //     // Include a dollar sign in the ticks
        //                       //     callback: function(value, index, values) {
        //                       //         return (value/1000).toFixed(1)+'K';
        //                       //     },
        //                       // },
        //                   },
        //               },
        //               interaction: {
        //                   mode: 'index',
        //                   intersect: false,
        //               }
        //           }
      };
    },
  };
  </script>
  
  <style>
  .traffic {
    transform: rotate(270deg);
  }
  
  .wrappchart {
    display: flex;
  }
  </style>
  