import axios from "../../plugins/axios";

const state = {
    username_change: null,
    name_change: null,
    profile_change: null,
    userprof: null,
    userSave: null,
    editprofdata: null,
    editprofile: null,
    dataapifetch: null
};

const getters = {
    username_change: (state) => state.username_change,
    name_change: (state) => state.name_change,
    profile_change: (state) => state.profile_change,
    userprof: (state) => state.userprof,
    userSave: (state) => state.userSave,
    editprofdata: (state) => state.editprofdata,
    editprofile: (state) => state.editprofile,
    dataapifetch: (state) => state.dataapifetch
};

const actions = {
    async fetchUserData({commit}) {
        const response = await axios.get("/api/login_navplus", {
            headers: {
                Authorization: "Bearer" + localStorage.getItem("token"),
            },
        });
        localStorage.setItem("user_data", JSON.stringify(response.data.user));
        commit("setuserprof", response.data.user)
        commit("setuserSave", response.data)
    },

    async fetchEditProf({commit}, data) {
        commit("seteditprofile", true)
        const response = await axios.get("/api/v1/navplus/user/edit/" + data, {
            headers: {
                Authorization: "Bearer" + localStorage.getItem("token"),
            },
        });

        commit("seteditprofdata", response.data.data)

    },


    async fetchDataApi({commit}, data) {
        //console.log('JALAN')
        const response = await axios.get("/api/v1/navplus/user/apiAccess/" + data, {
            headers: {
                Authorization: "Bearer" + localStorage.getItem("token"),
            },
        });
        //console.log(response)
        //console.log(commit)
        commit("set_fetchapi", response.data.data)
    },


    setuserSaveData({commit}, data) {
        commit("setuserSave", data)
        //console.log(data)
    },


    setUserChange({commit}, data) {
        commit("setusername_change", data)
    },

    setNameChange({commit}, data) {
        commit("setname_change", data)
    },

    setProfileChange({commit}, data) {
        commit("setprofile_change", data)
    },

    removeEditProf({commit}) {
        commit("seteditprofile", null),
            commit("seteditprofdata", null)
    }
};

const mutations = {
    setusername_change: (state, username_change) => (state.username_change = username_change),
    setname_change: (state, name_change) => (state.name_change = name_change),
    setprofile_change: (state, profile_change) => (state.profile_change = profile_change),
    setuserprof: (state, userprof) => (state.userprof = userprof),
    setuserSave: (state, userSave) => (state.userSave = userSave),
    seteditprofdata: (state, editprofdata) => (state.editprofdata = editprofdata),
    seteditprofile: (state, editprofile) => (state.editprofile = editprofile),
    set_fetchapi: (state, dataapifetch) => (state.dataapifetch = dataapifetch)
};

export default {
    state,
    getters,
    actions,
    mutations,
};
