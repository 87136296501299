<template>
  <v-card id="card_map_sailink" class="elevation-0 mt-0 rounded-0">
    <v-card id="title_map_sailink" class="container_showonmap ma-3 elevation-0 rounded-0 mt-0 pb-3"
      style="border: solid 1px #e0e0e0" v-if="showmaporbcommdata">
      <v-toolbar color="#F7F7F7" elevation="0" dense>
        {{ showmaporbcommdata.vessel_pt }}
        <v-spacer></v-spacer>

        <v-btn small class="elevation-0 rounded-0" icon @click="closeShowMap()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>

      <v-card class="flexposisi pa-3 rounded-0" height="fit-content" elevation="0" style="margin-top: 0">
        <v-card class="datacard elevation-0 rounded-0" color="#F7F7F7" elevation="0"
          style="border: solid 1px #e0e0e0;text-align:left">
          <v-simple-table v-if="showmaporbcommdata" class="elevation-0 rounded-0" dense>
            <template v-slot:default>
              <tbody>

                <tr>
                  <td class="pl-3">Terminal Type</td>
                  <td>{{ 'Orbcomm' }}</td>
                </tr>
                <tr>
                  <td class="pl-3">Mobile ID</td>
                  <td>
                    {{ showmaporbcommdata.MobileID }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-3">Device ID</td>
                  <td>
                    {{ showmaporbcommdata.dev_id }}
                  </td>
                </tr>



                <tr>
                  <td class="pl-3">Latitude, Longtitude</td>
                  <td>{{ showmaporbcommdata.message.latlong }}</td>
                </tr>
                <tr>
                  <td class="pl-3">DMS</td>
                  <td>{{ showmaporbcommdata.message.dms }}</td>
                </tr>
                <tr>
                  <td class="pl-3">Last Updated</td>
                  <td>{{ showmaporbcommdata.message.dateTime_local
                    }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>

          <v-divider></v-divider>
          <v-card color="#f7f7f7" class="elevation-0 rounded-0 pa-3">
            <v-card flat width="100%" class=" elevation-0 rounded-0  overflow-y-auto  " color="#f7f7f7" height="200">

              <div class="pswp-gallery pswp-gallery--single-column" id="sailink-gallery-container">
                <a v-for="(gambar, index) in showmaporbcommdata.images" :key="index" :href="gambar[0]"
                  :data-pswp-width="gambar[1]" :data-pswp-height="gambar[2]" :data-cropped="true" target="_blank"><img
                    :src="gambar[0]" class="vsl_img" alt="Vessel Image" /></a>
              </div>

            </v-card>
          </v-card>





        </v-card>

        <v-card color="#F7F7F7" id="mapcard" class="mapcard rounded-0" elevation="0" style="border: solid 1px #e0e0e0">
          <v-toolbar color="#F7F7F7" class="rounded-0 toolbar_sailinkdesktop" dense elevation="0">
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0" color="#fff" v-bind="attrs"
                  v-on="on" small elevation="0">
                  <v-icon small>mdi-update</v-icon>
                  &nbsp;
                  <div v-if="!showmaptrackingorb">Loading...</div>
                  <div v-if="showmaptrackingorb">
                    {{ showmaptrackingorb.range.label }}
                  </div>
                  &nbsp;
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, 'today')">Today</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, '24')">Last 24 Hour</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, '7')">Last 7 Days</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, '30')">Last 30 Days</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, 'week')">This Week</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, 'month')">This Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, 'lmonth')">Last Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="(dateopen = true), (datepickerID = showmaporbcommdata.id)">Custom
                    Range</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>

            <v-chip v-if="!showmaptrackingorb" style="border: solid 1px #e0e0e0" color="#ffff" class="rounded-0"
              label><v-icon small class="mr-1">mdi-calendar</v-icon>- - -</v-chip>
            <v-chip style="border: solid 1px #e0e0e0" color="#ffff" class="rounded-0" v-if="showmaptrackingorb"
              label><v-icon small class="mr-1">mdi-calendar</v-icon>{{ showmaptrackingorb.range.actual }}</v-chip>
          </v-toolbar>
          <v-card color="#F7F7F7" class="elevation-0 rounded-0 toolbar_sailinkmobile" elevation="0">
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn block style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0" color="#fff" v-bind="attrs"
                  v-on="on" small elevation="0">
                  <v-icon small>mdi-update</v-icon>
                  &nbsp;
                  <div v-if="!showmaptrackingorb">Loading...</div>
                  <div v-if="showmaptrackingorb">
                    {{ showmaptrackingorb.range.label }}
                  </div>
                  &nbsp;
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, 'today')">Today</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, '24')">Last 24 Hour</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, '7')">Last 7 Day</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, '30')">Last 30 Day</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, 'week')">This Week</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, 'month')">This Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="showdate(showmaporbcommdata.id, 'lmonth')">Last Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="(dateopen = true), (datepickerID = showmaporbcommdata.id)">Custom
                    Range</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>


          </v-card>

          <v-divider></v-divider>
          <v-container v-if="!showmaptrackingorb" id="map_sailink" fill-height fluid
            style="margin-top: 0; height: 35em; background-color: #aad3df">
            <v-row align="center" justify="center">
              <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
            </v-row>
          </v-container>

          <l-map :use-global-leaflet="true" id="map_sailink_" v-if="showmaptrackingorb.maptop"
            @fullscreenchange="triggerFullscreen" @polylinemeasure:move="onCount_move" @polylinemeasure:add="onCount"
            @polylinemeasure:remove="onCount" @polylinemeasure:toggle="handleToggle"
            @polylinemeasure:finish="handleFinish" style="height: 40em" zoom="7" ref="map_sailink" :center="[
              parseFloat(showmaptrackingorb.maptop.lat),
              parseFloat(showmaptrackingorb.maptop.lon),
            ]" :options="{
                minZoom: 3,
                zoomControl: false,
                doubleClickZoom: true,
                scrollWheelZoom: false,
              }">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-control-zoom position="bottomright"></l-control-zoom>
            <l-control-fullscreen position="bottomright"
              :options="{ title: { false: 'Go big!', true: 'Be regular' } }" />
            <l-control-polyline-measure v-if="etaControl" :ref="'polylineMeasure'" :options="{
              showUnitControl: false,
              showClearControl: false,
              showBearings: true,
              unit: unit,
              clearMeasurementsOnStop: false,
              measureControlClasses: ['hideControl'], // Classes to apply to the Measure Control
            }" v-model="measurepoly" position="bottomright" />

            <l-moving-rotated-marker v-if="drivePath" ref="driveMarker" :lat-lng="driveLatLng"
              :rotationAngle="driveRotationAngle" :duration="3000" :icon="icon" />

            <v-rotated-marker :lat-lng="[
              parseFloat(showmaptrackingorb.maptop.lat),
              parseFloat(showmaptrackingorb.maptop.lon),
            ]" :rotationAngle="parseInt(showmaptrackingorb.maptop.heading)">
              <l-icon :icon-size="[40 * 0.30, 100 * 0.30]" :icon-anchor="[40 * 0.30 / 2, 100 * 0.30 / 2]"
                class-name="someExtraClass">
                <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 100">
                  <path :fill="showmaptrackingorb.maptop.fill" stroke="#FFF" stroke-width="10"
                    d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" />
                </svg>
              </l-icon>
              <l-popup @ready="draw_Circle()">
                <table>
                  <tr>
                    <td><b>Latitude</b></td>
                    <td>:</td>
                    <td>
                      {{ showmaptrackingorb.maptop.lat }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Longitude</b></td>
                    <td>:</td>
                    <td>{{ showmaptrackingorb.maptop.lon }}</td>
                  </tr>
                  <tr>
                    <td><b>DMS</b></td>
                    <td>:</td>
                    <td>{{ showmaptrackingorb.maptop.dms }}</td>
                  </tr>
                  <tr>
                    <td><b>Speed</b></td>
                    <td>:</td>
                    <td>
                      {{ showmaptrackingorb.maptop.speed + "&nbsp;knots" }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Heading</b></td>
                    <td>:</td>
                    <td>{{ showmaptrackingorb.maptop.heading + "&deg;" }}</td>
                  </tr>
                  <tr>
                    <td><b>Last Update</b></td>
                    <td>:</td>
                    <td>{{ showmaptrackingorb.maptop.lastUp }}</td>
                  </tr>
                </table>
                <table>
                  <tr>
                    <td><b>SatNet</b></td>
                    <td><b>:</b></td>
                    <td>
                      <b>{{ showmaptrackingorb.maptop.satnet }}</b>
                    </td>
                  </tr>
                  <tr>
                    <td>EIRP</td>
                    <td>:</td>
                    <td>{{ showmaptrackingorb.maptop.satnet }}</td>
                  </tr>
                  <tr>
                    <td>G/T+</td>
                    <td>:</td>
                    <td>{{ showmaptrackingorb.maptop.satnet }}</td>
                  </tr>
                </table>
              </l-popup>
            </v-rotated-marker>








          </l-map>
          <l-map :use-global-leaflet="true" id="map_sailink_" v-if="!showmaptrackingorb.maptop"
            @fullscreenchange="triggerFullscreen" @polylinemeasure:move="onCount_move" @polylinemeasure:add="onCount"
            @polylinemeasure:remove="onCount" @polylinemeasure:toggle="handleToggle"
            @polylinemeasure:finish="handleFinish" style="height: 40em" zoom="7" ref="map_sailink" :center="[
              parseFloat(-4.717453334011475),
              parseFloat(117.02592084779302),
            ]" :options="{
                minZoom: 3,
                zoomControl: false,
                doubleClickZoom: true,
                scrollWheelZoom: false,
              }">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-control-zoom position="bottomright"></l-control-zoom>
            <l-control-fullscreen position="bottomright"
              :options="{ title: { false: 'Go big!', true: 'Be regular' } }" />
            <l-control-polyline-measure v-if="etaControl" :ref="'polylineMeasure'" :options="{
              showUnitControl: false,
              showClearControl: false,
              showBearings: true,
              unit: unit,
              clearMeasurementsOnStop: false,
              measureControlClasses: ['hideControl'], // Classes to apply to the Measure Control
            }" v-model="measurepoly" position="bottomright" />



          </l-map>
          <v-divider v-if="user_allowed.role_id <= 3"></v-divider>

          <v-toolbar v-if="user_allowed.role_id <= 3" dense color="#F7F7F7" elevation="0">
            User Who Can See This Vessel
            <v-spacer style="width: 20%"></v-spacer>
            <v-text-field style="border: solid 1px #e0e0e0" width="40" v-model="searchUserWho" hide-details
              prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
              class="elevation-0 rounded-0"></v-text-field>
          </v-toolbar>
          <v-divider v-if="user_allowed.role_id <= 3"></v-divider>
          <v-data-table v-if="user_allowed.role_id <= 3" :headers="headersUser" :items="vesselUsersOrb"
            :items-per-page="itemsPerPageUser" class="elevation-0 rounded-0 dataTable userTable_desktop "
            hide-default-footer hide-default-header :search="searchUserWho" :page.sync="pageUser"
            @page-count="pageCountUser = $event" @pagination="intoFilterUser" dense>
            <template v-slot:header>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">#</th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">Name</th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">Email</th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">Role</th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">Last Login</th>
            </template>
            <template v-slot:item="row">
              <tr>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.index + 1 }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.name }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.email }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.role_name }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.lastLogin }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table v-if="user_allowed.role_id <= 3" :headers="headersUser" :items="vesselUsersOrb"
            :items-per-page="3" class="elevation-0 rounded-0 userTable_mobile dataTable_Sailink" hide-default-footer
            hide-default-header :search="searchUserWho" :page.sync="pageUser" @page-count="pageCountUser = $event"
            @pagination="intoFilterUser" dense><template v-slot:item="row">
              <tr>
                <td style="border-bottom: none; text-align: left">
                  <v-card style="border-bottom: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-0" width="80vw">
                    <table>
                      <tr>
                        <td>{{ row.item.index + 1 }}</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>:</td>
                        <td>{{ row.item.name }}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>:</td>
                        <td>{{ row.item.email }}</td>
                      </tr>
                      <tr>
                        <td>Role</td>
                        <td>:</td>
                        <td>{{ row.item.role_name }}</td>
                      </tr>
                      <tr>
                        <td>Last Login</td>
                        <td>:</td>
                        <td>{{ row.item.lastLogin }}</td>
                      </tr>
                    </table>
                  </v-card>
                </td>
              </tr>
            </template></v-data-table>
          <v-divider v-if="user_allowed.role_id <= 3"></v-divider>
          <v-toolbar v-if="user_allowed.role_id <= 3" dense class="elevation-0 rounded-0" color="#F7F7F7">
            <p class="mt-3" style="font-size: 0.8em;text-align: left;">
              Showing {{ showingDatafromUser }} to {{ showingDatatoUser }} of
              {{ totalFilterUser }} entries
            </p>
            <v-spacer></v-spacer>
            <v-pagination color="primary" v-if="showmaporbcommdata" v-model="pageUser" :length="pageCountUser"
              :total-visible="7"></v-pagination>
          </v-toolbar>
        </v-card>
      </v-card>

      <v-card style="border: solid 1px #e0e0e0" class="ma-3 datatabledetail rounded-0 mt-0 mb-0" elevation="0">
        <v-toolbar v-if="showmaporbcommdata" elevation="0" style="max-height: 70px" color="#F7F7F7" fixed dense>
          <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0 mr-2" color="#ffff"
                v-bind="attrs" v-on="on">
                {{ kFormatter(itemsPerPage)
                }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list style="border: solid 1px #e0e0e0">
              <v-list-item @click="itemsPerPage = 10"><v-list-item-title>10</v-list-item-title></v-list-item>
              <v-list-item @click="itemsPerPage = 25"><v-list-item-title>25</v-list-item-title></v-list-item>
              <v-list-item @click="itemsPerPage = 50"><v-list-item-title>50</v-list-item-title></v-list-item>
              <v-list-item @click="itemsPerPage = 100"><v-list-item-title>100</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
          <v-btn :href="'https://sailink.id/cetak/csv/orbcomm/' + showmaporbcommdata.id + '/' + exp_range"
            color="primary" dark small style="color:#fff" class="elevation-0 rounded-0 pt-1 ml-2 pdfsailink_desktop"
            target="_blank">
            <v-icon x-small>mdi-file-move</v-icon> Export CSV
          </v-btn>
          <v-btn :href="'https://sailink.id/cetak/pdf/orbcomm/' + showmaporbcommdata.id + '/' + exp_range"
            color="primary" dark small style="color:#fff" class="elevation-0 rounded-0 pt-1 ml-2 pdfsailink_desktop"
            target="_blank">
            <v-icon x-small>mdi-file-move</v-icon> Export PDF
          </v-btn>
          <v-btn :href="'https://sailink.id/cetak/kml/orbcomm/' + showmaporbcommdata.id + '/' + exp_range"
            color="primary" dark small style="color:#fff" class="elevation-0 rounded-0 pt-1 ml-2 pdfsailink_desktop"
            target="_blank">
            <v-icon x-small>mdi-file-move</v-icon> Export KML
          </v-btn>
          <v-btn color="primary" class="elevation-0 rounded-0 pdfsailink_mobile  ml-1" style="padding-top: 0.24em;"
            :href="'https://sailink.id/cetak/csv/orbcomm/' + showmaporbcommdata.id + '/' + exp_range" target="_blank"
            x-small>CSV</v-btn>
          <v-btn color="primary" class="elevation-0 rounded-0 pdfsailink_mobile  ml-1" style="padding-top: 0.24em;"
            :href="'https://sailink.id/cetak/pdf/orbcomm/' + showmaporbcommdata.id + '/' + exp_range" target="_blank"
            x-small>PDF</v-btn>
          <v-btn color="primary" class="elevation-0 rounded-0 pdfsailink_mobile ml-1" style="padding-top: 0.24em;"
            :href="'https://sailink.id/cetak/kml/orbcomm/' + showmaporbcommdata.id + '/' + exp_range" target="_blank"
            x-small>KML</v-btn>


          <v-spacer style="width: 34%"></v-spacer>
          <v-text-field style="border: solid 1px #e0e0e0" width="40" v-model="search" hide-details
            prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
            class="elevation-0 rounded-0"></v-text-field>
        </v-toolbar>
        <v-divider></v-divider>
        <v-layout v-resize="onResize" column style="">
          <v-container id="map_sailink" v-if="!tableTrackingOrb" fill-height fluid style="height: 250px"
            class="elevation-0">
            <v-row align="center" justify="center">
              <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
            </v-row>
          </v-container>
          <v-data-table style="border: solid 1px #e0e0e0" v-if="tableTrackingOrb" :headers="headers" :search="search"
            :items="tableTrackingOrb" hide-default-footer hide-default-header :page.sync="page"
            :items-per-page="itemsPerPage" class="elevation-0 rounded-0 dataTable output_desktop"
            @page-count="pageCount = $event" @pagination="intoFilter">
            <template v-slot:header>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">#</th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                Latitude, Longitude
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                DMS
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">Speed<br />(Knots)</th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                Heading<br />(Degree)
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                Distance<br />(Nmi)
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                Date/Time<br />(UTC)
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2 tableHeader_">
                DateTime<br />(GMT+7)
              </th>

            </template>
            <template v-slot:item="row">
              <tr class="pa-0 ma-0">
                <td width="2%" class="pa-0 ma-0" style="white-space: nowrap; text-align: center">
                  {{ row.item.index + 1 }}
                </td>
                <td width="7%" class="pa-0 ma-0" style="text-align: center">
                  {{ row.item.lat }},{{ row.item.lng }}
                </td>
                <td width="7%" class="pa-0 ma-0" style="white-space: nowrap; text-align: center">
                  {{ row.item.dms }}
                </td>
                <td width="7%" class="pa-0 ma-0" style="white-space: nowrap; text-align: center">
                  {{ row.item.speed }}
                </td>
                <td width="7%" class="pa-0 ma-0" style="white-space: nowrap; text-align: center">
                  {{ row.item.heading }}
                </td>
                <td width="7%" class="pa-0 ma-0" style="white-space: nowrap; text-align: center">
                  {{ row.item.distance }}
                </td>
                <td width="7%" class="pa-0 ma-0" style="text-align: center">
                  {{ row.item.dateTime }}
                </td>
                <td width="7%" class="pa-0 ma-0" style="text-align: center">
                  {{ row.item.dateTime_local }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
            <p class="mt-3" style="font-size: 0.8em;text-align: left;">
              Showing {{ showingDatafrom }} to {{ showingDatato }} of
              {{ totalFilter }} entries
            </p>
            <v-spacer></v-spacer>
            <v-pagination color="primary" v-if="showmaporbcommdata" v-model="page" :length="pageCount"
              :total-visible="7"></v-pagination>
          </v-toolbar>
        </v-layout>

        <div id="app">
          <v-app id="inspire"> </v-app>
        </div>



      </v-card>

      <!--Modal Image-->
      <v-dialog v-model="imageOpen">
        <v-carousel hide-controls hide-delimiters>
          <v-carousel-item @click="imageOpen = false" v-for="(item, i) in showmaporbcommdata.images" :key="i">
            <img style="height: 100%" :src="item[0]" />
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
      <!--Modal Image End-->

    </v-card>

    <!--Modal App-->
    <v-dialog v-model="qdaily" width="85vw" persistent>
      <v-card color="#fff" class="elevation-0 rounded-0">
        <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7">
          <v-icon class="mr-2" small>mdi-chart-bar</v-icon> Quota daily usage {{ serviceQuota }}
          <v-spacer></v-spacer>
          <v-btn class="elevation-0 rounded-0" small icon @click="(qdaily = false), removeQuota()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-divider></v-divider>
        <v-toolbar v-if="showmaporbcommdata" dense class="elevation-0 rounded-0">

          <v-toolbar-subtitle style="font-size: 0.8em;text-align: left;">{{ showmaporbcommdata.vessel_pt
            }}</v-toolbar-subtitle>


          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider v-if="showmaporbcommdata"></v-divider>
        <v-container v-if="!quotaData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
          </v-row>
        </v-container>

        <v-card-text v-if="quotaData" style="padding: 2em">
          <QuotaChart v-if="serviceQuota == 'metered'" v-bind:dataChart="quotaData.chart" />

          <QuotaChartLine v-else v-bind:dataChart="quotaData.chart" />
        </v-card-text>

        <v-card-actions class="justify-end"> </v-card-actions>
      </v-card>
    </v-dialog>
    <!--Modal App End-->

    <!--Modal Date Picker-->
    <v-dialog ref="dialog" v-model="dateopen" :return-value.sync="dates" class="elevation-0 rounded-0" persistent
      width="750">
      <v-card class="elevation-0 rounded-0 pb-1">
        <v-toolbar dense color="#f7f7f7" class="elevation-0 rounded-0">
          Choose Custom Date Range for Tracking
          <v-spacer></v-spacer>
          <v-btn small class="elevation-0 rounded-0" icon @click="closeCostumRange()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-card class="elevation-0 rounded-0">
          <v-alert v-if="cant_future" class="elevation-0 rounded-0 ma-2 mt-3" text dense color="red"
            icon="mdi-clock-alert-outline" style="font-size: 0.86em;text-align: left;">
            Cannot predict future | Enter the correct date and time range
          </v-alert>
          <v-alert v-if="cant_backwards" type="warning" class="elevation-0 rounded-0 ma-2 mt-3" text dense
            icon="mdi-history" style="font-size: 0.86em;text-align: left;">
            Backwards date and time range | Enter the correct date and time range
          </v-alert>
        </v-card>
        <v-row width="100%" no-gutters>
          <v-col cols="12" sm="6">
            <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 pa-2 ma-2">
              <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 pa-2 ma-1">
                <v-date-picker @click:date="logtest" :disabled="(testdisabled || dates.length > 1) ? true : false"
                  color="primary" class="elevation-0 rounded-0" v-model="dates" range scrollable>
                </v-date-picker>
              </v-card>
            </v-card>
          </v-col>
          <v-col cols="12" sm="6">
            <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 pa-2 ma-2">
              <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0  ma-2">
                <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">From</v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="7">

                      <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="dates[0]" label="Date"
                        prepend-inner-icon="mdi-calendar"></v-text-field>

                    </v-col>
                    <v-col cols="12" sm="5">

                      <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="times[0]" label="Time"
                        prepend-inner-icon="mdi-clock-outline"></v-text-field>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
              <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0  ma-2">
                <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">To</v-toolbar>
                <v-card-text>
                  <v-row>
                    <v-col cols="12" sm="7">

                      <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="dates[1]" label="Date"
                        prepend-inner-icon="mdi-calendar"></v-text-field>

                    </v-col>
                    <v-col cols="12" sm="5">

                      <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="times[1]" label="Time"
                        prepend-inner-icon="mdi-clock-outline"></v-text-field>

                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>


              <v-toolbar class="elevation-0 rounded-0" dense color="#f7f7f7">
                <v-spacer></v-spacer>
                <v-btn small :disabled="dates.length > 1 ? false : true" class="elevation-0 rounded-0 mr-1"
                  @click="resetTimesDates()" color="primary"> Reset </v-btn>
                <v-btn small :disabled="dates.length > 1 ? false : true" class="elevation-0 rounded-0" color="primary"
                  @click="customRange()"> OK </v-btn>
              </v-toolbar>
            </v-card>
          </v-col>
        </v-row>

      </v-card>

    </v-dialog>

    <!--Modal Time Picker-->
    <v-dialog ref="dialog" v-model="timeopen" :return-value.sync="dates" class="elevation-0 rounded-0" persistent
      width="fit-content">
      <v-card class="elevation-0 rounded-0 pa-2">
        <v-card style="border: solid 1px #e0e0e0;display: block;" class="elevation-0 rounded-0 ma-2">
          <v-card style="border: solid 1px #e0e0e0;display: block;" class="elevation-0 rounded-0 ma-2">
            <v-time-picker v-model="timein" ampm-in-title format="24hr">
            </v-time-picker>
          </v-card>

          <v-card style="border: solid 1px #e0e0e0;display: block;" class="elevation-0 rounded-0 ma-2">
            <v-toolbar class="elevation-0 rounded-0" dense color="f7f7f7">
              <v-spacer></v-spacer>
              <v-btn :disabled="!timein ? true : false" class="elevation-0 rounded-0" small color="primary"
                @click="saveTimes()"> Choose Time </v-btn>
            </v-toolbar>
          </v-card>

        </v-card>
      </v-card>
    </v-dialog>


    <v-dialog class="rounded-0 elevation-0" v-model="switch_satnet_modal" persistent max-width="590">
      <v-card class="rounded-0 elevation-0">
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text v-if="!switch_true" style="text-align: left">Satnet will be switch</v-card-text>
        <v-card-text v-if="satnet_output">
          {{ satnet_output }}
        </v-card-text>
        <v-card-text v-if="switch_true">
          <v-progress-linear indeterminate color="primary"></v-progress-linear>

        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn v-if="satnet_output == null" :disabled="switch_true ? true : false" dark small class="rounded-0 elevation-0"
            color="red" @click="switch_satnet_modal = false">
            Cancel
          </v-btn>
          <v-btn v-if="switch_true == false && satnet_output != null" dark small class="rounded-0 elevation-0" color="red"
            @click="switch_satnet_modal = false, removeSatnetOutput()">
            Close
          </v-btn>
          <v-btn :disabled="!satnet_id ? true : false" small class="rounded-0 elevation-0" color="primary"
            @click="post_switch_satnet_()">
            Switch Satnet
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>


  </v-card>
</template>

<script>
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';
import axios from "axios";
// import VuePictureSwipe from 'vue-picture-swipe';
import { mapSettingsShow } from "@/constants/mapSettings";
import mapstyle from "@/assets/json/mapstyle.json";
import { mapActions, mapGetters } from "vuex";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import QuotaChart from "@/components/charts/QuotaChart.vue";
import QuotaChartLine from "@/components/charts/QuotaChartLine.vue";
import L from "leaflet";
import {
  LPopup,
  LMap,
  LTileLayer,
  LIcon,
  LControl,
  LPolygon,
  LControlZoom,
} from "vue2-leaflet";

import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import LControlPolylineMeasure from "vue2-leaflet-polyline-measure";
import LMovingRotatedMarker from 'vue2-leaflet-rotatedmarker'
import {
  cns11,
  cns11gain,
  cns11_gnt,
  cns11_gntgain,
} from "@/assets/js/cns11.js";
import {
  apstar5,
  apstar5gain,
  apstar5_gnt,
  apstar5_gntgain,
} from "@/assets/js/apstar5.js";
import {
  apstar92,
  apstar92gain,
  apstar92_gnt,
  apstar92_gntgain,
} from "@/assets/js/apstar9.js";
import $ from "jquery";
import { abs, absgain, abs_gnt, abs_gntgain } from "@/assets/js/abs.js";
import { ocn, ocngain, ocn_gnt, ocn_gntgain } from "@/assets/js/oceanbeam.js";
import {
  hts,
  htsgain,
  hts_gnt,
  hts_gntgain,
} from "@/assets/js/hts.js";

import { beam3, beam3gain, beam3_gnt, beam3_gntgain } from "@/assets/js/beam3.js";
import { horizon, horizongain, horizon_gnt, horizon_gntgain } from "@/assets/js/horizon.js";
import { utelsat, utelsatgain, utelsat_gnt, utelsat_gntgain } from "@/assets/js/utelsat.js";
import { ReInviteUserAgentServer } from 'sip.js/lib/core';



const iconUrl = 'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDIuODggMy40NiI+PGRlZnM+PHN0eWxlPi5ke2ZpbGw6IzAwMDBGRjtzdHJva2U6I2ZmZjtzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6LjJweDt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9ImEiLz48ZyBpZD0iYiI+PGcgaWQ9ImMiPjxwYXRoIGNsYXNzPSJkIiBkPSJNMi42OSwzLjI2bC0uOTYtLjQ1Yy0uMTktLjA5LS40LS4wOS0uNTksMGwtLjk2LC40NUwxLjQ0LC4yNmwxLjI1LDNaIi8+PC9nPjwvZz48L3N2Zz4='


export default {
  components: {
    LPopup,
    LIcon,
    LMap,
    LTileLayer,
    LControl,
    LPolygon,
    QuotaChart,
    QuotaChartLine,
    LControlZoom,
    LControlPolylineMeasure,
    LControlFullscreen,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
    LMovingRotatedMarker
  },







  data() {
    return {
      satnet_id: null,
      switch_satnet_modal: false,
      onFinish: false,
      currentLine: null,
      coords: null,
      toolTipsArr: null,
      cant_future: false,
      cant_backwards: false,
      testdisabled: false,
      exp_range: 'today',
      IntervalMoving: null,
      fetchInterval: null,
      latdms: null,
      itemsGambar: [
      ],
      icon: L.icon({
        iconUrl: iconUrl,
        iconSize: [17, 22],
        iconAnchor: [8.5, 11.5],
        popupAnchor: [0, -25]
      }),
      drivePath: null,
      driveLatLng: null,
      driveRotationAngle: null,
      driveMarker: null,
      showclick_dateTime: [],
      map_sailink_fs: false,
      totalFilter: null,
      totalFilterUser: null,
      itemsPerPageUser: 5,
      showonMarker: false,
      searchUserWho: "",
      qdaily: null,
      weatherData: [],
      imageOpenData: null,
      showonMarker: false,
      showRoute: false,
      showRouteData: null,
      showRouteEmpty: [{ index: 0, lat: "-", lng: "-", br: "-" }],
      brRouteData: null,
      headersRoute: [
        {
          text: "#",
          value: "index",
        },
        { text: "Latitude/Longitude", value: "Latitude" },
        { text: "Distance/Bearing", value: "Distance/Bearing" },
      ],
      onDraw: null,
      etaControl: false,
      url: "https://maps.skyreach.id/sailink/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      datepickerID: null,
      dates: [],
      times: [],
      timein: null,
      menu: false,
      a: 0,
      measurepoly: 0,
      mobileBreakpoint: 600,
      singleExpand: false,
      expanded: [],
      dateopen: false,
      timeopen: false,
      itemsPerPage: 10,
      itemsPerPageCoordinate: 10,
      page: 1,
      pageCount: 0,
      pageUser: 1,
      pageCountUser: 0,
      pageImage: 1,
      pageCountImage: 0,
      pageCoordinate: 1,
      pageCountCoordinate: 0,
      search: "",
      countLength: 0,
      markers: [
        {
          id: "a",
          position: { lat: 3, lng: 101 },
        },
        {
          id: "b",
          position: { lat: 5, lng: 99 },
        },
        {
          id: "c",
          position: { lat: 6, lng: 97 },
        },
      ],
      lines: [
        {
          id: "1",
          path: [
            { lat: 3, lng: 101 },
            { lat: 5, lng: 99 },
          ],
        },
        {
          id: "2",
          path: [
            { lat: 5, lng: 99 },
            { lat: 6, lng: 97 },
          ],
        },
      ],

      path: [
        { lat: -1.67292, lng: 100.63035 },
        { lat: -4.01517, lng: 102.28569 },
      ],

      loadernavplus: require("@/assets/img/load100new.gif"),
      headersData: [
        {
          text: "No",
          align: "start",
          sortable: false,
        },
        { text: "Latitude, Longtitude (DMS)", value: "lat", sortable: false },
        { text: "Heading (Degree)", value: "heading", sortable: false },
        { text: "Speed (Knots)", value: "speed", sortable: false },
        { text: "Distance(Nmi)", value: "distance", sortable: false },
        { text: "RX SNR (db)", value: "rx_snr", sortable: false },
        { text: "RX MODCOD", value: "modcod", sortable: false },
        { text: "TX Power(db)", value: "tx", sortable: false },
        { text: "SatNet", value: "satnet", sortable: false },
        { text: "Date/Time (GMT+7)", value: "dateTime", sortable: false },
        { text: "Link Status", value: "good", sortable: false },
      ],
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
        },
        { text: "Latitude, Longtitude (DMS)", value: "lat", sortable: false },
        {
          text: "",
          align: "start",
          sortable: false,
        },
        { text: "Heading (Degree)", value: "heading", sortable: false },
        { text: "Speed (Knots)", value: "speed", sortable: false },
        { text: "Distance(Nmi)", value: "distance", sortable: false },
        { text: "RX SNR (db)", value: "rx_snr", sortable: false },
        { text: "RX MODCOD", value: "modcod", sortable: false },
        { text: "TX Power(db)", value: "tx", sortable: false },
        { text: "SatNet", value: "satnet", sortable: false },
        { text: "Date/Time (GMT+7)", value: "dateTime", sortable: false },
        { text: "Link Status", value: "good", sortable: false },
      ],

      headersUser: [
        {
          text: "#",
          align: "start",
          sortable: false,
        },
        { text: "Name", value: "name", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Role", value: "role_name", sortable: false },
        { text: "Last Login", value: "lastLogin", sortable: false },
      ],

      options: {
        disableDefaultUI: true,
        fullscreenControl: true,
        zoomControl: true,
        styles: mapstyle,
      },
      model: 0,
      etaArrival: "",
      etaDuration: 0,
      etaSpeed: 1,
      etaMiles: 0,
      unit: "nauticalmiles",
      satellite: require("@/assets/img/satellite.png"),
      imageOpen: null,
      totalDistance: null,
      imageData: null,
      msg_id_after: null,
      paths_cns11: cns11,
      gain_cns11: cns11gain,
      paths_cns11gnt: cns11_gnt,
      gain_cns11gnt: cns11_gntgain,
      paths_apstar5: apstar5,
      gain_apstar5: apstar5gain,
      paths_apstar5gnt: apstar5_gnt,
      gain_apstar5gnt: apstar5_gntgain,
      paths_apstar9: apstar92,
      gain_apstar9: apstar92gain,
      paths_apstar9gnt: apstar92_gnt,
      gain_apstar9gnt: apstar92_gntgain,
      paths_abs: abs,
      gain_abs: absgain,
      paths_absgnt: abs_gnt,
      gain_absgnt: abs_gntgain,
      paths_ocn: ocn,
      gain_ocn: ocngain,
      paths_ocngnt: ocn_gnt,
      gain_ocngnt: ocn_gntgain,
      paths_hts: hts,
      gain_hts: htsgain,
      paths_htsgnt: hts_gnt,
      gain_htsgnt: hts_gntgain,
      paths_beam3: beam3,
      gain_beam3: beam3gain,
      paths_beam3gnt: beam3_gnt,
      gain_beam3gnt: beam3_gntgain,
      paths_hrz: horizon,
      gain_hrz: horizongain,
      paths_hrzgnt: horizon_gnt,
      gain_hrzgnt: horizon_gntgain,
      paths_utl: utelsat,
      gain_utl: utelsatgain,
      paths_utlgnt: utelsat_gnt,
      gain_utlgnt: utelsat_gntgain,
      EIRP: null,
      GNT: null,
      SKYR1: null,
      SKYR2: null,
      APST9: null,
      ABS2A: null,
      SR1MR: null,
      SR2MR: null,
      BEAM3: null,
      UTL70B: null,
      HRZ: null,
      polygon: {
        skyr1: "green",
        skyr2: "blue",
        apst9: "purple",
        abs2a: "orange",
        sr1mr: "#F7038B",
        beam3: "#DB6B0F",
        hrz: "#DBC70f",
        utl: "#0CAD93"
      },
    };
  },


  mounted() {
    var mama = this;
    if (!mama.sailink_executed) {
      //console.log("jalan title map");
      mama.addsailink_executed(true);
      $("html, body").animate(
        {
          scrollTop: $("#title_map_sailink").offset().top - 109,
        },
        800
      );
    }
  },

  methods: {
    ...mapActions([
      "removeSatnetOutput",
      "removeQuota",
      "showondateorb",
      "lnStatus",
      "showweather",
      "removeShowMap",
      "fetchQuota",
      "addsailink_executed",
      "post_switch_satnet",
    ]),

    //moving marker
    initialdata_moving() {
      var mother = this
      mother.drivePath = mother.showmaptrackingorb.tracking.slice()
      mother.driveLatLng = L.latLng([mother.drivePath[0].lat, mother.drivePath[0].lng])
      mother.driveRotationAngle = mother.drivePath[0].heading
    },

    post_switch_satnet_() {
      // console.log(this.ip_adr.ip_addr)
      const data = {
        ip_addr: this.ip_adr.ip_addr_val,
        iq_series: this.ip_adr.iq_series,
        satnet_id: this.satnet_id,
        token: localStorage.getItem("token"),
      };
      // console.log(data);
      this.post_switch_satnet(data);
      setTimeout(function () {
        this.satnet_id = null;
      }, 4000);
    },

    logtest(date) {
      console.log('DI CLICK')
      console.log(date)
      this.testdisabled = true;
      this.timeopen = true;
    },

    saveTimes() {
      this.times.push(this.timein);
      this.timein = null;
      this.timeopen = false;
      this.testdisabled = false;
    },

    //moving marker
    newAnimation() {
      this.drivePath = null;
      clearInterval(this.IntervalMoving);
      this.initialdata_moving()
    },



    //moving marker
    movingStart() {
      var mother = this
      this.IntervalMoving = setInterval(function () {
        mother.simulate()
      }, 250);

    },


    //moving marker
    simulate() {
      this.countLength = this.countLength + 1
      // console.log(this.countLength)
      // console.log(this.drivePath)


      if (!this.drivePath.length) {
        this.$refs.driveMarker.mapObject.setLatLng(L.latLng([this.drivePath[0].lat, this.drivePath[0].lng]))
        this.drivePath = this.drivePath.slice()
        return
      }
      let point = this.drivePath.shift()
      this.driveLatLng = L.latLng([point.lat, point.lng])
      this.driveRotationAngle = point.heading
      // console.log(this.driveRotationAngle)


      if (this.drivePath.length == 0 || !this.drivePath) {
        clearInterval(this.IntervalMoving);
        this.drivePath = null;
      }

    },

    triggerFullscreen() {
      if (this.$refs["map_sailink"].mapObject._isFullscreen != true) {
        this.$refs.map_sailink.mapObject.scrollWheelZoom.disable();
      } else {
        this.$refs.map_sailink.mapObject.scrollWheelZoom.enable();
      }
    },

    intoFilter(pagination) {
      this.totalFilter = pagination.itemsLength;
    },

    intoFilterUser(pagination) {
      this.totalFilterUser = pagination.itemsLength;
    },

    closeShowMap() {
      clearInterval(this.IntervalMoving);
      this.removeShowMap();
      $("html, body").animate(
        {
          scrollTop: $("#top_mysailink").offset().top - 140,
        },
        800
      );
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    closeETA() {
      this.etaControl = false;
      this.clearMeasure();
      this.totalDistance = 0;
      this.showonMarker = false;
      this.showRoute = false;
      if (this.$refs["map_sailink"].mapObject._isFullscreen != true) {
      } else {
        this.$refs["map_sailink"].mapObject.toggleFullscreen();
      }
    },

    draw_Circle() {
      var myMap = this.$refs.map_sailink.mapObject;
      var latLong = this.showmaptrackingorb.tracking;
      var pointList = this.showmaptrackingorb.tracking;

      const lightbox = new PhotoSwipeLightbox({
        gallery: '#sailink-gallery-container',
        children: 'a',
        pswpModule: () => import('photoswipe')
      });
      lightbox.init();





      var mama = this;
      var firstpolyline = new L.Polyline(pointList, {
        color: "#FEE800",
        weight: 3,
        opacity: 1,
        smoothFactor: 1,
      });
      firstpolyline.addTo(myMap);
      // Here's where you iterate over the array of coordinate objects.
      latLong.forEach(function (coord) {
        if (
          coord.lat != mama.showmaptrackingorb.maptop.lat &&
          coord.lng != mama.showmaptrackingorb.maptop.lon
        ) {
          var circle = L.circle(coord, {
            color: "#1DC01D",
            fillColor: "#1DC01D",
            fillOpacity: 0.5,
            radius: 1100,
          })
            .addTo(myMap)
            .bindPopup(
              "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
              coord.lat +
              "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
              coord.lng +
              "</td></tr><tr>" +
              "<td><b>DMS</b></td><td>:</td>" +
              "<td>" +
              coord.dms +
              "</td>" +
              "</tr>" +
              "<tr>" +
              "<td><b>Speed</b></td>" +
              "<td>:</td>" +
              " <td>" +
              coord.speed +
              "&nbsp;knots </td>" +
              "</tr>" +
              "<tr>" +
              " <td><b>Heading</b></td>" +
              "<td>:</td>" +
              "<td>" +
              coord.heading +
              "</td>" +
              " </tr>" +
              "<tr>" +
              "<td><b>Last Update</b></td>" +
              "<td>:</td>" +
              "<td>" +
              coord.updated +
              "</td>" +
              "</tr>" +
              "</table>"
            );
        }
      });
      var mom = this
      this.newAnimation()
      setTimeout(
        function () {
          mom.movingStart()
        }, 1000);



    },

    triggerPopup_mobile(coord) {
      //console.log(coord);
      $("html, body").animate(
        {
          scrollTop: $("#map_sailink_").offset().top - 109,
        },
        800
      );
      var myMap = this.$refs.map_sailink.mapObject;
      var popLocation = new L.LatLng(coord.lat, coord.lng);
      myMap.setView(popLocation, 6);
      var popup = L.popup()
        .setLatLng(popLocation)
        .setContent(
          "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
          coord.lat +
          "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
          coord.lng +
          "</td></tr><tr>" +
          "<td><b>DMS</b></td><td>:</td>" +
          "<td>" +
          coord.dms +
          "</td>" +
          "</tr>" +
          "<tr>" +
          "<td><b>Speed</b></td>" +
          "<td>:</td>" +
          " <td>" +
          coord.speed +
          "&nbsp;knots </td>" +
          "</tr>" +
          "<tr>" +
          " <td><b>Heading</b></td>" +
          "<td>:</td>" +
          "<td>" +
          coord.heading +
          "</td>" +
          " </tr>" +
          "<tr>" +
          "<td><b>Last Update</b></td>" +
          "<td>:</td>" +
          "<td>" +
          coord.dateTime +
          "</td>" +
          "</tr>" +
          "</table>"
        )
        .openOn(myMap);
    },

    triggerPopup(coord) {
      //console.log(coord);
      $("html, body").animate(
        {
          scrollTop: $("#title_map_sailink").offset().top - 109,
        },
        800
      );
      var myMap = this.$refs.map_sailink.mapObject;
      var popLocation = new L.LatLng(coord.lat, coord.lng);
      myMap.setView(popLocation, 6);
      var popup = L.popup()
        .setLatLng(popLocation)
        .setContent(
          "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
          coord.lat +
          "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
          coord.lng +
          "</td></tr><tr>" +
          "<td><b>DMS</b></td><td>:</td>" +
          "<td>" +
          coord.dms +
          "</td>" +
          "</tr>" +
          "<tr>" +
          "<td><b>Speed</b></td>" +
          "<td>:</td>" +
          " <td>" +
          coord.speed +
          "&nbsp;knots </td>" +
          "</tr>" +
          "<tr>" +
          " <td><b>Heading</b></td>" +
          "<td>:</td>" +
          "<td>" +
          coord.heading +
          "</td>" +
          " </tr>" +
          "<tr>" +
          "<td><b>Last Update</b></td>" +
          "<td>:</td>" +
          "<td>" +
          coord.dateTime +
          "</td>" +
          "</tr>" +
          "</table>"
        )
        .openOn(myMap);
    },

    toDegreesMinutesAndSeconds(coordinate, pad) {
      var absolute = Math.abs(coordinate);
      var degrees = Math.floor(absolute);
      var minutesNotTruncated = (absolute - degrees) * 60;
      var minutes = Math.floor(minutesNotTruncated);
      var seconds = Math.floor((minutesNotTruncated - minutes) * 60);
      //eslint-disable-next-line
      return (
        String(degrees).padStart(pad, "0") +
        "°" +
        String(minutes).padStart(2, "0") +
        " ' " +
        String(seconds).padStart(2, "0") +
        '"'
      );
    },

    convertDMS(lat, lng) {
      var latitude = this.toDegreesMinutesAndSeconds(lat, 2);
      var latitudeCardinal = lat >= 0 ? "N" : "S";

      var longitude = this.toDegreesMinutesAndSeconds(lng, 3);
      var longitudeCardinal = lng >= 0 ? "E" : "W";

      return (
        latitude +
        " " +
        latitudeCardinal +
        "-" +
        longitude +
        " " +
        longitudeCardinal
      );
    },

    fetchQdialy(service) {
      this.qdaily = true;
      var data = {
        vessel_id: this.showmaporbcommdata.id,
        service: service,
      };
      this.fetchQuota(data);
    },

    fullscreen() {
      $(".leaflet-control-fullscreen-button").trigger("click");
    },

    clearMeasure() {
      console.log('SELESAI');
      this.$refs["polylineMeasure"].mapObject._clearAllMeasurements();
      this.$refs["polylineMeasure"].mapObject._toggleMeasure();
      this.etaMiles = 0;
      this.etaDuration = 0;
      this.etaArrival = "";
      this.showRouteData = null;
    },

    handleToggle(toogle) {
      console.log("ke tugel");
      this.onDraw = toogle.sttus
      console.log(toogle.sttus)

    },

    handleFinish() {
      console.log("ITS FINISH");
      this.onCount();
      this.onFinish = true;
    },

    //Start Draw [ETA]
    startDraw() {

      if (this.onDraw == true && this.showRouteData != null) {
        this.clearMeasure();
      }
      this.$refs["polylineMeasure"].mapObject._toggleMeasure();
      if (this.showonMarker) {
        var latLng = L.latLng([
          parseFloat(this.showmaptrackingorb.maptop.lat),
          parseFloat(this.showmaptrackingorb.maptop.lon)
        ]);

        this.$refs["polylineMeasure"].mapObject._startLine(latLng);
        this.$refs["polylineMeasure"].mapObject._mouseMove({ latLng });
        this.$refs["polylineMeasure"].mapObject._currentLine.addPoint(latLng);
      }
    },

    onCount_move() {
      console.log('HITUNG ROUTE');
      console.log(this.currentLine)
      console.log(this.$refs["polylineMeasure"].mapObject._arrPolylines[0].circleCoords)
      this.currentLine = this.$refs["polylineMeasure"].mapObject._arrPolylines;
      this.coords = this.$refs["polylineMeasure"].mapObject._arrPolylines[0].circleCoords
      this.toolTipsArr = this.$refs["polylineMeasure"].mapObject._arrPolylines[0].tooltips;
      this.showRouteData = this.coords.map((item, index) =>
        Object.assign(item, { index })
      );
      this.brRouteData = this.toolTipsArr;
      var miles = 0;
      this.coords.forEach((coor, i) => {
        this.totalDistance = this.toolTipsArr[i]._icon.childNodes[4].innerText;
        miles = parseFloat(this.toolTipsArr[i]._icon.childNodes[4].innerText);
      });

      this.etaMiles = miles;
      var duration_raw = miles / this.etaSpeed;
      var duration = duration_raw.toFixed(2);
      var departDateInput = $("#departure-sailink").val();
      var today = new Date(departDateInput);
      var seconds = Math.round(duration * 60 * 60);
      today.setSeconds(today.getSeconds() + seconds);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      var h = today.getHours();
      var i = today.getMinutes();

      if (h < 10) {
        h = "0" + h;
      }

      if (i < 10) {
        i = "0" + i;
      }

      today = dd + "/" + mm + "/" + yyyy + " " + h + ":" + i;
      this.etaDuration = duration;
      this.etaArrival = today;
    },

    onCount() {
      console.log('HITUNG ROUTE');
      console.log(this.currentLine);
      this.currentLine = this.$refs["polylineMeasure"].mapObject._currentLine;
      this.coords = this.$refs["polylineMeasure"].mapObject._currentLine.circleCoords;
      this.toolTipsArr = this.$refs["polylineMeasure"].mapObject._currentLine.tooltips;
      this.showRouteData = this.coords.map((item, index) =>
        Object.assign(item, { index })
      );
      this.brRouteData = this.toolTipsArr;
      var miles = 0;
      this.coords.forEach((coor, i) => {
        this.totalDistance = this.toolTipsArr[i]._icon.childNodes[4].innerText;
        miles = parseFloat(this.toolTipsArr[i]._icon.childNodes[4].innerText);
      });

      this.etaMiles = miles;
      var duration_raw = miles / this.etaSpeed;
      var duration = duration_raw.toFixed(2);

      var departDateInput = $("#departure-sailink").val();
      var today = new Date(departDateInput);
      var seconds = Math.round(duration * 60 * 60);
      today.setSeconds(today.getSeconds() + seconds);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      var h = today.getHours();
      var i = today.getMinutes();

      if (h < 10) {
        h = "0" + h;
      }

      if (i < 10) {
        i = "0" + i;
      }

      today = dd + "/" + mm + "/" + yyyy + " " + h + ":" + i;
      this.etaDuration = duration;
      this.etaArrival = today;
    },

    onCountTotal() {
      console.log('HITUNG ROUTE');
      console.log(this.currentLine);
      this.showRouteData = this.coords.map((item, index) =>
        Object.assign(item, { index })
      );
      this.brRouteData = this.toolTipsArr;
      var miles = 0;
      this.coords.forEach((coor, i) => {
        this.totalDistance = this.toolTipsArr[i]._icon.childNodes[4].innerText;
        miles = parseFloat(this.toolTipsArr[i]._icon.childNodes[4].innerText);
      });

      this.etaMiles = miles;
      var duration_raw = miles / this.etaSpeed;
      var duration = duration_raw.toFixed(2);
      var departDateInput = $("#departure-sailink").val();
      var today = new Date(departDateInput);
      var seconds = Math.round(duration * 60 * 60);
      today.setSeconds(today.getSeconds() + seconds);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      var h = today.getHours();
      var i = today.getMinutes();

      if (h < 10) {
        h = "0" + h;
      }

      if (i < 10) {
        i = "0" + i;
      }

      today = dd + "/" + mm + "/" + yyyy + " " + h + ":" + i;
      this.etaDuration = duration;
      this.etaArrival = today;
    },

    countETA() {
      var duration_raw = this.etaMiles / this.etaSpeed;
      var duration = duration_raw.toFixed(2);
      var departDateInput = $("#departure-sailink").val();
      var today = new Date(departDateInput);
      var seconds = Math.round(duration * 60 * 60);
      today.setSeconds(today.getSeconds() + seconds);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      var h = today.getHours();
      var i = today.getMinutes();

      if (h < 10) {
        h = "0" + h;
      }

      if (i < 10) {
        i = "0" + i;
      }

      today = dd + "/" + mm + "/" + yyyy + " " + h + ":" + i;
      //console.log("arrival date " + today);
      this.etaDuration = duration;
      this.etaArrival = today;
    },

    customRange() {

      var tglcurrent = new Date();
      var tgldate1 = new Date(this.dates[0] + ' ' + this.times[0] + ':00');
      var tgldate2 = new Date(this.dates[1] + ' ' + this.times[1] + ':00');



      if (tgldate1.getTime() <= tglcurrent.getTime() && tgldate2.getTime() <= tglcurrent.getTime()) {
        if (tgldate1 > tgldate2) {
          this.cant_backwards = true
          this.resetTimesDates();
          setTimeout(() => {
            this.cant_backwards = false
          }, 5000)
        } else {
          this.dateopen = false;
          this.cant_future = false
          // var range_date = this.dates.join(" ");
          var range1 = this.dates[0].split('-');
          var range1_ = range1.join('_');

          var range2 = this.dates[1].split('-');
          var range2_ = range2.join('_');

          var timerange1 = this.times[0].split(':')
          var timerange1_ = timerange1.join('m')

          var timerange2 = this.times[1].split(':')
          var timerange2_ = timerange2.join('m')

          var range_date = range1_ + ' ' + timerange1_ + ' - ' + range2_ + ' ' + timerange2_;
          console.log(range_date);
          this.resetTimesDates();
          this.showdate(this.datepickerID, range_date);
        }

      } else {
        this.cant_future = true
        this.resetTimesDates();
        setTimeout(() => {
          this.cant_future = false
        }, 5000)
      }

    },

    closeCostumRange() {
      this.dateopen = false
      this.resetTimesDates();
    },

    resetTimesDates() {
      console.log('di reset')
      this.dates = []
      this.times = []
    },

    createCSV() {

      var parts = this.expsailink_title.split(' ').join('_');
      var rangedate = this.showmaptrackingorb.range.actual.split(' ').join('_')
      let pdfName = parts + "_Tracking_Reports_" + rangedate;

      var ln_status;
      var tableTrackingOrb = this.tableTrackingOrb.map((item, index) =>
        Object.assign(item, { index })
      );
      let data = [];
      let header = [
        ["#"],
        ["latitude"],
        ["longitude"],
        ["DMS"],
        ["Heading (Degree)"],
        ["Speed (Knots)"],
        ["Distance (Nmi)"],
        ["RX SNR (db)"],
        ["RX MODCOD"],
        ["TX Power (db)"],
        ["SatNet"],
        ["Date/Time (GMT+7)"],
        ["Link Status"],
      ];

      tableTrackingOrb.forEach((element) => {
        if (element.isActive == 1) {
          ln_status = "UP";
        } else {
          ln_status = "DOWN";
        }
        data.push([
          [element.index + 1],
          [element.lat, element.lng, `(` + element.dms + `)`],
          [element.heading],
          [element.speed],
          [element.distance],
          [element.rx_snr],
          [element.modcod],
          [element.tx],
          [element.satnet],
          [element.dateTime],
          [ln_status],
        ]);
      });

      let csvContent = data.map((e) => e.join(",")).join("\n");

      // var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent));
      link.setAttribute("download", pdfName + ".csv");
      document.body.appendChild(link); // Required for FF
      link.click();
    },

    showdate(item, range) {
      clearInterval(this.IntervalMoving);
      this.exp_range = range;
      this.countLength = 0;
      const data = {
        id: item,
        range: range,
        token: localStorage.getItem("token"),
      };

      this.showondateorb(data);
    },

    linkStatus(id) {
      const dataLn = {
        id: id,
        token: localStorage.getItem("token"),
      };

      this.lnStatus(dataLn);
    },

    async showwth(vid, msgid, type) {
      this.msg_id_after = msgid;
      const data = {
        id: vid,
        msg_id: msgid,
        type: type,
      };
      this.showweather(data);
    },

    // eta button clicked
    openETA() {
      this.etaControl = !this.etaControl;
      let now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      $("#departure-sailink").val(now.toISOString().slice(0, 16));
      if (this.$refs["map_sailink"].mapObject._isFullscreen != true) {
        this.$refs["map_sailink"].mapObject.toggleFullscreen();
      } else {
        //console.log("ETA ALREADY OPEN");
      }
    },

    removeWeather(dateTime) {

      this.$delete(this.showclick_dateTime, dateTime);
      this.$delete(this.weatherData, dateTime);
    },

    async weatherClick(id, msgid, type, dateTime) {

      this.$set(this.showclick_dateTime, dateTime, dateTime);
      const datawth = {
        id: id,
        msg_id: msgid,
        type: type,
      };
      const response = await axios.post(
        "https://navigatorplus.sailink.id/api/v1/navplus/weather/fetchRow",
        datawth,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      console.log('FETCH WEATHER')
      console.log(response);
      this.$set(this.weatherData, dateTime, response.data.data);
    },
  },

  computed: {
    ...mapGetters([
      "switch_true",
      "satnet_output",
      "expsailink_title",
      "user_allowed",
      "sailink_executed",
      "searchVesselID",
      "serviceQuota",
      "quotaData",
      "showmaporbcommdata",
      "tableTrackingOrb",
      "vesselUsersOrb",
      "ip_adr",
      "showmaptrackingorb",
      "ln_status",
      "weather_data",
      "showmapclick",
      "startFrom",
    ]),

    showingDatafrom() {
      if (this.totalFilter < 1) {
        return 0;
      } else {
        return 1;
      }
    },

    isFullscreen_() {
      return this.map_sailink_fs;
    },

    showingDatato() {
      if (this.totalFilter < this.itemsPerPage) {
        return this.totalFilter;
      } else {
        return this.itemsPerPage;
      }
    },

    showingDatafromUser() {
      if (this.totalFilterUser < 1) {
        return 0;
      } else {
        return 1;
      }
    },

    showingDatatoUser() {
      if (this.totalFilterUser < this.itemsPerPageUser) {
        return this.totalFilterUser;
      } else {
        return this.itemsPerPageUser;
      }
    },

    dateRangeText() {
      return this.dates.join(" ~ ");
    },

    mapConfig() {
      return {
        ...mapSettingsShow,
        center: this.mapCenter,
      };
    },

    mapCenter() {
      return {
        lat: parseFloat(this.showmaporbcommdata.maptop.lat),
        lng: parseFloat(this.showmaporbcommdata.maptop.lon),
      };
    },
  },
};
</script>

<style lang="scss" scopped>
@import "@/assets/css/showmapsailink.css";
@import "@/assets/css/navigator.css";
@import "@/assets/css/showmapSailink_.scss";
</style>