/* eslint no-console: "error" */
import axios from "../../plugins/axios";

const getDefaultState = () => {
  return {
    orbcomm_data: null,
    showmaporbcommdata:null,
    vesselUsersOrb:null,
    tableTrackingOrb:null,
    showmaptrackingorb:null,
    trackPathOrb:null,
  };
};

const state = getDefaultState();

const getters = {
  orbcomm_data: (state) => state.orbcomm_data,
  showmaporbcommdata: (state) => state.showmaporbcommdata,
  vesselUsersOrb:(state) => state.vesselUsersOrb,
  tableTrackingOrb:(state) => state.tableTrackingOrb,
  showmaptrackingorb:(state) => state.showmaptrackingorb,
  trackPathOrb:(state) => state.trackPathOrb,
};

const actions = {
  async fetchOrbComm({ commit }, data) {
    try {
      const response = await axios.get("/api/v1/navplus/orbcomm", {
        headers: {
          Authorization: "Bearer" + data,
        },
      });

      console.log("ORB COMM DATA ")

      console.log(response.data.data.vessels)
      commit(
        "setOrbComm",
        response.data.data.vessels.map((item, index) =>
          Object.assign(item, { index })
        )
      );
    } catch (e) {
      console.log(e);
    }
  },


  async showonMapOrbComm({ commit }, data) {
    commit("setshowmaporbdata", null);
    commit("setvesselUsers", null);
    commit("settableTracking", null);
    // commit("setQuotaData", null);
    try {
      const response = await axios.get("/api/v1/navplus/orbcomm/" + data.id, {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      });
      console.log("RESPON DARI FETCH API KE SERVER");
      console.log(response);
      var dataTable = await response.data.data;

      commit("setshowmaporbdata", dataTable);
      commit(
        "setvesselUsersOrb",
        dataTable.vesselUsers.map((item, index) =>
          Object.assign(item, { index })
        )
      );
      console.log("TABEL TRACKING/DATATABLE");
      console.log(dataTable.dataTable);
      commit(
        "settableTrackingOrb",
        dataTable.dataTable.map((item, index) => Object.assign(item, { index }))
      );
    } catch (e) {
      console.log(e);
      console.log("ERROR RESPON DARI FETCH API KE SERVER");
    }
  },


  async showondateorb({ commit }, data) {
    commit("setshowmaptrackingorb", null);
    const datadate = {
      id: data.id,
      range: data.range,
    };
    const response = await axios.post(
      "/api/v1/navplus/orbcomm/date",
      datadate,
      {
        headers: {
          Authorization: "Bearer" + data.token,
        },
      }
    );
    console.log("masuk ambil tracking");
    console.log(response);
    commit("setshowmaptrackingorb", await response.data.data);
    commit("settrackPathOrb", response.data.data.tracking.slice());

    commit(
      "settableTrackingOrb",
      response.data.data.dataTable.map((item, index) =>
        Object.assign(item, { index })
      )
    );
  },
  
};

const mutations = {
    setOrbComm: (state, orbcomm_data) => (state.orbcomm_data = orbcomm_data),
    setshowmaporbdata: (state, showmaporbcommdata) => (state.showmaporbcommdata = showmaporbcommdata),
    setvesselUsersOrb: (state, vesselUsersOrb) => (state.vesselUsersOrb = vesselUsersOrb),
    settableTrackingOrb: (state, tableTrackingOrb) => (state.tableTrackingOrb = tableTrackingOrb),
    setshowmaptrackingorb: (state, showmaptrackingorb) => (state.showmaptrackingorb = showmaptrackingorb),
    settrackPathOrb: (state, trackPathOrb) => (state.trackPathOrb = trackPathOrb),
};

export default {
  state,
  getters,
  actions,
  mutations,
};
