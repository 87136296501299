import axios from "../../plugins/axios";

const state = {
    OffSummary:null,
    fleetSummary: null,
    sailinkSummary: null,
    thurayaSummary: null,
    terminalList: null,
    phoneList: null,
    rateplanList: null,
    userList: null,
    groupList: null,
    companyList: null,
    iotList:null,
    svList:null,
    DTClick:null,
    dataDS:null,
    testData:null,
    summary_executed: false,
    testDataTable:null,
    ctgsummary:null,
    CLtitle:'Company List'
};

const getters = {
    OffSummary:(state) => state.OffSummary,
    DTClick:(state) => state.DTClick,
    fleetSummary: (state) => state.fleetSummary,
    sailinkSummary: (state) => state.sailinkSummary,
    thurayaSummary: (state) => state.thurayaSummary,
    terminalList: (state) => state.terminalList,
    phoneList: (state) => state.phoneList,
    rateplanList: (state) => state.rateplanList,
    userList: (state) => state.userList,
    groupList: (state) => state.groupList,
    companyList: (state) => state.companyList,
    dataDS:(state) => state.dataDS,
    testData:(state) => state.testData,
    summary_executed:(state) =>state.summary_executed,
    testDataTable:(state) => state.testDataTable,
    ctgsummary:(state) => state.ctgsummary,
    CLtitle:(state) => state.CLtitle,
    iotList:(state) => state.iotList,
    svList:(state)=>state.svList
};

const actions = {
    async fetchCL({commit}, data) {
        const response = await axios.get("/api/v1/navplus/summary", {
            headers: {
                Authorization: "Bearer" + data,
            },
        });
      
        commit("setcompanyList", response.data.ptArr.map((item, index) =>
            Object.assign(item, {index})
        ));
    },

    async fetchFS({commit}, data) {
        commit("setFleetSummary", null);
        const response = await axios.get("/api/v1/navplus/summary", {
            headers: {
                Authorization: "Bearer" + data,
            },
        });
      
        commit("setFleetSummary", response.data.fleetArr.map((item, index) =>
        Object.assign(item, {index})
    ));
    },

    async fetchSummary({commit}, data) {
       try{
        const response = await axios.get("/api/v1/navplus/summary", {
            headers: {
                Authorization: "Bearer" + data,
            },
        });
        //console.log('SUMMARY DATA')
        console.log(response);
        commit("setFleetSummary", response.data.fleetArr.map((item, index) =>
            Object.assign(item, {index})
        ));
        commit("setsailinkSummary", response.data.sailinkArr.map((item, index) =>
            Object.assign(item, {index})
        ));

        commit("setthurayaSummary", response.data.thurayaArr.map((item, index) =>
            Object.assign(item, {index})
        ));

        commit("setterminalList", response.data.terminalArr.map((item, index) =>
            Object.assign(item, {index})
        ));

        commit("setphoneList", response.data.ipArr.map((item, index) =>
            Object.assign(item, {index})
        ));

        commit("setrateplanList", response.data.rpArr.map((item, index) =>
            Object.assign(item, {index})
        ));

        commit("setuserList", response.data.userArr.map((item, index) =>
            Object.assign(item, {index})
        ));

        commit("setgroupList", response.data.grArr.map((item, index) =>
            Object.assign(item, {index})
        ));
        commit("setcompanyList", response.data.ptArr.map((item, index) =>
            Object.assign(item, {index})
        ));
        commit("setiotList", response.data.iotArr.map((item, index) =>
        Object.assign(item, {index})
    ));
    commit("setsvList", response.data.svArr.map((item, index) =>
    Object.assign(item, {index})
));
        commit("setOffSummary",null)
       }catch(e){
        commit("setOffSummary",true)
       }
    },

    async detailCompany_({commit},data){
        // commit("setcompanyList", null);
        // commit("setCLtitle",null)
        console.log(data)
            const response = await axios.post("/api/v1/navplus/summary/list", {'type':data.type}, {
                headers: {
                    Authorization: "Bearer" + data.token,
                },
            });
        console.log(response)
        console.log(commit)
        commit("setcompanyList", response.data.pts.map((item, index) =>
            Object.assign(item, {index})
        ));
        commit("setCLtitle",response.data.title)
    },


    removedetailSummary_({commit}){
        commit("settestData",null),
        commit("setDTClick",null)
    },

   async detailSummary_({commit},data){
    commit("setdataDS",null)
    commit("settestData",null)
    console.log(data)
        const response = await axios.post("/api/v1/navplus/summary/list", {'type':data.type}, {
            headers: {
                Authorization: "Bearer" + data.token,
            },
        });
        console.log('DETAIL SUMMARY');
        console.log(response)
        commit("setdataDS",response)
        commit("settestData",response)
        if(response.data.users){
            commit("settestDataTable", response.data.users.map((item, index) =>
            Object.assign(item, {index})
        ));    
        }else{
            commit("settestDataTable", response.data.vessels.map((item, index) =>
            Object.assign(item, {index})
        ));    
        }
       
    },

    addsummary_executed({commit}, data) {
        commit("setsummary_executed", data)
    },

    categorysummary_({commit},data){
        commit("setctgsummary",data)
    },

    addDTClick({commit},data){
        commit("setDTClick",data)
    }

};

const mutations = {
    setOffSummary:(state, OffSummary) => (state.OffSummary = OffSummary),
    setFleetSummary: (state, fleetSummary) => (state.fleetSummary = fleetSummary),
    setsailinkSummary: (state, sailinkSummary) => (state.sailinkSummary = sailinkSummary),
    setthurayaSummary: (state, thurayaSummary) => (state.thurayaSummary = thurayaSummary),
    setterminalList: (state, terminalList) => (state.terminalList = terminalList),
    setphoneList: (state, phoneList) => (state.phoneList = phoneList),
    setrateplanList: (state, rateplanList) => (state.rateplanList = rateplanList),
    setuserList: (state, userList) => (state.userList = userList),
    setgroupList: (state, groupList) => (state.groupList = groupList),
    setcompanyList: (state, companyList) => (state.companyList = companyList),
    setiotList: (state, iotList) => (state.iotList = iotList),
    setsvList: (state, svList) => (state.svList = svList),
    setdataDS:(state,dataDS) => (state.dataDS = dataDS),
    settestData:(state, testData) => (state.testData =testData),
    setsummary_executed:(state,summary_executed) => (state.summary_executed = summary_executed),
    settestDataTable:(state, testDataTable) => (state.testDataTable = testDataTable),
    setctgsummary:(state, ctgsummary) => (state.ctgsummary = ctgsummary),
    setCLtitle:(state, CLtitle) => (state.CLtitle = CLtitle),
    setDTClick:(state,DTClick) => (state.DTClick = DTClick),
    setOffSummary:(state,OffSummary) => (state.OffSummary = OffSummary)
};

export default {
    state,
    getters,
    actions,
    mutations,
};
