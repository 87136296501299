<template>
  <v-dialog class="elevation-0 rounded-0 pa-0" v-model="wlOpen" width="560" height="fit-content" persistent>
    <v-card class="elevation-0 rounded-0">
      <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7">
        <v-icon class="mr-2" small>mdi-cog</v-icon>White List Config
        <v-spacer></v-spacer>

        <v-btn small class="elevation-0 rounded-0" icon @click="closewlModal()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-divider></v-divider>
      <v-toolbar v-if="wlData" color="#FFFF" class="elevation-0 rounded-0" dense>
        <v-toolbar-subtitle style="font-size: 0.8em;text-align: left;">{{ wlData.vessel_number }}
        </v-toolbar-subtitle>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-toolbar v-if="wlData && wlData.latest_status != ''" color="#FFFF" class="elevation-0 rounded-0" dense>
        <v-toolbar-subtitle style="font-size: 0.8em;text-align: left;">
          <div v-if="wlData" style="text-align: left">

            <v-alert v-if="wlData.latest_status == 'FAILED'" class="elevation-0 rounded-0" dark dense type="error"
              icon="mdi-close-thick" style="font-size: 0.86em;text-align: left;">
              Failed to change Settings at {{ wlData.last_updated }}, please
              resubmit
            </v-alert>
            <v-alert v-if="wlData.latest_status == 'ON AIR'" class="elevation-0 rounded-0" dark dense type="warning"
              icon="mdi-clock" style="font-size: 0.86em;text-align: left;">
              Waiting for Confirmation, sent at {{ wlData.last_updated }}
            </v-alert>
            <v-alert v-if="wlData.latest_status == 'CONFIRMED'" class="elevation-0 rounded-0" dark dense color="green"
              icon="mdi-check-bold" style="font-size: 0.86em;text-align: left;">
              Updated at {{ wlData.last_updated }}
            </v-alert>
          </div>
        </v-toolbar-subtitle>

        <v-spacer></v-spacer>
      </v-toolbar>
      <v-divider v-if="wlData"></v-divider>

      <v-container style="margin-top: 0" v-if="!wlmodal" fill-height fluid>
        <v-row align="center" justify="center">
          <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
        </v-row>
      </v-container>

      <v-card-text v-if="wlmodal" id="div_wl" :style="wlData ? 'height:25em' : ''" class="overflow-y-auto pa-0">
        <v-container style="margin-top: 2em" v-if="!wlData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
          </v-row>
        </v-container>
        <div style="padding-left: 1.5em;" v-if="wlData && wlData.latest_status != 'ON AIR'" class="mt-10">

          <v-row no-gutters style="flex-wrap: nowrap">
            <v-col cols="1" style="min-width: 8em; margin: 5px">
              <v-row v-for="(item, i) in wlData.latest_names" :key="i" no-gutters>
                <v-col style="margin-top: -1.5em;">
                  <v-text-field style="font-size:1em" class="elevation-0 rounded-0 ma-0" :value="'Recipient ' + (i + 1)"
                    filled outlined readonly dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1" style="min-width: 100px; max-width: 100%; margin: 5px" class="flex-grow-1 flex-shrink-0">
              <v-row v-for="(item, i) in wlData.latest_names" :key="i" no-gutters>
                <v-col style="margin-top: -1.5em;margin-left: -0.75em;">
                  <v-text-field class="elevation-0 rounded-0" v-model="wlData.latest_names[i]"
                    :placeholder="wlData.latest_names[i] == '' && i == 0 ? 'Configuration Server' : wlData.latest_names[i] == '' && (i > 0 && i <= 6) ? 'NOC ' + (i) : 'Name ' + (a = i + 1)"
                    @input="checkdata('latest_names', i)" :filled="wlData.latest_names[i] == '' && (i >= 0 && i <= 6) ? true : item.includes('NOC') || item.includes('Configuration Server')
                      ? true
                      : false || user_allowed.allowed['edit whitelist contact']
                        ? false
                        : true
                      " outlined :readonly="wlData.latest_names[i] == '' && (i >= 0 && i <= 6) ? true : item.includes('NOC') || item.includes('Configuration Server')
    ? true
    : false || user_allowed.allowed['edit whitelist contact']
      ? false
      : true
    " dense :value="wlData.latest_names[i] != '' ? item : wlData.latest_names[i] == '' && i == 0 ? 'Configuration Server' : wlData.latest_names[i] == '' && (i > 0 && i <= 6) ? 'NOC ' + (i) : 'Name ' + (a = i + 1)"></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1" style="min-width: 100px; max-width: 100%; margin: 5px" class="flex-grow-1 flex-shrink-0">
              <v-row v-for="(item, i) in wlData.latest_content" :key="i" no-gutters>
                <v-col style="margin-top: -1.5em;margin-left: -1.5em;">
                  <v-text-field class="elevation-0 rounded-0" v-model="wlData.latest_content[i]"
                    @input="checkdata('latest_content', i)"
                    :value="wlData.latest_names[i] == '' && i == 0 ? '1005' : wlData.latest_names[i] == '' && (i > 0 && i <= 6) ? noc_numb[i + 1] : ''"
                    :placeholder="wlData.latest_names[i] == '' && i == 0 ? '1005' : wlData.latest_names[i] == '' && (i > 0 && i <= 6) ? noc_numb[i - 1] : 'Number ' + (a = i + 1)"
                    :filled="wlData.latest_names[i].includes('NOC') || wlData.latest_names[i].includes('Configuration Server')
                      ? true
                      : false || user_allowed.allowed['edit whitelist contact']
                        ? false
                        : true
                      " outlined :readonly="wlData.latest_names[i].includes('NOC') || wlData.latest_names[i].includes('Configuration Server')
    ? true
    : false || user_allowed.allowed['edit whitelist contact']
      ? false
      : true
    " dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row style="width: 100%;padding-left:0.3em;margin-top: -2em;">
            <v-col cols="12">
              <v-btn block small :disabled="changes_ ? false : true" class="elevation-0 rounded-0" @click="
                user_allowed.allowed['edit whitelist contact']
                  ? wlDataPost()
                  : console.log('disabled')
                " :color="user_allowed.allowed['edit whitelist contact']
    ? 'primary'
    : '#9CBDDE'
    " :style="user_allowed.allowed['edit whitelist contact']
    ? 'cursor:pointer'
    : 'cursor:not-allowed'
    ">
                Update White List Configuration
              </v-btn>
            </v-col>
          </v-row>
        </div>

        <div style="padding-left: 1.5em;" v-if="wlData && wlData.latest_status == 'ON AIR'" class="mt-10">
          <v-row no-gutters style="flex-wrap: nowrap">
            <v-col cols="1" style="min-width: 8em; margin: 5px">
              <v-row v-for="(item, i) in wlData.latest_names" :key="i" no-gutters>
                <v-col style="margin-top: -1.5em;">
                  <v-text-field style="font-size:1em" class="elevation-0 rounded-0 ma-0" :value="'Recipient ' + (i + 1)"
                    filled outlined readonly dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1" style="min-width: 100px; max-width: 100%; margin: 5px" class="flex-grow-1 flex-shrink-0">
              <v-row v-for="(item, i) in wlData.latest_names" :key="i" no-gutters>
                <v-col style="margin-top: -1.5em;margin-left: -0.75em;">
                  <v-text-field class="elevation-0 rounded-0" v-model="wlData.latest_names[i]"
                    :value="wlData.latest_names[i] != '' ? item : wlData.latest_names[i] == '' && i == 0 ? 'Configuration Server' : wlData.latest_names[i] == '' && (i > 0 && i <= 6) ? 'NOC ' + (i) : 'Name ' + (a = i + 1)"
                    :placeholder="wlData.latest_names[i] == '' && i == 0 ? 'Configuration Server' : wlData.latest_names[i] == '' && (i > 0 && i <= 6) ? 'NOC ' + (i) : 'Name ' + (a = i + 1)"
                    @input="checkdata('latest_names', i)" :filled="item.includes('NOC') || item.includes('Configuration Server')
                      ? true
                      : false || user_allowed.allowed['edit whitelist contact']
                        ? false
                        : true
                      " outlined :disabled="true" dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="1" style="min-width: 100px; max-width: 100%; margin: 5px" class="flex-grow-1 flex-shrink-0">
              <v-row v-for="(item, i) in wlData.latest_content" :key="i" no-gutters>
                <v-col style="margin-top: -1.5em;margin-left: -1.5em;">
                  <v-text-field class="elevation-0 rounded-0" v-model="wlData.latest_content[i]"
                    @input="checkdata('latest_content', i)"
                    :value="wlData.latest_names[i] == '' && i == 0 ? '1005' : wlData.latest_names[i] == '' && (i > 0 && i <= 6) ? noc_numb[i + 1] : ''"
                    :placeholder="wlData.latest_names[i] == '' && i == 0 ? '1005' : wlData.latest_names[i] == '' && (i > 0 && i <= 6) ? noc_numb[i - 1] : 'Number ' + (a = i + 1)"
                    :filled="wlData.latest_names[i].includes('NOC') || wlData.latest_names[i].includes('Configuration Server')
                      ? true
                      : false || user_allowed.allowed['edit whitelist contact']
                        ? false
                        : true
                      " outlined :disabled="true" dense></v-text-field>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row style="width: 100%;padding-left:0.3em;margin-top: -2em;">
            <v-col cols="12">
              <v-btn block small :disabled="true" class="elevation-0 rounded-0">
                Update White List Configuration
              </v-btn>
            </v-col>
          </v-row>
        </div>


      </v-card-text>


    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import axios from "../../plugins/axios";
import $ from "jquery";
export default {
  data() {
    return {
      noc_numb: ['+8821665411062', '+8821665411077', '+8821665411161', '+8821665411162', '+8821665411263', '+8821665411264'],
      wlmodal: null,
      change: false,
      loadernavplus: require("@/assets/img/load100new.gif"),
      fetchInterval: null,
    };
  },
  mounted() {
    this.wlFetchStatic();
  },
  methods: {
    ...mapActions(["wlModal", "wlFetch", "wlPost", "wlRemove"]),

    closewlModal() {
      this.wlRemove();
      this.stopFetch();
    },

    checkdata(statis, value) {
      console.log(value);
      console.log(this.wlmodal[statis][value]);
      console.log(this.wlData[statis][value]);
      if (this.wlmodal[statis][value] == this.wlData[statis][value]) {
        this.change = false;
      } else {
        this.change = true;
      }
    },
    async wlDataPost() {
      $("#div_wl").scrollTop(0);
      this.change = false;
      const data = {
        thuraya_number: this.wlData.number,
        recipients: this.wlData.latest_content,
        names: this.wlData.latest_names,
        token: localStorage.getItem("token"),
      };
      this.wlPost(data);

      var mother = this;
      var intervalSeconds = 0.5;
      this.fetchInterval = setInterval(function () {
        mother.wlDataFetch();
      }, intervalSeconds * 1000);
    },

    stopFetch() {
      console.log("STOP FETCH STATUS WHITELIST");
      clearInterval(this.fetchInterval);
      this.on = false;
    },

    async wlDataFetch() {
      console.log("FETCH TERUS STATUS WHITELIST");
      console.log("STATUS :");
      console.log(this.wlData.latest_status)
      const data = {
        id: this.wlID,
        token: localStorage.getItem("token"),
      };
      this.wlFetch(data);
      var mother = this;
      setTimeout(function () {
        if (mother.wlData) {
          if (mother.wlData.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.wlFetchStatic();
          }
        } else {
          if (mother.wlmodal.latest_status != "ON AIR") {
            mother.stopFetch();
            mother.wlFetchStatic();
          }
        }
      }, 5000);
    },

    async wlFetchStatic() {
      console.log(this.wlID);
      try {
        const response = await axios.get(
          "/api/v1/navplus/thuraya/" + this.wlID + "/whitelist",
          {
            headers: {
              Authorization: "Bearer" + localStorage.getItem("token"),
            },
          }
        );

        console.log(response);

        this.wlmodal = response.data.data;
        var mother = this;
        var intervalSeconds = 0.5;
        if (response.data.data.latest_status == "ON AIR") {
          this.fetchInterval = setInterval(function () {
            mother.wlDataFetch();
          }, intervalSeconds * 1000);
        }
      } catch (e) {
        console.log(e);
      }
    },
  },

  computed: {
    ...mapGetters(["wlData", "wlID", "wlOpen", "user_allowed"]),
    changes_() {
      return this.change;
    },
  },
};
</script>

<style lang="scss" scoped></style>
