<template>
  <v-app class="container_tabledata" color="#fff">
    <v-card id="top_mythuraya" elevation="0">
      <v-alert v-if="OffMythuraya" class="elevation-0 rounded-0" prominent type="warning" icon="mdi-web">
        <v-row align="center">
          <v-col class="grow">
            Connection timeout , please check your internet connection
          </v-col>
          <v-col class="shrink">
            <v-btn small @click="reload_page()" class="elevation-0 rounded-0">Reload</v-btn>
          </v-col>
        </v-row>
      </v-alert>
      <v-layout v-resize="onResize" column style="">
        <loadingTable v-if="!thurayaData" />
        <v-card v-if="thurayaData" class="ma-3 elevation-0 rounded-0" style="border: solid 1px #e0e0e0">
          <v-toolbar elevation="0" class="pa-0" color="#F7F7F7" fixed dense>
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0" color="#ffff"
                  v-bind="attrs" v-on="on">
                  {{ kFormatter(itemsPerPage)
                  }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item @click="itemsPerPage = 10"><v-list-item-title>10</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 25"><v-list-item-title>25</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 50"><v-list-item-title>50</v-list-item-title></v-list-item>
                <v-list-item @click="itemsPerPage = 100"><v-list-item-title>100</v-list-item-title></v-list-item>
              </v-list>
            </v-menu>
            <v-spacer style="width: 70%"></v-spacer>
            <v-text-field style="border: solid 1px #e0e0e0;" width="40" v-model="search" hide-details
              prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable class="elevation-0 rounded-0"
              @click="nulsearch()"></v-text-field>
          </v-toolbar>
          <v-divider></v-divider>
          <v-data-table style="margin-top: 0px" v-if="thurayaData" :search="showMapData()" :headers="headers
            " @pagination="yourMethod" :items="thurayaData" :items-per-page="itemsPerPage" hide-default-footer
            hide-default-header :page.sync="page" @page-count="pageCount = $event"
            class="elevation-0 rounded-0 dataTable desktopTable_thuraya">
            <template v-slot:header>
              <th width="3%" class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">No
              </th>
              <th width="3%" v-if="user_allowed.allowed['edit vessel detail']" class="pa-0 pt-2 pl-1"
                style="text-align:left;color:#626364;font-size:0.9em;height:3em"></th>
              <th width="16%" class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">
                Vessel Name</th>
              <th width="10%" class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">
                Company</th>
              <th width="17%" v-if="user_allowed.role_id <= 2" class="pa-0 pt-2 pl-1"
                style="text-align:left;color:#626364;font-size:0.9em;height:3em">Group</th>
              <th width="18%" class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">
                Thuraya Number</th>
              <th width="33%" class="pa-0 pt-2 pl-1" style="text-align:left;color:#626364;font-size:0.9em;height:3em">
              </th>

            </template>
            <template v-slot:item="row">
              <tr>
                <td width="3%" class="pa-0 pl-1" style="white-space: nowrap; min-width: 1em;text-align:left">
                  {{ row.item.index + 1 }}
                </td>
                <td width="3%" v-if="user_allowed.allowed['edit vessel detail']" class="pa-0 pl-1"
                  style="white-space: nowrap; padding: 1em;text-align:left">
                  <v-btn class="elevation-0 rounded-0 pa-0 ma-0 mt-1" fab dark x-small color="primary"
                    @click="vnameDataFetch(row.item)">
                    <v-icon x-small dark>mdi-cog</v-icon>
                  </v-btn>
                </td>
                <td width="16%" class="pa-0 pl-1" style="white-space: nowrap;text-align:left" ref="dataTable">
                  {{ row.item.name }}
                  <v-chip class="rounded-0 pa-1 ml-1" v-if="row.item.status != 'ACTIVE'" dark x-small>{{ row.item.status
                  }}</v-chip>
                </td>
                <td width="10%" class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                  {{ row.item.pt_name }}
                </td>
                <td width="17%" v-if="user_allowed.role_id <= 2" class="pa-0 pl-1"
                  style="white-space: nowrap;text-align:left">
                  {{ row.item.pt_group }}
                </td>
                <td width="18%" class="pa-0 pl-1" style="white-space: nowrap;text-align:left">
                  <v-btn @click="
                    row.item.callEnabled == 0
                      ? console.log('cant sms')
                      : (chroomDataFetch(row.item),
                        setChatCreated_Data(row.item))
                    " class="elevation-0 rounded-0 pa-1 ma-0 mr-1" fab dark x-small :style="row.item.callEnabled == 0
      ? 'cursor:not-allowed'
      : 'cursor:pointer'
    " :color="row.item.callEnabled == 0 ? '#90B7DE' : 'primary'">
                    <v-icon small dark> mdi-message-text </v-icon>
                  </v-btn>
                  <v-btn @click="
                    row.item.callEnabled == 0
                      ? console.log('cant call')
                      : row.item.callEnabled == 1
                        ? console.log('cant call')
                        : row.item.callEnabled == 2
                          ? (setCallCreated_Data(row.item), fetchCallData_(row.item.thuraya_number))
                          : (setCallCreated_Data(row.item), fetchCallData_(row.item.thuraya_number))
                    " class="elevation-0 rounded-0 pa-1 ma-0" fab dark x-small :style="row.item.callEnabled == 0
      ? 'cursor:not-allowed'
      : row.item.callEnabled == 1
        ? 'cursor:not-allowed'
        : row.item.callEnabled == 2
          ? 'cursor:pointer'
          : 'cursor:pointer'
    " :color="row.item.callEnabled == 0
      ? '#7AD29F'
      : row.item.callEnabled == 1
        ? 'red'
        : row.item.callEnabled == 2
          ? '#38C172'
          : '#38C172'
    ">
                    <v-icon small dark> mdi-phone </v-icon>
                  </v-btn>
                  +{{ row.item.thuraya_number }}
                </td>
                <td width="33%" class="pa-0" style="white-space: nowrap;text-align:left">
                  <v-menu content-class="elevation-0 rounded-0 pa-0" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn width="20%" color="primary" dark v-bind="attrs" v-on="on" small
                        class="elevation-0 rounded-0 pa-1 ma-0 mr-1">

                        <div style="font-size: 0.85em;"><v-icon class="mr-1" x-small>mdi-wrench</v-icon>config<v-icon class="ml-0" small>mdi-chevron-down</v-icon></div>
                        
                      </v-btn>
                    </template>
                    <v-list style="font-size: 0.9em" color="#1665B4" class="pa-0">
                      <v-list-item v-if="user_allowed.allowed['view whitelist contact']" style="color: #ffff"
                        @click="wlDataFetch(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                        Whitelist
                      </v-list-item>
                      <v-list-item v-if="user_allowed.allowed['view vessel detail']" style="color: #ffff"
                        @click="(vdOpen = true), vdDataFetch(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                        Vessel Detail
                      </v-list-item>
                      <v-list-item v-if="user_allowed.allowed['view listing']" style="color: #ffff"
                        @click="mnDataFetch(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                        Manifest Listing
                      </v-list-item>
                      <v-list-item style="color: #ffff" @click="gfDataFetch(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                        Geofencing
                      </v-list-item>
                      <v-list-item v-if="user_allowed.role_id <= 2" style="color: #ffff" @click="admDataFetch(row.item)"
                        class="pa-0 ma-0 pl-2 pr-2">
                        Admin Password
                      </v-list-item>

                      <v-list-item v-if="row.item.thuraya_ver == 2.0" style="color: #ffff" @click="rebootFetch(row.item)"
                        class="pa-0 ma-0 pl-2 pr-2">
                        Reboot Comand
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-menu v-if="user_allowed.allowed['view sos contact']" content-class="elevation-0 rounded-0 pa-0"
                    offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn width="12%" color="#E3342F" dark v-bind="attrs" v-on="on" small
                        class="elevation-0 rounded-0 pa-1 ma-0 mr-1">

                        <div style="font-size: 0.85em;">SOS<v-icon class="ml-0" small>mdi-chevron-down</v-icon></div>
                      </v-btn>
                    </template>
                    <v-list style="font-size: 0.9em" color="#E3342F" class="pa-0">
                      <v-list-item style="color: #ffff" @click="sosDataFetch(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                        Config
                      </v-list-item>
                      <v-list-item style="color: #ffff" @click="hsDataFetch(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                        History
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <v-btn width="25%" v-if="row.item.tracking == false" v-bind="attrs" v-on="on" color="#9BA1A6" dark small
                    style="cursor: not-allowed" class="elevation-0 rounded-0 pa-1 ma-0 mr-1">

                    <div style="font-size: 0.85em;"><v-icon class="mr-1" x-small>mdi-wrench</v-icon>config<v-icon class="ml-0" small>mdi-chevron-down</v-icon></div>
                  </v-btn>

                  <v-menu content-class="elevation-0 rounded-0 pa-0" v-if="row.item.tracking == true" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn width="25%" color="#6C757D" dark v-bind="attrs" v-on="on" small
                        class="elevation-0 rounded-0 pa-1 ma-0 mr-1">

                        <div style="font-size: 0.85em;"><v-icon class="mr-1" x-small>mdi-wrench</v-icon>Tracking<v-icon class="ml-0" small>mdi-chevron-down</v-icon></div>
                      </v-btn>
                    </template>
                    <v-list style="font-size: 0.9em" color="#6C757D" class="pa-0">
                      <v-list-item v-if="user_allowed.allowed['view tracking']" style="color: #ffff" @click="trackDataFetch(row.item)
                        " class="pa-0 ma-0 pl-2 pr-2">
                        Interval
                      </v-list-item>
                      <v-list-item v-if="user_allowed.allowed['view tracking contact']" style="color: #ffff"
                        @click="gpsDataFetch(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                        GPS Email
                      </v-list-item>
                      <v-list-item v-if="user_allowed.allowed['view tracking']" style="color: #ffff"
                        @click="sendDataSpeed(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                        Speed Alerts
                      </v-list-item>
                    </v-list>
                  </v-menu>
                  <v-btn width="11%" v-if="user_allowed.allowed['view cdr']" dark color="#38C172" role="button" @click="cdrDataFetch('month', row.item)
                    " data-toggle="modal" data-target="#cdrModal" small class="elevation-0 rounded-0 pa-1 ma-0 mr-1">
                  <div style="font-size: 0.85em;">CDR</div>
                  </v-btn>
                  <v-btn width="26%" dark v-if="row.item.tracking == true" color="primary" role="button"
                    class="elevation-0 rounded-0 pa-1 ma-0 mr-1" @click="
                      addthuraya_executed(false),
                      showMap(row.item.id, row.item.name),
                      setNameThur(row.item.name),
                      set_clickdate('today')
                      " small>
                    <div style="font-size: 0.85em;"><v-icon class="mr-1" x-small>mdi-map</v-icon>Show On Map
                              </div>
                  </v-btn>
                  <v-btn width="26%" v-if="row.item.tracking == false" role="button" color="#9CBDDE" dark small
                    class="elevation-0 rounded-0 pa-1 ma-0" style="cursor: not-allowed">
                    <div style="font-size: 0.85em;"><v-icon class="mr-1" x-small>mdi-map</v-icon>Show On Map
                              </div>
                  </v-btn>
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table style="margin-top: 0px" v-if="thurayaData" :search="showMapData()" :headers="headers
            " @pagination="yourMethod" :items="thurayaData" :items-per-page="itemsPerPage" hide-default-footer
            hide-default-header :page.sync="page" @page-count="pageCount = $event"
            class="elevation-0 rounded-0  mobileTable_thuraya"><template v-slot:item="row">

              <tr style="margin-top:0.4em;margin-bottom:0.4em">
                <td style="border-bottom:none;text-align:left">
                  <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" width="87vw">
                    <v-toolbar dense class="elevation-0 rounded-0">
                      <v-toolbar-subtitle>{{ row.item.index + 1 }}</v-toolbar-subtitle>
                      <v-spacer></v-spacer>

                      <v-btn v-if="user_allowed.allowed['edit vessel detail']"
                        class="elevation-0 rounded-0 pa-0 ma-0 mt-1" fab dark x-small color="primary"
                        @click="vnameDataFetch(row.item)">
                        <v-icon x-small dark>mdi-cog</v-icon>
                      </v-btn>

                    </v-toolbar>
                    <v-divider></v-divider>
                    <v-card-text>
                      <table>

                        <tr>
                          <td>Vessel Name</td>
                          <td>:</td>
                          <td>{{ row.item.name }}</td>
                        </tr>
                        <tr>
                          <td>Company</td>
                          <td>:</td>
                          <td>{{ row.item.pt_name }}</td>
                        </tr>
                        <tr>
                          <td>Group</td>
                          <td>:</td>
                          <td>{{ row.item.pt_group }}</td>
                        </tr>
                        <tr>
                          <td>Thuraya Number</td>
                          <td>:</td>
                          <td><v-btn @click="
                            row.item.callEnabled == 0
                              ? console.log('cant sms')
                              : (chroomDataFetch(row.item),
                                setChatCreated_Data(row.item))
                            " class="elevation-0 rounded-0 pa-1 ma-0 mr-1" fab dark x-small :style="row.item.callEnabled == 0
      ? 'cursor:not-allowed'
      : 'cursor:pointer'
    " :color="row.item.callEnabled == 0 ? '#90B7DE' : 'primary'">
                              <v-icon small dark> mdi-message-text </v-icon>
                            </v-btn>
                            <v-btn @click="
                              row.item.callEnabled == 0
                                ? console.log('cant call')
                                : row.item.callEnabled == 1
                                  ? console.log('cant call')
                                  : row.item.callEnabled == 2
                                    ? (setCallCreated_Data(row.item), fetchCallData_(row.item.thuraya_number))
                                    : (setCallCreated_Data(row.item), fetchCallData_(row.item.thuraya_number))
                              " class="elevation-0 rounded-0 pa-1 ma-0" fab dark x-small :style="row.item.callEnabled == 0
      ? 'cursor:not-allowed'
      : row.item.callEnabled == 1
        ? 'cursor:not-allowed'
        : row.item.callEnabled == 2
          ? 'cursor:pointer'
          : 'cursor:pointer'
    " :color="row.item.callEnabled == 0
      ? '#7AD29F'
      : row.item.callEnabled == 1
        ? 'red'
        : row.item.callEnabled == 2
          ? '#38C172'
          : '#38C172'
    ">
                              <v-icon small dark> mdi-phone </v-icon>
                            </v-btn>
                            +{{ row.item.thuraya_number }}
                          </td>
                        </tr>
                        <tr>
                          <td colspan="3"><v-divider></v-divider></td>
                        </tr>
                        <tr>
                          <td td colspan="3" class="pa-1">
                            <v-menu content-class="elevation-0 rounded-0 pa-0" offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="primary" dark v-bind="attrs" v-on="on" small
                                  class="elevation-0 rounded-0 pa-1 ma-1">

                                  <div style="font-size: 0.85em;"><v-icon class="mr-1" x-small>mdi-wrench</v-icon>config<v-icon class="ml-0" small>mdi-chevron-down</v-icon></div>
                                  
                                </v-btn>
                              </template>
                              <v-list style="font-size: 0.9em" color="#1665B4" class="pa-0">
                                <v-list-item v-if="user_allowed.allowed['view whitelist contact']" style="color: #ffff"
                                  @click="wlDataFetch(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                                  Whitelist
                                </v-list-item>
                                <v-list-item v-if="user_allowed.allowed['view vessel detail']" style="color: #ffff"
                                  @click="(vdOpen = true), vdDataFetch(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                                  Vessel Detail
                                </v-list-item>
                                <v-list-item v-if="user_allowed.allowed['view listing']" style="color: #ffff"
                                  @click="mnDataFetch(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                                  Manifest Listing
                                </v-list-item>
                                <v-list-item style="color: #ffff" @click="gfDataFetch(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                        Geofencing
                      </v-list-item>
                                <v-list-item v-if="user_allowed.role_id <= 2" style="color: #ffff"
                                  @click="admDataFetch(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                                  Admin Password
                                </v-list-item>
                                <v-list-item v-if="row.item.thuraya_ver == 2.0" style="color: #ffff" @click="rebootFetch(row.item)"
                        class="pa-0 ma-0 pl-2 pr-2">
                        Reboot Comand
                      </v-list-item>
                              </v-list>
                            </v-menu>
                            <v-menu v-if="user_allowed.allowed['view sos contact']"
                              content-class="elevation-0 rounded-0 pa-0" offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="#E3342F" dark v-bind="attrs" v-on="on" small
                                  class="elevation-0 rounded-0 pa-1 ma-1">

                                  <div style="font-size: 0.85em;">SOS<v-icon class="ml-0" small>mdi-chevron-down</v-icon></div>
                                </v-btn>
                              </template>
                              <v-list style="font-size: 0.9em" color="#E3342F" class="pa-0">
                                <v-list-item style="color: #ffff" @click="sosDataFetch(row.item)"
                                  class="pa-0 ma-0 pl-2 pr-2">
                                  Config
                                </v-list-item>
                                <v-list-item style="color: #ffff" @click="hsDataFetch(row.item)"
                                  class="pa-0 ma-0 pl-2 pr-2">
                                  History
                                </v-list-item>
                              </v-list>
                            </v-menu>

                            <v-btn v-if="row.item.tracking == false" v-bind="attrs" v-on="on" color="#9BA1A6" dark small
                              style="cursor: not-allowed" class="elevation-0 rounded-0 pa-1 ma-1">

                              <div style="font-size: 0.85em;"><v-icon class="mr-1" x-small>mdi-wrench</v-icon>Tracking<v-icon class="ml-0" small>mdi-chevron-down</v-icon></div>
                            </v-btn>

                            <v-menu content-class="elevation-0 rounded-0 pa-0" v-if="row.item.tracking == true" offset-y>
                              <template v-slot:activator="{ on, attrs }">
                                <v-btn color="#6C757D" dark v-bind="attrs" v-on="on" small
                                  class="elevation-0 rounded-0 pa-1 ma-1 ">

                                  <div style="font-size: 0.85em;"><v-icon class="mr-1" x-small>mdi-wrench</v-icon>Tracking<v-icon class="ml-0" small>mdi-chevron-down</v-icon></div>
                                </v-btn>
                              </template>
                              <v-list style="font-size: 0.9em" color="#6C757D" class="pa-0">
                                <v-list-item v-if="user_allowed.allowed['view tracking']" style="color: #ffff" @click="trackDataFetch(row.item)
                                  " class="pa-0 ma-0 pl-2 pr-2">
                                  Interval
                                </v-list-item>
                                <v-list-item v-if="user_allowed.allowed['view tracking contact']" style="color: #ffff"
                                  @click="gpsDataFetch(row.item)" class="pa-0 ma-0 pl-2 pr-2">
                                  GPS Email
                                </v-list-item>
                                <v-list-item v-if="user_allowed.allowed['view tracking']" style="color: #ffff"
                        @click="" class="pa-0 ma-0 pl-2 pr-2">
                        Speed Alert
                      </v-list-item>
                              </v-list>
                            </v-menu>
                            <v-btn v-if="user_allowed.allowed['view cdr']" dark color="#38C172" role="button" @click="cdrDataFetch('month', row.item)
                              " data-toggle="modal" data-target="#cdrModal" small class="elevation-0 rounded-0 pa-1 ma-1 mr-1">
                              CDR
                            </v-btn>
                            <v-btn dark v-if="row.item.tracking == true" color="primary" role="button"
                              class="elevation-0 rounded-0 pa-1 ma-1 mr-1" @click="
                                addthuraya_executed(false),
                                showMap(row.item.id, row.item.name),
                                setNameThur(row.item.name),
                                set_clickdate('24')
                                " small>
                              <div style="font-size: 0.85em;"><v-icon class="mr-1" x-small>mdi-map</v-icon>Show On Map
                              </div>
                            </v-btn>
                            <v-btn v-if="row.item.tracking == false" role="button" color="#9CBDDE" dark small
                              class="elevation-0 rounded-0 pa-1 ma-1" style="cursor: not-allowed">
                              <div style="font-size: 0.85em;"><v-icon class="mr-1" x-small>mdi-map</v-icon>Show On Map
                              </div>
                            </v-btn>
                          </td>
                        </tr>

                      </table>
                    </v-card-text>
                  </v-card>



                </td>
              </tr>

            </template></v-data-table>
          <v-divider></v-divider>
          <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">

            <p class="mt-3" style="font-size: 0.8em;text-align: left;">
              Showing {{ showingDatafrom }} to {{ showingDatato }} of
              {{ totalFilter }} entries
            </p>

            <v-spacer></v-spacer>
            <v-pagination class="pt-2 pb-2" color="primary" v-model="page" :length="pageCount"
              :total-visible="7"></v-pagination>
          </v-toolbar>

        </v-card>
      </v-layout>
      <div id="bottom_table_thuraya"></div>
    </v-card>

    <ShowonMapLoad v-if="!showmapthuraya && showthurayaclick" />
    <ShowonMapThuraya v-if="showmapthuraya" />
    <WhiteList v-if="wlOpen" />
    <VesselDetail v-if="vdOpen" />
    <SosHistory v-if="hsOpen" />
    <GpsEmail v-if="gpsOpen" />
    <Manifest v-if="mnOpen" />
    <Administrator v-if="admOpen" />
    <SosContact v-if="sosOpen" />
    <ChangeNameThur v-if="NameThurOpen_" :nameid="nameModal" />
    <CallModal v-if="CallCreated_" />
    <ChatRoom v-if="ChatCreated_" />
    <TrackInter v-if="trackOpen" />
    <cdrModal v-if="cdrOpen" />
    <GFModal v-if="gfOpen" />
    <RebootModal v-if="rbOpen" />
    <SpeedModal v-if="speedaltopen_thur" :speedalt_id="speed_id" />




  </v-app>
</template>

<script>
import CdrChart from "@/components/charts/CdrChart.vue";
import CallModal from "@/components/modals/call_modal.vue";
import GFModal from "@/components/modals/geo_modal.vue";
import ChangeNameThur from "@/components/modals/ChangeNameThur.vue"
import ChatRoom from "@/components/modals/chatroom_modal.vue";
import axios from "axios";
import $ from "jquery";
import loadingTable from "@/components/progress/loadingTable.vue";
import ShowonMapThuraya from "@/components/showonmap/ShowonMapThuraya.vue";
import ShowonMapLoad from "@/components/skeleton/ShowonMapLoad.vue"
import SIP from "sip.js";
import { mapGetters, mapActions } from "vuex";
import * as easings from "vuetify/lib/services/goto/easing-patterns";

//MODAL

//CONFIG BUTTON
import WhiteList from "@/components/modals/whitelist_modal.vue";
import VesselDetail from "@/components/modals/vesseldetail_modal.vue";
import SosHistory from "@/components/modals/history_modal.vue";
import GpsEmail from "@/components/modals/gpsemail_modal.vue";
import Manifest from "@/components/modals/manifest_modal.vue";
import Administrator from "@/components/modals/adm_modal.vue";
import SosContact from "@/components/modals/soscontact_modal.vue";
import TrackInter from "@/components/modals/sosinterval_modal.vue";
import cdrModal from "@/components/modals/cdr_modal.vue";
import RebootModal from "@/components/modals/reboot_modal.vue";
import SpeedModal from "@/components/modals/speedalert_thuraya.vue";

export default {
  props: ["user"],
  components: {
    SpeedModal,
    ShowonMapThuraya,
    CallModal,
    ChatRoom,
    CdrChart,
    WhiteList,
    VesselDetail,
    SosHistory,
    GpsEmail,
    loadingTable,
    ShowonMapLoad,
    ChangeNameThur,
    Manifest,
    Administrator,
    SosContact,
    TrackInter,
    cdrModal,
    GFModal,
    RebootModal
  },
  data() {
    return {
      speed_id:null,
      fetchInterval_: null,
      totalFilter: null,
      chatidt: null,
      dates: [],
      datepickerID: null,
      nameModal: null,
      dateopen: null,
      type: "number",
      pesan_confirm: false,
      pesan_kosong: false,
      number: 9999,
      selector: "#first",
      selections: ["#first", "#second", "#third"],
      selected: "Button",
      elements: ["Button", "Radio group"],
      duration: 300,
      offset: 0,
      easing: "easeInOutCubic",
      easings: Object.keys(easings),
      alert_: null,
      alertmsg_: null,
      nameChangeData: "",
      nameopen: false,
      itemsPerPage: 10,
      page: 1,
      pageCount: 0,
      itemsPerPageCDR: 10,
      pageCDR: 1,
      pageCountCDR: 0,
      headers: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "id",
        },
        {
          text: "",
          align: "start",
          sortable: false,
          value: "Edit Vessel Name",
        },
        { text: "Vessel Name", value: "name", width: "9%", sortable: false },
        { text: "Company", value: "pt_name", width: "9%", sortable: false },
        { text: "Group", value: "pt_group", width: "1%", sortable: false },
        {
          text: "Thuraya Number",
          value: "thuraya_number",
          width: "19%",
          sortable: false,
        },
        { text: "", value: "config", width: "50%", sortable: false },
      ],
      headerselse: [
        {
          text: "No",
          align: "start",
          sortable: false,
          value: "id",
        },

        { text: "Vessel Name", value: "name", width: "9%", sortable: false },
        { text: "Company", value: "pt_name", width: "9%", sortable: false },
        { text: "Group", value: "pt_group", width: "1%", sortable: false },
        {
          text: "Thuraya Number",
          value: "thuraya_number",
          width: "19%",
          sortable: false,
        },
        { text: "", value: "config", width: "50%", sortable: false },
      ],
      headerscdr: [
        { text: "Services", value: "Services" },
        { text: "MSISDN", value: "MSISDN" },
        { text: "Type", value: "Type" },
        { text: "Plcode", value: "Plcode" },
        { text: "Network", value: "Network" },
        { text: "Start Date", value: "Start_Date" },
        { text: "Called Party", value: "Called_Party" },
        { text: "Duration", value: "Duration" },
        { text: "Band", value: "Band" },
        { text: "Amount", value: "Amount" },
        { text: "DateTime Local", value: "dateTime_local" },
      ],
      admDataPost: null,
      alertwl_: null,
      alertps_: null,
      alertvd_: null,
      searchCDR: "",
      alertmn_: null,
      alertsos_: null,
      RecePient: "Recipient",
      wlDataName: [],
      wlDataRecep: [],
      wlDataContent: [],
      loadingdata: false,
      username: null,
      pesan: null,
      conOpen: null,

      search: "",
      loadernavplus: require("@/assets/img/load100new.gif"),
      labeldur: null,
      cdrid: null,
      manifestArray: [
        { 0: "" },
        { 1: "test" },
        { 2: "" },
        { 3: "" },
        { 4: "" },
        { 5: "" },
        { 6: "" },
        { 7: "" },
        { 8: "" },
        { 9: "" },
      ],

    };
  },

  async created() {
    this.fetchThuraya(localStorage.getItem("token"));

  },

  methods: {
    ...mapActions([
      "fetchSpeedAlert_thur",
      "fetchCallData",
      "Inputexpthuraya_title",
      "fetchUSERGET",
      "cdrFetch",
      "addNameThurOpen_",
      "fetchThuraya",
      "showonMapThuraya",
      "showdateThuraya",
      "removeThuraya",
      "addthuraya_executed",
      "addNameData",
      "vnameFetch",
      "wlModal",
      "wlFetch",
      "wlPost",
      "wlRemove",

      "vdFetch",
      "vdPost",
      "vdRemove",

      "admFetch",

      "mnFetch",

      "sosFetch",

      "trackFetch",

      "hsFetch",

      "gfFetch",

      "trackFetch",

      "gpsFetch",

      "rbModal",
      "rbFetch",

      "chRoomFetch",
      "fetchTlOpen",
      "setCallOpen",
      "setNameThuraya",
      "setCallCreated_Data",
      "setChatCreated_Data",

      "set_clickdate",
    ]),

    yourMethod(pagination) {
      this.totalFilter = pagination.itemsLength

    },

    reload_page() {
      window.location.reload()
    },

    setNameThur(name) {
      this.setNameThuraya(name);
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    customRange() {
      var range_date = this.dates.join(" ");
      console.log(range_date);
      this.showCDR(range_date, range_date, this.datepickerID);
    },

    mulaiCall() {
      var second;
      var callCounter;
      var thuraya_number = "";

      const webSocketServer = "wss://pbx.sailink.id:8099/ws";
      const domain = "pbx.sailink.id";
      const aor = "sip:99305@" + domain;
      const displayName = "99305";
      const authorizationUsername = "99305";
      const authorizationPassword = "cb7ac990829f2f40f14b010271c5494c";
      // const target = "sip:" + thurayaNumber + "@" + domain;

      function getAudio(id) {
        const el = document.getElementById(id);
        if (!(el instanceof HTMLAudioElement)) {
          throw new Error(`Element "${id}" not found or not an audio element.`);
        }
        console.log("audio element is valid");
        return el;
      }
      function getButton(id) {
        const el = document.getElementById(id);
        if (!(el instanceof HTMLButtonElement)) {
          throw new Error(`Element "${id}" not found or not a button element.`);
        }
        return el;
      }
      function getDiv(id) {
        const el = document.getElementById(id);
        // if (!(el instanceof HTMLDivElement)) {
        if (!(el instanceof HTMLSpanElement)) {
          throw new Error(`Element "${id}" not found or not a div element.`);
        }
        return el;
      }

      const audioElement = getAudio("remoteAudio");
      const hangupButton = getButton("hangup-btn");
      const statusDiv = getDiv("thurayaCallStatus");

      const simpleUserDelegate = {
        onCallCreated: () => {
          console.log(`[${displayName}] Call created`);
          statusDiv.innerHTML = "Call created";
        },
        onCallAnswered: () => {
          console.log(`[${displayName}] Call answered`);
          statusDiv.innerHTML = "Call answered";

          // start counting
          console.log("started counting");
          second = 0;
          callCounter = setInterval(() => {
            second++;
            console.log("second = " + second);
          }, 1000);
        },
        onCallHangup: () => {
          console.log(`[${displayName}] Call hangup`);
          statusDiv.innerHTML = "Call disconnected";

          // stop counting
          console.log("stop counting");
          clearInterval(callCounter);
          console.log("TOTAL CALL TIME == " + second + " Second");
          save_call_log(second);
        },
        onCallHold: (held) => {
          console.log(`[${displayName}] Call hold ${held}`);
        },
      };
      const simpleUserOptions = {
        aor,
        delegate: simpleUserDelegate,
        media: {
          remote: {
            audio: audioElement,
          },
        },
        userAgentOptions: {
          logLevel: "debug",
          displayName,
          authorizationUsername,
          authorizationPassword,
          logConfiguration: true,
        },
      };

      const simpleUser = new SIP.Web.SimpleUser(
        webSocketServer,
        simpleUserOptions
      );

      async function doCall(thurayaNumber) {
        let target = "sip:" + thurayaNumber + "@" + domain;
        thuraya_number = thurayaNumber;

        statusDiv.innerHTML = "Connecting...";
        await simpleUser.connect();
        statusDiv.innerHTML = "Registering...";
        await simpleUser.register();
        statusDiv.innerHTML = "Calling... " + thurayaNumber;
        await simpleUser.call(target, { inviteWithoutSdp: false });
      }

      async function doHangup() {
        await simpleUser.disconnect();
        await simpleUser.hangup();
      }

      // callButton.addEventListener("click", () => {
      //     doCall();
      // });

      hangupButton.addEventListener("click", () => {
        doHangup();
        // clearInterval(callCounter);
      });

      function save_call_log(duration) {
        var _token = $('input[name="_token"]').val();

        $.ajax({
          url: "ajax/storeCallLog",
          method: "POST",
          data: {
            thuraya_number: thuraya_number,
            duration: duration,
            _token: _token,
          },
          dataType: "json",
        }).done(function (con) {
          console.log("log saved");
        });
      }
    },



    async mnDataPush() {
      const data = {
        thuraya_number: this.mnData.number,
        types: this.mnData.latest_content,
      };
      const response = await axios.post(
        "https://navigatorplus.sailink.id/api/v1/navplus/thuraya/listing",
        data,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      console.log(response);
      if (response) {
        this.alertmn_ = true;
      }
    },

    async userfetch() {
      const response = await axios.get(
        "https://navigatorplus.sailink.id/api/login",
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      this.username = response.data.user.name;
    },



    async sendDataName(item) {
      console.log('click Name Data')
      this.nameModal = item
      this.addNameData(item)
      this.addNameThurOpen_(true)
    },

    nulsearch() {
      this.removeThuraya();
    },
    showMapData() {
      if (this.searchThuraya) {
        return this.searchThuraya;
      } else {
        return this.search;
      }
    },
    delwlData() {
      this.wlData = null;
      this.alertwl_ = null;
    },

    deltlp() {
      this.tlpData = null;
    },

    delvdData() {
      this.vdData = null;
      this.alertvd_ = null;
    },

    delmnData() {
      this.mnData = null;
    },

    deladmData() {
      this.admData = null;
      this.alertps_ = null;
    },

    delcdrData() {
      this.cdrData = null;
      this.labeldur = null;
      this.cdrid = null;
    },
    delsosData() {
      this.sosData = null;
    },
    deltrackData() {
      this.trackData = null;
    },

    showdate(item, range) {
      const data = {
        id: item,
        range: range,
        token: localStorage.getItem("token"),
      };
      this.showdateThuraya(data);
    },
    clearmoving: function () {
      this.$root.$emit('clearmoving') //like this
    },

    initialdata: function () {
      this.$root.$emit('initialdata') //like this
    },

    showMap(item, name) {
      this.clearmoving()
      this.Inputexpthuraya_title(name)
      if (!this.thuraya_executed) {

        $('html, body').animate({
          scrollTop: $("#bottom_table_thuraya").offset().top
        }, 1000);
      }

      const data = {
        id: item,
        token: localStorage.getItem("token"),
      };
      this.showonMapThuraya(data);
      this.showdate(item, "24");
    },


    cdrDataFetch(dur, item) {
      const data = {
        id: item.id,
        thuraya_number: item.thuraya_number,
        range: dur,
        token: localStorage.getItem("token"),
      };

      this.cdrFetch(data)
    },

    //CONFIG BUTTON FUNCTION

    //Whitelist
    wlDataFetch(item) {
      this.wlModal(true);
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.wlFetch(data);
    },

    //RebootFetch
    rebootFetch(item) {
      this.rbModal(true);
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.rbFetch(data);
    },

    //Vessel Detail
    vdDataFetch(item) {
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.vdFetch(data);
    },

    //SOS BUTTON
    hsDataFetch(item) {
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.hsFetch(data);
    },

    gfDataFetch(item) {
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.gfFetch(data)
    },

    sosDataFetch(item) {
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.sosFetch(data);
    },

    fetchCallData_(item) {
      console.log('MASUK API CALL DATA')
      const data = {
        thuraya_number: item,
        token: localStorage.getItem("token"),
      };
      this.fetchCallData(data);
    },


    vnameDataFetch(item) {
      console.log(item)
      this.nameModal = item
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.addNameThurOpen_(true)
      this.vnameFetch(data);
    },

    //TRACKING BUTTON
    gpsDataFetch(item) {
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.gpsFetch(data);
    },

    //CHATROOM
    chroomDataFetch(item) {
      this.chatidt = item;
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
        data: item,
      };
      this.chRoomFetch(data);
    },

    //ManifestListing
    mnDataFetch(item) {
      // this.wlModal(true);
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.mnFetch(data);
    },

    //Admin Password
    admDataFetch(item) {
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.admFetch(data);
    },

    //interval sos
    trackDataFetch(item) {
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.trackFetch(data);
    },

    async sendDataMessage() {
      const data = {
        thuraya_number: this.msgNumber,
        pesan: this.pesan,
      };
      const response = await axios.post(
        "https://navigatorplus.sailink.id/api/v1/navplus/sms/send",
        data,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      console.log(response);
      if (response) {
        this.alertmsg_ = true;
        this.chroomDataFetch(this.chatidt);
      }
    },



    async sendDataSpeed(item) {
      this.speed_id = item.id;
      const data = {
        id: item.id,
        token: localStorage.getItem("token"),
      };
      this.fetchSpeedAlert_thur(data);
    },


  },

  computed: {
    ...mapGetters([
    "speedaltopen_thur",
      "gfOpen",
      "OffMythuraya",
      "user_allowed",
      "thuraya_executed",
      "thurayaData",
      "showmapthuraya",
      "showmaptracking_thur",
      "thurayadataTable",
      "searchIDThuraya",
      "searchThuraya",
      "showthurayaclick",
      "userprof",
      "CallCreated_",
      "ChatCreated_",
      "NameThurOpen_",
      "nameData_Thur",

      "wlData",
      "wlID",
      "wlOpen",

      "rbOpen",
      "rbData",
      "rbID",

      "vdData",
      "vdOpen",

      "admData",
      "admOpen",

      "mnOpen",

      "gpsOpen",

      "trackOpen",
      "trackData",

      "sosOpen",
      "sosData",

      "hsOpen",

      "chData",
      "tlpopen",
      "callopen",

      "cdrData",
      "cdrOpen",
    ]),
    target() {
      return 62000000;
    },
    options() {
      return {
        duration: 300,
        offset: 0,
        easing: "easeInOutCubic",
      };
    },
    showingDatafrom() {
      if (this.totalFilter < 1) {
        return 0
      } else {
        return 1
      }

    },

    showingDatato() {
      if (this.totalFilter < this.itemsPerPage) {
        return this.totalFilter
      } else {
        return this.itemsPerPage
      }

    },
  },

  mounted() {
    this.fetchUSERGET(localStorage.getItem("token"))

    var mother = this;
    this.$nextTick(() => {
      if (mother.searchIDThuraya && !mother.thuraya_executed) {

        mother.showMap(mother.searchIDThuraya, mother.searchThuraya);

      }
    })

  },
};
</script>

<style lang="scss" scoped>
// @import "@/assets/scss/data.scss";
.chat-message {
  display: unset !important;
  white-space: break-spaces;
}

.chat-screen {
  max-height: 320px;
  overflow-y: auto;
}

.flex-none {
  flex: unset;
}

.received-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 54px;
  right: auto;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #1976d2 transparent transparent transparent;
}

.received-messageuser::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: 54px;
  right: auto;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #09294f transparent transparent transparent;
}

.sent-message::after {
  content: " ";
  position: absolute;
  width: 0;
  height: 0;
  left: auto;
  right: 54px;
  top: 12px;
  bottom: auto;
  border: 12px solid;
  border-color: #2ea1b0 transparent transparent transparent;
}

.vCard {
  display: flex !important;
  flex-direction: column;
}

.vCardText {
  flex-grow: 1;
  overflow: auto;
}

.mdi-account-circle::before {
  content: "\F0009";
  margin-right: 0.3em;
}

.mdi-ferry::before {
  content: "\F0213";
  margin-right: 0.3em;
}

.row-pointer {
  cursor: pointer;
}




@media (min-width: 280px) and (max-width: 319px) {
  .mobileTable_thuraya {
    display: block
  }

  .desktopTable_thuraya {
    display: none
  }
}

/* Mobile Devices */
@media (min-width: 320px) and (max-width: 480px) {
  .mobileTable_thuraya {
    display: block
  }

  .desktopTable_thuraya {
    display: none
  }
}

/* iPads, Tablets */
@media (min-width: 481px) and (max-width: 768px) {
  .mobileTable_thuraya {
    display: none
  }

  .desktopTable_thuraya {
    display: block
  }
}

/* Small screens, laptops */
@media (min-width: 769px) and (max-width: 1024px) {
  .mobileTable_thuraya {
    display: none
  }

  .desktopTable_thuraya {
    display: block
  }
}

/* Desktops, large screens */
@media (min-width: 1025px) and (max-width: 1200px) {
  .mobileTable_thuraya {
    display: none
  }

  .desktopTable_thuraya {
    display: block
  }
}

/* Extra large screens / TV */
@media screen and (min-width: 1201px) {
  .mobileTable_thuraya {
    display: none
  }

  .desktopTable_thuraya {
    display: block
  }


}
</style>
