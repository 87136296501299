import axios from "../../plugins/axios";

const state = {
    editpass: null,
    editpassid: null,
    editpassdata: null,
};

const getters = {
    editpass: (state) => state.editpass,
    editpassid: (state) => state.editpassid,
    editpassdata: (state) => state.editpassdata,
};

const actions = {
    async fetchEditPass({commit}, data) {
        commit("seteditpass", true)
        const response = await axios.get("/api/v1/navplus/user/edit/pass/" + data, {
            headers: {
                Authorization: "Bearer" + localStorage.getItem("token"),
            },
        });
        commit("seteditpassdata", response.data.data)
    },

    setEditPass({commit}, data) {
        commit("seteditpass", data)
    },

    setEditPassID({commit}, data) {
        commit("seteditpassid", data)
    }
};

const mutations = {
    seteditpass: (state, editpass) => (state.editpass = editpass),
    seteditpassid: (state, editpassid) => (state.editpassid = editpassid),
    seteditpassdata: (state, editpassdata) => (state.editpassdata = editpassdata)
};

export default {
    state,
    getters,
    actions,
    mutations,
};
