


<template>
  <Bar
    :chart-options="chartOptions"
    :chart-data="chartData"
    :chart-id="chartId"
    :dataset-id-key="datasetIdKey"
    :plugins="plugins"
    :css-classes="cssClasses"
    :styles="styles"
    :width="width"
    :height="height"
  />
</template>

<script>
import { Bar } from 'vue-chartjs'
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale)

export default {
  name: 'BarChart',
  components: { Bar },
  props: {
    cdrChart:[],
    chartId: {
      type: String,
      default: 'bar-chart'
    },
    datasetIdKey: {
      type: String,
      default: 'label'
    },
    width: {
      type: Number,
      default: 0
    },
    height: {
      type: Number,
      default: 450
    },
    cssClasses: {
      default: '',
      type: String
    },
    styles: {
      type: Object,
      default: () => {}
    },
    plugins: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      chartData: {
        labels: this.cdrChart.labels,
        datasets: [ {
            label: 'Usage by Day',
            backgroundColor: '#F49223',
            data: this.cdrChart.amount,            
          },
          {
            label: 'Total Usage',
            backgroundColor: '#325d9e',
            data: this.cdrChart.total,           
          }   ]
      },
      chartOptions: {
        responsive: true,
                maintainAspectRatio: false,
                plugins: {
                    title: {
                        display: false,
                        text: 'Chart.js Bar Chart - Stacked'
                    },
                    tooltip: {
                        callbacks: {
                            footer: function(items) {
                                return 'Total Balanced Usage : ' + parseFloat(items.reduce((a, b) => a + b.parsed.y, 0)).toFixed(2) + ' Unit'
                            },
                            label: function(items) {
                                // console.log(items);
                                return items.dataset.label + ' : ' + parseFloat(items.parsed.y).toFixed(2) + ' Unit';
                            }
                        }
                    },
                },
            
                scales: {
                    x: {
                        stacked: true,
                    },
                    y: {
                        stacked: true,
                    },
                },
                interaction: {
                    mode: 'x',
                    intersect: false,
                },
            }
      
    }
  }
}
</script>

<style>

</style>