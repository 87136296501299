<template>
  <v-card id="card_map_thuraya" class="elevation-0 mt-0 rounded-0">

    <v-card id="title_map_thuraya" class="container_showonmap ma-3 elevation-0 rounded-0 mt-0 pb-3"
      style="border: solid 1px #e0e0e0">
      <v-toolbar id="header_vessel_thuraya" color="#F7F7F7" elevation="0" dense>
        <v-toolbar-title style="font-size: 1.2em">{{
          showmapthuraya.vessel_pt
        }}</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn small class="elevation-0 rounded-0" icon @click="closeShowMapThuraya()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-divider></v-divider>
      <v-card class="flexposisi pa-3 rounded-0" height="fit-content" elevation="0" style="margin-top: 0">
        <v-card style="border: solid 1px #e0e0e0; text-align: left" class="datacard rounded-0" color="#F7F7F7"
          elevation="0">
          <v-simple-table dense>
            <template v-slot:default>
              <tbody>
                <tr>
                  <td class="pl-3">Thuraya Number</td>
                  <td>:&nbsp;+{{ showmapthuraya.number }}</td>
                </tr>
                <tr>
                  <td class="pl-3">Service Plan</td>
                  <td>:&nbsp;{{ showmapthuraya.rateplan }}</td>
                </tr>
                <tr v-if="showmapthuraya.pertamina_report">
                  <td class="pl-3">Pertamina Tracking</td>
                  <td>:&nbsp;Active</td>
                </tr>
                <tr>
                  <td class="pl-3">Latitude, Longtitude</td>
                  <td>
                    :&nbsp;{{ showmapthuraya.message.lat }},{{
                      showmapthuraya.message.lng
                    }}
                  </td>
                </tr>
                <tr>
                  <td class="pl-3">DMS</td>
                  <td>:&nbsp;{{ showmapthuraya.message.dms }}</td>
                </tr>
                <tr>
                  <td class="pl-3">Last Updated</td>
                  <td>:&nbsp;{{ showmapthuraya.message.lastUp }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-divider></v-divider>
          <!-- <v-toolbar color="#F7F7F7" dense class="elevation-0 rounded-0"
            >Vessel Images</v-toolbar
          >
          <v-divider></v-divider> -->
          <!-- <v-data-table
            v-if="showmapthuraya.images"
            :items="showmapthuraya.images"
            :items-per-page="4"
            class="elevation-0 rounded-0 image"
            hide-default-footer
            :page.sync="pageImage"
            @page-count="pageCountImage = $event"
          >
            <template v-slot:item="row">
              <tr>
                <td
                  style="
                    background-color: #ffff;
                    padding: 1em;
                    border-bottom: none;
                  "
                >
                  <img
                    @click="(imageOpen = true), (imageData = row.item[0])"
                    :src="row.item[0]"
                    style="
                      cursor: pointer;
                      width: 6em;
                      height: 6em;
                      object-fit: cover;
                    "
                  />
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-divider></v-divider>
          <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
            <p class="mt-3" style="font-size: 0.8em">
              Showing 1 to 4 of {{ showmapthuraya.images.length }} entries
            </p>
            <v-spacer></v-spacer>
            <v-pagination
              dense
              color="primary"
              v-model="pageImage"
              :length="pageCountImage"
              :total-visible="7"
            ></v-pagination>
          </v-toolbar> -->
          <v-card color="#f7f7f7" class="elevation-0 rounded-0 pa-3">
            <v-card flat width="100%" class=" elevation-0 rounded-0  overflow-y-auto  " color="#f7f7f7" height="200">

              <div class="pswp-gallery pswp-gallery--single-column" id="thuraya-gallery-container">
                <a v-for="(gambar, index) in showmapthuraya.images" :key="index" :href="gambar[0]"
                  :data-pswp-width="gambar[1]" :data-pswp-height="gambar[2]" :data-cropped="true" target="_blank"><img
                    :src="gambar[0]" class="vsl_img" alt="Vessel Image" /></a>
              </div>

            </v-card>
          </v-card>
        </v-card>

        <v-card style="border: solid 1px #e0e0e0" color="#F7F7F7" class="mapcard rounded-0" elevation="0">
          <v-toolbar color="#F7F7F7" class="elevation-0 rounded-0 toolbar_thurayadesktop" dense elevation="0">
            <v-menu offset-y content-class="elevation-0 rounded-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn style="border: solid 1px #e0e0e0" color="#ffff" class="rounded-0 elevation-0" v-bind="attrs"
                  v-on="on" small>
                  <v-icon small>mdi-update</v-icon>
                  &nbsp;
                  <div v-if="!showmaptracking_thur">Loading...</div>
                  <div v-if="showmaptracking_thur">
                    {{ showmaptracking_thur.range.label }}
                  </div>
                  &nbsp;
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(showmapthuraya.id, 'today'),
                    set_clickdate('today')
                    ">Today</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(showmapthuraya.id, '24'), set_clickdate('24')
                    ">Last 24 Hour</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(showmapthuraya.id, '7', set_clickdate('7'))
                    ">Last 7 Days</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(showmapthuraya.id, '30'), set_clickdate('30')
                    ">Last 30 Days</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(showmapthuraya.id, 'week', set_clickdate('7'))
                    ">This Week</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(showmapthuraya.id, 'month'), set_clickdate('30')
                    ">This Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(
                      showmapthuraya.id,
                      'lmonth',
                      set_clickdate('lmonth')
                    )
                    ">Last Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    (dateopen = true), (datepickerID = showmapthuraya.id)
                    ">Costume Range</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
            <v-spacer></v-spacer>
            <v-toolbar-title v-if="!showmaptracking_thur" style="width: 220px">
              <div class="loader-line"></div>
            </v-toolbar-title>
            <v-chip style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0" color="#fff"
              v-if="showmaptracking_thur" label>
              <v-icon small>mdi-calendar</v-icon>&nbsp;{{
                showmaptracking_thur.range.actual
              }}</v-chip>
            <v-chip v-if="!showmaptracking_thur" style="border: solid 1px #e0e0e0" color="#ffff" class="rounded-0"
              label><v-icon small class="mr-1">mdi-calendar</v-icon>- - -</v-chip>
          </v-toolbar>
          <v-toolbar height="fit-content" v-if="showmapthuraya.iot.power || showmapthuraya.gpio"
            style="border-bottom;: solid 1px #e0e0e0;border-top;: solid 1px #e0e0e0" color="#f1f1f1"
            class="elevation-0 rounded-0" dense elevation="0">
            <div style="max-width: 90em;
    padding: 0.4em;
    display: flex;
    flex-wrap: wrap;
    align-content: center;">
              <div v-if="(showmapthuraya.iot.power && showmapthuraya.gpio) ||
                showmapthuraya.rpm
                " class="gpiostatus ml-2">
                <v-icon> mdi-lightning-bolt </v-icon>
                <div style="font-size:0.8em;line-height:4.5em">CUBIC AC Power :</div>
                <v-chip text-color="white" small v-if="showmapthuraya.iot.power" class="mt-4  elevation-0 ml-2"
                  color="green">PLUGGED</v-chip>
                <v-chip text-color="white" small v-if="showmapthuraya.iot.power == false"
                  class="mt-4  elevation-0 ml-2 blink" color="red">UNPLUGGED</v-chip>
              </div>
              <div v-if="(showmapthuraya.iot.header)
                " class="gpiostatus ml-2">
                <v-icon> mdi-lightning-bolt </v-icon>
                <div style="font-size:0.8em;line-height:4.5em">SAILOGGER AC Power :</div>
                <v-chip small text-color="white" v-if="showmapthuraya.iot.gpio" class="mt-4  elevation-0 ml-2"
                  color="green">PLUGGED</v-chip>
                <v-chip small text-color="white" v-if="showmapthuraya.iot.gpio == false"
                  class="mt-4  elevation-0 ml-2 blink" color="red">UNPLUGGED</v-chip>
              </div>
              <div v-if="showmapthuraya.devices && showmapthuraya.devices.rpm == true
                " style="padding-top:0.9em" class="gpiostatus ml-2">
                <v-btn class="elevation-0 rounded-0" @click="rpmReport(showmapthuraya)" style="margin-left: 1em" small
                  dark color="primary"><v-icon x-small>mdi-speedometer</v-icon>RPM Report</v-btn>

              </div>






            </div>

          </v-toolbar>
          <v-card color="#F7F7F7" class="elevation-0 rounded-0 toolbar_thurayamobile" elevation="0">
            <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
              <template v-slot:activator="{ on, attrs }">
                <v-btn block style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0" color="#fff" v-bind="attrs"
                  v-on="on" small elevation="0">
                  <v-icon small>mdi-update</v-icon>
                  &nbsp;
                  <div v-if="!showmaptracking_thur">Loading...</div>
                  <div v-if="showmaptracking_thur">
                    {{ showmaptracking_thur.range.label }}
                  </div>
                  &nbsp;
                  <v-icon small>mdi-chevron-down</v-icon>
                </v-btn>
              </template>
              <v-list style="border: solid 1px #e0e0e0">
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(showmapthuraya.id, 'today'),
                    set_clickdate('today')
                    ">Today</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(showmapthuraya.id, '24'), set_clickdate('24')
                    ">Last 24 Hour</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(showmapthuraya.id, '7', set_clickdate('7'))
                    ">Last 7 Days</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(showmapthuraya.id, '30'), set_clickdate('30')
                    ">Last 30 Days</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(showmapthuraya.id, 'week', set_clickdate('7'))
                    ">This Week</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(showmapthuraya.id, 'month'), set_clickdate('30')
                    ">This Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    showondate(
                      showmapthuraya.id,
                      'lmonth',
                      set_clickdate('lmonth')
                    )
                    ">Last Month</v-btn>
                </v-list-item>
                <v-list-item>
                  <v-btn block plain small @click="
                    (dateopen = true), (datepickerID = showmapthuraya.id)
                    ">Costume Range</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>


          </v-card>
          <v-divider></v-divider>

          <v-container v-if="!showmaptracking_thur" id="map_thuraya_" fill-height fluid
            style="margin-top: 0; height: 33em; background-color: #aad3df">
            <v-row align="center" justify="center">
              <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
            </v-row>
          </v-container>


          <l-map id="mapcard_thuraya" @polylinemeasure:add="onCount" @fullscreenchange="triggerFullscreen"
            @polylinemeasure:move="onCount_move" @polylinemeasure:remove="onCount" @polylinemeasure:toggle="handleToggle"
            @polylinemeasure:finish="handleFinish" v-if="showmaptracking_thur" style="height: 40em" ref="map_thuraya_"
            :zoom="showmaptracking_thur.maptop ? 7 : 4" :center="showmaptracking_thur.maptop
              ? [
                parseFloat(showmaptracking_thur.maptop.lat),
                parseFloat(showmaptracking_thur.maptop.lon),
              ]
              : [0.3810441033104415, 118.29473758871244]
              " :options="{
    minZoom: 3,
    zoomControl: false,
    doubleClickZoom: true,
    scrollWheelZoom: false,
  }">
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-control-zoom position="bottomright"></l-control-zoom>
            <l-control-fullscreen position="bottomright" :options="{ title: { false: 'Go big!', true: 'Be regular' } }" />
            <l-control-polyline-measure v-if="etaControl" :ref="'polylineMeasure'" :options="{
              showUnitControl: false,
              showClearControl: false,
              measureControlClasses: ['hideControl'],
              showBearings: true,
              unit: unit_,
              clearMeasurementsOnStop: false,
            }" position="bottomright" />

            <v-rotated-marker v-if="showmaptracking_thur.maptop" :lat-lng="[
              parseFloat(showmaptracking_thur.maptop.lat),
              parseFloat(showmaptracking_thur.maptop.lon),
            ]" :rotationAngle="parseInt(showmaptracking_thur.maptop.rotation)">
              <l-icon :icon-size="[40 * 0.30, 100 * 0.30]" :icon-anchor="[40 * 0.30 / 2, 100 * 0.30 / 2]"
                class-name="someExtraClass">
                <svg id="a" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 40 100">
                  <path :fill="showmaptracking_thur.maptop.fill" stroke="#FFF" stroke-width="10"
                    d="M 37.93 32.68 L 20 0 L 2.07 32.68 c -1.35 2.45 -2.07 5.41 -2.07 8.44 v 57.33 H 40 V 41.12 c 0 -3.03 -0.72 -5.98 -2.07 -8.44 Z" />
                </svg>
              </l-icon>
              <l-popup @ready="draw_Circle()">
                <table>
                  <tr>
                    <td><b>Latitude</b></td>
                    <td>:</td>
                    <td>
                      {{ showmaptracking_thur.maptop.lat }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Longitude</b></td>
                    <td>:</td>
                    <td>{{ showmaptracking_thur.maptop.lon }}</td>
                  </tr>
                  <tr>
                    <td><b>DMS</b></td>
                    <td>:</td>
                    <td>{{ showmaptracking_thur.maptop.dms }}</td>
                  </tr>
                  <tr>
                    <td><b>Speed</b></td>
                    <td>:</td>
                    <td>
                      {{ showmaptracking_thur.maptop.speed + "&nbsp;knots" }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Heading</b></td>
                    <td>:</td>
                    <td>
                      {{ showmaptracking_thur.maptop.rotation + "&deg;" }}
                    </td>
                  </tr>
                  <tr>
                    <td><b>Last Update</b></td>
                    <td>:</td>
                    <td>{{ showmaptracking_thur.maptop.lastUp }}</td>
                  </tr>
                </table>
              </l-popup>
            </v-rotated-marker>

            <l-moving-rotated-marker v-if="drivePath" ref="driveMarker" :lat-lng="driveLatLng"
              :rotationAngle="driveRotationAngle" :duration="3000" :icon="icon" style="z-index:-2" />
            <l-control position="topleft" style="display: flex; justify-content: space-between">
              <div class="lmapbtn_thurayamobile">
                <v-expansion-panels class="elevation-0 rounded-0" style="width: 35vw">
                  <v-expansion-panel v-if="!etaControl">
                    <v-btn style="text-align: left" small color="primary" @click="
                      etaControl ? console.log('Already Open') : openETA()
                      " block class="elevation-0 rounded-0">ETA</v-btn>
                  </v-expansion-panel>
                </v-expansion-panels>
              </div>
              <div class="lmapbtn_thurayadesktop">
                <v-btn v-if="!etaControl" elevation="0" class="rounded-0 pa-1" height="31" width="50" dark
                  :color="etaControl ? '#95BADF' : 'primary'"
                  @click="etaControl ? console.log('Already Open') : openETA()">
                  <v-icon small>mdi-map-marker-distance</v-icon>
                  &nbsp; ETA
                </v-btn>
              </div>
            </l-control>
            <l-control position="bottomleft" style="display: flex">
              <v-card class="elevation-0 rounded-0 ETAcard_thurayadesktop" v-show="etaControl" height="fit-content"
                width="400">
                <v-toolbar class="elevation-0 rounded-0" dense color="#f7f7f7">
                  <v-btn id="strDraw_btn" class="elevation-0 rounded-0" :disabled="onDraw ? true : false" color="primary" small 
                    @click="startDraw()">START DRAW</v-btn>
                    <v-btn class="elevation-0 rounded-0 ml-1" :disabled="!showRouteData?true:false" color="primary" small 
                    @click="clearMeasure()">CLEAR</v-btn>
                  <v-btn  class="elevation-0 rounded-0 ml-1" :disabled="!showRouteData?true:false" color="primary" small
                    @click="onCountTotal()">COUNT</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0 rounded-0" fab x-small color="#f7f7f7" @click="

                    closeETA()

                    ">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <v-toolbar class="elevation-0 pa-0" dense color="#fff" style="display: flex">
                  <div style="margin-left: -1.5em; display: flex">
                    <v-checkbox v-model="showonMarker" label="" class="mx-0" color="primary" hide-details></v-checkbox>
                    <div style="
                        font-size: 1.2em;
                        margin-left: -1em;
                        margin-top: 1.3em;
                      ">
                      Start From Marker
                    </div>
                    <v-spacer style="width: 1em"></v-spacer>
                    <v-checkbox v-model="showRoute" label="" color="primary" value="red darken-3"
                      hide-details></v-checkbox>
                    <div style="
                        font-size: 1.2em;
                        margin-left: -1em;
                        margin-top: 1.3em;
                      ">
                      Show Route Detail
                    </div>
                  </div>
                </v-toolbar>
                <v-divider></v-divider>
                <div style="padding: 0.5em">
                  <v-list style="margin: auto">
                    <v-list-item>
                      <v-list-item-content style="text-align: left">
                        <table>
                          <tr>
                            <td>
                              <h3 style="font-size: 1.2em">Total Distance</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">:</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">
                                {{ etaMiles }}&nbsp;Nmi
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h3 style="font-size: 1.2em">Duration</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">:</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">
                                {{ etaDuration }}&nbsp;Hours
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h3 style="font-size: 1.2em">Speed</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">:</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">
                                <input style="
                                    width: 11em;
                                    background-color: #d5e6f7;
                                    padding: 0.2em;
                                    margin-right: 0.1em;
                                    cursor: pointer;
                                  " v-model="etaSpeed" type="number" v-on:input="countETA()" />&nbsp;Knots
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h3 style="font-size: 1.2em">Depature</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">:</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">
                                <input style="
                                    width: 11em;
                                    background-color: #d5e6f7;
                                    padding: 0.2em;
                                    margin-right: 0.1em;
                                    cursor: pointer;
                                  " type="datetime-local" id="departure-sailink" name="departure-form"
                                  v-on:input="countETA()" />
                                <span id="depart-container"></span>&nbsp;WIB
                              </h3>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <h3 style="font-size: 1.2em">Arrival</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">:</h3>
                            </td>
                            <td>
                              <h3 style="font-size: 1.2em">
                                <input style="
                                    width: 11em;
                                    background-color: #f7f7f7;
                                    padding: 0.2em;
                                    margin-right: 0.1em;
                                    cursor: auto;
                                  " type="text" readonly :value="etaArrival" />&nbsp;WIB
                              </h3>
                            </td>
                          </tr>
                        </table>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>

                <span class="d-none" id="depart-input-container"></span>
              </v-card>
              <v-card style="margin-bottom:1.4em" class="elevation-0 rounded-0 ETAcard_thurayamobile" v-show="etaControl"
                height="fit-content" width="300">
                <v-toolbar class="elevation-0 rounded-0" dense color="#F7F7F7">
                  <v-btn id="strDraw_btn" class="elevation-0 rounded-0"  x-small
                  :disabled="onDraw ? true : false" color="primary" @click="startDraw()">START DRAW</v-btn>
                  <v-btn :disabled="!showRouteData?true:false" class="elevation-0 rounded-0 ml-1" color="primary" x-small 
                    @click="clearMeasure()">CLEAR</v-btn>
                  <v-btn  :disabled="!showRouteData?true:false" class="elevation-0 rounded-0 ml-1" color="primary" x-small
                    @click="onCountTotal()">COUNT</v-btn>
                  <v-spacer></v-spacer>
                  <v-btn class="elevation-0 rounded-0" fab x-small color="#f7f7f7" @click="

                    closeETA()

                    ">
                    <v-icon dark> mdi-close </v-icon>
                  </v-btn>
                </v-toolbar>
                <v-divider></v-divider>
                <v-toolbar class="elevation-0 pa-0" dense color="#ffff" style="display: flex">
                  <div style="margin-left: -1.5em; display: flex">
                    <v-checkbox v-model="showonMarker" label="" class="mx-0" color="primary" hide-details></v-checkbox>
                    <div style="
                        font-size: 1em;
                        margin-left: -2em;
                        margin-top: 1.3em;
                      ">
                      Start From Marker
                    </div>
                    <v-spacer style="width: 1em"></v-spacer>
                    <v-checkbox v-model="showRoute" label="" color="primary" value="red darken-3"
                      hide-details></v-checkbox>
                    <div style="
                        font-size: 1em;
                        margin-left: -2em;
                        margin-top: 1.3em;
                      ">
                      Show Route Detail
                    </div>
                  </div>
                </v-toolbar>
                <v-divider></v-divider>
                <div style="padding: 0em">
                  <v-list style="margin: auto">
                    <v-list-item>
                      <v-list-item-content style="text-align: left">
                        <table>
                          <tr>
                            <td>
                              Total Distance
                            </td>
                            <td>:</td>
                            <td>

                              {{ etaMiles }}&nbsp;Nmi

                            </td>
                          </tr>
                          <tr>
                            <td>
                              Duration
                            </td>
                            <td>:</td>
                            <td>

                              {{ etaDuration }}&nbsp;Hours

                            </td>
                          </tr>
                          <tr>
                            <td>Speed</td>
                            <td>:</td>
                            <td>

                              <input style="
                                    width: 7em;
                                    background-color: #d5e6f7;
                                    padding: 0.2em;
                                    margin-right: 0.1em;
                                    cursor: pointer;
                                  " v-model="etaSpeed" type="number" v-on:input="countETA()" />&nbsp;Knots

                            </td>
                          </tr>
                          <tr>
                            <td>
                              Depature
                            </td>
                            <td>:</td>
                            <td>

                              <input style="
                                    width: 7em;
                                    background-color: #d5e6f7;
                                    padding: 0.2em;
                                    margin-right: 0.1em;
                                    cursor: pointer;
                                  " type="datetime-local" id="departure-sailink" name="departure-form"
                                v-on:input="countETA()" />
                              <span id="depart-container"></span>&nbsp;WIB

                            </td>
                          </tr>
                          <tr>
                            <td>
                              Arrival
                            </td>
                            <td>:</td>
                            <td>

                              <input style="
                                    width: 7em;
                                    background-color: #f7f7f7;
                                    padding: 0.2em;
                                    margin-right: 0.1em;
                                    cursor: auto;
                                  " type="text" readonly :value="etaArrival" />&nbsp;WIB

                            </td>
                          </tr>
                        </table>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>

                <span class="d-none" id="depart-input-container"></span>
              </v-card>
            </l-control>
            <l-control position="topright">
              <v-card width="300" class="elevation-0 rounded-0 coordinate_thurayadesktop" max-height="800"
                style="overflow-y: auto; margin-left: 1em" v-show="showRoute">
                <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7"
                  dense><v-spacer><v-toolbar-title>Coordinates</v-toolbar-title></v-spacer>
                </v-toolbar>
                <v-divider></v-divider>
                <v-data-table v-if="!showRouteData" :headers="headersRoute" :items="showRouteEmpty"
                  :items-per-page="itemsPerPageCoordinate" :page.sync="pageCoordinate" hide-default-footer
                  class="elevation-0 routeTable" @page-count="pageCountCoordinate = $event"><template v-slot:item="row">
                    <tr>
                      <td class="pa-0" style="white-space: nowrap; text-align: center">
                        {{ row.item.index }}
                      </td>
                      <td class="pa-0" style="white-space: nowrap; text-align: center">
                        {{ row.item.lat }}
                      </td>
                      <td class="pa-0" style="white-space: nowrap; text-align: center">
                        {{ row.item.br }}
                      </td>
                    </tr>
                  </template></v-data-table>
                <v-data-table v-if="showRouteData" :headers="headersRoute" :items="showRouteData" hide-default-footer
                  :page.sync="pageCoordinate" :items-per-page="itemsPerPageCoordinate" class="elevation-0 routeTable"
                  @page-count="pageCountCoordinate = $event"><template v-slot:item="row">
                    <tr>
                      <td class="pa-0" style="white-space: nowrap; text-align: center">
                        {{ row.item.index + 1 }}
                      </td>
                      <td class="pa-0" style="text-align: center">
                        {{ convertDMS(row.item.lat, row.item.lng).split("-")[0]
                        }}<br />
                        {{
                          convertDMS(row.item.lat, row.item.lng).split("-")[1]
                        }}
                      </td>
                      <td class="pa-0" style="text-align: center" v-if="brRouteData">
                        {{
                          brRouteData[row.item.index]._icon.childNodes[4]
                            .innerText
                        }}
                        &nbsp;/&nbsp;
                        {{
                          brRouteData[
                            row.item.index
                          ]._icon.childNodes[0].data.split(" ")[1]
                        }}
                      </td>
                      <td class="pa-0" style="text-align: center" v-else></td>
                    </tr>
                  </template></v-data-table>
                <v-toolbar v-if="showRouteData" dense class="elevation-0 rounded-0" color="#F7F7F7">
                  <p class="mt-3" style="font-size: 0.8em">
                    Showing 1 to {{ itemsPerPageCoordinate }} of
                    {{ showRouteData.length }} entries
                  </p>
                  <v-spacer></v-spacer>
                  <v-pagination dense color="primary" v-model="pageCoordinate" :length="pageCountCoordinate"
                    :total-visible="7"></v-pagination>
                </v-toolbar>
              </v-card>
              <v-card width="180" class="elevation-0 rounded-0 coordinate_thurayamobile" max-height="800"
                style="overflow-y: auto; margin-left: 1em" v-show="showRoute">
                <v-toolbar class="elevation-0 rounded-0" color="#f7f7f7" dense><v-spacer>Coordinates</v-spacer>
                </v-toolbar>

                <v-data-table v-if="!showRouteData" :headers="headersRoute" :items="showRouteEmpty" :items-per-page="3"
                  :page.sync="pageCoordinate" hide-default-footer hide-default-header style="text-align: left"
                  class="elevation-0 routeTable" @page-count="pageCountCoordinate = $event"><template v-slot:item="row">
                    <tr>
                      <td>
                        <v-card class="elevation-0 rounded-0">
                          <table>
                            <tr>
                              <td>#</td>
                              <td>:</td>
                              <td class="pa-0" style="white-space: nowrap; text-align: left">
                                {{ row.item.index }}
                              </td>
                            </tr>
                            <tr>
                              <td>Lat/Lng</td>
                              <td>:</td>
                              <td class="pa-0" style="white-space: nowrap; text-align: left">
                                {{ row.item.lat }}
                              </td>
                            </tr>
                            <tr>
                              <td>Dist/Brng</td>
                              <td>:</td>
                              <td class="pa-0" style="white-space: nowrap; text-align: left">
                                {{ row.item.br }}
                              </td>
                            </tr>


                          </table>
                        </v-card>
                      </td>
                    </tr>
                  </template></v-data-table>
                <v-data-table v-if="showRouteData" :headers="headersRoute" :items="showRouteData" hide-default-footer
                  hide-default-header :page.sync="pageCoordinate" :items-per-page="2" class="elevation-0 routeTable"
                  style="text-align: left" @page-count="pageCountCoordinate = $event"><template v-slot:item="row">
                    <tr>
                      <td>
                        <v-card class="elevation-0 rounded-0">
                          <table>
                            <tr>
                              <td>#</td>
                              <td>:</td>
                              <td class="pa-0" style="white-space: nowrap; text-align: left">
                                {{ row.item.index + 1 }}
                              </td>
                            </tr>
                            <tr>
                              <td>Lat/Lng</td>
                              <td>:</td>
                              <td class="pa-0" style="text-align: left">
                                {{ convertDMS(row.item.lat, row.item.lng).split("-")[0]
                                }}<br />
                                {{
                                  convertDMS(row.item.lat, row.item.lng).split("-")[1]
                                }}
                              </td>
                            </tr>
                            <tr>
                              <td>Dist/Brng</td>
                              <td>:</td>
                              <td class="pa-0" style="text-align: left" v-if="brRouteData">
                                {{
                                  brRouteData[row.item.index]._icon.childNodes[4]
                                    .innerText
                                }}
                                &nbsp;/&nbsp;
                                {{
                                  brRouteData[
                                    row.item.index
                                  ]._icon.childNodes[0].data.split(" ")[1]
                                }}
                              </td>
                              <td class="pa-0" v-else></td>
                            </tr>


                          </table>
                        </v-card>
                      </td>
                    </tr>




                  </template></v-data-table>
                <v-toolbar v-if="showRouteData" dense class="elevation-0 rounded-0" color="#F7F7F7">
                  <v-spacer></v-spacer>
                  <v-pagination dense color="primary" v-model="pageCoordinate" :length="pageCountCoordinate"
                    :total-visible="7"></v-pagination>
                </v-toolbar>
              </v-card>
            </l-control>
          </l-map>
          <v-divider v-if= "user_allowed.role_id <= 3"></v-divider>
          <v-toolbar v-if= "user_allowed.role_id <= 3" dense color="#F7F7F7" elevation="0">
            User Who Can See This Vessel
            <v-spacer style="width: 20%"></v-spacer>
            <v-text-field style="border: solid 1px #e0e0e0" width="40" v-model="searchUserWhoThur" hide-details
              prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
              class="elevation-0 rounded-0"></v-text-field>
          </v-toolbar>
          <v-divider v-if= "user_allowed.role_id <= 3"></v-divider>
          <v-data-table v-if= "user_allowed.role_id <= 3" :headers="headersUser" :items="vesselUsersThuraya" :items-per-page="4"
            class="elevation-0 rounded-0 dataTable userTable_desktop" hide-default-footer hide-default-header
            :page.sync="pageUser" @page-count="pageCountUser = $event" :search="searchUserWhoThur" dense>
            <template v-slot:header>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                #
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Name
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Email
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Role
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Last Login
              </th>
            </template>
            <template v-slot:item="row">
              <tr>
                <td class="pa-0" style="white-space: nowrap; text-align: center">
                  {{ row.item.index + 1 }}
                </td>
                <td class="pa-0" style="white-space: nowrap; text-align: center">
                  {{ row.item.name }}
                </td>
                <td class="pa-0" style="white-space: nowrap; text-align: center">
                  {{ row.item.email }}
                </td>
                <td class="pa-0" style="white-space: nowrap; text-align: center">
                  {{ row.item.role_name }}
                </td>
                <td class="pa-0" style="white-space: nowrap; text-align: center">
                  {{ row.item.lastLogin }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table v-if= "user_allowed.role_id <= 3" :headers="headersUser" :items="vesselUsersThuraya" :items-per-page="3"
            class="elevation-0 rounded-0 userTable_mobile dataTable_Sailink" hide-default-footer hide-default-header
            :search="searchUserWho" :page.sync="pageUser" @page-count="pageCountUser = $event"
            @pagination="intoFilterUser" dense><template v-slot:item="row">
              <tr>
                <td style="border-bottom: none; text-align: left">
                  <v-card style="border-bottom: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-0" width="80vw">
                    <table>
                      <tr>
                        <td>{{ row.item.index + 1 }}</td>
                        <td></td>
                        <td></td>
                      </tr>
                      <tr>
                        <td>Name</td>
                        <td>:</td>
                        <td>{{ row.item.name }}</td>
                      </tr>
                      <tr>
                        <td>Email</td>
                        <td>:</td>
                        <td>{{ row.item.email }}</td>
                      </tr>
                      <tr>
                        <td>Role</td>
                        <td>:</td>
                        <td>{{ row.item.role_name }}</td>
                      </tr>
                      <tr>
                        <td>Last Login</td>
                        <td>:</td>
                        <td>{{ row.item.lastLogin }}</td>
                      </tr>
                    </table>
                  </v-card>
                </td>
              </tr>
            </template></v-data-table>
          <v-divider v-if= "user_allowed.role_id <= 3"></v-divider>
          <v-toolbar v-if= "user_allowed.role_id <= 3" dense class="elevation-0 rounded-0" color="#F7F7F7">
            <p class="mt-3" style="font-size: 0.8em">
              Showing 1 to {{ itemsPerPage }} of
              {{ vesselUsersThuraya.length }} entries
            </p>
            <v-spacer></v-spacer>
            <v-pagination color="primary" dense v-model="pageUser" :length="pageCountUser"
              :total-visible="7"></v-pagination>
          </v-toolbar>
        </v-card>
      </v-card>

      <v-card style="border: solid 1px #e0e0e0" class="datatabledetail elevation-0 rounded-0 ma-3 mb-0 mt-0">
        <v-toolbar elevation="0" style="max-height: 70px" color="#F7F7F7" fixed dense>
          <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
            <template v-slot:activator="{ on, attrs }">
              <v-btn style="border: solid 1px #e0e0e0" small class="rounded-0 pa-1 elevation-0 ma-0 mr-2" color="#ffff"
                v-bind="attrs" v-on="on">
                {{ kFormatter(itemsPerPage)
                }}<v-icon small style="margin-left: 0.2em">mdi-unfold-more-horizontal</v-icon>
              </v-btn>
            </template>
            <v-list style="border: solid 1px #e0e0e0">
              <v-list-item @click="itemsPerPage = 10"><v-list-item-title>10</v-list-item-title></v-list-item>
              <v-list-item @click="itemsPerPage = 25"><v-list-item-title>25</v-list-item-title></v-list-item>
              <v-list-item @click="itemsPerPage = 50"><v-list-item-title>50</v-list-item-title></v-list-item>
              <v-list-item @click="itemsPerPage = 100"><v-list-item-title>100</v-list-item-title></v-list-item>
            </v-list>
          </v-menu>
          <v-btn color="primary" class="elevation-0 rounded-0 csvthuraya_desktop" @click="createCSV()" small><v-icon
              small>mdi-file-move</v-icon> &nbsp;Export CSV</v-btn>
          <v-btn :href="'https://sailink.id/cetak/pdf/thuraya/' + showmapthuraya.number + '/' + exp_range" color="primary"
            dark small style="color:#fff" class="elevation-0 rounded-0 pt-1 ml-2 pdfthuraya_desktop" target="_blank">
            <v-icon x-small>mdi-file-move</v-icon> Export PDF
          </v-btn>
          <v-btn :href="'https://sailink.id/cetak/kml/thuraya/' + showmapthuraya.number + '/' + exp_range" color="primary"
            dark small style="color:#fff" class="elevation-0 rounded-0 pt-1 ml-2 pdfthuraya_desktop" target="_blank">
            <v-icon x-small>mdi-file-move</v-icon> Export KML
          </v-btn>

          <v-btn color="primary" class="elevation-0 rounded-0 csvthuraya_mobile" @click="createCSV()" x-small>CSV</v-btn>
          <v-btn color="primary" class="elevation-0 rounded-0 pdfthuraya_mobile pb-0 ml-1"
            :href="'https://sailink.id/cetak/pdf/thuraya/' + showmapthuraya.number + '/' + exp_range" target="_blank"
            x-small>PDF</v-btn>

          <v-btn color="primary" class="elevation-0 rounded-0 pdfthuraya_mobile pb-0 ml-1"
            :href="'https://sailink.id/cetak/kml/thuraya/' + showmapthuraya.number + '/' + exp_range" target="_blank"
            x-small>KML</v-btn>

          <v-spacer style="width: 50%"></v-spacer>
          <v-text-field style="border: solid 1px #e0e0e0" width="40" v-model="search" hide-details
            prepend-inner-icon="mdi-magnify" placeholder="Search" solo clearable
            class="elevation-0 rounded-0"></v-text-field>
        </v-toolbar>
        <v-divider></v-divider>
        <v-layout v-resize="onResize" column style="">
          <v-container id="map_thuraya" v-if="!showmaptracking_thur" fill-height fluid style="height: 250px">
            <v-row align="center" justify="center">
              <v-col><img alt="Navplus logo" width="100px" :src="loadernavplus" class="justify-center" /></v-col>
            </v-row>
          </v-container>
          <v-data-table :search="search" style="margin-top: 0px"
            v-if="showmaptracking_thur && showmapthuraya.weather == null" :headers="headersData"
            :items="showmaptracking_thur.dataTable" hide-default-footer hide-default-header :page.sync="page"
            :items-per-page="itemsPerPage" class="elevation-0 rounded-0 dataTable trackingTable_thurayadesktop"
            @page-count="pageCount = $event">
            <template v-slot:header>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                No
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Latitude, Longitude
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                DMS
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Altitude
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Speed<br />(Knots)
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Heading<br />(Degree)
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Distance<br />(Nmi)
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                dateTime<br />(UTC)
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                dateTime<br />(GMT+7)
              </th>
            </template>
            <template v-slot:item="row">
              <tr>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.index + 1 }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  <a text class="link_latlon" style="color: black" @click="triggerPopup(row.item)">{{ row.item.lat }} , {{
                    row.item.lng }}</a>
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.dms }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.altitude }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.speed }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.heading }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.distance }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.dateTime }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ row.item.dateTime_local }}
                </td>
              </tr>
            </template>
          </v-data-table>
          <v-data-table :search="search" style="margin-top: 0px"
            v-if="showmaptracking_thur && showmapthuraya.weather != null" :headers="headers"
            :items="showmaptracking_thur.dataTable" multi-sort hide-default-footer hide-default-header :page.sync="page"
            :items-per-page="itemsPerPage" class="elevation-0 rounded-0 dataTable trackingTable_thurayadesktop"
            @page-count="pageCount = $event" item-key="msg_id" :expanded.sync="expanded" :single-expand="singleExpand">
            <template v-slot:header>

              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                No
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Latitude, Longitude
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">Weather Status</th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                DMS
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Altitude
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Speed<br />(Knots)
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Heading<br />(Degree)
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                Distance<br />(Nmi)
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                dateTime<br />(UTC)
              </th>
              <th class="pa-0 pt-2 pl-1 pb-2" style="
                  text-align: left;
                  color: #626364;
                  font-size: 0.9em;
                  height: 3em;
                  text-align: center;
                ">
                dateTime<br />(GMT+7)
              </th>
            </template>
            <template v-slot:item="{ item, expand, isExpanded }">
              <tr>

                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.index + 1 }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  <a text class="link_latlon" style="color: black" @click="triggerPopup(item)">{{ item.lat }} , {{
                    item.lng }}</a>
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.weather }}
                  <v-btn icon x-small class="mx-2 rounded-0 elevation-0" fab dark :color="!isExpanded ? 'green' : 'red'"
                    @click="
                      !isExpanded
                        ? (weatherClick(
                          showmapthuraya.id,
                          item.msg_id,
                          'thuraya',
                          item.dateTime
                        ),
                          expand(!isExpanded))
                        : (removeWeather(item.dateTime), expand(!isExpanded))
                      ">
                    <v-icon dark>
                      {{ !isExpanded ? "mdi-plus-box" : "mdi-minus-box" }}
                    </v-icon>
                  </v-btn>
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.dms }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.altitude }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.speed }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.heading }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.distance }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.dateTime }}
                </td>
                <td class="pa-0" style="white-space: nowrap; padding: 1em; text-align: center">
                  {{ item.dateTime_local }}
                </td>
              </tr>
            </template>

            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style="background-color: #b8cce2">
                <v-card class="elevation-0 rounded-0 pa-1" width="100%">
                  <v-card class="elevation-0 rounded-0 pa-2" style="border: solid 1px #e0e0e0">
                    <v-card class="elevation-0 rounded-0 pa-2" style="border: solid 1px #e0e0e0">
                      <v-toolbar class="elevation-0 text-center" dense color="#F7F7F7" elevation="2">
                        <v-spacer> Weather & Wave </v-spacer></v-toolbar>
                      <v-progress-linear v-if="!weatherData[item.dateTime]" indeterminate color="primary" height="7"
                        striped></v-progress-linear>
                      <v-row v-if="weatherData[item.dateTime]" no-gutters style="flex-wrap: nowrap">
                        <v-col cols="1" style="min-width: 100px; max-width: 100%; margin: 5px"
                          class="flex-grow-1 flex-shrink-0">
                          <v-card class="pa-2" outlined tile>
                            <v-card-text>
                              <v-simple-table>
                                <template v-slot:default>
                                  <tr>
                                    <td style="padding: 6px">Weather</td>
                                    <td style="padding: 6px">
                                      {{ weatherData[item.dateTime].weather }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 6px">Wind Direction</td>
                                    <td style="padding: 6px">
                                      {{ weatherData[item.dateTime].windDirection }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 6px">Wind Speed</td>
                                    <td style="padding: 6px">
                                      {{ weatherData[item.dateTime].windSpeed }}
                                    </td>
                                  </tr>
                                </template>
                              </v-simple-table>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="1" style="min-width: 100px; max-width: 100%; margin: 5px"
                          class="flex-grow-1 flex-shrink-0">
                          <v-card class="pa-2" outlined tile>
                            <v-card-text>
                              <v-simple-table>
                                <template v-slot:default>
                                  <tr>
                                    <td style="padding: 6px">Temperature</td>
                                    <td style="padding: 6px">
                                      {{ weatherData[item.dateTime].temperature }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 6px">Humidity</td>
                                    <td style="padding: 6px">
                                      {{ weatherData[item.dateTime].humidity }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 6px">Dew Point</td>
                                    <td style="padding: 6px">
                                      {{ weatherData[item.dateTime].dewPoint }}
                                    </td>
                                  </tr>
                                </template>
                              </v-simple-table>
                            </v-card-text>
                          </v-card>
                        </v-col>
                        <v-col cols="1" style="min-width: 100px; max-width: 100%; margin: 5px"
                          class="flex-grow-1 flex-shrink-0">
                          <v-card class="pa-2" outlined tile>
                            <v-card-text>
                              <v-simple-table>
                                <template v-slot:default>
                                  <tr>
                                    <td style="padding: 6px">Wave Height</td>
                                    <td style="padding: 6px">
                                      {{
                                        weatherData[item.dateTime]
                                          .waveSignificantHeight
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 6px">Wave From Direction</td>
                                    <td style="padding: 6px">
                                      {{
                                        weatherData[item.dateTime].waveFromDirection
                                      }}
                                    </td>
                                  </tr>
                                  <tr>
                                    <td style="padding: 6px">
                                      <p style="height: 2px"></p>
                                    </td>
                                    <td style="padding: 6px">
                                      <p style="height: 2px"></p>
                                    </td>
                                  </tr>
                                </template>
                              </v-simple-table>
                            </v-card-text>
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-card>
                </v-card>
              </td>
            </template>
          </v-data-table>
          <v-data-table :search="search" style="margin-top: 0px"
            v-if="showmaptracking_thur && showmapthuraya.weather != null" :headers="headers"
            :items="showmaptracking_thur.dataTable" multi-sort hide-default-footer hide-default-header :page.sync="page"
            :items-per-page="itemsPerPage" class="elevation-0 dataTable_Sailink trackingTable_thurayamobile"
            @page-count="pageCount = $event" item-key="msg_id" :expanded.sync="expanded" @pagination="intoFilter"
            :single-expand="singleExpand"><template v-slot:item="row">
              <tr>
                <td style="border-bottom: none; text-align: left">
                  <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" width="80vw">
                    <v-toolbar style="border-bottom: solid 1px #e0e0e0" class="elevation-0 rounded-0" dense>{{
                      row.item.index + 1 }}<v-spacer></v-spacer>
                      <v-btn @click="!weatherData[row.item.dateTime] ? weatherClick(
                        showmapthuraya.id,
                        row.item.msg_id,
                        'thuraya',
                        row.item.dateTime
                      ) : removeWeather(row.item.dateTime)" x-small class="mx-2 rounded-0 elevation-0" fab dark
                        color="primary">
                        <v-icon dark>
                          {{ !weatherData[row.item.dateTime] ? "mdi-plus" : "mdi-minus" }}
                        </v-icon>
                      </v-btn>
                    </v-toolbar>
                    <v-card-text>
                      <table>
                        <tr>
                          <td>Latitude, Longitude </td>
                          <td>:</td>
                          <td>
                            <a @mouseover="latdms = row.item.index" @mouseleave="latdms = null" class="link_latlon"
                              :style="latdms == row.item.index ? 'color:blue' : 'color:black'" small text
                              @click="triggerPopup(row.item)">{{ row.item.lat }} , {{ row.item.lng }}<br />({{
                                row.item.dms }})</a>

                          </td>
                        </tr>
                        <tr>
                          <td>DMS</td>
                          <td>:</td>
                          <td>
                            {{ row.item.dms }}
                          </td>
                        </tr>
                        <tr>
                          <td>Altitude</td>
                          <td>:</td>
                          <td>{{ row.item.altitude }}</td>
                        </tr>
                        <tr>
                          <td>Heading (Degree)</td>
                          <td>:</td>
                          <td>{{ row.item.heading }}</td>
                        </tr>
                        <tr>
                          <td>Speed (Knots)</td>
                          <td>:</td>
                          <td>{{ row.item.speed }}</td>
                        </tr>
                        <tr>
                          <td>Date/Time (UTC)</td>
                          <td>:</td>
                          <td>{{ row.item.dateTime_local }}</td>
                        </tr>
                        <tr>
                          <td>Date/Time (GMT+7)</td>
                          <td>:</td>
                          <td>{{ row.item.dateTime }}</td>
                        </tr>

                      </table>
                    </v-card-text>
                  </v-card>
                </td>



                <v-progress-linear
                  v-if="showclick_dateTime[row.item.dateTime] == row.item.dateTime && !weatherData[row.item.dateTime]"
                  indeterminate color="primary" height="7" striped></v-progress-linear>

              </tr>
              <tr style="background-color:#C1C1C1" v-if="weatherData[row.item.dateTime]">
                <td style="border-bottom: none; text-align: left">
                  <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" width="80vw">
                    <v-toolbar style="border-bottom: solid 1px #e0e0e0" class="elevation-0 rounded-0" dense>Weather &
                      Wave<v-spacer></v-spacer>
                    </v-toolbar>
                    <v-card-text>
                      <table>
                        <tr>
                          <td>Weather</td>
                          <td>:</td>
                          <td>
                            {{ weatherData[row.item.dateTime].weather }}
                          </td>
                        </tr>
                        <tr>
                          <td>Wind Direction</td>
                          <td>:</td>
                          <td> {{ weatherData[row.item.dateTime].windDirection }}</td>
                        </tr>
                        <tr>
                          <td>Wind Speed</td>
                          <td>:</td>
                          <td> {{ weatherData[row.item.dateTime].weather }}</td>
                        </tr>
                        <tr>
                          <td>Temperature</td>
                          <td>:</td>
                          <td>{{ weatherData[row.item.dateTime].temperature }}</td>
                        </tr>
                        <tr>
                          <td>Humidity</td>
                          <td>:</td>
                          <td>{{ weatherData[row.item.dateTime].humidity }}</td>
                        </tr>
                        <tr>
                          <td>Humidity</td>
                          <td>:</td>
                          <td> {{ weatherData[row.item.dateTime].dewPoint }}</td>
                        </tr>

                        <tr>
                          <td>Wave Height</td>
                          <td>:</td>
                          <td>{{ weatherData[row.item.dateTime]
                            .waveSignificantHeight }}</td>
                        </tr>
                        <tr>
                          <td>Wave From Direction</td>
                          <td>:</td>
                          <td> {{
                            weatherData[row.item.dateTime].waveFromDirection
                          }}</td>
                        </tr>
                      </table>
                    </v-card-text>
                  </v-card>
                </td>
              </tr>
            </template></v-data-table>
          <v-data-table :search="search" style="margin-top: 0px"
            v-if="showmaptracking_thur && showmapthuraya.weather == null" :headers="headers"
            :items="showmaptracking_thur.dataTable" multi-sort hide-default-footer hide-default-header :page.sync="page"
            :items-per-page="itemsPerPage" class="elevation-0 dataTable_Sailink trackingTable_thurayamobile"
            @page-count="pageCount = $event" item-key="msg_id" @pagination="intoFilter"><template v-slot:item="row">
              <tr>
                <td style="border-bottom: none; text-align: left">
                  <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 ma-1" width="80vw">
                    <v-toolbar style="border-bottom: solid 1px #e0e0e0" class="elevation-0 rounded-0" dense>{{
                      row.item.index + 1 }}<v-spacer></v-spacer>

                    </v-toolbar>
                    <v-card-text>
                      <table>
                        <tr>
                          <td>Latitude, Longitude </td>
                          <td>:</td>
                          <td>
                            <a @mouseover="latdms = row.item.index" @mouseleave="latdms = null" class="link_latlon"
                              :style="latdms == row.item.index ? 'color:blue' : 'color:black'" small text
                              @click="triggerPopup(row.item)">{{ row.item.lat }} , {{ row.item.lng }}<br />({{
                                row.item.dms }})</a>

                          </td>
                        </tr>
                        <tr>
                          <td>DMS</td>
                          <td>:</td>
                          <td>
                            {{ row.item.dms }}
                          </td>
                        </tr>
                        <tr>
                          <td>Altitude</td>
                          <td>:</td>
                          <td>{{ row.item.altitude }}</td>
                        </tr>
                        <tr>
                          <td>Heading (Degree)</td>
                          <td>:</td>
                          <td>{{ row.item.heading }}</td>
                        </tr>
                        <tr>
                          <td>Speed (Knots)</td>
                          <td>:</td>
                          <td>{{ row.item.speed }}</td>
                        </tr>
                        <tr>
                          <td>Date/Time (UTC)</td>
                          <td>:</td>
                          <td>{{ row.item.dateTime_local }}</td>
                        </tr>
                        <tr>
                          <td>Date/Time (GMT+7)</td>
                          <td>:</td>
                          <td>{{ row.item.dateTime }}</td>
                        </tr>

                      </table>
                    </v-card-text>
                  </v-card>
                </td>


              </tr>

            </template></v-data-table>
          <v-divider></v-divider>

          <v-toolbar v-if="showmaptracking_thur" dense class="elevation-0 rounded-0" color="#F7F7F7">
            <p class="mt-3" style="font-size: 0.8em">
              Showing 1 to {{ itemsPerPage }} of
              {{ showmaptracking_thur.dataTable.length }} entries
            </p>
            <v-spacer></v-spacer>
            <v-pagination class="mt-2" color="primary" v-if="showmapthuraya" v-model="page" :length="pageCount"
              :total-visible="7"></v-pagination>
          </v-toolbar>
        </v-layout>

        <div id="app">
          <v-app id="inspire"> </v-app>
        </div>
      </v-card>

      <!--Modal Image-->
      <v-dialog v-model="imageOpen">
        <!-- <v-carousel v-model="model" width="fit-content">
          <v-carousel-item
            @click="(imageOpen = true), (imageData = showmapthuraya.images)"
            v-for="image in showmapthuraya.images"
            :key="image"
          >
            <v-img :src="image[0]" ></v-img>
          </v-carousel-item>
        </v-carousel> -->
        <v-carousel hide-controls hide-delimiters>
          <v-carousel-item @click="imageOpen = false" v-for="(item, i) in showmapthuraya.images" :key="i">
            <img style="height: 100%" :src="item[0]" />
          </v-carousel-item>
        </v-carousel>
      </v-dialog>
      <!--Modal Notif End-->

      <!--Modal Date Picker-->
      <v-dialog ref="dialog" v-model="dateopen" :return-value.sync="dates" class="elevation-0 rounded-0" persistent
        width="750">
        <v-card class="elevation-0 rounded-0 pb-1">
          <v-toolbar dense color="#f7f7f7" class="elevation-0 rounded-0">
            Choose Custom Date Range for Tracking
            <v-spacer></v-spacer>
            <v-btn small class="elevation-0 rounded-0" icon @click="closeCostumRange()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-divider></v-divider>
          <v-card class="elevation-0 rounded-0">
            <v-alert v-if="cant_future" class="elevation-0 rounded-0 ma-2 mt-3" text dense color="red"
              icon="mdi-clock-alert-outline" style="font-size: 0.86em;text-align: left;">
              Cannot predict future | Enter the correct date and time range
            </v-alert>
            <v-alert v-if="cant_backwards" type="warning" class="elevation-0 rounded-0 ma-2 mt-3" text dense
              icon="mdi-history" style="font-size: 0.86em;text-align: left;">
              Backwards date and time range | Enter the correct date and time range
            </v-alert>
          </v-card>
          <v-row width="100%" no-gutters>
            <v-col cols="12" sm="6">
              <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 pa-2 ma-2">
                <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 pa-2 ma-1">
                  <v-date-picker @click:date="logtest" :disabled="(testdisabled || dates.length > 1) ? true : false"
                    color="primary" class="elevation-0 rounded-0" v-model="dates" range scrollable>
                  </v-date-picker>
                </v-card>
              </v-card>
            </v-col>
            <v-col cols="12" sm="6">
              <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0 pa-2 ma-2">
                <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0  ma-2">
                  <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">From</v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="7">

                        <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="dates[0]" label="Date"
                          prepend-inner-icon="mdi-calendar"></v-text-field>

                      </v-col>
                      <v-col cols="12" sm="5">

                        <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="times[0]" label="Time"
                          prepend-inner-icon="mdi-clock-outline"></v-text-field>

                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
                <v-card style="border: solid 1px #e0e0e0" class="elevation-0 rounded-0  ma-2">
                  <v-toolbar dense class="elevation-0 rounded-0" color="#f7f7f7">To</v-toolbar>
                  <v-card-text>
                    <v-row>
                      <v-col cols="12" sm="7">

                        <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="dates[1]" label="Date"
                          prepend-inner-icon="mdi-calendar"></v-text-field>

                      </v-col>
                      <v-col cols="12" sm="5">

                        <v-text-field readonly class="elevation-0 rounded-0" outlined v-model="times[1]" label="Time"
                          prepend-inner-icon="mdi-clock-outline"></v-text-field>

                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>


                <v-toolbar class="elevation-0 rounded-0" dense color="#f7f7f7">
                  <v-spacer></v-spacer>
                  <v-btn small :disabled="dates.length > 1 ? false : true" class="elevation-0 rounded-0 mr-1"
                    @click="resetTimesDates()" color="primary"> Reset </v-btn>
                  <v-btn small :disabled="dates.length > 1 ? false : true" class="elevation-0 rounded-0" color="primary"
                    @click="customRange()"> OK </v-btn>
                </v-toolbar>
              </v-card>
            </v-col>
          </v-row>

        </v-card>

      </v-dialog>

      <!--Modal Time Picker-->
      <v-dialog ref="dialog" v-model="timeopen" :return-value.sync="dates" class="elevation-0 rounded-0" persistent
        width="fit-content">
        <v-card class="elevation-0 rounded-0 pa-2">
          <v-card style="border: solid 1px #e0e0e0;display: block;" class="elevation-0 rounded-0 ma-2">
            <v-card style="border: solid 1px #e0e0e0;display: block;" class="elevation-0 rounded-0 ma-2">
              <v-time-picker v-model="timein" ampm-in-title format="24hr">
              </v-time-picker>
            </v-card>

            <v-card style="border: solid 1px #e0e0e0;display: block;" class="elevation-0 rounded-0 ma-2">
              <v-toolbar class="elevation-0 rounded-0" dense color="f7f7f7">
                <v-spacer></v-spacer>
                <v-btn :disabled="!timein ? true : false" class="elevation-0 rounded-0" small color="primary"
                  @click="saveTimes()"> Choose Time </v-btn>
              </v-toolbar>
            </v-card>

          </v-card>
        </v-card>
      </v-dialog>
    </v-card>
  </v-card>
</template>

<script>
import $ from "jquery";
import L from "leaflet";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import {
  LPopup,
  LMap,
  LTileLayer,
  LIcon,
  LControl,
  LControlZoom,
} from "vue2-leaflet";
import Vue2LeafletRotatedMarker from "vue2-leaflet-rotatedmarker";
import LMovingRotatedMarker from "vue2-leaflet-rotatedmarker";
import LControlFullscreen from "vue2-leaflet-fullscreen";
import LControlPolylineMeasure from "vue2-leaflet-polyline-measure";
import mapstyle from "@/assets/json/mapstyle.json";
import { mapSettingsShow } from "@/constants/mapSettings";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css';

const iconUrl =
  "data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iVVRGLTgiPz48c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgdmlld0JveD0iMCAwIDIuODggMy40NiI+PGRlZnM+PHN0eWxlPi5ke2ZpbGw6IzAwMDBGRjtzdHJva2U6I2ZmZjtzdHJva2UtbWl0ZXJsaW1pdDoxMDtzdHJva2Utd2lkdGg6LjJweDt9PC9zdHlsZT48L2RlZnM+PGcgaWQ9ImEiLz48ZyBpZD0iYiI+PGcgaWQ9ImMiPjxwYXRoIGNsYXNzPSJkIiBkPSJNMi42OSwzLjI2bC0uOTYtLjQ1Yy0uMTktLjA5LS40LS4wOS0uNTksMGwtLjk2LC40NUwxLjQ0LC4yNmwxLjI1LDNaIi8+PC9nPjwvZz48L3N2Zz4=";

export default {
  components: {
    LPopup,
    LIcon,
    LMap,
    LTileLayer,
    LControl,
    LControlPolylineMeasure,
    LControlFullscreen,
    LControlZoom,
    "v-rotated-marker": Vue2LeafletRotatedMarker,
    LMovingRotatedMarker,
  },
  data() {
    return {
      showonMarker: false,
      onFinish: false,
      currentLine: null,
      coords: null,
      toolTipsArr: null,
      cant_future: false,
      cant_backwards: false,
      unit_: "nauticalmiles",
      exp_range: 'today',
      showclick_dateTime: [],
      countLength: 0,
      IntervalMoving: null,
      fetchInterval: null,
      icon: L.icon({
        iconUrl: iconUrl,
        iconSize: [17, 22],
        iconAnchor: [8.5, 11.5],
        popupAnchor: [0, -25],
      }),
      drivePath: null,
      driveLatLng: null,
      driveRotationAngle: null,
      driveMarker: null,
      searchUserWhoThur: "",

      weatherData: [],
      headersRoute: [
        {
          text: "#",
          value: "index",
        },
        { text: "Latitude/Longitude", value: "Latitude" },
        { text: "Distance/Bearing", value: "Distance/Bearing" },
      ],
      itemsPerPageCoordinate: 10,
      pageCoordinate: 1,
      pageCountCoordinate: 0,
      showRoute: false,
      showRouteData: null,
      showRouteEmpty: [{ index: 0, lat: "-", lng: "-", br: "-" }],
      onDraw: null,
      etaControl: null,
      etaArrival: "",
      etaDuration: 0,
      etaSpeed: 1,
      etaMiles: 0,
      url: "https://maps.skyreach.id/sailink/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      dateopen: false,
      timeopen: false,
      datepickerID: null,
      pageUser: 1,
      pageCountUser: 0,
      dates: [],
      times: [],
      timein: null,
      itemsPerPage: 10,
      page: 1,
      pageCount: 0,
      itemsPerPageImage: 10,
      pageImage: 1,
      pageCountImage: 0,
      loadernavplus: require("@/assets/img/load100new.gif"),
      headers: [

        {
          text: "#",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Latitude, Longtitude ", value: "lat" },
        {
          text: " ",
          align: "start",
        },
        { text: "DMS", value: "dms" },
        { text: "Altitude", value: "altitude" },
        { text: "Speed (Knots)", value: "speed" },
        { text: "Heading (Degree)", value: "heading" },
        { text: "Distance(Nmi)", value: "distance" },
        { text: "dateTime (UTC)", value: "dateTime" },
        { text: "dateTime (GMT+7)", value: "dateTime_local" },
      ],
      headersData: [
        {
          text: "#",
          align: "start",
          sortable: false,
          value: "id",
        },
        { text: "Latitude, Longtitude ", value: "lat" },
        { text: "DMS", value: "dms" },
        { text: "Altitude", value: "altitude" },
        { text: "Speed (Knots)", value: "speed" },
        { text: "Heading (Degree)", value: "heading" },
        { text: "Distance(Nmi)", value: "distance" },
        { text: "dateTime (UTC)", value: "dateTime" },
        { text: "dateTime (GMT+7)", value: "dateTime_local" },
      ],
      headersUser: [
        {
          text: "#",
          align: "start",
          sortable: false,
        },
        { text: "Name", value: "name", sortable: false },
        { text: "Email", value: "email", sortable: false },
        { text: "Role", value: "role_name", sortable: false },
        { text: "Last Login", value: "lastLogin", sortable: false },
      ],
      options: {
        disableDefaultUI: true,
        fullscreenControl: true,
        zoomControl: true,
        styles: mapstyle,
      },
      imageOpen: null,
      imageData: null,
      msg_id_after: null,
      weather_data: null,
      search: "",
    };
  },

  mounted() {
    var mama = this;
    // clearInterval(this.IntervalMoving);
    // this.initialdata_movingfirst()
    // this.$root.$on('fromMap_Mooving_Thuraya', () => {
    //        this.fromMap_Mooving_Thuraya()
    // })

    if (!mama.thuraya_executed) {
      console.log("DI SET KE TRUE OLEH MOUTED MAP");
      mama.addthuraya_executed(true);
      $("html, body").animate(
        {
          scrollTop: $("#title_map_thuraya").offset().top - 109,
        },
        800
      );
    }
  },

  // watch:{
  //   showmaptracking_thur:function(e){
  //     console.log('SHOW MAP TRACKING RUN')

  //   }
  // },

  methods: {
    ...mapActions([
      "unsetmaptracking_",
      "showonMapThuraya",
      "showdateThuraya",
      "removeShowThuraya",
      "inputRPMID",
      "inputSearchIOT",
      "set_clickdate",
      "addthuraya_executed",
    ]),

    initialdata_moving() {
      var mother = this;

      mother.drivePath = mother.showmaptracking_thur.tracking.slice();
      mother.driveLatLng = L.latLng([
        mother.drivePath[0].lat,
        mother.drivePath[0].lng,
      ]);
      mother.driveRotationAngle = mother.drivePath[0].heading;
    },

    newAnimation() {
      this.drivePath = null;
      clearInterval(this.IntervalMoving);
      this.initialdata_moving();
    },

    movingStart() {
      var mother = this;
      this.IntervalMoving = setInterval(function () {
        mother.simulate();
      }, 300);
    },

    simulate() {
      this.countLength = this.countLength + 1;
      console.log(this.countLength);
      console.log(this.drivePath);

      if (!this.drivePath.length) {
        this.$refs.driveMarker.mapObject.setLatLng(
          L.latLng([this.drivePath[0].lat, this.drivePath[0].lng])
        );
        this.drivePath = this.drivePath.slice();
        return;
      }
      let point = this.drivePath.shift();
      this.driveLatLng = L.latLng([point.lat, point.lng]);
      this.driveRotationAngle = point.heading;
      console.log(this.driveRotationAngle);

      if (this.drivePath.length == 0 || !this.drivePath) {
        clearInterval(this.IntervalMoving);
        this.drivePath = null;
      }
    },

    triggerFullscreen() {
      if (this.$refs["map_thuraya_"].mapObject._isFullscreen != true) {
        this.$refs["map_thuraya_"].mapObject.scrollWheelZoom.disable();
      } else {
        this.$refs["map_thuraya_"].mapObject.scrollWheelZoom.enable();
      }
    },

    convertToCSV(objArray) {
      var array = typeof objArray != "object" ? JSON.parse(objArray) : objArray;
      var str = "";

      for (var i = 0; i < array.length; i++) {
        var line = "";
        for (var index in array[i]) {
          if (line != "") line += ",";

          line += array[i][index];
        }

        str += line + "\r\n";
      }

      return str;
    },

    download() {
      var itemsFormatted = [];
      var headers = {
        index: "#",
        latitude: "latitude", // remove commas to avoid errors
        longitude: "Longitude",
        dms: "DMS",
        altitude: "Altitude",
        speed: "Speed (Knots)",
        heading: "Heading (Degree)",
        distance: "Distance (Nmi)",
        dateTime: "dateTime (GMT+7)",
      };

      var itemsNotFormatted = this.showmaptracking_thur.dataTable;

      // format the data
      itemsNotFormatted.forEach((item) => {
        itemsFormatted.push({
          index: item.index + 1,
          latitude: item.lat, // remove commas to avoid errors
          longitude: item.lng,
          dms: item.dms,
          altitude: item.altitude,
          speed: item.speed,
          heading: item.heading,
          distance: item.distance,
          dateTime: item.dateTime_local,
        });
      });

      // or 'my-unique-title'
      var parts = this.expthuraya_title.split(" ").join('_');
      var rangedate = this.showmapthuraya.range.actual.split(' ').join('_')

      //console.log(parts);
      var fileTitle = parts + "_Tracking_Reports_" + rangedate;
      this.exportCSVFile(headers, itemsFormatted, fileTitle); // call the exportCSVFile() function to process the JSON and trigger the download
    },

    exportCSVFile(headers, items, fileTitle) {
      if (headers) {
        items.unshift(headers);
      }

      // Convert Object to JSON
      var jsonObject = JSON.stringify(items);

      var csv = this.convertToCSV(jsonObject);

      var exportedFilenmae = fileTitle + ".csv" || "export.csv";

      var blob = new Blob([csv], { type: "data:text/csv;charset=utf-8,%EF%BB%BF" });
      if (navigator.msSaveBlob) {
        // IE 10+
        navigator.msSaveBlob(blob, exportedFilenmae);
      } else {
        var link = document.createElement("a");
        if (link.download !== undefined) {
          // feature detection
          // Browsers that support HTML5 download attribute
          var url = URL.createObjectURL(blob);
          link.setAttribute("href", url);
          link.setAttribute("download", exportedFilenmae);
          link.style.visibility = "hidden";
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        }
      }
    },

    createCSV() {
      //console.log(this.showmaptable.vessel_pt);
      var parts = this.expthuraya_title.split(" ").join('_');
      var rangedate = this.showmapthuraya.range.actual.split(' ').join('_')

      //console.log(parts);

      let pdfName = parts + "_Tracking_Reports_" + rangedate;

      var tableTracking = this.showmaptracking_thur.dataTable;
      //console.log(tableTracking);

      this.download();
    },

    createPDF() {
      //console.log(this.showmaptable.vessel_pt);
      var parts = this.showmapthuraya.vessel_pt.split(" ");
      //console.log(parts);

      let pdfName = parts[0] + "_" + parts[1] + "_Tracking_Reports";
      var doc = new jsPDF({
        orientation: "l",
        unit: "mm",
        format: "a4",
        putOnlyUsedFonts: true,
      });

      doc.setProperties({
        title: pdfName,
        subject: "Its a Subject",
        author: "Me!",
        creator: "My Company Name",
      });

      var result = this.showmaptracking_thur.dataTable;
      //console.log(result);
      //console.log(this.tableTracking);

      let info = [];
      result.forEach((element) => {
        info.push([
          [element.index + 1],
          [element.lat, element.lng, `(` + element.dms + `)`],
          [element.dms],
          [element.altitude],
          [element.speed],
          [element.heading],
          [element.distance],
          [element.dateTime],
          [element.dateTime_local],
        ]);
      });

      //console.log(info);
      doc.text(this.showmapthuraya.vessel_pt, 15, 5);
      doc.text("Vessel Tracking Report", 15, 10, { fontSize: 9 });

      autoTable(doc, {
        head: [
          [
            "#",
            "latitude, longitude",
            "DMS",
            "Altitude",
            "Speed (Knots)",
            "Heading (Degree)",
            "Distance (Nmi)",
            "dateTime (UTC)",
            "dateTime (GMT+7)",
          ],
        ],
        body: info,
        theme: "striped",
      });

      doc.save(pdfName + ".pdf");
      // doc.output("dataurlnewwindow");
    },

    removeWeather(dateTime) {
      this.$delete(this.showclick_dateTime, dateTime);
      this.$delete(this.weatherData, dateTime);
    },

    closeShowMapThuraya() {
      clearInterval(this.IntervalMoving);
      this.removeShowThuraya();
      $("html, body").animate(
        {
          scrollTop: $("#top_mythuraya").offset().top - 140,
        },
        800
      );
    },

    centertoMap_() {
      console.log("centertomap_");
      $("html, body").animate(
        {
          scrollTop: $("#header_vessel_thuraya").offset().top,
        },
        1000
      );
    },

    triggerPopup(coord) {
      console.log(coord);
      $("html, body").animate(
        {
          scrollTop: $("#title_map_thuraya").offset().top - 109,
        },
        800
      );
      var myMap = this.$refs['map_thuraya_'].mapObject;
      var popLocation = new L.LatLng(coord.lat, coord.lng);
      myMap.setView(popLocation, 6);
      var popup = L.popup()
        .setLatLng(popLocation)
        .setContent(
          "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
          coord.lat +
          "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
          coord.lng +
          "</td></tr><tr>" +
          "<td><b>DMS</b></td><td>:</td>" +
          "<td>" +
          coord.dms +
          "</td>" +
          "</tr>" +
          "<tr>" +
          "<td><b>Speed</b></td>" +
          "<td>:</td>" +
          " <td>" +
          coord.speed +
          "&nbsp;knots </td>" +
          "</tr>" +
          "<tr>" +
          " <td><b>Heading</b></td>" +
          "<td>:</td>" +
          "<td>" +
          coord.heading +
          "</td>" +
          " </tr>" +
          "<tr>" +
          "<td><b>Last Update</b></td>" +
          "<td>:</td>" +
          "<td>" +
          coord.dateTime +
          "</td>" +
          "</tr>" +
          "</table>"
        )
        .openOn(myMap);
    },

    draw_Circle() {
      var myMap = this.$refs['map_thuraya_'].mapObject;
      var mama = this;
      console.log("MAP OBJEK");
      console.log(myMap);
      var latLong = this.showmaptracking_thur.tracking;
      var pointList = this.showmaptracking_thur.tracking;

      const lightbox = new PhotoSwipeLightbox({
        gallery: '#thuraya-gallery-container',
        children: 'a',
        pswpModule: () => import('photoswipe')
      });
      lightbox.init();

      var firstpolyline = new L.Polyline(pointList, {
        color: "#FEE800",
        weight: 3,
        opacity: 1,
        smoothFactor: 1,
      });
      firstpolyline.addTo(myMap);
      // Here's where you iterate over the array of coordinate objects.
      latLong.forEach(function (coord) {
        if (
          coord.lat != mama.showmaptracking_thur.maptop.lat &&
          coord.lng != mama.showmaptracking_thur.maptop.lon
        ) {
          var circle = L.circle(coord, {
            color: "#1DC01D",
            fillColor: "#1DC01D",
            fillOpacity: 0.5,
            radius: 1100,
          })
            .addTo(myMap)
            .bindPopup(
              "<table><tr><td><b>Latitude</b></td><td>:</td><td>" +
              coord.lat +
              "</td></tr><tr><td><b>Longitude</b></td><td>:</td><td>" +
              coord.lng +
              "}}</td></tr><tr>" +
              "<td><b>DMS</b></td><td>:</td>" +
              "<td>" +
              coord.dms +
              "</td>" +
              "</tr>" +
              "<tr>" +
              "<td><b>Speed</b></td>" +
              "<td>:</td>" +
              " <td>" +
              coord.speed +
              "&nbsp;knots </td>" +
              "</tr>" +
              "<tr>" +
              " <td><b>Heading</b></td>" +
              "<td>:</td>" +
              "<td>" +
              coord.heading +
              "</td>" +
              " </tr>" +
              "<tr>" +
              "<td><b>Last Update</b></td>" +
              "<td>:</td>" +
              "<td>" +
              coord.updated +
              "</td>" +
              "</tr>" +
              "</table>"
            );
        }
      });
      var mom = this;
      this.newAnimation();
      setTimeout(function () {
        mom.movingStart();
      }, 1000);
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    async weatherClick(id, msgid, type, dateTime) {
      // this.$delete(this.weatherData, dateTime);
      console.log(this.weatherData);
      this.$set(this.showclick_dateTime, dateTime, dateTime);
      const datawth = {
        id: id,
        msg_id: msgid,
        type: type,
      };
      const response = await axios.post(
        "https://navigatorplus.sailink.id/api/v1/navplus/weather/fetchRow",
        datawth,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      console.log(response);
      this.$set(this.weatherData, dateTime, response.data.data);
      console.log("weather datanya i fetch baru");
      console.log(this.weatherData);
    },

    toDegreesMinutesAndSeconds(coordinate, pad) {
      var absolute = Math.abs(coordinate);
      var degrees = Math.floor(absolute);
      var minutesNotTruncated = (absolute - degrees) * 60;
      var minutes = Math.floor(minutesNotTruncated);
      var seconds = Math.floor((minutesNotTruncated - minutes) * 60);
      //eslint-disable-next-line
      return (
        String(degrees).padStart(pad, "0") +
        "°" +
        String(minutes).padStart(2, "0") +
        " ' " +
        String(seconds).padStart(2, "0") +
        '"'
      );
    },

    customRange() {

      var tglcurrent = new Date();
      var tgldate1 = new Date(this.dates[0] + ' ' + this.times[0] + ':00');
      var tgldate2 = new Date(this.dates[1] + ' ' + this.times[1] + ':00');



      if (tgldate1.getTime() <= tglcurrent.getTime() && tgldate2.getTime() <= tglcurrent.getTime()) {
        if (tgldate1 > tgldate2) {
          this.cant_backwards = true
          this.resetTimesDates();
          setTimeout(() => {
            this.cant_backwards = false
          }, 5000)
        } else {
          this.dateopen = false;
          this.cant_future = false
          // var range_date = this.dates.join(" ");
          var range1 = this.dates[0].split('-');
          var range1_ = range1.join('_');

          var range2 = this.dates[1].split('-');
          var range2_ = range2.join('_');

          var timerange1 = this.times[0].split(':')
          var timerange1_ = timerange1.join('m')

          var timerange2 = this.times[1].split(':')
          var timerange2_ = timerange2.join('m')

          var range_date = range1_ + ' ' + timerange1_ + ' - ' + range2_ + ' ' + timerange2_;
          console.log(range_date);
          this.resetTimesDates();
          this.showondate(this.datepickerID, range_date);
        }

      } else {
        this.cant_future = true
        this.resetTimesDates();
        setTimeout(() => {
          this.cant_future = false
        }, 5000)
      }

    },

    closeCostumRange() {
      this.dateopen = false
      this.resetTimesDates();
    },

    resetTimesDates() {
      console.log('di reset')
      this.dates = []
      this.times = []
    },


    logtest(date) {
      console.log('DI CLICK')
      console.log(date)
      this.testdisabled = true;
      this.timeopen = true;
    },

    saveTimes() {
      this.times.push(this.timein);
      this.timein = null;
      this.timeopen = false;
      this.testdisabled = false;
    },

    convertDMS(lat, lng) {
      var latitude = this.toDegreesMinutesAndSeconds(lat, 2);
      var latitudeCardinal = lat >= 0 ? "N" : "S";

      var longitude = this.toDegreesMinutesAndSeconds(lng, 3);
      var longitudeCardinal = lng >= 0 ? "E" : "W";

      return (
        latitude +
        " " +
        latitudeCardinal +
        "-" +
        longitude +
        " " +
        longitudeCardinal
      );
    },

    rpmReport(rpm) {
      console.log("RPM LAPORAN");
      console.log(rpm);
      console.log(rpm.id);
      console.log(this.nameThuraya);
      this.inputRPMID(rpm.id);
      this.inputSearchIOT(this.nameThuraya);
      this.$router.push("/sailogger");
    },

    openETA() {
      this.etaControl = !this.etaControl;
      console.log(this.$refs["map_thuraya_"]);
      let now = new Date();
      now.setMinutes(now.getMinutes() - now.getTimezoneOffset());
      $("#departure-sailink").val(now.toISOString().slice(0, 16));
      if (this.$refs["map_thuraya_"].mapObject._isFullscreen != true) {
        this.$refs["map_thuraya_"].mapObject.toggleFullscreen();
      } else {
        console.log("ETA ALREADY OPEN");
      }
    },

    startDraw() {
      this.onDraw = !this.onDraw;
      if (this.onDraw == true && this.showRouteData != null) {
        this.clearMeasure();
      }
      this.$refs["polylineMeasure"].mapObject._toggleMeasure();
      console.log(this.$refs["polylineMeasure"].mapObject._arrPolylines);
    },

    clearMeasure() {
      this.$refs["polylineMeasure"].mapObject._clearAllMeasurements();
      this.etaMiles = 0;
      this.etaDuration = 0;
      this.etaArrival = "";
      this.showRouteData = null;
    },

    closeETA() {
      this.etaControl = false;
      this.clearMeasure();
      this.totalDistance = 0;
      this.showonMarker = false;
      this.showRoute = false;
      if (this.$refs["map_thuraya_"].mapObject._isFullscreen != true) {
      } else {
        this.$refs["map_thuraya_"].mapObject.toggleFullscreen();
      }
    },

    clearMeasure() {
      console.log('SELESAI');
      this.$refs["polylineMeasure"].mapObject._clearAllMeasurements();
      this.$refs["polylineMeasure"].mapObject._toggleMeasure();
      this.etaMiles = 0;
      this.etaDuration = 0;
      this.etaArrival = "";
      this.showRouteData = null;
    },

    handleToggle(toogle) {
      console.log("ke tugel");
      this.onDraw = toogle.sttus
      console.log(toogle.sttus)

    },

    handleFinish() {
      console.log("ITS FINISH");
      this.onCount();
      this.onFinish = true;
    },

    //Start Draw [ETA]
    startDraw() {

      if (this.onDraw == true && this.showRouteData != null) {
        this.clearMeasure();
      }
      this.$refs["polylineMeasure"].mapObject._toggleMeasure();
      if (this.showonMarker) {
        var latLng = L.latLng([
          parseFloat(this.showmaptracking_thur.maptop.lat),
          parseFloat(this.showmaptracking_thur.maptop.lon)
        ]);

        this.$refs["polylineMeasure"].mapObject._startLine(latLng);
        this.$refs["polylineMeasure"].mapObject._mouseMove({ latLng });
        this.$refs["polylineMeasure"].mapObject._currentLine.addPoint(latLng);
      }
    },

    onCount_move() {
      console.log('HITUNG ROUTE');
      console.log(this.currentLine)
      console.log(this.$refs["polylineMeasure"].mapObject._arrPolylines[0].circleCoords)
      this.currentLine = this.$refs["polylineMeasure"].mapObject._arrPolylines;
      this.coords = this.$refs["polylineMeasure"].mapObject._arrPolylines[0].circleCoords
      this.toolTipsArr = this.$refs["polylineMeasure"].mapObject._arrPolylines[0].tooltips;
      this.showRouteData = this.coords.map((item, index) =>
        Object.assign(item, { index })
      );
      this.brRouteData = this.toolTipsArr;
      var miles = 0;
      this.coords.forEach((coor, i) => {
        this.totalDistance = this.toolTipsArr[i]._icon.childNodes[4].innerText;
        miles = parseFloat(this.toolTipsArr[i]._icon.childNodes[4].innerText);
      });

      this.etaMiles = miles;
      var duration_raw = miles / this.etaSpeed;
      var duration = duration_raw.toFixed(2);
      var departDateInput = $("#departure-sailink").val();
      var today = new Date(departDateInput);
      var seconds = Math.round(duration * 60 * 60);
      today.setSeconds(today.getSeconds() + seconds);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      var h = today.getHours();
      var i = today.getMinutes();

      if (h < 10) {
        h = "0" + h;
      }

      if (i < 10) {
        i = "0" + i;
      }

      today = dd + "/" + mm + "/" + yyyy + " " + h + ":" + i;
      this.etaDuration = duration;
      this.etaArrival = today;
    },

    onCount() {
      console.log('HITUNG ROUTE');
      console.log(this.currentLine);
      this.currentLine = this.$refs["polylineMeasure"].mapObject._currentLine;
      this.coords = this.$refs["polylineMeasure"].mapObject._currentLine.circleCoords;
      this.toolTipsArr = this.$refs["polylineMeasure"].mapObject._currentLine.tooltips;
      this.showRouteData = this.coords.map((item, index) =>
        Object.assign(item, { index })
      );
      this.brRouteData = this.toolTipsArr;
      var miles = 0;
      this.coords.forEach((coor, i) => {
        this.totalDistance = this.toolTipsArr[i]._icon.childNodes[4].innerText;
        miles = parseFloat(this.toolTipsArr[i]._icon.childNodes[4].innerText);
      });

      this.etaMiles = miles;
      var duration_raw = miles / this.etaSpeed;
      var duration = duration_raw.toFixed(2);

      var departDateInput = $("#departure-sailink").val();
      var today = new Date(departDateInput);
      var seconds = Math.round(duration * 60 * 60);
      today.setSeconds(today.getSeconds() + seconds);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      var h = today.getHours();
      var i = today.getMinutes();

      if (h < 10) {
        h = "0" + h;
      }

      if (i < 10) {
        i = "0" + i;
      }

      today = dd + "/" + mm + "/" + yyyy + " " + h + ":" + i;
      this.etaDuration = duration;
      this.etaArrival = today;
    },

    onCountTotal() {
      console.log('HITUNG ROUTE');
      console.log(this.currentLine);
      this.showRouteData = this.coords.map((item, index) =>
        Object.assign(item, { index })
      );
      this.brRouteData = this.toolTipsArr;
      var miles = 0;
      this.coords.forEach((coor, i) => {
        this.totalDistance = this.toolTipsArr[i]._icon.childNodes[4].innerText;
        miles = parseFloat(this.toolTipsArr[i]._icon.childNodes[4].innerText);
      });

      this.etaMiles = miles;
      var duration_raw = miles / this.etaSpeed;
      var duration = duration_raw.toFixed(2);
      var departDateInput = $("#departure-sailink").val();
      var today = new Date(departDateInput);
      var seconds = Math.round(duration * 60 * 60);
      today.setSeconds(today.getSeconds() + seconds);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      var h = today.getHours();
      var i = today.getMinutes();

      if (h < 10) {
        h = "0" + h;
      }

      if (i < 10) {
        i = "0" + i;
      }

      today = dd + "/" + mm + "/" + yyyy + " " + h + ":" + i;
      this.etaDuration = duration;
      this.etaArrival = today;
    },

    countETA() {
      var duration_raw = this.etaMiles / this.etaSpeed;
      var duration = duration_raw.toFixed(2);
      var departDateInput = $("#departure-sailink").val();
      var today = new Date(departDateInput);
      var seconds = Math.round(duration * 60 * 60);
      today.setSeconds(today.getSeconds() + seconds);
      var dd = String(today.getDate()).padStart(2, "0");
      var mm = String(today.getMonth() + 1).padStart(2, "0");
      var yyyy = today.getFullYear();
      var h = today.getHours();
      var i = today.getMinutes();

      if (h < 10) {
        h = "0" + h;
      }

      if (i < 10) {
        i = "0" + i;
      }

      today = dd + "/" + mm + "/" + yyyy + " " + h + ":" + i;
      //console.log("arrival date " + today);
      this.etaDuration = duration;
      this.etaArrival = today;
    },

    async showweather(vid, msgid, type) {
      this.msg_id_after = msgid;
      this.weather_data = null;
      const data = {
        id: vid,
        msg_id: msgid,
        type: type,
      };
      const response = await axios.post("v1/navplus/weather/fetchRow", data, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      });
      console.log(response);
      this.weather_data = response.data.data;
    },

    clearInterval_showmap() {
      console.log("INTERVAL CLEANED");
      clearInterval(this.IntervalMoving);
    },

    showondate(item, range) {
      clearInterval(this.IntervalMoving);
      this.exp_range = range;
      this.countLength = 0;
      console.log("JALAN SAMPE DATE DI SHOW MAP");
      const data = {
        id: item,
        range: range,
        token: localStorage.getItem("token"),
      };

      this.showdateThuraya(data);
    },
  },

  computed: {
    ...mapGetters([
      "expthuraya_title",
      "user_allowed",
      "searchIDThuraya",
      "thuraya_executed",
      "showmapthuraya",
      "showmaptracking_thur",
      "showthurayaclick",
      "thurayadataTable",
      "vesselUsersThuraya",
      "nameThuraya",
      "clickdate",
    ]),
    mapConfig() {
      return {
        ...mapSettingsShow,
        center: this.mapCenter,
      };
    },

    mapCenter() {
      return {
        lat: parseFloat(this.showmapthuraya.maptop.lat),
        lng: parseFloat(this.showmapthuraya.maptop.lon),
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/css/showmapThuraya_.scss";
</style>
