<template>
  <div v-if="editapi">
    <v-dialog max-width="600" v-model="editapi" class="elevation-0 rounded-0" persistent>
      <v-card class="elevation-0 rounded-0" ref="form">
        <v-toolbar class="elevation-0 rounded-0" elevation="0" color="#f7f7f7" dense>
          <v-toolbar-title v-if="editprofdata.sameUser != true">Edit API access for {{ editprofdata.user.name
          }}</v-toolbar-title>
          <v-toolbar-title v-if="editprofdata.sameUser == true">Edit your API access
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-container style="margin-top:0" v-if="!editapidata" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col><img alt="Navplus logo" width="70px" :src="loadernavplus" class="justify-center" /></v-col>
          </v-row>
        </v-container>
        <v-card v-if="editapidata" class="elevation-0 rounded-0 pa-7 pb-0 mt-0">

          <v-row no-gutters>
            <v-col cols="12" sm="3">
              <v-subheader class="pl-0" style="text-align: left;">API Passphrase / Key</v-subheader>
            </v-col>
            <v-col cols="12" sm="9">
              <v-row no-gutters>
                <v-col cols="12" sm="8">
                  <div>
                    <v-text-field :value="!keyshow ? null : editapidata.passHash" outlined class="elevation-0 rounded-0"
                      readonly :append-icon="keyshow ? 'mdi-eye' : 'mdi-eye-off'"
                      @click:append="keyshow = !keyshow"></v-text-field>
                  </div>


                </v-col>


                <v-col cols="12" sm="4">
                  <v-switch @change="checkdata('isEnabled')" inset style="margin-top: -0.2em;"
                    v-model="editapidata.isEnabled" :label="editapidata.isEnabled ? 'Enabled' : 'Disabled'"></v-switch>
                </v-col>
              </v-row>
            </v-col>

          </v-row>
          <v-row no-gutters>
            <v-col cols="12" sm="3">
              <v-subheader class="pl-0" style="text-align: left;">Allowed IP</v-subheader>
            </v-col>
            <v-col cols="12" sm="9">
              <v-row no-gutters>
                <v-col cols="12">
                  <div>
                    <v-textarea @input="checkdata('ip_whitelist')" :value="editapidata.ip_whitelist"
                      v-model="editapidata.ip_whitelist" class="elevation-0 rounded-0" outlined></v-textarea>
                    <div class="hint_">
                      Separated by [ ; ] E.g. 127.0.0.1;10.x.x.x;
                    </div>
                  </div>

                </v-col>
              </v-row>


            </v-col>
          </v-row>
        </v-card>

        <!-- <v-card-text
         
          style="margin-top: 55px; margin-bottom: -50px"
        >
          <div
            class="div"
            style="display: flex; justify-content: space-between"
          >
            <div style="width: 70%">
             
            </div>
            <div style="width: 20%">
              
            </div>
          </div>

       

          <v-divider class="mt-12"></v-divider>
        </v-card-text> -->
        <v-card-actions v-if="editapidata" style="margin-top: 2em">
          <v-btn small :disabled="changes_ ? false : true" class="elevation-0 rounded-0" v-if="editapidata"
            color="primary" @click="
              editapidata.isEnable == '' || editapidata.ip_whitelist == ''
                ? (eapi_empty = true)
                : editapidata.isEnable != dataapifetch.isEnable ||
                  editapidata.ip_whitelist != dataapifetch.ip_whitelist
                  ? (eapi_confirm = true)
                  : (eapi_nochange = true)
              " style="width: 49%">
            Update Data
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn small class="elevation-0 rounded-0" color="red" @click="removeEditApi(), (keyshow = null)"
            style="width: 49%" dark>
            cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>







    <v-dialog v-model="eapi_confirm" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text style="text-align: left">Api access data will updated after you click yes</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="eapi_confirm = false">
            No
          </v-btn>
          <v-btn color="green darken-1" text @click="
            PostApiData(), keyshow = null, (dialog = true), (eapi_confirm = false),
            removeEditApi(), (keyshow = null)
            ">
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="eapi_nochange" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> No Changes! </v-card-title>
        <v-card-text style="text-align: left">You did not change anything, wanna continue edit or
          leave?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="eapi_nochange = false">
            continue
          </v-btn>
          <v-btn color="green darken-1" text @click="removeEditApi(), (keyshow = null), (eapi_nochange = false)">
            leave
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="eapi_empty" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Empty Input! </v-card-title>
        <v-card-text style="text-align: left">You sending empty field, please write something or
          leave?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="eapi_empty = false">
            continue
          </v-btn>
          <v-btn color="green darken-1" text @click="removeEditApi(), (keyshow = null), (eapi_empty = false)">
            leave
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="#4CAF50" style="padding: 1em" dark>
        <v-alert dense prominent type="success" style="margin-top: 1em">API data has change</v-alert>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
//   import axios from "axios";
import { mapGetters, mapActions } from "vuex";
import axios from "../../plugins/axios";
export default {
  data: () => ({
    change: false,
    loadernavplus: require("@/assets/img/load100new.gif"),
    dialog: false,
    keyshow: null,
    user: null,
    userpass: null,
    eapi_confirm: null,
    eapi_nochange: null,
    eapi_empty: null,
    editapidata_local: null,
    editapidata_static: null
  }),

  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 1000);
    },
  },

  mounted() {
    this.fetchApiDataStatic()
  },

  methods: {
    ...mapActions(["fetchApiData", "removeEditApi", "postApiData"]),



    checkdata(value) {
      console.log(this.editapidata[value])
      console.log(this.editapidata_static[value])
      if (this.editapidata[value] == this.editapidata_static[value]) {
        this.change = false
      } else {
        this.change = true
      }
    },

    async fetchApiDataStatic() {

      const response = await axios.get("/api/v1/navplus/user/apiAccess/" + this.user_allowed.id, {
        headers: {
          Authorization: "Bearer" + localStorage.getItem("token"),
        },
      });
      console.log('API ACCESS FETCH')
      console.log(response)
      this.editapidata_static = response.data.data


    },

    PostApiData() {
      const data = {
        id: this.editprofdata.user.id,
        ip_whitelist: this.editapidata.ip_whitelist,
        isEnabled: this.editapidata.isEnabled,
        token: localStorage.getItem("token")
      }
      this.postApiData(data);
    },

    inputLocal() {
      this.editapidata_local = this.editapidata
    }
  },

  computed: {
    ...mapGetters(["editapidata", "editapi", "editprofdata", "dataapifetch", "user_allowed"]),

    changes_() {
      return this.change;
    },
  },
};
</script>

<style lang="scss" scoped>
.hint_ {
  font-size: 0.8em;
  text-align: left;
  margin-top: -3em;
  padding: 1em;
  padding-left: 0em;
  padding-bottom: 2em;
}
</style>
