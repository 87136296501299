<template>
  <div>
    <!--Modal CDR-->
    <v-dialog
      v-model="cdrOpen"
      width="90%"
      class="elevation-0 rounded-0"
      persistent
    >
      <v-card class="elevation-0 rounded-0">
        <v-toolbar color="#f7f7f7" class="elevation-0 rounded-0" dense>
          <v-col class="header_modal">
            <v-row><v-toolbar-title>Call Details</v-toolbar-title></v-row>
          </v-col>

          <v-spacer></v-spacer>

          <v-btn small class="elevation-0 rounded-0" icon @click="closeCDR()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-toolbar v-if="cdrData" class="elevation-0 rounded-0" dense>
          <v-toolbar-subtitle>{{ cdrData.vessel_number }}</v-toolbar-subtitle>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider v-if="cdrData"></v-divider>

        <v-container style="margin-top: 0" v-if="!cdrData" fill-height fluid>
          <v-row align="center" justify="center">
            <v-col
              ><img
                alt="Navplus logo"
                width="70px"
                :src="loadernavplus"
                class="justify-center"
            /></v-col>
          </v-row>
        </v-container>
        <v-card-text>
          <v-row v-if="cdrData" class="mt-4">
            <v-col cols="4">
              <v-card
                class="elevation-0 rounded-0 pa-2"
                style="border: solid 1px #e0e0e0; text-align: left"
              >
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Type Terminal</td>
                        <td>: Thuraya Marine Star</td>
                      </tr>
                      <tr>
                        <td>Thuraya Terminal</td>
                        <td>: +{{ cdrData.number }}</td>
                      </tr>
                      <tr>
                        <td>Service Plan</td>
                        <td>: {{ cdrData.paket }}</td>
                      </tr>
                      <tr>
                        <td>Sim Number</td>
                        <td>: {{ cdrData.sim_no }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card
                class="elevation-0 rounded-0 pa-2"
                style="border: solid 1px #e0e0e0; text-align: left"
              >
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Allowance</td>
                        <td>: {{ cdrData.stat.allowance }}</td>
                      </tr>
                      <tr>
                        <td>Used Balance</td>
                        <td>
                          <p style="margin-top: 20px">:</p>
                          <v-col style="margin-top: -50px; margin-bottom: 5px"
                            >{{ cdrData.stat.used }} unit ({{
                              cdrData.stat.percentage
                            }}%)<v-progress-linear
                              :value="cdrData.stat.used"
                            ></v-progress-linear
                          ></v-col>
                        </td>
                      </tr>
                      <tr>
                        <td>Remaining Balance</td>
                        <td>: {{ cdrData.stat.balance }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="4">
              <v-card
                class="elevation-0 rounded-0 pa-2"
                style="border: solid 1px #e0e0e0; text-align: left"
              >
                <v-simple-table dense>
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td>Tracking Allowance</td>
                        <td>: {{ cdrData.stat_track.allowance }}</td>
                      </tr>
                      <tr>
                        <td>Used Balance</td>
                        <td>
                          <p style="margin-top: 20px">:</p>
                          <v-col style="margin-top: -50px; margin-bottom: 5px"
                            >{{ cdrData.stat_track.used }} unit ({{
                              cdrData.stat_track.percentage
                            }}%)<v-progress-linear
                              value="15"
                            ></v-progress-linear
                          ></v-col>
                        </td>
                      </tr>
                      <tr>
                        <td>Remaining Balance</td>
                        <td>: {{ cdrData.stat_track.balance }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="!cdrChart_">
              <v-card
                class="elevation-0 rounded-0 pa-10"
                style="
                  border: solid 1px #e0e0e0;
                  overflow-wrap: break-word;
                  text-align: left;
                "
              >
                <img
                  style="margin-left: 50%"
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
                />
              </v-card>
            </v-col>
            <v-col cols="12" v-if="cdrChart_">
              <v-card
                class="elevation-0 rounded-0"
                style="
                  border: solid 1px #e0e0e0;
                  overflow-wrap: break-word;
                  text-align: left;
                "
              >
                <v-toolbar class="elevation-0 rounded-0" dense>
                  <v-menu content-class="elevation-0 rounded-0" offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        style="border: solid 1px #e0e0e0"
                        small
                        class="elevation-0 rounded-0"
                        color="#ffff"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <i class="bx bx-time"></i>
                        &nbsp; &nbsp;
                        <v-text v-if="cdrData">{{
                          cdrData.range.label
                        }}</v-text>
                        <v-card-text v-if="!cdrData">
                          <div class="snippet" data-title=".dot-pulse">
                            <div class="stage">
                              <div class="dot-pulse"></div>
                            </div>
                          </div>
                        </v-card-text>
                        &nbsp; &nbsp;
                        <i class="bx bxs-chevron-down"></i>
                      </v-btn>
                    </template>
                    <v-list  style="border: solid 1px #e0e0e0">
                      <v-list-item>
                        <v-btn
                          block
                          plain
                          small
                          @click="showCDR('Today', 'today', cdrID)"
                          >Today</v-btn
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-btn
                          block
                          plain
                          small
                          @click="showCDR('This Week', 'week', cdrID)"
                          >This Week</v-btn
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-btn
                          block
                          plain
                          small
                          @click="showCDR('This Month', 'month', cdrID)"
                          >This Month</v-btn
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-btn
                          block
                          plain
                          small
                          @click="showCDR('Last Month', 'lmonth', cdrID)"
                          >Last Month</v-btn
                        >
                      </v-list-item>
                      <v-list-item>
                        <v-btn
                          block
                          plain
                          small
                          @click="(dateopen = true), (datepickerID = cdrID)"
                          >Custom Range</v-btn
                        >
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
                <v-divider></v-divider>
                <v-card
                  class="pa-4 elevation-0 rounded-0 ma-4"
                  style="border: solid 1px #e0e0e0"
                >
                  <v-lazy>
                    <client-only>
                      <CdrChart
                        class="chartnya"
                        v-if="cdrChart_"
                        v-bind:cdrChart="cdrChart_"
                      />
                    </client-only>
                  </v-lazy>
                </v-card>
              </v-card>
            </v-col>
            <v-col cols="12" v-if="!cdrOutput_">
              <v-card
                class="elevation-0 rounded-0 pa-10"
                style="
                  border: solid 1px #e0e0e0;
                  overflow-wrap: break-word;
                  text-align: left;
                "
              >
                <img
                  style="margin-left: 50%"
                  alt="Navplus logo"
                  width="70px"
                  :src="loadernavplus"
                  class="justify-center"
                />
              </v-card>
            </v-col>
            <v-col cols="12" v-if="cdrOutput_">
              <v-card
                class="elevation-0 rounded-0"
                style="border: solid 1px #e0e0e0; text-align: left"
              >
                <v-toolbar class="elevation-0" color="#F7F7F7" dense>
                  <v-btn
                    color="primary"
                    class="elevation-0 rounded-0 csvsailink_desktop"
                    @click="createCSV()"
                    small
                    ><v-icon x-small>mdi-file-move</v-icon> &nbsp;Export
                    CSV</v-btn
                  >
                  <v-btn
                    :href="
                      'https://sailink.id/cetak/cdr/pdf/' +
                      cdrData.number +
                      '/' +
                      exp_range +
                      '/' +
                      filterCDR
                    "
                    color="primary"
                    dark
                    small
                    style="color: #fff"
                    class="elevation-0 rounded-0 pt-1 ml-2 pdfsailink_desktop"
                    target="_blank"
                  >
                    <v-icon x-small>mdi-file-move</v-icon> Export PDF
                  </v-btn>

                  <v-spacer style="width: 40%"></v-spacer>

                  <v-btn
                    small
                    :color="searchCDR == '' ? 'primary' : '#fff'"
                    class="elevation-0 rounded-0"
                    style="border: solid 1px #e0e0e0"
                    @click="(searchCDR = ''), (filterCDR = 'all')"
                    >All</v-btn
                  >
                  <v-btn
                    small
                    :color="searchCDR == 'Telephony' ? 'primary' : '#fff'"
                    class="elevation-0 rounded-0 ml-1"
                    style="border: solid 1px #e0e0e0"
                    @click="
                      (searchCDR = 'Telephony'), (filterCDR = 'Telephony')
                    "
                    >Voice</v-btn
                  >
                  <v-btn
                    small
                    :color="searchCDR == 'SMS_CatchAll' ? 'primary' : '#fff'"
                    class="elevation-0 rounded-0 ml-1"
                    style="border: solid 1px #e0e0e0"
                    @click="
                      (searchCDR = 'SMS_CatchAll'), (filterCDR = 'SMS_CatchAll')
                    "
                    >SMS</v-btn
                  >
                  <v-btn
                    small
                    :color="
                      searchCDR == 'Tracking SMS 882161005' ? 'primary' : '#fff'
                    "
                    class="elevation-0 rounded-0 ml-1"
                    style="border: solid 1px #e0e0e0"
                    @click="
                      (searchCDR = 'Tracking SMS 882161005'),
                        (filterCDR = 'Tracking SMS 882161005')
                    "
                    >Tracking</v-btn
                  >
                </v-toolbar>
                <v-divider></v-divider>
                <v-toolbar class="elevation-0" color="#ffff" dense>
                  <v-menu offset-y content-class="elevation-0 rounded-0 pa-0">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        small
                        class="rounded-0 pa-1 elevation-0 ma-0"
                        style="border: solid 1px #e0e0e0"
                        color="#ffff"
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ kFormatter(itemsPerPageCDR)
                        }}<v-icon small style="margin-left: 0.2em"
                          >mdi-unfold-more-horizontal</v-icon
                        >
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="itemsPerPageCDR = 5"
                        ><v-list-item-title>5</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageCDR = 10"
                        ><v-list-item-title>10</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageCDR = 15"
                        ><v-list-item-title>15</v-list-item-title></v-list-item
                      >
                      <v-list-item @click="itemsPerPageCDR = cdrOutput_.length"
                        ><v-list-item-title>All</v-list-item-title></v-list-item
                      >
                    </v-list>
                  </v-menu>
                  <v-spacer style="width: 40%"></v-spacer>
                  <v-text-field
                    style="border: solid 1px #e0e0e0"
                    width="40"
                    v-model="searchCDR"
                    hide-details
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Search"
                    solo
                    clearable
                    class="elevation-0 rounded-0 ml-2"
                  ></v-text-field>
                </v-toolbar>
                <v-divider></v-divider>
                <v-data-table
                  v-if="cdrData"
                  :search="searchCDR"
                  :headers="headerscdr"
                  :items="cdrOutput_"
                  :items-per-page="itemsPerPageCDR"
                  hide-default-footer
                  :page.sync="pageCDR"
                  @page-count="pageCountCDR = $event"
                  @pagination="yourMethod"
                  class="elevation-0 dataTable mt-0"
                >
                  <template v-slot:item="row">
                    <tr>
                      <!-- <td>{{row.index}}</td> -->
                      <td>{{ row.item.Services }}</td>
                      <td>{{ row.item.MSISDN }}</td>
                      <td>{{ row.item.Type }}</td>
                      <td>{{ row.item.Plcode }}</td>
                      <td>{{ row.item.Network }}</td>
                      <td>{{ row.item.Start_Date }}</td>
                      <td>{{ row.item.Called_Party }}</td>
                      <td>{{ row.item.Duration }}</td>
                      <td>{{ row.item.Band }}</td>
                      <td>{{ row.item.Amount }}</td>
                      <td>{{ row.item.dateTime_local }}</td>
                    </tr>
                  </template>
                </v-data-table>
                <v-toolbar dense class="elevation-0 rounded-0" color="#F7F7F7">
                  <p class="mt-3" style="font-size: 0.8em">
                    Showing {{ showingDatafrom }} to {{ showingDatato }} of
                    {{ totalFilter }} entries
                  </p>
                  <v-spacer></v-spacer>
                  <v-pagination
                    class="pt-2 pb-2"
                    color="primary"
                    v-model="pageCDR"
                    :length="pageCountCDR"
                    :total-visible="7"
                  ></v-pagination>
                </v-toolbar>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--Modal CDR End-->

    <!--Modal Custom Range-->
    <!--Modal Custom Range-->
    <v-dialog
      ref="dialog"
      v-model="dateopen"
      :return-value.sync="dates"
      class="elevation-0 rounded-0"
      persistent
      width="290px"
    >
      <v-date-picker
        color="primary"
        class="elevation-0 rounded-0"
        v-model="dates"
        range
        scrollable
      >
        <v-spacer></v-spacer>
        <v-btn text color="primary" @click="dateopen = false"> Cancel </v-btn>
        <v-btn text color="primary" @click="customRangeCDR()"> OK </v-btn>
      </v-date-picker>
    </v-dialog>

    <!-- <v-dialog v-model="dateopen" width="25%">
    <v-card color="#1976D2">
      <v-toolbar color="#1976D2" dark>
        <v-toolbar-title>Custom Range</v-toolbar-title>
        <v-spacer></v-spacer
        ><v-btn
          class="mx-2"
          fab
          small
          dark
          color="#0B4281"
          @click="dateopen = false"
        >
          <v-icon dark> mdi-close </v-icon>
        </v-btn></v-toolbar
      >
      <div style="display: block">
        <v-date-picker v-model="dates" range full-width></v-date-picker>

        <v-btn
          @click="customRange(), (dateopen = false), (cdrData = null)"
          style="margin-top: -35px"
          color="primary"
          block
          >Add Range</v-btn
        >
      </div>
    </v-card>
  </v-dialog> -->
    <!--Modal Custom Range-->
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import CdrChart from "@/components/charts/CdrChart.vue";
export default {
  components: {
    CdrChart,
  },
  data() {
    return {
      loadernavplus: require("@/assets/img/load100new.gif"),
      exp_range: "month",
      totalFilter: null,
      itemsPerPageCDR: 10,
      pageCDR: 1,
      pageCountCDR: 0,
      searchCDR: "",
      filterCDR: "all",
      loadernavplus: require("@/assets/img/load100new.gif"),
      fetchInterval: null,
      dates: [],
      datepickerID: null,
      dateopen: null,
      headerscdr: [
        { text: "Services", value: "Services" },
        { text: "MSISDN", value: "MSISDN" },
        { text: "Type", value: "Type" },
        { text: "Plcode", value: "Plcode" },
        { text: "Network", value: "Network" },
        { text: "Start Date", value: "Start_Date" },
        { text: "Called Party", value: "Called_Party" },
        { text: "Duration", value: "Duration" },
        { text: "Band", value: "Band" },
        { text: "Amount", value: "Amount" },
        { text: "DateTime Local", value: "dateTime_local" },
      ],
    };
  },

  methods: {
    ...mapActions(["cdrFetch", "cdrRemove", "cdrDate"]),

    customRangeCDR() {
      this.dateopen = false;
      var range_date = this.dates.join(" ");
      this.showCDR(range_date, range_date, this.cdrID);
    },


    createCSV() {
   
      // var parts = this.showmaptable.vessel_pt.split(" ");
      // let pdfName = parts[0] + "_" + parts[1] + "_Tracking_Reports";
      let pdfName = this.cdrData.vessel_number
     
      var cdrOutput = this.cdrOutput_
      //console.log(cdrOutput);

      let data = [];
      let header = [
        ["services"],
        ["MSISDN"],
        ["Type"],
        ["Plcode"],
        ["Network"],
        ["Start_Date"],
        ["Called_Party"],
        ["Duration"],
        ["Band"],
        ["Amount"],
        ["DateTime Local"],
      ];

      cdrOutput.forEach((element) => {
        data.push([
          [element.Services],
          [element.MSISDN],
          [element.Type],
          [element.Plcode],
          [element.Network],
          [element.Start_Date],
          [element.Called_Party],
          [element.Duration],
          [element.Band],
          [element.Amount],
          [element.dateTime_local],
        ]);
      });

      let csvContent = data.map((e) => e.join(",")).join("\n");

      // var encodedUri = encodeURI(csvContent);
      var link = document.createElement("a");
      link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent));
      link.setAttribute("download", pdfName + ".csv");
      document.body.appendChild(link); // Required for FF
      link.click();
    },

    kFormatter(num) {
      return Math.abs(num) > 999
        ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "k"
        : Math.sign(num) * Math.abs(num);
    },

    yourMethod(pagination) {
      this.totalFilter = pagination.itemsLength;
    },

    showCDR(label, range, id) {
      var data = {
        id: id,
        label: label,
        token: localStorage.getItem("token"),
        range: range,
        thuraya_number: this.cdrData.number,
      };

      this.cdrDate(data);
    },

    customRange() {
      var range_date = this.dates.join(" ");
      const data = {
        id: this.cdrID,
        thuraya_number: this.cdrData.number,
        range: range_date,
        token: localStorage.getItem("token"),
      };
      this.cdrFetch(data);
    },

    closeCDR() {
      this.cdrRemove();
    },
  },
  computed: {
    ...mapGetters([
      "cdrData",
      "cdrID",
      "cdrOpen",
      "user_allowed",
      "cdrChart_",
      "cdrOutput_",
    ]),

    showingDatafrom() {
      if (this.totalFilter < 1) {
        return 0;
      } else {
        return 1;
      }
    },

    showingDatato() {
      if (this.totalFilter < this.itemsPerPage) {
        return this.totalFilter;
      } else {
        return this.itemsPerPage;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chartnya {
  height: 270px !important;
}
</style>
