<template>
  <div v-if="editprofile">
    <v-dialog max-width="600" v-model="editprofile" class="elevation-0 rounded-0" persistent>
      <v-card   ref="form" class="elevation-0 rounded-0">
        <v-toolbar elevation="0" color="#F7F7F7" dense >
          <v-toolbar-title>Edit Profile</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn
          class="elevation-0 rounded-0" small color="primary"
            v-if="editprofdata && editprofdata.sameUser != true"
            @click="setEditPass(true), fetchEditPass(editprofdata.user.id)"
            
          >
            <v-icon small>mdi-shield-lock-outline</v-icon>
            &nbsp; Password
          </v-btn>
          &nbsp;
          <v-btn class="elevation-0 rounded-0 ml-2" small color="primary" @click="fetchApiData(editprofdata.user.id),fetchDataApi(editprofdata.user.id)">
            <v-icon small>mdi-cog-outline</v-icon>
            &nbsp; Api Access
          </v-btn>
        </v-toolbar>
        <v-divider></v-divider>
        <v-container style="margin-top:0"  v-if="!editprofdata" fill-height fluid>
        <v-row align="center" justify="center">
          <v-col
            ><img
              alt="Navplus logo"
              width="70px"
              :src="loadernavplus"
              class="justify-center"
          /></v-col>
        </v-row>
      </v-container>
        <div v-if="editprofdata">
        <v-progress-linear
          v-if="!editprofdata"
          indeterminate
          color="cyan"
        ></v-progress-linear>
        <v-card-text
          v-if="editprofdata"
          style="margin-top: 55px; margin-bottom: -50px"
        >
        <v-form v-model="valid"  @submit.prevent="handleSubmit"  lazy-validation>
          <v-text-field
          @input="checkdata(editprofdata.user.name,'name')"
          class="elevation-0 rounded-0"
            :rules="rules"
            counter
            :value="editprofdata.user.name"
            label="Name"
            v-model="editprofdata.user.name"
            outlined
          ></v-text-field>
          <v-text-field
          @input="checkdata(editprofdata.user.username,'username')"
          class="elevation-0 rounded-0"
            :rules="rules2"
            counter
            :value="editprofdata.user.username"
            label="Username"
            v-model="editprofdata.user.username"
            outlined
          ></v-text-field>

          <v-text-field
            :value="editprofdata.user.email"
            label="Email"
            class="elevation-0 rounded-0"
            disabled
            outlined
          ></v-text-field>
        </v-form>
          <v-divider class="mt-12"></v-divider>
        </v-card-text>
        <v-card-actions style="margin-top: 2em">
          <v-btn
          :disabled="changes_ && valid ? false : true"
          small
          class="elevation-0 rounded-0"
            v-if="editprofdata"
            color="primary"
            style="width: 49%"
          @click="eprof_confirm = true"
          >
            Update Profile
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
          small
          class="elevation-0 rounded-0"
            color="red"
            @click="removeEditProf(null)"
            style="width: 49%"
            dark
          >
            cancel
          </v-btn>
        </v-card-actions>
      </div>
      </v-card>
    </v-dialog>
    <v-dialog v-model="eprof_confirm" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Are you sure? </v-card-title>
        <v-card-text style="text-align: left"
          >Profile data will updated after you click yes</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="eprof_confirm = false">
            No
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="
              EditProfileSubmit(), (dialog = true), (eprof_confirm = false)
            "
          >
            Yes
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="eprof_nochange" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> No Changes! </v-card-title>
        <v-card-text style="text-align: left"
          >You did not change anything</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="eprof_nochange = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="eprof_empty" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Empty Input! </v-card-title>
        <v-card-text style="text-align: left"
          >Name and username cannot empty</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="eprof_empty = false">
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" hide-overlay persistent width="300">
      <v-card color="#4CAF50" style="padding: 1em" dark>
        <v-alert dense prominent type="success" style="margin-top: 1em"
          >Profile has change</v-alert
        >
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import axios from "axios";
import { mapGetters, mapActions } from "vuex";

export default {
  props: ["user_id_bawaan"],
  data() {
    return {
      rules: [
      v => !!v || 'Name is required',
    ],
    rules2: [
      v => !!v || 'Userame is required',
    ],
      valid: false,
      change: false,
      loadernavplus: require("@/assets/img/load100new.gif"),
      dialog: false,
      eprof_confirm: null,
      eprof_nochange: null,
      eprof_empty: null,
    };
  },


  

  watch: {
    dialog(val) {
      if (!val) return;

      setTimeout(() => (this.dialog = false), 1000);
    },
  },

  async created() {
    this.fetchUserData(localStorage.getItem("token"));
  },

  destroyed() {
    this.removeEditProf(null);
  },

  created() {
      this.fetchUserData(localStorage.getItem("token"));
    },

  methods: {
    ...mapActions([
      "fetchUSERGET",
      "removeEditProf",
      "setUserChange",
      "setNameChange",
      "setProfileChange",
      "fetchUserData",
      "setuserSaveData",
      "setEditPass",
      "fetchEditPass",
      "fetchApiData",
      "addDataApi",
      "fetchDataApi"
    ]),
    

    checkdata(value,index) {
    var user_data = JSON.parse( localStorage.getItem('user_data') )
    console.log(user_data[index])
      console.log(value)
      if (user_data[index] == value) {
        this.change = false;
      } else {
        this.change = true;
      }
    },

    async EditProfileSubmit() {
      const data = {
        name: this.editprofdata.user.name,
        username: this.editprofdata.user.username,
      };
      const response = await axios.post(
        "https://navigatorplus.sailink.id/api/v1/navplus/user/edit/" +
          this.editprofdata.user.id,
        data,
        {
          headers: {
            Authorization: "Bearer" + localStorage.getItem("token"),
          },
        }
      );
      console.log(response);
      console.log(this.editprofdata);
      
      if (this.editprofdata.sameUser == true) {
        this.fetchUSERGET(localStorage.getItem("token"))
        this.fetchUserData();
      } else {
        this.fetchAdmin(localStorage.getItem("token"));
      }
      this.removeEditProf(null);
      
    },
  },
  computed: {
    ...mapGetters([
      "editprofile",
      "username_change",
      "name_change",
      "profile_change",
      "userprof",
      "userSave",
      "editprofdata",
    ]),
    changes_() {
      return this.change;
    },
  },
};
</script>

<style lang="scss" scoped></style>
